import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { CssBaseline } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { closeSnackbar } from 'ducks/snackbar'
import { styledComponentsTheme as baseTheme } from 'styles/baseTheme'

import AuthenticationLayout from 'components/layouts/AuthenticationLayout'
import CustomSnackBar from 'components/common/CustomSnackBar'
import NotFound from 'components/public/NotFound'
import PrivateRoute from './PrivateRoute'
import PrivateLayout from 'components/layouts/PrivateLayout'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import PublicLayout from 'components/layouts/PublicLayout'

const Notification = () => {
  const dispatch = useDispatch()
  // Setup notifications
  const { verticalPosition, horizontalPosition, message, autoHideDuration, isOpen, messageType } =
    useSelector(store => store.snackbar)
  return (
    <CustomSnackBar
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      message={message}
      autoHideDuration={autoHideDuration}
      isOpen={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') dispatch(closeSnackbar())
      }}
      messageType={messageType}
    />
  )
}

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={baseTheme}>
        <Helmet
          titleTemplate='%s | Alcoholic Beverage Control'
          defaultTitle='Price Posting Application | Alcoholic Beverage Control'
        ></Helmet>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />

          <Switch>
            {/* <PrivateRoute
              exact
              path='/(dashboard|licensees|licenseeUsers|abcUsers|products|pricePostings|pricePostingsSchedule|globalSettings|configurationRequests|newsUpdate|)'
              component={PrivateLayout}
            /> */}
            <PrivateRoute
              exact
              path='/(dashboard|licensees|licenseeUsers|abcUsers|products|pricePostings|globalSettings|configurationRequests|newsUpdate|)'
              component={PrivateLayout}
            />
            <Route exact path='/(home|publicProducts|publicPricePosts)' component={PublicLayout} />
            <Route
              exact
              path='/(login|forgotUsername|recoverUsername|forgotPassword|verifyCode|signup|newPassword)'
              component={AuthenticationLayout}
            />
            <Route
              exact
              path='/(register)'
              render={props => <AuthenticationLayout {...props} large={true} />}
            />
            <Route component={NotFound} />
          </Switch>
          <Notification />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
