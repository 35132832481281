import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Paper, Divider, Typography, Grid } from '@material-ui/core'
import Login from 'components/public/Login'
import ForgotPassword from 'components/public/ForgotPassword'
import VerifyCode from 'components/public/VerifyCode'
import ForgotUsername from 'components/public/ForgotUsername'
import RecoverUsername from 'components/public/RecoverUsername'
import NewPassword from 'components/public/NewPassword'
import SignUp from 'components/public/SignUp'
import LegalEntitySignUp from 'components/public/LegalEntitySignUp'
import logo from 'assets/abcLogo.png'
import lightBackgroundImg from 'assets/background.png'
import backgroundImg from 'assets/backgroundShadow.png'

const Logo = styled.img`
  max-width: 200px;
  max-height: 80px;
  margin: 10px auto;
`

const Header = styled.div`
  min-height: 100px;
  margin-bottom: 15px;
`

const Title = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
`

const StyledDivider = styled(Divider)`
  && {
    background-color: ${props => props.theme.gold};
    height: 2px;
  }
`

const GlobalStyle = createGlobalStyle`
  html {
    height: 100vh;
    background: ${props =>
      props.isAuthorizing ? props.theme.light : props.theme.dark};
  }
  body {
    -webkit-font-smoothing: subpixel-antialiased !important; 
    text-rendering:optimizeLegibility !important;
  }
`

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 254px;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: ${props => (props.isAuthorizing ? '0.3' : '1')};
  ${props =>
    props.isAuthorizing
      ? `background-image: url(${lightBackgroundImg});`
      : `background-image: url(${backgroundImg});`}
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: 1920px 254px;
`
const EmptyDiv = styled.div``
const Container = styled(Paper)`
  && {
    text-align: center;
    padding: 25px;
    width: auto;
    margin: 5% 24px;

    form {
      margin-top: 10px;
    }

    @media (min-width: 448px) {
      width: ${props => (props.large === 'true' ? 'auto' : '420px')};
      margin: 80px auto;
    }
  }
`

const AuthenticationLayout = props => {
  const { isLoading } = useSelector(store => store.global)
  let location = useLocation()

  // Used to display same background as private layout while authenticating user
  const isAuthorizing =
    isLoading && (location.pathname === '/' || location.pathname === '/login')

  const commonComponent = (
    <>
      <GlobalStyle isAuthorizing={isAuthorizing} />
      {isAuthorizing ? (
        ''
      ) : (
        <>
          <Header>
            <Logo src={logo} alt='ABC Seal' />
            <Title variant='h5' role='heading' aria-level='1'>
              ABC Price Posting
            </Title>
            <StyledDivider />
          </Header>
          <Switch>
            <Route exact path='/(login|)' component={Login} />
            <Route exact path='/forgotUsername' component={ForgotUsername} />
            <Route exact path='/recoverUsername' component={RecoverUsername} />
            <Route exact path='/forgotPassword' component={ForgotPassword} />
            <Route exact path='/verifyCode' component={VerifyCode} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/register' component={LegalEntitySignUp} />
            <Route exact path='/newPassword' component={NewPassword} />
          </Switch>
        </>
      )}
      <Background isAuthorizing={isAuthorizing} />
    </>
  )

  return (
    <Grid container justify='center'>
      <Grid item xs={12} sm={props.large ? 8 : 12}>
        {isAuthorizing ? (
          <EmptyDiv>{commonComponent}</EmptyDiv>
        ) : (
          <Container
            large={props.large ? props.large.toString() : ''}
            role='main'
          >
            {commonComponent}
          </Container>
        )}
      </Grid>
    </Grid>
  )
}

export default AuthenticationLayout
