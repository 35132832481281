import { createReducer, createAction } from '@reduxjs/toolkit'
import { setLoading, showError, setFetchingTotalCount } from 'ducks/global'
import {
  whereClauseBuilderForPP,
  getToken,
  formatNumberToTwoDecimal,
  exportToExcel,
  convertSizeOfBBL,
} from 'common/helper'
import { print } from 'graphql'
import {
  getPublicPricePostsQuery,
  getPricePostingsTotalCount,
  getPublicPricePostByIDQuery,
  getPPForBulkSelection,
  getPricePostingsQueryForExport,
} from 'graphql/queries'
import { format } from 'date-fns'

const fetchTotalCount = graphQLParams => {
  return async dispatch => {
    dispatch(setFetchingTotalCount(true))
    await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken({ code: 'getPricePostingsTotalCount' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getPricePostingsTotalCount),
        variables: graphQLParams,
      }),
    })
      .then(res => res.json())
      .then(res => {
        dispatch(setPricePostingsTotalCount(res.data.pricePostingsCount.count))
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was issue while trying to fetch the total number of pages, please check your internet connection and try again.'
          )
        )
      })
    dispatch(setFetchingTotalCount(false))
  }
}

export const getPricePostsList = (whereClause, getUpdatedTotalCount, selectAll = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading price posts, please wait...'))
    if (getUpdatedTotalCount) {
      dispatch(setPricePostingsTotalCount(0))
    }
    const {
      pricePostingsFilters,
      pricePostingsTotalCount,
      orderBy,
      order,
      pageNumber,
      rowsPerPage,
    } = getState().publicPricePosts

    const orderRule = `${orderBy}_${order}`
    let whereClauseFromFilters = {}

    if (whereClause) {
      dispatch(setPricePostingsFilters(whereClause))
      whereClauseFromFilters = { ...whereClause }
    } else {
      whereClauseFromFilters = {
        ...pricePostingsFilters,
        ...whereClause,
      }
    }
    if (whereClauseFromFilters.status.length === 0) {
      whereClauseFromFilters.status = [{ value: 'Active' }, { value: 'Old' }]
    }

    let graphQLParams = {
      where: whereClauseBuilderForPP(whereClauseFromFilters),
    }
    if (selectAll) {
      await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: 'getPPForBulkSelection' }),
          'content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: print(getPPForBulkSelection),
          variables: graphQLParams,
        }),
      })
        .then(res => res.json())
        .then(res => {
          res.data.pricePostings.count > 0 &&
            dispatch(
              setPricePostingBulkSelect(
                res.data.pricePostings.results.map(r => ({
                  id: r.id,
                  status: r.status,
                  createdByLicenseeID: r.createdByLicensee ? r.createdByLicensee.id : '',
                }))
              )
            )
        })
        .catch(_ => {
          dispatch(
            showError(
              'Unable to select items, please check your internet connection and try again. Thank you.'
            )
          )
        })
      dispatch(setLoading(false))
    } else {
      graphQLParams = {
        orderBy: orderRule,
        limit: rowsPerPage,
        offset: pageNumber * rowsPerPage,
        where: whereClauseBuilderForPP(whereClauseFromFilters),
      }

      let pricePostsFetchSuccess = false
      await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: 'getPublicPricePostsQuery' }),
          'content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: print(getPublicPricePostsQuery),
          variables: graphQLParams,
        }),
      })
        .then(response => response.json())
        .then(res => {
          pricePostsFetchSuccess = true
          dispatch(setPricePostingsList(res.data.pricePostings))
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was some issue while trying to fetch price posts, please check your internet connection and try again.'
            )
          )
        })
      dispatch(setLoading(false))
      if (pricePostsFetchSuccess && pricePostingsTotalCount === 0) {
        graphQLParams = {
          where: graphQLParams.where,
        }
        dispatch(fetchTotalCount(graphQLParams))
      }
    }
  }
}

// Fetch pricePosting by ID after selecting list item
export const fetchPricePostingByID = id => {
  return async dispatch => {
    dispatch(setLoading('Loading Price Posting...'))

    fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken({ code: 'getPublicPricePostByIDQuery' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getPublicPricePostByIDQuery),
        variables: { id: parseInt(id) },
      }),
    })
      .then(res => res.json())
      .then(res => {
        const finalRes = res.data.pricePostings.results[0]
        const pricePostingFromResponse = {
          ...finalRes,
          product: {
            value: finalRes.product.id,
            label: finalRes.product.name,
          },
          tradeName: finalRes.product.tradeName,
          size: {
            value: finalRes.productSize.id,
            label: `
              ${convertSizeOfBBL(finalRes.productSize.size, finalRes.productSize.unit.unit)}
              ${finalRes.productSize.unit.unit} - 
              ${finalRes.productSize.containerType.type}
            `,
          },
          price: formatNumberToTwoDecimal(finalRes.price),
          containerCharge: formatNumberToTwoDecimal(finalRes.containerCharge),
          pricePromotion: finalRes.pricePromotion,
          status: { value: finalRes.status, label: finalRes.status },
          county: [{ value: finalRes.county, label: finalRes.county }],
          receivingMethod: [
            {
              value: finalRes.receivingMethod,
              label: finalRes.receivingMethod,
            },
          ],
          tradingArea: finalRes.tradingArea ? finalRes.tradingArea : '',
          isEffectiveImmediately: false,
        }
        dispatch(setPricePostingDetails(pricePostingFromResponse))
        dispatch(setPricePostingShow(true))
      })
      .catch(_ => {
        dispatch(
          showError(
            `Unable to retrieve price post, please check your internet connection and try again`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const exportToExcelCall = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Postings...'))
    const { bulkSelected } = getState().publicPricePosts

    await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken({ code: 'getPricePostingsQueryForExport' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getPricePostingsQueryForExport),
        variables: {
          where: { id_in: bulkSelected.map(item => parseInt(item.id)) },
          orderBy: 'createdAt_DESC',
        },
      }),
    })
      .then(res => res.json())
      .then(response => {
        const finalRes = response.data.pricePostings.results.map(pricePosting => {
          let updatedPricePosting = {}
          updatedPricePosting = {
            Manufacturer: pricePosting.manufacturer.name,
            'Trade Name': pricePosting.product.tradeName,
            'Product Name': pricePosting.product.name,
            'Package Configuration': pricePosting.package.package,
            'Product Size': `${pricePosting.productSize.size} ${pricePosting.productSize.unit.unit}`,
            'Container Type': pricePosting.productSize.containerType.type,
            'Trading Area': pricePosting.tradingArea,
            County: pricePosting.county,
            'Prices To': pricePosting.pricesTo.name,
            'Receiving Method': pricePosting.receivingMethod,
            'Price($)': formatNumberToTwoDecimal(pricePosting.price),
            'Container Charge($)': formatNumberToTwoDecimal(pricePosting.containerCharge),
            'Price Promotion': pricePosting.pricePromotion,
            Licensee: pricePosting.createdByLicensee ? pricePosting.createdByLicensee.name : '',
            'Submitted Date': format(pricePosting.createdAt, 'MM/dd/yyyy'),
            'Effective Date': format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
            Status: pricePosting.status,
          }
          return updatedPricePosting
        })
        exportToExcel(finalRes, 'listOfPricePostings')
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of pricePostings, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const resetPricePostDetails = () => {
  return dispatch => {
    dispatch(setPricePostingShow(false))
    dispatch(setPricePostingDetails(initialState.pricePostingDetails))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setPricePostingsPageNumber(0))
    dispatch(setPricePostingsFilters(initialState.pricePostingsFilters))
    dispatch(getPricePostsList(false, true))
  }
}

export const setPricePostingsList = createAction('publicPricePosts/setPricePostingsList')
export const setPricePostingsTotalCount = createAction(
  'publicPricePosts/setPricePostingsTotalCount'
)
export const setIsPricePostingsFilterOpen = createAction(
  'publicPricePosts/setIsPricePostingsFilterOpen'
)
export const setPricePostingsFilters = createAction('publicPricePosts/setPricePostingsFilters')
export const setPricePostingShow = createAction('publicPricePosts/setPricePostingShow')
export const setPricePostingDetails = createAction('publicPricePosts/setPricePostingDetails')
export const setPricePostingBulkSelect = createAction('publicPricePosts/setPricePostingBulkSelect')
export const setPricePostingsPageNumber = createAction(
  'publicPricePosts/setPricePostingsPageNumber'
)
export const setPricePostingsRowsPerPage = createAction(
  'publicPricePosts/setPricePostingsRowsPerPage'
)
export const setPricePostingsRequestedSort = createAction(
  'publicPricePosts/setPricePostingsRequestedSort'
)
export const resetPricePostingsList = createAction('publicPricePosts/resetPricePostingsList')

const initialState = {
  pricePostingsList: { results: [], firstLoad: true },
  pricePostingsTotalCount: 0,
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  bulkSelected: [],
  isPricePostingsFilterOpen: false,
  pricePostingsFilters: {
    createdByLicensee: [],
    manufacturer: [],
    products: [],
    tradeName: '',
    package: [],
    size: [],
    county: [],
    pricesTo: [],
    operatorForPrice: 'equal',
    price: '',
    receivingMethod: [],
    operatorForContainerCharge: 'equal',
    containerCharge: '',
    status: [],
    effectiveFrom: '',
    effectiveTo: '',
    submittedFrom: '',
    submittedTo: '',
  },
  isPricePostingShown: false,
  pricePostingDetails: {
    manufacturer: '',
    product: '',
    package: '',
    size: '',
    county: '',
    tradingArea: '',
    pricesTo: '',
    price: '',
    containerCharge: '',
    pricePromotion: '',
    receivingMethod: '',
    isEffectiveImmediately: false,
    status: '',
    createdByLicensee: {
      id: '',
      name: '',
    },
  },
}

export default createReducer(initialState, {
  [setPricePostingsList]: (state, action) => {
    state.pricePostingsList = action.payload
  },
  [setPricePostingsTotalCount]: (state, action) => {
    state.pricePostingsTotalCount = action.payload
  },
  [setPricePostingBulkSelect]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setPricePostingsFilters]: (state, action) => {
    state.pricePostingsFilters = action.payload
  },
  [setPricePostingShow]: (state, action) => {
    state.isPricePostingShown = action.payload
  },
  [setPricePostingDetails]: (state, action) => {
    state.pricePostingDetails = action.payload
  },
  [setPricePostingsPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setPricePostingsRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setPricePostingsRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setIsPricePostingsFilterOpen]: (state, action) => {
    state.isPricePostingsFilterOpen = action.payload
  },
  [resetPricePostingsList]: state => {
    state.pricePostingsList = initialState.pricePostingsList
    state.pageNumber = initialState.pageNumber
    state.isPricePostingsFilterOpen = initialState.isPricePostingsFilterOpen
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.pricePostingsFilters = initialState.pricePostingsFilters
    state.isPricePostingShown = initialState.isPricePostingShown
    state.pricePostingDetails = initialState.pricePostingDetails
    state.singlePricePostSelected = initialState.singlePricePostSelected
  },
})
