import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingIndicator from 'components/common/LoadingIndicator'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { Helmet } from 'react-helmet'
import {
  getProductsList,
  setIsProductsFilterOpen,
  setProductBulkSelection,
  setProductsPageNumber,
  setProductsRowsPerPage,
  setProductsRequestedSort,
  resetProductDetails,
  setProductShow,
  fetchProductByID,
  exportProductsToExcel,
} from 'ducks/public/products'
import ProductsFilters from './ProductsFilters'
import ViewProduct from './ViewProduct'

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const ProductsHeaders = [
  { id: 'manufacturers', label: 'Manufacturer', isSortable: true },
  { id: 'tradeName', label: 'Trade Name', isSortable: true },
  { id: 'name', label: 'Product Name', isSortable: true },
  { id: 'createdAt', label: 'Added Date', isSortable: true },
  { id: 'updatedAt', label: 'Edited Date', isSortable: true },
]

const PublicProducts = () => {
  const dispatch = useDispatch()
  const {
    productsList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    isProductsFilterOpen,
    isProductShown,
    productsFilters,
  } = useSelector(store => store.publicProducts)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getProductsList())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  /*------Action Items----------*/
  const actionItemsOnSelection = (
    <ActionWrapper>
      <Button
        aria-label='export'
        color='primary'
        onClick={e => dispatch(exportProductsToExcel())}
        size='medium'
        variant='contained'
        startIcon={<CloudDownload />}
      >
        Export To Excel
      </Button>
    </ActionWrapper>
  )

  /*------Bulk/single selection----------*/
  const onItemSelected = product => {
    dispatch(fetchProductByID(product.id))
  }

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = productsList.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          productsList.results.map(finalObject => ({
            id: finalObject.id,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = productsList.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setProductBulkSelection(newSelected))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getProductsList(false, true))
  }

  const handleClearSelection = () => {
    dispatch(setProductBulkSelection([]))
  }

  /*------Pagination----------*/
  const handlePageNumber = newPage => {
    dispatch(setProductsPageNumber(newPage))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(setProductsRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setProductsRequestedSort(value))
  }

  const handleFilter = value => {
    dispatch(setIsProductsFilterOpen(value))
  }

  /*------Products Modals----------*/
  const onQuickViewClosed = () => {
    dispatch(resetProductDetails())
    dispatch(setProductShow(false))
  }

  /*------Products list----------*/
  let updatedProductsList = []
  updatedProductsList = productsList.results
    ? productsList.results.map((product, index) => ({
        id: product.id,
        manufacturer: product.manufacturer.name,
        tradeName: product.tradeName,
        product: product.name,
        createdAt: format(product.createdAt, 'MM/dd/yyyy'),
        updatedAt: format(product.updatedAt, 'MM/dd/yyyy'),
      }))
    : []

  return productsList.firsLoad ? (
    <LoadingIndicator text={loadingText} />
  ) : (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <ListComponent
        mainTitle='Products'
        listContent={
          <ReusableTable
            headers={ProductsHeaders}
            tableData={updatedProductsList}
            pagination={true}
            selectionOption={true}
            totalCount={productsList.totalCount}
            onSingleItemSelected={onItemSelected}
            handleBulkSelection={handleBulkSelection}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleSelectAllRows={handleSelectionOfAllRows}
            handleClearSelection={handleClearSelection}
            actionItemsOnSelection={actionItemsOnSelection}
          />
        }
        viewComp={<ViewProduct />}
        modalViewTitle='Product Details'
        onModalClosed={onQuickViewClosed}
        expandedOptions={<ProductsFilters productsFilters={productsFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isProductsFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isProductShown}
        publicView={true}
      />
    </>
  )
}
export default PublicProducts
