import React from 'react'
import { Grid, Typography, Chip, Avatar } from '@material-ui/core'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  color: #666;
  h6 {
    color: rgba(0, 0, 0, 0.87);
  }
  span.empty {
    color: #b3b3b3;
  }
  span.active {
    color: ${props => props.theme.active};
  }
  span.pending {
    color: ${props => props.theme.pending};
  }
  span.inactive {
    color: ${props => props.theme.inactive};
  }
`
const StyledChip = styled(Chip)`
  margin: 10px 5px 0px 0px;
`

const DataGrid = ({ label, labelRepresentsQuestion, value }) => {
  const labelEnd = labelRepresentsQuestion ? `?` : `:`
  return label === 'License Types' ? (
    <StyledGrid item xs={12} sm={12} md={12} lg={12}>
      <Typography variant='subtitle2'>
        <strong>{`${label}${labelEnd}`}</strong>
      </Typography>

      <span>
        {value.map((item, index) => (
          <StyledChip
            key={index}
            avatar={<Avatar>{item.number ? item.number : 'COC'}</Avatar>}
            label={
              item.label.indexOf(' - ') !== -1
                ? item.label.split(' - ')[1].trim()
                : item.label
            }
            color='primary'
            variant='outlined'
          />
        ))}
      </span>
    </StyledGrid>
  ) : (
    <StyledGrid item xs={12} sm={6} md={4} lg={3}>
      <Typography variant='subtitle2'>
        <strong>{`${label}${labelEnd}`}</strong>
      </Typography>
      {!value ? (
        <span className='empty'>No value</span>
      ) : (
        <span className={label === 'Prior Status' ? value.toLowerCase() : ''}>
          {value}
        </span>
      )}
    </StyledGrid>
  )
}

export default DataGrid
