import React from 'react'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import styled from 'styled-components'
import { updateProfile } from 'ducks/profileView'
import { setEditMode } from 'ducks/global'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import NumericInput from 'components/common/NumericInput'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { countries, usStates } from 'common/constants'

const validations = props => {
  return Yup.object().shape({
    ownProfile: Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string()
        .email('Please enter a valid email')
        .required('Required'),
      legalEntity:
        props.ownProfile.legalEntity &&
        props.ownProfile.legalEntity.mailingAddress
          ? Yup.object().shape({
              name: Yup.string().required('Required'),
              email: Yup.string().email('Please enter a valid email'),
              isMailingSameAsPremises: Yup.bool(),
              mailingAddress: Yup.object().when('isMailingSameAsPremises', {
                is: true,
                then: Yup.object().shape({
                  address: Yup.string(),
                  address2: Yup.string(),
                  city: Yup.string(),
                  state: Yup.string(),
                  zipcode: Yup.string(),
                  country: Yup.string(),
                }),
                otherwise: Yup.object().shape({
                  address: Yup.string().required('Required'),
                  address2: Yup.string(),
                  city: Yup.string().required('Required'),
                  state: Yup.string().required('Required'),
                  zipcode: Yup.string()
                    .max(20, 'Zip Code must be at most 20 characters')
                    .required('Required'),
                  country: Yup.string().required('Required'),
                }),
              }),
            })
          : '',
    }),
  })
}
const CheckboxWrapper = styled(FormControlLabel)`
  && {
    padding: 0px 0 10px 16px;
  }
`

const CommonEditComp = ({ label, name, autoCompName, required }) => {
  const gridSize = label.indexOf('Address') !== -1 ? 6 : 3
  return (
    <Grid item xs={12} sm={12} md={gridSize} lg={gridSize}>
      <Field
        variant='outlined'
        margin='normal'
        required={required}
        fullWidth
        label={label}
        name={name}
        autoComplete={autoCompName}
        component={TextField}
      />
    </Grid>
  )
}

const ProfileEdit = props => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)

  const onOwnProfileSubmit = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      // Check if email has changed (used to update Cognito's user email)
      const newEmail = formikValues.values.ownProfile.email
      const hasEmailChanged =
        newEmail.length > 0 &&
        formikValues.initialValues.ownProfile.email !== newEmail
      dispatch(updateProfile(formikValues.values.ownProfile, hasEmailChanged))
    }
  }

  return (
    <Form>
      <Field
        name='ownProfile'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.ownProfile = JSON.parse(
            JSON.stringify(formikProps.form.values.ownProfile)
          )

          const formikValues = formikProps.form.values
          const premisesAddress = formikValues.ownProfile.legalEntity
            ? formikValues.ownProfile.legalEntity.premisesAddress
            : ''

          const handleAutoCompleteChange = (event, parentType, elementType) => {
            if (elementType === 'country') {
              formikProps.form.setFieldValue(
                `ownProfile.legalEntity.${parentType}.state`,
                ''
              )
            }
            formikProps.form.setFieldValue(
              `ownProfile.legalEntity.${parentType}.${elementType}`,
              event.label
            )
          }

          return (
            <>
              {isLoading ? (
                <LoadingIndicator text='Updating Profile...' transparent />
              ) : (
                <>
                  <CustomCard title='User Details'>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='First Name'
                        name='ownProfile.firstName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Last Name'
                        name='ownProfile.lastName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Email'
                        name='ownProfile.email'
                        autoComplete='email'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='ownProfile.phoneNumber'
                        label='Phone'
                        id='phone'
                        component={NumericInput}
                        autoComplete='phone'
                        format={'### ### #### ### ##'}
                      />
                    </Grid>
                  </CustomCard>
                  {premisesAddress && (
                    <>
                      <CustomCard title='Licensee Premises Address'>
                        <DataGrid
                          label='Address Line 1'
                          value={premisesAddress.address}
                        />
                        {premisesAddress.address2 ? (
                          <DataGrid
                            label='Address Line 2'
                            value={premisesAddress.address2}
                          />
                        ) : (
                          ''
                        )}
                        <DataGrid label='City' value={premisesAddress.city} />
                        <DataGrid label='State' value={premisesAddress.state} />
                        <DataGrid
                          label='Zip Code'
                          value={premisesAddress.zipcode}
                        />
                        <DataGrid
                          label='Country'
                          value={premisesAddress.country}
                        />
                      </CustomCard>

                      <Grid item xs={12} sm={12} md={6}>
                        <CheckboxWrapper
                          control={
                            <Field
                              onChange={() =>
                                formikProps.form.setFieldValue(
                                  'ownProfile.legalEntity.isMailingSameAsPremises',
                                  !formikValues.ownProfile.legalEntity
                                    .isMailingSameAsPremises
                                )
                              }
                              name='ownProfile.legalEntity.isMailingSameAsPremises'
                              component={Checkbox}
                              type='checkbox'
                              checked={
                                formikValues.ownProfile.legalEntity
                                  .isMailingSameAsPremises
                              }
                            />
                          }
                          label='Mailing address is the same as premises address.'
                        />
                      </Grid>

                      {!formikValues.ownProfile.legalEntity
                        .isMailingSameAsPremises && (
                        <CustomCard title={'Licensee Mailing Address'}>
                          <CommonEditComp
                            label='Address Line 1'
                            name='ownProfile.legalEntity.mailingAddress.address'
                            autoCompName='address-line1'
                            required={true}
                          />
                          <CommonEditComp
                            label='Address Line 2'
                            name='ownProfile.legalEntity.mailingAddress.address2'
                            autoCompName='address-line2'
                          />
                          <CommonEditComp
                            label='City'
                            name='ownProfile.legalEntity.mailingAddress.city'
                            autoCompName='address-level2'
                            required={true}
                          />
                          <Grid item xs={12} sm={12} md={3}>
                            <Field
                              placeHolderText='Country *'
                              selectedValue={
                                formikValues.ownProfile.legalEntity
                                  .mailingAddress.country && {
                                  label:
                                    formikValues.ownProfile.legalEntity
                                      .mailingAddress.country,
                                  value:
                                    formikValues.ownProfile.legalEntity
                                      .mailingAddress.country,
                                }
                              }
                              name='ownProfile.legalEntity.mailingAddress.country'
                              formikProps={formikProps.form}
                              dropDownOptions={countries}
                              onValueChange={event =>
                                handleAutoCompleteChange(
                                  event,
                                  'mailingAddress',
                                  'country'
                                )
                              }
                              component={AutoCompleteComp}
                            />
                          </Grid>
                          {formikValues.ownProfile.legalEntity.mailingAddress
                            .country === 'United States' ? (
                            <Grid item xs={12} sm={12} md={3}>
                              <Field
                                placeHolderText='State *'
                                selectedValue={
                                  formikValues.ownProfile.legalEntity
                                    .mailingAddress.state && {
                                    label:
                                      formikValues.ownProfile.legalEntity
                                        .mailingAddress.state,
                                    value:
                                      formikValues.ownProfile.legalEntity
                                        .mailingAddress.state,
                                  }
                                }
                                name='ownProfile.legalEntity.mailingAddress.state'
                                formikProps={formikProps.form}
                                dropDownOptions={usStates}
                                onValueChange={event =>
                                  handleAutoCompleteChange(
                                    event,
                                    'mailingAddress',
                                    'state'
                                  )
                                }
                                component={AutoCompleteComp}
                              />
                            </Grid>
                          ) : (
                            <CommonEditComp
                              label='State'
                              name='ownProfile.legalEntity.mailingAddress.state'
                              autoCompName='address-level1'
                              required={true}
                            />
                          )}
                          <CommonEditComp
                            label='Zip Code'
                            name='ownProfile.legalEntity.mailingAddress.zipcode'
                            autoCompName='postal-code'
                            required={true}
                          />
                        </CustomCard>
                      )}
                    </>
                  )}

                  <SaveAndCancelButton
                    onSave={() => onOwnProfileSubmit(formikProps.form)}
                    onCancel={() => {
                      dispatch(setEditMode(false))
                    }}
                    submitLabel='Update'
                    isDisabled={!formikProps.form.isValid}
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: props => validations(props),
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(ProfileEdit)

export default WithFormikWrapper
