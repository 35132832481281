import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const ToggleMaskPassword = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [passwordIsMasked, setPasswordIsMasked] = useState(true)
  let touchedValue = null
  let errorValue = null
  let fieldNameArray = field.name.split('.')
  if (fieldNameArray.length > 1) {
    touchedValue =
      touched[fieldNameArray[0]] &&
      touched[fieldNameArray[0]][fieldNameArray[1]]

    errorValue =
      errors[fieldNameArray[0]] && errors[fieldNameArray[0]][fieldNameArray[1]]
  } else {
    touchedValue = touched[fieldNameArray[0]]
    errorValue = errors[fieldNameArray[0]]
  }

  const toggleMaskPassword = () => {
    setPasswordIsMasked(!passwordIsMasked)
  }
  return (
    <TextField
      {...field}
      {...props}
      type={passwordIsMasked ? 'password' : 'text'}
      error={touchedValue && errorValue ? true : false}
      helperText={touchedValue && errorValue && `${errorValue}`}
      inputProps={{ onCopy: e => e.preventDefault() }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => {
                toggleMaskPassword()
              }}
            >
              {passwordIsMasked ? (
                <VisibilityOff titleAccess='Show password' />
              ) : (
                <Visibility titleAccess='Hide password' />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default ToggleMaskPassword
