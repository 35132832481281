import React from 'react'
import { Formik, Form, Field } from 'formik'
import { formTypeValues } from 'common/constants'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  performCompetitivePricePosting,
  setPriceAndContainerFormType,
} from 'ducks/competitivePricePosting'
import CustomCard from 'components/common/CustomCard'
import { Grid, Button } from '@material-ui/core'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import NumericInput from 'components/common/NumericInput'
import styled from 'styled-components'
import { Save } from '@material-ui/icons'
import ConfirmationModal from 'components/common/ConfirmationModal'
import { useState } from 'react'
import { removeExtraCharFromPrice } from 'common/helper'

const SubmitButtonWrapper = styled(Grid)`
  && {
    margin-top: 14px;
  }
`
const StyledButton = styled(Button)`
  && {
    width: 100%;
    transition: 250ms all ease-in;
  }
`

const PriceAndContainerForm = () => {
  const dispatch = useDispatch()
  const [confirmCompetitivePricePost, setConfirmCompetitivePriceChange] = useState(false)
  const [newPrices, setNewPrices] = useState(null)

  const {
    bulkCompetitiveItemsSelected,
    bulkSelectionOfCompetingWithItems,
    priceAndContainerFormType,
  } = useSelector(store => store.competitivePricePosting)

  let validationFields = {
    price: Yup.string()
      .matches(/^[$]((?!(0))[1-9]?,?[0-9]{1,}|0)\.[0-9]{2,3}$/, {
        message: 'Price not properly formatted (0.00)',
      })
      .test(
        'price',
        "Should be less than your own price and greater than or equal to competitor's price.",
        value => {
          const enteredValue = parseFloat(removeExtraCharFromPrice(value))
          const competitorPriceArray = bulkSelectionOfCompetingWithItems.map(bs => bs.price)
          const myPricesArray = bulkCompetitiveItemsSelected.map(bs => bs.price)
          const isValid =
            competitorPriceArray.every(mp => enteredValue > mp || enteredValue === mp) &&
            myPricesArray.every(mp => enteredValue < mp)
          return isValid
        }
      ),
    containerCharge: Yup.string().test(
      'containerCharge',
      "Should be less than your own container charge and greater than or equal to competitor's container charge.",
      value => {
        const enteredValue = parseFloat(removeExtraCharFromPrice(value))
        const competitorContainerArray = bulkSelectionOfCompetingWithItems.map(
          bs => bs.containerCharge
        )
        const myContainerArray = bulkCompetitiveItemsSelected.map(bs => bs.containerCharge)
        const isValid =
          competitorContainerArray.every(mp => enteredValue > mp || enteredValue === mp) &&
          myContainerArray.every(mp => enteredValue < mp)
        return isValid
      }
    ),
  }

  const handleConfirmation = () => {
    setConfirmCompetitivePriceChange(false)
    dispatch(performCompetitivePricePosting(newPrices))
  }

  if (priceAndContainerFormType === formTypeValues.priceOnly) {
    validationFields = {
      price: validationFields.price,
    }
  }
  if (priceAndContainerFormType === formTypeValues.containerOnly) {
    validationFields = {
      containerCharge: validationFields.containerCharge,
    }
  }

  const priceAndContainerFormValidations = Yup.object().shape(validationFields)

  return (
    <>
      <Formik
        initialValues={{
          updateField: {
            value: formTypeValues.priceOnly,
            label: formTypeValues.priceOnly,
          },
          price: '',
          containerCharge: '',
        }}
        onSubmit={(newPrices, { setSubmitting }) => {
          setConfirmCompetitivePriceChange(true)
          setNewPrices(newPrices)
        }}
        validationSchema={priceAndContainerFormValidations}
      >
        <Form noValidate>
          <Field
            name='priceAndContainerForm'
            render={formikProps => {
              const handleAutoCompleteChange = (field, event) => {
                formikProps.form.setFieldValue('price', '')
                formikProps.form.setFieldValue('containerCharge', '')
                dispatch(setPriceAndContainerFormType(event.value))
                formikProps.form.setFieldValue(`${field}`, event)
              }
              let fieldGridValue = 6
              let buttonGridValue = 3
              if (priceAndContainerFormType === formTypeValues.both) {
                fieldGridValue = 4
                buttonGridValue = 2
              }
              return (
                <CustomCard title='Please submit new prices'>
                  <Grid item xs={12} sm={12} md={buttonGridValue} lg={buttonGridValue}>
                    <Field
                      placeHolderText='Update fields'
                      name='updateField'
                      formikProps={formikProps.form}
                      selectedValue={formikProps.form.values.updateField}
                      dropDownOptions={[
                        {
                          value: formTypeValues.both,
                          label: formTypeValues.both,
                        },
                        {
                          value: formTypeValues.priceOnly,
                          label: formTypeValues.priceOnly,
                        },
                        {
                          value: formTypeValues.containerOnly,
                          label: formTypeValues.containerOnly,
                        },
                      ]}
                      onValueChange={event => handleAutoCompleteChange('updateField', event)}
                      isMulti={false}
                      component={AutoCompleteComp}
                    />
                  </Grid>

                  {(priceAndContainerFormType === formTypeValues.both ||
                    priceAndContainerFormType === formTypeValues.priceOnly) && (
                    <Grid item xs={12} sm={6} md={fieldGridValue} lg={fieldGridValue}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Price'
                        id='price'
                        name='price'
                        required
                        prefix={'$'}
                        placeholder='0.00'
                        decimalScale={3}
                        component={NumericInput}
                        isAllowed={values => {
                          const { formattedValue, floatValue } = values
                          if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                            return false
                          }
                          return formattedValue === '' || floatValue <= 9999.99
                        }}
                      />
                    </Grid>
                  )}
                  {(priceAndContainerFormType === formTypeValues.both ||
                    priceAndContainerFormType === formTypeValues.containerOnly) && (
                    <Grid item xs={12} sm={6} md={fieldGridValue} lg={fieldGridValue}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        required
                        label='Container Charge'
                        id='containerCharge'
                        name='containerCharge'
                        decimalScale={2}
                        fixedDecimalScale={true}
                        component={NumericInput}
                        isAllowed={values => {
                          const { formattedValue, floatValue } = values
                          if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                            return false
                          }
                          return formattedValue === '' || floatValue <= 9999.99
                        }}
                      />
                    </Grid>
                  )}
                  <SubmitButtonWrapper
                    item
                    xs={12}
                    sm={12}
                    md={buttonGridValue}
                    lg={buttonGridValue}
                  >
                    <StyledButton
                      aria-label='Submit-competitive-prices'
                      color='primary'
                      type='submit'
                      disabled={!formikProps.form.isValid}
                      variant='contained'
                    >
                      <Save />
                      &nbsp;&nbsp;{'Submit'}
                    </StyledButton>
                  </SubmitButtonWrapper>
                  <div>* indicates a required field</div>
                </CustomCard>
              )
            }}
          />
        </Form>
      </Formik>
      <ConfirmationModal
        isConfirmationModalOpen={confirmCompetitivePricePost}
        confirmationList={[
          `If there any pending price changes associated with your selected price posting(s), they will be removed.
           Please confirm if you wish to proceed.`,
        ]}
        onConfirmation={() => handleConfirmation()}
        onConfirmationModalClosed={() => setConfirmCompetitivePriceChange(false)}
      />
    </>
  )
}

export default PriceAndContainerForm
