import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Link } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const CenterDiv = styled.div`
  text-align: center;
  padding-top: 20%;
`

const NotFound = () => (
  <CenterDiv>
    <WarningIcon color='primary' fontSize='large' />
    <br />
    <Typography component='h1' variant='h5'>
      The page you're looking for doesn't exist
    </Typography>
    <br />
    <Link to='/' variant='h6' component={RouterLink}>
      Go Home
    </Link>
  </CenterDiv>
)

export default NotFound
