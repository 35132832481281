import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextField } from 'formik-material-ui'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import { AbilityContext } from 'common/abilityContext'
import GenericDatePicker from 'components/common/GenericDatePicker'
import { statusForDropdown } from 'common/constants'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { getRolesForDropDown } from 'ducks/global'
import { clearFilters, setAbcPageNumber, getListOfUsers } from 'ducks/abcUsers'
import AutoCompleteComp from 'components/common/AutoCompleteComp'

const DatePickerWrapper = styled(Grid)`
  display: flex;
`
const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    input {
      height: 17px;
    }
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`
const ABCUsersFilters = () => {
  const dispatch = useDispatch()
  const { rolesForDropDown } = useSelector(store => store.global)
  const abilityFunc = useContext(AbilityContext)

  useEffect(() => {
    dispatch(getRolesForDropDown())
  }, [dispatch])

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setAbcPageNumber(0))
    dispatch(getListOfUsers(formikProps.values.abcUsersFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue(`abcUsersFilters`, {
      firstName: '',
      lastName: '',
      email: '',
      role: [],
      createdAt: null,
      operatorForCreated: 'equal',
      operatorForUpdated: 'equal',
      updatedAt: null,
      status: [],
    })
  }

  const updatedRolesList = rolesForDropDown
    ? rolesForDropDown.filter(
        role => abilityFunc.can('seeRolesForAbcUsers', role.label) === true
      )
    : []
  return (
    <Form>
      <Field
        name='abcUsersFilters'
        render={formikProps => {
          formikProps.form.values.abcUsersFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.abcUsersFilters)
          )
          const formikValues = formikProps.form.values

          const handleAutoCompleteChange = (field, event) => {
            formikProps.form.setFieldValue(`abcUsersFilters.${field}`, event)
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='First Name'
                    name='abcUsersFilters.firstName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Last Name'
                    name='abcUsersFilters.lastName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Email'
                    name='abcUsersFilters.email'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Roles'
                    name='abcUsersFilters.role'
                    formikProps={formikProps.form}
                    selectedValue={formikValues.abcUsersFilters.role}
                    dropDownOptions={updatedRolesList}
                    onValueChange={event =>
                      handleAutoCompleteChange('role', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Status'
                    name='abcUsersFilters.status'
                    formikProps={formikProps.form}
                    selectedValue={formikValues.abcUsersFilters.status}
                    dropDownOptions={statusForDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Added Date'
                    name='abcUsersFilters.createdAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.abcUsersFilters.createdAtFrom,
                      end: formikValues.abcUsersFilters.createdAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'abcUsersFilters.createdAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'abcUsersFilters.createdAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Edited Date'
                    name='abcUsersFilters.updatedAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.abcUsersFilters.updatedAtFrom,
                      end: formikValues.abcUsersFilters.updatedAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'abcUsersFilters.updatedAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'abcUsersFilters.updatedAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(ABCUsersFilters)

export default WithFormikWrapper
