import React from 'react'
import styled from 'styled-components'
import { Typography, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

const StyledTooltip = styled(Tooltip)`
  && {
    margin-left: 3px;
    bottom: 90px;
  }
`

const List = styled.ul`
  padding-left: 20px;
`

const Message = styled(Typography)`
  && {
    line-height: 20px;
    padding: 5px;
  }
`

const Icon = styled(InfoOutlined)`
  && {
    pointer-events: auto;
  }
`

const CommonTooltip = ({ tooltipTitle, informationList, open = false }) => {
  return (
    <>
      <StyledTooltip
        arrow={true}
        variant='dense'
        placement='right'
        open={open}
        title={
          informationList.length > 1 ? (
            <>
              <Typography color='inherit' variant='subtitle1' children={<b>{tooltipTitle}</b>} />
              <List>
                {informationList.map((info, index) => (
                  <li key={index}>
                    <Typography variant='body2'>{info}</Typography>
                  </li>
                ))}
              </List>
            </>
          ) : (
            <Message color='inherit' variant='subtitle2' children={informationList[0]} />
          )
        }
      >
        <Icon fontSize='small' color='primary' />
      </StyledTooltip>
    </>
  )
}

export default CommonTooltip
