import React, { useState } from 'react'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { Grid, Link } from '@material-ui/core'
import BulkUploadComp from 'components/common/BulkUploadComp'
import { useSelector } from 'react-redux'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { useDispatch } from 'tests/react-redux-hooks'
import { setPPSBulkUploadDialog, setPricePostingScheduleDate } from 'ducks/pricePostingsSchedule'
import { resetBulkUpload } from 'ducks/bulkUpload'
import { exportToExcel } from 'common/helper'
import styled from 'styled-components'
import { CloudDownload } from '@material-ui/icons'
import CustomCard from 'components/common/CustomCard'
import CommonTooltip from 'components/common/CommonTooltip'
import DatePicker from 'components/common/DatePicker'

const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    width: 100%;
  }
`

const StyledCloudDownloadIcon = styled(CloudDownload)`
  margin-bottom: -5px;
  margin-right: 4px;
`

const formatColumnName = value => {
  /*----map through the headers array and replace column names----*/
  return value.includes('manufacturer')
    ? value.replace(value, 'manufacturer')
    : value === 'trade name'
    ? value.replace(value, 'tradeName')
    : value.includes('product name')
    ? value.replace(value, 'productName')
    : value.includes('package')
    ? value.replace(value, 'packageConfiguration')
    : value.includes('product size')
    ? value.replace(value, 'productSize')
    : value.includes('container type')
    ? value.replace(value, 'containerType')
    : value === 'trading area'
    ? value.replace(value, 'tradingArea')
    : value.includes('county')
    ? value.replace(value, 'county')
    : value === 'prices to'
    ? value.replace(value, 'pricesTo')
    : value.includes('receiving')
    ? value.replace(value, 'receivingMethod')
    : value === 'price($)'
    ? value.replace(value, 'price')
    : value.includes('container charge($)')
    ? value.replace(value, 'containerCharge')
    : value.includes('price promotion')
    ? value.replace(value, 'pricePromotion')
    : value.includes('is effective')
    ? value.replace(value, 'isEffectiveImmediately')
    : value.replace(/\s(.)/g, function ($$, $1) {
        return $1.toUpperCase()
      })
}

const pricePostingScheduleBulkUploadTemplate = [
  {
    Manufacturer: '',
    'Trade name': '',
    'Product Name': '',
    'Package Configuration': '',
    'Product Size': '',
    'Container Type': '',
    County: '',
    'Prices To': '',
    'Receiving Method': '',
    'Price($)': '',
    'Container Charge($)': '',
    'Is Effective Immediately(Yes/No)': '',
    'Price Promotion(Yes/No)': '',
  },
]

const validations = props => {
  return Yup.object().shape({
    scheduledDate: Yup.string().required('Required'),
  })
}

const PricePostingScheduleBulkUpload = props => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [dateError, setDateError] = useState(null)
  const dispatch = useDispatch()
  const { uploadNotReady, bulkJson, bulkFilename } = useSelector(store => store.bulkUpload)

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  minDate.setHours(0, 0, 0, 0)

  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 30)
  maxDate.setHours(0, 0, 0, 0)

  const getDateOnly = myDate => {
    const date = myDate
    const dd = date.getDate()
    const m = date.getMonth() + 1
    const y = date.getFullYear()
    return (m <= 9 ? '0' + m : m) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + y
  }

  const handleCancel = () => {
    dispatch(setPPSBulkUploadDialog(false))
    dispatch(resetBulkUpload())
  }
  const handleSubmit = (props, bulkJSON, bulkFilename) => {
    if (!selectedDate) {
      setDateError('Please selecte a date.')
      return
    }

    if (!selectedDate) {
      dispatch(setPricePostingScheduleDate(minDate.toLocaleDateString('en-us')))
    } else {
      dispatch(setPricePostingScheduleDate(new Date(selectedDate).toLocaleDateString('en-us')))
    }
    props.handleBulkUpload(bulkJSON, bulkFilename)
  }
  const handleDateChange = date => {
    if (!date) {
      return false
    }

    let selectedDate = getDateOnly(new Date(date))
    console.log('dates', selectedDate, getDateOnly(minDate), getDateOnly(maxDate))
    if (
      selectedDate &&
      new Date(selectedDate) >= new Date(getDateOnly(minDate)) &&
      new Date(selectedDate) <= new Date(getDateOnly(maxDate))
    ) {
      setSelectedDate(selectedDate)
      setDateError('')
    } else {
      setDateError('Selected date is out of range.')
    }
  }

  return (
    <Form>
      <CustomCard
        title={
          <>
            Bulk upload
            <CommonTooltip
              tooltipTitle='Price posting schedule through bulk upload:'
              informationList={[
                'The required fields are: Manufacturer, Product name, Package configuration, Product size, County, Prices to, Receiving method, Price($), Container charge($).',
                'Price and Container Charge should be numerical or $00.00 format only.',
                'Failed rows will be returned in an .xlsx file along with error messages.',
                'If an error field is received, please review, fix the error and upload the file again.',
                'Refer to template for file format.',
              ]}
            />
          </>
        }
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DatePickerField
            label='Scheduled Date'
            name='scheduledDate'
            component={DatePicker}
            disablePast={true}
            value={selectedDate}
            onChange={handleDateChange}
            placeholder='MM-DD-YYYY'
            minDate={minDate}
            maxDate={maxDate}
            helperText={dateError}
            error={dateError ? true : false}
            maxDateMessage='Scheduled date cannot exceed 30 days'
            minDateMessage='Scheduled date must be later than today'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BulkUploadComp
            validations={{
              requiredColumn: [
                'manufacturer',
                'product name',
                'package configuration',
                'product size',
                'container type',
                'county',
                'prices to',
                'receiving method',
                'price($)',
                'container charge($)',
                'pricePromotion',
              ],
              columns: [
                'manufacturer',
                'tradeName',
                'productName',
                'packageConfiguration',
                'productSize',
                'containerType',
                'tradingArea',
                'county',
                'pricesTo',
                'receivingMethod',
                'price($)',
                'containerCharge($)',
                'isEffectiveImmediately',
                'pricePromotion',
              ],
              uploadProcessName: 'Price Posting bulk upload',
            }}
            formatColumnName={formatColumnName}
          />
          <SaveAndCancelButton
            onSave={() => handleSubmit(props, bulkJson, bulkFilename)}
            onCancel={() => handleCancel()}
            submitLabel='Upload'
            isDisabled={!uploadNotReady}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Link
            component='button'
            variant='body2'
            onClick={() => {
              exportToExcel(
                pricePostingScheduleBulkUploadTemplate,
                'Price Posting Bulk Upload Template'
              )
            }}
          >
            {<StyledCloudDownloadIcon />} Download Price Posting Bulk Upload Template
          </Link>
        </Grid>
      </CustomCard>
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(PricePostingScheduleBulkUpload)

export default WithFormikWrapper
