import React from 'react'
import styled from 'styled-components'
import { Tooltip, Typography, Button } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

const DisclaimerInfo = styled(Tooltip)`
  && {
    padding: 0px;
  }
`

const List = styled.ul`
  padding-left: 20px;
`

const ButtonWithDisabledInfo = ({
  buttonDisabledShowTooltip,
  tooltipTitle,
  disabledReasons,
  onClick,
  buttonLabel,
  startIcon,
}) => (
  <DisclaimerInfo
    arrow={true}
    variant='dense'
    placement='bottom'
    disableFocusListener={!buttonDisabledShowTooltip}
    disableHoverListener={!buttonDisabledShowTooltip}
    disableTouchListener={!buttonDisabledShowTooltip}
    title={
      disabledReasons.length > 1 ? (
        <>
          <Typography
            color='inherit'
            variant='subtitle1'
            children={<b>{tooltipTitle}</b>}
          />
          <List>
            {disabledReasons.map((info, index) => (
              <li key={index}>
                <Typography variant='body2'>{info}</Typography>
              </li>
            ))}
          </List>
        </>
      ) : (
        <Typography
          color='inherit'
          variant='subtitle1'
          children={disabledReasons[0]}
        />
      )
    }
  >
    <span>
      <Button
        aria-label='status-change'
        color='primary'
        onClick={() => onClick()}
        size='medium'
        variant='contained'
        disabled={buttonDisabledShowTooltip}
        endIcon={buttonDisabledShowTooltip && <InfoOutlined color='primary' />}
        startIcon={startIcon}
      >
        {buttonLabel}
      </Button>
    </span>
  </DisclaimerInfo>
)

export default ButtonWithDisabledInfo
