import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { setLoading, setEditMode, showError, showSuccess, setNewProductAdded } from './global'
import {
  getProductsQuery,
  getProductByIDQuery,
  findProductDuplicatesQuery,
  getProductsForBulkSelection,
  getProductsForPricePostingAndExport,
} from 'graphql/queries'
import {
  createNewProduct,
  updateProductMutation,
  activateOrDeactivateProductMutation,
} from 'graphql/mutations'
import { resetBulkUpload } from './bulkUpload'
import {
  exportToExcel,
  checkIfUserIsLicensee,
  cleanUp,
  whereClauseBuilderForProducts,
  notifyLEAdmins,
} from 'common/helper'
import { pricePostingURL, abcNotificationsEmail, licenseCategoryList } from 'common/constants'
import { productNameChangeTemplate } from 'common/templates'

const productsDuplicateCheck = async (product, manufacturerName) => {
  // Add GraphQL query parameters
  let queryParams = {
    manufacturerName_lower: manufacturerName,
    name_lower: cleanUp(product.name),
  }
  let queryOrParam = {
    manufacturerId: product.manufacturer,
    name_lower: cleanUp(product.name),
  }
  if (product.id) queryParams.id_not = parseInt(product.id)
  if (typeof product.tradeName === 'string') {
    queryParams.tradeName_lower = cleanUp(product.tradeName)
    queryOrParam.tradeName_lower = cleanUp(product.tradeName)
  }
  queryParams.OR = queryOrParam
  // Check that the combination of Manufacturer & product name doesn't already exist in the system
  return await API.graphql(graphqlOperation(findProductDuplicatesQuery, { where: queryParams }))
}

const checkUserIsCOC = LicenseeDetails => {
  let userIsCOC
  if (LicenseeDetails) {
    const loggedInLicenseType = LicenseeDetails.licenseTypes.map(lt => lt.id)

    userIsCOC = loggedInLicenseType.includes('7')
  }
  return userIsCOC
}

export const deactivateProductByID = id => {
  return async (dispatch, getState) => {
    await API.graphql(
      graphqlOperation(activateOrDeactivateProductMutation, {
        where: { id: parseInt(id) },
      })
    ).then(response => {
      dispatch(
        updateProductsList({
          id,
          product: response.data.activateOrDeactivateProduct,
        })
      )
    })
  }
}

export const getProductsList = (whereClause, selectAll = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Products...'))
    const productsState = getState().products
    const currentUser = getState().auth.user
    const orderRule = `${productsState.orderBy}_${productsState.order}`
    const loggedInUserIsManufacturer =
      currentUser.legalEntity &&
      currentUser.legalEntity.licenseCategory.includes(licenseCategoryList.manufacturer)
    const loggedInUserIsLicensee =
      currentUser.legalEntity &&
      Object.values(licenseCategoryList).includes(currentUser.legalEntity.licenseCategory)

    if (whereClause) {
      // Filter and map manufacturer values from the filters
      whereClause = {
        ...whereClause,
        manufacturerId_in: whereClause.manufacturer.map(item => parseInt(item.value)),
      }

      dispatch(setProductsFilters(whereClause))
    } else {
      whereClause = {
        ...productsState.productsFilters,
        ...whereClause,
      }
    }

    if (loggedInUserIsManufacturer) {
      //if the logged in user is manufacturer, than use the list filtering radio options
      if (productsState.listType === 'filtered') {
        whereClause = {
          ...whereClause,
          manufacturerId: parseInt(currentUser.legalEntity.id),
        }
      }
    } else if (productsState.listType === 'all' && !productsState.areFiltersUsed) {
      whereClause = {
        ...whereClause,
        manufacturerId: '',
      }
    }
    // if the user is a licensee and either all products are selected or the licensee is strictly wholesaler
    if (
      loggedInUserIsLicensee &&
      (productsState.listType === 'all' || currentUser.legalEntity.licenseCategory === 'Wholesaler')
    ) {
      whereClause = {
        ...whereClause,
        status: { value: 'Active' },
      }
    }
    // Add GraphQL query parameters
    let graphQLParams = {
      orderBy: orderRule,
      where: whereClauseBuilderForProducts(whereClause),
    }

    if (selectAll) {
      await API.graphql(graphqlOperation(getProductsForBulkSelection, graphQLParams))
        .then(response => {
          dispatch(setProductBulkSelection(response.data.products.results))
        })
        .catch(errorResponse => {
          dispatch(
            showError(
              'Unable to select products, please check your internet connection and try again. Thank you.'
            )
          )
        })
    } else {
      graphQLParams = {
        ...graphQLParams,
        limit: productsState.rowsPerPage,
        offset: productsState.pageNumber * productsState.rowsPerPage,
      }
      await API.graphql(graphqlOperation(getProductsQuery, graphQLParams))
        .then(response => {
          //If there are no products returned && logged in user is COC and && listType is 'filtered'(my products)
          if (
            response.data.products.totalCount === 0 &&
            checkUserIsCOC(currentUser.legalEntity) &&
            productsState.listType === 'filtered'
          ) {
            // then set listType to 'All' so that the search products is triggered again with all products this time,
            // and set the flag COCProductsCount to '0', so that we can use it to disable radio toggle option on the products list
            dispatch(setListType('all'))
            dispatch(setCOCProductsCount(0))
          } else {
            dispatch(setProductsList(response.data.products))
          }
        })
        .catch(errorResponse => {
          dispatch(
            showError(
              'Unable to retrieve list of products, please check your internet connection and try again'
            )
          )
        })
    }
    dispatch(setLoading(false))
  }
}

// Fetch product by ID after selecting list item
export const fetchProductByID = (id, editMode = false) => {
  return async dispatch => {
    dispatch(setLoading('Loading Product...'))
    const productRes = await API.graphql(
      graphqlOperation(getProductByIDQuery, { id: parseInt(id) })
    )
    if (productRes.data.products.results[0]) {
      const productFromResponse = productRes.data.products.results[0]
      let newResponse = {}
      newResponse = {
        ...productFromResponse,
        manufacturer: productFromResponse.manufacturer,
        tradeName: productFromResponse.tradeName ? productFromResponse.tradeName : '',
      }
      dispatch(setProductDetails(newResponse))
      dispatch(setOldProductDetails(newResponse))
      dispatch(setEditMode(editMode))
      dispatch(setProductShow(true))
    } else {
      dispatch(
        showError(`Unable to retrieve product, please check your internet connection and try again`)
      )
    }
    dispatch(setLoading(false))
  }
}

export const addNewProduct = productDetails => {
  let newProduct = JSON.parse(JSON.stringify(productDetails))
  return async (dispatch, getState) => {
    dispatch(setLoading('Adding Product...'))
    const currUser = getState().auth.user
    const loggedInUserIsLeUser = checkIfUserIsLicensee(currUser.role.id)
    let product = {}
    let manufacturerName

    /* Clean up product and trade name */
    newProduct.tradeName = newProduct.tradeName.toString().replace(/\s\s+/g, ' ').trim()
    newProduct.name = newProduct.name.toString().replace(/\s\s+/g, ' ').trim()

    /*------Add the manufacturer ID, if the logged in user is one of the 
    Le-user(Main Admin, secondary admins, just user)------*/
    if (loggedInUserIsLeUser) {
      product = {
        ...newProduct,
        manufacturer: parseInt(currUser.legalEntity.id),
        userId: currUser.id,
      }
      manufacturerName = currUser.legalEntity.name
    } else {
      product = {
        ...newProduct,
        manufacturer: parseInt(newProduct.manufacturer.value),
        userId: currUser.id,
      }
      manufacturerName = newProduct.manufacturer.label
    }

    // Check that the combination of Manufacturer & product name doesn't already exist in the system
    const res = await productsDuplicateCheck(product, manufacturerName)

    if (res.data.products.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The combination of this manufacturer, product name and/or trade name already exists in the system. Please review your information and try again.'
        )
      )
    } else {
      const productRes = await API.graphql(graphqlOperation(createNewProduct(product)))
      if (productRes.data.createProduct) {
        const productFromResponse = productRes.data.createProduct
        let newResponse = {}
        newResponse = {
          ...productFromResponse,
          tradeName: productFromResponse.tradeName ? productFromResponse.tradeName : '',
        }

        // Notify LE Admins when a new product is added by ABC
        if (!loggedInUserIsLeUser) {
          notifyLEAdmins(product.manufacturer, 'PRODUCT_ADDED')
        }

        dispatch(setCOCProductsCount(''))
        dispatch(setProductDetails(newResponse))
        dispatch(setEditMode(false))
        dispatch(showSuccess('New product has been created successfully.'))
        dispatch(setNewProductAdded(true))
        dispatch(getProductsList())
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the new product, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

export const updateProduct = productDetails => {
  let updatingProduct = JSON.parse(JSON.stringify(productDetails))
  return async (dispatch, getState) => {
    dispatch(setLoading('Adding Product...'))
    if (updatingProduct.manufacturer.value) {
      updatingProduct.manufacturer.id = updatingProduct.manufacturer.value
      updatingProduct.manufacturer.name = updatingProduct.manufacturer.label
    }
    const currUser = getState().auth.user
    const { oldProductDetails } = getState().products
    let isDuplicateProduct = false
    const loggedInUserIsLeUser = checkIfUserIsLicensee(currUser.role.id)
    let product = {}

    // Add old product name and old trade name only if they are changed
    if (updatingProduct.name.toLowerCase() !== oldProductDetails.name.toLowerCase()) {
      updatingProduct = {
        ...updatingProduct,
        oldName: oldProductDetails.name,
      }
    }
    if (updatingProduct.tradeName.toLowerCase() !== oldProductDetails.tradeName.toLowerCase()) {
      updatingProduct = {
        ...updatingProduct,
        oldTradeName: oldProductDetails.tradeName,
      }
    }

    /*------Add the manufacturer ID, if the logged in user is one of the 
    Le-user(Main Admin, secondary admins, just user)------*/

    if (loggedInUserIsLeUser) {
      product = {
        ...updatingProduct,
        manufacturer: parseInt(currUser.legalEntity.id),
        userId: currUser.id,
      }
    } else {
      product = {
        ...updatingProduct,
        manufacturer: parseInt(updatingProduct.manufacturer.id),
        userId: currUser.id,
      }
    }
    // Check if any of the three values (manufacturer, product names or trade name) are changed
    if (
      product.manufacturer.toString() !== oldProductDetails.manufacturer.id ||
      product.name !== oldProductDetails.name ||
      product.tradeName !== oldProductDetails.tradeName
    ) {
      // Check that the combination of manufacturer & product name doesn't already exist in the system
      const res = await productsDuplicateCheck(product, updatingProduct.manufacturer.name)
      if (res.data.products.count > 0) {
        isDuplicateProduct = true
      }
    }

    if (isDuplicateProduct) {
      dispatch(
        showError(
          'The combination of manufacturer, product name and/or trade name already exists. Please review your information and try again.'
        )
      )
    } else {
      await API.graphql(graphqlOperation(updateProductMutation(product)))
        .then(res => {
          dispatch(setProductDetails(updatingProduct))
          dispatch(setOldProductDetails(updatingProduct))
          dispatch(setEditMode(false))
          dispatch(showSuccess('Product updated successfully.'))
          dispatch(getProductsList())
          dispatch(setNewProductAdded(true))

          // If the logged in user is a manufacturer notify ABC about the name change via email
          if (loggedInUserIsLeUser) {
            let productChangeDetails = { ...updatingProduct }
            // Remove old name changes from older transactions
            if (oldProductDetails.name.toLowerCase() === updatingProduct.name.toLowerCase())
              productChangeDetails.oldName = null
            if (
              oldProductDetails.tradeName.toLowerCase() === updatingProduct.tradeName.toLowerCase()
            )
              productChangeDetails.oldTradeName = null

            // Notify ABC via email
            const params = {
              body: {
                to: abcNotificationsEmail,
                subject: 'ABC Price Posting - Product Name Change',
                message: productNameChangeTemplate(productChangeDetails),
                buttonLink: pricePostingURL,
                buttonText: 'Visit Price Posting Application',
              },
            }
            API.post('ABC-API', '/send-email', params)
          }
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was an issue creating the new product, please review the form and submit again. Thank you.'
            )
          )
        })
    }
    dispatch(setLoading(false))
  }
}

export const productBulkUpload = (productsList, manufacturerId) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const currUser = getState().auth.user
    const loggedInUserIsLeUser = checkIfUserIsLicensee(currUser.role.id)
    const params = {
      body: {
        userId: currUser.id,
        manufacturerId: manufacturerId,
        products: productsList,
      },
    }
    await API.post('ABC-API', '/products-bulk-upload', params)
      .then(res => {
        let notifyAdmins = true
        if (res.errorMessages && res.errorMessages.length > 0) {
          // Create new file including error messages
          dispatch(
            showError(
              `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
            )
          )
          exportToExcel(res.errorMessages, 'failedProductUpload')
          // Notify admins if one or more products were added
          notifyAdmins = res.errorMessages.length < productsList.length
        } else {
          // All rows added successfully
          dispatch(setIsBulkDialogOpen(false))
          dispatch(showSuccess('Products bulk upload completed successfully.'))
          dispatch(setNewProductAdded(true))
        }
        // Notify LE Admins when products are added by ABC
        if (!loggedInUserIsLeUser && notifyAdmins) {
          notifyLEAdmins(manufacturerId, 'PRODUCT_ADDED')
        }

        dispatch(setProductBulk({ manufacturer: {} }))
        dispatch(resetBulkUpload())
        dispatch(getProductsList())
      })
      .catch(e => {
        dispatch(
          showError(
            'An issue occurred during data validation, please review your file and try again.'
          )
        )
      })

    dispatch(setLoading(false))
  }
}

export const exportForPricePosting = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Exporting products for price posting, please wait...'))

    const productsState = getState().products
    const orderRule = `${productsState.orderBy}_${productsState.order}`
    const graphQLParams = {
      where: {
        id_in: productsState.bulkSelected.map(item => parseInt(item.id)),
      },
      orderBy: orderRule,
    }
    await API.graphql(graphqlOperation(getProductsForPricePostingAndExport, graphQLParams))
      .then(response => {
        const finalProductsToExport = response.data.products.results.map(item => ({
          Manufacturer: item.manufacturer.name,
          'Trade Name': item.tradeName,
          'Product Name': item.name,
          'Package Configuration': '',
          'Product Size': '',
          'Container Type': '',
          County: '',
          'Prices To': '',
          'Receiving Method': '',
          'Price($)': '',
          'Container Charge($)': '',
          'Is Effective Immediately(Yes/No)': '',
          'Price Promotion(Yes/No)': '',
        }))
        exportToExcel(finalProductsToExport, 'List of products')
      })
      .catch(errorResponse => {
        dispatch(
          showError(
            'Unable to retrieve list  of products, please check your internet connection and try again.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setProductBulkSelection([]))
    dispatch(setCOCProductsCount(''))
    dispatch(setFiltersUsed(false))
    dispatch(setProductsPageNumber(0))
    dispatch(setProductsFilters(initialState.productsFilters))
    dispatch(getProductsList())
  }
}

export const resetProductDetails = () => {
  return dispatch => {
    dispatch(setProductDetails(initialState.productDetails))
    dispatch(setLoading(false))
  }
}

export const setProductsList = createAction('products/setProductsList')
export const updateProductsList = createAction('products/updateProductsList')
export const resetProductsList = createAction('products/resetProductsList')
export const setIsProductsFilterOpen = createAction('products/setIsProductsFilterOpen')
export const setProductsFilters = createAction('products/setProductsFilters')
export const setProductShow = createAction('products/setProductShow')
export const setProductDetails = createAction('products/setProductDetails')
export const setProductBulkSelection = createAction('products/setProductBulkSelection')
export const setOldProductDetails = createAction('products/setOldProductDetails')
export const setProductsPageNumber = createAction('products/setProductsPageNumber')
export const setProductsRowsPerPage = createAction('products/setProductsRowsPerPage')
export const setProductsRequestedSort = createAction('products/setProductsRequestedSort')
export const setActivationConfirmation = createAction('products/setActivationConfirmation')
export const setInactivationConfirmation = createAction('products/setInactivationConfirmation')
export const setIsBulkDialogOpen = createAction('products/setIsBulkDialogOpen')
export const setProductBulk = createAction('product/setProductBulk')
export const setListType = createAction('product/setListType')
export const setFiltersUsed = createAction('product/setFiltersUsed')
export const setCOCProductsCount = createAction('product/setCOCProductsCount')

const initialState = {
  productsList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  bulkSelected: [],
  isProductsFilterOpen: false,
  productsFilters: {
    manufacturer: [],
    tradeName: '',
    name: '',
    createdAt: null,
    operatorForCreated: 'equal',
    updatedAt: null,
    operatorForUpdated: 'equal',
  },
  isProductShown: false,
  productDetails: {
    manufacturer: '',
    tradeName: '',
    oldTradeName: '',
    name: '',
    oldName: '',
    createdAt: null,
    updatedAt: null,
  },
  oldProductDetails: {
    manufacturer: '',
    tradeName: '',
    oldTradeName: '',
    name: '',
    oldName: '',
  },
  productBulk: { manufacturer: {} },
  isBulkDialogOpen: false,
  activationConfirmation: false,
  inactivationConfirmation: false,
  listType: 'filtered', //my products
  areFiltersUsed: false,
  cocProductsCount: '',
}

export default createReducer(initialState, {
  [setProductsList]: (state, action) => {
    state.productsList = action.payload
  },
  [updateProductsList]: (state, action) => {
    const products = state.productsList.results
    const index = products.findIndex(product => product.id === action.payload.id)
    if (products && products.length > index) {
      const product = products[index]
      const status = action.payload.product.status
      state.productsList.results[index] = {
        ...product,
        status,
      }
    }
  },
  [resetProductsList]: state => {
    state.productsList = initialState.productsList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.isProductsFilterOpen = initialState.isProductsFilterOpen
    state.productsFilters = initialState.productsFilters
    state.isProductShown = initialState.isProductShown
    state.productDetails = initialState.productDetails
    state.oldProductDetails = initialState.oldProductDetails
    state.productBulk = initialState.productBulk
    state.isBulkDialogOpen = initialState.isBulkDialogOpen
    state.listType = initialState.listType
    state.areFiltersUsed = initialState.areFiltersUsed
    state.cocProductsCount = initialState.cocProductsCount
  },
  [setIsProductsFilterOpen]: (state, action) => {
    state.isProductsFilterOpen = action.payload
  },
  [setProductsFilters]: (state, action) => {
    state.productsFilters = action.payload
  },
  [setProductShow]: (state, action) => {
    state.isProductShown = action.payload
  },
  [setProductDetails]: (state, action) => {
    state.productDetails = action.payload
  },
  [setOldProductDetails]: (state, action) => {
    state.oldProductDetails = action.payload
  },
  [setProductBulkSelection]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setProductsPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setProductsRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setProductsRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setIsBulkDialogOpen]: (state, action) => {
    state.isBulkDialogOpen = action.payload
  },
  [setProductBulk]: (state, action) => {
    state.productBulk = action.payload
  },
  [setListType]: (state, action) => {
    state.listType = action.payload
  },
  [setFiltersUsed]: (state, action) => {
    state.areFiltersUsed = action.payload
  },
  [setCOCProductsCount]: (state, action) => {
    state.cocProductsCount = action.payload
  },
  [setInactivationConfirmation]: (state, action) => {
    state.inactivationConfirmation = action.payload
  },
  [setActivationConfirmation]: (state, action) => {
    state.activationConfirmation = action.payload
  },
})
