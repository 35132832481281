import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  setLoading,
  showError,
  showSuccess,
  setNewProductSizeAdded,
  setNewPackageConfigAdded,
} from './global'
import {
  getConfigRequestsQuery,
  getConfigRequestByIDQuery,
  findProductSizeDuplicatesQuery,
  findPackagesDuplicatesQuery,
} from 'graphql/queries'
import {
  createConfigRequestMutation,
  updateConfigRequestMutation,
  deleteConfigRequestMutation,
  createProductSizeMutation,
  createPackageMutation,
} from 'graphql/mutations'
import {
  cleanUp,
  notifyLEAdmins,
  getStartOfDay,
  getEndOfDay,
} from 'common/helper'
import { pricePostingURL } from 'common/constants'
import {
  configurationRequestedTemplate,
  configurationReviewedTemplate,
} from 'common/templates'

export const getConfigurationRequests = whereClause => {
  return async (dispatch, getState) => {
    const configRequestsState = getState().configurationRequests
    const orderRule = `${configRequestsState.orderBy}_${configRequestsState.order}`
    dispatch(setLoading('Loading configuration requests...'))
    // Add GraphQL query parameters
    let graphQLParams = {
      orderBy: orderRule,
      limit: configRequestsState.rowsPerPage,
      offset: configRequestsState.pageNumber * configRequestsState.rowsPerPage,
    }
    let filterWhereClause = null

    if (whereClause) {
      filterWhereClause = {
        ...whereClause,
      }
      dispatch(setConfigurationRequestFilters(filterWhereClause))
    } else {
      filterWhereClause = configRequestsState.configurationRequestFilters
    }

    if (filterWhereClause) {
      let whereParams = {}
      if (filterWhereClause.type) {
        whereParams.type_in = filterWhereClause.type.map(t => t.label)
      }
      if (filterWhereClause.request) {
        whereParams.request_like = cleanUp(filterWhereClause.request)
      }
      if (filterWhereClause.status && filterWhereClause.status.length > 0) {
        whereParams.status_in = filterWhereClause.status.map(s => s.label)
      }
      if (filterWhereClause.createdAtFrom && filterWhereClause.createdAtTo) {
        whereParams.createdAt_gte = getStartOfDay(
          filterWhereClause.createdAtFrom
        )
        whereParams.createdAt_lte = getEndOfDay(filterWhereClause.createdAtTo)
      }
      if (filterWhereClause.reviewedAtFrom && filterWhereClause.reviewedAtTo) {
        whereParams.reviewedAt_gte = getStartOfDay(
          filterWhereClause.reviewedAtFrom
        )
        whereParams.reviewedAt_lte = getEndOfDay(filterWhereClause.reviewedAtTo)
      }

      graphQLParams = { ...graphQLParams, where: whereParams }
    }

    await API.graphql(graphqlOperation(getConfigRequestsQuery, graphQLParams))
      .then(response => {
        dispatch(setConfigurationRequestList(response.data.configRequests))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of configuration requests, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const fetchConfigByID = id => {
  return async dispatch => {
    dispatch(setLoading('Loading request details...'))
    const configRequestRes = await API.graphql(
      graphqlOperation(getConfigRequestByIDQuery, { id: parseInt(id) })
    )
    if (configRequestRes.data.configRequests.results[0]) {
      dispatch(
        setConfigurationRequest(configRequestRes.data.configRequests.results[0])
      )
      dispatch(setConfigurationRequestShown(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve configuration request, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const setConfigRequestStatus = (config, status) => {
  let updatedConfig = JSON.parse(JSON.stringify(config))
  return async (dispatch, getState) => {
    const currentUser = getState().auth.user

    dispatch(
      setLoading(
        `${
          status === 'Approve' ? 'Approving' : 'Denying'
        } selected configuration...`
      )
    )

    const graphQLParamsForUpdate = {
      data: {
        type: updatedConfig.type,
        typeId: parseInt(updatedConfig.typeId),
        status: status === 'Approve' ? 'Approved' : 'Denied',
        reviewedBy: parseInt(currentUser.id),
      },
      where: { id: parseInt(updatedConfig.id) },
    }

    await API.graphql(
      graphqlOperation(updateConfigRequestMutation, graphQLParamsForUpdate)
    )
      .then(async _ => {
        updatedConfig.status = graphQLParamsForUpdate.data.status
        updatedConfig.reviewedBy = currentUser
        updatedConfig.reviewedAt = new Date().getTime()
        dispatch(setConfigurationRequest(updatedConfig))
        dispatch(
          showSuccess(
            `${config.type} has been ${updatedConfig.status.toLowerCase()}.`
          )
        )
        // Delete request if approved
        if (status === 'Approve') {
          await API.graphql(
            graphqlOperation(deleteConfigRequestMutation, {
              where: { id: parseInt(updatedConfig.id) },
            })
          ).catch(_ => {
            console.log('error when trying to delete config request record', _)
          })
        }

        dispatch(getConfigurationRequests())
        if (config.type === 'Product Size')
          dispatch(setNewProductSizeAdded(true))
        else if (config.type === 'Package Configuration')
          dispatch(setNewPackageConfigAdded(true))

        // If licensee is not part of the current data, retrieve it directly from configuration request
        let licenseeId = -1,
          licenseeUserEmail = ''
        if (!updatedConfig.createdByLicensee) {
          const configRequestRes = await API.graphql(
            graphqlOperation(getConfigRequestByIDQuery, {
              id: parseInt(updatedConfig.id),
            })
          )
          const configRequestResult =
            configRequestRes.data.configRequests.results[0]
          if (configRequestResult) {
            licenseeId = configRequestResult.createdByLicensee.id
            licenseeUserEmail = configRequestResult.createdBy.email
          }
        } else {
          licenseeId = updatedConfig.createdByLicensee.id
          licenseeUserEmail = updatedConfig.createdBy.email
        }
        notifyLEAdmins(
          licenseeId,
          'CUSTOM',
          `Regarding ${config.type} Request`,
          configurationReviewedTemplate(updatedConfig.status.toLowerCase()),
          licenseeUserEmail
        )
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was an issue updating the product size, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setConfigurationRequestPageNumber(0))
    dispatch(
      setConfigurationRequestFilters(initialState.configurationRequestFilters)
    )
    dispatch(getConfigurationRequests())
  }
}

export const resetConfigRequestDetails = () => {
  return dispatch => {
    dispatch(setConfigurationRequest(initialState.configurationRequest))
    dispatch(setConfigurationRequestShown(false))
    dispatch(setLoading(false))
  }
}

const notifyABC = (subject, licenseeName) => {
  const params = {
    body: {
      to: 'PPS@abc.ca.gov',
      subject: `ABC Price Posting - ${subject}`,
      message: configurationRequestedTemplate(licenseeName),
      buttonLink: pricePostingURL,
      buttonText: 'Visit Price Posting Application',
    },
  }
  API.post('ABC-API', '/send-email', params)
}

export const productSizeRequestedByLicensee = productSizeDetails => {
  let newProductSize = JSON.parse(JSON.stringify(productSizeDetails))
  return async (dispatch, getState) => {
    dispatch(setLoading('Validating product size...'))
    const currentUser = getState().auth.user

    let graphQLDataObject = {
      size: newProductSize.size,
      unit: newProductSize.unit,
      containerType: newProductSize.containerType,
    }

    // Add GraphQL query parameters
    let graphQLParams = {
      where: graphQLDataObject,
    }

    // Check that the product size doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findProductSizeDuplicatesQuery, graphQLParams)
    )
    if (res.data.productSizes.count > 0) {
      dispatch(
        showError(
          'The product size you requested already exists or has already been requested. Please review your information and try again.'
        )
      )
    } else {
      // Add 'Pending' product size and configuration request
      graphQLDataObject.status = 'Pending'

      const productSizeRes = await API.graphql(
        graphqlOperation(createProductSizeMutation, { data: graphQLDataObject })
      )
      const productSizeData = productSizeRes.data.createProductSize
      if (productSizeData) {
        // Add configuration request of type 'Product Size'
        graphQLDataObject = {
          type: 'Product Size',
          typeId: productSizeData.id,
          request: `${graphQLDataObject.size} ${productSizeData.unit.unit} ${productSizeData.containerType.type}`,
          status: 'Pending',
          createdBy: parseInt(currentUser.id),
          createdByLicensee: parseInt(currentUser.legalEntity.id),
        }
        const configRequestRes = await API.graphql(
          graphqlOperation(createConfigRequestMutation, {
            data: graphQLDataObject,
          })
        )
        if (configRequestRes.data.createConfigRequest) {
          dispatch(toggleConfigReqDialog(false))
          // Email ABC biz admins about new request
          notifyABC('Size Request Submitted', currentUser.legalEntity.name)
          dispatch(
            showSuccess(
              `The new product size request has been sent to ABC. You will receive an email once it has been reviewed.`
            )
          )
        } else {
          dispatch(
            showError(
              'There was an issue creating the request, please review the form and submit again. Thank you.'
            )
          )
        }
      } else {
        dispatch(
          showError(
            'There was an issue creating the product size, please review the form and submit again. Thank you.'
          )
        )
      }
    }
    dispatch(setLoading(false))
  }
}

export const packageConfigRequestedByLicensee = packageConfigurationDetails => {
  let newPackageConfiguration = JSON.parse(
    JSON.stringify(packageConfigurationDetails)
  )
  return async (dispatch, getState) => {
    dispatch(setLoading('Validating package configuration...'))
    const currentUser = getState().auth.user

    const graphQlParams = {
      where: {
        package_lower: cleanUp(newPackageConfiguration.package),
      },
    }

    // Check that the package configuration doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findPackagesDuplicatesQuery, graphQlParams)
    )
    if (res.data.packages.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The package configuration you requested already exists or has already been requested. Please review your information and try again.'
        )
      )
    } else {
      const graphQlParamsForCreation = {
        data: {
          package: cleanUp(newPackageConfiguration.package),
          status: 'Pending',
        },
      }
      const packageRes = await API.graphql(
        graphqlOperation(createPackageMutation, graphQlParamsForCreation)
      )
      if (packageRes.data.createPackage) {
        // Add configuration request of type 'Package Configuration'
        const graphQLDataObject = {
          type: 'Package Configuration',
          typeId: packageRes.data.createPackage.id,
          request: `${graphQlParamsForCreation.data.package}`,
          status: 'Pending',
          createdBy: parseInt(currentUser.id),
          createdByLicensee: parseInt(currentUser.legalEntity.id),
        }
        const configRequestRes = await API.graphql(
          graphqlOperation(createConfigRequestMutation, {
            data: graphQLDataObject,
          })
        )
        if (configRequestRes.data.createConfigRequest) {
          dispatch(toggleConfigReqDialog(false))
          // Email ABC biz admins about new request
          notifyABC(
            'Configuration Request Submitted',
            currentUser.legalEntity.name
          )
          dispatch(
            showSuccess(
              `The new package configuration request has been sent to ABC. You will receive an email once it has been reviewed.`
            )
          )
        } else {
          dispatch(
            showError(
              'There was an issue creating the request, please review the form and submit again. Thank you.'
            )
          )
        }
      } else {
        dispatch(
          showError(
            'There was an issue creating the package configuration, please review the form and submit again. Thank you.'
          )
        )
      }
    }
    dispatch(setLoading(false))
  }
}

export const setConfigurationRequestList = createAction(
  'configurationRequests/setConfigurationRequestList'
)
export const setConfigurationRequestPageNumber = createAction(
  'configurationRequests/setConfigurationRequestPageNumber'
)
export const setConfigurationRequestRowsPerPage = createAction(
  'configurationRequests/setConfigurationRequestRowsPerPage'
)
export const setConfigurationRequestSort = createAction(
  'configurationRequests/setConfigurationRequestSort'
)
export const setConfigurationRequestFilters = createAction(
  'configurationRequests/setConfigurationRequestFilters'
)
export const setConfigurationRequestShown = createAction(
  'configurationRequests/setConfigurationRequestShown'
)
export const setDeactivationConfirmation = createAction(
  'configurationRequests/setDeactivationConfirmation'
)
export const setActivationConfirmation = createAction(
  'configurationRequests/setActivationConfirmation'
)
export const setConfigurationRequest = createAction(
  'configurationRequests/setConfigurationRequest'
)
export const setConfigurationRequestFilterOpen = createAction(
  'configurationRequests/setConfigurationRequestFilterOpen'
)
export const resetConfigurationRequest = createAction(
  'configurationRequests/resetConfigurationRequest'
)
export const toggleConfigReqDialog = createAction(
  'configurationRequests/toggleConfigReqDialog'
)

const initialState = {
  configurationRequestList: {
    results: [],
    count: 0,
    totalCount: 0,
    firstLoad: true,
  },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  configurationRequest: {
    type: '',
    request: '',
    createdAt: null,
    reviewedAt: null,
    status: '',
    createdBy: '',
    reviewedBy: '',
    createdByLicensee: '',
    email: '',
  },
  isConfigurationRequestShown: false,
  isConfigurationRequestFilterOpen: false,
  configurationRequestFilters: {
    type: '',
    request: '',
    createdAt: null,
    operatorForCreated: 'equal',
    operatorForReviewed: 'equal',
    reviewedAt: null,
    status: [],
  },

  activationConfirmation: false,
  deactivationConfirmation: false,
  isReqConfigDialogOpen: false,
}

export default createReducer(initialState, {
  [setConfigurationRequestList]: (state, action) => {
    state.configurationRequestList = action.payload
  },
  [setConfigurationRequestPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setConfigurationRequestRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setConfigurationRequestSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setConfigurationRequestFilterOpen]: (state, action) => {
    state.isConfigurationRequestFilterOpen = action.payload
  },
  [setConfigurationRequestFilters]: (state, action) => {
    state.configurationRequestFilters = action.payload
  },
  [setConfigurationRequestShown]: (state, action) => {
    state.isConfigurationRequestShown = action.payload
  },
  [setActivationConfirmation]: (state, action) => {
    state.activationConfirmation = action.payload
  },
  [setDeactivationConfirmation]: (state, action) => {
    state.deactivationConfirmation = action.payload
  },
  [setConfigurationRequest]: (state, action) => {
    state.configurationRequest = action.payload
  },
  [toggleConfigReqDialog]: (state, action) => {
    state.isReqConfigDialogOpen = action.payload
  },
  [resetConfigurationRequest]: state => {
    state.configurationRequest = initialState.configurationRequest
    state.isConfigurationRequestShown = initialState.isConfigurationRequestShown
    state.configurationRequestFilters = initialState.configurationRequestFilters
    state.configurationRequestList = initialState.configurationRequestList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.isConfigurationRequestFilterOpen =
      initialState.isConfigurationRequestFilterOpen
    state.activationConfirmation = initialState.activationConfirmation
    state.deactivationConfirmation = initialState.deactivationConfirmation
    state.isReqConfigDialogOpen = initialState.isReqConfigDialogOpen
  },
})
