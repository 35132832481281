import React, { useEffect } from 'react'
import { Grid, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { getLicenseTypes, setEditMode } from 'ducks/global'
import {
  addLegalEntity,
  updateLegalEntity,
  setLeAccountShown,
  resetLegalEntityDetails,
} from 'ducks/legalEntity'
import { countries, usStates } from 'common/constants'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { TextField } from 'formik-material-ui'
import { Can } from 'common/abilityContext'
import CommonTooltip from 'components/common/CommonTooltip'

const CheckboxWrapper = styled(FormControlLabel)`
  && {
    padding: 0;
    margin-right: 0;
  }
`

const CommonEditComp = ({ label, name, autoCompName, required }) => {
  const gridSize = label.indexOf('Address') !== -1 ? 6 : 3
  return (
    <Grid item xs={12} sm={12} md={gridSize} lg={gridSize}>
      <Field
        variant='outlined'
        margin='normal'
        required={required}
        fullWidth
        label={label}
        name={name}
        autoComplete={autoCompName}
        component={TextField}
      />
    </Grid>
  )
}

const Title = styled(Typography)`
  && {
    color: ${props => props.theme.dark};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
`
const validations = props => {
  return Yup.object().shape({
    legalEntity: Yup.object().shape({
      name: Yup.string().required('Required'),
      licensed: Yup.bool(),
      email: Yup.string().email('Please enter a valid email'),
      isMailingSameAsPremises: Yup.bool(),
      premisesAddress: Yup.object()
        .nullable({ isNullable: true })
        .when('licensed', {
          is: true,
          then: Yup.object().shape({
            address: Yup.string().required('Required'),
            address2: Yup.string(),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zipcode: Yup.string()
              .max(20, 'Zip Code must be at most 20 characters')
              .required('Required'),
            country: Yup.string().required('Required'),
          }),
        }),
      licenseTypes: Yup.array().when('licensed', {
        is: true,
        then: Yup.array().required('Required'),
      }),
      mailingAddress: Yup.object()
        .nullable({ isNullable: true })
        .when('isMailingSameAsPremises', {
          is: false,
          then: Yup.object().shape({
            address: Yup.string().required('Required'),
            address2: Yup.string(),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zipcode: Yup.string()
              .max(20, 'Zip Code must be at most 20 characters')
              .required('Required'),
            country: Yup.string().required('Required'),
          }),
        }),
      inLitigation: Yup.bool(),
    }),
  })
}

const EditLegalEntity = () => {
  const dispatch = useDispatch()
  const { licenseTypes, isLoading } = useSelector(store => store.global)

  // Reset authentication variables to avoid using values set out of context
  useEffect(() => {
    dispatch(getLicenseTypes())
  }, [dispatch])
  const onAddLegalEntity = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      if (formikValues.values.legalEntity.id) {
        dispatch(updateLegalEntity(formikValues.values.legalEntity))
      } else {
        dispatch(addLegalEntity(formikValues.values.legalEntity))
      }
    }
  }

  return (
    <Form>
      <Field
        name='legalEntity'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.legalEntity = JSON.parse(
            JSON.stringify(formikProps.form.values.legalEntity)
          )
          const formikValues = formikProps.form.values
          const handleCancel = () => {
            if (!formikValues.legalEntity.id) {
              dispatch(setLeAccountShown(false))
              dispatch(resetLegalEntityDetails())
            }
            dispatch(setEditMode(false))
          }

          const handleAutoCompleteChange = (event, parentType, elementType) => {
            if (elementType === 'country') {
              formikProps.form.setFieldValue(
                `legalEntity.${parentType}.state`,
                ''
              )
            }
            formikProps.form.setFieldValue(
              `legalEntity.${parentType}.${elementType}`,
              event.label
            )
          }
          const handleLicenseTypeChange = (field, event) => {
            formikProps.form.setFieldValue(`legalEntity.${field}`, event)
          }

          const handleLicensedCheckbox = () => {
            if (!formikValues.legalEntity.premisesAddress) {
              const addressObj = {
                address: '',
                address2: '',
                city: '',
                state: '',
                zipcode: '',
                country: 'United States',
              }
              formikProps.form.setFieldValue(
                'legalEntity.isMailingSameAsPremises',
                true
              )
              formikProps.form.setFieldValue(
                'legalEntity.premisesAddress',
                addressObj
              )
              formikProps.form.setFieldValue(
                'legalEntity.mailingAddress',
                addressObj
              )
            }
            formikProps.form.setFieldValue(
              'legalEntity.licensed',
              !formikValues.legalEntity.licensed
            )
          }

          return (
            <>
              {isLoading || licenseTypes.length === 0 ? (
                <LoadingIndicator
                  text={`Loading ${
                    isLoading ? 'Licensee' : 'License Types'
                  }...`}
                  transparent
                />
              ) : (
                <>
                  <CustomCard
                    titleContent={
                      <>
                        <Title variant='h6' children='Licensee' />
                        <CheckboxWrapper
                          control={
                            <Field
                              onChange={() => {
                                handleLicensedCheckbox()
                              }}
                              name='legalEntity.licensed'
                              component={Checkbox}
                              type='checkbox'
                              checked={formikValues.legalEntity.licensed}
                            />
                          }
                          label='Licensed'
                        />
                      </>
                    }
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formikValues.legalEntity.licensed ? 4 : 6}
                      lg={formikValues.legalEntity.licensed ? 4 : 6}
                    >
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Licensee Name'
                        name='legalEntity.name'
                        component={TextField}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formikValues.legalEntity.licensed ? 4 : 6}
                      lg={formikValues.legalEntity.licensed ? 4 : 6}
                    >
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Email'
                        name='legalEntity.email'
                        autoComplete='email'
                        component={TextField}
                      />
                    </Grid>
                    {formikValues.legalEntity.licensed && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field
                          placeHolderText='License Types *'
                          name='legalEntity.licenseTypes'
                          selectedValue={formikValues.legalEntity.licenseTypes}
                          formikProps={formikProps.form}
                          dropDownOptions={licenseTypes}
                          onValueChange={event =>
                            handleLicenseTypeChange('licenseTypes', event)
                          }
                          isMulti={true}
                          component={AutoCompleteComp}
                        />
                      </Grid>
                    )}
                  </CustomCard>
                  {formikValues.legalEntity.licensed && (
                    <CustomCard
                      titleContent={
                        <>
                          <Title variant='h6' children='Premises Address' />{' '}
                          <CheckboxWrapper
                            control={
                              <Field
                                onChange={() =>
                                  formikProps.form.setFieldValue(
                                    'legalEntity.isMailingSameAsPremises',
                                    !formikValues.legalEntity
                                      .isMailingSameAsPremises
                                  )
                                }
                                name='legalEntity.isMailingSameAsPremises'
                                component={Checkbox}
                                type='checkbox'
                                checked={
                                  formikValues.legalEntity
                                    .isMailingSameAsPremises
                                }
                              />
                            }
                            label='Mailing address is the same as premises address.'
                          />
                        </>
                      }
                    >
                      <CommonEditComp
                        label='Address Line 1'
                        name='legalEntity.premisesAddress.address'
                        autoCompName='address-line1'
                        required={true}
                      />
                      <CommonEditComp
                        label='Address Line 2'
                        name='legalEntity.premisesAddress.address2'
                        autoCompName='address-line2'
                      />
                      <CommonEditComp
                        label='City'
                        name='legalEntity.premisesAddress.city'
                        autoCompName='address-level2'
                        required={true}
                      />
                      <Grid item xs={12} sm={12} md={3}>
                        <Field
                          placeHolderText='Country *'
                          selectedValue={
                            formikValues.legalEntity.premisesAddress
                              .country && {
                              label:
                                formikValues.legalEntity.premisesAddress
                                  .country,
                              value:
                                formikValues.legalEntity.premisesAddress
                                  .country,
                            }
                          }
                          name='legalEntity.premisesAddress.country'
                          formikProps={formikProps.form}
                          dropDownOptions={countries}
                          onValueChange={event =>
                            handleAutoCompleteChange(
                              event,
                              'premisesAddress',
                              'country'
                            )
                          }
                          component={AutoCompleteComp}
                        />
                      </Grid>
                      {formikValues.legalEntity.premisesAddress.country ===
                      'United States' ? (
                        <Grid item xs={12} sm={12} md={3}>
                          <Field
                            placeHolderText='State *'
                            selectedValue={
                              formikValues.legalEntity.premisesAddress
                                .state && {
                                label:
                                  formikValues.legalEntity.premisesAddress
                                    .state,
                                value:
                                  formikValues.legalEntity.premisesAddress
                                    .state,
                              }
                            }
                            name='legalEntity.premisesAddress.state'
                            formikProps={formikProps.form}
                            dropDownOptions={usStates}
                            onValueChange={event =>
                              handleAutoCompleteChange(
                                event,
                                'premisesAddress',
                                'state'
                              )
                            }
                            component={AutoCompleteComp}
                          />
                        </Grid>
                      ) : (
                        <CommonEditComp
                          label='State'
                          name='legalEntity.premisesAddress.state'
                          autoCompName='address-level1'
                          required={true}
                        />
                      )}
                      <CommonEditComp
                        label='Zip Code'
                        name='legalEntity.premisesAddress.zipcode'
                        autoCompName='postal-code'
                        required={true}
                      />
                    </CustomCard>
                  )}
                  {formikValues.legalEntity.licensed &&
                    !formikValues.legalEntity.isMailingSameAsPremises && (
                      <CustomCard title={'Mailing Address'}>
                        <CommonEditComp
                          label='Address Line 1'
                          name='legalEntity.mailingAddress.address'
                          autoCompName='address-line1'
                          required={true}
                        />
                        <CommonEditComp
                          label='Address Line 2'
                          name='legalEntity.mailingAddress.address2'
                          autoCompName='address-line2'
                        />
                        <CommonEditComp
                          label='City'
                          name='legalEntity.mailingAddress.city'
                          autoCompName='address-level2'
                          required={true}
                        />
                        <Grid item xs={12} sm={12} md={3}>
                          <Field
                            placeHolderText='Country *'
                            selectedValue={
                              formikValues.legalEntity.mailingAddress
                                .country && {
                                label:
                                  formikValues.legalEntity.mailingAddress
                                    .country,
                                value:
                                  formikValues.legalEntity.mailingAddress
                                    .country,
                              }
                            }
                            name='legalEntity.mailingAddress.country'
                            formikProps={formikProps.form}
                            dropDownOptions={countries}
                            onValueChange={event =>
                              handleAutoCompleteChange(
                                event,
                                'mailingAddress',
                                'country'
                              )
                            }
                            component={AutoCompleteComp}
                          />
                        </Grid>
                        {formikValues.legalEntity.mailingAddress.country ===
                        'United States' ? (
                          <Grid item xs={12} sm={12} md={3}>
                            <Field
                              placeHolderText='State *'
                              selectedValue={
                                formikValues.legalEntity.mailingAddress
                                  .state && {
                                  label:
                                    formikValues.legalEntity.mailingAddress
                                      .state,
                                  value:
                                    formikValues.legalEntity.mailingAddress
                                      .state,
                                }
                              }
                              name='legalEntity.mailingAddress.state'
                              formikProps={formikProps.form}
                              dropDownOptions={usStates}
                              onValueChange={event =>
                                handleAutoCompleteChange(
                                  event,
                                  'mailingAddress',
                                  'state'
                                )
                              }
                              component={AutoCompleteComp}
                            />
                          </Grid>
                        ) : (
                          <CommonEditComp
                            label='State'
                            name='legalEntity.mailingAddress.state'
                            autoCompName='address-level1'
                            required={true}
                          />
                        )}
                        <CommonEditComp
                          label='Zip Code'
                          name='legalEntity.mailingAddress.zipcode'
                          autoCompName='postal-code'
                          required={true}
                        />
                      </CustomCard>
                    )}
                  <Can I='select' this='litigation'>
                    {() => (
                      <>
                        <CheckboxWrapper
                          control={
                            <Field
                              onChange={() =>
                                formikProps.form.setFieldValue(
                                  'legalEntity.inLitigation',
                                  !formikValues.legalEntity.inLitigation
                                )
                              }
                              name='legalEntity.inLitigation'
                              component={Checkbox}
                              type='checkbox'
                              checked={formikValues.legalEntity.inLitigation}
                            />
                          }
                          label='In litigation'
                        />
                        <CommonTooltip
                          tooltipTitle='Litigation Rules:'
                          informationList={[
                            'If the litigation option is selected, old price postings of this licensee that reach the three year purge period will not be removed.',
                            'In litigation Licensee Admin/User will be able to see the old price postings of this licensee even after 3 years.',
                            'If litigation option is not selected, all old price postings of this licensee that meet the three year purge window will be removed.',
                          ]}
                        />
                      </>
                    )}
                  </Can>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddLegalEntity(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.legalEntity.id && 'Update'}
                    isDisabled={
                      formikValues.legalEntity.id && !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: props => validations(props),
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditLegalEntity)

export default WithFormikWrapper
