import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import styled from 'styled-components'
import { Avatar, Typography, Button } from '@material-ui/core'
import requestSettings from 'assets/requestSettings.png'
import { Block, Add } from '@material-ui/icons'
import { setConfigRequestStatus } from 'ducks/configurationRequests'
import DataGrid from 'components/common/DataGrid'
import { format } from 'date-fns'

const ConfigIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 60px;
    height: 60px;
  }
`

const DenyButton = styled(Button)`
  && {
    background-color: ${props => props.theme.red};
    color: #fff;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

const ApproveButton = styled(Button)`
  && {
    background-color: ${props => props.theme.green};
    color: #fff;
    margin-top: 5px;
    margin-right: 5px;
  }
`

const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewConfigurationRequest = () => {
  const dispatch = useDispatch()
  const { configurationRequest } = useSelector(
    store => store.configurationRequests
  )
  const { isAuxLoading, loadingAuxText } = useSelector(store => store.global)
  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={<ConfigIcon src={requestSettings} />}
          profileTitle={
            <>
              <TitleLabel
                children={`${configurationRequest.type} Request`}
                variant='body2'
              />
              <TitleValue
                children={configurationRequest.request}
                variant='h6'
              />
            </>
          }
          profileAction={
            configurationRequest.status === 'Pending' && (
              <>
                <ApproveButton
                  variant='contained'
                  onClick={() => {
                    dispatch(
                      setConfigRequestStatus(configurationRequest, 'Approve')
                    )
                  }}
                  startIcon={<Add />}
                >
                  Approve
                </ApproveButton>
                <DenyButton
                  variant='contained'
                  onClick={() => {
                    dispatch(
                      setConfigRequestStatus(configurationRequest, 'Deny')
                    )
                  }}
                  startIcon={<Block />}
                >
                  Deny
                </DenyButton>
              </>
            )
          }
          divider
        >
          <DataGrid label='Type' value={configurationRequest.type} />
          <DataGrid
            label='Submitted By'
            value={
              configurationRequest.createdBy &&
              `${configurationRequest.createdBy.firstName} ${configurationRequest.createdBy.lastName}`
            }
          />
          <DataGrid
            label='Submitted By Licensee'
            value={
              configurationRequest.createdByLicensee &&
              configurationRequest.createdByLicensee.name
            }
          />
          <DataGrid label='Status' value={configurationRequest.status} />
          <DataGrid
            label='Submitted By Email'
            value={
              configurationRequest.createdBy &&
              configurationRequest.createdBy.email
            }
          />
          <DataGrid
            label='Submitted Date'
            value={
              configurationRequest.createdAt
                ? format(configurationRequest.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Reviewed By'
            value={
              configurationRequest.reviewedBy &&
              `${configurationRequest.reviewedBy.firstName} ${configurationRequest.reviewedBy.lastName}`
            }
          />
          <DataGrid
            label='Review Date'
            value={
              configurationRequest.reviewedAt
                ? format(configurationRequest.reviewedAt, 'MM/dd/yyyy')
                : ''
            }
          />
        </CustomCard>
      )}
    </>
  )
}

export default ViewConfigurationRequest
