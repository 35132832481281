import React from 'react'
import { Grid, Link } from '@material-ui/core'
import BulkUploadComp from 'components/common/BulkUploadComp'
import { useSelector } from 'react-redux'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { useDispatch } from 'tests/react-redux-hooks'
import { licenseeBulkUploadProcess } from 'ducks/legalEntity'
import { exportToExcel } from 'common/helper'
import styled from 'styled-components'
import { CloudDownload } from '@material-ui/icons'
import CustomCard from 'components/common/CustomCard'
import CommonTooltip from 'components/common/CommonTooltip'
import { setLicenseeBulkUploadModal } from 'ducks/legalEntity'

const StyledCloudDownloadIcon = styled(CloudDownload)`
  margin-bottom: -5px;
  margin-right: 4px;
`

const formatColumnName = value => {
  /*----map through the headers array and replace column names----*/
  return value === 'license name'
    ? value.replace(value, 'licenseName')
    : value === 'license type'
    ? value.replace(value, 'licenseType')
    : value === 'premises address'
    ? value.replace(value, 'premisesAddress')
    : value === 'premises city'
    ? value.replace(value, 'premisesCity')
    : value === 'premises state'
    ? value.replace(value, 'premisesState')
    : value === 'premises country'
    ? value.replace(value, 'premisesCountry')
    : value === 'premises zip'
    ? value.replace(value, 'premisesZip')
    : value === 'mailing address'
    ? value.replace(value, 'mailingAddress')
    : value === 'mailing city'
    ? value.replace(value, 'mailingCity')
    : value === 'mailing state'
    ? value.replace(value, 'mailingState')
    : value === 'mailing country'
    ? value.replace(value, 'mailingCountry')
    : value === 'mailing zip'
    ? value.replace(value, 'mailingZipCode')
    : value.replace(/\s(.)/g, function($$, $1) {
        return $1.toUpperCase()
      })
}

const licenseeBulkUploadTemplate = [
  {
    'Licensee Name': '',
    Email: '',
    'License Type': '',
    'Premises Address': '',
    'Premises City': '',
    'Premises State': '',
    'Premises Country': '',
    'Premises Zip Code': '',
    'Mailing Address': '',
    'Mailing City': '',
    'Mailing State': '',
    'Mailing Country': '',
    'Mailing Zip Code': '',
  },
]

const LicenseeBulkUpload = () => {
  const dispatch = useDispatch()
  const { uploadNotReady, bulkJson } = useSelector(store => store.bulkUpload)

  const handleCancel = () => {
    dispatch(setLicenseeBulkUploadModal(false))
  }
  return (
    <CustomCard
      title={
        <>
          Bulk upload
          <CommonTooltip
            tooltipTitle='Licensee bulk upload:'
            informationList={[
              'The required fields are: Licensee Name, Email, License Type, Premises Address, Premises City, Premises State, Premises Country & Premises Zip.',
              'If you enter information in any of the mailing address fields, then all mailing address fields will be required.',
              'Failed rows will be returned in an .xlsx file along with error messages.',
              'If an error field is received, please review, fix the error and upload the file again.',
              'Refer to template for file format.',
            ]}
          />
        </>
      }
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BulkUploadComp
          validations={{
            requiredColumn: [
              'licensee name',
              'email',
              'license type',
              'premises address',
              'premises city',
              'premises state',
              'premises country',
              'premises zip code',
            ],
            columns: [
              'licensee name',
              'email',
              'license type',
              'premises address',
              'premises city',
              'premises state',
              'premises country',
              'premises zip code',
              'mailing address',
              'mailing city',
              'mailing state',
              'mailing country',
              'mailing zip code',
            ],
            uploadProcessName: 'Licensee bulk upload',
          }}
          formatColumnName={formatColumnName}
        />
        <SaveAndCancelButton
          onSave={() => dispatch(licenseeBulkUploadProcess(bulkJson))}
          onCancel={() => handleCancel()}
          submitLabel='Upload'
          isDisabled={!uploadNotReady}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            exportToExcel(
              licenseeBulkUploadTemplate,
              'Licensee Bulk Upload Template'
            )
          }}
        >
          {<StyledCloudDownloadIcon />} Download Licensee Bulk Upload Template
        </Link>
      </Grid>
    </CustomCard>
  )
}

export default LicenseeBulkUpload
