import React from 'react'
import { Typography } from '@material-ui/core'
import banner_background from 'assets/banner_background.jpg'
import banner_foreground from 'assets/banner_foreground.png'
import styled from 'styled-components'

const BannerContainer = styled.div`
  background: #046b99 url(${banner_background}) 0 100% / cover no-repeat;
  height: 330px;
  margin: 58px 0 -116px;
  padding: 0 24px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: right;
  width: 100%;
  && img {
    bottom: 0;
    height: 310px;
    left: 57vw;
    position: absolute;
  }
  @media (max-width: 980px) {
    height: 300px;
    margin-bottom: -90px;
    && img {
      height: auto;
      left: 59vw;
      width: 220px;
    }
  }
  @media (max-width: 600px) {
    height: 200px;
    padding: 0 16px;
    && img {
      display: none;
    }
  }
  @media (min-width: 1265px) {
    padding: 0 calc(50% - 800px);
  }
`

const TyphographyContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  height: 100%;
  margin: auto;
  max-width: 1200px;
  padding: 0 calc(12px + 5%);
  position: relative;
  text-align: left;
  z-index: 1;
  && h1 {
    font-size: 1.7rem;
    font-weight: 700;
  }
  && p {
    font-size: 1.2rem;
    font-weight: 500;
    max-width: 28em;
  }
  @media (max-width: 980px) {
    margin: auto 0;
    max-width: 420px;
    && h1 {
      font-size: 1.6rem;
    }
    && p {
      font-size: 1.1rem;
    }
  }
`

const HeroBanner = () => {
  return (
    <>
      <BannerContainer>
        <TyphographyContainer>
          <Typography
            variant='h1'
            children='Welcome to Online Beer Price Posting!'
          />
          <Typography
            variant='body1'
            children='Get access to all the tools you will need to ensure compliance with price posting requirements.'
          />
        </TyphographyContainer>
        <img src={banner_foreground} alt='male brewer' />
      </BannerContainer>
    </>
  )
}

export default HeroBanner
