import { createAction, createReducer } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  setLoading,
  setEditMode,
  showError,
  showSuccess,
  setNewPricesToAdded,
} from './global'
import {
  getPricesToQuery,
  getPricesToByIDQuery,
  findPricesToDuplicatesQuery,
  findGlobalSettingsInPricePostsQuery,
} from 'graphql/queries'
import {
  createPricesToMutation,
  updatePricesToMutation,
  inactivateAndCreatePricesToMutation,
  changePricesToStatus,
} from 'graphql/mutations'
import { cleanUp } from 'common/helper'

export const fetchPricesTos = (whereClause = {}) => {
  return async (dispatch, getState) => {
    const pricesToState = getState().pricesTo
    const orderRule = `${pricesToState.orderBy}_${pricesToState.order}`

    dispatch(setLoading('Fetching Prices To list...'))

    // Add GraphQL query parameters
    let graphQLparams = {
      orderBy: orderRule,
      limit: pricesToState.rowsPerPage,
      offset: pricesToState.pageNumber * pricesToState.rowsPerPage,
    }
    if (whereClause && whereClause.name)
      graphQLparams.where = { name_like: cleanUp(whereClause.name) }

    await API.graphql(graphqlOperation(getPricesToQuery, graphQLparams))
      .then(response => {
        dispatch(setPricesTosList(response.data.pricesTo))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of prices to, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const resetPricesToDetails = () => {
  return dispatch => {
    dispatch(setPricesToDetails(initialState.pricesToDetails))
    dispatch(setEditMode(true))
    dispatch(setLoading(false))
  }
}

export const addNewPricesTo = pricesToDetails => {
  let newPricesTo = JSON.parse(JSON.stringify(pricesToDetails))
  return async dispatch => {
    dispatch(setLoading('Adding Prices To...'))

    const graphQlParamsForSearch = {
      where: {
        name_lower: cleanUp(newPricesTo.name),
      },
    }

    // Check that the pricesTo name doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findPricesToDuplicatesQuery, graphQlParamsForSearch)
    )
    if (res.data.pricesTo.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The "Prices To" name selected already exists, please review your information and try again.'
        )
      )
    } else {
      const graphQlParamsForCreation = {
        data: {
          name: cleanUp(newPricesTo.name),
        },
      }
      const pricesToRes = await API.graphql(
        graphqlOperation(createPricesToMutation, graphQlParamsForCreation)
      )
      if (pricesToRes.data.createPricesTo) {
        dispatch(setPricesToDetails(pricesToRes.data.createPricesTo))
        dispatch(setPricesToShown(false))
        dispatch(
          showSuccess('New "Prices To" item has been created successfully.')
        )
        dispatch(fetchPricesTos())
        dispatch(setNewPricesToAdded(true))
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the "Prices To" item, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

export const updatePricesTo = pricesToDetails => {
  return async dispatch => {
    dispatch(setLoading('Updating Prices To...'))

    const graphQlParamsForSearch = {
      where: {
        name_lower: cleanUp(pricesToDetails.name),
      },
    }

    // Check that the pricesTo name doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findPricesToDuplicatesQuery, graphQlParamsForSearch)
    )
    if (res.data.pricesTo.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The "Prices To" name selected already exists, please review your information and try again.'
        )
      )
    } else {
      // Check that the pricesTo item isn't being used by existing price postings, if it is show message to user
      const res = await API.graphql(
        graphqlOperation(
          findGlobalSettingsInPricePostsQuery({
            pricesTo: pricesToDetails.id,
          })
        )
      )
      // Display confirmation message to user
      if (res.data.pricePostings.count > 0) {
        dispatch(setLoading(false))
        dispatch(setIsPricesToUsed(true))
        dispatch(setPricesToDetails(pricesToDetails))
        dispatch(setLoading(false))
        dispatch(setPricesToShown(false))
      } else {
        const graphQlParamsForUpdate = {
          data: {
            name: cleanUp(pricesToDetails.name),
          },
          where: {
            id: pricesToDetails.id,
          },
        }
        await API.graphql(
          graphqlOperation(updatePricesToMutation, graphQlParamsForUpdate)
        )
          .then(_ => {
            dispatch(setPricesToDetails(pricesToDetails))
            dispatch(setPricesToShown(false))
            dispatch(
              showSuccess('"Prices To" item has been updated successfully.')
            )
            dispatch(setNewPricesToAdded(true))
            dispatch(fetchPricesTos())
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was an issue updating the "Prices To" item, please review the form and submit again. Thank you.'
              )
            )
          })
        dispatch(setLoading(false))
      }
    }
  }
}

export const updatePricesToOnConfirmation = () => {
  return async (dispatch, getState) => {
    dispatch(setIsPricesToUsed(false))
    dispatch(setLoading('Loading...'))
    const { pricesToDetails } = getState().pricesTo
    const graphQlParamsForUpdate = {
      data: {
        name: cleanUp(pricesToDetails.name),
      },
      where: {
        id: pricesToDetails.id,
      },
    }
    await API.graphql(
      graphqlOperation(
        inactivateAndCreatePricesToMutation,
        graphQlParamsForUpdate
      )
    )
      .then(_ => {
        dispatch(setPricesToDetails(pricesToDetails))
        dispatch(showSuccess('"Prices To" item has been updated successfully.'))
        dispatch(fetchPricesTos())
        dispatch(setNewPricesToAdded(true))
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was an issue updating the "Prices To" item, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const togglePricesToStatus = pricesToDetails => {
  return async (dispatch, getState) => {
    dispatch(setStatusChangeConfirmationForPricesTo(false))
    const userMessage =
      pricesToDetails.status === 'Active' ? 'Deactivating' : 'Reactivating'
    dispatch(setLoading(`${userMessage}  "Prices To" item. Please wait...`))
    let { pricesTosList } = getState().pricesTo
    pricesTosList = JSON.parse(JSON.stringify(pricesTosList))
    const changingStatus =
      pricesToDetails.status === 'Active' ? 'Inactive' : 'Active'
    const graphQLParams = {
      status: changingStatus,
      where: {
        id: pricesToDetails.id,
      },
    }
    await API.graphql(graphqlOperation(changePricesToStatus, graphQLParams))
      .then(_ => {
        const pricesToIndex = pricesTosList.results.findIndex(
          item => parseInt(item.id) === pricesToDetails.id
        )
        pricesTosList.results[pricesToIndex].status = changingStatus
        dispatch(setPricesTosList(pricesTosList))
        dispatch(
          showSuccess(`${userMessage}  "Prices To" item was successfully.`)
        )
      })
      .catch(err => {
        dispatch(
          showError(
            `There was an issue ${userMessage} the "Prices To" item, please check your internet connection and try again. Thank you.`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

// Fetch Prices To by ID after selecting list item
export const fetchPricesToByID = (id, editMode = true) => {
  return async dispatch => {
    dispatch(setLoading('Loading...'))
    const pricesToRes = await API.graphql(
      graphqlOperation(getPricesToByIDQuery, { id: parseInt(id) })
    )
    if (pricesToRes.data.pricesTo.results[0]) {
      dispatch(setPricesToDetails(pricesToRes.data.pricesTo.results[0]))
      dispatch(setEditMode(editMode))
      dispatch(setPricesToShown(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve "Prices To" item, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const setPricesTosList = createAction('pricesTos/setPricesTosList')
export const setPricesToPageNumber = createAction(
  'pricesTos/setPricesToPageNumber'
)
export const setPricesToRequestedSort = createAction(
  'pricesTos/setPricesToRequestedSort'
)
export const setPricesToSelectedValue = createAction(
  'pricesTos/setPricesToSelectedValue'
)
export const setPricesToShown = createAction('pricesTos/setPricesToShown')
export const setPricesToDetails = createAction('pricesTos/setPricesToDetails')
export const setIsPricesToUsed = createAction('pricesTos/setIsPricesToUsed')
export const resetPricesTo = createAction('pricesTos/resetPricesTo')
export const setStatusChangeConfirmationForPricesTo = createAction(
  'pricesTos/setStatusChangeConfirmationForPricesTo'
)

const initialState = {
  pricesTosList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pricesToDetails: {
    name: '',
  },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'name',
  selected: [],
  isPricesToShown: false,
  isPricesToUsed: false,
  statusChangeConfirmationForPricesTo: false,
}

export default createReducer(initialState, {
  [setPricesToDetails]: (state, action) => {
    state.pricesToDetails = action.payload
  },
  [setPricesTosList]: (state, action) => {
    state.pricesTosList = action.payload
  },
  [setPricesToPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setPricesToRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setPricesToSelectedValue]: (state, action) => {
    state.selected = action.payload
  },
  [setPricesToShown]: (state, action) => {
    state.isPricesToShown = action.payload
  },
  [setIsPricesToUsed]: (state, action) => {
    state.isPricesToUsed = action.payload
  },
  [setStatusChangeConfirmationForPricesTo]: (state, action) => {
    state.statusChangeConfirmationForPricesTo = action.payload
  },
  [resetPricesTo]: state => {
    state.pricesTosList = initialState.pricesTosList
    state.pricesToDetails = initialState.pricesToDetails
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.selected = initialState.selected
    state.isPricesToShown = initialState.isPricesToShown
    state.isPricesToUsed = initialState.isPricesToUsed
  },
})
