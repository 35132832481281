import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Edit, AttachMoney, Block, HowToReg } from '@material-ui/icons'
import { setEditMode } from 'ducks/global'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { Can } from 'common/abilityContext'
import { addEditBulkOn } from 'common/constants'
import { CheckLicensee } from 'common/helper'
import { setInactivationConfirmation, setActivationConfirmation } from 'ducks/pricePostings'

const MoneyIcon = styled(AttachMoney)`
  && {
    font-size: 3.03125rem;
  }
`
const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`
const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewPricePosting = () => {
  const dispatch = useDispatch()
  const { isAuxLoading, loadingAuxText } = useSelector(store => store.global)
  // const { user } = useSelector(store => store.auth)
  const { pricePostingDetails } = useSelector(store => store.pricePostings)
  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={
            <UserIcon alt='Price Posting Icon'>
              <MoneyIcon />
            </UserIcon>
          }
          profileTitle={
            <>
              <TitleLabel children={`Product Name`} variant='body2' />
              <TitleValue
                children={pricePostingDetails.product && pricePostingDetails.product.label}
                variant='h6'
              />
            </>
          }
          profileAction={
            <>
              {pricePostingDetails.status.label === 'Old' ? (
                ''
              ) : (
                <Can
                  I='edit/view'
                  this={
                    new CheckLicensee({
                      ifCreatedByLicenseeIs:
                        pricePostingDetails.createdByLicensee &&
                        pricePostingDetails.createdByLicensee.id,
                    })
                  }
                >
                  {() => (
                    <Can I='perform' this={addEditBulkOn.pricePostings}>
                      {() => (
                        <>
                          {pricePostingDetails.status.value === 'Active' && (
                            <IconButton
                              aria-label='Inactivate Price post'
                              aria-haspopup='false'
                              color='primary'
                              onClick={() => dispatch(setInactivationConfirmation(true))}
                            >
                              <Block color='error' />
                              <Typography variant='subtitle1'>&nbsp;{'Inactivate'}</Typography>
                            </IconButton>
                          )}
                          {pricePostingDetails.status.label === 'Inactive' ? (
                            <IconButton
                              aria-label='Activate Price post'
                              aria-haspopup='false'
                              color='primary'
                              onClick={() => dispatch(setActivationConfirmation(true))}
                            >
                              <HowToReg color='primary' />
                              <Typography variant='subtitle1'>&nbsp;{'Reactivate'}</Typography>
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label='Edit Price posting'
                              aria-haspopup='false'
                              color='primary'
                              onClick={() => dispatch(setEditMode(true))}
                            >
                              <EditIcon color='primary' />
                              <Typography variant='subtitle1'>&nbsp;{'Edit'}</Typography>
                            </IconButton>
                          )}
                        </>
                      )}
                    </Can>
                  )}
                </Can>
              )}
            </>
          }
          divider
        >
          <DataGrid
            label='Manufacturer'
            value={pricePostingDetails.manufacturer && pricePostingDetails.manufacturer.label}
          />
          <DataGrid label='Trade Name' value={pricePostingDetails.tradeName} />
          <DataGrid
            label='Package Configuration'
            value={pricePostingDetails.package && pricePostingDetails.package.label}
          />
          <DataGrid
            label='Promotional Item'
            value={pricePostingDetails.promotional && pricePostingDetails.promotional}
          />
          <DataGrid
            label='Size'
            value={pricePostingDetails.size && pricePostingDetails.size.label}
          />
          <DataGrid
            label='County'
            value={pricePostingDetails.county && pricePostingDetails.county[0].label}
          />
          <DataGrid label='Trading Area' value={pricePostingDetails.tradingArea} />
          <DataGrid
            label='Prices To'
            value={pricePostingDetails.pricesTo && pricePostingDetails.pricesTo.label}
          />
          <DataGrid label='Price' value={pricePostingDetails.price} />
          <DataGrid label='Container Charge' value={pricePostingDetails.containerCharge} />
          <DataGrid label='Price Promotion' value={pricePostingDetails.pricePromotion.label} />
          <DataGrid
            label='Receiving Method'
            value={
              pricePostingDetails.receivingMethod && pricePostingDetails.receivingMethod[0].label
            }
          />
          <DataGrid
            label='Status'
            value={pricePostingDetails.status && pricePostingDetails.status.label}
          />
          <DataGrid
            label='Licensee (Posted By)'
            value={
              pricePostingDetails.createdByLicensee && pricePostingDetails.createdByLicensee.name
            }
          />
          <Can
            I='edit/view'
            this={
              new CheckLicensee({
                ifCreatedByLicenseeIs:
                  pricePostingDetails.createdByLicensee && pricePostingDetails.createdByLicensee.id,
              })
            }
          >
            {() => <DataGrid label='Posted By' value={pricePostingDetails.createdBy.fullName} />}
          </Can>
          <DataGrid
            label='Submit date'
            value={
              pricePostingDetails.createdAt
                ? format(pricePostingDetails.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Effective date'
            value={
              pricePostingDetails.effectiveDate
                ? format(pricePostingDetails.effectiveDate, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Competitive'
            value={pricePostingDetails.competitivePostId ? 'Yes' : 'No'}
          />
          <DataGrid label='Source' value={pricePostingDetails.source} />
        </CustomCard>
      )}
    </>
  )
}

export default ViewPricePosting
