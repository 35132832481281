import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Collapse,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Form, Field, withFormik, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import styled from 'styled-components'
import { registerAsUser, verifyRegistrationCode } from 'ducks/authentication'
import SubmitButton from 'components/common/SubmitButton'
import ToggleMaskPassword from 'components/common/ToggleMaskPassword'
import NumericInput from 'components/common/NumericInput'
import PasswordConstraintsCheck from 'components/common/PasswordContraintsCheck'

const SignUp = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)
  const {
    registeringUser,
    isRegistrationSuccess,
    isRegistrationCodeVerified,
  } = useSelector(store => store.auth)

  const verificationValidations = Yup.object().shape({
    verificationCode: Yup.string().required('Required'),
  })

  // If the registration is successful redirect the user to the login page
  if (isRegistrationSuccess) {
    return <Redirect to='/login' />
  }

  return (
    <>
      {!isRegistrationCodeVerified ? (
        <Typography variant='h6'>Sign Up</Typography>
      ) : (
        <Typography variant='body1'>
          To complete the registration please setup your credentials:
        </Typography>
      )}

      <Formik
        initialValues={{ verificationCode: '' }}
        onSubmit={credentials => {
          dispatch(verifyRegistrationCode(credentials))
        }}
        validationSchema={verificationValidations}
      >
        <Form noValidate>
          <Field
            name='verifyRegistrationCode'
            render={formikProps => (
              <>
                {!isRegistrationCodeVerified ? (
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='verificationCode'
                    label='Verification Code'
                    name='verificationCode'
                    autoComplete='current-password'
                    component={ToggleMaskPassword}
                  />
                ) : (
                  ''
                )}
                <Collapse in={isRegistrationCodeVerified} timeout='auto'>
                  {isRegistrationCodeVerified && (
                    <WithFormikWrapper
                      registeringUser={registeringUser}
                      isRegistrationCodeVerified={isRegistrationCodeVerified}
                    />
                  )}
                </Collapse>
                {!isRegistrationCodeVerified && (
                  <SubmitButton isSubmitting={isLoading} />
                )}
              </>
            )}
          />
        </Form>
      </Formik>
    </>
  )
}
export default SignUp

const validations = Yup.object().shape({
  registeringUser: Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    username: Yup.string()
      .matches(/^\S+$/, `Username can't include spaces.`)
      .required('Required'),
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Required'),
    password: Yup.string()
      .matches(
        /([a-z])/,
        'Password should have minimum of one lower case letter.'
      )
      .matches(
        /([A-Z])/,
        'Password should have minimum of one uppercase case letter.'
      )
      .matches(
        /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/,
        'Password should have minimum of one special character.'
      )
      .matches(/([0-9])/, 'Password should have minimum of one number.')
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Required'),
  }),
})

const CustomButton = styled(Button)`
  && {
    height: 3rem;
    margin: 20px 20px 20px 0;
  }
`

const RegisterUser = () => {
  const dispatch = useDispatch()
  const onRegisterUser = formikValues => {
    formikValues.handleSubmit()
    if (formikValues.isValid) {
      dispatch(registerAsUser(formikValues.values.registeringUser))
    }
  }
  const { isRegistrationCodeVerified } = useSelector(store => store.auth)
  const { isLoading } = useSelector(store => store.global)
  return (
    <Field
      name='registeringUser'
      render={formikProps => {
        formikProps.form.values.registeringUser = JSON.parse(
          JSON.stringify(formikProps.form.values.registeringUser)
        )
        let enteringNewPassword =
          formikProps.form.values.registeringUser.password
        return (
          <>
            <Field
              variant='outlined'
              margin='normal'
              required={isRegistrationCodeVerified}
              fullWidth
              id='firstName'
              label='First Name'
              name='registeringUser.firstName'
              autoComplete='first name'
              component={TextField}
            />
            <Field
              variant='outlined'
              margin='normal'
              required={isRegistrationCodeVerified}
              fullWidth
              name='registeringUser.lastName'
              label='Last Name'
              id='lastName'
              component={TextField}
              autoComplete='last name'
            />
            <Field
              variant='outlined'
              margin='normal'
              required={isRegistrationCodeVerified}
              fullWidth
              disabled={true}
              name='registeringUser.email'
              label='Email'
              id='email'
              component={TextField}
              autoComplete='email'
            />
            <Field
              variant='outlined'
              margin='normal'
              fullWidth
              required={isRegistrationCodeVerified}
              name='registeringUser.username'
              label='Username'
              id='username'
              component={TextField}
              autoComplete='username'
            />
            <Field
              variant='outlined'
              margin='normal'
              required={isRegistrationCodeVerified}
              fullWidth
              name='registeringUser.password'
              label='Password'
              type='password'
              id='password'
              component={ToggleMaskPassword}
            />
            <PasswordConstraintsCheck
              enteringNewPassword={enteringNewPassword}
            />
            <Field
              variant='outlined'
              margin='normal'
              required={isRegistrationCodeVerified}
              fullWidth
              name='registeringUser.confirmPassword'
              label='Confirm Password'
              type='password'
              id='confirmPassword'
              component={ToggleMaskPassword}
            />
            <Field
              variant='outlined'
              margin='normal'
              fullWidth
              name='registeringUser.phone'
              label='Phone'
              id='phone'
              component={NumericInput}
              autoComplete='phone'
              format={'### ### #### ### ##'}
            />
            <CustomButton
              onClick={() => onRegisterUser(formikProps.form)}
              fullWidth
              variant='contained'
              color='primary'
            >
              {isLoading ? (
                <CircularProgress color='inherit' size={25} />
              ) : (
                'Submit'
              )}
            </CustomButton>
          </>
        )
      }}
    />
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(RegisterUser)
