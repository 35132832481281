export default {
  apiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_AWS_API_GATEWAY_URL,
  },
  graphql: {
    endpoint: process.env.REACT_APP_GRAPHQL_SERVER,
  },
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
}
