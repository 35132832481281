import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Popover,
} from '@material-ui/core'
import {
  MoreVert,
  PictureInPicture,
  Edit,
  LocalPrintshop,
  Block,
  HowToReg,
  Send,
} from '@material-ui/icons'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import {
  resetLegalEntityDetails,
  getListOfLegalEntities,
  setLeBulkSelection,
  setLePageNumber,
  setLeRowsPerPage,
  setLeRequestedSort,
  setIsLegalEntityFilterOpen,
  fetchLegalEntityByID,
  setLeAccountShown,
  setLicenseeBulkUploadModal,
  printRegOfSelectedLicensees,
  deactivateLicensee,
  reactivateLicensee,
  setReactivationConfirmation,
  setDeactivationConfirmation,
  setInactiveLicenseeFound,
  setConfirmationForLinkingProducts,
  createNewLicenseeProcess,
  resendRegistration,
} from 'ducks/legalEntity'
import { setEditMode } from 'ducks/global'
import LoadingIndicator from 'components/common/LoadingIndicator'
import ReusableTable from 'components/common/ReusableTable'
import ListComponent from 'components/common/ListComponent'
import EditLegalEntity from './EditLegalEntity'
import ViewLegalEntity from './ViewLegalEntity'
import LegalEntitiesFilters from './LegalEntitiesFilters'
import LicenseeBulkUpload from './LicenseeBulkUpload'
import { resetBulkUpload } from 'ducks/bulkUpload'
import LegalEntityPrintTemplate from 'components/common/LegalEntityPrintTemplate'
import ReactToPrint from 'react-to-print'
import ButtonWithDisabledInfo from 'components/common/ButtonWithDisabledInfo'
import ConfirmationModal from 'components/common/ConfirmationModal'
import QuickView from 'components/common/QuickView'
import CreateOrReactivateLicensee from './CreateOrReactivateLicensee'

const DontPrintWrapper = styled.div`
  @media print {
    display: none;
  }
`

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const LegalEntities = () => {
  const dispatch = useDispatch()
  const currentComponentRef = useRef()
  // Used for popover actions state
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLE, setSelectedLE] = useState(-1)

  const {
    legalEntityDetails,
    legalEntities,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    legalEntitiesFilters,
    isLegalEntityFilterOpen,
    isLeAccountShown,
    licenseeBulkUploadModal,
    bulkPrintingLEs,
    isDeactivationConfirmationOpen,
    isReactivationConfirmationOpen,
    inactiveLicenseeFound,
    confirmationForLinkingProducts,
  } = useSelector(store => store.legalEntity)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getListOfLegalEntities())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  const onQuickViewClosed = () => {
    dispatch(setLeAccountShown(false))
    dispatch(resetLegalEntityDetails())
    dispatch(setEditMode(false))
  }

  const legalEntityHeaders = [
    { id: 'name', label: 'Licensee', isSortable: true },
    { id: 'licenseTypes', label: 'License Types' },
    { id: 'licenseCategory', label: 'Category', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'createdAt', label: 'Added Date', isSortable: true },
    { id: 'updatedAt', label: 'Edited Date', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  const handleBulkUploadClicked = () => {
    dispatch(setLicenseeBulkUploadModal(true))
  }
  const onBulkUploadModalClosed = () => {
    dispatch(setLicenseeBulkUploadModal(false))
    dispatch(resetBulkUpload())
  }

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, licenseeDetails) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedLE(licenseeDetails)
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchLegalEntityByID(selectedLE.id))
  }

  const handleEditSelected = () => {
    setAnchorEl(null)
    dispatch(fetchLegalEntityByID(selectedLE.id, true))
  }
  const onItemSelected = data => {
    dispatch(fetchLegalEntityByID(data.id))
    setSelectedLE(data)
  }

  const handleDeactivation = () => {
    setAnchorEl(null)
    dispatch(setDeactivationConfirmation(false))
    dispatch(deactivateLicensee(selectedLE.id))
  }

  const handleReactivation = () => {
    setAnchorEl(null)
    dispatch(setReactivationConfirmation(false))
    dispatch(reactivateLicensee(selectedLE.id))
  }

  const handleResendRegistration = selectedUser => {
    dispatch(resendRegistration(selectedUser))
    setAnchorEl(null)
  }

  const openAction = Boolean(anchorEl)

  let updatedLegalEntities = []

  updatedLegalEntities = legalEntities.results.map((le, index) => ({
    ...le,
    licenseTypes: le.licenseTypes
      ? le.licenseTypes.map((licenseType, index) => (
          <span key={index}>
            <>
              {licenseType.number && licenseType.number.length > 0
                ? `${licenseType.number} - ${licenseType.name}`
                : licenseType.name}
              <br />
            </>
          </span>
        ))
      : 'None',
    createdAt: le.createdAt ? format(le.createdAt, 'MM/dd/yyyy') : '',
    updatedAt: le.updatedAt ? format(le.updatedAt, 'MM/dd/yyyy') : '',
    actions: (
      <>
        <IconButton
          aria-label='actions'
          aria-haspopup='true'
          onClick={event => handleActionOpen(event, le)}
          color='primary'
        >
          <MoreVert color='primary' />
        </IconButton>
      </>
    ),
  }))

  const onAdd = () => {
    dispatch(resetLegalEntityDetails())
    dispatch(setEditMode(true))
    dispatch(setLeAccountShown(true))
  }
  const handleFilter = value => {
    dispatch(setIsLegalEntityFilterOpen(value))
  }
  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = legalEntities.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          legalEntities.results.map(finalObject => ({
            id: finalObject.id,
            licensed:
              finalObject.licenseCategory === 'Unlicensed' ? false : true,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = legalEntities.results
          .filter(leItem => items.id === leItem.id)
          .map(finalObject => ({
            id: finalObject.id,
            licensed:
              finalObject.licenseCategory === 'Unlicensed' ? false : true,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setLeBulkSelection(newSelected))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getListOfLegalEntities(false, true))
  }

  const handleClearSelection = () => {
    dispatch(setLeBulkSelection([]))
  }

  const handlePageNumber = newPage => {
    dispatch(setLePageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setLeRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setLeRequestedSort(value))
  }
  const selectedOnlyLicensedLE = bulkSelected.every(
    item => item.licensed === true
  )

  const actionItemsOnSelection = (
    <ActionWrapper>
      <ReactToPrint
        copyStyles={true}
        onBeforeGetContent={() => {
          return new Promise(async resolve => {
            await dispatch(printRegOfSelectedLicensees(resolve))
          })
        }}
        trigger={() => (
          <ButtonWithDisabledInfo
            buttonDisabledShowTooltip={!selectedOnlyLicensedLE}
            disabledReasons={[
              'You must select only licensed licensees for bulk printing.',
            ]}
            onClick={e => {}}
            buttonLabel='Print Registrations'
            startIcon={<LocalPrintshop />}
          />
        )}
        content={() => currentComponentRef.current}
      />
    </ActionWrapper>
  )

  return (
    <DontPrintWrapper>
      {legalEntities.firstLoad ? (
        <LoadingIndicator text={loadingText} transparent />
      ) : (
        <>
          <Helmet>
            <title>Licensees</title>
          </Helmet>
          <Popover
            anchorReference={'anchorEl'}
            anchorEl={anchorEl}
            aria-haspopup='true'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={openAction}
            onClose={handleActionClose}
          >
            <ActionItems onClick={handleViewSelected}>
              <PictureInPicture color='primary' fontSize='small' />
              <ActionLabels children='View' variant='body1' />
            </ActionItems>
            <Divider />
            <ActionItems onClick={handleEditSelected}>
              <Edit color='primary' fontSize='small' />
              <ActionLabels children='Edit' variant='body1' />
            </ActionItems>
            {selectedLE.status === 'Pending' && (
              <>
                <Divider />
                <ActionItems
                  onClick={() => handleResendRegistration(selectedLE)}
                >
                  <Send color='primary' fontSize='small' />
                  <ActionLabels
                    children='Resend Registration'
                    variant='body1'
                  />
                </ActionItems>
              </>
            )}
            <Divider />
            {(selectedLE.status === 'Active' ||
              selectedLE.status === 'Pending') && (
              <ActionItems
                onClick={() => dispatch(setDeactivationConfirmation(true))}
              >
                <Block color='error' fontSize='small' />
                <ActionLabels children='Deactivate' variant='body1' />
              </ActionItems>
            )}
            {selectedLE.status === 'Inactive' && (
              <ActionItems
                onClick={() => dispatch(setReactivationConfirmation(true))}
              >
                <HowToReg color='primary' fontSize='small' />
                <ActionLabels children='Reactivate' variant='body1' />
              </ActionItems>
            )}
          </Popover>
          <ListComponent
            mainTitle='Licensees'
            listContent={
              <ReusableTable
                headers={legalEntityHeaders}
                tableData={updatedLegalEntities}
                pagination={true}
                selectionOption={true}
                totalCount={legalEntities.totalCount}
                onSingleItemSelected={onItemSelected}
                handleBulkSelection={handleBulkSelection}
                setPageNumber={handlePageNumber}
                setRowsPerPage={handleRowsPerPage}
                setRequestedSort={handleRequestSort}
                pageNumber={pageNumber}
                rowsPerPage={rowsPerPage}
                order={order}
                orderBy={orderBy}
                bulkSelected={bulkSelected}
                canChangeRowsPerPage={[10, 20, 50, 100]}
                handleSelectAllRows={handleSelectionOfAllRows}
                handleClearSelection={handleClearSelection}
                actionItemsOnSelection={actionItemsOnSelection}
              />
            }
            editComp={<EditLegalEntity legalEntity={legalEntityDetails} />}
            viewComp={<ViewLegalEntity />}
            modalEditTitle={
              legalEntityDetails.id ? 'Edit Licensee' : 'Add Licensee'
            }
            modalViewTitle='Licensee Profile'
            onModalClosed={onQuickViewClosed}
            expandedOptions={
              <LegalEntitiesFilters
                legalEntitiesFilters={legalEntitiesFilters}
              />
            }
            handleExpandingFilter={handleFilter}
            filterExpanded={isLegalEntityFilterOpen}
            loadingText={loadingText}
            isDialogOpen={isLeAccountShown}
            onAddButtonClicked={onAdd}
            isBulkUploadAvailable={true}
            bulkUploadContent={<LicenseeBulkUpload />}
            onBulkUploadClicked={handleBulkUploadClicked}
            bulkUploadTitle='Licensee Bulk Upload'
            isBulkDialogOpen={licenseeBulkUploadModal}
            onBulkUploadModalClosed={onBulkUploadModalClosed}
          />
          <LegalEntityPrintTemplate
            ref={currentComponentRef}
            printingProps={bulkPrintingLEs}
          />
        </>
      )}
      <ConfirmationModal
        isConfirmationModalOpen={isDeactivationConfirmationOpen}
        confirmationList={[
          'Are you sure you want to deactivate this licensee?',
        ]}
        onConfirmation={handleDeactivation}
        onConfirmationModalClosed={() =>
          dispatch(setDeactivationConfirmation(false))
        }
        type='deactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={isReactivationConfirmationOpen}
        confirmationList={[
          'Are you sure you want to reactivate this licensee?',
        ]}
        onConfirmation={handleReactivation}
        onConfirmationModalClosed={() =>
          dispatch(setReactivationConfirmation(false))
        }
      />
      <QuickView
        dialogContent={<CreateOrReactivateLicensee />}
        title='Inactive Licensee Found'
        onClose={() => dispatch(setInactiveLicenseeFound(null))}
        isDialogOpen={
          inactiveLicenseeFound && !confirmationForLinkingProducts
            ? true
            : false
        }
        isSmallDialog={true}
      />
      <ConfirmationModal
        isConfirmationModalOpen={confirmationForLinkingProducts}
        confirmationList={[
          'Do you want to link products from the existing inactive licensee to the new one?',
        ]}
        onConfirmation={() => {
          // Create the new licensee and link their products
          dispatch(createNewLicenseeProcess(legalEntityDetails, true, false))
        }}
        onCancel={() => {
          // Create the new licensee without linking products
          dispatch(createNewLicenseeProcess(legalEntityDetails, false, false))
        }}
        onConfirmationModalClosed={() =>
          dispatch(setConfirmationForLinkingProducts(false))
        }
      />
    </DontPrintWrapper>
  )
}

export default LegalEntities
