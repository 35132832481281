import React from 'react'
import { Typography } from '@material-ui/core'
import * as XLSX from 'xlsx'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateBulkJson, handleBulkUploadNotReady, updateBulkFilename } from 'ducks/bulkUpload'
import { useDropzone } from 'react-dropzone'
import { Description } from '@material-ui/icons'
import { showError, showSuccess } from 'ducks/global'

const Container = styled.div`
  && {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: -webkit-grab;
    cursor: grab;
  }
`

const UploadLabel = styled(Typography)`
  padding-left: 7px;
`

const AcceptedIcon = styled(Description)`
  margin-right: 5px;
  font-size: 25px;
`

const AcceptedLabel = styled(UploadLabel)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.dark};
`
const BulkUploadComp = ({ validations, formatColumnName }) => {
  const dispatch = useDispatch()
  const requiredColumnLength = validations.requiredColumn.length

  const extractHeaders = ws => {
    let header = []
    const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
    for (let i = 0; i < columnCount; ++i) {
      if (!ws[`${XLSX.utils.encode_col(i)}1`]) {
        header = false
        return header
      }
      header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
    }
    return header.map(h => h.toLowerCase())
  }

  const checkRequiredHeaders = fileHeaders => {
    let finalArray = []
    const reqColumNames = validations.requiredColumn
    for (let i = 0; i < requiredColumnLength; ++i) {
      let returnColumnName = null
      returnColumnName = fileHeaders.filter(fh => fh.includes(reqColumNames[i]))
      returnColumnName.length > 0 && finalArray.push(returnColumnName[0])
    }
    return finalArray.length === requiredColumnLength ? true : false
  }

  const checkForAtLeastOneRow = ws => {
    const columnCount = XLSX.utils.sheet_to_json(ws)
    return columnCount.length > 0 ? true : false
  }

  const validateFile = file => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result

      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsName = wb.SheetNames[0]
      const ws = wb.Sheets[wsName]

      if (ws['!merges'] && ws['!merges'].length > 0) {
        /*--- if the file has one or more merged cells---*/
        dispatch(handleBulkUploadNotReady(false))
        dispatch(
          showError(
            `The file you are trying to upload has one or more merged cells, please remove them and try again.`
          )
        )
        return
      }

      const headers = extractHeaders(ws)
      // if (!headers) {
      //   /*--- if the file has extra columns with no headers ---*/
      //   dispatch(handleBulkUploadNotReady(false))
      //   dispatch(
      //     showError(
      //       `The file you are trying to upload has hidden empty headers, please use the ${validations.uploadProcessName} template as reference and try again.`
      //     )
      //   )
      //   return
      // }
      if (requiredColumnLength && headers.length < requiredColumnLength) {
        /*--- if the file has less columns---*/
        dispatch(handleBulkUploadNotReady(false))
        dispatch(
          showError(
            `The file you are trying to upload is missing required columns, please use the ${validations.uploadProcessName} template as reference and try again.`
          )
        )
        return
      } else if (!checkRequiredHeaders(headers)) {
        /*----Check if the header has all the required columns----*/
        dispatch(handleBulkUploadNotReady(false))
        dispatch(
          showError(
            `The file you are trying to upload does not have the required column(s), please use the ${validations.uploadProcessName} template as reference and try again.`
          )
        )
        return
      } else if (!checkForAtLeastOneRow(ws)) {
        /*----Check if the file has at least one row ----*/
        dispatch(handleBulkUploadNotReady(false))
        dispatch(
          showError(
            `The file you are trying to upload should have a minimum of one row, please add data and try again.`
          )
        )
        return
      } else {
        /*----------convert sheet to JSON, and remove extra row-------------*/
        let modifiedHeaders = headers.map(h => {
          return formatColumnName(h)
        })
        let sheetToJson = XLSX.utils.sheet_to_json(ws, {
          header: modifiedHeaders,
          range: 1,
        })
        /*--------Remove extra columns if any-----------*/
        // if (modifiedHeaders.length > validations.columns.length) {
        //   sheetToJson = modifiedHeaders.find(mh => mh.includes('trade'))
        //     ? sheetToJson.map(t => ({
        //         productName: t.productName,
        //         tradeName: t.tradeName,
        //       }))
        //     : sheetToJson.map(t => ({ productName: t.productName }))
        // }

        // Convert all Excel columns using toString() except numeric columns listed in numericColumns[]
        const numericColumns = ['price', 'containerCharge']
        if (sheetToJson[0]) {
          sheetToJson.forEach(sheet => {
            for (const column in sheet) {
              sheet[column] = !numericColumns.includes(column)
                ? sheet[column].toString().replace(/\s\s+/g, ' ').trim()
                : sheet[column]
            }
          })
        }
        dispatch(updateBulkJson(sheetToJson))
        dispatch(updateBulkFilename(file.name))
        dispatch(showSuccess('File formatting validation completed, ready to upload.'))
      }
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.xlsx, .csv',
    onDropAccepted: files => {
      validateFile(files[0])
    },
    onDropRejected: files => {
      dispatch(handleBulkUploadNotReady(false))
    },
  })

  const isAccepted = acceptedFiles.length > 0 ? true : false
  const isRejected = rejectedFiles.length > 0 ? true : false

  return (
    <Container {...getRootProps({ isDragActive, isDragReject, isDragAccept })}>
      <input {...getInputProps()} />
      {isAccepted ? (
        <AcceptedLabel variant='h6'>
          <AcceptedIcon color='primary' /> {acceptedFiles[0].name}
        </AcceptedLabel>
      ) : isDragActive ? (
        <UploadLabel variant='h6'>Drop the file here ...</UploadLabel>
      ) : isRejected ? (
        <UploadLabel color={isRejected && 'secondary'} variant='h6'>
          Only .xlsx or .csv files are supported
        </UploadLabel>
      ) : (
        <>
          <UploadLabel variant='h6'>
            Drag 'n' drop a file here, or click to select a file
          </UploadLabel>
          <UploadLabel variant='h6'>(Supported file formats: .xlsx and .csv)</UploadLabel>
        </>
      )}
    </Container>
  )
}
export default BulkUploadComp
