import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import { IconButton, Popover, MenuItem, Typography, Divider, Button } from '@material-ui/core'
import {
  MoreVert,
  PictureInPicture,
  Edit,
  Block,
  HowToReg,
  InsertChart,
  CloudDownload,
} from '@material-ui/icons'
import LoadingIndicator from 'components/common/LoadingIndicator'
import styled from 'styled-components'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import PricePostingsFilters from './PricePostingsFilters'
import EditPricePosting from './EditPricePosting'
import ViewPricePosting from './ViewPricePostings'
import { AbilityContext, Can } from 'common/abilityContext'
import { addEditBulkOn } from 'common/constants'
import {
  formatNumberToTwoDecimal,
  formatNumberToThreeDecimal,
  isDateSameAsToday,
  exportToExcel,
  convertSizeOfBBL,
} from 'common/helper'
import {
  clearFilters,
  getPricePostingsList,
  setIsPricePostingsFilterOpen,
  setPricePostingShow,
  resetPricePostingDetails,
  fetchPricePostingByID,
  setPricePostingBulkSelect,
  setPricePostingsPageNumber,
  setPricePostingsRowsPerPage,
  setPricePostingsRequestedSort,
  exportToExcelCall,
  setListType,
  selectAllPricePostings,
  setInactivationConfirmation,
  setActivationConfirmation,
  activatePricePosting,
  inactivatePricePosting,
  setBulkInactivationConfirmation,
  setBulkActivationConfirmation,
  setSinglePricePostSelected,
  setIsCompetingUsingMyOwn,
  setCompetingUsingOthers,
  setPricePostingConfirmation,
  pricePostingAfterConfirmation,
  setPricePostingSuccess,
  setIsMultiPriceChangeModalOpen,
  setPPBulkUploadDialog,
  setFailedPricePostingsDuringBulk,
  setPricePostingsTotalCount,
  setPricePromoActivationConfirmation,
  setPricePromoBulkActivationConfirmation,  
  setPricePromoInactivationConfirmation,
  setPricePromoBulkInactivationConfirmation,
  activatePricePostingPromo,
  inactivatePricePostingPromo,
} from 'ducks/pricePostings'
import {
  exportPricePromoAuditByPricePosting,
} from 'ducks/pricePostingsPromoAudit'
import {
  setBulkCompetitiveItemsSelected,
  setBulkSelectionOfCompetingWithItems,
  onResetCompetingPricePosting,
} from 'ducks/competitivePricePosting'
import { fetchProductsWithTrade, setEditMode, showError, setLoading } from 'ducks/global'
import { CheckLicensee, checkIfUserIsABC } from 'common/helper'
import ConfirmationModal from 'components/common/ConfirmationModal'
import QuickView from 'components/common/QuickView'
import CompetitivePricePosting from './CompetitivePricePosting'
import ButtonWithDisabledInfo from 'components/common/ButtonWithDisabledInfo'
import MultiplePriceChange from './MultiplePriceChange'
import { resetBulkUpload } from 'ducks/bulkUpload'
import PricePostingBulkUpload from './PricePostingBulkUpload'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const StyledDivider = styled(Divider)`
  && {
    height: 2px;
  }
`
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const PricePostingsList = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    pricePostingsList,
    pricePostingsTotalCount,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    isPricePostingsFilterOpen,
    isPricePostingShown,
    pricePostingsFilters,
    pricePostingDetails,
    listType,
    areFiltersUsed,
    activationConfirmation,
    inactivationConfirmation,
    bulkInactivationConfirmation,
    bulkActivationConfirmation,
    pricePromoActivationConfirmation,
    pricePromoBulkActivationConfirmation,    
    pricePromoInactivationConfirmation,
    pricePromoBulkInactivationConfirmation,
    singlePricePostSelected,
    isCompetingUsingMyOwn,
    pricePostingConfirmation,
    pricePostingSuccess,
    pricePostingItems,
    isMultiPriceChangeModalOpen,
    isPPBulkUploadDialogOpen,
    failedPricePostingsDuringBulk,
  } = useSelector(store => store.pricePostings)
  const { loadingText, fetchingTotalCount } = useSelector(store => store.global)
  useEffect(() => {
    dispatch(getPricePostingsList())
  }, [dispatch, listType, order, orderBy, pageNumber, rowsPerPage])

  const abilityFunc = useContext(AbilityContext)

  const { user } = useSelector(store => store.auth)
  const isUserAbc = user.role ? checkIfUserIsABC(user.role.id) : false

  /*----Table Header-----*/

  let pricePostingsHeaders = [
    { id: 'manufacturer', label: 'Manufacturer', isSortable: true },
    { id: 'tradeName', label: 'Trade Name', isSortable: true },
    { id: 'productName', label: 'Product Name', isSortable: true },
    {
      id: 'package',
      label: 'Package Configuration',
      isSortable: true,
    },
    { id: 'size', label: 'Size', isSortable: true },
    { id: 'county', label: 'County', isSortable: true },
    { id: 'pricesTo', label: 'Prices To', isSortable: true },
    { id: 'receivingMethod', label: 'Receiving Method', isSortable: true },
    { id: 'price', label: 'Price($)', isSortable: true },
    {
      id: 'containerCharge',
      label: 'Container Charge($)',
      isSortable: true,
    },
    { id: 'pricePromotion', label: 'Price Promotion', isSortable: true },
    { id: 'createdByLicensee', label: 'Posted By', isSortable: true },
    { id: 'effectiveDate', label: 'Effective Date', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, lineItem) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    dispatch(setSinglePricePostSelected(lineItem))
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchPricePostingByID(singlePricePostSelected.id))
  }

  const handleEditSelected = () => {
    setAnchorEl(null)
    dispatch(fetchPricePostingByID(singlePricePostSelected.id, true))
  }

  const handleCompetitiveSelect = (isSelectingMyItem = false) => {
    const actionOpenedItem = [
      {
        id: singlePricePostSelected.id,
        status: 'Active',
        createdByLicenseeID:
          singlePricePostSelected.createdByLicensee && singlePricePostSelected.createdByLicensee.id,
        price: singlePricePostSelected.price,
        containerCharge: singlePricePostSelected.containerCharge,
        county: singlePricePostSelected.county,
      },
    ]
    setAnchorEl(null)
    dispatch(setIsCompetingUsingMyOwn(true))
    // dispatch(fetchPricePostingByID(singlePricePostSelected.id))
    if (isSelectingMyItem) {
      dispatch(setBulkCompetitiveItemsSelected(actionOpenedItem))
    } else {
      dispatch(setBulkSelectionOfCompetingWithItems(actionOpenedItem))
      dispatch(setCompetingUsingOthers(true))
    }
  }

  const handleViewPricePromotionAudit = () => {
    setAnchorEl(null)
    dispatch(exportPricePromoAuditByPricePosting(singlePricePostSelected.id))
  }

  const openAction = Boolean(anchorEl)

  const onSingleItemSelected = (pricePosting, index) => {
    dispatch(fetchPricePostingByID(pricePosting.id))
    dispatch(setSinglePricePostSelected(pricePostingsList.results[index]))
  }

  const handleActivation = () => {
    setAnchorEl(null)
    dispatch(setActivationConfirmation(false))
    dispatch(activatePricePosting([singlePricePostSelected]))
  }

  const handleInactivation = () => {
    setAnchorEl(null)
    dispatch(setInactivationConfirmation(false))
    dispatch(inactivatePricePosting([singlePricePostSelected]))
  }

  const handleBulkInactivation = () => {
    dispatch(setBulkInactivationConfirmation(false))
    dispatch(inactivatePricePosting(bulkSelected))
  }

  const handleBulkActivation = () => {
    dispatch(setBulkActivationConfirmation(false))
    dispatch(activatePricePosting(bulkSelected))
  }

  const handlePricePromoActivation = () => {
    setAnchorEl(null)
    dispatch(setPricePromoActivationConfirmation(false))
    dispatch(activatePricePostingPromo([singlePricePostSelected]))
  }

  const handlePricePromoInactivation = () => {
    setAnchorEl(null)
    dispatch(setPricePromoInactivationConfirmation(false))
    dispatch(inactivatePricePostingPromo([singlePricePostSelected]))
  }

  const handlePricePromoBulkActivation = () => {
    dispatch(setPricePromoBulkActivationConfirmation(false))
    dispatch(activatePricePostingPromo(bulkSelected))
  }

  const handlePricePromoBulkInactivation = () => {
    dispatch(setPricePromoBulkInactivationConfirmation(false))
    dispatch(inactivatePricePostingPromo(bulkSelected))
  }

  const handlePricePostingConfirmation = () => {
    if (pricePostingItems && pricePostingItems.length > 0) {
      dispatch(pricePostingAfterConfirmation(pricePostingItems))
    }
    dispatch(setPricePostingConfirmation(false))
    dispatch(setPricePostingSuccess([]))
    dispatch(setPricePostingShow(false))
    if (failedPricePostingsDuringBulk.length > 0) {
      dispatch(
        showError(
          `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
        )
      )
      exportToExcel(failedPricePostingsDuringBulk, 'failedPricePosting')
      dispatch(setFailedPricePostingsDuringBulk([]))
    }
  }

  const handlePricePostingConfirmationCancel = () => {
    dispatch(setPricePostingConfirmation(false))
    dispatch(setPricePostingShow(false))
    if (pricePostingSuccess.length > 0) {
      dispatch(setPricePostingSuccess([]))
      dispatch(getPricePostingsList(false, true))
    }
    if (failedPricePostingsDuringBulk.length > 0) {
      dispatch(
        showError(
          `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
        )
      )
      exportToExcel(failedPricePostingsDuringBulk, 'failedPricePosting')
      dispatch(setFailedPricePostingsDuringBulk([]))
    }
    dispatch(setLoading(false))
  }

  const extraMessageOnConfirmationModal =
    pricePostingSuccess.length > 0
      ? `Price posting(s) with ${
          pricePostingSuccess.length > 1
            ? `more than one county were`
            : `county: ${pricePostingSuccess[0].county} was`
        } successful. `
      : ''

  let pricePostingConfirmationMessage = []

  if (pricePostingItems && pricePostingItems.length > 0) {
    const reactivatingItems = new Set()
    const priceChangeItems = new Set()
    const priceChangeAfterReactivation = new Set()
    const effectiveImmediateItems = new Set()
    for (const pricePost of pricePostingItems) {
      if (pricePost.priceReactivation) {
        if (pricePost.priceReactivationWithPriceChange) {
          priceChangeAfterReactivation.add(pricePost.county.label)
        } else {
          reactivatingItems.add(pricePost.county.label)
        }
      } else if (pricePost.makeEffectiveImmediateItem) {
        effectiveImmediateItems.add(pricePost.county.label)
      } else {
        priceChangeItems.add(pricePost.county.label)
      }
    }

    if (reactivatingItems.size > 0) {
      pricePostingConfirmationMessage.push(
        `Reactivation of price post(s) with county(ies): \n ${[...reactivatingItems].join(', ')}.`
      )
    }
    if (priceChangeAfterReactivation.size > 0) {
      pricePostingConfirmationMessage.push(
        `Reactivation and price change of price post(s) with county(ies): \n ${[
          ...priceChangeAfterReactivation,
        ].join(', ')}.`
      )
    }
    if (priceChangeItems.size > 0) {
      pricePostingConfirmationMessage.push(
        `Price change of price post(s) with county(ies): \n ${[...priceChangeItems].join(', ')}.`
      )
    }
    if (effectiveImmediateItems.size > 0) {
      pricePostingConfirmationMessage.push(
        `Make the price post(s) with the following county(ies) effective immediately: \n ${[
          ...effectiveImmediateItems,
        ].join(', ')}.`
      )
    }
  }
  /*------PricePostings list----------*/
  let updatedPricePostingsList = []
  updatedPricePostingsList = pricePostingsList.results.map((pricePosting, index) => {
    const updatedPricePosting = {
      id: pricePosting.id,
      manufacturer: pricePosting.manufacturer.name,
      tradeName: pricePosting.product.tradeName,
      productName: pricePosting.product.name,
      package: pricePosting.package.package,
      size: `${convertSizeOfBBL(
        pricePosting.productSize.size,
        pricePosting.productSize.unit.unit
      )} ${pricePosting.productSize.unit.unit} ${pricePosting.productSize.containerType.type}
        `,
      county: pricePosting.county,
      pricesTo: pricePosting.pricesTo.name,
      receivingMethod: pricePosting.receivingMethod,
      price: formatNumberToThreeDecimal(pricePosting.price),
      containerCharge: formatNumberToTwoDecimal(pricePosting.containerCharge),
      pricePromotion: pricePosting.pricePromotion ? 'Yes' : 'No',
      createdByLicensee: pricePosting.createdByLicensee && pricePosting.createdByLicensee.name,
      effectiveDate: format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
      status: pricePosting.status,
      actions: (
        <>
          <IconButton
            aria-label='actions'
            aria-haspopup='true'
            onClick={event => handleActionOpen(event, pricePosting)}
            color='primary'
          >
            <MoreVert color='primary' />
          </IconButton>
        </>
      ),
    }
    return updatedPricePosting
  })

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = pricePostingsList.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(item => !singlePageSelectedIDs.includes(item.id))
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          pricePostingsList.results.map(finalObject => ({
            id: finalObject.id,
            manufacturer: {
              value: finalObject.manufacturer.id,
              label: finalObject.manufacturer.name,
            },
            package: {
              value: finalObject.package.id,
              label: finalObject.package.name,
            },
            receivingMethod: {
              value: finalObject.receivingMethod,
              label: finalObject.receivingMethod,
            },
            size: {
              value: finalObject.productSize.id,
            },
            pricesTo: {
              value: finalObject.pricesTo.id,
              label: finalObject.pricesTo.name,
            },
            product: {
              value: finalObject.product.id,
              label: finalObject.product.name,
            },
            createdAt: finalObject.createdAt,
            parentPricePostId: finalObject.parentPricePostId,
            status: finalObject.status,
            createdByLicenseeID: finalObject.createdByLicensee.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            pricePromotion: finalObject.pricePromotion,
            county: finalObject.county,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = pricePostingsList.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
            manufacturer: {
              value: finalObject.manufacturer.id,
              label: finalObject.manufacturer.name,
            },
            package: {
              value: finalObject.package.id,
              label: finalObject.package.name,
            },
            receivingMethod: {
              value: finalObject.receivingMethod,
              label: finalObject.receivingMethod,
            },
            size: {
              value: finalObject.productSize.id,
            },
            pricesTo: {
              value: finalObject.pricesTo.id,
              label: finalObject.pricesTo.name,
            },
            product: {
              value: finalObject.product.id,
              label: finalObject.product.name,
            },
            createdAt: finalObject.createdAt,
            parentPricePostId: finalObject.parentPricePostId,
            status: finalObject.status,
            createdByLicenseeID: finalObject.createdByLicensee
              ? finalObject.createdByLicensee.id
              : '',
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            pricePromotion: finalObject.pricePromotion,
            county: finalObject.county,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setPricePostingBulkSelect(newSelected))
  }

  const handlePageNumber = newPage => {
    dispatch(setPricePostingsPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setPricePostingsRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setPricePostingsRequestedSort(value))
  }

  const onQuickViewClosed = () => {
    dispatch(resetPricePostingDetails())
    dispatch(setPricePostingShow(false))
    dispatch(setEditMode(false))
  }

  const onQuickViewClosedManufacturerCheck = () => {
    const filterManufacturer = pricePostingsFilters.manufacturer
    if (filterManufacturer.length > 0) {
      const manufacturerId = filterManufacturer.map(m => parseInt(m.value))
      dispatch(fetchProductsWithTrade(manufacturerId))
    }
    onQuickViewClosed()
  }

  const handleFilter = value => {
    dispatch(setIsPricePostingsFilterOpen(value))
  }
  const onAdd = () => {
    dispatch(resetPricePostingDetails())
    dispatch(setEditMode(true))
    dispatch(setPricePostingShow(true))
  }
  const exportSelectedToExcel = () => {
    dispatch(exportToExcelCall())
  }

  const handleSelectionOfAllRows = () => {
    dispatch(selectAllPricePostings())
  }

  const handlePPListType = event => {
    dispatch(setPricePostingBulkSelect([]))
    dispatch(setPricePostingsTotalCount(0))
    dispatch(setListType(event.target.value))
    dispatch(setPricePostingsPageNumber(0))
    dispatch(clearFilters(false))
  }

  const handleClearSelection = event => {
    dispatch(setPricePostingBulkSelect([]))
  }

  const handleBulkUploadClicked = () => {
    dispatch(setPPBulkUploadDialog(true))
  }

  const onBulkUploadModalClosed = () => {
    dispatch(setPPBulkUploadDialog(false))
    dispatch(resetBulkUpload())
  }

  const handleCompetingModalClosed = () => {
    dispatch(setIsCompetingUsingMyOwn(false))
    dispatch(onResetCompetingPricePosting())
  }

  const statusChangeButtonDisabledInfo = [
    'Combination of price posts with different status.',
    'Pending or Old price posts.',
    'Price posts created by other licensees.',
  ]

  const selectedItemsAreActiveOnly = bulkSelected.every(item => item.status === 'Active')
  const selectedItemsAreInactiveOnly = bulkSelected.every(item => item.status === 'Inactive')

  const allBulkSelectedCreatedByLicenseeAreEqual =
    bulkSelected.length > 0
      ? bulkSelected.every(
          (item, i, arr) => item.createdByLicenseeID === bulkSelected[0].createdByLicenseeID
        )
      : false

  const bulkSelectedCreatedByLicenseeID =
    allBulkSelectedCreatedByLicenseeAreEqual && bulkSelected[0].createdByLicenseeID

  const loggedInLicenseeIsOwnerOfBulkSelected = abilityFunc.can(
    'edit/view',
    new CheckLicensee({
      ifCreatedByLicenseeIs: bulkSelectedCreatedByLicenseeID,
    })
  )

  const multiPriceChangeButtonDisabled =
    !bulkSelected.every(item => item.status === 'Pending' || item.status === 'Active') ||
    !loggedInLicenseeIsOwnerOfBulkSelected

  const statusChangeButtonDisabled =
    (!selectedItemsAreActiveOnly && !selectedItemsAreInactiveOnly) ||
    !loggedInLicenseeIsOwnerOfBulkSelected

  const pricePromoSelectedItemsAreActiveOnly = bulkSelected.every(item => item.pricePromotion)
  const pricePromoSelectedItemsAreInactiveOnly = bulkSelected.every(item => item.pricePromotion === false)

  const pricePromoStatusChangeButtonDisabled = 
    !bulkSelected.every(item => item.status === 'Pending' || item.status === 'Active') || 
    ((!loggedInLicenseeIsOwnerOfBulkSelected && !isUserAbc) ||
    (!pricePromoSelectedItemsAreActiveOnly && !pricePromoSelectedItemsAreInactiveOnly))

  const actionItemsOnSelection = (
    <ActionWrapper>
      <Can I='perform' this={addEditBulkOn.pricePostings}>
        {() => (
          <>
            <ButtonWithDisabledInfo
              buttonDisabledShowTooltip={multiPriceChangeButtonDisabled}
              tooltipTitle='Price change cannot be done on price posts:'
              disabledReasons={[
                'With statuses other than pending and active.',
                'Created by other licensees.',
              ]}
              onClick={e => dispatch(setIsMultiPriceChangeModalOpen(true))}
              buttonLabel='Edit'
              startIcon={<Edit />}
            />
            <ButtonWithDisabledInfo
              buttonDisabledShowTooltip={statusChangeButtonDisabled}
              tooltipTitle='Status change cannot be performed on:'
              disabledReasons={statusChangeButtonDisabledInfo}
              startIcon={
                selectedItemsAreActiveOnly ? (
                  <Block />
                ) : selectedItemsAreInactiveOnly ? (
                  <HowToReg />
                ) : (
                  ''
                )
              }
              onClick={() =>
                selectedItemsAreActiveOnly
                  ? dispatch(setBulkInactivationConfirmation(!bulkInactivationConfirmation))
                  : dispatch(setBulkActivationConfirmation(!bulkActivationConfirmation))
              }
              buttonLabel={
                statusChangeButtonDisabled
                  ? '(In)activate'
                  : selectedItemsAreActiveOnly
                  ? 'Inactivate'
                  : selectedItemsAreInactiveOnly
                  ? 'Reactivate'
                  : ''
              }
            />
          </>
        )}
      </Can>
      <Can I='perform' this={addEditBulkOn.pricePromo}>
        {() => (
          <>
            <ButtonWithDisabledInfo
              buttonDisabledShowTooltip={pricePromoStatusChangeButtonDisabled}
              tooltipTitle='Status change cannot be performed on:'
              disabledReasons={statusChangeButtonDisabledInfo}
              startIcon={
                pricePromoSelectedItemsAreActiveOnly ? (
                  <Block />
                ) : pricePromoSelectedItemsAreInactiveOnly ? (
                  <HowToReg />
                ) : (
                  ''
                )
              }
              onClick={() =>
                pricePromoSelectedItemsAreActiveOnly
                  ? dispatch(setPricePromoBulkInactivationConfirmation(!pricePromoBulkInactivationConfirmation))
                  : dispatch(setPricePromoBulkActivationConfirmation(!pricePromoBulkActivationConfirmation))
              }
              buttonLabel={
                pricePromoStatusChangeButtonDisabled
                  ? '(In)activate Promo'
                  : pricePromoSelectedItemsAreActiveOnly
                  ? 'Inactivate Promo'
                  : pricePromoSelectedItemsAreInactiveOnly
                  ? 'Activate Promo'
                  : ''
              }
            />
          </>
        )}
      </Can>
      <Button
        aria-label='export'
        color='primary'
        onClick={e => exportSelectedToExcel()}
        size='medium'
        variant='contained'
        startIcon={<CloudDownload />}
      >
        Export To Excel
      </Button>
    </ActionWrapper>
  )

  return pricePostingsList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Price Postings</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        {isUserAbc 
          && (singlePricePostSelected.status === 'Active' || singlePricePostSelected.status === 'Pending') ? (
          <>
            {singlePricePostSelected.pricePromotion ? (
              <>
                <Divider />
                <ActionItems onClick={() => dispatch(setPricePromoInactivationConfirmation(true))}>
                  <Edit color='primary' fontSize='small' />
                  <ActionLabels children='Inactivate Promo' variant='body1' />
                </ActionItems>
              </>
            ) : (
              <>
                <Divider />
                <ActionItems onClick={() => dispatch(setPricePromoActivationConfirmation(true))}>
                  <Edit color='primary' fontSize='small' />
                  <ActionLabels children='Activate Promo' variant='body1' />
                </ActionItems>
              </>
            )}
          </>
        ) : (
          ''
        )}
        {isUserAbc 
          && singlePricePostSelected.status === 'Active' ? (
          <>
            <StyledDivider />
            <ActionItems onClick={handleViewPricePromotionAudit}>
              <CloudDownload color='primary' fontSize='small' />
              <ActionLabels children='Promo Report' variant='body1' />
            </ActionItems>
          </>
        ) : (
          ''
        )}
        {singlePricePostSelected.status === 'Old' ? (
          ''
        ) : (
          <Can I='perform' this={addEditBulkOn.pricePostings}>
            {() =>
              abilityFunc.can(
                'edit/view',
                new CheckLicensee({
                  ifCreatedByLicenseeIs:
                    singlePricePostSelected.createdByLicensee &&
                    singlePricePostSelected.createdByLicensee.id,
                })
              ) ? (
                <>
                  {singlePricePostSelected.status === 'Inactive' ? (
                    <>
                      <StyledDivider />
                      <ActionItems onClick={() => dispatch(setActivationConfirmation(true))}>
                        <HowToReg color='primary' fontSize='small' />
                        <ActionLabels children='Reactivate' variant='body1' />
                      </ActionItems>
                    </>
                  ) : (
                    <>
                      <Divider />
                      <ActionItems onClick={handleEditSelected}>
                        <Edit color='primary' fontSize='small' />
                        <ActionLabels children='Edit' variant='body1' />
                      </ActionItems>
                      {singlePricePostSelected.status === 'Active' && (
                        <>
                          <StyledDivider />
                          <ActionItems
                            onClick={() => handleCompetitiveSelect(true)}
                            disabled={
                              singlePricePostSelected.competitivePostId &&
                              isDateSameAsToday(singlePricePostSelected.updatedAt)
                            }
                          >
                            <InsertChart color='primary' fontSize='small' />
                            <ActionLabels children='Competitive' variant='body1' />
                          </ActionItems>

                          <StyledDivider />
                          <ActionItems onClick={() => dispatch(setInactivationConfirmation(true))}>
                            <Block color='error' fontSize='small' />
                            <ActionLabels children='Inactivate' variant='body1' />
                          </ActionItems>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                singlePricePostSelected.status === 'Active' && (
                  <>
                    <StyledDivider />
                    <ActionItems
                      onClick={() => handleCompetitiveSelect(false)}
                      disabled={
                        singlePricePostSelected.competitivePostId &&
                        isDateSameAsToday(singlePricePostSelected.updatedAt)
                      }
                    >
                      <InsertChart color='primary' fontSize='small' />
                      <ActionLabels children='Competitive' variant='body1' />
                    </ActionItems>
                  </>
                )
              )
            }
          </Can>
        )}
      </Popover>
      <ListComponent
        mainTitle='Price Postings'
        listContent={
          <ReusableTable
            headers={pricePostingsHeaders}
            tableData={updatedPricePostingsList}
            pagination={true}
            selectionOption={true}
            totalCount={pricePostingsTotalCount}
            fetchingTotalCount={fetchingTotalCount}
            onSingleItemSelected={onSingleItemSelected}
            handleBulkSelection={handleBulkSelection}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleSelectAllRows={handleSelectionOfAllRows}
            handleClearSelection={handleClearSelection}
            actionItemsOnSelection={actionItemsOnSelection}
          />
        }
        editComp={
          <EditPricePosting
            cannotSelectManufacturer={abilityFunc.cannot('select', 'manufacturerOnPricePosting')}
            onCancel={onQuickViewClosedManufacturerCheck}
            pricePosting={pricePostingDetails}
          />
        }
        viewComp={<ViewPricePosting />}
        modalEditTitle={`${pricePostingDetails.id ? 'Edit Price Posting' : 'Add Price Posting'}`}
        modalViewTitle='Price Posting Details'
        onModalClosed={onQuickViewClosedManufacturerCheck}
        expandedOptions={<PricePostingsFilters pricePostingsFilters={pricePostingsFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isPricePostingsFilterOpen}
        areFiltersUsed={areFiltersUsed}
        loadingText={loadingText}
        isDialogOpen={isPricePostingShown}
        onAddButtonClicked={onAdd}
        listType={listType}
        handleListType={handlePPListType}
        isBulkUploadAvailable={true}
        bulkUploadContent={<PricePostingBulkUpload />}
        onBulkUploadClicked={handleBulkUploadClicked}
        bulkUploadTitle='Price Posting Bulk Upload'
        isBulkDialogOpen={isPPBulkUploadDialogOpen}
        onBulkUploadModalClosed={onBulkUploadModalClosed}
        listTypeRadioLabels={['All Price Postings', 'My Price Postings']}
        permitRadioFilters={'onPricePostingsList'}
      />
      <ConfirmationModal
        isConfirmationModalOpen={inactivationConfirmation}
        confirmationList={[
          `You are about to set one or more price postings to an inactive status.
           If there any pending price changes associated with these price postings, they will be removed.
           Please confirm you wish to proceed.`,
        ]}
        onConfirmation={handleInactivation}
        onConfirmationModalClosed={() => dispatch(setInactivationConfirmation(false))}
        type='inactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={activationConfirmation}
        confirmationList={['Are you sure you want to activate this price post?']}
        onConfirmation={handleActivation}
        onConfirmationModalClosed={() => dispatch(setActivationConfirmation(false))}
      />
      <ConfirmationModal
        isConfirmationModalOpen={bulkInactivationConfirmation}
        confirmationList={[
          `You are about to set one or more price postings to an inactive status.
           If there any pending price changes associated with these price postings, they will be removed.
           Please confirm you wish to proceed.`,
        ]}
        onConfirmation={handleBulkInactivation}
        onConfirmationModalClosed={() => dispatch(setBulkInactivationConfirmation(false))}
        type='inactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={bulkActivationConfirmation}
        confirmationList={['Are you sure you want to reactivate the selected price post(s)?']}
        onConfirmation={handleBulkActivation}
        onConfirmationModalClosed={() => dispatch(setBulkActivationConfirmation(false))}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePromoActivationConfirmation}
        confirmationList={['Are you sure you want to activate the price promotion of this price post?']}
        onConfirmation={handlePricePromoActivation}
        onConfirmationModalClosed={() => dispatch(setPricePromoActivationConfirmation(false))}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePromoInactivationConfirmation}
        confirmationList={[`Are you sure you want to Inactivate the price promotion of this price post?`]}
        onConfirmation={handlePricePromoInactivation}
        onConfirmationModalClosed={() => dispatch(setPricePromoInactivationConfirmation(false))}
        type='inactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePromoBulkActivationConfirmation}
        confirmationList={['Are you sure you want to activate the price promotion of the selected price post(s)?']}
        onConfirmation={handlePricePromoBulkActivation}
        onConfirmationModalClosed={() => dispatch(setPricePromoBulkActivationConfirmation(false))}
      />      
      <ConfirmationModal
        isConfirmationModalOpen={pricePromoBulkInactivationConfirmation}
        confirmationList={[`Are you sure you want to inactivate the price promotion of the selected price post(s)?`]}
        onConfirmation={handlePricePromoBulkInactivation}
        onConfirmationModalClosed={() => dispatch(setPricePromoBulkInactivationConfirmation(false))}
        type='inactivate'
      />

      <QuickView
        title='Competitive Price Posting'
        onClose={handleCompetingModalClosed}
        dialogContent={<CompetitivePricePosting />}
        isDialogOpen={isCompetingUsingMyOwn}
        isSmallDialog={false}
      />
      <QuickView
        title='Multiple Price Change'
        onClose={() => dispatch(setIsMultiPriceChangeModalOpen(false))}
        dialogContent={<MultiplePriceChange />}
        isDialogOpen={isMultiPriceChangeModalOpen}
        isSmallDialog={false}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePostingConfirmation}
        confirmationList={pricePostingConfirmationMessage}
        onConfirmation={handlePricePostingConfirmation}
        onConfirmationModalClosed={handlePricePostingConfirmationCancel}
        extraMessage={extraMessageOnConfirmationModal}
      />
    </>
  )
}

export default PricePostingsList
