import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { productSizeStatus } from 'common/constants'
import { convertSizeOfBBL } from 'common/helper'
import {
  fetchProductSizes,  
  clearFilters,
  setProductSizePageNumber,
  setFiltersUsed,
} from 'ducks/productSizes'

const ProductSizeFilters = () => {
  const dispatch = useDispatch()
  const { productSizesListForDropdown } = useSelector(
    store => store.productSizes
  )
  const sizes = productSizesListForDropdown.results.map(item => {
    return `{"value": ${item.size},"label": "${convertSizeOfBBL(
      item.size,
      item.unit.unit
    )}"}`
  })
  const containerTypes = productSizesListForDropdown.results.map(item => {
    return `{"value": ${item.containerType.id},"label": "${item.containerType.type}"}`
  })
  const units = productSizesListForDropdown.results.map(item => {
    return `{"value": ${item.unit.id},"label": "${item.unit.unit}"}`
  })
  const sizeDropdown = [...new Set(sizes)]
    .map(JSON.parse)
    .sort((a, b) => (a.label > b.label ? 1 : -1))
  const containerTypeDropdown = [...new Set(containerTypes)]
    .map(JSON.parse)
    .sort((a, b) => (a.label > b.label ? 1 : -1))
  const unitDropdown = [...new Set(units)]
    .map(JSON.parse)
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  const onApplyingFilters = formikProps => {    
    formikProps.handleSubmit()
    dispatch(setProductSizePageNumber(0))
    dispatch(setFiltersUsed(true))    
    dispatch(fetchProductSizes(formikProps.values.productSizeFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue(`productSizeFilters`, {
      size: '',
      unit: '',
      containerType: '',
      status: '',
    })
  }

  return (
    <Form>
      <Field
        name='productSizeFilters'
        render={formikProps => {
          formikProps.form.values.productSizeFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.productSizeFilters)
          )
          const formikValues = formikProps.form.values

          const handleAutoCompleteChange = (field, event) => {
            const selectedValue = event && event.length > 0 ? event : ""
            //const selectedValue = event ? event : []
            formikProps.form.setFieldValue(
              `productSizeFilters.${field}`,
              selectedValue
            )
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Size'
                    name='productSizeFilters.size'
                    selectedValue={formikValues.productSizeFilters.size}
                    formikProps={formikProps.form}
                    dropDownOptions={sizeDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('size', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Units'
                    name='productSizeFilters.unit'
                    selectedValue={formikValues.productSizeFilters.unit}
                    formikProps={formikProps.form}
                    dropDownOptions={unitDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('unit', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Container Type'
                    name='productSizeFilters.containerType'
                    selectedValue={
                      formikValues.productSizeFilters.containerType
                    }
                    formikProps={formikProps.form}
                    dropDownOptions={containerTypeDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('containerType', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Status'
                    name='productSizeFilters.status'
                    formikProps={formikProps.form}
                    selectedValue={formikValues.productSizeFilters.status}
                    dropDownOptions={productSizeStatus}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                isDisabled={!formikProps.form.isValid}
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(ProductSizeFilters)

export default WithFormikWrapper
