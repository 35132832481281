import React from 'react'
import styled from 'styled-components'
import { Divider } from '@material-ui/core'

const TopMargin = styled(Divider)`
  && {
    background-color: ${props => props.theme.gold};
    height: 4px;
  }
`

const CustomDivider = () => {
  return <TopMargin />
}

export default CustomDivider
