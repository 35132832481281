import React from 'react'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  updatePackageConfiguration,
  addNewPackageConfiguration,
  setPackageConfigurationShown,
  resetPackageConfigurationDetails,
} from 'ducks/packageConfigurations'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import { Grid } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import {
  packageConfigRequestedByLicensee,
  toggleConfigReqDialog,
} from 'ducks/configurationRequests'

const validations = Yup.object().shape({
  packageConfiguration: Yup.object().shape({
    package: Yup.string().required('Required'),
  }),
})

const EditPackageConfiguration = ({ isRequestedByLicensee }) => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)
  //   const abilityFunc = useContext(AbilityContext)

  const onAddPackageConfiguration = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      let finalObject = formikValues.values.packageConfiguration
      if (isRequestedByLicensee) {
        dispatch(packageConfigRequestedByLicensee(finalObject))
      } else {
        if (formikValues.values.packageConfiguration.id) {
          dispatch(
            updatePackageConfiguration({
              ...finalObject,
              id: parseInt(finalObject.id),
            })
          )
        } else {
          dispatch(addNewPackageConfiguration(finalObject))
        }
      }
    }
  }

  return (
    <Form>
      <Field
        name='packageConfiguration'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.packageConfiguration = JSON.parse(
            JSON.stringify(formikProps.form.values.packageConfiguration)
          )
          const formikValues = formikProps.form.values

          const handleCancel = () => {
            dispatch(setPackageConfigurationShown(false))
            dispatch(resetPackageConfigurationDetails())
            if (isRequestedByLicensee) {
              dispatch(toggleConfigReqDialog(false))
            }
          }

          return (
            <>
              {isLoading ? (
                <LoadingIndicator
                  text={'Loading Package Configuration...'}
                  transparent
                />
              ) : (
                <>
                  <CustomCard
                    title={!isRequestedByLicensee && 'Package Configuration'}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Configuration'
                        name='packageConfiguration.package'
                        component={TextField}
                      />
                    </Grid>
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddPackageConfiguration(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={
                      formikValues.packageConfiguration.id && 'update'
                    }
                    isDisabled={
                      formikValues.packageConfiguration.id &&
                      !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditPackageConfiguration)

export default WithFormikWrapper
