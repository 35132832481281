import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import LoadingIndicator from 'components/common/LoadingIndicator'
import {
  fetchPricesTos,
  fetchPricesToByID,
  setPricesToShown,
  setPricesToPageNumber,
  setPricesToRequestedSort,
  resetPricesToDetails,
  updatePricesToOnConfirmation,
  setIsPricesToUsed,
  togglePricesToStatus,
  setStatusChangeConfirmationForPricesTo,
} from 'ducks/pricesTo'
import EditPricesTo from './EditPricesTo'
import ConfirmationModal from 'components/common/ConfirmationModal'
import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core'
import { Block, VerifiedUserOutlined, MoreVert, Edit } from '@material-ui/icons'
import styled from 'styled-components'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const PricesTo = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedPricesTo, setSelectedPricesTo] = useState(-1)
  const {
    pricesTosList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    selected,
    isPricesToShown,
    pricesToDetails,
    isPricesToUsed,
    statusChangeConfirmationForPricesTo,
  } = useSelector(store => store.pricesTo)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(fetchPricesTos())
  }, [dispatch, order, orderBy, rowsPerPage, pageNumber])

  const PricesTosHeaders = [
    { id: 'name', label: 'Prices To', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  const onItemSelected = pricesTo => {
    setAnchorEl(null)
    dispatch(fetchPricesToByID(pricesTo.id))
  }

  const handlePageNumber = newPage => {
    dispatch(setPricesToPageNumber(newPage))
  }
  const handleRequestSort = value => {
    dispatch(setPricesToRequestedSort(value))
  }

  const onQuickViewClosed = () => {
    dispatch(resetPricesToDetails())
    dispatch(setPricesToShown(false))
  }
  const onAdd = () => {
    dispatch(resetPricesToDetails())
    dispatch(setPricesToShown(true))
  }
  const handleActionOpen = (event, pricesTo) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setSelectedPricesTo({
      ...pricesTo,
      id: parseInt(pricesTo.id),
    })
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }
  const handleStatusChange = () => {
    setAnchorEl(null)
    dispatch(setStatusChangeConfirmationForPricesTo(true))
  }

  const openAction = Boolean(anchorEl)

  const updatedPricesTosList = pricesTosList.results
    ? pricesTosList.results.map(priceTo => ({
        id: priceTo.id,
        name: priceTo.name,
        status: priceTo.status,
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='true'
              onClick={event => handleActionOpen(event, priceTo)}
              color='primary'
            >
              <MoreVert color='primary' />
            </IconButton>
          </>
        ),
      }))
    : []

  return pricesTosList.firstLoad ? (
    <LoadingIndicator loadingText={loadingText} />
  ) : (
    <>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={() => onItemSelected(selectedPricesTo)}>
          <Edit color='primary' fontSize='small' />
          <ActionLabels children='Edit' variant='body1' />
        </ActionItems>
        <Divider />
        {selectedPricesTo.status === 'Active' ? (
          <ActionItems onClick={handleStatusChange}>
            <Block color='error' fontSize='small' />
            <ActionLabels children='Deactivate' variant='body1' />
          </ActionItems>
        ) : (
          <ActionItems onClick={handleStatusChange}>
            <VerifiedUserOutlined color='primary' fontSize='small' />
            <ActionLabels children='Activate' variant='body1' />
          </ActionItems>
        )}
      </Popover>
      <ListComponent
        mainTitle='Prices To'
        listContent={
          <ReusableTable
            headers={PricesTosHeaders}
            tableData={updatedPricesTosList}
            pagination={true}
            selectionOption={false}
            totalCount={pricesTosList.totalCount}
            onSingleItemSelected={onItemSelected}
            setPageNumber={handlePageNumber}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={selected}
          />
        }
        editComp={<EditPricesTo pricesTo={pricesToDetails} />}
        modalEditTitle={pricesToDetails.id ? 'Edit Price To' : 'Add Price To'}
        modalViewTitle='Price To'
        onModalClosed={onQuickViewClosed}
        loadingText={loadingText}
        isDialogOpen={isPricesToShown}
        onAddButtonClicked={onAdd}
        smallTable={true}
      />
      <ConfirmationModal
        isConfirmationModalOpen={isPricesToUsed}
        confirmationList={[
          'This setting is currently being used by one or more price posts, if you confirm the change, the current setting will become inactive and the change will be added as a new item.',
        ]}
        onConfirmation={() => dispatch(updatePricesToOnConfirmation())}
        onConfirmationModalClosed={() => dispatch(setIsPricesToUsed(false))}
      />
      <ConfirmationModal
        isConfirmationModalOpen={statusChangeConfirmationForPricesTo}
        confirmationList={[
          `Please confirm if you want to ${
            selectedPricesTo.status === 'Active' ? 'deactivate' : 'reactivate'
          } "Prices To" item.`,
        ]}
        onConfirmation={() => dispatch(togglePricesToStatus(selectedPricesTo))}
        onConfirmationModalClosed={() =>
          dispatch(setStatusChangeConfirmationForPricesTo(false))
        }
      />
    </>
  )
}

export default PricesTo
