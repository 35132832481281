import React from 'react'
import { Event } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DatePicker = ({ field, form, ...props }) => {
  const handleChange =
    props.onChange ||
    (date => {
      form.setFieldValue(field.name, date)
    })
  return (
    <KeyboardDatePicker
      {...field}
      {...props}
      clearable
      inputVariant='outlined'
      placeholder='MM/DD/YYYY'
      onChange={handleChange}
      value={props.value}
      format='MM/dd/yyyy'
      keyboardIcon={<Event titleAccess='Show date picker calendar.' />}
    />
  )
}

export default DatePicker
