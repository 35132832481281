import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import authReducer from 'ducks/authentication'
import snackbar from 'ducks/snackbar'
import sidebar from 'ducks/sidebar'
import global from 'ducks/global'
import quickView from 'ducks/quickView'
import legalEntity from 'ducks/legalEntity'
import bulkUpload from 'ducks/bulkUpload'
import abcUsers from 'ducks/abcUsers'
import legalEntityUser from 'ducks/legalEntityUser'
import profileView from 'ducks/profileView'
import products from 'ducks/products'
import productSizes from 'ducks/productSizes'
import pricesTo from 'ducks/pricesTo'
import packageConfigurations from 'ducks/packageConfigurations'
import { Auth, API } from 'aws-amplify'
import pricePostings from 'ducks/pricePostings'
import pricePostingsSchedule from 'ducks/pricePostingsSchedule'
import competitivePricePosting from 'ducks/competitivePricePosting'
import publicLayout from 'ducks/public/layout'
import publicPricePosts from 'ducks/public/pricePosts'
import publicProducts from 'ducks/public/products'
import configurationRequests from 'ducks/configurationRequests'
import newsUpdate from 'ducks/newsUpdate'

const services = {
  AmplifyAuth: Auth,
  AmplifyAPI: API,
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar,
    sidebar,
    global,
    quickView,
    legalEntity,
    bulkUpload,
    abcUsers,
    legalEntityUser,
    profileView,
    products,
    productSizes,
    pricesTo,
    packageConfigurations,
    pricePostings,
    pricePostingsSchedule,
    competitivePricePosting,
    configurationRequests,
    newsUpdate,
    /*---- Public store items -----*/
    publicLayout,
    publicPricePosts,
    publicProducts,
  },
  middleware: [
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: services,
      },
    }),
  ],
  devTools: window.location.port,
})

export default store
