import React, { useEffect, useContext } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { getRoles, setEditMode } from 'ducks/global'
import {
  addNewUser,
  updateAbcUser,
  setAbcUserShow,
  resetUserDetails,
} from 'ducks/abcUsers'
import { AbilityContext } from 'common/abilityContext'
import NumericInput from 'components/common/NumericInput'

const validations = Yup.object().shape({
  abcUser: Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Required'),
    role: Yup.string().required('Required'),
  }),
})

const EditABCUser = () => {
  const dispatch = useDispatch()
  const { isLoading, roles } = useSelector(store => store.global)
  const { user } = useSelector(store => store.auth)
  const abilityFunc = useContext(AbilityContext)

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  const updatedRolesList = roles
    ? roles.filter(role => abilityFunc.can('create', role.name) === true)
    : []

  const onAddAbcUser = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      if (formikValues.values.abcUser.id) {
        // Check if email has changed (used to update Cognito user's email)
        const newEmail = formikValues.values.abcUser.email
        const hasEmailChanged =
          newEmail.length > 0 &&
          formikValues.initialValues.abcUser.email !== newEmail
        dispatch(updateAbcUser(formikValues.values.abcUser, hasEmailChanged))
      } else {
        dispatch(addNewUser(formikValues.values.abcUser))
      }
    }
  }

  return (
    <Form>
      <Field
        name='abcUser'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.abcUser = JSON.parse(
            JSON.stringify(formikProps.form.values.abcUser)
          )
          const formikValues = formikProps.form.values

          const handleCancel = () => {
            if (!formikValues.abcUser.id) {
              dispatch(setAbcUserShow(false))
              dispatch(resetUserDetails())
            }
            dispatch(setEditMode(false))
          }

          return (
            <>
              {isLoading || updatedRolesList.length === 0 ? (
                <LoadingIndicator
                  text={`Loading ${isLoading ? 'User' : 'Roles'}...`}
                  transparent
                />
              ) : (
                <>
                  <CustomCard title='Account Information'>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='First Name'
                        name='abcUser.firstName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Last Name'
                        name='abcUser.lastName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Email'
                        name='abcUser.email'
                        autoComplete='email'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='abcUser.phoneNumber'
                        label='Phone'
                        id='phone'
                        component={NumericInput}
                        autoComplete='phone'
                        format={'### ### #### ### ##'}
                      />
                    </Grid>
                    {formikProps.form.values.abcUser.id !== user.id ? (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field
                          variant='outlined'
                          margin='normal'
                          select
                          required
                          fullWidth
                          label='Role'
                          name='abcUser.role'
                          component={TextField}
                        >
                          Role *
                          {updatedRolesList.map(val => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddAbcUser(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.abcUser.id && 'update'}
                    isDisabled={
                      formikValues.abcUser.id && !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditABCUser)

export default WithFormikWrapper
