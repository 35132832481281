import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLEsForDropDown } from 'ducks/global'
import { Form, Field, Formik } from 'formik'
import CustomCard from 'components/common/CustomCard'
import { Grid, Button } from '@material-ui/core'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import {
  getListOfMyPPForCompeting,
  setBulkCompetitiveItemsSelected,
  setCompetitiveListPageNumber,
  setCompetitiveListRowsPerPage,
  setCompetitiveListRequestedSort,
  getCompetingWithPPList,
  setCompetitorListSearchCriteria,
  setPriceAndContainerFormType,
  setBulkSelectionOfCompetingWithItems,
} from 'ducks/competitivePricePosting'
import ReusableTable from 'components/common/ReusableTable'
import { formatNumberToTwoDecimal } from 'common/helper'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Search, KeyboardReturn } from '@material-ui/icons'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CompetitorList from './CompetitorList'
import PriceAndContainerForm from './PriceAndContainerForm'
import { formTypeValues } from 'common/constants'
import { TextField } from 'formik-material-ui'

const SearchButtonWrapper = styled(Grid)`
  && {
    margin-top: 14px;
  }
`

const StyledButton = styled(Button)`
  && {
    width: 100%;
    transition: 250ms all ease-in;
  }
`

const CustomButton = styled(Button)`
  && {
    width: 180px;
    top: 51px;
    position: absolute;
    right: 0;
    margin-right: 20px;
    transition: 250ms all ease-in;
  }
`

const competitiveListHeaders = [
  { id: 'manufacturer', label: 'Manufacturer', isSortable: true },
  { id: 'productName', label: 'Product Name', isSortable: true },
  { id: 'tradeName', label: 'Trade Name', isSortable: true },
  {
    id: 'package',
    label: 'Package Configuration',
    isSortable: true,
  },
  { id: 'size', label: 'Size', isSortable: true },
  { id: 'county', label: 'County', isSortable: true },
  { id: 'pricesTo', label: 'Prices To', isSortable: true },
  { id: 'price', label: 'Price($)', isSortable: true },
  {
    id: 'containerCharge',
    label: 'Container Charge($)',
    isSortable: true,
  },
  { id: 'createdByLicensee', label: 'By Licensee', isSortable: true },
  { id: 'effectiveDate', label: 'Effective Date', isSortable: true },
  { id: 'createdAt', label: 'Submit Date', isSortable: true },
  { id: 'status', label: 'Status', isSortable: true },
]

const CompetitivePricePosting = () => {
  const dispatch = useDispatch()

  //---------Get data from store----------
  const {
    myCompetingPricePosts,
    competitiveListPageNumber,
    competitiveListOrder,
    competitiveListOrderBy,
    competitiveListRowsPerPage,
    bulkCompetitiveItemsSelected,
    listOfCompetingWithPP,
    priceAndContainerFormType,
    bulkSelectionOfCompetingWithItems,
    myCompetingPpTotalCount,
  } = useSelector(store => store.competitivePricePosting)

  const {
    isLoading,
    loadingText,
    lEsForDropDown,
    fetchingTotalCount,
  } = useSelector(store => store.global)

  const { isCompetingUsingOthers } = useSelector(store => store.pricePostings)

  const currentUserLeID = useSelector(store => store.auth.user.legalEntity.id)

  //--------------When some thing changes-------
  useEffect(() => {
    dispatch(getListOfMyPPForCompeting())
    if (!isCompetingUsingOthers) {
      dispatch(getLEsForDropDown())
    }
  }, [
    dispatch,
    competitiveListOrder,
    competitiveListOrderBy,
    competitiveListPageNumber,
    competitiveListRowsPerPage,
    isCompetingUsingOthers,
  ])

  const updatedLEsForDropDown = lEsForDropDown.filter(
    le => le.value !== currentUserLeID
  )

  //---------Methods for my list------------
  const onSingleItemSelection = pricePosting => {
    //might come in the future
  }

  const onBulkSelection = items => {
    const bulkSelectedIDs = bulkCompetitiveItemsSelected.map(d => d.id)
    const singlePageSelectedIDs = myCompetingPricePosts.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkCompetitiveItemsSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          myCompetingPricePosts.results.map(finalObject => ({
            id: finalObject.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            county: finalObject.county,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = myCompetingPricePosts.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            county: finalObject.county,
          }))

        newSelected = newSelected.concat(
          bulkCompetitiveItemsSelected,
          extractedValue
        )
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkCompetitiveItemsSelected.slice(1))
      } else if (selectedIndex === bulkCompetitiveItemsSelected.length - 1) {
        newSelected = newSelected.concat(
          bulkCompetitiveItemsSelected.slice(0, -1)
        )
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkCompetitiveItemsSelected.slice(0, selectedIndex),
          bulkCompetitiveItemsSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setBulkCompetitiveItemsSelected(newSelected))
    if (newSelected.length === 0) {
      dispatch(setPriceAndContainerFormType(false))
    }
  }

  const handleSelectionOfAllRows = () => {
    //argument 'true' acts as a flag for selectAll
    dispatch(getListOfMyPPForCompeting(true))
  }

  const handleClearAllSelection = () => {
    dispatch(setBulkCompetitiveItemsSelected([]))
    dispatch(setPriceAndContainerFormType(false))
  }

  const handlePageNumber = newPage => {
    dispatch(setCompetitiveListPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setCompetitiveListRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setCompetitiveListRequestedSort(value))
  }

  let myPricePosts = []

  myPricePosts = myCompetingPricePosts.results.map((pricePosting, index) => {
    const updatedItem = {
      id: pricePosting.id,
      manufacturer: pricePosting.manufacturer.name,
      productName: pricePosting.product.name,
      tradeName: pricePosting.product.tradeName,
      package: pricePosting.package.package,
      size: `${pricePosting.productSize.size} ${pricePosting.productSize.unit.unit}`,
      county: pricePosting.county,
      pricesTo: pricePosting.pricesTo.name,
      price: formatNumberToTwoDecimal(pricePosting.price),
      containerCharge: formatNumberToTwoDecimal(pricePosting.containerCharge),
      createdByLicensee:
        pricePosting.createdByLicensee && pricePosting.createdByLicensee.name,
      effectiveDate: format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
      createdAt: format(pricePosting.createdAt, 'MM/dd/yyyy'),
      status: pricePosting.status,
    }
    return updatedItem
  })

  const actionItemsOnSelection = (
    <CustomButton
      aria-label='compete'
      color='primary'
      onClick={e =>
        dispatch(setPriceAndContainerFormType(formTypeValues.priceOnly))
      }
      size='small'
      variant='contained'
    >
      <KeyboardReturn />
      &nbsp;&nbsp;
      {'Continue'}
    </CustomButton>
  )

  return (
    <>
      {((!isCompetingUsingOthers && myCompetingPricePosts.count > 1) ||
        (isCompetingUsingOthers && myCompetingPricePosts.count > 0)) && (
        <CustomCard
          title={
            isCompetingUsingOthers
              ? 'Select your price posting(s) to use as competitive:'
              : 'We found similar items with different counties and/or prices, feel free to add them to your selection:'
          }
          children={
            <ReusableTable
              headers={competitiveListHeaders}
              tableData={myPricePosts}
              pagination={true}
              selectionOption={true}
              totalCount={myCompetingPpTotalCount}
              fetchingTotalCount={fetchingTotalCount}
              onSingleItemSelected={onSingleItemSelection}
              handleBulkSelection={onBulkSelection}
              setPageNumber={handlePageNumber}
              setRowsPerPage={handleRowsPerPage}
              setRequestedSort={handleRequestSort}
              pageNumber={competitiveListPageNumber}
              rowsPerPage={competitiveListRowsPerPage}
              order={competitiveListOrder}
              orderBy={competitiveListOrderBy}
              bulkSelected={bulkCompetitiveItemsSelected}
              handleSelectAllRows={handleSelectionOfAllRows}
              canChangeRowsPerPage={[5, 10, 20, 50, 100]}
              handleClearSelection={handleClearAllSelection}
              actionItemsOnSelection={
                isCompetingUsingOthers && actionItemsOnSelection
              }
              noRowsFoundMessage="Competitive price posting can be done only once per day on each item, make sure you select the correct competitor's price post for better results."
            />
          }
        />
      )}
      {isLoading ? (
        <LoadingIndicator text={loadingText} />
      ) : isCompetingUsingOthers && myCompetingPricePosts.count === 0 ? (
        "There are no price posting(s) created under your licensee which match the selected competitive price post's criteria, please try competing with different price posting."
      ) : (
        ''
      )}
      {!isCompetingUsingOthers && bulkCompetitiveItemsSelected.length > 0 && (
        <>
          <Formik
            initialValues={{ createdByLicensee: '', productLike: '' }}
            onSubmit={(searchCriteria, { setSubmitting }) => {
              dispatch(setCompetitorListSearchCriteria(searchCriteria))
              dispatch(setBulkSelectionOfCompetingWithItems([]))
              dispatch(getCompetingWithPPList()).then(() => {
                setTimeout(setSubmitting(false), 2000)
              })
            }}
          >
            <Form noValidate>
              <Field
                name='searchForCompetitor'
                render={formikProps => {
                  const handleAutoCompleteChange = (field, event) => {
                    formikProps.form.setFieldValue(`${field}`, event)
                  }

                  return (
                    <CustomCard
                      title='Select licensee or enter product to search for price postings you want to compete with:'
                      children={
                        <>
                          <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Field
                              placeHolderText='Licensee (Posted By)'
                              name='createdByLicensee'
                              formikProps={formikProps.form}
                              selectedValue={
                                formikProps.form.values.createdByLicensee
                              }
                              dropDownOptions={updatedLEsForDropDown}
                              onValueChange={event =>
                                handleAutoCompleteChange(
                                  'createdByLicensee',
                                  event
                                )
                              }
                              isMulti={false}
                              component={AutoCompleteComp}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Field
                              variant='outlined'
                              margin='normal'
                              fullWidth
                              id='productLike'
                              label='Product'
                              name='productLike'
                              component={TextField}
                            />
                          </Grid>
                          <SearchButtonWrapper
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                          >
                            <StyledButton
                              aria-label='search-competitor-list'
                              color='primary'
                              type='submit'
                              disabled={!formikProps.form.isValid}
                              variant='contained'
                            >
                              <Search />
                              &nbsp;&nbsp;{'Search'}
                            </StyledButton>
                          </SearchButtonWrapper>
                        </>
                      }
                    />
                  )
                }}
              />
            </Form>
          </Formik>
          {!listOfCompetingWithPP.firstLoad ? (
            listOfCompetingWithPP.count > 0 ? (
              <CompetitorList />
            ) : (
              'No results found, please try updating the search criteria.'
            )
          ) : (
            ''
          )}
        </>
      )}
      {((isCompetingUsingOthers && bulkCompetitiveItemsSelected.length > 0) ||
        bulkSelectionOfCompetingWithItems.length === 1) &&
        priceAndContainerFormType && <PriceAndContainerForm />}
    </>
  )
}

export default CompetitivePricePosting
