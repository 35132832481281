import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Popover,
} from '@material-ui/core'
import {
  MoreVert,
  PictureInPicture,
  Edit,
  Block,
  HowToReg,
} from '@material-ui/icons'
import styled from 'styled-components'
import { format } from 'date-fns'
import EditABCUser from './EditABCUser'
import ViewUserProfile from './ViewUserProfile'
import ABCUsersFilters from './ABCUsersFilters'
import LoadingIndicator from 'components/common/LoadingIndicator'
import ReusableTable from 'components/common/ReusableTable'
import {
  getListOfUsers,
  resetUserDetails,
  setAbcSelectedValue,
  setAbcPageNumber,
  setAbcRowsPerPage,
  setAbcRequestedSort,
  setIsAbcUsersFilterOpen,
  fetchABCUserByID,
  setAbcUserShow,
  activateAbcUser,
  deactivateAbcUser,
  setActivationConfirmation,
  setDeactivationConfirmation,
} from 'ducks/abcUsers'
import { setEditMode } from 'ducks/global'
import ListComponent from 'components/common/ListComponent'
import { Can } from 'common/abilityContext'
import { roles } from 'common/constants'
import ConfirmationModal from 'components/common/ConfirmationModal'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const ABCUsers = () => {
  const dispatch = useDispatch()
  // Used for popover actions state
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedUser, setSelectedUser] = useState(-1)

  const {
    abcUsersDetails,
    usersList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    selected,
    abcUsersFilters,
    isAbcUsersFilterOpen,
    isAbcUserShown,
    activationConfirmation,
    deactivationConfirmation,
  } = useSelector(store => store.abcUsers)
  const { loadingText } = useSelector(store => store.global)
  const { user } = useSelector(store => store.auth)

  useEffect(() => {
    dispatch(getListOfUsers())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  const onQuickViewClosed = () => {
    dispatch(resetUserDetails())
    dispatch(setAbcUserShow(false))
    dispatch(setEditMode(false))
  }

  const UsersHeaders = [
    { id: 'firstName', label: 'First Name', isSortable: true },
    { id: 'lastName', label: 'Last Name', isSortable: true },
    { id: 'email', label: 'Email', isSortable: true },
    { id: 'role', label: 'Role', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'createdAt', label: 'Added Date', isSortable: true },
    { id: 'updatedAt', label: 'Edited Date', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, user) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedUser(user)
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchABCUserByID(selectedUser.id))
  }

  const handleEditSelected = () => {
    setAnchorEl(null)
    dispatch(fetchABCUserByID(selectedUser.id, true))
  }

  const handleActivation = () => {
    setAnchorEl(null)
    dispatch(setActivationConfirmation(false))
    dispatch(activateAbcUser(selectedUser.id))
  }

  const handleDeactivation = () => {
    setAnchorEl(null)
    dispatch(setDeactivationConfirmation(false))
    dispatch(deactivateAbcUser(selectedUser.id))
  }

  const onItemSelected = user => {
    dispatch(fetchABCUserByID(user.id))
    setSelectedUser(user)
  }
  const onAdd = () => {
    dispatch(resetUserDetails())
    dispatch(setEditMode(true))
    dispatch(setAbcUserShow(true))
  }
  const handleFilter = value => {
    dispatch(setIsAbcUsersFilterOpen(value))
  }

  const openAction = Boolean(anchorEl)

  let updatedUsersList = []
  updatedUsersList = usersList.results.map((user, index) => ({
    ...user,
    role: user.role.name,
    createdAt: format(user.createdAt, 'MM/dd/yyyy'),
    updatedAt: format(user.updatedAt, 'MM/dd/yyyy'),
    actions: (
      <>
        <IconButton
          aria-label='actions'
          aria-haspopup='true'
          onClick={event => handleActionOpen(event, user)}
          color='primary'
        >
          <MoreVert color='primary' />
        </IconButton>
      </>
    ),
  }))

  const handleSelectedValue = value => {
    dispatch(setAbcSelectedValue(value))
  }
  const handlePageNumber = newPage => {
    dispatch(setAbcPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setAbcRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setAbcRequestedSort(value))
  }

  return usersList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>ABC Users</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        <Can
          I='edit'
          this={selectedUser.role ? selectedUser.role.name : roles.abcITAdmin}
        >
          {() => (
            <>
              <Divider />
              <ActionItems onClick={handleEditSelected}>
                <Edit color='primary' fontSize='small' />
                <ActionLabels children='Edit' variant='body1' />
              </ActionItems>
              {user.id !== selectedUser.id &&
              (selectedUser.status === 'Active' ||
                selectedUser.status === 'Pending') ? (
                <>
                  <Divider />
                  <ActionItems
                    onClick={() => dispatch(setDeactivationConfirmation(true))}
                  >
                    <Block color='error' fontSize='small' />
                    <ActionLabels children='Deactivate' variant='body1' />
                  </ActionItems>
                </>
              ) : (
                ''
              )}
              {user.id !== selectedUser.id &&
              selectedUser.status === 'Inactive' ? (
                <>
                  <Divider />
                  <ActionItems
                    onClick={() => dispatch(setActivationConfirmation(true))}
                  >
                    <HowToReg color='primary' fontSize='small' />
                    <ActionLabels children='Reactivate' variant='body1' />
                  </ActionItems>
                </>
              ) : (
                ''
              )}
            </>
          )}
        </Can>
      </Popover>
      <ListComponent
        mainTitle='ABC Users'
        listContent={
          <ReusableTable
            headers={UsersHeaders}
            tableData={updatedUsersList}
            pagination={true}
            selectionOption={false}
            totalCount={usersList.totalCount}
            showExportToExcel={false}
            onSingleItemSelected={onItemSelected}
            handleBulkSelection={handleSelectedValue}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={selected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
          />
        }
        editComp={<EditABCUser abcUser={abcUsersDetails} />}
        viewComp={<ViewUserProfile />}
        modalEditTitle={`${
          abcUsersDetails.id ? 'Edit ABC User' : 'Add ABC User'
        }`}
        modalViewTitle='ABC User Profile'
        onModalClosed={onQuickViewClosed}
        expandedOptions={<ABCUsersFilters abcUsersFilters={abcUsersFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isAbcUsersFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isAbcUserShown}
        onAddButtonClicked={onAdd}
      />
      <ConfirmationModal
        isConfirmationModalOpen={deactivationConfirmation}
        confirmationList={['Are you sure you want to deactivate this user?']}
        onConfirmation={handleDeactivation}
        onConfirmationModalClosed={() =>
          dispatch(setDeactivationConfirmation(false))
        }
        type='deactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={activationConfirmation}
        confirmationList={['Are you sure you want to reactivate this user?']}
        onConfirmation={handleActivation}
        onConfirmationModalClosed={() =>
          dispatch(setActivationConfirmation(false))
        }
      />
    </>
  )
}

export default ABCUsers
