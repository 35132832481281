import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingIndicator from 'components/common/LoadingIndicator'
import {
  Popover,
  MenuItem,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core'
import styled from 'styled-components'
import { PictureInPicture, Block, Add, MoreVert } from '@material-ui/icons'
import { Helmet } from 'react-helmet'
import {
  fetchConfigByID,
  setConfigRequestStatus,
  setConfigurationRequestPageNumber,
  setConfigurationRequestRowsPerPage,
  setConfigurationRequestSort,
  setConfigurationRequestFilterOpen,
  getConfigurationRequests,
  resetConfigRequestDetails,
} from 'ducks/configurationRequests'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import ConfigurationFilters from './ConfigurationFilters'
import ViewConfigurationRequest from './ViewConfigurationRequest'
import { format } from 'date-fns'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`
const configurationRequestsHeaders = [
  { id: 'type', label: 'Type', isSortable: true },
  { id: 'request', label: 'Change Request', isSortable: true },
  { id: 'createdAt', label: 'Submitted Date', isSortable: true },
  { id: 'reviewedAt', label: 'Review Date', isSortable: true },
  { id: 'status', label: 'Status', isSortable: true },
  { id: 'actions', label: 'Actions' },
]

const ConfigurationRequestList = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedConfig, setSelectedConfig] = useState(-1)

  const dispatch = useDispatch()

  const {
    configurationRequestList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    isConfigurationRequestFilterOpen,
    configurationRequestFilters,
    isConfigurationRequestShown,
  } = useSelector(store => store.configurationRequests)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getConfigurationRequests())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  /*----------Row item selection------------*/
  const onItemSelected = config => {
    dispatch(fetchConfigByID(config.id))
    setSelectedConfig(config)
  }

  const onQuickViewClosed = () => {
    dispatch(resetConfigRequestDetails())
  }

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, config) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setSelectedConfig(config)
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchConfigByID(selectedConfig.id))
  }

  const handleStatusChange = value => {
    dispatch(setConfigRequestStatus(selectedConfig, value))
    handleActionClose()
  }

  const openAction = Boolean(anchorEl)

  /*--------------Pagination------------*/
  const handlePageNumber = newPage => {
    dispatch(setConfigurationRequestPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setConfigurationRequestRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setConfigurationRequestSort(value))
  }
  const handleFilter = value => {
    dispatch(setConfigurationRequestFilterOpen(value))
  }

  const updatedConfigReqList = configurationRequestList.results.map(config => ({
    id: config.id,
    type: config.type,
    request: config.request,
    createdAt: format(config.createdAt, 'MM/dd/yyyy'),
    reviewedAt: config.reviewedAt
      ? format(config.reviewedAt, 'MM/dd/yyyy')
      : 'Not reviewed',
    status: config.status,
    actions: (
      <>
        <IconButton
          aria-label='actions'
          aria-haspopup='true'
          onClick={event => handleActionOpen(event, config)}
          color='primary'
        >
          <MoreVert color='primary' />
        </IconButton>
      </>
    ),
  }))

  return configurationRequestList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Configuration Requests</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        <>
          {selectedConfig.status === 'Pending' && (
            <>
              <Divider />
              <ActionItems onClick={() => handleStatusChange('Approve')}>
                <Add color='primary' fontSize='small' />
                <ActionLabels children='Approve' variant='body1' />
              </ActionItems>
              <Divider />
              <ActionItems onClick={() => handleStatusChange('Deny')}>
                <Block color='error' fontSize='small' />
                <ActionLabels children='Deny' variant='body1' />
              </ActionItems>
            </>
          )}
        </>
      </Popover>
      <ListComponent
        mainTitle='Configuration Requests'
        listContent={
          <ReusableTable
            headers={configurationRequestsHeaders}
            tableData={updatedConfigReqList}
            pagination={true}
            selectionOption={false}
            totalCount={configurationRequestList.totalCount}
            onSingleItemSelected={onItemSelected}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            bulkSelected={[]}
          />
        }
        viewComp={<ViewConfigurationRequest />}
        modalViewTitle='Configuration Request'
        onModalClosed={onQuickViewClosed}
        expandedOptions={
          <ConfigurationFilters
            configurationFilters={configurationRequestFilters}
          />
        }
        handleExpandingFilter={handleFilter}
        filterExpanded={isConfigurationRequestFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isConfigurationRequestShown}
      />
    </>
  )
}
export default ConfigurationRequestList
