import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { utcToZonedTime } from 'date-fns-tz'
import {
  setLoading,
  showError,
  setEditMode,
  showSuccess,
  fetchProductsWithTrade,
  fetchPackageConfiguration,
  fetchProductSizes,
  fetchPricesTo,
  getLEsForDropDown,
  setFetchingTotalCount,
  setLoadingProgress,
} from './global'
import { resetBulkUpload } from 'ducks/bulkUpload'
import {
  getPricePostingsScheduleSingleQuery,
  getPricePostingsScheduleQuery,
  getPricePostingsScheduleQueryAsLicensee,
  getPricePostingsScheduleAsLicenseeCount,
  getPricePostingsScheduleCount,
} from 'graphql/queries'
import {
  pricePostingsScheduleUpdateScheduleDate,
  createPricePostingsSchedule,
  deletePricePostSchedule,
} from 'graphql/mutations'
import {
  checkIfUserIsLicensee,
  checkIfUserIsABC,
  checkUserIsManufacturerNotCoc,
  exportToExcel,
  getToken,
} from 'common/helper'
import { californiaCounties, receivingMethod } from 'common/constants'
import uuid from 'uuid'

const fetchPricePostingsScheduleCount = graphQlParams => {
  return async dispatch => {
    dispatch(setFetchingTotalCount(true))
    if (graphQlParams.manufacturer) {
      await API.graphql(graphqlOperation(getPricePostingsScheduleAsLicenseeCount, graphQlParams))
        .then(res => {
          dispatch(
            setPricePostingsScheduleCount(res.data.pricePostingsScheduleAsLicenseeCount.count)
          )
        })
        .catch(err => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    } else {
      await API.graphql(graphqlOperation(getPricePostingsScheduleCount, graphQlParams))
        .then(res => {
          dispatch(setPricePostingsScheduleCount(res.data.pricePostingsScheduleCount.count))
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    }
    dispatch(setFetchingTotalCount(false))
  }
}

export const getPricePostingsScheduleList = (whereClause, getUpdatedTotalCount) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Postings Schedule...'))
    if (getUpdatedTotalCount) {
      dispatch(setPricePostingsScheduleCount(0))
    }
    const pricePostingsScheduleState = getState().pricePostingsSchedule
    const currentUser = getState().auth.user
    const userHasLicenseeRole = checkIfUserIsLicensee(currentUser.role.id)
    const userHasAbcRole = currentUser.role && checkIfUserIsABC(currentUser.role.id)

    let whereClauseFromFilters = {}

    if (userHasLicenseeRole) {
      // if the logged-in user has licensee role
      whereClauseFromFilters = {
        manufacturerId: parseInt(currentUser.legalEntity.id),
      }
    }

    const orderRule = `${pricePostingsScheduleState.orderBy}_${pricePostingsScheduleState.order}`

    if (whereClause) {
      whereClauseFromFilters = {
        ...whereClause,
        ...whereClauseFromFilters,
      }
      dispatch(setPricePostingsScheduleFilters(whereClauseFromFilters))
    } else {
      whereClauseFromFilters = {
        ...pricePostingsScheduleState.pricePostingsScheduleFilters,
        ...whereClauseFromFilters,
      }
    }

    let graphQlParams = {
      orderBy: orderRule,
      limit: pricePostingsScheduleState.rowsPerPage,
      offset: pricePostingsScheduleState.pageNumber * pricePostingsScheduleState.rowsPerPage,
    }

    let pricePostingsScheduleList

    if (userHasAbcRole) {
      if (whereClauseFromFilters) {
        graphQlParams.where = whereClauseFromFilters
      }
      await API.graphql(graphqlOperation(getPricePostingsScheduleQuery, graphQlParams))
        .then(res => {
          pricePostingsScheduleList = res.data
          dispatch(setPricePostingsScheduleList(pricePostingsScheduleList.pricePostingsSchedule))
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of scheduled price postings, please check your internet connection and try again. Thank you.'
            )
          )
        )
    } else {
      //  If it gets here, the user has the licensee role
      if (whereClauseFromFilters) {
        graphQlParams = {
          ...graphQlParams,
          manufacturerId: parseInt(whereClauseFromFilters.manufacturerId),
          where: whereClauseFromFilters,
        }
      }

      await API.graphql(graphqlOperation(getPricePostingsScheduleQueryAsLicensee, graphQlParams))
        .then(res => {
          pricePostingsScheduleList = res.data
          dispatch(
            setPricePostingsScheduleList(pricePostingsScheduleList.pricePostingsScheduleAsLicensee)
          )
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of scheduled price postings, please check your internet connection and try again. Thank you.'
            )
          )
        )
    }
    // If list is loaded and totalCount isn't retrieved yet, get count
    if (
      pricePostingsScheduleList &&
      (pricePostingsScheduleState.pricePostingsScheduleCount === 0 || getUpdatedTotalCount)
    ) {
      const params = {
        where: graphQlParams.where,
      }
      dispatch(fetchPricePostingsScheduleCount(params))
    }
    dispatch(setLoading(false))
  }
}

export const getPricePostingsScheduleListForBulkSelection = whereClause => {
  return async (dispatch, getState) => {
    const pricePostingsScheduleState = getState().pricePostingsSchedule
    const currentUser = getState().auth.user
    const userHasLicenseeRole = checkIfUserIsLicensee(currentUser.role.id)
    const userHasAbcRole = currentUser.role && checkIfUserIsABC(currentUser.role.id)

    let whereClauseFromFilters = {}

    if (userHasLicenseeRole) {
      // if the logged-in user has licensee role
      whereClauseFromFilters = {
        manufacturerId: parseInt(currentUser.legalEntity.id),
      }
    }

    const orderRule = `${pricePostingsScheduleState.orderBy}_${pricePostingsScheduleState.order}`

    if (whereClause) {
      whereClauseFromFilters = {
        ...whereClause,
        ...whereClauseFromFilters,
      }
      dispatch(setPricePostingsScheduleFilters(whereClauseFromFilters))
    } else {
      whereClauseFromFilters = {
        ...pricePostingsScheduleState.pricePostingsScheduleFilters,
        ...whereClauseFromFilters,
      }
    }

    let graphQlParams = {}
    graphQlParams = {
      orderBy: orderRule,
    }

    let pricePostingsScheduleList

    if (userHasAbcRole) {
      if (whereClauseFromFilters) {
        graphQlParams.where = whereClauseFromFilters
      }
      await API.graphql(graphqlOperation(getPricePostingsScheduleQuery, graphQlParams))
        .then(res => {
          pricePostingsScheduleList = res.data
          const selIds = pricePostingsScheduleList.pricePostingsSchedule.results.map(item => ({
            id: item.id,
          }))
          dispatch(setPricePostingsScheduleBulkSelection(selIds))
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of scheduled price postings for bulk selection. Please check your internet connection and try again. Thank you.'
            )
          )
        )
    } else {
      //  If it gets here, the user has the licensee role
      if (whereClauseFromFilters) {
        graphQlParams = {
          ...graphQlParams,
          manufacturerId: parseInt(whereClauseFromFilters.manufacturerId),
          where: whereClauseFromFilters,
        }
      }

      await API.graphql(graphqlOperation(getPricePostingsScheduleQueryAsLicensee, graphQlParams))
        .then(res => {
          pricePostingsScheduleList = res.data
          const selIds = pricePostingsScheduleList.pricePostingsScheduleAsLicensee.results.map(
            item => ({ id: item.id })
          )
          dispatch(setPricePostingsScheduleBulkSelection(selIds))
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of scheduled price postings for bulk selection. Please check your internet connection and try again. Thank you.'
            )
          )
        )
    }
    dispatch(setLoading(false))
  }
}

export const fetchPricePostingScheduleByID = (id, editMode = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Posting Schedule Details...'))

    const pricePostingScheduleRes = await API.graphql(
      graphqlOperation(getPricePostingsScheduleSingleQuery, {
        where: { id: parseInt(id) },
      })
    )
    const res = pricePostingScheduleRes.data.pricePostingsScheduleSingle

    if (res) {
      const pricePostingScheduleFromResponse = {
        id: res.id,
        manufacturer: {
          id: res.manufacturer.id,
          label: res.manufacturer.name,
        },
        uploadName: res.uploadName,
        uploadKey: res.uploadKey,
        numErrors: res.numErrors,
        createdBy: {
          id: res.createdBy.id,
          label: res.createdBy.firstName + ' ' + res.createdBy.lastName,
        },
        scheduledDate: res.scheduledDate,
        createdAt: res.createdAt,
        status: res.status,
        numRecords: res.numRecords,
        numProcessed: res.numProcessed,
        numErrors: res.numErrors,
      }

      dispatch(setPricePostingScheduleDetails(pricePostingScheduleFromResponse))
      dispatch(setPricePostingScheduleShow(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve Price Posting Schedle Details, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

// ------------------------------------------------
// ------- PROCESS PRICE POSTINGS SCHEDULE --------
// ------------------------------------------------
const submitPricePostingSchedule = finalPricePostingsScheduleList => {
  return async (dispatch, getState) => {
    try {
      let pricePostingScheduleErrors = []
      const { failedPricePostingsScheduleDuringBulk } = getState().pricePostingsSchedule
      const currentUser = getState().auth.user
      const { pricePostingScheduleDate } = getState().pricePostingsSchedule
      const { pricePostingScheduleBulkFilename } = getState().pricePostingsSchedule
      //If there are any errors stored, push them to the current error variable
      if (failedPricePostingsScheduleDuringBulk.length > 0) {
        pricePostingScheduleErrors = pricePostingScheduleErrors.concat(
          failedPricePostingsScheduleDuringBulk
        )
        dispatch(setFailedPricePostingsScheduleDuringBulk([]))
      }

      const manufacturerId = parseInt(currentUser.legalEntity.id)
      const fileName = pricePostingScheduleBulkFilename.split('.')[0] + '-' + new Date().getTime()

      // ------------ UPLOAD JSON TO S3 --------------
      const { key, preSignedUrl } = await fetch(
        `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/price-postings-staging-upload-presign?manufacturerId=${manufacturerId}&userId=${currentUser.id}&fileName=${fileName}&fileExtn=json`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + getToken({ code: currentUser.id }),
            'Content-Type': 'text/plain',
          },
        }
      )
        .then(response => response.json())
        .then(res => res)
        .catch(e => {
          console.log('error getting presigned URL: ', e)
        })

      const { status } = await fetch(preSignedUrl, {
        method: 'PUT',
        body: JSON.stringify(finalPricePostingsScheduleList),
      }).catch(e => {
        dispatch(showError('An error occurred while processing the file.'))
      })

      if (status == 200) {
        // ------------ PROCESS PRICE POSTINGS SCHEDULE ------------
        const res = await API.graphql(
          graphqlOperation(createPricePostingsSchedule, {
            data: {
              manufacturerId,
              uploadName: pricePostingScheduleBulkFilename,
              scheduledDate: utcToZonedTime(
                new Date(pricePostingScheduleDate).toISOString(),
                'America/Los_Angeles'
              ),
              createdBy: parseInt(currentUser.id),
              updatedBy: parseInt(currentUser.id),
              uploadKey: key,
              numRecords: finalPricePostingsScheduleList.length,
            },
          })
        )

        const success = res.data.createPricePostingsSchedule.success

        dispatch(
          setLoadingProgress({
            value: 100,
            buffer: 100,
          })
        )

        if (pricePostingScheduleErrors.length > 0) {
          dispatch(setFailedPricePostingsScheduleDuringBulk(pricePostingScheduleErrors))

          if (pricePostingScheduleErrors.length > 99500) {
            dispatch(
              showError(
                `Application has reached the limit of 100,000 errors. We created a file with these errors, please review your file and try again, thanks.`
              )
            )
          } else {
            dispatch(
              showError(
                `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
              )
            )
          }

          exportToExcel(pricePostingScheduleErrors, 'failedPricePosting')
          //
        } else if (success && !pricePostingScheduleErrors.length > 0) {
          dispatch(resetBulkUpload())
          dispatch(showSuccess('Price posting schedule was successful.'))
        }

        if (success) {
          dispatch(getPricePostingsScheduleList(false, true))
        } else {
          dispatch(setLoading(false))
        }
      }
    } catch (error) {
      console.error(JSON.stringify(error))
      dispatch(
        showError(
          `2. An error occurred during the bulk upload process, please make sure your file follows the template requirements and try again.` +
            JSON.stringify(error)
        )
      )
    }

    dispatch(setLoadingProgress(null))
  }
}

export const bulkPricePostingScheduleProcess = bulkArrayFromExcel => {
  return async (dispatch, getState) => {
    dispatch(setPPSBulkUploadDialog(false))
    dispatch(setLoading('Retrieving data for bulk price posting...'))
    dispatch(setFailedPricePostingsScheduleDuringBulk([]))
    const currentUser = getState().auth.user
    let pricePostingScheduleErrors = [],
      finalPricePostingsScheduleList = [],
      pricesToOptions,
      productsWithTradeOptions,
      packageConfigOptions,
      productSizesOptions,
      listOfNumberOfItemsPerRow = []

    const currentUserLicenseeName = currentUser.legalEntity.name
    // Wrap on try catch to remove loading and reset UI if an unexpected error is found
    try {
      // Check if the logged in user is only manufacturer and not license type COC
      let userIsOnlyManufacturerNotCOC = null

      userIsOnlyManufacturerNotCOC = checkUserIsManufacturerNotCoc(currentUser.legalEntity)

      let correctlyEnteredManufacturerList = []

      if (userIsOnlyManufacturerNotCOC) {
        //Check if all the items from the uploaded excel have manufacturer name same as logged in user's licensee's name
        //filter only the correctly entered manufacturer details(value, label)
        bulkArrayFromExcel
          .map(bulkItem => toLowerAndTrimWhenString(bulkItem.manufacturer))
          .includes(toLowerAndTrimWhenString(currentUserLicenseeName)) &&
          fetchProductsWithTrade(await dispatch(fetchProductsWithTrade(false))) //Fetch productName with tradeName, using logged in user's manufacturer ID, if no arguments were sent
      } else {
        //if it reaches here than the logged in user is other than *manufacturerOnlyNotCOC*
        //retrieve  all the configurations with IDs(packageConfig, productSizes, pricesTo)
        //use the list(receivingMethods, county ) from constants.js
        await dispatch(getLEsForDropDown()) // get the manufacturers list, to check if user entered correct manufacturer names in each row

        const existingLEsList = getState().global.lEsForDropDown
        const bulkArrayManufacturersList = bulkArrayFromExcel.map(bulkItem =>
          toLowerAndTrimWhenString(bulkItem.manufacturer)
        )

        //filter only the correctly entered manufacturer details(value, label )
        correctlyEnteredManufacturerList = existingLEsList.filter(existingLE =>
          bulkArrayManufacturersList.includes(toLowerAndTrimWhenString(existingLE.label))
        )

        //productName with tradeName, use all the manufacturer from bulkJSONWithCorrectManufacturer
        correctlyEnteredManufacturerList.length > 0 &&
          (await dispatch(
            fetchProductsWithTrade(correctlyEnteredManufacturerList.map(item => item.value))
          ))
      }

      dispatch(setLoading('Validating fields...'))

      await Promise.all([
        dispatch(fetchPackageConfiguration()),
        dispatch(fetchProductSizes()),
        dispatch(fetchPricesTo()),
      ])

      const {
        pricesToForDropDown,
        productsWithTradeForBulkProcess,
        packageConfigForDropDown,
        productSizesForDropDown,
      } = getState().global
      pricesToOptions = pricesToForDropDown
      productsWithTradeOptions = productsWithTradeForBulkProcess
      packageConfigOptions = packageConfigForDropDown
      productSizesOptions = productSizesForDropDown

      for (const bulkItem of bulkArrayFromExcel) {
        // Loop through each row item and check if all the fields entered are available
        let invalidRequiredFields = []

        let finalManufacturer
        if (bulkItem.manufacturer) {
          if (userIsOnlyManufacturerNotCOC) {
            finalManufacturer = toLowerAndTrimWhenString(bulkItem.manufacturer) ===
              toLowerAndTrimWhenString(currentUserLicenseeName) && {
              value: currentUser.legalEntity.id,
              label: currentUserLicenseeName,
            }
          } else {
            finalManufacturer = correctlyEnteredManufacturerList.filter(
              manufacturerObj =>
                toLowerAndTrimWhenString(manufacturerObj.label) ===
                toLowerAndTrimWhenString(bulkItem.manufacturer)
            )[0]
          }
        }
        if (!finalManufacturer) {
          invalidRequiredFields.push('Manufacturer')
        }

        let finalProductWithTrade
        if (bulkItem.productName) {
          const finalProduct = productsWithTradeOptions.filter(productObj => {
            return (
              toLowerAndTrimWhenString(productObj.name) ===
                toLowerAndTrimWhenString(bulkItem.productName) &&
              toLowerAndTrimWhenString(finalManufacturer.label) ===
                toLowerAndTrimWhenString(bulkItem.manufacturer)
            )
          })

          if (finalProduct.length === 0) {
            invalidRequiredFields.push('Product name')
          }

          if (finalProduct.length > 0 && bulkItem.tradeName) {
            finalProductWithTrade = finalProduct.filter(
              productObj =>
                productObj.tradeName &&
                toLowerAndTrimWhenString(productObj.tradeName) ===
                  toLowerAndTrimWhenString(bulkItem.tradeName)
            )[0]
            if (!finalProductWithTrade) {
              invalidRequiredFields.push('Trade name')
            }
          } else if (finalProduct.length > 0) {
            finalProductWithTrade = finalProduct.filter(
              productObj => productObj.tradeName === ''
            )[0]
            if (!finalProductWithTrade) {
              invalidRequiredFields.push('Trade name')
            }
          }
        } else {
          invalidRequiredFields.push('Product name')
        }

        let finalPackageConfig
        if (bulkItem.packageConfiguration) {
          finalPackageConfig = packageConfigOptions.filter(
            packageObj =>
              toLowerAndTrimWhenString(packageObj.label) ===
              toLowerAndTrimWhenString(bulkItem.packageConfiguration)
          )[0]
        }

        if (!finalPackageConfig) {
          invalidRequiredFields.push('Package configuration')
        }

        let finalProductSize
        if (bulkItem.productSize && typeof bulkItem.productSize === 'string') {
          // filter out all the matched product size-unit  with the entered product size-unit
          const matchedProductSizeAndUnit = productSizesOptions.filter(item => {
            // the label is in <15.5 Liter - can> format, hence split by "-" and trim
            return (
              toLowerAndTrimWhenString(bulkItem.productSize) ===
              toLowerAndTrimWhenString(item.label.split('-')[0])
            )
          })

          if (matchedProductSizeAndUnit && matchedProductSizeAndUnit.length > 0) {
            if (bulkItem.containerType) {
              // now check if the entered container type exists in the matched product size-unit list
              const productSizeUnitTypeIndex = matchedProductSizeAndUnit.findIndex(
                item =>
                  toLowerAndTrimWhenString(bulkItem.containerType) ===
                  toLowerAndTrimWhenString(item.label.split('-')[1])
              )
              if (productSizeUnitTypeIndex > -1) {
                //both product size-unit and container type combination is found
                finalProductSize = matchedProductSizeAndUnit[productSizeUnitTypeIndex]
              } else {
                invalidRequiredFields.push('Combination of product size & container type')
              }
            }
          } else {
            invalidRequiredFields.push('Product size')
          }
        } else {
          invalidRequiredFields.push('Product size')
        }

        if (!bulkItem.containerType) {
          invalidRequiredFields.push('Container Type')
        }

        let finalCounty
        if (bulkItem.county) {
          const enteredCounty = toLowerAndTrimWhenString(bulkItem.county)
          finalCounty =
            enteredCounty === 'all counties' || enteredCounty === 'all'
              ? californiaCounties.filter(c => c.value !== 'All Counties')
              : californiaCounties.filter(
                  countyObj => countyObj.label.toLowerCase() === enteredCounty
                )
        }

        if (!finalCounty || finalCounty.length === 0) {
          invalidRequiredFields.push('County')
        }

        let finalReceivingMethod
        if (bulkItem.receivingMethod) {
          const enteredReceivingMethod = toLowerAndTrimWhenString(bulkItem.receivingMethod)
          finalReceivingMethod =
            enteredReceivingMethod === 'both'
              ? receivingMethod
              : receivingMethod.filter(
                  receivingMetObj => receivingMetObj.label.toLowerCase() === enteredReceivingMethod
                )
        }

        if (!finalReceivingMethod || finalReceivingMethod.length === 0) {
          invalidRequiredFields.push('Receiving Method')
        }

        let finalPricesTo
        if (bulkItem.pricesTo) {
          finalPricesTo = pricesToOptions.filter(
            pricesToObj =>
              toLowerAndTrimWhenString(pricesToObj.label) ===
              toLowerAndTrimWhenString(bulkItem.pricesTo)
          )[0]
        }

        if (!finalPricesTo) {
          invalidRequiredFields.push('Prices To')
        }

        let finalPrice
        if (typeof bulkItem.price === 'number') {
          finalPrice = bulkItem.price
        } else if (bulkItem.price && /^[$][0-9]{1,2}.[0-9]{1,2}/.test(bulkItem.price)) {
          finalPrice = parseFloat(bulkItem.price.replace(/[$,]/gi, ''))
        } else {
          invalidRequiredFields.push('Price')
        }

        let finalContainerCharge
        if (typeof bulkItem.containerCharge === 'number') {
          finalContainerCharge = bulkItem.containerCharge
        } else if (
          bulkItem.containerCharge &&
          /^[$][0-9]{1,2}.[0-9]{1,2}/.test(bulkItem.containerCharge)
        ) {
          finalContainerCharge = parseFloat(bulkItem.containerCharge.replace(/[$,]/gi, ''))
        } else {
          invalidRequiredFields.push('Container charge')
        }

        let finalPricePromotion
        if (bulkItem.pricePromotion) {
          if ("YES" === bulkItem.pricePromotion.toUpperCase() || "NO" === bulkItem.pricePromotion.toUpperCase()) {
            finalPricePromotion = {
              value: "YES" === bulkItem.pricePromotion.toUpperCase(),
              label: bulkItem.pricePromotion,
            }
          } else {
            invalidRequiredFields.push('Price promotion')
          }
        } else {
          invalidRequiredFields.push('Price promotion')
        }

        // Check if all the fields in the current row, are entered correctly and can be used for price posting
        if (invalidRequiredFields.length === 0) {
          // Push each correctly entered row item into the finalPricePostingsScheduleList
          // And also calculate & push the possible number of price postings that can be created out of each row
          listOfNumberOfItemsPerRow.push(finalCounty.length * finalReceivingMethod.length)

          finalPricePostingsScheduleList.push({
            manufacturer: finalManufacturer,
            product: {
              value: finalProductWithTrade.id,
              name: finalProductWithTrade.name,
              tradeName: finalProductWithTrade.tradeName,
            },
            package: finalPackageConfig,
            size: finalProductSize,
            county: finalCounty,
            pricesTo: finalPricesTo,
            receivingMethod: finalReceivingMethod,
            price: finalPrice,
            containerCharge: finalContainerCharge,
            isEffectiveImmediately:
              bulkItem.isEffectiveImmediately &&
              toLowerAndTrimWhenString(bulkItem.isEffectiveImmediately) === 'yes'
                ? true
                : false,
            userId: currentUser.id,
            userLicenseeID: currentUser.legalEntity.id,
            status: '',
            source: 'Bulk Upload',
            pricePromotion: finalPricePromotion,
          })
        } else {
          //if it gets here that means, one or more fields do not support for price posting process
          //hence add a errorReason for the current row object and push it to the array list
          pricePostingScheduleErrors.push({
            Manufacturer: bulkItem.manufacturer,
            'Trade name': bulkItem.tradeName,
            'Product Name': bulkItem.productName,
            'Package Configuration': bulkItem.packageConfiguration,
            'Product Size': bulkItem.productSize,
            'Container Type': bulkItem.containerType,
            County: bulkItem.county,
            'Prices To': bulkItem.pricesTo,
            'Receiving Method': bulkItem.receivingMethod,
            'Price($)': bulkItem.price,
            'Container Charge($)': bulkItem.containerCharge,
            'Effective Immediately(Yes/No)': bulkItem.isEffectiveImmediately,
            'Price Promotion(Yes/No)': bulkItem.pricePromotion,
            'Error Reason': `Invalid ${invalidRequiredFields.join(', ')}`,
          })
        }
      }

      //this will make sure, to export the error file right away if there are no items for price postings
      //if there are any items available for price posting, then the error file will be stored in store for exporting later
      if (pricePostingScheduleErrors.length > 0) {
        if (finalPricePostingsScheduleList.length > 0) {
          dispatch(setFailedPricePostingsScheduleDuringBulk(pricePostingScheduleErrors))
        } else {
          exportToExcel(pricePostingScheduleErrors, 'failedPricePosting')
          dispatch(setLoading(false))
          dispatch(
            showError(
              `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
            )
          )
        }
      }

      if (finalPricePostingsScheduleList.length > 0) {
        dispatch(setLoading('Processing price postings...'))
        dispatch(
          setLoadingProgress({
            value: 0,
            buffer: 0,
          })
        )
        dispatch(submitPricePostingSchedule(finalPricePostingsScheduleList))
      }
    } catch (error) {
      console.error('Error::' + JSON.stringify(error))
      dispatch(setLoading(false))
      dispatch(setLoadingProgress(null))
      dispatch(
        showError(
          `An error occurred during the bulk upload process, please make sure your file follows the template requirements and try again.` +
            JSON.stringify(error)
        )
      )
    }
  }
}

export const handlePricePostScheduleSubmit = pricePostingScheduleDetails => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Updating price posting schedule...'))

    const currentUser = getState().auth.user
    const graphQLParamsForUpdate = {
      data: {
        scheduledDate: utcToZonedTime(
          new Date(pricePostingScheduleDetails.scheduledDate).toISOString(),
          'America/Los_Angeles'
        ),
        updatedBy: parseInt(currentUser.id),
      },
      ppsId: parseInt(pricePostingScheduleDetails.id),
      ppsManufacturerId: parseInt(pricePostingScheduleDetails.manufacturer.id),
    }

    await API.graphql(
      graphqlOperation(pricePostingsScheduleUpdateScheduleDate, graphQLParamsForUpdate)
    )
      .then(_ => {
        //dispatch(setPricePostingScheduleDetails(pricePostingScheduleDetails))
        dispatch(showSuccess('Price Posting Schedule has been updated successfully.'))
        dispatch(getPricePostingsScheduleList())
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was an issue updating the price posting schedule, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const fetchPricePostScheduleErrorFile = objKey => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Requesting error file...'))

    const currentUser = getState().auth.user

    const { preSignedUrl } = await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/price-postings-staging-upload-presign?objKey=${
        'error/' + objKey
      }&action=GET`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: currentUser.id }),
          'Content-Type': 'text/plain',
        },
      }
    )
      .then(response => response.json())
      .then(res => res)
      .catch(e => {
        console.log('error getting presigned URL: ', e)
      })

    const result = await fetch(preSignedUrl, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(res => res)
      .catch(e => {
        dispatch(showError('An error occurred while processing the file.'))
      })

    exportToExcel(result.errors, 'failedScheduledPricePosting')
    dispatch(setLoading(false))
  }
}

const toLowerAndTrimWhenString = value => {
  return typeof value === 'string' ? value.toLowerCase().trim() : value
}

export const clearFilters = (reload = true) => {
  return dispatch => {
    dispatch(setPricePostingsScheduleBulkSelection([]))
    dispatch(setPricePostingsSchedulePageNumber(0))
    dispatch(setPricePostingsScheduleFilters(initialState.pricePostingsScheduleFilters))
    reload && dispatch(getPricePostingsScheduleList(false, true))
  }
}

export const resetPricePostingScheduleDetails = () => {
  return (dispatch, getState) => {
    dispatch(setPricePostingScheduleDetails(initialState.pricePostingScheduleDetails))
    dispatch(setLoading(false))
  }
}

export const deleteStagedPostingsByIds = () => {
  return async (dispatch, getState) => {
    const ids = getState().pricePostingsSchedule.bulkSelected
    let selectedIds = []
    if (ids && ids.length > 0) {
      selectedIds = ids.map(val => parseInt(val.id))

      await API.graphql(
        graphqlOperation(deletePricePostSchedule, {
          where: { id_in: selectedIds },
        })
      )
        .then(_ => {
          dispatch(getPricePostingsScheduleList(false, true))
          dispatch(showSuccess(`Selected price posting schedules deleted successfully.`))
          dispatch(setPricePostingsScheduleBulkSelection([]))
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was an issue deleting the price posting schedule, try again. Thank you.'
            )
          )
        })
    }
  }
}

export const deleteStagedPostingByID = id => {
  return async dispatch => {
    await API.graphql(
      graphqlOperation(deletePricePostSchedule, {
        where: { id: parseInt(id) },
      })
    )
      .then(_ => {
        dispatch(getPricePostingsScheduleList())
        dispatch(showSuccess(`Scheduled price posting deleted successfully.`))
      })
      .catch(_ => {
        dispatch(
          showError('There was an issue deleting the price posting schedule, try again. Thank you.')
        )
      })
  }
}

export const setPricePostingsScheduleList = createAction(
  'pricePostingsSchedule/setPricePostingsScheduleList'
)
export const resetPricePostingsScheduleList = createAction(
  'pricePostingsSchedule/resetPricePostingsScheduleList'
)
export const setPricePostingsScheduleCount = createAction(
  'pricePostingsSchedule/setPricePostingsScheduleCount'
)
export const setPricePostingsScheduleRequestedSort = createAction(
  'pricePostingsSchedule/setPricePostingsScheduleRequestedSort'
)
export const setPricePostingsSchedulePageNumber = createAction(
  'pricePostingsSchedule/setPricePostingsSchedulePageNumber'
)
export const setPricePostingsScheduleRowsPerPage = createAction(
  'pricePostingsSchedule/setPricePostingsScheduleRowsPerPage'
)
export const setPricePostingScheduleDetails = createAction(
  'pricePostingsSchedule/setPricePostingScheduleDetails'
)
export const setPricePostingScheduleShow = createAction(
  'pricePostingsSchedule/setPricePostingScheduleShow'
)
export const setPricePostingsScheduleFilters = createAction(
  'pricePostingsSchedule/setPricePostingsScheduleFilters'
)
export const setSinglePricePostingScheduleSelected = createAction(
  'pricePostingsSchedule/setSinglePricePostingScheduleSelected'
)
export const setPPSBulkUploadDialog = createAction('pricePostingsSchedule/setPPSBulkUploadDialog')
export const setFailedPricePostingsScheduleDuringBulk = createAction(
  'pricePostingsSchedule/setFailedPricePostingsScheduleDuringBulk'
)
export const setPricePostingScheduleBulk = createAction(
  'pricePostingsSchedule/setPricePostingScheduleBulk'
)
export const setPricePostingScheduleConfirmation = createAction(
  'pricePostingsSchedule/setPricePostingScheduleConfirmation'
)
export const setPricePostingScheduleItems = createAction(
  'pricePostingsSchedule/setPricePostingScheduleItems'
)
export const setPricePostingScheduleSuccess = createAction(
  'pricePostingsSchedule/setPricePostingScheduleSuccess'
)
export const setPricePostingScheduleDate = createAction(
  'pricePostingsSchedule/setPricePostingScheduleDate'
)
export const setPricePostingDeleteConfirmation = createAction(
  'pricePostingSchedule/setPricePostingDeleteConfirmation'
)
export const setPricePostingScheduleBulkFilename = createAction(
  'pricePostingSchedule/setPricePostingScheduleBulkFilename'
)
export const setPricePostingsScheduleBulkSelection = createAction(
  'pricePostingSchedule/setPricePostingsScheduleBulkSelection'
)
export const setPricePostingScheduleBulkDeletionConfirmation = createAction(
  'pricePostingsSchedule/setPricePostingScheduleBulkDeletionConfirmation'
)

const minDate = new Date()
minDate.setDate(minDate.getDate() + 1)

const initialState = {
  pricePostingsScheduleList: { results: [], firstLoad: true },
  pricePostingsScheduleCount: 0,
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  isPricePostingScheduleShown: false,
  pricePostingScheduleDetails: {
    id: '',
    manufacturer: {
      id: '',
      label: '',
    },
    uploadName: '',
    uploadKey: '',
    numErrors: '',
    createdBy: {
      id: '',
      label: '',
    },
    scheduledDate: '',
    numRecords: 0,
    numProcessed: 0,
    numErrors: 0,
  },
  pricePostingScheduleConfirmation: false,
  pricePostingScheduleItems: [],
  failedPricePostingsScheduleDuringBulk: [],
  pricePostingScheduleDate: minDate.getTime(),
  isPPSBulkUploadDialogOpen: false,
  pricePostingDeleteConfirmation: false,
  pricePostingScheduleBulkFilename: null,
  pricePostingScheduleBulkDeletionConfirmation: false,
  bulkSelected: [],
}

export default createReducer(initialState, {
  [setPricePostingsScheduleList]: (state, action) => {
    state.pricePostingsScheduleList = action.payload
  },
  [setPricePostingsScheduleCount]: (state, action) => {
    state.pricePostingsScheduleCount = action.payload
  },
  [resetPricePostingsScheduleList]: state => {
    state.pricePostingsScheduleList = initialState.pricePostingsScheduleList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.isPricePostingScheduleShown = initialState.isPricePostingScheduleShown
    state.pricePostingsScheduleFilters = initialState.pricePostingsScheduleFilters
    state.pricePostingDeleteConfirmation = initialState.pricePostingDeleteConfirmation
    state.pricePostingScheduleBulkDeletionConfirmation =
      initialState.pricePostingScheduleBulkDeletionConfirmation
    state.bulkSelected = initialState.bulkSelected
  },
  [setPricePostingsScheduleFilters]: (state, action) => {
    state.pricePostingsScheduleFilters = action.payload
  },
  [setPricePostingsSchedulePageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setSinglePricePostingScheduleSelected]: (state, action) => {
    state.singlePricePostingScheduleSelected = action.payload
  },
  [setPricePostingsScheduleRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setPricePostingScheduleShow]: (state, action) => {
    state.isPricePostingScheduleShown = action.payload
  },
  [setPricePostingScheduleDetails]: (state, action) => {
    state.pricePostingScheduleDetails = action.payload
  },
  [setPPSBulkUploadDialog]: (state, action) => {
    state.isPPSBulkUploadDialogOpen = action.payload
  },
  [setFailedPricePostingsScheduleDuringBulk]: (state, action) => {
    state.failedPricePostingsScheduleDuringBulk = action.payload
  },
  [setPricePostingScheduleConfirmation]: (state, action) => {
    state.pricePostingScheduleConfirmation = action.payload
  },
  [setPricePostingScheduleItems]: (state, action) => {
    state.pricePostingScheduleItems = action.payload
  },
  [setPricePostingScheduleDate]: (state, action) => {
    state.pricePostingScheduleDate = action.payload
  },
  [setPricePostingDeleteConfirmation]: (state, action) => {
    state.pricePostingDeleteConfirmation = action.payload
  },
  [setPricePostingScheduleBulkFilename]: (state, action) => {
    state.pricePostingScheduleBulkFilename = action.payload
  },
  [setPricePostingsScheduleRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setPricePostingsScheduleBulkSelection]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setPricePostingScheduleBulkDeletionConfirmation]: (state, action) => {
    state.pricePostingScheduleBulkDeletionConfirmation = action.payload
  },
})
