import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import PublicSidebar from 'components/public/Sidebar'
import { Container } from '@material-ui/core'
import backgroundImg from 'assets/background.png'
import PublicHeader from 'components/public/Header'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import PublicProducts from 'components/public/Products'
import PublicPricePosts from 'components/public/PricePosts'
import PublicDashboard from 'components/public/Dashboard'
import { useSelector } from 'react-redux'
import LoadingIndicator from 'components/common/LoadingIndicator'
import PublicFooter from 'components/public/Footer'

const GlobalStyle = createGlobalStyle`
  html{
    background: ${props => props.theme.light}
  }
  body{
    -webkit-font-smoothing: subpixel-antialiased !important; 
    text-rendering:optimizeLegibility !important;
  }
  td.active {
    color: ${props => props.theme.active};
  }
  td.pending {
    color: ${props => props.theme.pending};
  }
  td.inactive {
    color: ${props => props.theme.inactive};
  }
  fieldset {
    height: 50px;
  }

  a, a:link, a:visited {
    color: ${props => props.theme.blue};
  }
  a:hover, a:active {
    color: ${props => props.theme.dark};
  }
`

const Background = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 254px;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
  background-image: url(${backgroundImg});
  background-position: center 80px;
  background-repeat: repeat-x;
  background-size: 1920px 254px;
`

const SwitchComp = () => {
  return (
    <Switch>
      <Route exact path='/home' component={PublicDashboard} />
      <Route exact path='/publicPricePosts' component={PublicPricePosts} />
      <Route exact path='/publicProducts' component={PublicProducts} />
    </Switch>
  )
}

const LayoutWrapper = styled(Container)`
  && {
    margin-top: 53px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
`

const PublicLayout = () => {
  const { isAuthenticated, pathname } = useSelector(store => store.auth)
  const { isLoading } = useSelector(store => store.global)
  let location = useLocation()

  if (isAuthenticated) {
    return <Redirect to={pathname} />
  }

  // Used to display loading indicator while authenticating user
  const isAuthorizing =
    isLoading && (location.pathname === '/' || location.pathname === '/home')

  return (
    <>
      <GlobalStyle />
      <PublicSidebar role='navigation' />
      <PublicHeader role='banner' />
      <LayoutWrapper role='main'>
        {isAuthorizing ? (
          <LoadingIndicator text='Loading...' />
        ) : (
          <SwitchComp />
        )}
      </LayoutWrapper>
      <Background />
      <PublicFooter role='banner' />
    </>
  )
}

export default PublicLayout
