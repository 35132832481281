import { createReducer, createAction } from '@reduxjs/toolkit'
import {
  setLoading,
  setAuxLoading,
  showError,
  setEditMode,
  showSuccess,
} from './global'
import { API, graphqlOperation } from 'aws-amplify'
import {
  getUsersQuery,
  findUserDuplicatesQuery,
  getUserByIdQuery,
  getUsersForSelectAll,
  getUsersForExport,
} from 'graphql/queries'
import {
  createNewUser,
  resendUserRegistration,
  updateLeUserMutation,
} from 'graphql/mutations'
import { leUserRegistrationTemplate } from 'common/templates'
import { userSignUpLink } from 'common/constants'
import {
  updateCognitoEmail,
  cleanUp,
  exportToExcel,
  getStartOfDay,
  getEndOfDay,
} from 'common/helper'

export const getLeUsersList = (whereClause, selectAll = false) => {
  return async (dispatch, getState) => {
    const leUserState = getState().legalEntityUser
    const loggedInUserDetails = getState().auth.user
    const orderRule = `${leUserState.orderBy}_${leUserState.order}`
    let filtersWhereClause = {}
    const isLoggedInAsLeAdmin = loggedInUserDetails.role.id === '4'

    //update the licensee id to logged in users ID when logged in as licensee-admin
    if (isLoggedInAsLeAdmin) {
      filtersWhereClause = {
        ...whereClause,
        legalEntityId_in: [parseInt(loggedInUserDetails.legalEntity.id)],
      }
    }
    if (whereClause) {
      //when not logged in as licensee-admin use the licensee filtered values

      if (!isLoggedInAsLeAdmin) {
        filtersWhereClause = {
          ...whereClause,
          ...filtersWhereClause,
          legalEntityId_in: whereClause.licensee.map(li => parseInt(li.value)),
        }
      }
      dispatch(setLeUsersFilters(filtersWhereClause))
    } else {
      filtersWhereClause = {
        ...leUserState.leUsersFilters,
        ...filtersWhereClause,
      }
    }
    dispatch(setLoading('Loading Users...'))

    // Add GraphQL query parameters
    let graphQLParams = {
      orderBy: orderRule,
    }
    // Set up GraphQL where parameters based on filters selected
    if (filtersWhereClause) {
      let whereParam = {}

      if (filtersWhereClause.role && filtersWhereClause.role.length > 0)
        whereParam.roleId_in = filtersWhereClause.role.map(r =>
          parseInt(r.value)
        )
      else whereParam.roleId_in = [4, 5]

      if (filtersWhereClause.status && filtersWhereClause.status.length > 0)
        whereParam.status_in = filtersWhereClause.status.map(stat => stat.value)
      if (
        filtersWhereClause.legalEntityId_in &&
        filtersWhereClause.legalEntityId_in.length > 0
      )
        whereParam.legalEntityId_in = filtersWhereClause.legalEntityId_in

      if (filtersWhereClause.firstName)
        whereParam.firstName_like = cleanUp(filtersWhereClause.firstName)
      if (filtersWhereClause.lastName)
        whereParam.lastName_like = cleanUp(filtersWhereClause.lastName)
      if (filtersWhereClause.email)
        whereParam.email_like = cleanUp(filtersWhereClause.email)

      if (filtersWhereClause.createdAtFrom && filtersWhereClause.createdAtTo) {
        whereParam.createdAt_gte = getStartOfDay(
          filtersWhereClause.createdAtFrom
        )
        whereParam.createdAt_lte = getEndOfDay(filtersWhereClause.createdAtTo)
      }
      if (filtersWhereClause.updatedAtFrom && filtersWhereClause.updatedAtTo) {
        whereParam.updatedAt_gte = getStartOfDay(
          filtersWhereClause.updatedAtFrom
        )
        whereParam.updatedAt_lte = getEndOfDay(filtersWhereClause.updatedAtTo)
      }

      graphQLParams.where = whereParam
    }

    if (selectAll) {
      await API.graphql(graphqlOperation(getUsersForSelectAll, graphQLParams))
        .then(response => {
          dispatch(setLicenseeUsersBulkSelection(response.data.users.results))
        })
        .catch(error => {
          dispatch(
            showError(
              'Unable to select users, please check your internet connection and try again. Thank you.'
            )
          )
        })
    } else {
      graphQLParams = {
        ...graphQLParams,
        limit: leUserState.rowsPerPage,
        offset: leUserState.pageNumber * leUserState.rowsPerPage,
        includeLE: true,
      }

      await API.graphql(graphqlOperation(getUsersQuery, graphQLParams))
        .then(response => {
          dispatch(setLeUsersList(response.data.users))
        })
        .catch(error => {
          dispatch(
            showError(
              'Unable to retrieve list of users, please check your internet connection and try again. Thank you.'
            )
          )
        })
    }
    dispatch(setLoading(false))
  }
}

export const addNewLeUser = leUserDetails => {
  const newLeUser = { ...leUserDetails }
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading...'))
    const currUser = getState().auth.user

    // If licensee is not sent on details, use the user's linked licensee
    if (leUserDetails.legalEntity) {
      newLeUser.legalEntityId = leUserDetails.legalEntity.value
      newLeUser.legalEntityName = leUserDetails.legalEntity.label
    } else if (currUser.legalEntity) {
      newLeUser.legalEntityId = currUser.legalEntity.id
      newLeUser.legalEntityName = currUser.legalEntity.name
    }

    // Check that the combination of names & email & licensee doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findUserDuplicatesQuery, {
        where: {
          firstName_lower: cleanUp(newLeUser.firstName),
          lastName_lower: cleanUp(newLeUser.lastName),
          email_lower: cleanUp(newLeUser.email),
          legalEntityId_in: [parseInt(newLeUser.legalEntityId)],
        },
      })
    )
    if (res.data.users.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          `A user with this name and email already exists${
            leUserDetails.legalEntity && ` under the selected licensee`
          }, please review your information and try again.`
        )
      )
    } else {
      const user = {
        ...newLeUser,
        status: 'Pending',
        roleId: newLeUser.role,
        userId: currUser.id,
      }
      user.email = user.email.toLowerCase()

      const userRes = await API.graphql(graphqlOperation(createNewUser(user)))
      if (userRes.data.createUser) {
        userRes.data.createUser.role = newLeUser.role
        userRes.data.canSelectLeAdmin = newLeUser.canSelectLeAdmin
        dispatch(setLegalEntityUser(userRes.data.createUser))

        // Send user registration email
        const verificationCode = userRes.data.createUser.verificationCode
        const params = {
          body: {
            to: user.email,
            subject: 'ABC Price Posting - User Registration',
            message: leUserRegistrationTemplate({
              licensee: newLeUser.legalEntityName,
              verificationCode,
            }),
            buttonLink: userSignUpLink,
            buttonText: 'Complete Registration',
          },
        }
        await API.post('ABC-API', '/send-email', params)

        dispatch(setEditMode(false))
        dispatch(
          showSuccess(
            'A link to complete the registration process has been sent to the user.'
          )
        )
        dispatch(getLeUsersList())
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the user, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

/* Resend Registration code */
export const resendRegistration = newLeUser => {
  return async (dispatch, getState) => {
    dispatch(setAuxLoading('Sending registration to user...'))
    const currUser = getState().auth.user
    const user = {
      id: newLeUser.id,
      updatedBy: currUser.id,
    }
    const userRes = await API.graphql(
      graphqlOperation(resendUserRegistration(user))
    )
    if (userRes.data.resendUserRegistration) {
      const newRegistration = userRes.data.resendUserRegistration
      // Send user registration email
      const verificationCode = newRegistration.verificationCode
      const params = {
        body: {
          to: newRegistration.email.toLowerCase(),
          subject: 'ABC Price Posting - User Registration',
          message: leUserRegistrationTemplate({
            licensee: newLeUser.legalEntity.name,
            verificationCode,
          }),
          buttonLink: userSignUpLink,
          buttonText: 'Complete Registration',
        },
      }
      await API.post('ABC-API', '/send-email', params)
      dispatch(
        showSuccess(
          'A link to complete the registration process has been sent to the user.'
        )
      )
      dispatch(setAuxLoading(false))
    } else {
      dispatch(setAuxLoading(false))
      dispatch(
        showError(
          'There was an issue while resending registration, please review the form and submit again. Thank you.'
        )
      )
    }
  }
}

// Fetch user by ID after selecting list item
export const fetchLeUserByID = (id, editMode = false) => {
  return async dispatch => {
    dispatch(setLoading('Loading User...'))
    const userRes = await API.graphql(
      graphqlOperation(getUserByIdQuery, {
        where: { id: parseInt(id) },
        includeLicensee: true,
      })
    )
    if (userRes.data.users.results[0]) {
      const userFromResponse = userRes.data.users.results[0]
      let newResponse = {}
      newResponse = {
        ...userFromResponse,
        role: userFromResponse.role.id,
      }
      dispatch(setLegalEntityUser(newResponse))
      dispatch(setEditMode(editMode))
      dispatch(setLeUserShown(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve user, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const updateLeUser = (leUserDetails, hasEmailChanged = false) => {
  return async dispatch => {
    dispatch(setLoading(true))
    // Retrieve id and name when LE dropdown has been selected
    if (leUserDetails.legalEntity.value) {
      leUserDetails.legalEntity.id = leUserDetails.legalEntity.value
      leUserDetails.legalEntity.name = leUserDetails.legalEntity.label
    }

    // Check that the combination of names & email & licensee doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findUserDuplicatesQuery, {
        where: {
          id_not: parseInt(leUserDetails.id),
          firstName_lower: cleanUp(leUserDetails.firstName),
          lastName_lower: cleanUp(leUserDetails.lastName),
          email_lower: cleanUp(leUserDetails.email),
          legalEntityId_in: [parseInt(leUserDetails.legalEntity.id)],
        },
      })
    )
    if (res.data.users.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          `A user with this name and email already exists${
            leUserDetails.legalEntity && ` under the selected licensee`
          }, please review your information and try again.`
        )
      )
    } else {
      // If email was changed, update the Cognito user email attribute
      if (hasEmailChanged && leUserDetails.username) {
        // Update email attribute in Cognito user
        updateCognitoEmail(leUserDetails.username, leUserDetails.email)
      }

      await API.graphql(graphqlOperation(updateLeUserMutation(leUserDetails)))
        .then(res => {
          dispatch(setEditMode(false))
          dispatch(setLegalEntityUser(leUserDetails))
          dispatch(getLeUsersList())
          dispatch(showSuccess('User details updated successfully'))
        })
        .catch(_ => {
          dispatch(showError(`Unable to edit user data, please try again.`))
        })
    }
    dispatch(setLoading(false))
  }
}

export const exportLicenseeUsersToExcel = () => {
  return async (dispatch, getState) => {
    dispatch(
      setLoading(
        'Exporting both licensee admins and users to excel, please wait... '
      )
    )
    const licenseeUsersState = getState().legalEntityUser
    const orderRule = `${licenseeUsersState.orderBy}_${licenseeUsersState.order}`
    const graphQLParams = {
      where: {
        id_in: licenseeUsersState.bulkSelected.map(item => parseInt(item.id)),
      },
      orderBy: orderRule,
      includeLE: true,
    }
    await API.graphql(graphqlOperation(getUsersForExport, graphQLParams))
      .then(response => {
        const finalLicenseeUsersToExport = response.data.users.results.map(
          item => ({
            Licensee: item.legalEntity.name,
            'First Name': item.firstName,
            'Last Name': item.lastName,
            Email: item.email,
            Role: item.role.name,
            Status: item.status,
          })
        )
        exportToExcel(finalLicenseeUsersToExport, 'List of users')
      })
      .catch(errorResponse => {
        dispatch(
          showError(
            'Unable to export licensee users to excel, kindly check your internet connection and try again. Thank you.'
          )
        )
      })

    dispatch(setLoading(false))
  }
}

export const resetLeUserDetails = () => {
  return dispatch => {
    dispatch(setLegalEntityUser(initialState.legalEntityUser))
    dispatch(setLoading(false))
  }
}
export const clearFilters = () => {
  return dispatch => {
    dispatch(setLeUsersPageNumber(0))
    dispatch(setLeUsersFilters(initialState.leUsersFilters))
    dispatch(getLeUsersList())
  }
}

/* ------------------------------------------------------------------------------- */
/* ------------- A C T I V A T E / D E A C T I V A T E   U S E R S  -------------- */
/* ------------------------------------------------------------------------------- */

// Enable/Disable user in Cognito and update status in DB
const toggleUserStatus = async (
  userDetails,
  option,
  userIds,
  updatedBy,
  dispatch
) => {
  dispatch(
    setLoading(`${option === 'ENABLE' ? 'Activating' : 'Deactivating'} user...`)
  )

  // Create new object
  let leUser = JSON.parse(JSON.stringify(userDetails))

  const params = {
    body: {
      option,
      userIds,
      updatedBy,
    },
  }
  await API.post('ABC-API', '/update-user-status', params)
    .then(res => {
      leUser.status = res.status
      // Update profile view & list
      dispatch(setLegalEntityUser(leUser))
      dispatch(getLeUsersList())
      dispatch(
        showSuccess(
          `User ${
            option === 'ENABLE' ? 'activated' : 'deactivated'
          } successfully`
        )
      )
    })
    .catch(error => {
      const message =
        error.response && error.response.data && error.response.data.message
      const genericMessage = `Unable to ${
        option === 'ENABLE' ? 'activate' : 'deactivate'
      } user, please try again.`
      dispatch(showError(message || genericMessage))
    })

  dispatch(setLoading(false))
}

export const activateLeUser = userId => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const userDetails = getState().legalEntityUser.legalEntityUser

    await toggleUserStatus(
      userDetails,
      'ENABLE',
      [userId],
      currUser.id,
      dispatch
    )
  }
}

export const deactivateLeUser = userId => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const userDetails = getState().legalEntityUser.legalEntityUser

    await toggleUserStatus(
      userDetails,
      'DISABLE',
      [userId],
      currUser.id,
      dispatch
    )
  }
}

export const setLegalEntityUser = createAction(
  'legalEntityUser/setLegalEntityUser'
)
export const setLeUsersList = createAction('legalEntityUser/setLeUsersList')
export const setLeUsersPageNumber = createAction(
  'legalEntityUser/setLeUsersPageNumber'
)
export const setLeUsersRowsPerPage = createAction(
  'legalEntityUser/setLeUsersRowsPerPage'
)
export const setLeUsersRequestedSort = createAction(
  'legalEntityUser/setLeUsersRequestedSort'
)
export const setLicenseeUsersBulkSelection = createAction(
  'legalEntityUser/setLicenseeUsersBulkSelection'
)
export const setLeUsersFilters = createAction(
  'legalEntityUser/setLeUsersFilters'
)
export const setIsLEUsersFilterOpen = createAction(
  'legalEntityUser/setIsLEUsersFilterOpen'
)
export const setLeUserShown = createAction('legalEntityUser/setLeUserShown')
export const setDeactivationConfirmation = createAction(
  'legalEntityUser/setDeactivationConfirmation'
)
export const setActivationConfirmation = createAction(
  'legalEntityUser/setActivationConfirmation'
)
export const resetLicenseeUser = createAction(
  'legalEntityUser/resetLicenseeUser'
)

const initialState = {
  legalEntityUser: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    createdAt: null,
    updatedAt: null,
    status: '',
    role: '',
    legalEntity: '',
  },
  isLeUserShown: false,
  leUsersFilters: {
    firstName: '',
    lastName: '',
    email: '',
    role: [],
    createdAt: null,
    operatorForCreated: 'equal',
    operatorForUpdated: 'equal',
    updatedAt: null,
    status: [],
    licensee: [],
  },
  leUsersList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  bulkSelected: [],
  isLeUsersFilterOpen: false,
  activationConfirmation: false,
  deactivationConfirmation: false,
}

export default createReducer(initialState, {
  [setLegalEntityUser]: (state, action) => {
    state.legalEntityUser = action.payload
  },
  [setLeUsersList]: (state, action) => {
    state.leUsersList = action.payload
  },
  [setLeUsersPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setLeUsersRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setLeUsersRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setLicenseeUsersBulkSelection]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setLeUsersFilters]: (state, action) => {
    state.leUsersFilters = action.payload
  },
  [setIsLEUsersFilterOpen]: (state, action) => {
    state.isLeUsersFilterOpen = action.payload
  },
  [setLeUserShown]: (state, action) => {
    state.isLeUserShown = action.payload
  },
  [setActivationConfirmation]: (state, action) => {
    state.activationConfirmation = action.payload
  },
  [setDeactivationConfirmation]: (state, action) => {
    state.deactivationConfirmation = action.payload
  },
  [resetLicenseeUser]: state => {
    state.legalEntityUser = initialState.legalEntityUser
    state.isLeUserShown = initialState.isLeUserShown
    state.leUsersFilters = initialState.leUsersFilters
    state.leUsersList = initialState.leUsersList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.isLeUsersFilterOpen = initialState.isLeUsersFilterOpen
    state.activationConfirmation = initialState.activationConfirmation
    state.deactivationConfirmation = initialState.deactivationConfirmation
  },
})
