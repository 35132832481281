import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import { Edit, Send, Block, HowToReg } from '@material-ui/icons'
import styled from 'styled-components'
import {
  resendRegistration,
  setDeactivationConfirmation,
  setActivationConfirmation,
} from 'ducks/abcUsers'
import { getRoles, setEditMode } from 'ducks/global'
import { format } from 'date-fns'
import { Can } from 'common/abilityContext'
import { roles as rolesConst } from 'common/constants'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'

const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`
const SendIcon = styled(Send)`
  && {
    font-size: 30px;
  }
`

const ViewUserProfile = () => {
  const dispatch = useDispatch()
  const { roles, isAuxLoading, loadingAuxText } = useSelector(
    store => store.global
  )
  const { abcUsersDetails } = useSelector(store => store.abcUsers)
  const { user } = useSelector(store => store.auth)

  const roleName =
    roles &&
    roles.find(role => {
      return role.id === abcUsersDetails.role
    })

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={
            <UserIcon>
              {abcUsersDetails.firstName.charAt(0).toUpperCase()}
              {abcUsersDetails.lastName.charAt(0).toUpperCase()}
            </UserIcon>
          }
          profileTitle={
            <Typography
              children={`${abcUsersDetails.firstName} ${abcUsersDetails.lastName}`}
              variant='h6'
            />
          }
          profileSubHeading={roleName ? roleName.name : ''}
          profileAction={
            <>
              {abcUsersDetails.status === 'Pending' && (
                <IconButton
                  aria-label='Resend Registration'
                  aria-haspopup='false'
                  color='primary'
                  onClick={() =>
                    dispatch(resendRegistration(abcUsersDetails.id))
                  }
                >
                  <SendIcon color='primary' />
                  <Typography variant='subtitle1'>
                    &nbsp;{'Resend Registration'}
                  </Typography>
                </IconButton>
              )}
              <Can
                I='edit'
                this={roleName ? roleName.name : rolesConst.abcITAdmin}
              >
                {() => (
                  <>
                    <IconButton
                      aria-label='Edit ABC User'
                      aria-haspopup='false'
                      color='primary'
                      onClick={() => dispatch(setEditMode(true))}
                    >
                      <EditIcon color='primary' />
                      <Typography variant='subtitle1'>
                        &nbsp;{'Edit'}
                      </Typography>
                    </IconButton>
                    {user.id !== abcUsersDetails.id &&
                    (abcUsersDetails.status === 'Active' ||
                      abcUsersDetails.status === 'Pending') ? (
                      <IconButton
                        aria-label='Deactivate ABC User'
                        aria-haspopup='false'
                        color='primary'
                        onClick={() =>
                          dispatch(setDeactivationConfirmation(true))
                        }
                      >
                        <Block color='error' />
                        <Typography variant='subtitle1'>
                          &nbsp;{'Deactivate'}
                        </Typography>
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {user.id !== abcUsersDetails.id &&
                    abcUsersDetails.status === 'Inactive' ? (
                      <IconButton
                        aria-label='Reactivate ABC User'
                        aria-haspopup='false'
                        color='primary'
                        onClick={() =>
                          dispatch(setActivationConfirmation(true))
                        }
                      >
                        <HowToReg color='primary' />
                        <Typography variant='subtitle1'>
                          &nbsp;{'Reactivate'}
                        </Typography>
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </Can>
            </>
          }
          divider
        >
          <DataGrid label='Email' value={abcUsersDetails.email} />
          <DataGrid label='Phone' value={abcUsersDetails.phoneNumber} />
          <DataGrid label='Status' value={abcUsersDetails.status} />
          <DataGrid
            label='Added Date'
            value={
              abcUsersDetails.createdAt
                ? format(abcUsersDetails.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Edited Date'
            value={
              abcUsersDetails.updatedAt
                ? format(abcUsersDetails.updatedAt, 'MM/dd/yyyy')
                : ''
            }
          />
          { user.id === abcUsersDetails.id &&
           (<DataGrid label='Username' value={abcUsersDetails.username} />
          )}          
        </CustomCard>
      )}
    </>
  )
}

export default ViewUserProfile
