import { createAction, createReducer } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  setLoading,
  setEditMode,
  showError,
  showSuccess,
  setNewProductSizeAdded,
} from './global'
import {
  getProductSizesQuery,
  getProductSizeByIDQuery,
  findProductSizeDuplicatesQuery,
  findGlobalSettingsInPricePostsQuery,
} from 'graphql/queries'
import {
  createProductSizeMutation,
  updateProductSizeMutation,
  inactivateAndCreateProductSizeMutation,
  changeProductSizeStatus,
} from 'graphql/mutations'

export const fetchProductSizes = whereClause => {
  return async (dispatch, getState) => {
    const productSizesState = getState().productSizes
    const orderRule = `${productSizesState.orderBy}_${productSizesState.order}`    
    
    dispatch(setLoading('Fetching product sizes...'))

    // Add GraphQL query parameters
    let graphQLParams = { 
      where: { status_not: 'Pending' },     
      orderBy: orderRule,
      limit: productSizesState.rowsPerPage,
      offset: productSizesState.pageNumber * productSizesState.rowsPerPage,
    }

    if(!whereClause){
      whereClause = {
        ...productSizesState.productSizeFilters,
      }
    }    
    
    let whereParam = {}
    if (whereClause.size)
      whereParam.size_in = whereClause.size.map(size => size.value)
    if (whereClause.unit)
      whereParam.unit_in = whereClause.unit.map(unit => parseInt(unit.value))
    if (whereClause.containerType)
      whereParam.containerType_in = whereClause.containerType.map(
        containerType => parseInt(containerType.value)
      )
    if (whereClause.status)
      whereParam.status_in = whereClause.status.map(status =>
        status.value)

    graphQLParams.where = { ...graphQLParams.where, ...whereParam }
    whereClause !== productSizesState.productSizeFilters &&
    dispatch(setProductSizeFilters(whereClause))
      

    await API.graphql(graphqlOperation(getProductSizesQuery, graphQLParams))
      .then(response => {
        dispatch(setProductSizesList(response.data.productSizes))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of product sizes, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const fetchProductSizesForDropdown = whereClause => {
  return async (dispatch, getState) => {
    const productSizesState = getState().productSizes
    const orderRule = `${productSizesState.orderBy}_${productSizesState.order}`

    dispatch(setLoading('Fetching product sizes...'))

    // Add GraphQL query parameters
    let graphQLParams = {
      where: { status_not: 'Pending' },
      orderBy: orderRule,
      limit: 10000,
      offset: 0,
    }

    await API.graphql(graphqlOperation(getProductSizesQuery, graphQLParams))
      .then(response => {
        dispatch(setProductSizesListForDropdown(response.data.productSizes))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of product sizes, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const resetProductSizeDetails = () => {
  return dispatch => {
    dispatch(setProductSizeDetails(initialState.productSizeDetails))
    dispatch(setEditMode(true))
    dispatch(setLoading(false))
  }
}

export const addNewProductSize = productSizeDetails => {
  let newProductSize = JSON.parse(JSON.stringify(productSizeDetails))
  return async dispatch => {
    dispatch(setLoading('Adding product size...'))

    const graphQLDataObject = {
      size: newProductSize.size,
      unit: newProductSize.unit,
      containerType: newProductSize.containerType,
    }

    // Add GraphQL query parameters
    let graphQLParams = {
      where: graphQLDataObject,
    }

    // Check that the product size doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findProductSizeDuplicatesQuery, graphQLParams)
    )
    if (res.data.productSizes.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The product size selected already exists. Please review your information and try again.'
        )
      )
    } else {
      graphQLParams = {
        data: graphQLDataObject,
      }
      const productSizeRes = await API.graphql(
        graphqlOperation(createProductSizeMutation, graphQLParams)
      )
      if (productSizeRes.data.createProductSize) {
        dispatch(setProductSizeDetails(productSizeRes.data.createProductSize))
        dispatch(setProductSizeShown(false))
        dispatch(showSuccess('New product size has been created successfully.'))
        dispatch(fetchProductSizes())
        dispatch(setNewProductSizeAdded(true))
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the product size, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

export const updateProductSize = productSizeDetails => {
  return async dispatch => {
    dispatch(setLoading('Updating product size...'))

    //Add graphQl query parameters for duplicate check
    const graphQLParams = {
      where: {
        id_not: productSizeDetails.id,
        size: productSizeDetails.size,
        unit: productSizeDetails.unit,
        containerType: productSizeDetails.containerType,
      },
    }
    // Check that the product size doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findProductSizeDuplicatesQuery, graphQLParams)
    )
    if (res.data.productSizes.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The product size selected already exists. Please review your information and try again.'
        )
      )
    } else {
      // Check that the product size isn't being used by existing price postings, if it is show message to user
      const res = await API.graphql(
        graphqlOperation(
          findGlobalSettingsInPricePostsQuery({
            size: productSizeDetails.id,
          })
        )
      )
      // Display confirmation message to user
      if (res.data.pricePostings.count > 0) {
        dispatch(setLoading(false))
        dispatch(setIsProductSizeUsed(true))
        dispatch(setProductSizeDetails(productSizeDetails))
        dispatch(setProductSizeShown(false))
      } else {
        const graphQLParamsForUpdate = {
          data: {
            size: productSizeDetails.size,
            unit: productSizeDetails.unit,
            containerType: productSizeDetails.containerType,
          },
          where: { id: productSizeDetails.id },
        }
        await API.graphql(
          graphqlOperation(updateProductSizeMutation, graphQLParamsForUpdate)
        )
          .then(_ => {
            dispatch(setProductSizeDetails(productSizeDetails))
            dispatch(setProductSizeShown(false))
            dispatch(showSuccess('Product size has been updated successfully.'))
            dispatch(fetchProductSizes())
            dispatch(setNewProductSizeAdded(true))
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was an issue updating the product size, please review the form and submit again. Thank you.'
              )
            )
          })
        dispatch(setLoading(false))
      }
    }
  }
}

export const updateProductSizeOnConfirmation = () => {
  return async (dispatch, getState) => {
    dispatch(setIsProductSizeUsed(false))
    dispatch(setLoading('Loading...'))
    const { productSizeDetails } = getState().productSizes
    const graphQLParams = {
      data: {
        size: productSizeDetails.size,
        unit: productSizeDetails.unit,
        containerType: productSizeDetails.containerType,
      },
      where: { id: productSizeDetails.id },
    }
    await API.graphql(
      graphqlOperation(inactivateAndCreateProductSizeMutation, graphQLParams)
    )
      .then(_ => {
        dispatch(setProductSizeDetails(productSizeDetails))
        dispatch(showSuccess('Product size has been updated successfully.'))
        dispatch(fetchProductSizes())
        dispatch(setNewProductSizeAdded(true))
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was an issue updating the product size, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const toggleSizeStatus = productSizeDetails => {
  return async (dispatch, getState) => {
    dispatch(setStatusChangeConfirmationForSize(false))
    const userMessage =
      productSizeDetails.status === 'Active' ? 'Deactivating' : 'Reactivating'
    dispatch(setLoading(`${userMessage} product size. Please wait...`))
    let { productSizesList } = getState().productSizes
    productSizesList = JSON.parse(JSON.stringify(productSizesList))
    const changingStatus =
      productSizeDetails.status === 'Active' ? 'Inactive' : 'Active'
    const graphQLParams = {
      status: changingStatus,
      where: {
        id: productSizeDetails.id,
      },
    }
    await API.graphql(graphqlOperation(changeProductSizeStatus, graphQLParams))
      .then(_ => {
        // find the product size, to change the status on the UI
        const productSizeIndex = productSizesList.results.findIndex(
          item => parseInt(item.id) === productSizeDetails.id
        )
        productSizesList.results[productSizeIndex].status = changingStatus
        dispatch(setProductSizesList(productSizesList))
        dispatch(showSuccess(`${userMessage} product size was successfully.`))
      })
      .catch(err => {
        dispatch(
          showError(
            `There was an issue ${userMessage} a product size, please check your internet connection and try again. Thank you.`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

// Fetch product size by ID after selecting list item
export const fetchProductSizeByID = (id, editMode = true) => {
  return async dispatch => {
    dispatch(setLoading('Loading...'))
    const productSizeRes = await API.graphql(
      graphqlOperation(getProductSizeByIDQuery, { id: parseInt(id) })
    )
    if (productSizeRes.data.productSizes.results[0]) {
      dispatch(
        setProductSizeDetails(productSizeRes.data.productSizes.results[0])
      )
      dispatch(setEditMode(editMode))
      dispatch(setProductSizeShown(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve product size, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setFiltersUsed(false))
    dispatch(setProductSizePageNumber(0))
    dispatch(setProductSizeFilters(initialState.productSizeFilters))
    dispatch(fetchProductSizes())
  }
}

export const setProductSizesList = createAction(
  'productSizes/setProductSizesList'
)
export const setProductSizesListForDropdown = createAction(
  'productSizes/setProductSizesListForDropdown'
)
export const setProductSizeFilters = createAction(
  'productSizes/setProductSizeFilters'
)
export const setIsProductSizeFilterOpen = createAction(
  'productSizes/setIsProductSizeFilterOpen'
)
export const setProductSizePageNumber = createAction(
  'productSizes/setProductSizePageNumber'
)
export const setProductSizeRequestedSort = createAction(
  'productSizes/setProductSizeRequestedSort'
)
export const setProductSizeSelectedValue = createAction(
  'productSizes/setProductSizeSelectedValue'
)
export const setProductSizeShown = createAction(
  'productSizes/setProductSizeShown'
)
export const setProductSizeDetails = createAction(
  'productSizes/setProductSizeDetails'
)
export const setIsProductSizeUsed = createAction(
  'productSizes/setIsProductSizeUsed'
)
export const resetProductSize = createAction('productSizes/resetProductSize')
export const setStatusChangeConfirmationForSize = createAction(
  'productSizes/setStatusChangeConfirmationForSize'
)
export const setFiltersUsed = createAction('productSizes/setFiltersUsed')

const initialState = {
  productSizesList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  productSizesListForDropdown: {
    results: [],
    count: 0,
    totalCount: 0,
    firstLoad: true,
  },
  productSizeFilters: {
    size: '',
    unit: '',
    containerType: '',
    status: '',
  },
  productSizeDetails: {
    size: '',
    unit: { id: '', unit: '', abbreviation: '' },
    containerType: { id: '', type: '' },
  },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'unit',
  selected: [],
  isProductSizeShown: false,
  isProductSizeUsed: false,
  isProductSizeFilterOpen: false,
  statusChangeConfirmationForSize: false,
}

export default createReducer(initialState, {
  [setProductSizeDetails]: (state, action) => {
    state.productSizeDetails = action.payload
  },
  [setProductSizesList]: (state, action) => {
    state.productSizesList = action.payload
  },
  [setProductSizesListForDropdown]: (state, action) => {
    state.productSizesListForDropdown = action.payload
  },
  [setProductSizeFilters]: (state, action) => {
    state.productSizeFilters = action.payload
  },
  [setIsProductSizeFilterOpen]: (state, action) => {
    state.isProductSizeFilterOpen = action.payload
  },
  [setFiltersUsed]: (state, action) => {
    state.areFiltersUsed = action.payload
  },
  [setProductSizePageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setProductSizeRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setProductSizeSelectedValue]: (state, action) => {
    state.selected = action.payload
  },
  [setProductSizeShown]: (state, action) => {
    state.isProductSizeShown = action.payload
  },
  [setIsProductSizeUsed]: (state, action) => {
    state.isProductSizeUsed = action.payload
  },
  [setStatusChangeConfirmationForSize]: (state, action) => {
    state.statusChangeConfirmationForSize = action.payload
  },
  [resetProductSize]: state => {
    state.productSizesList = initialState.productSizesList
    state.productSizeDetails = initialState.productSizeDetails
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.selected = initialState.selected
    state.isProductSizeShown = initialState.isProductSizeShown
    state.isProductSizeUsed = initialState.isProductSizeUsed
  },
})
