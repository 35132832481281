import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { formTypeValues } from 'common/constants'
import * as Yup from 'yup'
import CustomCard from 'components/common/CustomCard'
import { Grid, Button } from '@material-ui/core'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import NumericInput from 'components/common/NumericInput'
import styled from 'styled-components'
import { Save } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { handlePricePostSubmit, setIsMultiPriceChangeModalOpen } from 'ducks/pricePostings'

const SubmitButtonWrapper = styled(Grid)`
  && {
    margin-top: 14px;
  }
`
const StyledButton = styled(Button)`
  && {
    width: 100%;
    transition: 250ms all ease-in;
  }
`

const MultiplePriceChange = () => {
  const dispatch = useDispatch()
  const [priceChangeFormType, setPriceChangeFormType] = useState(formTypeValues.priceOnly)

  const { bulkSelected } = useSelector(store => store.pricePostings)
  const manufacturerIsLicenseeList = bulkSelected.reduce((acc, cur) => {
    if (cur.manufacturer.value === cur.createdByLicenseeID) {
      acc.push(cur)
    }
    return acc
  }, [])
  const allOwnManufacturerProducts = manufacturerIsLicenseeList.length === bulkSelected.length

  const dropdownTypeOptions = [
    {
      value: formTypeValues.both,
      label: formTypeValues.both,
    },
    {
      value: formTypeValues.priceOnly,
      label: formTypeValues.priceOnly,
    },
    {
      value: formTypeValues.containerOnly,
      label: formTypeValues.containerOnly,
    },
  ]

  allOwnManufacturerProducts &&
    dropdownTypeOptions.push({
      value: formTypeValues.pricePromotionOnly,
      label: formTypeValues.pricePromotionOnly,
    })

  let validationFields = {
    price: Yup.string()
      .required('Required')
      .matches(/^[$]((?!(0))[1-9]?,?[0-9]{1,}|0)\.[0-9]{2,3}$/, {
        message: 'Price not properly formatted (0.00)',
      }),
    containerCharge: Yup.string().required('Required'),
    pricePromotion: Yup.string().required('Required'),
  }

  if (priceChangeFormType === formTypeValues.both) {
    validationFields = {
      price: validationFields.price,
      containerCharge: validationFields.containerCharge,
    }
  }

  if (priceChangeFormType === formTypeValues.priceOnly) {
    validationFields = {
      price: validationFields.price,
    }
  }
  if (priceChangeFormType === formTypeValues.containerOnly) {
    validationFields = {
      containerCharge: validationFields.containerCharge,
    }
  }
  if (priceChangeFormType === formTypeValues.pricePromotionOnly) {
    validationFields = {
      pricePromotion: validationFields.pricePromotion,
    }
  }

  let initValues = {
    updateField: {
      value: formTypeValues.priceOnly,
      label: formTypeValues.priceOnly,
    },
    price: '',
    containerCharge: '',
  }

  if (priceChangeFormType === formTypeValues.pricePromotionOnly) initValues.pricePromotion = ''

  const validationSchema = Yup.object().shape(validationFields)
  return (
    <Formik
      initialValues={{
        updateField: {
          value: formTypeValues.priceOnly,
          label: formTypeValues.priceOnly,
        },
        price: '',
        containerCharge: '',
        pricePromotion: '',
      }}
      onSubmit={(newPrices, { setSubmitting }) => {
        let finalPricePostsList = ''
        newPrices.updateField.value === formTypeValues.both
          ? (finalPricePostsList = bulkSelected.map(bs => {
              return {
                ...bs,
                status: { value: bs.status, label: bs.status },
                county: [{ value: bs.county, label: bs.county }],
                receivingMethod: [bs.receivingMethod],
                price: newPrices.price,
                containerCharge: newPrices.containerCharge,
                pricePromotion: bs.pricePromotion
                  ? { value: 'true', label: 'Yes' }
                  : { value: 'false', label: 'No' },
              }
            }))
          : newPrices.updateField.value === formTypeValues.priceOnly
          ? (finalPricePostsList = bulkSelected.map(bs => {
              return {
                ...bs,
                status: { value: bs.status, label: bs.status },
                county: [{ value: bs.county, label: bs.county }],
                receivingMethod: [bs.receivingMethod],
                price: newPrices.price,
                pricePromotion: bs.pricePromotion
                  ? { value: 'true', label: 'Yes' }
                  : { value: 'false', label: 'No' },
              }
            }))
          : newPrices.updateField.value === formTypeValues.containerOnly
          ? (finalPricePostsList = bulkSelected.map(bs => {
              return {
                ...bs,
                status: { value: bs.status, label: bs.status },
                county: [{ value: bs.county, label: bs.county }],
                receivingMethod: [bs.receivingMethod],
                containerCharge: newPrices.containerCharge,
                pricePromotion: bs.pricePromotion
                  ? { value: 'true', label: 'Yes' }
                  : { value: 'false', label: 'No' },
              }
            }))
          : (finalPricePostsList = bulkSelected.map(bs => {
              return {
                ...bs,
                status: { value: bs.status, label: bs.status },
                county: [{ value: bs.county, label: bs.county }],
                receivingMethod: [bs.receivingMethod],
                pricePromotion: newPrices.pricePromotion,
              }
            }))
        dispatch(handlePricePostSubmit(finalPricePostsList))
        dispatch(setIsMultiPriceChangeModalOpen(false))
        // setNewPrices(newPrices)
      }}
      validationSchema={validationSchema}
    >
      <Form noValidate>
        <Field
          name='multiplePriceChange'
          render={formikProps => {
            const handleAutoCompleteChange = (field, event) => {
              formikProps.form.setFieldValue('price', '')
              formikProps.form.setFieldValue('containerCharge', '')
              allOwnManufacturerProducts && formikProps.form.setFieldValue('pricePromotion', '')
              setPriceChangeFormType(event.value)
              formikProps.form.setFieldValue(`${field}`, event)
            }

            let fieldGridValue = 6
            let buttonGridValue = 3
            if (priceChangeFormType === formTypeValues.both) {
              fieldGridValue = 4
              buttonGridValue = 2
            }
            return (
              <CustomCard title='Please submit new prices'>
                <Grid item xs={12} sm={12} md={buttonGridValue} lg={buttonGridValue}>
                  <Field
                    placeHolderText='Update fields'
                    name='updateField'
                    formikProps={formikProps.form}
                    selectedValue={formikProps.form.values.updateField}
                    dropDownOptions={dropdownTypeOptions}
                    onValueChange={event => handleAutoCompleteChange('updateField', event)}
                    isMulti={false}
                    component={AutoCompleteComp}
                  />
                </Grid>

                {(priceChangeFormType === formTypeValues.both ||
                  priceChangeFormType === formTypeValues.priceOnly) && (
                  <Grid item xs={12} sm={6} md={fieldGridValue} lg={fieldGridValue}>
                    <Field
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label='Price'
                      id='price'
                      name='price'
                      required
                      prefix={'$'}
                      placeholder='0.00'
                      decimalScale={3}
                      component={NumericInput}
                      isAllowed={values => {
                        const { formattedValue, floatValue } = values
                        if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                          return false
                        }
                        return formattedValue === '' || floatValue <= 9999.99
                      }}
                    />
                  </Grid>
                )}
                {(priceChangeFormType === formTypeValues.both ||
                  priceChangeFormType === formTypeValues.containerOnly) && (
                  <Grid item xs={12} sm={6} md={fieldGridValue} lg={fieldGridValue}>
                    <Field
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      required
                      label='Container Charge'
                      id='containerCharge'
                      name='containerCharge'
                      decimalScale={2}
                      fixedDecimalScale={true}
                      component={NumericInput}
                      isAllowed={values => {
                        const { formattedValue, floatValue } = values
                        if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                          return false
                        }
                        return formattedValue === '' || floatValue <= 9999.99
                      }}
                    />
                  </Grid>
                )}
                {priceChangeFormType === formTypeValues.pricePromotionOnly && (
                  <Grid item xs={12} sm={6} md={fieldGridValue} lg={fieldGridValue}>
                    <Field
                      placeHolderText='Price Promotion *'
                      name='pricePromotion'
                      id='pricePromotion'
                      required
                      selectedValue={formikProps.form.values.pricePromotion}
                      formikProps={formikProps.form}
                      dropDownOptions={[
                        { value: 'false', label: 'No' },
                        { value: 'true', label: 'Yes' },
                      ]}
                      onValueChange={event => {
                        formikProps.form.setFieldValue('pricePromotion', event)
                      }}
                      isMulti={false}
                      component={AutoCompleteComp}
                    />
                  </Grid>
                )}
                <SubmitButtonWrapper item xs={12} sm={12} md={buttonGridValue} lg={buttonGridValue}>
                  <StyledButton
                    aria-label='Submit-competitive-prices'
                    color='primary'
                    type='submit'
                    disabled={!formikProps.form.isValid}
                    variant='contained'
                  >
                    <Save />
                    &nbsp;&nbsp;{'Submit'}
                  </StyledButton>
                </SubmitButtonWrapper>
              </CustomCard>
            )
          }}
        />
      </Form>
    </Formik>
  )
}

export default MultiplePriceChange
