import React, { useRef } from 'react'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { LocalPrintshop, Edit, Send, Block, HowToReg } from '@material-ui/icons'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LegalEntityPrintTemplate from 'components/common/LegalEntityPrintTemplate'
import LoadingIndicator from 'components/common/LoadingIndicator'
import {
  resendRegistration,
  setDeactivationConfirmation,
  setReactivationConfirmation,
} from 'ducks/legalEntity'
import { setEditMode } from 'ducks/global'
import { format } from 'date-fns'

const LeIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 60px;
    height: 60px;
  }
`

const DontPrintWrapper = styled.div`
  @media print {
    display: none;
  }
`

const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`
const SendIcon = styled(Send)`
  && {
    font-size: 30px;
  }
`
const PrintIcon = styled(LocalPrintshop)`
  && {
    font-size: 30px;
  }
`
const ViewLegalEntity = () => {
  const dispatch = useDispatch()
  const legalEntityViewRef = useRef()
  const { legalEntityDetails } = useSelector(store => store.legalEntity)
  const { isAuxLoading, loadingAuxText } = useSelector(store => store.global)
  const { premisesAddress, mailingAddress } = legalEntityDetails
  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <div>
          <DontPrintWrapper>
            <CustomCard
              profileIcon={
                <LeIcon>
                  {legalEntityDetails.name.trim().charAt(0).toUpperCase()}
                </LeIcon>
              }
              profileTitle={
                <Typography
                  children={`${legalEntityDetails.name}`}
                  variant='h6'
                />
              }
              profileSubHeading={
                <>
                  <strong>
                    Status:&nbsp;
                    <span
                      className={`${legalEntityDetails.status.toLowerCase()}`}
                    >
                      {legalEntityDetails.status}
                    </span>
                  </strong>
                </>
              }
              profileAction={
                <>
                  <IconButton
                    aria-label='Edit Licensee'
                    aria-haspopup='false'
                    color='primary'
                    onClick={() => {
                      dispatch(setEditMode(true))
                    }}
                  >
                    <EditIcon color='primary' />
                    <Typography variant='subtitle1'>&nbsp;{'Edit'}</Typography>
                  </IconButton>
                  <>
                    {legalEntityDetails.licensed && (
                      <ReactToPrint
                        copyStyles={true}
                        trigger={() => (
                          <IconButton
                            aria-label='Print Licensee profile'
                            aria-haspopup='false'
                            color='primary'
                          >
                            <PrintIcon color='primary' />
                            <Typography variant='subtitle1'>
                              &nbsp;{'Print'}
                            </Typography>
                          </IconButton>
                        )}
                        content={() => legalEntityViewRef.current}
                      />
                    )}
                    <>
                      {legalEntityDetails.email &&
                        legalEntityDetails.email.length > 0 &&
                        legalEntityDetails.status === 'Pending' && (
                          <IconButton
                            aria-label='Resend Registration'
                            aria-haspopup='false'
                            color='primary'
                            onClick={() => {
                              dispatch(resendRegistration(legalEntityDetails))
                            }}
                          >
                            <SendIcon color='primary' />
                            <Typography variant='subtitle1'>
                              &nbsp;{'Resend Registration'}
                            </Typography>
                          </IconButton>
                        )}
                    </>
                    <>
                      {legalEntityDetails.status === 'Active' ||
                      legalEntityDetails.status === 'Pending' ? (
                        <IconButton
                          aria-label='Deactivate licensee'
                          aria-haspopup='false'
                          color='primary'
                          onClick={() => {
                            dispatch(setDeactivationConfirmation(true))
                          }}
                        >
                          <Block color='error' />
                          <Typography variant='subtitle1'>
                            &nbsp;{'Deactivate'}
                          </Typography>
                        </IconButton>
                      ) : legalEntityDetails.status === 'Inactive' ? (
                        <IconButton
                          aria-label='Reactivate licensee'
                          aria-haspopup='false'
                          color='primary'
                          onClick={() => {
                            dispatch(setReactivationConfirmation(true))
                          }}
                        >
                          <HowToReg color='primary' />
                          <Typography variant='subtitle1'>
                            &nbsp;{'Reactivate'}
                          </Typography>
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </>
                  </>
                </>
              }
            />

            <>
              <CustomCard title='Licensee'>
                {legalEntityDetails.licensed && (
                  <DataGrid
                    label='Unique Identifier'
                    value={legalEntityDetails.uniqueId}
                  />
                )}
                <DataGrid label='Name' value={legalEntityDetails.name} />
                <DataGrid label='Email' value={legalEntityDetails.email} />
                {legalEntityDetails.licensed ? (
                  <>
                    <DataGrid
                      label='Prior Status'
                      value={
                        legalEntityDetails.previous_status
                          ? legalEntityDetails.previous_status
                          : 'N/A'
                      }
                    />
                    <DataGrid
                      label='Category'
                      value={legalEntityDetails.licenseCategory}
                    />
                    <DataGrid
                      label='In litigation'
                      labelRepresentsQuestion={true}
                      value={
                        legalEntityDetails.inLitigation === true ? 'Yes' : 'No'
                      }
                    />
                  </>
                ) : (
                  <DataGrid label='Type' value='Unlicensed' />
                )}
                <DataGrid
                  label='Added Date'
                  value={
                    legalEntityDetails.createdAt
                      ? format(legalEntityDetails.createdAt, 'MM/dd/yyyy')
                      : ''
                  }
                />
                <DataGrid
                  label='Edited Date'
                  value={
                    legalEntityDetails.updatedAt
                      ? format(legalEntityDetails.updatedAt, 'MM/dd/yyyy')
                      : ''
                  }
                />

                {legalEntityDetails.licensed ? (
                  <DataGrid
                    label='License Types'
                    value={legalEntityDetails.licenseTypes}
                  />
                ) : (
                  ''
                )}
              </CustomCard>
              {legalEntityDetails.licensed && (
                <CustomCard
                  title={
                    legalEntityDetails.isMailingSameAsPremises
                      ? 'Premises & Mailing Address'
                      : 'Premises Address'
                  }
                >
                  <DataGrid
                    label='Address Line 1'
                    value={premisesAddress.address}
                  />
                  <DataGrid
                    label='Address Line 2'
                    value={premisesAddress.address2}
                  />
                  <DataGrid label='City' value={premisesAddress.city} />
                  <DataGrid label='State' value={premisesAddress.state} />
                  <DataGrid label='Zip Code' value={premisesAddress.zipcode} />
                  <DataGrid label='Country' value={premisesAddress.country} />
                </CustomCard>
              )}
              {legalEntityDetails.licensed && (
                <>
                  {!legalEntityDetails.isMailingSameAsPremises && (
                    <CustomCard title='Mailing Address'>
                      <DataGrid
                        label='Address Line 1'
                        value={mailingAddress.address}
                      />
                      <DataGrid
                        label='Address Line 2'
                        value={mailingAddress.address2}
                      />
                      <DataGrid label='City' value={mailingAddress.city} />
                      <DataGrid label='State' value={mailingAddress.state} />
                      <DataGrid
                        label='Zip Code'
                        value={mailingAddress.zipcode}
                      />
                      <DataGrid
                        label='Country'
                        value={mailingAddress.country}
                      />
                    </CustomCard>
                  )}
                </>
              )}
            </>
          </DontPrintWrapper>
          {legalEntityDetails.licensed && (
            <LegalEntityPrintTemplate
              ref={legalEntityViewRef}
              printingProps={[legalEntityDetails]}
            />
          )}
        </div>
      )}
    </>
  )
}

export default ViewLegalEntity
