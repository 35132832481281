import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

const CustomButton = styled(Button)`
  && {
    height: 3rem;
    margin: 20px 20px 20px 0;
  }
`

const SubmitButton = ({ isSubmitting, text = 'Submit' }) => (
  <CustomButton
    disabled={isSubmitting === true}
    type='submit'
    fullWidth
    variant='contained'
    color='primary'
  >
    {isSubmitting ? <CircularProgress color='primary' size={25} /> : text}
  </CustomButton>
)
export default SubmitButton
