import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import {
  addNewPricesTo,
  updatePricesTo,
  setPricesToShown,
  resetPricesToDetails,
} from 'ducks/pricesTo'

const validations = Yup.object().shape({
  pricesTo: Yup.object().shape({
    name: Yup.string().required('Required'),
  }),
})

const EditPricesTo = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)
  //   const abilityFunc = useContext(AbilityContext)

  const onAddPricesTo = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      let finalObject = formikValues.values.pricesTo
      if (finalObject.id) {
        dispatch(
          updatePricesTo({ ...finalObject, id: parseInt(finalObject.id) })
        )
      } else {
        dispatch(addNewPricesTo(finalObject))
      }
    }
  }

  return (
    <Form>
      <Field
        name='pricesTo'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.pricesTo = JSON.parse(
            JSON.stringify(formikProps.form.values.pricesTo)
          )
          const formikValues = formikProps.form.values

          const handleCancel = () => {
            dispatch(setPricesToShown(false))
            dispatch(resetPricesToDetails())
          }

          return (
            <>
              {isLoading ? (
                <LoadingIndicator text={'Loading Prices To...'} transparent />
              ) : (
                <>
                  <CustomCard title='Prices To'>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Price To'
                        name='pricesTo.name'
                        component={TextField}
                      />
                    </Grid>
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddPricesTo(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.pricesTo.id && 'update'}
                    isDisabled={
                      formikValues.pricesTo.id && !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditPricesTo)

export default WithFormikWrapper
