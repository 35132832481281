import { setLoading, showError, showSuccess, setFetchingTotalCount } from './global'
import {
  // setPricePostingsList,
  setIsCompetingUsingMyOwn,
  setCompetingUsingOthers,
  getPricePostingsList,
} from './pricePostings'
import { whereClauseBuilderForPP, removeExtraCharFromPrice, isDateSameAsToday } from 'common/helper'
import { API, graphqlOperation } from 'aws-amplify'
import { createReducer, createAction } from '@reduxjs/toolkit'
import {
  getPricePostingsQueryAsLicensee,
  getPPForBulkSelectionAsLicensee,
  getPricePostingsQuery,
  getPPForBulkSelection,
  getPricePostingsAsLicenseeTotalCount,
  getPricePostingsTotalCount,
} from 'graphql/queries'
import { competitivePricePosting } from 'graphql/mutations'
import { formTypeValues } from 'common/constants'

const fetchPricePostingsTotalCount = graphQlParams => {
  return async dispatch => {
    dispatch(setFetchingTotalCount(true))
    if (graphQlParams.manufacturerId) {
      await API.graphql(graphqlOperation(getPricePostingsAsLicenseeTotalCount, graphQlParams))
        .then(res => {
          dispatch(setMyCompetingPpTotalCount(res.data.pricePostingsAsLicenseeCount.count))
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    } else {
      await API.graphql(graphqlOperation(getPricePostingsTotalCount, graphQlParams))
        .then(res => {
          dispatch(setCompetingWithPPTotalCount(res.data.pricePostingsCount.count))
        })
        .catch(err => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    }
    dispatch(setFetchingTotalCount(false))
  }
}

export const getListOfMyPPForCompeting = (selectAll = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Looking for similar price postings...'))
    const competitivePricePostingState = getState().competitivePricePosting
    const { singlePricePostSelected, isCompetingUsingOthers } = getState().pricePostings
    const currentUser = getState().auth.user

    const orderRule = `${competitivePricePostingState.competitiveListOrderBy}_${competitivePricePostingState.competitiveListOrder}`

    let finalWhereParams = {
      status: [{ value: 'Active' }],
      createdByLicensee: [{ value: parseInt(currentUser.legalEntity.id) }],
      manufacturer: !isCompetingUsingOthers && [{ value: singlePricePostSelected.manufacturer.id }],
      products: !isCompetingUsingOthers && [{ value: singlePricePostSelected.product.id }],
      county: isCompetingUsingOthers && [{ value: singlePricePostSelected.county }],
      receivingMethod: [{ value: singlePricePostSelected.receivingMethod }],
      package: [{ value: singlePricePostSelected.package.id }],
      size: [{ value: singlePricePostSelected.productSize.id }],
      pricesTo: [{ value: singlePricePostSelected.pricesTo.id }],
    }

    if (selectAll) {
      const graphQlParams = {
        manufacturerId: parseInt(currentUser.legalEntity.id),
        userId: parseInt(currentUser.id),
        where: whereClauseBuilderForPP(finalWhereParams),
      }

      const res = await API.graphql(
        graphqlOperation(getPPForBulkSelectionAsLicensee, graphQlParams)
      )
      const myCompetingPricePosts = res.data.pricePostingsAsLicensee
      if (myCompetingPricePosts) {
        const allItemsSelected = myCompetingPricePosts.results.filter(item => {
          return (
            (!item.competitivePostId ||
              (item.competitivePostId && !isDateSameAsToday(item.updatedAt))) && {
              id: item.id,
              price: item.price,
              containerCharge: item.containerCharge,
              county: item.county,
            }
          )
        })
        dispatch(setBulkCompetitiveItemsSelected(allItemsSelected))
      } else {
        dispatch(
          showError(
            'Unable to select all price postings, please check your internet connection and try again. Thank you.'
          )
        )
      }
    } else {
      const graphQlParams = {
        manufacturerId: parseInt(currentUser.legalEntity.id),
        userId: parseInt(currentUser.id),
        orderBy: orderRule,
        limit: competitivePricePostingState.competitiveListRowsPerPage,
        offset:
          competitivePricePostingState.competitiveListPageNumber *
          competitivePricePostingState.competitiveListRowsPerPage,
        where: whereClauseBuilderForPP(finalWhereParams),
      }
      let myCompetingPricePosts
      await API.graphql(graphqlOperation(getPricePostingsQueryAsLicensee, graphQlParams))
        .then(res => {
          myCompetingPricePosts = res.data.pricePostingsAsLicensee
          dispatch(
            setMyCompetingPricePosts({
              ...myCompetingPricePosts,
              results: myCompetingPricePosts.results.filter(item => {
                return (
                  (!item.competitivePostId ||
                    (item.competitivePostId && !isDateSameAsToday(item.updatedAt))) &&
                  item
                )
              }),
            })
          )
        })
        .catch(_ => {
          dispatch(
            showError(
              'Unable to retrieve list of price postings, please check your internet connection and try again. Thank you.'
            )
          )
        })
      if (myCompetingPricePosts && competitivePricePostingState.myCompetingPpTotalCount === 0) {
        //fetch total count separately
        delete graphQlParams.limit
        delete graphQlParams.offset
        delete graphQlParams.orderBy

        dispatch(fetchPricePostingsTotalCount(graphQlParams))
      }
    }
    dispatch(setLoading(false))
  }
}

export const getCompetingWithPPList = (selectAll = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading price posts to compete with...'))

    const {
      competitorListSearchCriteria,
      bulkCompetitiveItemsSelected,
      competingWithListOrder,
      competingWithListOrderBy,
      competingWithListRowsPerPage,
      competingWithListPageNumber,
      competingWithPPTotalCount,
    } = getState().competitivePricePosting
    const currentUserLeID = getState().auth.user.legalEntity.id
    const { singlePricePostSelected } = getState().pricePostings
    const orderRule = `${competingWithListOrderBy}_${competingWithListOrder}`

    const countyList = bulkCompetitiveItemsSelected.map(b => {
      return { value: b.county }
    })

    let finalWhereParams = {
      county: countyList,
      receivingMethod: [{ value: singlePricePostSelected.receivingMethod }],
      package: [{ value: singlePricePostSelected.package.id }],
      size: [{ value: singlePricePostSelected.productSize.id }],
      pricesTo: [{ value: singlePricePostSelected.pricesTo.id }],
      status: [{ value: 'Active' }],
    }

    if (competitorListSearchCriteria.createdByLicensee) {
      finalWhereParams.createdByLicensee = [competitorListSearchCriteria.createdByLicensee]
    } else {
      finalWhereParams.createdByLicenseeNot = [{ value: currentUserLeID }]
    }

    if (competitorListSearchCriteria.productLike) {
      finalWhereParams.productLike = competitorListSearchCriteria.productLike
    }

    if (selectAll) {
      let graphQlParams = {
        where: whereClauseBuilderForPP(finalWhereParams),
      }

      const res = await API.graphql(graphqlOperation(getPPForBulkSelection, graphQlParams))
      const competingWithPricePostings = res.data.pricePostings
      if (competingWithPricePostings) {
        const allItemsSelected = competingWithPricePostings.results.map(item => {
          return {
            id: item.id,
            price: item.price,
            containerCharge: item.containerCharge,
          }
        })
        dispatch(setBulkSelectionOfCompetingWithItems(allItemsSelected))
      } else {
        dispatch(
          showError(
            'Unable to select all price postings, please check your internet connection and try again. Thank you.'
          )
        )
      }
    } else {
      let graphQlParams = {
        orderBy: orderRule,
        limit: competingWithListRowsPerPage,
        offset: competingWithListPageNumber * competingWithListRowsPerPage,
        where: whereClauseBuilderForPP(finalWhereParams),
      }
      let competingWithPricePostings

      await API.graphql(graphqlOperation(getPricePostingsQuery, graphQlParams))
        .then(res => {
          competingWithPricePostings = res.data.pricePostings
          dispatch(setListOfCompetingWithPP(competingWithPricePostings))
        })
        .catch(_ => {
          dispatch(
            showError(
              'Unable to retrieve list of price postings, please check your internet connection and try again. Thank you.'
            )
          )
        })
      if (competingWithPricePostings && competingWithPPTotalCount === 0) {
        //fetch total count separately
        delete graphQlParams.limit
        delete graphQlParams.offset
        delete graphQlParams.orderBy

        dispatch(fetchPricePostingsTotalCount(graphQlParams))
      }
    }
    dispatch(setLoading(false))
  }
}

export const performCompetitivePricePosting = newPrices => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Processing price change, please wait...'))
    const currentUser = getState().auth.user
    // let pricePostingsList = getState().pricePostings.pricePostingsList
    const { bulkCompetitiveItemsSelected, bulkSelectionOfCompetingWithItems } =
      getState().competitivePricePosting

    let data = {}
    // Set prices data depending on dropdown selection (Price Only, Container Charge Only or Both)

    newPrices.updateField.value === formTypeValues.both
      ? (data = {
          price: removeExtraCharFromPrice(newPrices.price),
          containerCharge: removeExtraCharFromPrice(newPrices.containerCharge),
        })
      : newPrices.updateField.value === formTypeValues.priceOnly
      ? (data = {
          price: removeExtraCharFromPrice(newPrices.price),
        })
      : (data = {
          containerCharge: removeExtraCharFromPrice(newPrices.containerCharge),
        })
    data.updatedBy = parseInt(currentUser.id)

    const myPricePostingIds = bulkCompetitiveItemsSelected.map(item => parseInt(item.id))
    const competingPricePostingIds = bulkSelectionOfCompetingWithItems.map(item =>
      parseInt(item.id)
    )

    await API.graphql(
      graphqlOperation(competitivePricePosting, {
        myPricePostingIds,
        competingPricePostingIds,
        data,
      })
    )
      .then(res => {
        dispatch(showSuccess('Price post(s) updated successfully.'))
        dispatch(onResetCompetingPricePosting())

        dispatch(getPricePostingsList(false, true))
        // // Update price postings list with new price values (only update price postings changed)
        // data.effectiveDate = new Date().getTime()
        // data.competitivePostId = true // adding a fake true, just for the sake of having some value in the list immediately after competitive process. This competitivePostID is used for showing/hiding competitive option in the action menu
        // data.updatedAt = new Date().getTime()
        // let results = pricePostingsList.results
        // // Find my price post ids and update them with new data
        // const updatedItems = results
        //   .filter(p => myPricePostingIds.map(id => `${id}`).includes(p.id))
        //   .map(p => ({ ...p, ...data }))
        // // Replace new items in old results list
        // const newPricePostingsList = results.map(
        //   obj => updatedItems.find(o => o.id === obj.id) || obj
        // )
        // // Update list on page
        // dispatch(
        //   setPricePostingsList({
        //     ...pricePostingsList,
        //     results: newPricePostingsList,
        //   })
        // )
      })
      .catch(err => {
        dispatch(
          showError(
            'There was an issue updating the price post, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const onResetCompetingPricePosting = () => {
  return async dispatch => {
    dispatch(resetCompetingWithPPList())
    dispatch(resetMyCompetingPricePosts())
    dispatch(setCompetingUsingOthers(false))
    dispatch(setIsCompetingUsingMyOwn(false))
  }
}

export const setCompetitiveListPageNumber = createAction(
  'competitivePricePosting/setCompetitiveListPageNumber'
)
export const setCompetitiveListRowsPerPage = createAction(
  'competitivePricePosting/setCompetitiveListRowsPerPage'
)
export const setCompetitiveListRequestedSort = createAction(
  'competitivePricePosting/setCompetitiveListRequestedSort'
)
export const setMyCompetingPricePosts = createAction(
  'competitivePricePosting/setMyCompetingPricePosts'
)
export const setMyCompetingPpTotalCount = createAction(
  'competitivePricePosting/setMyCompetingPpTotalCount'
)
export const setBulkCompetitiveItemsSelected = createAction(
  'competitivePricePosting/setBulkCompetitiveItemsSelected'
)
export const setPriceAndContainerFormType = createAction(
  'competitivePricePosting/setPriceAndContainerFormType'
)
export const resetMyCompetingPricePosts = createAction(
  'competitivePricePosting/resetMyCompetingPricePosts'
)

//------------------Competing with items-----------------

export const setCompetingWithListPageNumber = createAction(
  'competitivePricePosting/setCompetingWithListPageNumber'
)
export const setCompetingWithListRowsPerPage = createAction(
  'competitivePricePosting/setCompetingWithListRowsPerPage'
)
export const setCompetingWithListRequestSort = createAction(
  'competitivePricePosting/setCompetingWithListRequestSort'
)
export const setListOfCompetingWithPP = createAction(
  'competitivePricePosting/setListOfCompetingWithPP'
)
export const setCompetingWithPPTotalCount = createAction(
  'competitivePricePosting/setCompetingWithPPTotalCount'
)
export const setBulkSelectionOfCompetingWithItems = createAction(
  'competitivePricePosting/setBulkSelectionOfCompetingWithItems'
)
export const setCompetitorListSearchCriteria = createAction(
  'competitivePricePosting/setCompetitorListSearchCriteria'
)
export const resetCompetingWithPPList = createAction(
  'competitivePricePosting/resetCompetingWithPPList'
)

const initialState = {
  myCompetingPricePosts: {
    results: [],
    count: 0,
    firstLoad: true,
  },
  myCompetingPpTotalCount: 0,
  competitiveListOrder: 'DESC',
  competitiveListOrderBy: 'createdAt',
  competitiveListPageNumber: 0,
  competitiveListRowsPerPage: 5,
  bulkCompetitiveItemsSelected: [],
  priceAndContainerFormType: false,
  listOfCompetingWithPP: {
    results: [],
    count: 0,
    firstLoad: true,
  },
  competingWithPPTotalCount: 0,
  competingWithListOrder: 'DESC',
  competingWithListOrderBy: 'createdAt',
  competingWithListPageNumber: 0,
  competingWithListRowsPerPage: 5,
  bulkSelectionOfCompetingWithItems: [],
  competitorListSearchCriteria: '',
}

export default createReducer(initialState, {
  [setCompetitiveListPageNumber]: (state, action) => {
    state.competitiveListPageNumber = action.payload
  },
  [setCompetitiveListRowsPerPage]: (state, action) => {
    state.competitiveListRowsPerPage = parseInt(action.payload, 10)
    state.competitiveListPageNumber = 0
  },
  [setCompetitiveListRequestedSort]: (state, action) => {
    const isDesc =
      state.competitiveListOrderBy === action.payload && state.competitiveListOrder === 'DESC'
    state.competitiveListOrder = isDesc ? 'ASC' : 'DESC'
    state.competitiveListOrderBy = action.payload
  },
  [setMyCompetingPricePosts]: (state, action) => {
    state.myCompetingPricePosts = action.payload
  },
  [setMyCompetingPpTotalCount]: (state, action) => {
    state.myCompetingPpTotalCount = action.payload
  },
  [setBulkCompetitiveItemsSelected]: (state, action) => {
    state.bulkCompetitiveItemsSelected = action.payload
  },
  [setPriceAndContainerFormType]: (state, action) => {
    state.priceAndContainerFormType = action.payload
  },
  [resetMyCompetingPricePosts]: (state, action) => {
    state.competitiveListOrder = initialState.competitiveListOrder
    state.competitiveListOrderBy = initialState.competitiveListOrderBy
    state.competitiveListPageNumber = initialState.competitiveListPageNumber
    state.competitiveListRowsPerPage = initialState.competitiveListRowsPerPage
    state.myCompetingPricePosts = initialState.myCompetingPricePosts
    state.myCompetingPpTotalCount = initialState.myCompetingPpTotalCount
    state.bulkCompetitiveItemsSelected = initialState.bulkCompetitiveItemsSelected
    state.priceAndContainerFormType = initialState.priceAndContainerFormType
  },

  //-------------Competing with items--------------------
  [setCompetingWithListPageNumber]: (state, action) => {
    state.competingWithListPageNumber = action.payload
  },
  [setCompetingWithListRowsPerPage]: (state, action) => {
    state.competingWithListRowsPerPage = parseInt(action.payload, 10)
    state.competingWithListPageNumber = 0
  },
  [setCompetingWithListRequestSort]: (state, action) => {
    const isDesc =
      state.competingWithListOrderBy === action.payload && state.competingWithListOrder === 'DESC'
    state.competingWithListOrder = isDesc ? 'ASC' : 'DESC'
    state.competingWithListOrderBy = action.payload
  },
  [setListOfCompetingWithPP]: (state, action) => {
    state.listOfCompetingWithPP = action.payload
  },
  [setCompetingWithPPTotalCount]: (state, action) => {
    state.competingWithPPTotalCount = action.payload
  },
  [setBulkSelectionOfCompetingWithItems]: (state, action) => {
    state.bulkSelectionOfCompetingWithItems = action.payload
  },
  [setCompetitorListSearchCriteria]: (state, action) => {
    state.competitorListSearchCriteria = action.payload
  },
  [resetCompetingWithPPList]: state => {
    state.listOfCompetingWithPP = initialState.listOfCompetingWithPP
    state.competingWithPPTotalCount = initialState.competingWithPPTotalCount
    state.competingWithListPageNumber = initialState.competingWithListPageNumber
    state.competingWithListRowsPerPage = initialState.competingWithListRowsPerPage
    state.competingWithListOrderBy = initialState.competingWithListOrderBy
    state.competingWithListOrder = initialState.competingWithListOrder
    state.bulkSelectionOfCompetingWithItems = initialState.bulkSelectionOfCompetingWithItems
    state.priceAndContainerFormType = initialState.priceAndContainerFormType
    state.competitorListSearchCriteria = initialState.competitorListSearchCriteria
  },
})
