import { createAction, createReducer } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { openSnackBar } from './snackbar'
import {
  getLicenseTypesQuery,
  getRolesQuery,
  getRolesQueryForDropDown,
  getLicenseesCountQuery,
  getLicenseesCountForPublicQuery,
  getLEsForDropdownQuery,
  getLEsForPublicDropdownQuery,
  getUnitsQuery,
  getContainerTypes,
  getPricesToForDropDownQuery,
  getProductsWithTradeQuery,
  getPackagesForDropDown,
  getProductSizesForDropDown,
} from 'graphql/queries'
import {
  checkIfUserIsABC,
  checkUserIsManufacturerNotCoc,
  checkUserIsCocOnly,
  checkUserIsManufacturerAndWholesaler,
  getToken,
  convertSizeOfBBL,
} from 'common/helper'
import { print } from 'graphql'

export const showSuccess = message => {
  return async dispatch => {
    dispatch(openSnackBar('bottom', 'center', message, 'success'))
  }
}

export const showError = message => {
  return async dispatch => {
    dispatch(openSnackBar('bottom', 'center', message, 'error'))
  }
}

export const showWarning = message => {
  return async dispatch => {
    dispatch(openSnackBar('bottom', 'center', message, 'warning'))
  }
}

export const setLicenseTypes = createAction('global/setLicenseTypes')
export const setRoles = createAction('global/setRoles')
export const setEditMode = createAction('global/setEditMode')
export const setLoading = createAction('global/setLoading')
export const setLoadingProgress = createAction('global/setLoadingProgress')
export const setAuxLoading = createAction('global/setAuxLoading')
export const setLEsForDropDown = createAction('global/setLEsForDropDown')
export const setLEsForPublicDropDown = createAction(
  'global/setLEsForPublicDropDown'
)
export const setUnitsOfMeasure = createAction('global/setUnitsOfMeasure')
export const setPricesToForDropDown = createAction(
  'global/setPricesToForDropDown'
)
export const setProductsWithTrade = createAction('global/setProductsWithTrade')
export const setPackageConfigForDropDown = createAction(
  'global/setPackageConfigForDropDown'
)
export const setProductSizesForDropDown = createAction(
  'global/setProductSizesForDropDown'
)
export const resetGlobalDuck = createAction('global/resetGlobalDuck')
export const setRolesForDropDown = createAction('global/setRolesForDropDown')
export const setProductsWithTradeForBulkProcess = createAction(
  'global/setProductsWithTradeForBulkProcess'
)
export const setNewProductAdded = createAction('global/setNewProductAdded')
export const setNewPackageConfigAdded = createAction(
  'global/setNewPackageConfigAdded'
)
export const setNewProductSizeAdded = createAction(
  'global/setNewProductSizeAdded'
)
export const setNewPricesToAdded = createAction('global/setNewPricesToAdded')
export const setNewLicenseeAdded = createAction('global/setNewLicenseeAdded')
export const setContainerTypes = createAction('global/setContainerTypes')
export const setFetchingTotalCount = createAction(
  'global/setFetchingTotalCount'
)

export const getLicenseTypes = () => {
  return async (dispatch, getState) => {
    const currLicenseTypes = getState().global.licenseTypes
    // If license types aren't already defined, retrieve them from the DB
    if (!currLicenseTypes.length > 0) {
      const res = await API.graphql(graphqlOperation(getLicenseTypesQuery))
      if (res.data.licenseTypes.length > 0) {
        const licenseTypes = res.data.licenseTypes.map(type => ({
          ...type,
          label:
            type.number && type.number.length > 0
              ? `${type.number} - ${type.label}`
              : type.label,
        }))

        dispatch(setLicenseTypes(licenseTypes))
      }
    }
  }
}

export const getRoles = () => {
  return async (dispatch, getState) => {
    const currentRoles = getState().global.roles
    if (!currentRoles) {
      const res = await API.graphql(graphqlOperation(getRolesQuery))
      if (res.data.roles.length > 0) {
        dispatch(setRoles(res.data.roles))
      }
    }
  }
}

export const getRolesForDropDown = () => {
  return async (dispatch, getState) => {
    const currentRoles = getState().global.rolesForDropDown
    if (!currentRoles) {
      const res = await API.graphql(graphqlOperation(getRolesQueryForDropDown))
      if (res.data.roles.length > 0) {
        dispatch(setRolesForDropDown(res.data.roles))
      }
    }
  }
}

export const getLEsForDropDown = publicView => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading manufacturers list...'))
    const { newLicenseeAdded, lEsForDropDown, lEsForPublicDropDown } =
      getState().global

    try {
      const batchSize = 25000
      // Only retrieve licensees if there's a newLicenseeAdded or licensees aren't already in Redux
      // If we're on the public view get only Active licensees
      if (
        newLicenseeAdded ||
        (publicView && !lEsForPublicDropDown.length > 0)
      ) {
        const licenseesList = await getLicenseesInBatchesForPublic(batchSize)

        dispatch(setLEsForPublicDropDown(licenseesList))
        dispatch(setNewLicenseeAdded(false))
      } else if (
        newLicenseeAdded ||
        (!publicView && !lEsForDropDown.length > 0)
      ) {
        const licenseesList = await getLicenseesInBatches(batchSize)

        dispatch(setLEsForDropDown(licenseesList))
        dispatch(setNewLicenseeAdded(false))
      }
    } catch (err) {
      dispatch(
        showError(
          'There was some error while fetching licensees, please check your connection and try again. Thank you.'
        )
      )
    }
    dispatch(setLoading(false))
  }
}

const getLicenseesInBatches = async batchSize => {
  let currCount = 0,
    licenseesList = []
  // Get total count
  const licenseesCountRes = await API.graphql(
    graphqlOperation(getLicenseesCountQuery)
  )
  const licenseesCount = licenseesCountRes.data.legalEntities.count

  // Get licensees in batches
  while (currCount < licenseesCount) {
    const res = await API.graphql(
      graphqlOperation(getLEsForDropdownQuery, {
        limit: batchSize,
        offset: currCount,
      })
    )

    currCount += res.data.legalEntities.count
    licenseesList = licenseesList.concat(res.data.legalEntities.results)
  }
  return licenseesList
}

const getLicenseesInBatchesForPublic = async batchSize => {
  let currCount = 0,
    licenseesList = []
  // Get total count
  const licenseesCount = await fetch(
    `${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer ' + getToken({ code: 'getLicenseesCountForPublicQuery' }),
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getLicenseesCountForPublicQuery),
      }),
    }
  )
    .then(response => response.json())
    .then(res => {
      return res.data.legalEntities.count
    })

  // Get licensees in batches
  while (currCount < licenseesCount) {
    const legalEntities = await fetch(
      `${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization:
            'Bearer ' + getToken({ code: 'getLEsForDropdownQuery' }),
          'content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: print(getLEsForPublicDropdownQuery),
          variables: { limit: batchSize, offset: currCount },
        }),
      }
    )
      .then(response => response.json())
      .then(res => {
        return res.data.legalEntities
      })

    currCount += legalEntities.count
    licenseesList = licenseesList.concat(legalEntities.results)
  }
  return licenseesList
}

export const fetchPricesTo = publicView => {
  return async (dispatch, getState) => {
    const { pricesToForDropDown, newPricesToAdded } = getState().global
    if (newPricesToAdded || !pricesToForDropDown.length > 0) {
      let finalList
      if (publicView) {
        await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization:
              'Bearer ' + getToken({ code: 'getPricesToForDropDownQuery' }),
            'content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: print(getPricesToForDropDownQuery),
          }),
        })
          .then(response => response.json())
          .then(res => {
            finalList = res.data.pricesTo.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some issue while fetching "Prices To" options, check your internet connection and try again. Thank you.'
              )
            )
          })
      } else {
        await API.graphql(graphqlOperation(getPricesToForDropDownQuery))
          .then(res => {
            finalList = res.data.pricesTo.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some issue while fetching "Prices TO" options, check your internet connection and try again. Thank you.'
              )
            )
          })
      }
      if (finalList) {
        dispatch(setPricesToForDropDown(finalList))
        dispatch(setNewPricesToAdded(false))
      }
    }
  }
}

export const fetchUnitsOfMeasure = () => {
  return async (dispatch, getState) => {
    const currentUnitsList = getState().global.unitsOfMeasure
    if (currentUnitsList.length === 0) {
      await API.graphql(graphqlOperation(getUnitsQuery))
        .then(res => dispatch(setUnitsOfMeasure(res.data.units)))
        .catch(_ =>
          dispatch(
            showError(
              'There was some issue while trying to fetch list of units for selection, check you internet connection and try again. Thank you.'
            )
          )
        )
    }
  }
}

export const fetchContainerTypes = () => {
  return async (dispatch, getState) => {
    const currentContainerTypes = getState().global.containerTypes
    if (currentContainerTypes.length === 0) {
      await API.graphql(graphqlOperation(getContainerTypes))
        .then(res => dispatch(setContainerTypes(res.data.containerTypes)))
        .catch(_ =>
          dispatch(
            showError(
              'There was some error while trying to fetch Container Types for selection, check your internet connection and try again. Thank you.'
            )
          )
        )
    }
  }
}

export const fetchPackageConfiguration = publicView => {
  return async (dispatch, getState) => {
    const { packageConfigForDropDown, newPackageConfigAdded } =
      getState().global
    if (newPackageConfigAdded || !packageConfigForDropDown.length > 0) {
      let finalList
      let queryWhereParam = { status_not: 'Pending' }
      if (publicView) {
        await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization:
              'Bearer ' + getToken({ code: 'getPackagesForDropDown' }),
            'content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: print(getPackagesForDropDown),
            variables: { where: queryWhereParam },
          }),
        })
          .then(response => response.json())
          .then(res => {
            finalList = res.data.packages.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some error while fetching package configurations, please check your internet connection and try again. Thank you.'
              )
            )
          })
      } else {
        await API.graphql(
          graphqlOperation(getPackagesForDropDown, {
            where: queryWhereParam,
          })
        )
          .then(res => {
            finalList = res.data.packages.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some error while fetching package configurations, please check your internet connection and try again. Thank you.'
              )
            )
          })
      }
      if (finalList) {
        dispatch(setPackageConfigForDropDown(finalList))
        dispatch(setNewPackageConfigAdded(false))
      }
    }
  }
}

export const fetchProductSizes = publicView => {
  return async (dispatch, getState) => {
    const { productSizesForDropDown, newProductSizeAdded } = getState().global
    if (newProductSizeAdded || !productSizesForDropDown.length > 0) {
      let finalList
      let queryWhereParam = { status_not: 'Pending' }
      if (publicView) {
        await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization:
              'Bearer ' + getToken({ code: 'getProductSizesForDropDown' }),
            'content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: print(getProductSizesForDropDown),
            variables: { where: queryWhereParam },
          }),
        })
          .then(response => response.json())
          .then(res => {
            finalList = res.data.productSizes.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some error while fetching Product Sizes, please check your internet connection and try again. Thank you.'
              )
            )
          })
      } else {
        await API.graphql(
          graphqlOperation(getProductSizesForDropDown, {
            where: queryWhereParam,
          })
        )
          .then(res => {
            finalList = res.data.productSizes.results
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was some error while fetching Product Sizes, please check your internet connection and try again. Thank you.'
              )
            )
          })
      }
      if (finalList) {
        const newArray = finalList.map(s => {
          return {
            value: s.id,
            label: `\
              ${convertSizeOfBBL(s.size, s.unit.unit)} ${s.unit.unit} - ${
              s.containerType.type
            }
            `.trim(),
            status: s.status,
          }
        })
        dispatch(setProductSizesForDropDown(newArray))
        dispatch(setNewProductSizeAdded(false))
      }
    }
  }
}

export const fetchProductsWithTrade = (selectedManufacturerIds, publicView) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading products from selected licensee...'))
    const currentUser = getState().auth.user
    const { productsWithTrade, newProductAdded } = getState().global
    const userHasAbcRole =
      currentUser.role && checkIfUserIsABC(currentUser.role.id)
    const userIsManufacturerNotCOC = checkUserIsManufacturerNotCoc(
      currentUser.legalEntity
    )

    if (
      selectedManufacturerIds ||
      !productsWithTrade.length > 0 ||
      newProductAdded
    ) {
      let queryWhereParam = selectedManufacturerIds
        ? {
            manufacturerId_in: selectedManufacturerIds.map(mId =>
              parseInt(mId)
            ),
          }
        : userIsManufacturerNotCOC ||
          checkUserIsManufacturerAndWholesaler(currentUser.legalEntity) ||
          checkUserIsCocOnly(currentUser.legalEntity)
        ? { manufacturerId: parseInt(currentUser.legalEntity.id) }
        : ''

      if (queryWhereParam) {
        !userHasAbcRole && (queryWhereParam.status = 'Active')

        let finalList
        if (publicView) {
          await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization:
                'Bearer ' + getToken({ code: 'getProductsWithTradeQuery' }),
              'content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: print(getProductsWithTradeQuery),
              variables: { where: queryWhereParam, userIsManufacturer: false },
            }),
          })
            .then(response => response.json())
            .then(res => {
              finalList = res.data.products.results
            })
            .catch(errorRes => {
              dispatch(
                showError(
                  'There was some issue while fetching product options, please check your internet connection and try again. Thank you.'
                )
              )
            })
        } else {
          await API.graphql(
            graphqlOperation(getProductsWithTradeQuery, {
              where: queryWhereParam,
              userIsManufacturer: userIsManufacturerNotCOC,
            })
          )
            .then(res => {
              finalList = res.data.products.results
            })
            .catch(_ => {
              dispatch(
                showError(
                  'There was some issue while fetching product options, please check your internet connection and try again. Thank you.'
                )
              )
            })
        }
        if (finalList) {
          let newArray = []
          newArray = finalList.map(product => {
            const comboProductName = `${
              !userIsManufacturerNotCOC ? `${product.manufacturer.name} - ` : ''
            }${product.tradeName ? `${product.tradeName} - ` : ''}${
              product.name
            }`
            return {
              value: product.id,
              label: comboProductName,
            }
          })
          dispatch(setProductsWithTradeForBulkProcess(finalList))
          dispatch(setNewProductAdded(false))
          dispatch(setProductsWithTrade(newArray))
        }
      }
    }
    dispatch(setLoading(false))
  }
}

const initialState = {
  licenseTypes: [],
  roles: null,
  rolesForDropDown: null,
  fetchingTotalCount: true,
  isLoading: false,
  loadingText: '',
  loadingProgress: null,
  isAuxLoading: false,
  loadingAuxText: '',
  isEditMode: false,
  lEsForDropDown: [],
  lEsForPublicDropDown: [],
  unitsOfMeasure: [],
  pricesToForDropDown: [],
  productsWithTrade: [],
  packageConfigForDropDown: [],
  productSizesForDropDown: [],
  productsWithTradeForBulkProcess: [],
  newProductAdded: false,
  newPackageConfigAdded: false,
  newProductSizeAdded: false,
  newPricesToAdded: false,
  newLicenseeAdded: false,
  containerTypes: [],
}

export default createReducer(initialState, {
  [setLicenseTypes]: (state, action) => {
    state.licenseTypes = action.payload
  },
  [setRoles]: (state, action) => {
    state.roles = action.payload
  },
  [setRolesForDropDown]: (state, action) => {
    state.rolesForDropDown = action.payload
  },
  [setFetchingTotalCount]: (state, action) => {
    state.fetchingTotalCount = action.payload
  },
  [setLoading]: (state, action) => {
    if (typeof action.payload === 'boolean') {
      state.isLoading = action.payload
      state.loadingText = ''
    } else if (typeof action.payload === 'string') {
      state.isLoading = true
      state.loadingText = action.payload
    } else {
      state.isLoading = action.payload.loading
      state.loadingText = action.payload.text ? action.payload.text : ''
    }
  },
  [setLoadingProgress]: (state, action) => {
    state.loadingProgress = action.payload
  },
  [setAuxLoading]: (state, action) => {
    state.isAuxLoading = action.payload
    if (typeof action.payload === 'boolean') {
      state.isAuxLoading = action.payload
      state.loadingAuxText = ''
    } else if (typeof action.payload === 'string') {
      state.isAuxLoading = true
      state.loadingAuxText = action.payload
    } else {
      state.isAuxLoading = action.payload.loading
      state.loadingAuxText = action.payload.text ? action.payload.text : ''
    }
  },
  [setEditMode]: (state, action) => {
    state.isEditMode = action.payload
  },
  [setLEsForDropDown]: (state, action) => {
    state.lEsForDropDown = action.payload
  },
  [setLEsForPublicDropDown]: (state, action) => {
    state.lEsForPublicDropDown = action.payload
  },
  [setUnitsOfMeasure]: (state, action) => {
    state.unitsOfMeasure = action.payload
  },
  [setPricesToForDropDown]: (state, action) => {
    state.pricesToForDropDown = action.payload
  },
  [setProductsWithTrade]: (state, action) => {
    state.productsWithTrade = action.payload
  },
  [setPackageConfigForDropDown]: (state, action) => {
    state.packageConfigForDropDown = action.payload
  },
  [setProductSizesForDropDown]: (state, action) => {
    state.productSizesForDropDown = action.payload
  },
  [setProductsWithTradeForBulkProcess]: (state, action) => {
    state.productsWithTradeForBulkProcess = action.payload
  },
  [setNewProductAdded]: (state, action) => {
    state.newProductAdded = action.payload
  },
  [setNewPricesToAdded]: (state, action) => {
    state.newPricesToAdded = action.payload
  },
  [setNewPackageConfigAdded]: (state, action) => {
    state.newPackageConfigAdded = action.payload
  },
  [setNewProductSizeAdded]: (state, action) => {
    state.newProductSizeAdded = action.payload
  },
  [setNewLicenseeAdded]: (state, action) => {
    state.newLicenseeAdded = action.payload
  },
  [setContainerTypes]: (state, action) => {
    state.containerTypes = action.payload
  },
  [resetGlobalDuck]: state => {
    state.licenseTypes = initialState.licenseTypes
    state.roles = initialState.roles
    state.isLoading = initialState.isLoading
    state.loadingText = initialState.loadingText
    state.loadingProgress = initialState.loadingProgress
    state.isAuxLoading = initialState.isAuxLoading
    state.loadingAuxText = initialState.loadingAuxText
    state.isEditMode = initialState.isEditMode
    state.lEsForDropDown = initialState.lEsForDropDown
    state.unitsOfMeasure = initialState.unitsOfMeasure
    state.pricesToForDropDown = initialState.pricesToForDropDown
    state.productsWithTrade = initialState.productsWithTrade
    state.packageConfigForDropDown = initialState.packageConfigForDropDown
    state.productSizesForDropDown = initialState.productSizesForDropDown
    state.rolesForDropDown = initialState.rolesForDropDown
    state.newProductAdded = initialState.newProductAdded
    state.newPackageConfigAdded = initialState.newPackageConfigAdded
    state.newProductSizeAdded = initialState.newProductSizeAdded
    state.newPricesToAdded = initialState.newPricesToAdded
    state.newLicenseeAdded = initialState.newLicenseeAdded
    state.productsWithTradeForBulkProcess =
      initialState.productsWithTradeForBulkProcess
    state.fetchingTotalCount = initialState.fetchingTotalCount
  },
})
