export const roles = {
  abcITAdmin: 'IT Admin',
  abcBizAdmin: 'ABC Biz Admin',
  abcUser: 'ABC User',
  lEAdmin: 'Licensee Admin',
  lEUser: 'Licensee User',
}

export const statusForDropdown = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
]

export const configurationTypes = [
  { label: 'Product Size', value: 'productSize' },
  { label: 'Package Configuration', value: 'packageConfiguration' },
]

export const configurationStatuses = [
  { label: 'Approved', value: 'approved' },
  { label: 'Pending', value: 'pending' },
  { label: 'Denied', value: 'denied' },
]

export const productStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

export const productSizeStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

export const packageConfigurationStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

export const pricePostingStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Old', label: 'Old' },
  { value: 'Inactive', label: 'Inactive' },
]

export const pricePostingUpdateTypes = {
  activePricePosting: 'activePricePosting',
  sameDayPricePosting: 'sameDayPricePosting',
  pendingPricePosting: 'pendingPricePosting',
}

export const licenseCategoryList = {
  wholesaler: 'Wholesaler',
  manufacturer: 'Manufacturer',
  both: 'Manufacturer / Wholesaler',
}

export const formTypeValues = {
  both: 'Price and container charge',
  priceOnly: 'Price only',
  containerOnly: 'Container charge only',
  pricePromotionOnly: 'Price promotion only',
}

export const includeCompetitiveOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Both', label: 'Both' },
]

export const licensedDropdownOptions = [
  { value: 'Both', label: 'Both' },
  { value: 'Licensed', label: 'Licensed' },
  { value: 'Unlicensed', label: 'Unlicensed' },
]

export const litigationOptions = [
  { value: 'Both', label: 'Both' },
  { value: 'In litigation', label: 'In Litigation' },
  { value: 'Not in litigation', label: 'Not in litigation' },
]

export const californiaCounties = [
  { value: 'All Counties', label: 'All Counties' },
  { value: 'Alameda', label: 'Alameda' },
  { value: 'Alpine', label: 'Alpine' },
  { value: 'Amador', label: 'Amador' },
  { value: 'Butte', label: 'Butte' },
  { value: 'Calaveras', label: 'Calaveras' },
  { value: 'Colusa', label: 'Colusa' },
  { value: 'Contra Costa', label: 'Contra Costa' },
  { value: 'Del Norte', label: 'Del Norte' },
  { value: 'El Dorado', label: 'El Dorado' },
  { value: 'Fresno', label: 'Fresno' },
  { value: 'Glenn', label: 'Glenn' },
  { value: 'Humboldt', label: 'Humboldt' },
  { value: 'Imperial', label: 'Imperial' },
  { value: 'Inyo', label: 'Inyo' },
  { value: 'Kern', label: 'Kern' },
  { value: 'Kings', label: 'Kings' },
  { value: 'Lake', label: 'Lake' },
  { value: 'Lassen', label: 'Lassen' },
  { value: 'Los Angeles', label: 'Los Angeles' },
  { value: 'Madera', label: 'Madera' },
  { value: 'Marin', label: 'Marin' },
  { value: 'Mariposa', label: 'Mariposa' },
  { value: 'Mendocino', label: 'Mendocino' },
  { value: 'Merced', label: 'Merced' },
  { value: 'Modoc', label: 'Modoc' },
  { value: 'Mono', label: 'Mono' },
  { value: 'Monterey', label: 'Monterey' },
  { value: 'Napa', label: 'Napa' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Placer', label: 'Placer' },
  { value: 'Plumas', label: 'Plumas' },
  { value: 'Riverside', label: 'Riverside' },
  { value: 'Sacramento', label: 'Sacramento' },
  { value: 'San Benito', label: 'San Benito' },
  { value: 'San Bernardino', label: 'San Bernardino' },
  { value: 'San Diego', label: 'San Diego' },
  { value: 'San Francisco', label: 'San Francisco' },
  { value: 'San Joaquin', label: 'San Joaquin' },
  { value: 'San Luis Obispo', label: 'San Luis Obispo' },
  { value: 'San Mateo', label: 'San Mateo' },
  { value: 'Santa Barbara', label: 'Santa Barbara' },
  { value: 'Santa Clara', label: 'Santa Clara' },
  { value: 'Santa Cruz', label: 'Santa Cruz' },
  { value: 'Shasta', label: 'Shasta' },
  { value: 'Sierra', label: 'Sierra' },
  { value: 'Siskiyou', label: 'Siskiyou' },
  { value: 'Solano', label: 'Solano' },
  { value: 'Sonoma', label: 'Sonoma' },
  { value: 'Stanislaus', label: 'Stanislaus' },
  { value: 'Sutter', label: 'Sutter' },
  { value: 'Tehama', label: 'Tehama' },
  { value: 'Trinity', label: 'Trinity' },
  { value: 'Tulare', label: 'Tulare' },
  { value: 'Tuolumne', label: 'Tuolumne' },
  { value: 'Ventura', label: 'Ventura' },
  { value: 'Yolo', label: 'Yolo' },
  { value: 'Yuba', label: 'Yuba' },
]

export const addEditBulkOn = {
  licensees: 'addEditBulkOnLicensees',
  licenseeUsers: 'addEditBulkOnLicensee Users',
  abcUsers: 'addEditBulkOnABC Users',
  products: 'addEditBulkOnProducts',
  pricePostings: 'addEditBulkOnPrice Postings',
  pricePostingsSchedule: 'addEditBulkOnPrice Posting Scheduling Tool',
  productSizes: 'addEditBulkOnProduct Sizes',
  pricesTo: 'addEditBulkOnPrices To',
  packageConfigurations: 'addEditBulkOnPackage Configurations',
  pricePromo: 'addEditBulkOnPricePromo PricePostingsPromo',
}

const applicationURL = process.env.REACT_APP_APPLICATION_URL
export const userSignUpLink = `${applicationURL}/signup`
export const leSignUpLink = `${applicationURL}/register`
export const abcNotificationsEmail = 'pps@abc.ca.gov'
export const pricePostingURL = applicationURL

export const sideBarNavLinks = {
  dashboard: '/dashboard',
  legalEntities: '/licensees',
  legalEntityUsers: '/licenseeUsers',
  abcUsers: '/abcUsers',
  products: '/products',
  pricePostings: '/pricePostings',
  pricePostingsSchedule: '/pricePostingsSchedule',
  globalSettings: '/globalSettings',
  configurationRequests: '/configurationRequests',
  requestNewConfiguration: '/requestNewConfiguration',
  newsUpdate: '/newsUpdate',
}

export const operators = [
  {
    label: '=',
    value: 'equal',
  },
  { label: '>', value: '_gt' },
  { label: '<', value: '_lt' },
]

export const categoryList = ['Manufacturer', 'Wholesaler', 'Manufacturer / Wholesaler']
export const receivingMethod = [
  { value: 'FOB', label: 'FOB' },
  { value: 'Delivery', label: 'Delivery' },
]
export const countries = [
  { label: 'United States' },
  { label: 'Afghanistan' },
  { label: 'Åland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'Andorra' },
  { label: 'Angola' },
  { label: 'Anguilla' },
  { label: 'Antarctica' },
  { label: 'Antigua and Barbuda' },
  { label: 'Argentina' },
  { label: 'Armenia' },
  { label: 'Aruba' },
  { label: 'Australia' },
  { label: 'Austria' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia' },
  { label: 'Bosnia and Herzegovina' },
  { label: 'Botswana' },
  { label: 'Bouvet Island' },
  { label: 'Brazil' },
  { label: 'Brunei Darussalam' },
  { label: 'Bulgaria' },
  { label: 'Burkina Faso' },
  { label: 'Burundi' },
  { label: 'Cambodia' },
  { label: 'Cameroon' },
  { label: 'Canada' },
  { label: 'Cape Verde' },
  { label: 'Cayman Islands' },
  { label: 'Central African Republic' },
  { label: 'Chad' },
  { label: 'Chile' },
  { label: 'China' },
  { label: 'Christmas Island' },
  { label: 'Cocos (Keeling) Islands' },
  { label: 'Colombia' },
  { label: 'Comoros' },
  { label: 'Congo' },
  {
    label: 'Congo, The Democratic Republic of the',
  },
  { label: 'Cook Islands' },
  { label: 'Costa Rica' },
  { label: "Cote D'Ivoire" },
  { label: 'Croatia' },
  { label: 'Cuba' },
  { label: 'Cyprus' },
  { label: 'Czech Republic' },
  { label: 'Denmark' },
  { label: 'Djibouti' },
  { label: 'Dominica' },
  { label: 'Dominican Republic' },
  { label: 'Ecuador' },
  { label: 'Egypt' },
  { label: 'El Salvador' },
  { label: 'Equatorial Guinea' },
  { label: 'Eritrea' },
  { label: 'Estonia' },
  { label: 'Ethiopia' },
  {
    label: 'Falkland Islands (Malvinas)',
  },
  { label: 'Faroe Islands' },
  { label: 'Fiji' },
  { label: 'Finland' },
  { label: 'France' },
  { label: 'French Guiana' },
  { label: 'French Polynesia' },
  {
    label: 'French Southern Territories',
  },
  { label: 'Gabon' },
  { label: 'Gambia' },
  { label: 'Georgia' },
  { label: 'Germany' },
  { label: 'Ghana' },
  { label: 'Gibraltar' },
  { label: 'Greece' },
  { label: 'Greenland' },
  { label: 'Grenada' },
  { label: 'Guadeloupe' },
  { label: 'Guatemala' },
  { label: 'Guernsey' },
  { label: 'Guinea' },
  { label: 'Guinea-Bissau' },
  { label: 'Guyana' },
  { label: 'Haiti' },
  {
    label: 'Heard Island and Mcdonald Islands',
  },
  {
    label: 'Holy See (Vatican City State)',
  },
  { label: 'Honduras' },
  { label: 'Hong Kong' },
  { label: 'Hungary' },
  { label: 'Iceland' },
  { label: 'India' },
  { label: 'Indonesia' },
  { label: 'Iran, Islamic Republic Of' },
  { label: 'Iraq' },
  { label: 'Ireland' },
  { label: 'Isle of Man' },
  { label: 'Israel' },
  { label: 'Italy' },
  { label: 'Jamaica' },
  { label: 'Japan' },
  { label: 'Jersey' },
  { label: 'Jordan' },
  { label: 'Kazakhstan' },
  { label: 'Kenya' },
  { label: 'Kiribati' },
  {
    label: "Korea, Democratic People'S Republic of",
  },
  { label: 'Korea, Republic of' },
  { label: 'Kuwait' },
  { label: 'Kyrgyzstan' },
  {
    label: "Lao People's Democratic Republic",
  },
  { label: 'Latvia' },
  { label: 'Lebanon' },
  { label: 'Lesotho' },
  { label: 'Liberia' },
  { label: 'Libyan Arab Jamahiriya' },
  { label: 'Liechtenstein' },
  { label: 'Lithuania' },
  { label: 'Luxembourg' },
  { label: 'Macao' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
  },
  { label: 'Madagascar' },
  { label: 'Malawi' },
  { label: 'Malaysia' },
  { label: 'Maldives' },
  { label: 'Mali' },
  { label: 'Malta' },
  { label: 'Martinique' },
  { label: 'Mauritania' },
  { label: 'Mauritius' },
  { label: 'Mayotte' },
  { label: 'Mexico' },
  {
    label: 'Micronesia, Federated States of',
  },
  { label: 'Moldova, Republic of' },
  { label: 'Monaco' },
  { label: 'Mongolia' },
  { label: 'Montserrat' },
  { label: 'Morocco' },
  { label: 'Mozambique' },
  { label: 'Myanmar' },
  { label: 'Namibia' },
  { label: 'Nauru' },
  { label: 'Nepal' },
  { label: 'Netherlands' },
  { label: 'Netherlands Antilles' },
  { label: 'New Caledonia' },
  { label: 'New Zealand' },
  { label: 'Nicaragua' },
  { label: 'Niger' },
  { label: 'Nigeria' },
  { label: 'Niue' },
  { label: 'Norfolk Island' },
  { label: 'Norway' },
  { label: 'Oman' },
  { label: 'Pakistan' },
  {
    label: 'Palestinian Territory, Occupied',
  },
  { label: 'Panama' },
  { label: 'Papua New Guinea' },
  { label: 'Paraguay' },
  { label: 'Peru' },
  { label: 'Philippines' },
  { label: 'Pitcairn' },
  { label: 'Poland' },
  { label: 'Portugal' },
  { label: 'Qatar' },
  { label: 'Reunion' },
  { label: 'Romania' },
  { label: 'Russian Federation' },
  { label: 'RWANDA' },
  { label: 'Saint Helena' },
  { label: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia' },
  { label: 'Saint Pierre and Miquelon' },
  {
    label: 'Saint Vincent and the Grenadines',
  },
  { label: 'Samoa' },
  { label: 'San Marino' },
  { label: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia' },
  { label: 'Senegal' },
  { label: 'Serbia and Montenegro' },
  { label: 'Seychelles' },
  { label: 'Sierra Leone' },
  { label: 'Singapore' },
  { label: 'Slovakia' },
  { label: 'Slovenia' },
  { label: 'Solomon Islands' },
  { label: 'Somalia' },
  { label: 'South Africa' },
  {
    label: 'South Georgia and the South Sandwich Islands',
  },
  { label: 'Spain' },
  { label: 'Sri Lanka' },
  { label: 'Sudan' },
  { label: 'Suriname' },
  { label: 'Svalbard and Jan Mayen' },
  { label: 'Swaziland' },
  { label: 'Sweden' },
  { label: 'Switzerland' },
  { label: 'Syrian Arab Republic' },
  { label: 'Taiwan, Province of China' },
  { label: 'Tajikistan' },
  {
    label: 'Tanzania, United Republic of',
  },
  { label: 'Thailand' },
  { label: 'Timor-Leste' },
  { label: 'Togo' },
  { label: 'Tokelau' },
  { label: 'Tonga' },
  { label: 'Trinidad and Tobago' },
  { label: 'Tunisia' },
  { label: 'Turkey' },
  { label: 'Turkmenistan' },
  { label: 'Turks and Caicos Islands' },
  { label: 'Tuvalu' },
  { label: 'Uganda' },
  { label: 'Ukraine' },
  { label: 'United Arab Emirates' },
  { label: 'United Kingdom' },
  {
    label: 'United States Minor Outlying Islands',
  },
  { label: 'Uruguay' },
  { label: 'Uzbekistan' },
  { label: 'Vanuatu' },
  { label: 'Venezuela' },
  { label: 'Viet Nam' },
  { label: 'Virgin Islands, British' },
  { label: 'Wallis and Futuna' },
  { label: 'Western Sahara' },
  { label: 'Yemen' },
  { label: 'Zambia' },
  { label: 'Zimbabwe' },
]

export const usStates = [
  {
    label: 'Alabama',
    abbreviation: 'AL',
  },
  {
    label: 'Alaska',
    abbreviation: 'AK',
  },
  {
    label: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    label: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    label: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    label: 'California',
    abbreviation: 'CA',
  },
  {
    label: 'Colorado',
    abbreviation: 'CO',
  },
  {
    label: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    label: 'Delaware',
    abbreviation: 'DE',
  },
  {
    label: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    label: 'Florida',
    abbreviation: 'FL',
  },
  {
    label: 'Georgia',
    abbreviation: 'GA',
  },
  {
    label: 'Guam',
    abbreviation: 'GU',
  },
  {
    label: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    label: 'Idaho',
    abbreviation: 'ID',
  },
  {
    label: 'Illinois',
    abbreviation: 'IL',
  },
  {
    label: 'Indiana',
    abbreviation: 'IN',
  },
  {
    label: 'Iowa',
    abbreviation: 'IA',
  },
  {
    label: 'Kansas',
    abbreviation: 'KS',
  },
  {
    label: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    label: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    label: 'Maine',
    abbreviation: 'ME',
  },
  {
    label: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    label: 'Maryland',
    abbreviation: 'MD',
  },
  {
    label: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    label: 'Michigan',
    abbreviation: 'MI',
  },
  {
    label: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    label: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    label: 'Missouri',
    abbreviation: 'MO',
  },
  {
    label: 'Montana',
    abbreviation: 'MT',
  },
  {
    label: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    label: 'Nevada',
    abbreviation: 'NV',
  },
  {
    label: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    label: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    label: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    label: 'New York',
    abbreviation: 'NY',
  },
  {
    label: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    label: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    label: 'Ohio',
    abbreviation: 'OH',
  },
  {
    label: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    label: 'Oregon',
    abbreviation: 'OR',
  },
  {
    label: 'Palau',
    abbreviation: 'PW',
  },
  {
    label: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    label: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    label: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    label: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    label: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    label: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    label: 'Texas',
    abbreviation: 'TX',
  },
  {
    label: 'Utah',
    abbreviation: 'UT',
  },
  {
    label: 'Vermont',
    abbreviation: 'VT',
  },
  {
    label: 'Virgin Islands, U.S.',
    abbreviation: 'VI',
  },
  {
    label: 'Virginia',
    abbreviation: 'VA',
  },
  {
    label: 'Washington',
    abbreviation: 'WA',
  },
  {
    label: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    label: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    label: 'Wyoming',
    abbreviation: 'WY',
  },
]
