import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, MenuItem, Box, Link } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import SubmitButton from 'components/common/SubmitButton'
import { recoverUsername } from 'ducks/authentication'
import styled from 'styled-components'

const SuccessMessageWrapper = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    color: white;
  }
`
const RecoverUsernameSuccessMessage = styled(Box)`
  && {
    background-color: ${props => props.theme.dark};
    padding: 10px;
    border-radius: 5px;
    width: 60%;
  }
`

const RecoverUsername = () => {
  const dispatch = useDispatch()
  const { isRecoveryUsernameCodeVerified, emailLinkedLicensees } = useSelector(
    store => store.auth
  )

  const validations = Yup.object().shape({
    code: Yup.string().required('Required'),
  })

  return (
    <>
      {!isRecoveryUsernameCodeVerified && (
        <Typography variant='body1'>
          A verification code was sent to your email. Please enter it here.
        </Typography>
      )}
      <Formik
        initialValues={{ code: '', licensee: '' }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(recoverUsername(values.code)).then(() =>
            setSubmitting(false)
          )
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='validationsPage'
            render={formikProps => {
              const recoveredUsername =
                emailLinkedLicensees.length === 1
                  ? emailLinkedLicensees[0].username
                  : formikProps.form.values.licensee

              const usernameDisplay = (
                <>
                  {(emailLinkedLicensees.length === 1 ||
                    formikProps.form.values.licensee) && (
                    <>
                      Please use the below username to login into the system:
                      <SuccessMessageWrapper>
                        <RecoverUsernameSuccessMessage>
                          {recoveredUsername}
                        </RecoverUsernameSuccessMessage>
                      </SuccessMessageWrapper>
                      <Link href='/login'>Back to login page</Link>
                    </>
                  )}
                </>
              )

              return (
                <>
                  {!isRecoveryUsernameCodeVerified ? (
                    <>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='code'
                        label='Verification Code'
                        name='code'
                        type='password'
                        component={TextField}
                        autoFocus
                      />
                      <SubmitButton
                        isSubmitting={formikProps.form.isSubmitting}
                      />
                    </>
                  ) : /* Add a licensee dropdown, so that user can select a licensee
                  and see the username connected to that licensee  */
                  emailLinkedLicensees.length > 1 ? (
                    <>
                      Your email is linked to multiple accounts, please select
                      for which licensee you'd like to see the username:
                      <br />
                      <br />
                      <Field
                        variant='outlined'
                        margin='normal'
                        select
                        required
                        fullWidth
                        label='Licensee'
                        name='licensee'
                        component={TextField}
                      >
                        Licensee *
                        {emailLinkedLicensees.map(val => (
                          <MenuItem key={val.username} value={val.username}>
                            {val.name}
                          </MenuItem>
                        ))}
                      </Field>
                      <br />
                      <br />
                      {usernameDisplay}
                    </>
                  ) : (
                    <>{usernameDisplay}</>
                  )}
                </>
              )
            }}
          />
        </Form>
      </Formik>
    </>
  )
}

export default RecoverUsername
