import React, { Fragment } from 'react'
import {
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import styled from 'styled-components'
import { ArrowBack } from '@material-ui/icons'
import CustomDivider from 'components/common/CustomDivider'
import { useDispatch, useSelector } from 'react-redux'
import { setPublicSidebarOpen } from 'ducks/public/layout'
import { Link } from 'react-router-dom'
import { publicSidebarRoutes } from 'components/routes/sidebarRoutes'

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${props => props.theme.dark};
  }
`
const ListWrapper = styled.div`
  width: 250px;
`
const MainTitleWrapper = styled.span`
  && {
    margin: 0.1% 0 0.1% 0;
    max-height: 53px;
    color: ${props => props.theme.light};
    display: flex;
    justify-content: space-around;
  }
`
const MainMenuTitle = styled(Typography)`
  && {
    align-self: center;
    font-weight: bold;
  }
`
const BackIcon = styled(ArrowBack)`
  color: ${props => props.theme.light};
`
const ListItemButton = styled(ListItem)`
  && {
    color: ${props => props.theme.light};
  }
`

const PublicSidebar = () => {
  const dispatch = useDispatch()

  const ListItemLink = ({ primary, to, icon }) => {
    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <Link to={to} {...itemProps} ref={ref} />
        )),
      [to]
    )

    return (
      <li className='sideBarRoutingLink'>
        <ListItemButton divider button component={renderLink}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
        </ListItemButton>
      </li>
    )
  }

  const { isPublicSidebarOpen } = useSelector(store => store.publicLayout)
  return (
    <StyledDrawer
      open={isPublicSidebarOpen}
      onClose={() => dispatch(setPublicSidebarOpen())}
      role='navigation'
    >
      <ListWrapper
        role='presentation'
        onClick={() => dispatch(setPublicSidebarOpen())}
        onKeyDown={() => dispatch(setPublicSidebarOpen())}
      >
        <MainTitleWrapper>
          <MainMenuTitle children='Main Menu' variant='h6' />
          <IconButton aria-label='back' aria-haspopup='false'>
            <BackIcon fontSize='large' />
          </IconButton>
        </MainTitleWrapper>

        <CustomDivider />
        <List>
          {publicSidebarRoutes.map(
            item =>
              item.label !== 'Login' && (
                <Fragment key={item.label}>
                  <ListItemLink
                    to={item.to}
                    primary={item.label}
                    icon={item.icon}
                  />
                </Fragment>
              )
          )}
        </List>
      </ListWrapper>
    </StyledDrawer>
  )
}
export default PublicSidebar
