import React from 'react'
import {
  CalendarToday,
  AttachMoney,
  People,
  Settings,
  Business,
  Person,
  LocalDrink,
  LockOpen,
  PostAdd,
} from '@material-ui/icons'
import styled from 'styled-components'
import licensees from 'assets/licensees.png'
import leUsers from 'assets/leUsers.png'
import products from 'assets/products.png'
import pricePosting from 'assets/pricePosting.png'
import schedulingTool from 'assets/ppScheduleTool.png'
import settings from 'assets/settings.png'
import abcLogo from 'assets/abcLogo.png'
import requestSettings from 'assets/requestSettings.png'
import newsUpdates from 'assets/newsUpdates.png'

const Wrapper = styled.span`
  color: ${props => props.theme.light};
`

export const sidebarRoutes = [
  {
    label: 'Licensees',
    icon: (
      <Wrapper>
        <Business />
      </Wrapper>
    ),
    to: '/licensees',
    image: licensees,
    description: 'Register or update licensee accounts',
  },
  {
    label: 'Licensee Users',
    icon: (
      <Wrapper>
        <Person />
      </Wrapper>
    ),
    to: '/licenseeUsers',
    image: leUsers,
    description: 'Create or modify user accounts',
  },
  {
    label: 'ABC Users',
    icon: (
      <Wrapper>
        <People />
      </Wrapper>
    ),
    to: '/abcUsers',
    image: abcLogo,
    description: 'Manage ABC users',
  },
  {
    label: 'Products',
    icon: (
      <Wrapper>
        <LocalDrink />
      </Wrapper>
    ),
    to: '/products',
    image: products,
    description: 'Add or update products',
  },
  {
    label: 'Price Postings',
    icon: (
      <Wrapper>
        <AttachMoney />
      </Wrapper>
    ),
    to: '/pricePostings',
    image: pricePosting,
    description: 'Enter, modify or filter product prices',
  },
  //   {
  //     label: 'Price Posting Scheduling Tool',
  //     icon: (
  //       <Wrapper>
  //         <CalendarToday />
  //       </Wrapper>
  //     ),
  //     to: '/pricePostingsSchedule',
  //     image: schedulingTool,
  //     description: 'Stage price posting submissions',
  //   },
  {
    label: 'Global Settings',
    icon: (
      <Wrapper>
        <Settings />
      </Wrapper>
    ),
    to: '/globalSettings',
    image: settings,
    description: 'Manage product sizes, configurations and settings',
  },
  {
    label: 'Configuration Requests',
    icon: (
      <Wrapper>
        <PostAdd />
      </Wrapper>
    ),
    to: '/configurationRequests',
    image: requestSettings,
    description: 'Manage requests for new sizes/configurations from licensees',
  },
  {
    label: 'Request Configuration',
    icon: (
      <Wrapper>
        <PostAdd />
      </Wrapper>
    ),
    to: '/requestNewConfiguration',
    image: requestSettings,
    description: 'Request a new product size or package configuration',
  },
  {
    label: 'News & Updates',
    icon: (
      <Wrapper>
        <PostAdd />
      </Wrapper>
    ),
    to: '/newsUpdate',
    image: newsUpdates,
    description: 'Add a news update',
  },
]

export const publicSidebarRoutes = [
  {
    label: 'Products',
    icon: (
      <Wrapper>
        <LocalDrink />
      </Wrapper>
    ),
    to: '/publicProducts',
    image: products,
    description: 'View products',
  },
  {
    label: 'Price Postings',
    icon: (
      <Wrapper>
        <AttachMoney />
      </Wrapper>
    ),
    to: '/publicPricePosts',
    image: pricePosting,
    description: 'View prices',
  },
  {
    label: 'Login',
    icon: (
      <Wrapper>
        <LockOpen />
      </Wrapper>
    ),
    to: '/login',
    image: abcLogo,
    description: 'Enter here to login in with your registered username and password',
  },
]
