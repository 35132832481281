import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import styled from 'styled-components'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const colorMapper = type => {
  const value = {
    success: '#43a047',
    error: '#d32f2f',
    info: '#1976d2',
    warning: '#ffa000',
    default: 'black',
  }
  return value[type]
}

const Message = styled.span`
  display: flex;
  align-items: center;
`

const CustomSnackBarContent = styled(SnackbarContent)`
  background-color: ${props => colorMapper(props.messagetype)} !important;
  .MuiSnackbarContent-message {
    max-width: 87%;

    @media (max-width: 448px) {
      max-width: 80%;
    }
  }
`

const SuccessIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const CustomErrorIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`

const CustomInfoIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const CustomWarningIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const DefaultIcon = styled(CheckCircleIcon)`
  opacity: 0.9;
  margin-right: 10px;
`
const CustomSnackBar = ({
  verticalPosition,
  horizontalPosition,
  classes,
  message,
  autoHideDuration,
  isOpen,
  onClose,
  messageType,
  ...other
}) => {
  // const Icon = styled(variantIcon[messageType])`
  //   opacity: 0.9;
  //   margin-right: 10px;
  // `
  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={isOpen}
    >
      <CustomSnackBarContent
        aria-describedby='client-snackbar'
        messagetype={messageType}
        message={
          <Message id='client-snackbar'>
            {messageType === variantIcon.success ? (
              <SuccessIcon />
            ) : messageType === variantIcon.error ? (
              <CustomErrorIcon />
            ) : messageType === variantIcon.info ? (
              <CustomInfoIcon />
            ) : messageType === variantIcon.warning ? (
              <CustomWarningIcon />
            ) : (
              <DefaultIcon />
            )}
            {message}
          </Message>
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  )
}

CustomSnackBar.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func,
  messageType: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
    .isRequired,
}

CustomSnackBar.defaultProps = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  message: '',
  autoHideDuration: 5000,
  messageType: 'success',
}

export default CustomSnackBar
