import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { lighten } from 'polished'
import { TextField } from 'formik-material-ui'
import { compareAsc, parseISO } from 'date-fns'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CommonTooltip from 'components/common/CommonTooltip'
import ReusableTable from 'components/common/ReusableTable'
import { setEditMode } from 'ducks/global'
import pricePostingsSchedule, {
  handlePricePostScheduleSubmit,
  setPricePostingScheduleDate,
} from 'ducks/pricePostingsSchedule'
import DatePicker from 'components/common/DatePicker'
import { getDateOnly } from 'common/helper'
import { Delete } from '@material-ui/icons'

const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    width: 100%;
  }
`

const DisclaimerText = styled(Typography)`
  && {
    font-size: 14px;
    background-color: ${props => lighten(0.1, props.theme.gold)};
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
`

const Title = styled(Typography)`
  && {
    color: ${props => props.theme.dark};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
`

const validations = props => {
  return Yup.object().shape({
    pricePostingSchedule: Yup.object().shape({
      scheduledDate: Yup.string().required('Required'),
    }),
  })
}

const EditPricePostingSchedule = props => {
  const dispatch = useDispatch()
  const { isLoading, loadingText, loadingProgress } = useSelector(store => store.global)
  const { pricePostingScheduleDetails } = useSelector(store => store.pricePostingsSchedule)

  //----------state initialization-------
  const [disclaimerParams, setDisclaimerParams] = useState({
    showBanner: false,
    updateType: '',
    bannerText: '',
  })

  const onAddPricePostingSchedule = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    // Handle valid post
    if (formikValues.isValid) {
      let pricePostingScheduleValue = formikValues.values.pricePostingSchedule
      dispatch(handlePricePostScheduleSubmit(pricePostingScheduleValue))
    }

    setTimeout(() => props.onCancel(), 0)
    dispatch(setEditMode(false))
  }

  const minScheduleDate = new Date()
  minScheduleDate.setDate(minScheduleDate.getDate() + 1)

  const maxScheduleDate = new Date()
  maxScheduleDate.setDate(maxScheduleDate.getDate() + 30)

  return (
    <Form>
      <Field
        name='pricePostingSchedule'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.pricePostingSchedule = JSON.parse(
            JSON.stringify(formikProps.form.values.pricePostingSchedule)
          )
          const pricePostingScheduleFormikValues = formikProps.form.values.pricePostingSchedule

          const handleCancel = () => {
            pricePostingScheduleFormikValues.id ? dispatch(setEditMode(false)) : props.onCancel()
          }
          const selectedDate = new Date(formikProps.form.values.pricePostingSchedule.scheduledDate)
          const today = new Date()

          return (
            <>
              {isLoading ? (
                <LoadingIndicator
                  text={loadingText.length > 0 ? loadingText : `Loading Scheduled Posting...`}
                  progress={loadingProgress}
                  transparent
                />
              ) : (
                <>
                  <CustomCard
                    titleContent={
                      <>
                        <Title
                          key={pricePostingScheduleDetails}
                          variant='h6'
                          children='Price Posting Schedule Details'
                        />
                        <Collapse
                          in={disclaimerParams.showBanner}
                          children={
                            <DisclaimerText
                              component='div'
                              variant='h6'
                              children={disclaimerParams.bannerText}
                            />
                          }
                        />
                      </>
                    }
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        disabled={true}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='File Name'
                        name='pricePostingSchedule.uploadName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <DatePickerField
                        label='Scheduled Date'
                        name='pricePostingSchedule.scheduledDate'
                        minDate={minScheduleDate}
                        maxDate={maxScheduleDate}
                        maxDateMessage='Scheduled date cannot exceed 30 days'
                        minDateMessage='Scheduled date must be later than today'
                        component={DatePicker}
                      />
                    </Grid>
                  </CustomCard>

                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddPricePostingSchedule(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={pricePostingScheduleFormikValues.id && 'update'}
                    isDisabled={
                      (pricePostingScheduleFormikValues.id && !formikProps.form.isValid) ||
                      selectedDate <= today
                        ? true
                        : false
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  isInitialValid: props => {
    return props.pricePostingSchedule.id ? false : true
  },
  enableReinitialize: true,
})(EditPricePostingSchedule)

export default WithFormikWrapper
