import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getConfigurationRequests,
  setConfigurationRequestPageNumber,
  clearFilters,
} from 'ducks/configurationRequests'
import { TextField } from 'formik-material-ui'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import GenericDatePicker from 'components/common/GenericDatePicker'
import { configurationTypes, configurationStatuses } from 'common/constants'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import AutoCompleteComp from 'components/common/AutoCompleteComp'

const DatePickerWrapper = styled(Grid)`
  display: flex;
`
const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    input {
      height: 17px;
    }
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`

const ConfigurationFilters = () => {
  const dispatch = useDispatch()

  useEffect(() => {}, [dispatch])

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setConfigurationRequestPageNumber(0))
    dispatch(getConfigurationRequests(formikProps.values.configurationFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue('configurationFilters', {
      type: '',
      changeRequest: '',
      createdAt: null,
      operatorForCreated: 'equal',
      operatorForUpdated: 'equal',
      updatedAt: null,
      status: [],
    })
  }

  return (
    <Form>
      <Field
        name='configurationFilters'
        render={formikProps => {
          formikProps.form.values.configurationFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.configurationFilters)
          )
          const formikValues = formikProps.form.values
          const configurationFiltersFormikValues =
            formikProps.form.values.configurationFilters

          const handleAutoCompleteChange = (field, event) => {
            formikProps.form.setFieldValue(
              `configurationFilters.${field}`,
              event
            )
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Type'
                    name='configurationFilters.type'
                    selectedValue={formikValues.configurationFilters.type}
                    formikProps={formikProps.form}
                    dropDownOptions={configurationTypes}
                    onValueChange={event =>
                      handleAutoCompleteChange('type', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Change Request'
                    name='configurationFilters.request'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Status'
                    name='configurationFilters.status'
                    selectedValue={formikValues.configurationFilters.status}
                    formikProps={formikProps.form}
                    dropDownOptions={configurationStatuses}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Submitted Date'
                    name='configurationFilters.createdAt'
                    component={GenericDatePicker}
                    value={{
                      begin: configurationFiltersFormikValues.createdAtFrom,
                      end: configurationFiltersFormikValues.createdAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'configurationFilters.createdAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'configurationFilters.createdAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Review Date'
                    name='configurationFilters.reviewedAt'
                    value={{
                      begin: configurationFiltersFormikValues.reviewedAtFrom,
                      end: configurationFiltersFormikValues.reviewedAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'configurationFilters.reviewedAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'configurationFilters.reviewedAtTo',
                        values.end
                      )
                    }}
                    component={GenericDatePicker}
                    fullWidth
                  />
                </DatePickerWrapper>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(ConfigurationFilters)

export default WithFormikWrapper
