import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSideBarOpen: false,
}

const sidebarSlice = createSlice({
  initialState,
  reducers: {
    handleSidebar(state) {
      state.isSideBarOpen = !state.isSideBarOpen
    },
  },
  name: 'sidebar',
})

const { actions, reducer } = sidebarSlice

const { handleSidebar } = actions

export const onSidebarExpandIconClicked = () => {
  return dispatch => {
    dispatch(handleSidebar())
  }
}

export default reducer
