import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { Grid, Link } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import BulkUploadComp from 'components/common/BulkUploadComp'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import {
  setIsBulkDialogOpen,
  productBulkUpload,
  setProductBulk,
} from 'ducks/products'
import { resetBulkUpload } from 'ducks/bulkUpload'
import { getLEsForDropDown } from 'ducks/global'
import { exportToExcel } from 'common/helper'
import CommonTooltip from 'components/common/CommonTooltip'

const CloudDownloadIcon = styled(CloudDownload)`
  margin-bottom: -5px;
  margin-right: 4px;
`

const validations = props => {
  return Yup.object().shape({
    productBulk: Yup.object().shape({
      manufacturer: !props.cannotSelectManufacturer
        ? Yup.string().required('Required')
        : '',
    }),
  })
}

const formatColumnName = name => {
  /*----map through the headers array and replace column names----*/
  return name.replace(/\s(.)/g, function ($$, $1) {
    return $1.toUpperCase()
  })
}

const ProductBulkUpload = () => {
  const dispatch = useDispatch()
  const { lEsForDropDown, isLoading } = useSelector(store => store.global)
  const { uploadNotReady, bulkJson } = useSelector(store => store.bulkUpload)
  const { user } = useSelector(store => store.auth)

  const onProductBulkUpload = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    let manufacturerId = ''
    if (formikValues.values.cannotSelectManufacturer) {
      manufacturerId = user.legalEntity.id
    } else {
      manufacturerId = formikValues.values.productBulk.manufacturer.value
      dispatch(setProductBulk(formikValues.values.productBulk))
    }
    dispatch(productBulkUpload(bulkJson, manufacturerId))
  }

  useEffect(() => {
    dispatch(getLEsForDropDown())
  }, [dispatch])

  const updatedLegalEntities = lEsForDropDown.filter(
    le =>
      le.status === 'Active' &&
      (le.licenseCategory !== 'Wholesaler' || !le.licensed)
  )

  const productBulkUploadTemplate = [
    {
      'Trade Name': '',
      'Product Name': '',
    },
  ]

  return isLoading ? (
    <LoadingIndicator text='Product bulk upload in progress...' transparent />
  ) : (
    <>
      <Form>
        <Field
          name='productBulk'
          render={formikProps => {
            formikProps.form.values.productBulk = JSON.parse(
              JSON.stringify(formikProps.form.values.productBulk)
            )
            const formikValues = formikProps.form.values
            const handleCancel = () => {
              dispatch(setIsBulkDialogOpen(false))
              dispatch(resetBulkUpload())
              dispatch(setProductBulk({ manufacturer: {} }))
            }

            const handleManufacturerChange = event => {
              formikProps.form.setFieldValue(`productBulk.manufacturer`, event)
            }

            const leObject =
              formikValues.productBulk.manufacturer &&
              lEsForDropDown.find(
                le => le.value === formikValues.productBulk.manufacturer.value
              )
            const submitDisabled = () => {
              if (formikValues.cannotSelectManufacturer) {
                return !uploadNotReady
              } else {
                return !formikProps.form.isValid || !uploadNotReady
              }
            }

            return (
              <CustomCard
                title={
                  <>
                    Bulk Upload
                    <CommonTooltip
                      tooltipTitle='Product Bulk Upload Information:'
                      informationList={[
                        'Column "Product Name" is required, "Trade Name" is optional.',
                        'Failed rows will be returned in an .xlsx file along with error messages.',
                        'If an errors file is received, please review, fix and and upload the given file.',
                        'Refer to template for file format.',
                      ]}
                    />
                  </>
                }
              >
                {!formikValues.cannotSelectManufacturer && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      placeHolderText='Manufacturer *'
                      selectedValue={leObject}
                      name='productBulk.manufacturer'
                      formikProps={formikProps.form}
                      dropDownOptions={updatedLegalEntities}
                      onValueChange={handleManufacturerChange}
                      component={AutoCompleteComp}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <BulkUploadComp
                    validations={{
                      requiredColumn: ['product'],
                      columns: ['tradeName', 'productName'],
                      uploadProcessName: 'product bulk upload',
                    }}
                    formatColumnName={formatColumnName}
                  />
                </Grid>
                <SaveAndCancelButton
                  onSave={() => onProductBulkUpload(formikProps.form)}
                  onCancel={() => handleCancel()}
                  submitLabel='Submit'
                  isDisabled={submitDisabled()}
                />
              </CustomCard>
            )
          }}
        />
      </Form>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            exportToExcel(
              productBulkUploadTemplate,
              'Product Bulk Upload Template'
            )
          }}
        >
          {<CloudDownloadIcon />}Download Product Bulk Upload Template
        </Link>
      </Grid>
    </>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: props => validations(props),
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(ProductBulkUpload)

export default WithFormikWrapper
