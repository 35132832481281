import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { Edit, Send, HowToReg, Block } from '@material-ui/icons'
import { format } from 'date-fns'
import { setEditMode, getRoles, getLEsForDropDown } from 'ducks/global'
import {
  resendRegistration,
  setDeactivationConfirmation,
  setActivationConfirmation,
} from 'ducks/legalEntityUser'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { Can } from 'common/abilityContext'
import { roles as rolesConst } from 'common/constants'

const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`
const SendIcon = styled(Send)`
  && {
    font-size: 30px;
  }
`

const ViewLeUser = () => {
  const dispatch = useDispatch()
  const { legalEntityUser } = useSelector(store => store.legalEntityUser)
  const { roles, lEsForDropDown, isAuxLoading, loadingAuxText } = useSelector(
    store => store.global
  )
  const { user } = useSelector(store => store.auth)

  const roleName =
    roles &&
    roles.find(role => {
      return role.id === legalEntityUser.role
    })

  const leObject =
    legalEntityUser.legalEntity &&
    lEsForDropDown.find(le => le.value === legalEntityUser.legalEntity.id)
  useEffect(() => {
    dispatch(getLEsForDropDown())
    dispatch(getRoles())
  }, [dispatch])
  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={
            <UserIcon>
              {legalEntityUser.firstName.charAt(0).toUpperCase()}
              {legalEntityUser.lastName.charAt(0).toUpperCase()}
            </UserIcon>
          }
          profileTitle={
            <Typography
              children={`${legalEntityUser.firstName} ${legalEntityUser.lastName}`}
              variant='h6'
            />
          }
          profileSubHeading={roleName ? roleName.name : ''}
          profileAction={
            <>
              {legalEntityUser.status === 'Pending' && (
                <IconButton
                  aria-label='Resend Registration'
                  aria-haspopup='false'
                  color='primary'
                  onClick={() => dispatch(resendRegistration(legalEntityUser))}
                >
                  <SendIcon color='primary' />
                  <Typography variant='subtitle1'>
                    &nbsp;{'Resend Registration'}
                  </Typography>
                </IconButton>
              )}
              <Can
                I='edit'
                this={roleName ? roleName.name : rolesConst.abcITAdmin}
              >
                {() => (
                  <>
                    <IconButton
                      aria-label='Edit Licensee User'
                      aria-haspopup='false'
                      color='primary'
                      onClick={() => dispatch(setEditMode(true))}
                    >
                      <EditIcon color='primary' />
                      <Typography variant='subtitle1'>
                        &nbsp;{'Edit'}
                      </Typography>
                    </IconButton>
                  </>
                )}
              </Can>
              <Can
                I='editStatus'
                this={roleName ? roleName.name : rolesConst.abcITAdmin}
              >
                {() => (
                  <>
                    {legalEntityUser.status === 'Active' ||
                    legalEntityUser.status === 'Pending' ? (
                      <IconButton
                        aria-label='Deactivate Licensee User'
                        aria-haspopup='false'
                        color='primary'
                        onClick={() =>
                          dispatch(setDeactivationConfirmation(true))
                        }
                      >
                        <Block color='error' />
                        <Typography variant='subtitle1'>
                          &nbsp;{'Deactivate'}
                        </Typography>
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {legalEntityUser.legalEntity.status === 'Active' &&
                      legalEntityUser.status === 'Inactive' && (
                        <IconButton
                          aria-label='Reactivate Licensee User'
                          aria-haspopup='false'
                          color='primary'
                          onClick={() =>
                            dispatch(setActivationConfirmation(true))
                          }
                        >
                          <HowToReg color='primary' />
                          <Typography variant='subtitle1'>
                            &nbsp;{'Reactivate'}
                          </Typography>
                        </IconButton>
                      )}
                  </>
                )}
              </Can>
            </>
          }
          divider
        >
          <DataGrid label='Licensee' value={leObject && leObject.label} />
          <DataGrid label='Email' value={legalEntityUser.email} />
          <DataGrid label='Phone' value={legalEntityUser.phoneNumber} />
          <DataGrid label='Status' value={legalEntityUser.status} />
          <DataGrid
            label='Added Date'
            value={
              legalEntityUser.createdAt
                ? format(legalEntityUser.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Edited Date'
            value={
              legalEntityUser.updatedAt
                ? format(legalEntityUser.updatedAt, 'MM/dd/yyyy')
                : ''
            }
          />
          {user.id === legalEntityUser.id && (
            <DataGrid label='Username' value={legalEntityUser.username} />
          )}
        </CustomCard>
      )}
    </>
  )
}

export default ViewLeUser
