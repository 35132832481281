import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { setEditMode, setLoading, showError, showSuccess } from './global'
import {
  createNewsUpdateMutation,
  deleteNewsUpdateMutation,
  updateNewsUpdateMutation,
} from 'graphql/mutations'
import {
  getNewsUpdateQuery,
  getNewsUpdatesQuery,
  getProgramUpdatesQuery,
} from 'graphql/queries'
import { cleanUp } from 'common/helper'

export const createNewsUpdate = newsUpdateDetails => {
  let newNewsUpdate = JSON.parse(JSON.stringify(newsUpdateDetails))

  return async (dispatch, getState) => {
    dispatch(setLoading('Adding news update...'))
    const currentUser = getState().auth.user
    const graphQLDataObject = {
      title: cleanUp(newNewsUpdate.title),
      description: cleanUp(newNewsUpdate.description),
      createdBy: parseInt(currentUser.id),
    }

    const newsUpdateRes = await API.graphql(
      graphqlOperation(createNewsUpdateMutation, {
        data: graphQLDataObject,
      })
    )

    if (newsUpdateRes.data.createNewsUpdate) {
      dispatch(getNewsUpdates())
      dispatch(toggleNewsUpdateDialog(false))
      dispatch(showSuccess('News Update added successfully.'))
    } else {
      dispatch(
        showError(
          'There was an issue adding the news update, please review the form and submit again. Thank you.'
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const updateNewsUpdate = newsUpdateDetails => {
  let newsUpdate = JSON.parse(JSON.stringify(newsUpdateDetails))

  return async (dispatch, getState) => {
    dispatch(setLoading('Updating news update...'))
    const currentUser = getState().auth.user
    const newsUpdateRes = await API.graphql(
      graphqlOperation(updateNewsUpdateMutation, {
        data: {
          title: cleanUp(newsUpdate.title),
          description: cleanUp(newsUpdate.description),
          updatedBy: parseInt(currentUser.id),
        },
        where: { id: parseInt(newsUpdate.id) },
      })
    )

    if (newsUpdateRes.data.updateNewsUpdate) {
      dispatch(getNewsUpdates())
      dispatch(setEditMode(false))
      dispatch(toggleNewsUpdateDialog(false))
      dispatch(showSuccess('News update successful.'))
    } else {
      dispatch(
        showError(
          'There was an issue updating the news update, please review the form and submit again. Thank you.'
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const fetchNewsUpdateByID = id => {
  return async dispatch => {
    const newsUpdateRes = await API.graphql(
      graphqlOperation(getNewsUpdateQuery, { id: parseInt(id) })
    )
    if (newsUpdateRes.data.newsUpdate) {
      dispatch(setNewsUpdateDetails(newsUpdateRes.data.newsUpdate))
    } else {
      dispatch(
        showError(
          `Unable to retrieve news update, please check your internet connection and try again.`
        )
      )
    }
  }
}

export const deleteNewsUpdateByID = () => {
  return async (dispatch, getState) => {
    const deleteItemID = getState().newsUpdate.newsUpdateDetails.id
    const newsUpdateRes = await API.graphql(
      graphqlOperation(deleteNewsUpdateMutation, {
        where: { id: parseInt(deleteItemID) },
      })
    )
    if (newsUpdateRes.data.deleteNewsUpdate) {
      dispatch(getNewsUpdates())
      dispatch(resetNewsUpdateDetails())
      dispatch(showSuccess(`News update deleted successfully.`))
    } else {
      dispatch(showError(`Unable to delete news update.`))
    }
  }
}

export const getNewsUpdates = () => {
  return async dispatch => {
    const programUpdatesRes = await API.graphql(
      graphqlOperation(getProgramUpdatesQuery)
    )
    const newsUpdatesRes = await API.graphql(
      graphqlOperation(getNewsUpdatesQuery)
    )
    if (newsUpdatesRes.data.newsUpdates) {
      programUpdatesRes.data.programUpdates.results.forEach(item => {
        newsUpdatesRes.data.newsUpdates.results.push({
          createdAt: new Date(item.pubDate).getTime(),
          description: item.content,
          link: item.link,
          title: item.title,
        })
      })
      const newsUpdateResults = {
        ...newsUpdatesRes.data.newsUpdates,
        firstLoad: false,
      }
      dispatch(setNewsUpdateResults(newsUpdateResults))
    } else {
      dispatch(
        showError(
          `Unable to retrieve news update, please check your internet connection and try again.`
        )
      )
    }
  }
}

export const resetNewsUpdateDetails = () => {
  return dispatch => {
    dispatch(
      setNewsUpdateDetails({
        ...initialState.newsUpdateDetails,
      })
    )
    dispatch(setLoading(false))
  }
}

export const setNewsUpdateDetails = createAction(
  'newsUpdate/setNewsUpdateDetails'
)
export const setNewsUpdateResults = createAction(
  'newsUpdate/setNewsUpdateResults'
)
export const toggleNewsUpdateDialog = createAction(
  'newsUpdate/toggleNewsUpdateDialog'
)
export const setSingleNewsUpdateSelected = createAction(
  'pricePosting/setSingleNewsUpdateSelected'
)
export const setConfirmNewsUpdateDelete = createAction(
  'pricePosting/setConfirmNewsUpdateDelete'
)

const initialState = {
  newsUpdateResults: {
    results: [],
    firstLoad: true,
  },
  newsUpdateDetails: {
    id: '',
    title: '',
    description: '',
  },
  isNewsUpdateDialogOpen: false,
  singleNewsUpdateSelected: '',
  confirmNewsUpdateDelete: false,
}

export default createReducer(initialState, {
  [setNewsUpdateResults]: (state, action) => {
    state.newsUpdateResults = action.payload
  },
  [setNewsUpdateDetails]: (state, action) => {
    state.newsUpdateDetails = action.payload
  },
  [toggleNewsUpdateDialog]: (state, action) => {
    state.isNewsUpdateDialogOpen = action.payload
  },
  [setSingleNewsUpdateSelected]: (state, action) => {
    state.singleNewsUpdateSelected = action.payload
  },
  [setConfirmNewsUpdateDelete]: (state, action) => {
    state.confirmNewsUpdateDelete = action.payload
  },
})
