import React, { Component } from 'react'
import styled from 'styled-components'
import { Table, TableBody, TableRow, TableCell, Link } from '@material-ui/core'
import { leSignUpLink } from 'common/constants'

const PrintingScreen = styled(Table)`
  && {
    display: none;
    @media print {
      display: block;
    }

    .MuiTableCell-root {
      border-bottom: none;
    }
  }
`
const DataWrapper = styled(TableCell)`
  && {
    padding-top: 25px;
    height: 100vh;
    display: flex;
  }
`

const TopAndBottomWrapper = styled(TableCell)`
  && {
    page-break-after: always;
  }
`

const LogoImg = styled.img`
  max-height: 35px;
  margin-bottom: 15px;
`

const LEProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ProfileElement = styled.span`
  margin-bottom: 13px;
`

const Disclaimer = styled.div`
  color: #666;
  font-size: 13px;
  margin-bottom: 13px;
`
const MailingBox = styled.div`
  margin-left: 6rem;
`

const PrintTemplate = ({ legalEntityDetails }) => {
  const { premisesAddress, mailingAddress } = legalEntityDetails
  return (
    <>
      <TableRow>
        <TopAndBottomWrapper>&nbsp;</TopAndBottomWrapper>
        <DataWrapper>
          <div>
            {/* <!-- START CENTERED WHITE CONTAINER --> */}
            <Table role='presentation'>
              {/* <!-- START MAIN CONTENT AREA --> */}
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Table
                      role='presentation'
                      border='0'
                      cellPadding='0'
                      cellSpacing='0'
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <LogoImg
                              src='https://www.abc.ca.gov/wp-content/uploads/2019/01/abc-logo-with-seal.jpg'
                              alt='ABC Logo'
                            />
                            <br />
                            <p>
                              Your<>&nbsp;</>
                              <b>
                                California Alcoholic Beverage Control Price
                                Posting account
                              </b>
                              <>&nbsp;</>has been created. Please go to
                              <>&nbsp;</>
                              <Link href={legalEntityDetails.userSignUpLink}>
                                {legalEntityDetails.userSignUpLink ||
                                  leSignUpLink}
                              </Link>
                              <>&nbsp;</>
                              to complete the registration process.
                            </p>
                            <hr />
                            <MailingBox>
                              <br />
                              <br />
                              <br />
                              <div>
                                <b>{legalEntityDetails.name}</b>
                              </div>
                              <div>{mailingAddress.address}</div>
                              <div>
                                {mailingAddress.address2
                                  ? mailingAddress.address2
                                  : ''}
                              </div>
                              <div>{`${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zipcode}`}</div>
                              <div>{mailingAddress.country}</div>
                            </MailingBox>
                            <hr />
                            <h3>Licensee</h3>
                            <LEProfileWrapper>
                              <ProfileElement>
                                <b>Unique Identifier:</b>
                                <br /> {legalEntityDetails.uniqueId}
                              </ProfileElement>

                              {legalEntityDetails.email &&
                              legalEntityDetails.email.length > 0 ? (
                                <ProfileElement>
                                  <b>Email:</b>
                                  <br />
                                  {legalEntityDetails.email}
                                </ProfileElement>
                              ) : (
                                <ProfileElement />
                              )}
                            </LEProfileWrapper>
                            <LEProfileWrapper>
                              <ProfileElement>
                                <b>License Type:</b>
                                <br />
                                {legalEntityDetails.licenseTypes
                                  .map(lt => lt.label)
                                  .join(', ')}
                              </ProfileElement>
                            </LEProfileWrapper>
                            <div>
                              <hr />
                              <h3>Premises address</h3>
                              <LEProfileWrapper>
                                <ProfileElement>
                                  <b>Address Line 1:</b>
                                  <br />
                                  {premisesAddress.address}
                                </ProfileElement>
                                {premisesAddress.address2 ? (
                                  <ProfileElement>
                                    <b>Address Line 2:</b>
                                    <br />
                                    {premisesAddress.address2}
                                  </ProfileElement>
                                ) : (
                                  ''
                                )}
                              </LEProfileWrapper>
                              <LEProfileWrapper>
                                <ProfileElement>
                                  <b>City:</b>
                                  <br />
                                  {premisesAddress.city}
                                </ProfileElement>
                                <ProfileElement>
                                  <b>State:</b>
                                  <br />
                                  {premisesAddress.state}
                                </ProfileElement>
                                <ProfileElement>
                                  <b>Zip Code:</b>
                                  <br />
                                  {premisesAddress.zipcode}
                                </ProfileElement>
                                <ProfileElement>
                                  <b>Country:</b>
                                  <br />
                                  {premisesAddress.country}
                                </ProfileElement>
                              </LEProfileWrapper>
                              <Disclaimer>
                                * The premises address is provided for
                                verification purposes. If you have a license at
                                more than one premises, only one of those
                                addresses is displayed. If you do not possess an
                                active license at the displayed premises
                                address, do not complete the registration
                                process and contact the Price Posting Unit
                                at&nbsp;
                                <Link href='mailto:PPS@abc.ca.gov'>
                                  PPS@abc.ca.gov
                                </Link>
                                .
                              </Disclaimer>
                            </div>
                            <hr />
                            <br />
                            California Department of Alcoholic Beverage Control,
                            Sacramento CA
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
              {/* <!-- END MAIN CONTENT AREA --> */}
            </Table>

            {/* <!-- END CENTERED WHITE CONTAINER --> */}
          </div>
        </DataWrapper>
        <TopAndBottomWrapper>&nbsp;</TopAndBottomWrapper>
      </TableRow>
    </>
  )
}

class LegalEntityPrintTemplate extends Component {
  render() {
    const { printingProps } = this.props
    // printingProps is an array of legalEntityDetails
    // Each value inside printingProps is used to build the printing component for each page
    let printingPages = []
    for (const legalEntityDetails of printingProps) {
      const tempTemplate = (
        <PrintTemplate legalEntityDetails={legalEntityDetails} />
      )
      printingPages.push(tempTemplate)
    }
    const finalTable = (
      <>
        <PrintingScreen
          size='small'
          role='presentation'
          border='0'
          cellPadding='0'
          cellSpacing='0'
        >
          <>
            {/* map and create single component for each page */}
            {printingPages.map((page, index) => (
              <TableBody key={index}>{page}</TableBody>
            ))}
          </>
        </PrintingScreen>
      </>
    )

    return finalTable
  }
}
export default LegalEntityPrintTemplate
