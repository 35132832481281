import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Fade,
  Avatar,
} from '@material-ui/core'
import styled from 'styled-components'
import { darken } from 'polished'
import { sidebarRoutes } from 'components/routes/sidebarRoutes'
import { Can } from 'common/abilityContext'
import { useDispatch, useSelector } from 'react-redux'
import { toggleConfigReqDialog } from 'ducks/configurationRequests'
import { toggleNewsUpdateDialog } from 'ducks/newsUpdate'
import NewsUpdates from 'components/common/NewsUpdates'
import { getNewsUpdates } from 'ducks/newsUpdate'
import LoadingIndicator from 'components/common/LoadingIndicator'

const Wrapper = styled(Grid)`
  padding: 2% 5% 5%;
  margin: auto;
  max-width: 1200px;
`

const CustomGrid = styled(Grid)`
  margin: 0 auto;
  && h4 {
    margin-top: 16px;
  }
`

const CustomListItem = styled(ListItem)`
  &:hover {
    border-radius: 5px;
  }
`

const CustomListItemText = styled(ListItemText)`
  && span + p {
    color: rgba(0, 0, 0, 0.87);
  }
`

const CustomAvatar = styled(Avatar)`
  background-color: white;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  margin-right: 10px;
`

const Title = styled(Typography)`
  font-size: 1.1rem;
  color: ${props => props.theme.blue};
  &&[bold] {
    color: ${props => darken(0.1, props.theme.blue)};
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: 20px;
  }
`

const Dashboard = () => {
  const dispatch = useDispatch()
  const { newsUpdateResults } = useSelector(store => store.newsUpdate)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getNewsUpdates())
  }, [dispatch])

  const ListItemLink = ({ title, description, link, image }) => {
    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <Link to={link} {...itemProps} ref={ref} />
        )),
      [link]
    )

    return (
      <Can I='read' this={link}>
        {() =>
          link === '/requestNewConfiguration' ? (
            <CustomListItem
              divider
              button
              component='div'
              onClick={() => dispatch(toggleConfigReqDialog(true))}
              alignItems='flex-start'
            >
              <ListItemAvatar>
                <CustomAvatar alt='Dashboard Icon' src={image} />
              </ListItemAvatar>

              <CustomListItemText
                primary={<Title>{title}</Title>}
                secondary={description}
              />
            </CustomListItem>
          ) : link === '/newsUpdate' ? (
            <CustomListItem
              divider
              button
              component='div'
              onClick={() => dispatch(toggleNewsUpdateDialog(true))}
              alignItems='flex-start'
            >
              <ListItemAvatar>
                <CustomAvatar alt='Dashboard Icon' src={image} />
              </ListItemAvatar>

              <CustomListItemText
                primary={<Title>{title}</Title>}
                secondary={description}
              />
            </CustomListItem>
          ) : (
            <CustomListItem
              divider
              button
              component={renderLink}
              alignItems='flex-start'
            >
              <ListItemAvatar>
                <CustomAvatar alt='Dashboard Icon' src={image} />
              </ListItemAvatar>

              <CustomListItemText
                primary={<Title>{title}</Title>}
                secondary={description}
              />
            </CustomListItem>
          )
        }
      </Can>
    )
  }

  return newsUpdateResults.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Fade in timeout={{ enter: 1600, exit: 800 }}>
        <Wrapper container spacing={1}>
          <CustomGrid item xs={12} sm={6}>
            <Title variant='h4' component='h2' bold='bold'>
              Beer Price Posting
            </Title>
            <Typography
              variant='body1'
              component={'span'}
              children={
                <>
                  <p>
                    If you are an importer or a wholesaler and the products you
                    sell are not available in the online Beer Price Posting
                    system, please contact the manufacturer of the beer to have
                    them input their product names into the system. A foreign
                    beer manufacturer who does not ship beer to California is
                    not required to hold a license issued by the Department.
                    These manufacturers can complete form{' '}
                    <a
                      href='https://www.abc.ca.gov/form/abc-415/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      ABC-415
                    </a>{' '}
                    and email the completed form to{' '}
                    <a href='mailto:pps@abc.ca.gov'>pps@abc.ca.gov</a>
                    {'.  '}For instructions on how to complete the form, please
                    see form{' '}
                    <a
                      href='https://www.abc.ca.gov/abc-415-instructions/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      ABC-415 Instructions
                    </a>
                    .
                  </p>
                  <strong>Additional links:</strong>
                  <ul>
                    <li>
                      <a
                        href='https://www.abc.ca.gov/licensing/beer-price-posting/frequently-asked-questions/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.abc.ca.gov/licensing/beer-price-posting/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Beer price posting information
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.youtube.com/playlist?app=desktop&list=PLkQmb-gnart2_OVOnetLlpWfxr7C0e3Pp'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        View instructional videos
                      </a>
                    </li>
                  </ul>
                </>
              }
            />
            <br />
            <NewsUpdates newsList={newsUpdateResults} />
          </CustomGrid>
          <CustomGrid item sm={1}></CustomGrid>
          <CustomGrid item xs={12} sm={5}>
            <List>
              {sidebarRoutes.map(item => (
                <React.Fragment key={item.label}>
                  <ListItemLink
                    title={item.label}
                    description={item.description}
                    link={item.to}
                    image={item.image}
                  />
                </React.Fragment>
              ))}
            </List>
          </CustomGrid>
        </Wrapper>
      </Fade>
    </>
  )
}
export default Dashboard
