import { createMuiTheme } from '@material-ui/core/styles'

const abcStyles = {
  blue: '#026B99',
  gold: '#FBAE22',
  gray: '#f2f2f2',
  green: '#29A745',
  light: '#F0F5F7',
  red: '#d32f2f',
  dark: '#323A45',
  border: '#e5e5e5',
  cancel: '#4b5768',
  active: '#006600',
  complete: '#006600',
  pending: '#963e03',
  inactive: '#af0000',
  error: '#af0000',
}

export const styledComponentsTheme = abcStyles

export const materialUITheme = createMuiTheme({
  typography: {
    fontSize: 13,
    fontFamily: ['Lato', 'sans-serif'],
  },
  palette: {
    primary: {
      main: '#026B99',
    },
    secondary: {
      main: '#FBAE22',
    },
    error: {
      main: '#d32f2f',
    },
    background: {
      default: 'rgba(255, 255, 255, 0.0)',
    },
  },
  overrides: {
    MuiMenuItem: {
      // For ListItem, change this to MuiListItem
      root: {
        '&$selected': {
          // this is to refer to the prop provided by M-UI
          backgroundColor: 'rgba(255, 255, 255, 0.0)', // updated backgroundColor
          // color: "white",
        },
        '&:hover': {
          // this is to refer to the prop provided by M-UI
          backgroundColor: abcStyles.blue, // updated backgroundColor
          color: 'white',
        },
      },
    },
    MuiTypography: {
      h3: {
        fontFamily: ['Open Sans', 'sans-serif'],
      },
      h4: {
        fontFamily: ['Open Sans', 'sans-serif'],
      },
      h5: {
        fontFamily: ['Open Sans', 'sans-serif'],
        fontWeight: 600,
      },
      h6: {
        fontFamily: ['Open Sans', 'sans-serif'],
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1600px !important',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '12px 24px',
      },
    },
    MuiCardHeader: {
      title: {
        fontWeight: 'bold',
      },
    },
    MuiCardContent: {
      root: {
        padding: '16px !important',
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px',
        borderBottom: '1px solid rgba(224, 224, 224, 0.6)',
      },
      head: {
        fontFamily: ['Open Sans', 'sans-serif'],
        fontSize: '13px',
        fontWeight: 600,
      },
    },
    MuiGrid: {
      item: {
        padding: '8px 12px !important',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '8px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 16px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, .6)',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px 14px',
        '&$disabled': {
          backgroundColor: 'hsl(0,0%,95%)',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#026B99 !important',
      },
    },
    MuiLinearProgress: {
      dashed: {
        backgroundImage: `radial-gradient(${abcStyles.gold} 0%, ${abcStyles.gold} 16%, transparent 42%) !important`,
      },
    },
    MuiTableRow: {
      hover: {
        height: '55px',
      },
    },
    MuiChip: {
      outlinedPrimary: {
        border: '1px solid rgba(30, 107, 153, 0.2)',
      },
    },
  },
})
