import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Popover,
  Button,
} from '@material-ui/core'
import {
  MoreVert,
  PictureInPicture,
  Edit,
  Block,
  HowToReg,
  CloudDownload,
  Send,
} from '@material-ui/icons'
import { format } from 'date-fns'
import styled from 'styled-components'
import LoadingIndicator from 'components/common/LoadingIndicator'
import ReusableTable from 'components/common/ReusableTable'
import ListComponent from 'components/common/ListComponent'
import {
  getLeUsersList,
  resetLeUserDetails,
  setLicenseeUsersBulkSelection,
  setLeUsersPageNumber,
  setLeUsersRowsPerPage,
  setLeUsersRequestedSort,
  setIsLEUsersFilterOpen,
  fetchLeUserByID,
  setLeUserShown,
  activateLeUser,
  deactivateLeUser,
  resendRegistration,
  setDeactivationConfirmation,
  setActivationConfirmation,
  exportLicenseeUsersToExcel,
} from 'ducks/legalEntityUser'
import { setEditMode } from 'ducks/global'
import EditLeUser from './EditLeUser'
import ViewLeUser from './ViewLeUser'
import LeUsersFilters from './LeUsersFilters'
import { AbilityContext, Can } from 'common/abilityContext'
import { roles } from 'common/constants'
import ConfirmationModal from 'components/common/ConfirmationModal'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const LegalEntityUsers = () => {
  const dispatch = useDispatch()
  // Used for popover actions state
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedUser, setSelectedUser] = useState(-1)

  const abilityFunc = useContext(AbilityContext)
  const {
    legalEntityUser,
    leUsersList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    leUsersFilters,
    isLeUsersFilterOpen,
    isLeUserShown,
    deactivationConfirmation,
    activationConfirmation,
  } = useSelector(store => store.legalEntityUser)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getLeUsersList())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  const onQuickViewClosed = () => {
    dispatch(resetLeUserDetails())
    dispatch(setLeUserShown(false))
    dispatch(setEditMode(false))
  }

  const UsersHeaders = [
    { id: 'firstName', label: 'First Name', isSortable: true },
    { id: 'lastName', label: 'Last Name', isSortable: true },
    { id: 'email', label: 'Email', isSortable: true },
    { id: 'role', label: 'Role', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'legalEntities', label: 'Licensee', isSortable: true },
    { id: 'createdAt', label: 'Added Date', isSortable: true },
    { id: 'updatedAt', label: 'Edited Date', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, user) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setSelectedUser(user)
  }

  const handleActionClose = () => {
    console.log('action close')
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchLeUserByID(selectedUser.id))
  }

  const handleEditSelected = () => {
    setAnchorEl(null)
    dispatch(fetchLeUserByID(selectedUser.id, true))
  }
  const onItemSelected = user => {
    dispatch(fetchLeUserByID(user.id))
    setSelectedUser(user)
  }
  const onAdd = () => {
    dispatch(resetLeUserDetails())
    dispatch(setEditMode(true))
    dispatch(setLeUserShown(true))
  }
  const handleFilter = value => {
    dispatch(setIsLEUsersFilterOpen(value))
  }

  const handleActivation = () => {
    setAnchorEl(null)
    dispatch(setActivationConfirmation(false))
    dispatch(activateLeUser(selectedUser.id))
  }

  const handleDeactivation = () => {
    setAnchorEl(null)
    dispatch(setDeactivationConfirmation(false))
    dispatch(deactivateLeUser(selectedUser.id))
  }

  const handleResendRegistration = selectedUser => {
    dispatch(resendRegistration(selectedUser))
    setAnchorEl(null)
  }

  const openAction = Boolean(anchorEl)

  const updatedLeUsersList = leUsersList.results.map((user, index) => ({
    ...user,
    role: user.role.name,
    legalEntity: user.legalEntity.name,
    createdAt: format(user.createdAt, 'MM/dd/yyyy'),
    updatedAt: format(user.updatedAt, 'MM/dd/yyyy'),
    actions: (
      <>
        <IconButton
          aria-label='actions'
          aria-haspopup='true'
          onClick={event => handleActionOpen(event, user)}
          color='primary'
        >
          <MoreVert color='primary' />
        </IconButton>
      </>
    ),
  }))

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = leUsersList.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          leUsersList.results.map(finalObject => ({
            id: finalObject.id,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = leUsersList.results
          .filter(leItem => items.id === leItem.id)
          .map(finalObject => ({
            id: finalObject.id,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setLicenseeUsersBulkSelection(newSelected))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getLeUsersList(false, true))
  }

  const handleClearSelection = () => {
    dispatch(setLicenseeUsersBulkSelection([]))
  }

  const actionItemsOnSelection = (
    <ActionWrapper>
      <Button
        aria-label='export'
        color='primary'
        onClick={e => dispatch(exportLicenseeUsersToExcel())}
        size='medium'
        variant='contained'
        startIcon={<CloudDownload />}
      >
        Export To Excel
      </Button>
    </ActionWrapper>
  )

  const handlePageNumber = newPage => {
    dispatch(setLeUsersPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setLeUsersRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setLeUsersRequestedSort(value))
  }

  return leUsersList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Licensee Users</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        <Can
          I='edit'
          this={selectedUser.role ? selectedUser.role.name : roles.lEUser}
        >
          {() => (
            <>
              <Divider />
              <ActionItems onClick={handleEditSelected}>
                <Edit color='primary' fontSize='small' />
                <ActionLabels children='Edit' variant='body1' />
              </ActionItems>
            </>
          )}
        </Can>
        {selectedUser.status === 'Pending' && (
          <>
            <Divider />
            <ActionItems onClick={() => handleResendRegistration(selectedUser)}>
              <Send color='primary' fontSize='small' />
              <ActionLabels children='Resend Registration' variant='body1' />
            </ActionItems>
          </>
        )}
        <Can
          I='editStatus'
          this={selectedUser.role ? selectedUser.role.name : roles.lEUser}
        >
          {() => (
            <>
              {selectedUser.status === 'Active' ||
              selectedUser.status === 'Pending' ? (
                <>
                  <Divider />
                  <ActionItems
                    onClick={() => dispatch(setDeactivationConfirmation(true))}
                  >
                    <Block color='error' fontSize='small' />
                    <ActionLabels children='Deactivate' variant='body1' />
                  </ActionItems>
                </>
              ) : (
                ''
              )}
              {selectedUser.legalEntity.status === 'Active' &&
                selectedUser.status === 'Inactive' && (
                  <>
                    <Divider />
                    <ActionItems
                      onClick={() => dispatch(setActivationConfirmation(true))}
                    >
                      <HowToReg color='primary' fontSize='small' />
                      <ActionLabels children='Reactivate' variant='body1' />
                    </ActionItems>
                  </>
                )}
            </>
          )}
        </Can>
      </Popover>
      <ListComponent
        mainTitle='Licensee Users'
        listContent={
          <ReusableTable
            headers={UsersHeaders}
            tableData={updatedLeUsersList}
            pagination={true}
            selectionOption={
              !abilityFunc.cannot('select', roles.lEAdmin) && true
            }
            totalCount={leUsersList.totalCount}
            onSingleItemSelected={onItemSelected}
            handleBulkSelection={handleBulkSelection}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleSelectAllRows={handleSelectionOfAllRows}
            handleClearSelection={handleClearSelection}
            actionItemsOnSelection={actionItemsOnSelection}
          />
        }
        editComp={
          <EditLeUser
            leUser={legalEntityUser}
            canSelectLeAdmin={abilityFunc.cannot('select', roles.lEAdmin)}
          />
        }
        viewComp={<ViewLeUser />}
        modalEditTitle={`${
          legalEntityUser.id ? 'Edit Licensee user' : 'Add Licensee User'
        }`}
        modalViewTitle='Licensee User Profile'
        onModalClosed={onQuickViewClosed}
        expandedOptions={<LeUsersFilters leUsersFilters={leUsersFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isLeUsersFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isLeUserShown}
        onAddButtonClicked={onAdd}
      />
      <ConfirmationModal
        isConfirmationModalOpen={deactivationConfirmation}
        confirmationList={['Are you sure you want to deactivate this user?']}
        onConfirmation={handleDeactivation}
        onConfirmationModalClosed={() =>
          dispatch(setDeactivationConfirmation(false))
        }
        type='deactivate'
      />
      <ConfirmationModal
        isConfirmationModalOpen={activationConfirmation}
        confirmationList={['Are you sure you want to reactivate this user?']}
        onConfirmation={handleActivation}
        onConfirmationModalClosed={() =>
          dispatch(setActivationConfirmation(false))
        }
      />
    </>
  )
}

export default LegalEntityUsers
