import React, { Component } from 'react'
import Select, { components, createFilter } from 'react-select'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { FixedSizeList as List } from 'react-window'

const selectStyles = isNotValid => ({
  control: (styles, state) => {
    let controlObj = {
      ...styles,
      minHeight: '45px',
      marginTop: '8px',
      fontSize: '14px',
      borderColor: isNotValid ? '#ff1744' : '#ccc',
    }
    if (state.hasValue || state.selectProps.menuIsOpen) {
      controlObj = {
        ...controlObj,
        'div:first-of-type': {
          position: 'initial',
        },
      }
    }
    return controlObj
  },
  placeholder: (styles, state) => {
    let placeholderObj = {
      ...styles,
      color: isNotValid ? '#ff1744' : '#808080',
      transition:
        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    }
    if (state.hasValue || state.selectProps.menuIsOpen) {
      placeholderObj = {
        ...placeholderObj,
        position: 'absolute !important',
        top: '0%',
        background: '#ffffff',
        paddingLeft: '4px',
        paddingRight: '4px',
        transform: 'translate(-7px,-10px) scale(0.75)',
      }
    }
    return placeholderObj
  },
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#F0F5F7',
    },
    ':focus': {
      ...styles[':focus'],
      backgroundColor: '#F0F5F7',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? '#F0F5F7' : 'white'),
    },
  }),
})

const HelperText = styled(Typography)`
  && {
    min-height: 1em;
    color: #ff1744;
    margin: 8px 14px 0;
    font-size: 0.6964285714285714rem;
  }
`

const StyledSelect = styled(Select)`
  && {
    margin-bottom: 3px;
    width: 100%;
  }
`

const getValue = (objParam, name) => {
  let finalValue = objParam
  if (
    !(Object.entries(objParam).length === 0 && objParam.constructor === Object)
  ) {
    for (let step = 0; step < name.length; step++) {
      if (finalValue[name[step]] !== undefined) {
        finalValue = finalValue[name[step]]
      } else {
        finalValue = false
        break
      }
    }
    return finalValue
  } else {
    return false
  }
}

const NoResultsFound = styled(Typography)`
  && {
    padding: 10px;
    text-align: center;
  }
`

const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child => {
        return child && child.type !== Placeholder ? child : null
      })}
    </ValueContainer>
  )
}

class MenuList extends Component {
  constructor(props) {
    super(props)
    this.listRef = undefined
  }

  getFocusIndex = children => {
    const isArray = children instanceof Array
    children = isArray ? children : [children]
    return Math.max(
      children.findIndex(({ props: { isFocused } = {} } = {}) => {
        return isFocused === true
      }),
      0
    )
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * enables scrolling on key down arrow
     * note: prevents scrolling on index 0 and 1 to avoid
     * returning to top of menu when it remains open after selecting
     */
    const currentIndex = this.getFocusIndex(this.props.children)
    if (currentIndex > 1) {
      this.listRef.scrollToItem(currentIndex)
    }
  }

  render() {
    const { options, maxHeight, children, getValue } = this.props
    const [value] = getValue()
    const height = 35
    const initialOffset = options.indexOf(value) * 37
    const optionsHeight = Math.min(maxHeight, (children.length || 1) * height)
    const itemCountValue = children.length ? children.length : 1
    return (
      <List
        ref={ref => {
          this.listRef = ref
        }}
        itemSize={height}
        overscanCount={5}
        height={optionsHeight}
        initialScrollOffset={initialOffset}
        itemCount={itemCountValue}
      >
        {({ index, style }) => (
          <div className='option-wrapper' style={style}>
            {children.length ? (
              children[index]
            ) : (
              <NoResultsFound variant='subtitle1'>
                {this.props.selectProps.components.noOptionsMessage
                  ? this.props.selectProps.components.noOptionsMessage
                  : `No Options Found`}
              </NoResultsFound>
            )}
          </div>
        )}
      </List>
    )
  }
}

function OptimizedOption(props) {
  delete props.innerProps.onMouseMove
  delete props.innerProps.onMouseOver
  return <components.Option {...props}>{props.children}</components.Option>
}

const AutoCompleteComp = ({
  field,
  form: { touched, errors, setFieldTouched },
  placeHolderText,
  dropDownOptions,
  isMulti,
  selectedValue,
  onValueChange,
  noOptionsMessage,
  formikProps,
  isDisabled,
  autoFocus = false,
}) => {
  const arrayOfName = field.name.split('.')
  const isTouched = getValue(touched, arrayOfName)
  const isError = getValue(errors, arrayOfName) === 'Required' ? true : false
  const isNotValid = isTouched && isError

  const handleOnBlur = () => {
    if (
      !formikProps.values[arrayOfName[0]][arrayOfName[1]] ||
      !formikProps.values[arrayOfName[0]][arrayOfName[1]].value
    ) {
      setFieldTouched(field.name, true)
    } else {
      setFieldTouched(field.name, false)
    }
  }
  return (
    <>
      <StyledSelect
        autoFocus={autoFocus}
        isDisabled={isDisabled}
        placeholder={placeHolderText}
        options={dropDownOptions}
        isMulti={isMulti}
        value={selectedValue}
        menuPortalTarget={document.querySelector('body')}
        onChange={event => onValueChange(event)}
        styles={selectStyles(isNotValid)}
        filterOption={createFilter({ ignoreAccents: false })}
        onBlur={handleOnBlur}
        components={{
          ValueContainer: CustomValueContainer,
          noOptionsMessage: noOptionsMessage,
          MenuList: MenuList,
          Option: OptimizedOption,
        }}
      />
      {isNotValid && <HelperText children='Required' variant='body2' />}
    </>
  )
}

export default AutoCompleteComp
