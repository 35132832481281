import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import LoadingIndicator from 'components/common/LoadingIndicator'
import {
  fetchProductSizes,
  fetchProductSizesForDropdown,
  setProductSizeShown,
  resetProductSizeDetails,
  fetchProductSizeByID,
  setProductSizePageNumber,
  setProductSizeRequestedSort,
  updateProductSizeOnConfirmation,
  setIsProductSizeUsed,
  toggleSizeStatus,
  setStatusChangeConfirmationForSize,
  setIsProductSizeFilterOpen,
} from 'ducks/productSizes'
import { fetchUnitsOfMeasure } from 'ducks/global'
import EditProductSize from './EditProductSize'
import ProductSizeFilters from './ProductSizeFilters'
import ConfirmationModal from 'components/common/ConfirmationModal'
import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core'
import { Block, VerifiedUserOutlined, MoreVert, Edit } from '@material-ui/icons'
import styled from 'styled-components'
import { convertSizeOfBBL } from 'common/helper'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const ProductSizes = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedProductSize, setSelectedProductSize] = useState(-1)
  const {
    productSizesList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    selected,
    isProductSizeShown,
    productSizeDetails,
    productSizeFilters,
    isProductSizeUsed,
    statusChangeConfirmationForSize,
    isProductSizeFilterOpen,
  } = useSelector(store => store.productSizes)
  const { loadingText, unitsOfMeasure } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(fetchUnitsOfMeasure())
    dispatch(fetchProductSizes())
    dispatch(fetchProductSizesForDropdown())
  }, [dispatch, order, orderBy, rowsPerPage, pageNumber])

  const ProductSizesHeaders = [
    { id: 'size', label: 'Product Size', isSortable: true },
    { id: 'unit', label: 'Units', isSortable: true },
    { id: 'containerType', label: 'Container Type', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  const onItemSelected = productSize => {
    setAnchorEl(null)
    dispatch(fetchProductSizeByID(productSize.id))
  }

  const handlePageNumber = newPage => {
    dispatch(setProductSizePageNumber(newPage))
  }
  const handleRequestSort = value => {
    dispatch(setProductSizeRequestedSort(value))
  }

  const onQuickViewClosed = () => {
    dispatch(resetProductSizeDetails())
    dispatch(setProductSizeShown(false))
  }
  const onAdd = () => {
    dispatch(resetProductSizeDetails())
    dispatch(setProductSizeShown(true))
  }
  const handleFilter = value => {
    dispatch(setIsProductSizeFilterOpen(value))
  }
  const handleActionOpen = (event, productSize) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setSelectedProductSize({
      ...productSize,
      id: parseInt(productSize.id),
    })
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }
  const handleStatusChange = () => {
    setAnchorEl(null)
    dispatch(setStatusChangeConfirmationForSize(true))
  }

  const openAction = Boolean(anchorEl)

  const updatedProductSizesList = productSizesList.results
    ? productSizesList.results.map(size => ({
        id: size.id,
        size: convertSizeOfBBL(size.size, size.unit.unit),
        unit: size.unit.unit,
        containerType: size.containerType.type,
        status: size.status,
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='true'
              onClick={event => handleActionOpen(event, size)}
              color='primary'
            >
              <MoreVert color='primary' />
            </IconButton>
          </>
        ),
      }))
    : []

  return productSizesList.firstLoad ? (
    <LoadingIndicator loadingText={loadingText} />
  ) : (
    <>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={() => onItemSelected(selectedProductSize)}>
          <Edit color='primary' fontSize='small' />
          <ActionLabels children='Edit' variant='body1' />
        </ActionItems>
        <Divider />
        {selectedProductSize.status === 'Active' ? (
          <ActionItems onClick={handleStatusChange}>
            <Block color='error' fontSize='small' />
            <ActionLabels children='Deactivate' variant='body1' />
          </ActionItems>
        ) : (
          <ActionItems onClick={handleStatusChange}>
            <VerifiedUserOutlined color='primary' fontSize='small' />
            <ActionLabels children='Activate' variant='body1' />
          </ActionItems>
        )}
      </Popover>
      <ListComponent
        mainTitle='Product Sizes'
        listContent={
          <ReusableTable
            headers={ProductSizesHeaders}
            tableData={updatedProductSizesList}
            pagination={true}
            selectionOption={false}
            totalCount={productSizesList.totalCount}
            onSingleItemSelected={onItemSelected}
            setPageNumber={handlePageNumber}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={selected}
          />
        }
        editComp={
          <EditProductSize
            productSize={productSizeDetails}
            unitsOfMeasure={unitsOfMeasure}
          />
        }
        expandedOptions={
          <ProductSizeFilters productSizeFilters={productSizeFilters} />
        }
        handleExpandingFilter={handleFilter}
        filterExpanded={isProductSizeFilterOpen}
        modalEditTitle={
          productSizeDetails.id ? 'Edit Product Size' : 'Add Product Size'
        }
        modalViewTitle='Product Size'
        onModalClosed={onQuickViewClosed}
        loadingText={loadingText}
        isDialogOpen={isProductSizeShown}
        onAddButtonClicked={onAdd}
      />
      <ConfirmationModal
        isConfirmationModalOpen={isProductSizeUsed}
        confirmationList={[
          'This size is currently being used by one or more price posts, if you confirm the change, the current size will become inactive and the change will be added as a new item.',
        ]}
        onConfirmation={() => dispatch(updateProductSizeOnConfirmation())}
        onConfirmationModalClosed={() => dispatch(setIsProductSizeUsed(false))}
      />
      <ConfirmationModal
        isConfirmationModalOpen={statusChangeConfirmationForSize}
        confirmationList={[
          `Please confirm if you want to ${
            selectedProductSize.status === 'Active'
              ? 'deactivate'
              : 'reactivate'
          } the product size.`,
        ]}
        onConfirmation={() => dispatch(toggleSizeStatus(selectedProductSize))}
        onConfirmationModalClosed={() =>
          dispatch(setStatusChangeConfirmationForSize(false))
        }
      />
    </>
  )
}

export default ProductSizes
