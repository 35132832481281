import React from 'react'
import styled from 'styled-components'
import {
  ListItemIcon,
  Grid,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core'
import { Clear, Done } from '@material-ui/icons'

const ListIcon = styled(ListItemIcon)`
  && {
    min-width: 24px;
    color: #b3b3b3;
  }
`

const ConstraintGrid = styled(Grid)`
  && {
    padding: 0px !important;
  }
`

const ConstraintItem = styled(ListItem)`
  && {
    padding-top: 2px;
    padding-bottom: 0;
  }
`
const CheckIcon = styled(Done)`
  color: ${props => props.theme.green};
`

const PasswordConstraint = ({ disabled, rule }) => (
  <ConstraintGrid item xs={12} sm={6} md={6} lg={6}>
    <ConstraintItem disabled={disabled} disableGutters>
      <ListIcon>
        {disabled ? <Clear fontSize='small' /> : <CheckIcon fontSize='small' />}
      </ListIcon>
      <ListItemText primary={rule} />
    </ConstraintItem>
  </ConstraintGrid>
)

const PasswordConstraintsCheck = ({ enteringNewPassword }) => {
  return (
    <List dense disablePadding>
      <Grid container>
        <PasswordConstraint
          disabled={!enteringNewPassword.match(/.{8,}$/gm)}
          rule='Minimum 8 characters'
        />
        <PasswordConstraint
          disabled={!enteringNewPassword.match(/([a-z])/gm)}
          rule='Lower case letter [a-z]'
        />
        <PasswordConstraint
          disabled={!enteringNewPassword.match(/([A-Z])/gm)}
          rule='Upper case letter [A-Z]'
        />
        <PasswordConstraint
          disabled={!enteringNewPassword.match(/([0-9])/gm)}
          rule='Numeric character [0-9]'
        />
        <PasswordConstraint
          disabled={
            !enteringNewPassword.match(
              /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/gm
            )
          }
          rule='Special character'
        />
      </Grid>
    </List>
  )
}

export default PasswordConstraintsCheck
