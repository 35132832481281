/* --------- E M A I L  T E M P L A T E S --------- */

export const abcUserRegistrationTemplate = verificationCode =>
  `Your <b>California Alcoholic Beverage Control Price Posting</b> account has been created. 
    Please click on the provided link to complete the registration process.<br><br>
    <b>Verification code:</b> ${verificationCode}<br/><br/>
    <span style='color:#666;font-size:13px;'>* Please note that the code will expire after 10 minutes.</span>`.replace(
    /(\r\n|\n|\r)/gm,
    ''
  )

export const leUserRegistrationTemplate = ({ licensee, verificationCode }) =>
  `Your <b>California Alcoholic Beverage Control Price Posting</b> account has been created. 
    Please click on the provided link to complete the registration process.<br><br>
    <b>Licensee:</b> ${licensee}<br><br><b>Verification code:</b> ${verificationCode}<br/><br/>
    <span style='color:#666;font-size:13px;'>* Please note that the code will expire after 10 minutes.</span>`.replace(
    /(\r\n|\n|\r)/gm,
    ''
  )

export const licenseeDeactivatedTemplate = licensee =>
  `The price posting account for ${licensee} has been deactivated. Users will no longer be able to access the system. If you have any questions, please contact the Department at PPS@abc.ca.gov.`

export const licenseeReactivatedTemplate = licensee =>
  `The price posting account for ${licensee} has been reactivated. Administrators will be able to access the system now. If you have any questions, please contact the Department at PPS@abc.ca.gov.`

export const licenseCategoryUpdateTemplate = () =>
  `A license type has been added or removed from your price posting account. If you have any questions, please contact the Department at PPS@abc.ca.gov.`

export const productsAddedTemplate = () =>
  `Pursuant to your request, the Department has added beer products to your price posting account. Prices can now be posted for those products.`

export const configurationRequestedTemplate = licensee =>
  `Licensee ${licensee} has requested a new configuration be added to the system. Please login to review the configuration request.`

export const configurationReviewedTemplate = status =>
  `Your configuration request has been ${status} by ABC. If you have any questions, please contact the Department at PPS@abc.ca.gov.`

export const productNameChangeTemplate = ({
  manufacturer,
  oldName,
  name,
  oldTradeName,
  tradeName,
}) =>
  `A manufacturer has changed their product name. 
  Please review the details below and login to the application if any changes are needed.<br><br>
    <b>Manufacturer:</b> ${manufacturer.name}<br><br>
    <b>Product Name:</b> ${
      oldName && oldName.length >= 0
        ? `<br><b style="margin-left:25px">From:</b> ${oldName} <br> 
           <b style="margin-left:25px">To:</b>  ${name}`
        : `${name}`
    }
    ${
      tradeName || typeof oldTradeName === 'string'
        ? `<br><br><b>Trade Name:</b> ${
            typeof oldTradeName === 'string'
              ? `<br><b style="margin-left:25px">From:</b> ${oldTradeName} <br> 
                 <b style="margin-left:25px">To:</b> ${tradeName}`
              : `${tradeName}`
          }`
        : ''
    }<br><br>`.replace(/(\r\n|\n|\r)/gm, '')

export const leAccountRegistrationTemplate = le =>
  `Your <b>California Alcoholic Beverage Control Price Posting</b> account has been created. 
  Please click on the provided link to complete the registration process.<br><br>
      <b>Licensee:</b> ${le.name}<br><br>
      <b>Unique Identifier:</b> ${le.uniqueId}<br><br>
      <b>Premises Address:</b><br/>
      ${le.premisesAddress.address}${
    le.premisesAddress.address2.length > 0
      ? `, ${le.premisesAddress.address2}`
      : ''
  }, ${le.premisesAddress.city}, ${le.premisesAddress.state}, ${
    le.premisesAddress.zipcode
  }, ${le.premisesAddress.country}<br/><br/>
  <span style='color:#666;font-size:13px;'>* The premises address is provided for verification purposes. 
  If you have a license at more than one premises, only one of those addresses is displayed. 
  If you do not possess an active license at the displayed premises address, 
  do not complete the registration process and contact the Price Posting Unit at 
  <a href='mailto:PPS@abc.ca.gov'>PPS@abc.ca.gov</a>.</span><br/><br/>
      <b>Mailing Address:</b><br/>
      ${le.mailingAddress.address}${
    le.mailingAddress.address2.length > 0
      ? `, ${le.mailingAddress.address2}`
      : ''
  }, ${le.mailingAddress.city}, ${le.mailingAddress.state}, ${
    le.mailingAddress.zipcode
  }, ${le.mailingAddress.country}<br/><br/>
      <b>License Types:</b><br/> ${le.licenseTypes
        .map(lt => `${lt.label}`)
        .join(', ')}
      `.replace(/(\r\n|\n|\r)/gm, '')
