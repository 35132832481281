import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import CustomCard from 'components/common/CustomCard'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CommonTooltip from 'components/common/CommonTooltip'
import { setEditMode, getLEsForDropDown } from 'ducks/global'
import { checkIfUserIsLicensee, checkIfUserIsABC } from 'common/helper'
import { setProductShow, resetProductDetails, addNewProduct, updateProduct } from 'ducks/products'
import styled from 'styled-components'

const validations = props => {
  return Yup.object().shape({
    product: Yup.object().shape({
      manufacturer: !props.cannotSelectManufacturer ? Yup.string().required('Required') : '',
      name: Yup.string().required('Required'),
    }),
  })
}

const CustomGrid = styled(Grid)`
  margin-top: 20px;
  margin-left: -20px;
`

const EditProduct = () => {
  const dispatch = useDispatch()
  const { isLoading, lEsForDropDown } = useSelector(store => store.global)

  const { user } = useSelector(store => store.auth)
  const isUserLicensee = user && user.role ? checkIfUserIsLicensee(user.role.id) : false

  const onAddProduct = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      if (formikValues.values.product.id) {
        dispatch(updateProduct(formikValues.values.product))
      } else {
        dispatch(addNewProduct(formikValues.values.product))
      }
    }
  }

  const updatedLegalEntities = lEsForDropDown.filter(
    le => le.status === 'Active' && (le.licenseCategory !== 'Wholesaler' || !le.licensed)
  )

  useEffect(() => {
    dispatch(getLEsForDropDown())
  }, [dispatch])

  return (
    <Form>
      <Field
        name='product'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.product = JSON.parse(
            JSON.stringify(formikProps.form.values.product)
          )
          const formikValues = formikProps.form.values

          const handleCancel = () => {
            if (!formikValues.product.id) {
              dispatch(setProductShow(false))
              dispatch(resetProductDetails())
            }
            dispatch(setEditMode(false))
          }

          const handleManufacturerChange = event => {
            formikProps.form.setFieldValue(`product.manufacturer`, event)
          }

          const leObject =
            formikValues.product.manufacturer &&
            lEsForDropDown.find(le => le.value === formikValues.product.manufacturer.id)

          return (
            <>
              {isLoading ? (
                <LoadingIndicator text={'Loading Product...'} transparent />
              ) : (
                <>
                  <CustomCard title='Product Details'>
                    {!formikValues.cannotSelectManufacturer && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field
                          autoFocus
                          placeHolderText='Manufacturer *'
                          selectedValue={leObject}
                          name='product.manufacturer'
                          formikProps={formikProps.form}
                          dropDownOptions={updatedLegalEntities}
                          onValueChange={handleManufacturerChange}
                          component={AutoCompleteComp}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        autoFocus={formikValues.cannotSelectManufacturer ? true : false}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Trade Name'
                        name='product.tradeName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={11} sm={11} md={4} lg={4}>
                      <Field
                        disabled={formikValues.product.id && isUserLicensee}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Product Name'
                        name='product.name'
                        component={TextField}
                      />
                    </Grid>
                    {user.role && !checkIfUserIsABC(user.role.id) ? (
                      <CustomGrid item xs={1} sm={1} md={1} lg={1}>
                        <CommonTooltip
                          informationList={[
                            'Users no longer have the ability to edit product names.  If a product name needs to be edited, send a detailed email to PPS@abc.ca.gov indicating the current product name, the amended product name, and why it needs to be amended.',
                          ]}
                          placement='top'
                          open={true}
                        />
                      </CustomGrid>
                    ) : null}
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddProduct(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.product.id && 'update'}
                    isDisabled={formikValues.product.id && !formikProps.form.isValid}
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditProduct)

export default WithFormikWrapper
