import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Typography, Avatar } from '@material-ui/core'
import { getLEsForDropDown } from 'ducks/global'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import beer from 'assets/beer.png'

const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.light};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewProduct = () => {
  const dispatch = useDispatch()
  const { isAuxLoading, loadingAuxText, lEsForDropDown } = useSelector(
    store => store.global
  )
  const { productDetails } = useSelector(store => store.publicProducts)

  useEffect(() => {
    dispatch(getLEsForDropDown(true)) //@param: publicView = true
  }, [dispatch])

  const leObject =
    productDetails.manufacturer &&
    lEsForDropDown.find(le => le.value === productDetails.manufacturer.id)

  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={<UserIcon src={beer} />}
          profileTitle={
            <>
              <TitleLabel children={`Product Name`} variant='body2' />
              <TitleValue children={productDetails.name} variant='h6' />
            </>
          }
          divider
        >
          <DataGrid
            label='Manufacturer Name'
            value={leObject && leObject.label}
          />
          <DataGrid label='Trade Name' value={productDetails.tradeName} />
          <DataGrid
            label='Added Date'
            value={
              productDetails.createdAt
                ? format(productDetails.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Edited Date'
            value={
              productDetails.updatedAt
                ? format(productDetails.updatedAt, 'MM/dd/yyyy')
                : ''
            }
          />
        </CustomCard>
      )}
    </>
  )
}

export default ViewProduct
