import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import SubmitButton from 'components/common/SubmitButton'
import ToggleMaskPassword from 'components/common/ToggleMaskPassword'
import PasswordConstraintsCheck from 'components/common/PasswordContraintsCheck'
import { addNewPassword } from 'ducks/authentication'

const NewPassword = () => {
  const dispatch = useDispatch()
  const { isAuthenticated, isAuthenticating, pathname } = useSelector(
    store => store.auth
  )

  // If the user is authenticated redirect them to the home page
  if (isAuthenticated) {
    return <Redirect to={pathname} />
  }

  const validationFields = {
    oldPassword: Yup.string().required('Required'),
    password: Yup.string()
      .matches(
        /([a-z])/,
        'Password should have minimum of one lower case letter.'
      )
      .matches(
        /([A-Z])/,
        'Password should have minimum of one uppercase case letter.'
      )
      .matches(
        /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/,
        'Password should have minimum of one special character.'
      )
      .matches(/([0-9])/, 'Password should have minimum of one numeric.')
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  }

  const validations = Yup.object().shape(validationFields)

  return (
    <>
      <Typography variant='body1'>Please create a new password.</Typography>
      <Formik
        initialValues={{
          oldPassword: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            addNewPassword(values.oldPassword, values.password)
          ).then(() => setSubmitting(false))
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='validationsPage'
            render={formikProps => {
              let enteringNewPassword = formikProps.form.values.password
              return (
                <>
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='oldPassword'
                    label='Old Password'
                    id='oldPassword'
                    component={ToggleMaskPassword}
                  />
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='New Password'
                    id='password'
                    component={ToggleMaskPassword}
                  />
                  <PasswordConstraintsCheck
                    enteringNewPassword={enteringNewPassword}
                  />
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='confirmPassword'
                    label='Confirm Password'
                    id='confirmPassword'
                    component={ToggleMaskPassword}
                  />
                  <SubmitButton isSubmitting={isAuthenticating} />
                </>
              )
            }}
          />
        </Form>
      </Formik>
    </>
  )
}

export default NewPassword
