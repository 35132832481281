import { createAction, createReducer } from '@reduxjs/toolkit'

export const updateBulkJson = bulkJson => {
  return dispatch => {
    dispatch(handleBulkJson(bulkJson))
    dispatch(handleBulkUploadNotReady(true))
  }
}

export const updateBulkFilename = filename => {
  return dispatch => {
    dispatch(handleBulkFilename(filename))
  }
}

export const resetBulkUpload = createAction('bulkUpload/resetBulkUpload')
export const handleBulkJson = createAction('bulkUpload/handleBulkJson')
export const handleBulkFilename = createAction('bulkUpload/handleBulkFilename')
export const handleErrorJson = createAction('bulkUpload/handleErrorJson')
export const handleBulkUploadNotReady = createAction('bulkUpload/handleBulkUploadNotReady')

const initialState = {
  bulkJson: null,
  bulkFilename: null,
  returnedErrorJson: null,
  uploadNotReady: false,
}

export default createReducer(initialState, {
  [handleBulkJson]: (state, action) => {
    state.bulkJson = action.payload
  },
  [handleBulkFilename]: (state, action) => {
    state.bulkFilename = action.payload
  },
  [handleErrorJson]: (state, action) => {
    state.returnedErrorJson = action.payload
  },
  [handleBulkUploadNotReady]: (state, action) => {
    state.uploadNotReady = action.payload
  },
  [resetBulkUpload]: (state, action) => {
    state.bulkJson = initialState.bulkJson
    state.returnedErrorJson = initialState.returnedErrorJson
    state.uploadNotReady = initialState.uploadNotReady
  },
})
