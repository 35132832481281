import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Edit } from '@material-ui/icons'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import { setEditMode, getLEsForDropDown } from 'ducks/global'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { Can, AbilityContext } from 'common/abilityContext'
import { addEditBulkOn } from 'common/constants'
import beer from 'assets/beer.png'
import { useContext } from 'react'
import { CheckManufacturerForProductCreation } from 'common/helper'

const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.light};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`

const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewProduct = () => {
  const dispatch = useDispatch()
  const { isAuxLoading, loadingAuxText, lEsForDropDown } = useSelector(
    store => store.global
  )
  const { productDetails } = useSelector(store => store.products)
  const abilityFunc = useContext(AbilityContext)

  useEffect(() => {
    dispatch(getLEsForDropDown())
  }, [dispatch])

  const leObject =
    productDetails.manufacturer &&
    lEsForDropDown.find(le => le.value === productDetails.manufacturer.id)

  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={<UserIcon src={beer} />}
          profileTitle={
            <>
              <TitleLabel children={`Product Name`} variant='body2' />
              <TitleValue children={productDetails.name} variant='h6' />
            </>
          }
          profileAction={
            productDetails.status === 'Active' &&
            abilityFunc.can('perform', addEditBulkOn.products) &&
            abilityFunc.can(
              'edit',
              new CheckManufacturerForProductCreation({
                ifCreatedByManufacturerIs:
                  productDetails.manufacturer && productDetails.manufacturer.id,
              })
            ) ? (
              <IconButton
                aria-label='Edit Product'
                aria-haspopup='false'
                color='primary'
                onClick={() => dispatch(setEditMode(true))}
              >
                <EditIcon color='primary' />
                <Typography variant='subtitle1'>&nbsp;{'Edit'}</Typography>
              </IconButton>
            ) : (
              ''
            )
          }
          divider
        >
          <DataGrid
            label='Manufacturer Name'
            value={leObject && leObject.label}
          />
          <DataGrid label='Trade Name' value={productDetails.tradeName} />
          <DataGrid
            label='Added Date'
            value={
              productDetails.createdAt
                ? format(productDetails.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <Can I='view' this='oldProductInfo'>
            {() => (
              <>
                <DataGrid
                  label='Old Product Name'
                  value={productDetails.oldName}
                />
                <DataGrid
                  label='Old Trade Name'
                  value={productDetails.oldTradeName}
                />
              </>
            )}
          </Can>
          <DataGrid
            label='Edited Date'
            value={
              productDetails.updatedAt
                ? format(productDetails.updatedAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid label='Source' value={productDetails.source} />
        </CustomCard>
      )}
    </>
  )
}

export default ViewProduct
