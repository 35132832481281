import { createAction, createReducer } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  showSuccess,
  showError,
  setLoading,
  setLoadingProgress,
  setAuxLoading,
  setEditMode,
  setNewLicenseeAdded,
  getLicenseTypes,
  showWarning,
} from './global'
import {
  getLegalEntitiesQuery,
  getLegalEntityByIdQuery,
  getLegalEntitiesForSelectAll,
  getLegalEntitiesForBulkPrinting,
  findLegalEntityQuery,
  getUsersQuery,
  fetchLicenseesIdQuery,
} from 'graphql/queries'
import {
  licenseeMutation,
  bulkLicenseeMutation,
  deactivateLicenseeMutation,
  reactivateLicenseeMutation,
  linkProductsFromLicenseeMutation,
} from 'graphql/mutations'
import {
  leAccountRegistrationTemplate,
  licenseeDeactivatedTemplate,
  licenseeReactivatedTemplate,
  licenseCategoryUpdateTemplate,
} from 'common/templates'
import {
  pricePostingURL,
  leSignUpLink,
  licenseCategoryList,
  usStates,
  countries,
  litigationOptions,
} from 'common/constants'
import {
  cleanUp,
  exportToExcel,
  notifyLEAdmins,
  getStartOfDay,
  getEndOfDay,
} from 'common/helper'
import * as Yup from 'yup'

export const setLegalEntityDetails = createAction(
  'legalEntity/setLegalEntityDetails'
)
export const setLegalEntities = createAction('legalEntity/setLegalEntities')
export const setLePageNumber = createAction('legalEntity/setLePageNumber')
export const setLeRowsPerPage = createAction('legalEntity/setLeRowsPerPage')
export const setLeRequestedSort = createAction('legalEntity/setLeRequestedSort')
export const setLeBulkSelection = createAction('legalEntity/setLeBulkSelection')
export const setIsLegalEntityFilterOpen = createAction(
  'legalEntity/setIsLegalEntityFilterOpen'
)
export const setLegalEntitiesFilters = createAction(
  'legalEntity/setLegalEntitiesFilters'
)
export const setLeAccountShown = createAction('legalEntity/setLeAccountShown')
export const setLicenseeBulkUploadModal = createAction(
  'legalEntity/setLicenseeBulkUploadModal'
)
export const setBulkPrintingLEs = createAction('legalEntity/setBulkPrintingLEs')
export const setDeactivationConfirmation = createAction(
  'legalEntity/setDeactivationConfirmation'
)
export const setReactivationConfirmation = createAction(
  'legalEntity/setReactivationConfirmation'
)
export const resetLegalEntities = createAction('legalEntity/resetLegalEntities')
export const setInactiveLicenseeFound = createAction(
  'legalEntity/setInactiveLicenseeFound'
)
export const setConfirmationForLinkingProducts = createAction(
  'legalEntity/setConfirmationForLinkingProducts'
)

// Determine license category based on license types
const getLicenseCategory = usedLicenseTypes => {
  let isManufacturer = false,
    isWholesaler = false
  for (let lt of usedLicenseTypes) {
    if (lt.category === licenseCategoryList.manufacturer) isManufacturer = true
    else if (lt.category === licenseCategoryList.wholesaler) isWholesaler = true
  }
  if (isManufacturer && isWholesaler) return licenseCategoryList.both
  else if (isManufacturer) return licenseCategoryList.manufacturer
  else if (isWholesaler) return licenseCategoryList.wholesaler
}

export const addLegalEntity = legalEntityDetails => {
  // Create new object based on params (done to avoid keeping a reference to the object sent as param)
  let newLegalEntity = JSON.parse(JSON.stringify(legalEntityDetails))
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading...'))

    // Define license category before storing details
    const usedLicenseTypes = newLegalEntity.licenseTypes
    newLegalEntity.licenseCategory = getLicenseCategory(usedLicenseTypes)
    dispatch(setLegalEntityDetails(newLegalEntity))

    let createNewLicensee = false

    await API.graphql(
      graphqlOperation(fetchLicenseesIdQuery, {
        where: {
          name_lower: newLegalEntity.name.trim(),
          status: 'Inactive',
        },
      })
    )
      .then(res => {
        const response = res.data.legalEntities.results
        if (response.length === 1) {
          // this will show a confirmation modal to inactive the existing item or create a new one
          dispatch(setInactiveLicenseeFound(response[0].id))
        } else if (response.length === 0) {
          // as no inactive items are found will Create a new Licensee
          createNewLicensee = true
        } else if (response.length >= 2) {
          dispatch(
            showError(
              'More then one inactive licensee with this name has been found, please contact your Price Posting IT administrator to assist you with creating this licensee.'
            )
          )
        }
      })
      .catch(err => {
        dispatch(
          showError(
            'There was an issue creating the licensee, please review the form and submit again. Thank you.'
          )
        )
      })

    if (createNewLicensee) {
      // create the new licensee and do not link products
      dispatch(createNewLicenseeProcess(newLegalEntity, false))
    } else {
      dispatch(setLoading(false))
    }
  }
}

export const createNewLicenseeProcess = (
  legalEntityDetails,
  linkProducts = false,
  duplicateCheck = true
) => {
  let newLegalEntity = JSON.parse(JSON.stringify(legalEntityDetails))
  return async (dispatch, getState) => {
    // Store inactive licensee id (if any) and clear modals before starting creation process
    const inactiveLicenseeId = getState().legalEntity.inactiveLicenseeFound
    dispatch(setInactiveLicenseeFound(null))
    dispatch(setConfirmationForLinkingProducts(false))
    dispatch(setLoading('Creating new licensee, please wait...'))

    const currUser = getState().auth.user
    const currUserId = parseInt(currUser.id)
    const usedLicenseTypes = newLegalEntity.licenseTypes

    let licenseeData = {
      name: newLegalEntity.name.trim(),
      email: newLegalEntity.email.toLowerCase(),
      licensed: newLegalEntity.licensed,
      licenseTypes: usedLicenseTypes.map(lt => parseInt(lt.value)),
      licenseCategory: getLicenseCategory(usedLicenseTypes),
      premisesAddress: newLegalEntity.premisesAddress,
      mailingAddress: newLegalEntity.mailingAddress,
      isMailingSameAsPremises: newLegalEntity.isMailingSameAsPremises,
      userId: currUserId,
      inLitigation: newLegalEntity.inLitigation,
    }

    // Create Licensee (don't check for duplicates only when linking products)
    const res = await API.graphql(
      graphqlOperation(licenseeMutation, {
        data: licenseeData,
        duplicateCheck: duplicateCheck,
      })
    )

    const mutationError = res.data.licenseeMutation.error
    if (!mutationError) {
      const response = res.data.licenseeMutation.result
      // Adjust licensee details for UI
      const licenseeDetails = {
        ...licenseeData,
        licenseTypes: usedLicenseTypes,
        userSignUpLink: leSignUpLink,
        uniqueId: response.uniqueId,
        id: response.id,
        status: response.status,
      }
      if (licenseeData.isMailingSameAsPremises)
        licenseeDetails.mailingAddress = licenseeDetails.premisesAddress

      let registrationEmailSent = false
      // Send LE account registration email
      if (licenseeData.email.length > 0 && licenseeData.licensed) {
        const params = {
          body: {
            to: licenseeData.email,
            subject: 'ABC Price Posting - Account Registration',
            message: leAccountRegistrationTemplate(licenseeDetails),
            buttonLink: leSignUpLink,
            buttonText: 'Complete Registration',
          },
        }
        API.post('ABC-API', '/send-email', params)
        registrationEmailSent = true
      }

      if (
        linkProducts &&
        inactiveLicenseeId &&
        licenseeDetails.licenseCategory.includes('Manufacturer')
      ) {
        await API.graphql(
          graphqlOperation(linkProductsFromLicenseeMutation, {
            newId: parseInt(response.id),
            oldId: parseInt(inactiveLicenseeId),
            updatedBy: currUserId,
          })
        ).catch(err => {
          dispatch(
            showError(
              'There was an issue linking products from the inactive licensee, please contact your Price Posting IT administrator to assist you with creating this licensee.'
            )
          )
        })
      }

      dispatch(setLegalEntityDetails(licenseeDetails))
      dispatch(setEditMode(false))
      dispatch(getListOfLegalEntities())
      dispatch(
        showSuccess(
          `The licensee has been created ${
            registrationEmailSent
              ? 'and a notification has been sent to the provided email.'
              : 'successfully.'
          }`
        )
      )
      dispatch(setNewLicenseeAdded(true))
    } else {
      // Display error to user
      dispatch(setLoading(false))
      const errorMessage =
        mutationError === 'DUPLICATE'
          ? 'A licensee with the same name already exists, please review your information and try again.'
          : 'There was an issue creating the licensee, please review the form and submit again. Thank you.'
      dispatch(showError(errorMessage))
    }
  }
}

export const updateLegalEntity = legalEntity => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const currUser = getState().auth.user
    const originalLicensee = getState().legalEntity.legalEntityDetails
    const usedLicenseTypes = legalEntity.licenseTypes
    const newLicenseCategory = getLicenseCategory(usedLicenseTypes)

    let licenseeData = {
      id: parseInt(legalEntity.id),
      name: legalEntity.name.trim(),
      email: legalEntity.email.toLowerCase(),
      licensed: legalEntity.licensed,
      licenseTypes: usedLicenseTypes.map(lt => parseInt(lt.value)),
      licenseCategory: newLicenseCategory,
      premisesAddress: legalEntity.premisesAddress,
      mailingAddress: legalEntity.mailingAddress,
      isMailingSameAsPremises: legalEntity.isMailingSameAsPremises,
      userId: parseInt(currUser.id),
      inLitigation: legalEntity.inLitigation,
    }

    // Update Licensee
    const res = await API.graphql(
      graphqlOperation(licenseeMutation, {
        data: licenseeData,
      })
    )

    const mutationError = res.data.licenseeMutation.error
    if (!mutationError) {
      const response = res.data.licenseeMutation.result
      dispatch(setEditMode(false))
      const licenseeDetails = { ...legalEntity, status: response.status }
      if (legalEntity.isMailingSameAsPremises)
        licenseeDetails.mailingAddress = licenseeDetails.premisesAddress

      let registrationEmailSent = false
      // Send LE account registration email only on change from unlicensed to licensed
      if (
        legalEntity.email.length > 0 &&
        legalEntity.licensed &&
        originalLicensee.licensed !== legalEntity.licensed &&
        response.status === 'Pending'
      ) {
        licenseeDetails.uniqueId = response.uniqueId
        const params = {
          body: {
            to: licenseeData.email,
            subject: 'ABC Price Posting - Account Registration',
            message: leAccountRegistrationTemplate(licenseeDetails),
            buttonLink: leSignUpLink,
            buttonText: 'Complete Registration',
          },
        }
        API.post('ABC-API', '/send-email', params)
        registrationEmailSent = true
      }

      // Notify LE Admins about license category change (if any)
      if (
        originalLicensee.status === 'Active' &&
        originalLicensee.licenseCategory !== newLicenseCategory
      ) {
        notifyLEAdmins(legalEntity.id, 'LICENSEE_CATEGORY_CHANGED')
      }

      dispatch(setLegalEntityDetails(licenseeDetails))
      dispatch(getListOfLegalEntities())
      dispatch(
        showSuccess(
          `The licensee has been updated ${
            registrationEmailSent
              ? 'and a notification has been sent to the provided email.'
              : 'successfully.'
          }`
        )
      )

      dispatch(setNewLicenseeAdded(true))
    } else {
      // Display error to user
      dispatch(setLoading(false))
      const errorMessage =
        mutationError === 'DUPLICATE'
          ? 'A licensee with the same name already exists, please review your information and try again.'
          : 'Unable to edit licensee data, please try again.'
      dispatch(showError(errorMessage))
    }
  }
}

export const resetLegalEntityDetails = () => {
  return dispatch => {
    dispatch(setLegalEntityDetails(initialState.legalEntityDetails))
    dispatch(setLoading(false))
  }
}

/* Resend Registration code */
export const resendRegistration = targetLE => {
  let currLegalEntity
  currLegalEntity = JSON.parse(JSON.stringify(targetLE))
  return async (dispatch, getState) => {
    if (!targetLE.email) {
      await dispatch(fetchLegalEntityByID(targetLE.id, false, false))
      currLegalEntity = getState().legalEntity.legalEntityDetails
    }
    dispatch(setAuxLoading('Sending registration to licensee...'))

    // Send LE account registration email
    const params = {
      body: {
        to: currLegalEntity.email.toLowerCase(),
        subject: 'ABC Price Posting - Account Registration',
        message: leAccountRegistrationTemplate(currLegalEntity),
        buttonLink: leSignUpLink,
        buttonText: 'Complete Registration',
      },
    }
    await API.post('ABC-API', '/send-email', params)
    dispatch(
      showSuccess(
        'A link to complete the registration process has been sent to the user.'
      )
    )
    dispatch(setAuxLoading(false))
  }
}

// Fetch user by ID after selecting a list item
export const fetchLegalEntityByID = (
  id,
  editMode = false,
  showAccount = true
) => {
  return async dispatch => {
    dispatch(setLoading('Loading Licensee...'))
    await API.graphql(
      graphqlOperation(getLegalEntityByIdQuery, { where: { id: parseInt(id) } })
    )
      .then(response => {
        let newResponse = {}
        if (response.data.legalEntities.count > 0) {
          const resData = response.data.legalEntities.results[0]
          newResponse = {
            ...resData,
            licenseTypes: resData.licenseTypes.map(lt => ({
              ...lt,
              label:
                lt.number && lt.number.length > 0
                  ? `${lt.number} - ${lt.label}`
                  : lt.label,
            })),
            isMailingSameAsPremises: false,
            userSignUpLink: leSignUpLink,
          }
          if (
            resData.mailingAddress &&
            resData.mailingAddress.id &&
            resData.premisesAddress &&
            resData.premisesAddress.id === resData.mailingAddress.id
          ) {
            newResponse = {
              ...newResponse,
              mailingAddress: resData.mailingAddress,
              isMailingSameAsPremises: true,
            }
          }
        }

        dispatch(setLegalEntityDetails(newResponse))
        dispatch(setEditMode(editMode))
        dispatch(setLeAccountShown(showAccount))
      })
      .catch(_ => {
        dispatch(
          showError(
            `Unable to retrieve licensee, please check your internet connection and try again`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const getListOfLegalEntities = (whereClause, selectAll = false) => {
  return async (dispatch, getState, services) => {
    const leState = getState().legalEntity
    const orderRule = `${leState.orderBy}_${leState.order}`
    let filtersWhereClause = null
    if (whereClause) {
      // map through the list of license types and return ID's of the selected license types
      filtersWhereClause = {
        ...whereClause,
      }
      dispatch(setLegalEntitiesFilters(filtersWhereClause))
    } else {
      filtersWhereClause = leState.legalEntitiesFilters
    }
    dispatch(setLoading('Loading Licensees...'))

    // Add GraphQL query parameters
    let graphQLParams = {
      orderBy: orderRule,
    }
    // Set up GraphQL where parameters based on filters selected
    if (filtersWhereClause) {
      let whereParam = {}
      if (filtersWhereClause.licensee)
        whereParam.name_like = cleanUp(filtersWhereClause.licensee)
      if (filtersWhereClause.licenseCategory)
        whereParam.licenseCategory = cleanUp(filtersWhereClause.licenseCategory)
      if (filtersWhereClause.status && filtersWhereClause.status.length > 0) {
        whereParam.status_in = filtersWhereClause.status.map(
          stat => `${stat.value}`
        )
      }
      if (
        filtersWhereClause.licenseTypes &&
        filtersWhereClause.licenseTypes.length > 0
      ) {
        whereParam.licenseTypeId_in = filtersWhereClause.licenseTypes.map(
          type => parseInt(type.value)
        )
        graphQLParams.operator = filtersWhereClause.operatorForLicenseType
      }
      if (filtersWhereClause.licensed.label === 'Licensed') {
        whereParam.licensed = true
      }
      if (filtersWhereClause.licensed.label === 'Unlicensed') {
        whereParam.licensed = false
      }

      if (filtersWhereClause.createdAtFrom && filtersWhereClause.createdAtTo) {
        whereParam.createdAt_gte = getStartOfDay(
          filtersWhereClause.createdAtFrom
        )
        whereParam.createdAt_lte = getEndOfDay(filtersWhereClause.createdAtTo)
      }
      if (filtersWhereClause.updatedAtFrom && filtersWhereClause.updatedAtTo) {
        whereParam.updatedAt_gte = getStartOfDay(
          filtersWhereClause.updatedAtFrom
        )
        whereParam.updatedAt_lte = getEndOfDay(filtersWhereClause.updatedAtTo)
      }

      if (filtersWhereClause.isEmailEmpty) {
        whereParam.email = ''
      } else if (filtersWhereClause.email) {
        whereParam.email_like = filtersWhereClause.email
      }

      //When in litigation is selected
      if (filtersWhereClause.litigation.label === litigationOptions[1].label) {
        whereParam.inLitigation = true
      }
      //When not in litigation is selected
      if (filtersWhereClause.litigation.label === litigationOptions[2].label) {
        whereParam.inLitigation = false
      }

      graphQLParams.where = whereParam
    }

    if (selectAll) {
      await services.AmplifyAPI.graphql(
        graphqlOperation(getLegalEntitiesForSelectAll, graphQLParams)
      )
        .then(response => {
          dispatch(
            setLeBulkSelection(
              response.data.legalEntities.results.map(item => ({
                id: item.id,
                licensed: item.licensed,
              }))
            )
          )
        })
        .catch(errorResponse => {
          dispatch(
            showError(
              'Unable to retrieve list of licensees, please check your internet connection and try again'
            )
          )
        })
    } else {
      graphQLParams = {
        ...graphQLParams,
        limit: leState.rowsPerPage,
        offset: leState.pageNumber * leState.rowsPerPage,
      }

      await services.AmplifyAPI.graphql(
        graphqlOperation(getLegalEntitiesQuery, graphQLParams)
      )
        .then(response => {
          dispatch(
            setLegalEntities({
              ...response.data.legalEntities,
              results: response.data.legalEntities.results.map(item => {
                const licensed = item.licensed
                // Remove from list to hide it from UI list
                delete item.licensed

                return licensed
                  ? item
                  : {
                      ...item,
                      licenseTypes: '',
                      licenseCategory: 'Unlicensed',
                    }
              }),
            })
          )
        })
        .catch(errorResponse => {
          dispatch(
            showError(
              'Unable to retrieve list of licensees, please check your internet connection and try again'
            )
          )
        })
    }
    dispatch(setLoading(false))
  }
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const lowerCaseAndTrim = value => {
  return typeof value === 'string' ? value.toLowerCase().trim() : value
}

export const licenseeBulkUploadProcess = bulkLicensees => {
  return async (dispatch, getState) => {
    dispatch(setLicenseeBulkUploadModal(false))
    dispatch(setLoading('Retrieving data for bulk upload...'))
    await dispatch(getLicenseTypes())

    dispatch(setLoading('Validating fields...'))
    const { licenseTypes } = getState().global
    const currUser = getState().auth.user
    let licenseesWithError = [],
      finalLicenseesList = []

    // New bulk licensees list which includes the index (used later to reference rows with errors)
    const numberedBulkLicensees = bulkLicensees.map((licensee, index) => ({
      index: index,
      ...licensee,
    }))

    // Function to create error object based on template and specific reason
    const createErrorObject = (bulkItem, errorReason) => ({
      'Licensee Name': bulkItem.licenseeName,
      Email: bulkItem.email,
      'License Type': bulkItem.licenseType,
      'Premises Address': bulkItem.premisesAddress,
      'Premises City': bulkItem.premisesCity,
      'Premises State': bulkItem.premisesState,
      'Premises Country': bulkItem.premisesCountry,
      'Premises Zip Code': bulkItem.premisesZipCode,
      'Mailing Address': bulkItem.mailingAddress,
      'Mailing City': bulkItem.mailingCity,
      'Mailing State': bulkItem.mailingState,
      'Mailing Country': bulkItem.mailingCountry,
      'Mailing Zip Code': bulkItem.mailingZipCode,
      'Error Reason': errorReason,
    })

    // Wrap on try catch to remove loading and reset UI if an unexpected error is found
    try {
      for (let bulkItem of numberedBulkLicensees) {
        let invalidFields = [],
          validEmail,
          validPremisesState,
          validPremisesCountry,
          validMailingAddress = false,
          validMailingState,
          validMailingCountry,
          indexOfDuplicateLicensee,
          validLicenseType

        const formattedEnteredValues = {
          licenseType: lowerCaseAndTrim(bulkItem.licenseType),
          licenseeName: lowerCaseAndTrim(bulkItem.licenseeName),
          email: lowerCaseAndTrim(bulkItem.email),
          premisesCountry: lowerCaseAndTrim(bulkItem.premisesCountry),
          premisesState: lowerCaseAndTrim(bulkItem.premisesState),
          premisesAddress: lowerCaseAndTrim(bulkItem.premisesAddress),
          premisesCity: lowerCaseAndTrim(bulkItem.premisesCity),
          premisesZipCode: lowerCaseAndTrim(bulkItem.premisesZipCode),
          mailingCountry: lowerCaseAndTrim(bulkItem.mailingCountry),
          mailingState: lowerCaseAndTrim(bulkItem.mailingState),
          mailingAddress: lowerCaseAndTrim(bulkItem.mailingAddress),
          mailingCity: lowerCaseAndTrim(bulkItem.mailingCity),
          mailingZipCode: lowerCaseAndTrim(bulkItem.mailingZipCode),
        }

        if (formattedEnteredValues.licenseType) {
          // Check that user entered valid license type, which exists in the system
          if (
            typeof formattedEnteredValues.licenseType === 'string' &&
            formattedEnteredValues.licenseType === 'coc'
          ) {
            //index 6: is the COC licensee type
            validLicenseType = licenseTypes[6]
          } else {
            //gets here if it is not string and not coc
            validLicenseType = licenseTypes.filter(
              type =>
                parseInt(type.number) ===
                parseInt(formattedEnteredValues.licenseType)
            )[0] // Here the validLicenseType will be an object, example:{value: 1, number: 17, Label: 'Manufacturer', category:'manufacture/wholesaler'}
          }
        }

        if (formattedEnteredValues.licenseeName) {
          // Check for duplicate licensee name inside the adding licenses details list
          indexOfDuplicateLicensee = finalLicenseesList.findIndex(
            item =>
              item.name.toLowerCase().trim() ===
              formattedEnteredValues.licenseeName
          )
        } else {
          invalidFields.push('Licensee Name')
        }

        if (!validLicenseType) {
          invalidFields.push('License Type')
        }

        if (
          !formattedEnteredValues.licenseeName ||
          !validLicenseType ||
          indexOfDuplicateLicensee < 0
        ) {
          // Did not find duplicate Licensee name in the same excel file
          // If it gets here it is a new licensee name
          const emailSchema = Yup.string().email()
          if (formattedEnteredValues.email) {
            const emailIsValid = await emailSchema.isValid(
              formattedEnteredValues.email
            )
            if (emailIsValid) {
              validEmail = formattedEnteredValues.email
            } else {
              invalidFields.push('Email')
            }
          } else {
            validEmail = ''
          }

          let indexOfCountry
          if (formattedEnteredValues.premisesCountry) {
            indexOfCountry = countries.findIndex(
              country =>
                country.label.toLowerCase() ===
                formattedEnteredValues.premisesCountry
            )
          }

          if (indexOfCountry > -1) {
            //found Country in the the countries constant from constants.js
            validPremisesCountry = countries[indexOfCountry].label
          } else {
            invalidFields.push('Premises Country')
          }

          let indexOfState

          if (formattedEnteredValues.premisesState) {
            if (formattedEnteredValues.premisesState.length === 2) {
              indexOfState = usStates.findIndex(
                state =>
                  state.abbreviation.toLowerCase() ===
                  formattedEnteredValues.premisesState
              )
            } else {
              indexOfState = usStates.findIndex(
                state =>
                  state.label.toLowerCase() ===
                  formattedEnteredValues.premisesState
              )
            }
          }

          if (validPremisesCountry === 'United States' && indexOfState > -1) {
            validPremisesState = usStates[indexOfState].label
          } else if (
            (validPremisesCountry === 'United States' && indexOfState === -1) ||
            !formattedEnteredValues.premisesState
          ) {
            invalidFields.push('Premises State')
          } else if (indexOfState > -1) {
            validPremisesState = usStates[indexOfState].label
          } else {
            validPremisesState = formattedEnteredValues.premisesState
          }

          if (!formattedEnteredValues.premisesAddress) {
            invalidFields.push('Premises Address')
          }

          if (!formattedEnteredValues.premisesCity) {
            invalidFields.push('Premises City')
          }

          if (!formattedEnteredValues.premisesZipCode) {
            invalidFields.push('Premises Zip Code')
          }

          if (
            formattedEnteredValues.mailingAddress ||
            formattedEnteredValues.mailingCity ||
            formattedEnteredValues.mailingState ||
            formattedEnteredValues.mailingCountry ||
            formattedEnteredValues.mailingZipCode
          ) {
            let indexOfMailingCountry
            if (formattedEnteredValues.mailingCountry) {
              indexOfMailingCountry = countries.findIndex(
                country =>
                  country.label.toLowerCase() ===
                  formattedEnteredValues.mailingCountry
              )
            }

            let invalidFieldsSize = invalidFields.length
            if (indexOfMailingCountry > -1) {
              //found Country in the the countries constant from constants.js
              validMailingCountry = countries[indexOfMailingCountry].label
            } else {
              invalidFields.push('Mailing Country')
            }

            let indexOfState

            if (formattedEnteredValues.mailingState) {
              if (formattedEnteredValues.mailingState.length === 2) {
                indexOfState = usStates.findIndex(
                  state =>
                    state.abbreviation.toLowerCase() ===
                    formattedEnteredValues.mailingState
                )
              } else {
                indexOfState = usStates.findIndex(
                  state =>
                    state.label.toLowerCase() ===
                    formattedEnteredValues.mailingState
                )
              }
            }

            if (validMailingCountry === 'United States' && indexOfState > -1) {
              validMailingState = usStates[indexOfState].label
            } else if (
              (validMailingCountry === 'United States' &&
                indexOfState === -1) ||
              !formattedEnteredValues.mailingState
            ) {
              invalidFields.push('Mailing State')
            } else if (indexOfState > -1) {
              validMailingState = usStates[indexOfState].label
            } else {
              validMailingState = formattedEnteredValues.mailingState
            }

            if (!formattedEnteredValues.mailingAddress) {
              invalidFields.push('Mailing Address')
            }

            if (!formattedEnteredValues.mailingCity) {
              invalidFields.push('Mailing City')
            }

            if (!formattedEnteredValues.mailingZipCode) {
              invalidFields.push('Mailing Zip Code')
            }

            // If no invalid fields where found then set the mailing address as valid
            validMailingAddress = invalidFieldsSize === invalidFields.length
          }

          if (invalidFields.length === 0) {
            // If it gets here that means all the fields entered are valid and can be sent for adding a new licensee
            let licenseeData = {
              index: bulkItem.index,
              name: bulkItem.licenseeName.trim(),
              email: validEmail,
              licenseType: [validLicenseType],
              premisesAddress: {
                address: bulkItem.premisesAddress,
                address2: '',
                city: bulkItem.premisesCity,
                state: validPremisesState,
                country: validPremisesCountry,
                zipcode: `${bulkItem.premisesZipCode}`,
              },
              isMailingSameAsPremises: !validMailingAddress,
              userId: parseInt(currUser.id),
            }
            if (!licenseeData.isMailingSameAsPremises)
              licenseeData.mailingAddress = {
                address: bulkItem.mailingAddress,
                address2: '',
                city: bulkItem.mailingCity,
                state: validMailingState,
                country: validMailingCountry,
                zipcode: `${bulkItem.mailingZipCode}`,
              }
            finalLicenseesList.push(licenseeData)
          } else {
            // If it gets here that means more than one fields are invalid
            // Send back that particular row item with error reason
            let errorReason = `Invalid or missing ${invalidFields.join(
              ', '
            )}. Make sure you entered all required fields correctly.`
            licenseesWithError.push(createErrorObject(bulkItem, errorReason))
          }
        } else if (indexOfDuplicateLicensee > -1) {
          // Found duplicate licensee-name with different licensee-type
          // Check if the found licensee have the same license type
          if (
            finalLicenseesList[indexOfDuplicateLicensee].licenseType
              .map(type => type.number)
              .includes(validLicenseType.number)
          ) {
            // Send back that particular row item with error reason
            let errorReason = `Licensee with the same name already exists in the system.`
            licenseesWithError.push(createErrorObject(bulkItem, errorReason))
          } else {
            // If it gets here the finalLicenseeList has the same licensee name with different licensee types
            // Push the licensee type to the final licensee list to merge all license types into 1 licensee entry
            finalLicenseesList[indexOfDuplicateLicensee].licenseType.push(
              validLicenseType
            )
          }
        }
      }

      dispatch(setLoading('Processing licensees...'))
      dispatch(
        setLoadingProgress({
          value: 0,
          buffer: 0,
        })
      )
      if (finalLicenseesList.length > 0) {
        // Determine the licensee category using each row item's licensee types
        finalLicenseesList = finalLicenseesList.map(item => {
          const licenseTypes = item.licenseType
          delete item.licenseType
          return {
            ...item,
            licensed: true,
            licenseCategory: getLicenseCategory(licenseTypes),
            licenseTypes: licenseTypes.map(lt => parseInt(lt.value)),
          }
        })
      }

      // Separate bulk into batches of 500 to allow progress to be shown and remove overloading the server
      const bulkListSize = finalLicenseesList.length,
        batchSize = 500
      let addedLicensees = [],
        progressCount = 0,
        bufferSize = Math.floor((batchSize / bulkListSize) * 100)
      // Keep list of licensee admin emails where the license category has been updated to notify them
      const categoryChangedBulkEmails = []

      dispatch(
        setLoadingProgress({
          value: 0,
          buffer: bufferSize >= 100 ? 100 : bufferSize,
        })
      )

      // Send to API list in batches
      while (progressCount < bulkListSize) {
        let batchLicenseeList
        // If current batch is less than the max batch size send the remaining items on the list
        if (bulkListSize <= batchSize) batchLicenseeList = finalLicenseesList
        else {
          batchLicenseeList = finalLicenseesList.slice(
            progressCount,
            progressCount + batchSize
          )
        }

        // Process licensees in bulk
        const res = await API.graphql(
          graphqlOperation(bulkLicenseeMutation, {
            data: batchLicenseeList,
          })
        )

        const results = res.data.bulkLicenseeMutation

        // Loop through results and retrieve successful entries and add errors to list to be exported
        for (let bulkItem of results) {
          if (bulkItem.error) {
            // Retrieve excel data from row using the returned index
            const rowData = numberedBulkLicensees.find(
              l => l.index === bulkItem.index
            )
            if (rowData) {
              // Add the error to the list to be exported
              let errorReason =
                bulkItem.error === 'DUPLICATE'
                  ? `Licensee with the same name already exists in the system.`
                  : `An error occurred while processing this row, please review the values and try again.`
              licenseesWithError.unshift(
                createErrorObject(rowData, errorReason)
              )
            }
          } else {
            addedLicensees.push({
              ...bulkItem.result,
              index: bulkItem.index,
              type: bulkItem.type,
            })
            // Update list of licensees where the license category has been updated to notify their admins
            if (
              bulkItem.type === 'UPDATE' &&
              bulkItem.adminsToNotify.length > 0
            ) {
              categoryChangedBulkEmails.push({
                to: bulkItem.adminsToNotify.join(','),
                message: licenseCategoryUpdateTemplate(),
              })
            }
          }
        }

        progressCount += batchSize
        bufferSize = Math.floor(
          ((progressCount + batchSize) / bulkListSize) * 100
        )
        dispatch(
          setLoadingProgress({
            value: Math.floor((progressCount / bulkListSize) * 100),
            buffer: bufferSize >= 100 ? 100 : bufferSize,
          })
        )
      }

      // -----------------------------------------------------------------
      // ------------------ SETUP AND SEND BULK EMAILS -------------------
      // -----------------------------------------------------------------

      // Get added (no emails on updates) licensees with emails only (if they're on this list they're already validated)
      let licenseesToEmail = addedLicensees.filter(
        licensee => licensee.email !== '' && licensee.type === 'ADD'
      )

      let registrationEmailsSent = false
      // Send LE account registration emails
      if (licenseesToEmail.length > 0) {
        let bulkEmailMessages = []

        // Add extra data needed for emails (license type labels, addresses & uniqueId)
        licenseesToEmail = licenseesToEmail.map(licensee => {
          // Retrieve data from validated list
          const licenseeData = finalLicenseesList.find(
            l => l.index === licensee.index
          )
          if (licenseeData) {
            let extendedData = {
              ...licenseeData,
              ...licensee,
              licenseTypes: licenseeData.licenseTypes.map(
                lt =>
                  licenseTypes.filter(
                    type => parseInt(type.value) === parseInt(lt)
                  )[0]
              ),
            }
            if (extendedData.isMailingSameAsPremises)
              extendedData.mailingAddress = extendedData.premisesAddress

            // Add message to bulk list
            bulkEmailMessages.push({
              to: extendedData.email,
              message: leAccountRegistrationTemplate(extendedData),
            })
            return extendedData
          } else return licensee
        })

        const params = {
          body: {
            subject: 'ABC Price Posting - Account Registration',
            messages: JSON.stringify(bulkEmailMessages),
            buttonLink: leSignUpLink,
            buttonText: 'Complete Registration',
          },
        }
        API.post('ABC-API', '/send-bulk-emails', params)
        registrationEmailsSent = true
      }

      // Notify admins of licensees where the license category has been updated
      if (categoryChangedBulkEmails.length > 0) {
        const params = {
          body: {
            subject: 'ABC Price Posting - License Type Change',
            messages: JSON.stringify(categoryChangedBulkEmails),
            buttonLink: pricePostingURL,
            buttonText: 'Visit Price Posting Application',
          },
        }
        API.post('ABC-API', '/send-bulk-emails', params)
      }

      // Update user with any success/error messages

      dispatch(
        setLoadingProgress({
          value: 100,
          buffer: 100,
        })
      )

      if (licenseesWithError.length > 0) {
        dispatch(
          showError(
            `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
          )
        )
        exportToExcel(licenseesWithError, 'Failed adding licensees')
      } else if (addedLicensees.length > 0 && !licenseesWithError.length > 0) {
        dispatch(
          showSuccess(
            `Licensees bulk upload was successful${
              registrationEmailsSent
                ? ', notifications have been sent to the provided emails.'
                : '.'
            }`
          )
        )
      }

      await sleep(300)
      dispatch(setLoading(false))
      dispatch(setLoadingProgress(null))
      dispatch(getListOfLegalEntities())
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(setLoadingProgress(null))
      dispatch(
        showError(
          `An error occurred during the bulk upload process, please make sure your file follows the template requirements and try again.`
        )
      )
    }
  }
}

export const printRegOfSelectedLicensees = resolve => {
  return async (dispatch, getState) => {
    dispatch(
      setLoading(
        'Exporting registrations of selected licensees, please wait...'
      )
    )
    const legalEntityState = getState().legalEntity
    const orderRule = `${legalEntityState.orderBy}_${legalEntityState.order}`
    const graphQLParams = {
      where: {
        id_in: legalEntityState.bulkSelected.map(item => parseInt(item.id)),
      },
      orderBy: orderRule,
    }
    await API.graphql(
      graphqlOperation(getLegalEntitiesForBulkPrinting, graphQLParams)
    )
      .then(response => {
        let newResponse = []
        if (response.data.legalEntities.count > 0) {
          newResponse = response.data.legalEntities.results.map(leDetails => {
            let resData = {
              ...leDetails,
              licenseTypes: leDetails.licenseTypes.map(lt => ({
                ...lt,
                label:
                  lt.number && lt.number.length > 0
                    ? `${lt.number} - ${lt.label}`
                    : lt.label,
              })),
              isMailingSameAsPremises: false,
              userSignUpLink: leSignUpLink,
            }
            if (
              leDetails.mailingAddress &&
              leDetails.mailingAddress.id &&
              leDetails.premisesAddress &&
              leDetails.premisesAddress.id === leDetails.mailingAddress.id
            ) {
              resData = {
                ...resData,
                mailingAddress: leDetails.mailingAddress,
                isMailingSameAsPremises: true,
              }
            }
            return resData
          })
        }
        dispatch(setBulkPrintingLEs(newResponse))
        resolve() //this will resolve the promise, which started in the onBeforeGetContent prop of the React-to-print
      })
      .catch(errorReason => {
        dispatch(
          showError(
            'Unable to fetch licensee registrations, please check your internet connection and try again. '
          )
        )
      })
    dispatch(setLoading(false))
  }
}

// ---------------- D E A C T I V A T E   L I C E N S E E ----------------

const batchingHelperForUserStatusChange = async (
  usersList,
  statusChangeTo,
  currUserId,
  dispatch,
  isLEDeactivation
) => {
  // Separate users list into batches of 10 to allow progress to be shown and remove overloading the server

  const userListSize = usersList.length,
    batchSize = 10
  let successfullyDisabledUsers = [],
    failedDisablingUsers = [],
    progressCount = 0,
    bufferSize = Math.floor((batchSize / userListSize) * 100)
  dispatch(
    setLoadingProgress({
      value: 0,
      buffer: bufferSize >= 100 ? 100 : bufferSize,
    })
  )
  // Send to API list in batches
  while (progressCount <= userListSize) {
    let batchUsersList
    // If current batch is less than the max batch size send the remaining items on the list
    if (userListSize <= batchSize) batchUsersList = usersList
    else {
      batchUsersList = usersList.slice(progressCount, progressCount + batchSize)
    }

    // Process user status in bulk
    const userStatusChangeParams = {
      body: {
        option: statusChangeTo,
        userIds: batchUsersList,
        updatedBy: currUserId,
        isLEDeactivation,
      },
    }
    await API.post('ABC-API', '/update-user-status', userStatusChangeParams)
      .then(res => successfullyDisabledUsers.push(res.status))
      .catch(err => failedDisablingUsers.push(err.message))

    progressCount += batchSize
    bufferSize = Math.floor(((progressCount + batchSize) / userListSize) * 100)
    dispatch(
      setLoadingProgress({
        value: Math.floor((progressCount / userListSize) * 100),
        buffer: bufferSize >= 100 ? 100 : bufferSize,
      })
    )
  }
  return successfullyDisabledUsers.length > 0
    ? 'Success'
    : failedDisablingUsers.length > 0
    ? 'Error'
    : ''
}

export const deactivateLicensee = licenseeId => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Deactivating licensee, please wait...'))
    const currUser = getState().auth.user
    const licenseeState = getState().legalEntity
    const currUserId = parseInt(currUser.id)
    licenseeId = parseInt(licenseeId)

    // Retrieve all users from this licensee
    const usersRes = await API.graphql(
      graphqlOperation(getUsersQuery, {
        where: { legalEntityId: licenseeId },
        orderBy: 'createdAt_ASC',
        limit: 1000,
        offset: 0,
      })
    )

    if (usersRes.data.users) {
      const users = usersRes.data.users.results

      // Retrieve admin users emails to notify them about the deactivation
      const adminUsers = users.filter(
        user => user.role.name === 'Licensee Admin' && user.email !== ''
      )
      const adminEmails = adminUsers.map(user => user.email).join(',')

      // Disable all licensee users (set status to Inactive in DB and Disabled in Cognito)
      const usersToBeDisabled = users.map(user => user.id)
      const batchingProcess = await batchingHelperForUserStatusChange(
        usersToBeDisabled,
        'DISABLE',
        currUserId,
        dispatch,
        true
      )

      // Deactivate licensee
      let deactivationWasSuccess
      await API.graphql(
        graphqlOperation(deactivateLicenseeMutation, {
          id: licenseeId,
          updatedBy: currUserId,
        })
      )
        .then(response => {
          deactivationWasSuccess = response.data.deactivateLicensee.success
          //if the deactivation is performed from  detail view
          if (licenseeState.isLeAccountShown) {
            //Update the status
            const newLegalEntityDetails = {
              ...licenseeState.legalEntityDetails,
              status: 'Inactive',
            }
            dispatch(setLegalEntityDetails(newLegalEntityDetails))
          }
        })
        .catch(errorRes => {
          dispatch(
            showError(
              'An issue occurred while deactivating the licensee, please try again.'
            )
          )
        })

      if (deactivationWasSuccess) {
        let currLEDetails
        if (licenseeId) {
          await dispatch(fetchLegalEntityByID(licenseeId, false, false))
          currLEDetails = getState().legalEntity.legalEntityDetails
        }
        // Notify Licensee Admins via email
        if (adminUsers.length > 0) {
          const params = {
            body: {
              to: adminEmails,
              subject: 'ABC Price Posting - Licensee Deactivated',
              message: licenseeDeactivatedTemplate(currLEDetails.name),
            },
          }
          API.post('ABC-API', '/send-email', params)
        }

        if (batchingProcess === 'Success') {
          dispatch(
            showSuccess('Deactivation of licensee account was successful.')
          )
        } else if (batchingProcess === 'Error') {
          dispatch(
            showWarning(
              'Deactivation of licensee account was successful, but there were some issue when trying to deactivate one or more users. Try to deactivate them manually or contact IT. Thank you.'
            )
          )
        }

        dispatch(getListOfLegalEntities())
      } else {
        dispatch(
          showError(
            'An issue occurred while deactivating the licensee, please try again.'
          )
        )
      }
    }
    dispatch(setLoading(false))
  }
}

// ---------------- R E A C T I V A T E   L I C E N S E E ----------------

export const reactivateLicensee = licenseeId => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Reactivating licensee, please wait...'))
    dispatch(setInactiveLicenseeFound(null))
    dispatch(setLeAccountShown(false))
    dispatch(setEditMode(false))

    const currUser = getState().auth.user
    const licenseeState = getState().legalEntity
    const currUserId = parseInt(currUser.id)
    licenseeId = parseInt(licenseeId)

    // If licensee name isn't assigned, look through existing licensees and find the matching id
    let licenseeName = licenseeState.legalEntityDetails.name
    if (licenseeName.length === 0) {
      const findLicensee = licenseeState.legalEntities.results.find(
        licensee => licensee.id === `${licenseeId}`
      )
      if (findLicensee) licenseeName = findLicensee.name
    }

    // Check for duplicate licensees (Active | Pending)
    let queryParams = {
      name_lower: licenseeName,
      id_not: licenseeId,
      status_in: ['Active', 'Pending'],
    }

    // Check that Active | Pending licensees with the same name doesn't already exist in the system
    const duplicateRes = await API.graphql(
      graphqlOperation(findLegalEntityQuery, { where: queryParams })
    )

    if (duplicateRes.data.legalEntities.count > 0) {
      dispatch(
        showError(
          'Cannot reactivate licensee since an active/pending licensee with the same name already exists.'
        )
      )
    } else {
      let licenseeAdmins = null
      // Retrieve all the licensee admins associated to this account
      await API.graphql(
        graphqlOperation(getUsersQuery, {
          where: { legalEntityId: licenseeId, roleId: 4 },
          orderBy: 'createdAt_ASC',
          limit: 1000,
          offset: 0,
        })
      )
        .then(retrieveAdminsRes => {
          licenseeAdmins = retrieveAdminsRes.data.users.results
        })
        .catch(errorRes => {
          dispatch(
            showError(
              'An issue occurred when trying to reactivate the licensee admins, please check you internet connection and try again. Thank you.'
            )
          )
        })
      // Reactivate the licensee account
      if (licenseeAdmins) {
        let reactivationLicenseeSuccess = null
        await API.graphql(
          graphqlOperation(reactivateLicenseeMutation, {
            id: licenseeId,
            updatedBy: currUserId,
          })
        )
          .then(res => {
            reactivationLicenseeSuccess = res.data.reactivateLicensee.success
          })
          .catch(errorRes => {
            dispatch(
              showError(
                'An issue occurred when reactivating the licensee, please check your internet connection and try again. Thank you.'
              )
            )
          })
        let batchingProcess = ''
        if (licenseeAdmins.length > 0) {
          let currLEDetails
          if (licenseeId) {
            await dispatch(fetchLegalEntityByID(licenseeId, false, false))
            currLEDetails = getState().legalEntity.legalEntityDetails
          }
          // Notify Licensee Admins via email
          const adminEmails = licenseeAdmins.map(user => user.email).join(',')
          const params = {
            body: {
              to: adminEmails,
              subject: 'ABC Price Posting - Licensee Reactivated',
              message: licenseeReactivatedTemplate(currLEDetails.name),
              buttonLink: pricePostingURL,
              buttonText: 'Visit Price Posting Application',
            },
          }
          API.post('ABC-API', '/send-email', params)
          //Enable all the licensee admins
          const adminsToBeEnabled = licenseeAdmins.map(admin => admin.id)
          batchingProcess = await batchingHelperForUserStatusChange(
            adminsToBeEnabled,
            'ENABLE',
            currUserId,
            dispatch
          )
        }
        if (reactivationLicenseeSuccess) {
          dispatch(getListOfLegalEntities())
          //if the reactivation is performed from  detail view
          if (licenseeState.isLeAccountShown) {
            //Update the status
            const newLegalEntityDetails = {
              ...licenseeState.legalEntityDetails,
              status: 'Active',
            }
            dispatch(setLegalEntityDetails(newLegalEntityDetails))
          }
          if (batchingProcess === 'Success') {
            dispatch(
              showSuccess('Reactivation of licensee account was successful.')
            )
          } else if (batchingProcess === 'Error') {
            dispatch(
              showWarning(
                'Reactivation of licensee account was successful, but there was some issue when trying to reactivate one or more admins. Try to deactivate them manually or contact IT. Thank you.'
              )
            )
          }
        } else {
          dispatch(
            showError(
              'An issue occurred when reactivating the licensee, please check your internet connection and try again. Thank you.'
            )
          )
        }
      } else {
        dispatch(
          showError(
            'An issue occurred when reactivating the licensee, please check your internet connection and try again. Thank you.'
          )
        )
      }
    }
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setLePageNumber(0))
    dispatch(setLegalEntitiesFilters(initialState.legalEntitiesFilters))
    dispatch(getListOfLegalEntities())
  }
}

const initialState = {
  legalEntityDetails: {
    name: '',
    licensed: true,
    email: '',
    status: '',
    previous_status: '',
    licenseCategory: '',
    licenseTypes: [],
    premisesAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'United States',
    },
    mailingAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'United States',
    },
    isMailingSameAsPremises: true,
    inLitigation: false,
  },
  legalEntitiesFilters: {
    licensee: '',
    licenseTypes: [],
    licenseCategory: '',
    createdAt: null,
    operatorForLicenseType: 'or',
    operatorForCreated: 'equal',
    operatorForUpdated: 'equal',
    updatedAt: null,
    status: [],
    licensed: { value: 'Both', label: 'Both' },
    email: '',
    isEmailEmpty: false,
    litigation: { value: 'Both', label: 'Both' },
  },
  legalEntities: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  bulkSelected: [],
  isLegalEntityFilterOpen: false,
  isLeAccountShown: false,
  licenseeBulkUploadModal: false,
  bulkPrintingLEs: [],
  isDeactivationConfirmationOpen: false,
  isReactivationConfirmationOpen: false,
  inactiveLicenseeFound: null,
  confirmationForLinkingProducts: false,
}

export default createReducer(initialState, {
  [setLegalEntityDetails]: (state, action) => {
    state.legalEntityDetails = action.payload
  },
  [setLegalEntities]: (state, action) => {
    state.legalEntities = action.payload
  },
  [setLePageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setLeRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setLeRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setLeBulkSelection]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setLegalEntitiesFilters]: (state, action) => {
    state.legalEntitiesFilters = action.payload
  },
  [setIsLegalEntityFilterOpen]: (state, action) => {
    state.isLegalEntityFilterOpen = action.payload
  },
  [setLeAccountShown]: (state, action) => {
    state.isLeAccountShown = action.payload
  },
  [setLicenseeBulkUploadModal]: (state, action) => {
    state.licenseeBulkUploadModal = action.payload
  },
  [setBulkPrintingLEs]: (state, action) => {
    state.bulkPrintingLEs = action.payload
  },
  [setDeactivationConfirmation]: (state, action) => {
    state.isDeactivationConfirmationOpen = action.payload
  },
  [setReactivationConfirmation]: (state, action) => {
    state.isReactivationConfirmationOpen = action.payload
  },
  [setInactiveLicenseeFound]: (state, action) => {
    state.inactiveLicenseeFound = action.payload
  },
  [setConfirmationForLinkingProducts]: (state, action) => {
    state.confirmationForLinkingProducts = action.payload
  },
  [resetLegalEntities]: state => {
    state.legalEntityDetails = initialState.legalEntityDetails
    state.legalEntitiesFilters = initialState.legalEntitiesFilters
    state.legalEntities = initialState.legalEntities
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.isLegalEntityFilterOpen = initialState.isLegalEntityFilterOpen
    state.isLeAccountShown = initialState.isLeAccountShown
    state.licenseeBulkUploadModal = initialState.licenseeBulkUploadModal
    state.bulkPrintingLEs = initialState.bulkPrintingLEs
    state.isDeactivationConfirmationOpen =
      initialState.isDeactivationConfirmationOpen
    state.isReactivationConfirmationOpen =
      initialState.isReactivationConfirmationOpen
  },
})
