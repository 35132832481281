import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TablePagination,
  IconButton,
  Checkbox,
  TableSortLabel,
  Typography,
  Fade,
  Collapse,
  Box,
  Link,
  Divider,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core'
import styled from 'styled-components'
import { rgba } from 'polished'
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons'

const HeaderTitle = styled.span`
  color: #000;
`

const LastPageButtonsWrapper = styled.div`
  display: flex;
`

const CustomTableRow = styled(TableRow)`
  && {
    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.light} !important;
    }
  }
`
const SelectAllWrapper = styled(TableCell)`
  && {
    border-bottom: 2px solid ${props => props.theme.gold};
    background-color: #fff;
    line-height: 3rem;
  }
`

const HeaderTableCell = styled(TableCell)`
  && {
    border-bottom: 2px solid ${props => props.theme.gold};
    background-color: #fff;
    line-height: 1.2rem;
  }
`

const SortingLabel = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`
const BodyTableCell = styled(TableCell)`
  max-width: 200px;
  overflow-wrap: break-word;
`

const SortedByLabel = styled(TableSortLabel)`
  && {
    color: ${props => props.theme.gold} !important;
  }
  &&:focus {
    outline: 1px dotted #666;
  }
`

const EmptyState = styled.div`
  text-align: center;
  padding: 20px;
`

const TableWrapper = styled.div`
  && {
    overflow-x: auto;
    max-height: 65vh;
    width: 100%;

    .MuiTableCell-root {
      padding: 10px 8px;
    }
  }
`

const Arrow = styled.div`
  min-height: 0px;
  content: '';
  position: relative;
  left: 8px;
  width: 0;
  border-top: solid 15px #fbae22;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
  border-bottom: solid 0px transparent;
  z-index: 32;
`

const SelectedCount = styled(Typography)`
  && {
    padding-left: 2px;
    padding-right: 8px;
    padding-top: 4px;
    font-size: 15px;
  }
`
const SelectedRowsBanner = styled(Box)`
  && {
    background-color: ${props => props.theme.gold};
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
  }
`
const CustomCollapse = styled(Collapse)`
  && {
    width: 100%;
  }
`

const CustomTablePagination = styled(TablePagination)`
  && {
    min-height: 50px;
    color: #595959;

    .MuiIconButton-root {
      color: ${props => props.theme.blue && rgba(props.theme.blue, 0.7)};
    }
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
    .MuiSelect-icon {
      color: ${props => props.theme.blue && rgba(props.theme.blue, 0.7)};
    }
  }
`
const PaginationWrapper = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    color: #595959;
  }
`
const FetchingText = styled.span`
  && {
    display: flex;
    align-items: center;
    padding: 15px;
  }
`

const SelectionInfo = styled.span`
  display: flex;
`
const LinkForSelectionBanner = styled(Link)`
  && {
    padding-left: 5px;
    padding-top: 5px;
  }
`
const StyledDivider = styled(Divider)`
  && {
    margin-left: 5px;
    margin-top: 5px;
    background-color: ${props => props.theme.primary};
    width: 1.6px;
    height: 25px;
    border-radius: 2px;
  }
`

const FadeWrapper = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    color: #595959;
  }
`

const PageInfo = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    margin-right: 15px !important;
    color: inherit;
    font-size: 13px !important;
  }
`
const PageSelect = styled(Select)`
  && {
    text-align: right;
    text-align-last: right;
    margin: 0px;
    padding: 0px 0px 0px 0px;
    font-size: 13px !important;
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
    .MuiSelect-icon {
      color: ${props => props.theme.blue && rgba(props.theme.blue, 0.7)};
    }
  }
`

const ITEM_HEIGHT = 25
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
}

const PageSelectMenuItem = styled(MenuItem)`
  && {
    margin: 0px;
    padding: 0px 0px 0px 0px;
    justify-content: flex-end;
    font-size: 13px !important;
    color: #595959;

    .Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
`

const range = (start, end) => {
  let length = end - start + 1
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start)
}

const ReusableTable = ({
  headers,
  tableData,
  pagination,
  selectionOption,
  totalCount,
  fetchingTotalCount,
  onSingleItemSelected,
  handleBulkSelection,
  setPageNumber,
  setRowsPerPage,
  setRequestedSort,
  pageNumber,
  rowsPerPage,
  order,
  orderBy,
  bulkSelected,
  canChangeRowsPerPage,
  handleSelectAllRows,
  handleClearSelection,
  actionItemsOnSelection,
  noRowsFoundMessage,
}) => {
  const { isLoading } = useSelector(store => store.global)
  const singlePageSelectedIDs = tableData.map(n => n.id)
  const bulkSelectedIDs = bulkSelected.map(d => d.id)

  const totalPageCount = Math.ceil(totalCount / rowsPerPage)
  const pageRange = range(1, totalPageCount)
  const selectDisabled = totalPageCount === 0 ? true : false

  const handleSelectAllItemsOnPage = event => {
    if (event.target.checked) {
      handleBulkSelection('selectAll')
    } else {
      handleBulkSelection('unselectAll')
    }
  }

  const handleClick = (event, data, index) => {
    event.persist()
    if (event.target.id.includes('listItemSelection')) {
      handleBulkSelection(data)
    } else {
      onSingleItemSelected(data, index)
    }
  }

  const isSelected = id => bulkSelectedIDs.indexOf(id) !== -1
  const bulkSelectedLength = bulkSelected.length
  const currentPageRowCount = tableData.length
  const orderForMUITable = order === 'DESC' ? 'desc' : 'asc'
  return (
    <>
      <>
        <CustomCollapse
          in={bulkSelectedLength > 0}
          children={
            <>
              <SelectedRowsBanner component='div'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <SelectionInfo>
                      <SelectedCount
                        component='div'
                        variant='h6'
                        children={`${bulkSelectedLength} Row${
                          bulkSelectedLength > 1 ? 's' : ''
                        } Selected`}
                      />
                      {bulkSelectedLength !== totalCount && (
                        <>
                          <LinkForSelectionBanner
                            component='button'
                            variant='button'
                            onClick={() => {
                              handleSelectAllRows()
                            }}
                          >
                            Select all
                          </LinkForSelectionBanner>
                          <StyledDivider orientation='vertical' />
                        </>
                      )}
                      <LinkForSelectionBanner
                        component='button'
                        variant='button'
                        onClick={() => {
                          handleClearSelection()
                        }}
                      >
                        Clear All
                      </LinkForSelectionBanner>
                    </SelectionInfo>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={6}>
                    {actionItemsOnSelection}
                  </Grid>
                </Grid>
              </SelectedRowsBanner>
              {bulkSelectedLength > 0 && <Arrow />}
            </>
          }
        />

        <TableWrapper>
          <Table
            stickyHeader={!isLoading && currentPageRowCount !== 0 && true}
            padding='checkbox'
            size={canChangeRowsPerPage ? 'medium' : 'small'}
          >
            <TableHead>
              <TableRow>
                {selectionOption && (
                  <SelectAllWrapper padding='checkbox'>
                    <Checkbox
                      color='primary'
                      indeterminate={
                        bulkSelectedLength > 0 && bulkSelectedLength < currentPageRowCount
                      }
                      checked={singlePageSelectedIDs.every(id => bulkSelectedIDs.includes(id))} //checks if bulkSelected array includes current page IDs
                      onChange={handleSelectAllItemsOnPage}
                      inputProps={{ 'aria-label': 'select all rows' }}
                    />
                  </SelectAllWrapper>
                )}
                {headers.map(headCell => (
                  <HeaderTableCell
                    key={headCell.id}
                    align='center'
                    padding='default'
                    sortDirection={orderBy === headCell.id ? orderForMUITable : false}
                  >
                    {headCell.isSortable ? (
                      <SortedByLabel
                        active={orderBy === headCell.id}
                        direction={orderForMUITable}
                        onClick={event => setRequestedSort(headCell.id)}
                      >
                        <HeaderTitle>{headCell.label}</HeaderTitle>
                        {orderBy === headCell.id ? (
                          <SortingLabel>
                            {order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                          </SortingLabel>
                        ) : null}
                      </SortedByLabel>
                    ) : (
                      <HeaderTitle>{headCell.label}</HeaderTitle>
                    )}
                  </HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <Fade in={!isLoading} timeout={{ enter: 800, exit: 800 }}>
              <TableBody>
                {tableData.map((data, index) => {
                  let arrayOfKeys = Object.keys(data)
                  arrayOfKeys.shift()
                  const isItemSelected = isSelected(data.id)
                  const labelId = `Select row-${index}`
                  return (
                    <CustomTableRow
                      hover
                      onClick={event => handleClick(event, data, index)}
                      role='checkbox'
                      aria-checked={selectionOption && isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={selectionOption && isItemSelected}
                    >
                      {selectionOption && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-label': labelId,
                              id: `listItemSelection-${index}`,
                            }}
                          />
                        </TableCell>
                      )}
                      {arrayOfKeys.map((eachKey, index) => (
                        <BodyTableCell
                          padding='default'
                          align='center'
                          key={index}
                          className={
                            eachKey === 'status' &&
                            data[eachKey] &&
                            typeof data[eachKey] === 'string'
                              ? data[eachKey].toLowerCase()
                              : ''
                          }
                        >
                          {data[eachKey]}
                        </BodyTableCell>
                      ))}
                    </CustomTableRow>
                  )
                })}
              </TableBody>
            </Fade>
          </Table>
          {!isLoading && currentPageRowCount === 0 ? (
            <EmptyState className='noResultsFoundWrapper'>{noRowsFoundMessage}</EmptyState>
          ) : (
            ''
          )}
        </TableWrapper>
      </>

      <Divider orientation='horizontal' />
      {pagination && (
        <PaginationWrapper>
          {fetchingTotalCount ? (
            <FetchingText>
              <span>
                <Typography variant='subtitle2' children='Fetching total number of pages...' />
              </span>
            </FetchingText>
          ) : (
            <>
              <Fade in={pagination} timeout={{ enter: 800, exit: 800 }}>
                <FadeWrapper>
                  <PageInfo component='div'>
                    Total Pages: &nbsp;&nbsp;{totalPageCount}&nbsp;
                  </PageInfo>
                  <PageInfo component='div'>Go to Page:</PageInfo>
                  <PageSelect
                    id='pageNumSelect'
                    value={pageNumber + 1}
                    label='Page Number'
                    onChange={event => setPageNumber(event.target.value - 1)}
                    disabled={selectDisabled}
                    MenuProps={MenuProps}
                  >
                    {pageRange.map((e, idx) => (
                      <PageSelectMenuItem
                        key={idx}
                        value={e}
                        selected={pageNumber + 1 === e ? true : false}
                      >
                        {e}
                      </PageSelectMenuItem>
                    ))}
                  </PageSelect>
                  <CustomTablePagination
                    rowsPerPageOptions={canChangeRowsPerPage ? canChangeRowsPerPage : [10]}
                    component='div'
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={(event, newPage) => setPageNumber(newPage)}
                    onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
                    ActionsComponent={TablePaginationActions}
                  />
                </FadeWrapper>
              </Fade>
            </>
          )}
        </PaginationWrapper>
      )}
    </>
  )
}

const TablePaginationActions = props => {
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <LastPageButtonsWrapper>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        <LastPage />
      </IconButton>
    </LastPageButtonsWrapper>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

ReusableTable.defaultProps = {
  noRowsFoundMessage: 'No results found, please try updating the filters.',
}

export default ReusableTable
