import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Fade,
  Avatar,
} from '@material-ui/core'
import styled from 'styled-components'
import { darken } from 'polished'
import { useDispatch, useSelector } from 'react-redux'
import NewsUpdates from 'components/common/NewsUpdates'
import HeroBanner from 'components/common/HeroBanner'
import { getNewsUpdates } from 'ducks/public/newsUpdate'
import { publicSidebarRoutes } from 'components/routes/sidebarRoutes'
import LoadingIndicator from 'components/common/LoadingIndicator'

const Wrapper = styled(Grid)`
  padding: 340px 5% 5%;
  margin: auto;
  max-width: 1200px;
  @media (max-width: 980px) {
    padding-top: 310px;
  }
  @media (max-width: 600px) {
    padding-top: 210px;
  }
`

const CustomGrid = styled(Grid)`
  margin: 0 auto;
  && h4 {
    margin-top: 16px;
  }
`

const CustomListItem = styled(ListItem)`
  &:hover {
    border-radius: 5px;
  }
`

const CustomListItemText = styled(ListItemText)`
  && span + p {
    color: rgba(0, 0, 0, 0.87);
  }
`

const CustomAvatar = styled(Avatar)`
  background-color: white;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  margin-right: 10px;
`

const Title = styled(Typography)`
  font-size: 1.1rem;
  color: ${props => props.theme.blue};
  &&[bold] {
    color: ${props => darken(0.1, props.theme.blue)};
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: 20px;
  }
`

const PublicDashboard = () => {
  const dispatch = useDispatch()
  const { newsUpdateResults } = useSelector(store => store.newsUpdate)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getNewsUpdates())
  }, [dispatch])

  const ListItemLink = ({ title, description, link, image }) => {
    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <Link to={link} {...itemProps} ref={ref} />
        )),
      [link]
    )

    return (
      <CustomListItem
        divider
        button
        component={renderLink}
        alignItems='flex-start'
      >
        <ListItemAvatar>
          <CustomAvatar alt='Dashboard Icon' src={image} />
        </ListItemAvatar>
        <CustomListItemText
          primary={<Title>{title}</Title>}
          secondary={description}
        />
      </CustomListItem>
    )
  }

  return newsUpdateResults.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <Fade in timeout={{ enter: 1600, exit: 800 }}>
      <Wrapper container spacing={1}>
        <HeroBanner />
        <CustomGrid item xs={12} sm={6}>
          <Title variant='h4' component='h2' bold='bold'>
            Beer Price Posting
          </Title>
          <Typography
            variant='body1'
            component={'span'}
            children={
              <>
                <p>You can view products and prices without an account.</p>
                <p>
                  If you are required to post prices and have not received your
                  registration information or if you have general questions,
                  please contact the Price Posting Support Team at{' '}
                  <a href='mailto:pps@abc.ca.gov'>pps@abc.ca.gov</a>.
                </p>
              </>
            }
          />
          <br />
          <NewsUpdates newsList={newsUpdateResults} />
        </CustomGrid>
        <CustomGrid item xs={12} sm={1}></CustomGrid>
        <CustomGrid item xs={12} sm={5}>
          <List>
            {publicSidebarRoutes.map(item => (
              <React.Fragment key={item.label}>
                <ListItemLink
                  title={item.label}
                  description={item.description}
                  link={item.to}
                  image={item.image}
                />
              </React.Fragment>
            ))}
          </List>
        </CustomGrid>
      </Wrapper>
    </Fade>
  )
}
export default PublicDashboard
