import React from 'react'
import { AppBar, Link, Toolbar } from '@material-ui/core'
import styled from 'styled-components'

const Footer = styled(AppBar)`
  && {
    background-color: #fff;
    top: auto;
    bottom: 0;
    border-top: 4px solid ${props => props.theme.gold};
    z-index: 5;
  }
`

const FooterContent = styled(Toolbar)`
  && {
    min-height: 50px;
  }
`

const Devider = styled('div')`
  && {
    border-left: 2px solid ${props => props.theme.blue};
    margin-left: 4px;
    margin-right: 2px;
  }
`

const PublicFooter = props => {
  return (
    <Footer position='fixed'>
      <FooterContent>
        <Link
          target='_blank'
          href='https://www.abc.ca.gov/conditions-of-use/'
          variant='body1'
        >
          Conditions of Use
        </Link>
        <Devider>&nbsp;</Devider>
        <Link
          target='_blank'
          href='https://www.abc.ca.gov/privacy-policy/'
          variant='body1'
        >
          Privacy Policy
        </Link>
      </FooterContent>
    </Footer>
  )
}

export default PublicFooter
