import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core'
import ButtonWithDisabledInfo from 'components/common/ButtonWithDisabledInfo'
import {
  InsertDriveFile,
  MoreVert,
  PictureInPicture,
  Block,
  Edit,
  Delete,
} from '@material-ui/icons'
import LoadingIndicator from 'components/common/LoadingIndicator'
import styled from 'styled-components'
import ViewPricePostingsSchedule from './ViewPricePostingsSchedule'
import EditPricePostingSchedule from './EditPricePostingSchedule'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import { AbilityContext, Can } from 'common/abilityContext'
import { addEditBulkOn } from 'common/constants'
import {
  getPricePostingsScheduleList,
  getPricePostingsScheduleListForBulkSelection,
  setPricePostingsSchedulePageNumber,
  setPricePostingsScheduleRowsPerPage,
  setPricePostingsScheduleRequestedSort,
  setPricePostingScheduleShow,
  fetchPricePostingScheduleByID,
  setSinglePricePostingScheduleSelected,
  resetPricePostingScheduleDetails,
  setPPSBulkUploadDialog,
  setPricePostingScheduleBulkFilename,
  setPricePostingScheduleConfirmation,
  setFailedPricePostingsScheduleDuringBulk,
  setPricePostingScheduleItems,
  bulkPricePostingScheduleProcess,
  deleteStagedPostingByID,
  deleteStagedPostingsByIds,
  setPricePostingDeleteConfirmation,
  setPricePostingsScheduleBulkSelection,
  setPricePostingScheduleBulkDeletionConfirmation,
  fetchPricePostScheduleErrorFile,
} from 'ducks/pricePostingsSchedule'
import { setEditMode, showError, setLoading } from 'ducks/global'
import ConfirmationModal from 'components/common/ConfirmationModal'
import { CheckLicensee, exportToExcel, checkIfUserIsABC } from 'common/helper'
import { resetBulkUpload } from 'ducks/bulkUpload'
import PricePostingScheduleBulkUpload from './PricePostingScheduleBulkUpload'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 20px;
    padding: 6px 18px;
  }
  @media (max-width: 550px) {
    display: block;
    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const PricePostingsScheduleList = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    pricePostingsScheduleList,
    pricePostingsScheduleCount,
    singlePricePostingScheduleSelected,
    pricePostingScheduleDetails,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    pricePostingScheduleItems,
    pricePostingScheduleConfirmation,
    isPricePostingScheduleShown,
    isPPSBulkUploadDialogOpen,
    failedPricePostingsScheduleDuringBulk,
    pricePostingDeleteConfirmation,
    pricePostingScheduleBulkDeletionConfirmation,
    bulkSelected,
  } = useSelector(store => store.pricePostingsSchedule)

  const { loadingText, fetchingTotalCount } = useSelector(store => store.global)
  useEffect(() => {
    dispatch(getPricePostingsScheduleList())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  const { user } = useSelector(store => store.auth)
  const isUserAbc = user.role ? checkIfUserIsABC(user.role.id) : false
  console.debug('isUserAbc', isUserAbc)

  const abilityFunc = useContext(AbilityContext)

  /*----Table Header-----*/

  let pricePostingsScheduleHeaders = [
    { id: 'uploadName', label: 'File Name', isSortable: true },
    { id: 'scheduledDate', label: 'Scheduled Date', isSortable: true },
    { id: 'createdBy', label: 'Posted By', isSortable: true },
    { id: 'createdAt', label: 'Posted Date', isSortable: true },
    { id: 'manufacturerId', label: 'Licensee', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'errorFile', label: 'Error File', isSortable: false },
    { id: 'actions', label: 'Actions' },
  ]

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, lineItem) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    dispatch(setSinglePricePostingScheduleSelected(lineItem))
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(
      fetchPricePostingScheduleByID(singlePricePostingScheduleSelected.id)
    )
  }

  const handleEditSelected = () => {
    dispatch(setEditMode(true))
    setAnchorEl(null)
    dispatch(
      fetchPricePostingScheduleByID(singlePricePostingScheduleSelected.id, true)
    )
  }

  const handleDeleteSelected = () => {
    setAnchorEl(null)
    dispatch(setPricePostingDeleteConfirmation(true))
    //dispatch(deleteStagedPostingByID(singlePricePostingScheduleSelected.id))
  }

  const openAction = Boolean(anchorEl)

  const onSingleItemSelected = (pricePostingsSchedule, index) => {
    dispatch(fetchPricePostingScheduleByID(pricePostingsSchedule.id))
    dispatch(
      setSinglePricePostingScheduleSelected(
        pricePostingsScheduleList.results[index]
      )
    )
  }

  const handleBulkUpload = (bulkJSON, bulkFilename) => {
    dispatch(setPPSBulkUploadDialog(false))
    dispatch(setPricePostingScheduleConfirmation(true))
    dispatch(setPricePostingScheduleItems(bulkJSON))
    dispatch(setPricePostingScheduleBulkFilename(bulkFilename))
  }

  const handlePricePostingDeleteConfirmation = () => {
    dispatch(deleteStagedPostingByID(singlePricePostingScheduleSelected.id))
    dispatch(setPricePostingDeleteConfirmation(false))
  }

  const handlePricePostingDeleteCancel = () => {
    dispatch(setPricePostingDeleteConfirmation(false))
  }

  const handlePricePostingScheduleConfirmation = () => {
    if (pricePostingScheduleItems && pricePostingScheduleItems.length > 0) {
      dispatch(bulkPricePostingScheduleProcess(pricePostingScheduleItems))
    }
    dispatch(setPricePostingScheduleConfirmation(false))
    // dispatch(setPricePostingScheduleSuccess([]))
    dispatch(setPricePostingScheduleShow(false))

    if (failedPricePostingsScheduleDuringBulk.length > 0) {
      dispatch(
        showError(
          `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the schedule. Please fix the issue(s) and upload the new file.`
        )
      )
      exportToExcel(
        failedPricePostingsScheduleDuringBulk,
        'failedPricePostingSchedule'
      )
      dispatch(setFailedPricePostingsScheduleDuringBulk([]))
    }
  }

  const handlePricePostingScheduleConfirmationCancel = () => {
    dispatch(setPricePostingScheduleConfirmation(false))
    dispatch(setPricePostingScheduleShow(false))
    if (failedPricePostingsScheduleDuringBulk.length > 0) {
      dispatch(
        showError(
          `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the schedule. Please fix the issue(s) and upload the new file.`
        )
      )
      exportToExcel(
        failedPricePostingsScheduleDuringBulk,
        'failedPricePostingSchedule'
      )
      dispatch(setFailedPricePostingsScheduleDuringBulk([]))
    }
    dispatch(setLoading(false))
    dispatch(resetBulkUpload())
  }

  const handleRequestErrorFile = (event, objKey) => {
    event.stopPropagation()
    dispatch(fetchPricePostScheduleErrorFile(objKey))
  }

  let pricePostingDeleteConfirmationMessage = [
    'Are you sure you want to delete this scheduled price posting?',
  ]

  /*------PricePostings list----------*/
  const statuses = {
    SCH: 'Scheduled',
    COM: (
      <>
        <Typography
          style={{ color: 'green' }}
          variant='inherit'
          children={`Complete`}
        />
      </>
    ),
    ERR: (
      <>
        <Typography color='error' variant='inherit' children={`Error`} />
      </>
    ),
    QUE: 'In Queue',
  }

  const updatedPricePostingsScheduleList = pricePostingsScheduleList.results
    ? pricePostingsScheduleList.results.map(pricePostingSchedule => ({
        id: pricePostingSchedule.id,
        uploadName: pricePostingSchedule.uploadName,
        scheduledDate: format(pricePostingSchedule.scheduledDate, 'MM/dd/yyyy'),
        createdBy:
          pricePostingSchedule.createdBy.firstName +
          ' ' +
          pricePostingSchedule.createdBy.lastName,
        createdAt: format(pricePostingSchedule.createdAt, 'MM/dd/yyyy'),
        manufacturer: pricePostingSchedule.manufacturer.name,
        status: statuses[pricePostingSchedule.status],
        errorFile:
          pricePostingSchedule.numErrors > 0 ? (
            <>
              <IconButton
                aria-label='Errors'
                onClick={event =>
                  handleRequestErrorFile(event, pricePostingSchedule.uploadKey)
                }
                color='primary'
              >
                <InsertDriveFile color='error' />
              </IconButton>
            </>
          ) : (
            ''
          ),
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='true'
              onClick={event => handleActionOpen(event, pricePostingSchedule)}
              color='primary'
            >
              <MoreVert color='primary' />
            </IconButton>
          </>
        ),
      }))
    : []

  const handlePageNumber = newPage => {
    dispatch(setPricePostingsSchedulePageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setPricePostingsScheduleRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setPricePostingsScheduleRequestedSort(value))
  }
  const onQuickViewClosed = () => {
    dispatch(resetPricePostingScheduleDetails())
    dispatch(setPricePostingScheduleShow(false))
    dispatch(setEditMode(false))
  }
  const handleBulkUploadClicked = () => {
    dispatch(setPPSBulkUploadDialog(true))
  }
  const onBulkUploadModalClosed = () => {
    dispatch(setPPSBulkUploadDialog(false))
    dispatch(resetBulkUpload())
  }

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = pricePostingsScheduleList.results.map(
      n => n.id
    )
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          pricePostingsScheduleList.results.map(finalObject => ({
            id: finalObject.id,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = pricePostingsScheduleList.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }
    dispatch(setPricePostingsScheduleBulkSelection(newSelected))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getPricePostingsScheduleListForBulkSelection(false, true))
  }

  const handleClearSelection = () => {
    dispatch(setPricePostingsScheduleBulkSelection([]))
  }

  const handlePricePostingScheduleBulkDeleteClicked = () => {
    setAnchorEl(null)
    dispatch(setPricePostingScheduleBulkDeletionConfirmation(true))
  }

  const handlePricePostingScheduleBulkDeleteConfirmation = () => {
    dispatch(deleteStagedPostingsByIds(bulkSelected))
    dispatch(setPricePostingScheduleBulkDeletionConfirmation(false))
  }

  const handlePricePostingScheduleBulkDeleteCancel = () => {
    dispatch(setPricePostingScheduleBulkDeletionConfirmation(false))
  }

  const disableBulkSelectionDeleteButton = () => {
    for (const result of pricePostingsScheduleList.results) {
      for (const item of bulkSelected) {
        if (item.id === result.id && result.status !== 'SCH') {
          return true
        }
      }
    }
    return false
  }

  const canBulkDeleteSelected = () => {
    abilityFunc.can('delete', !isUserAbc)
  }

  const actionItemsOnSelection = (
    <Can
      I='perform'
      this={addEditBulkOn.pricePostingsSchedule}
      ability={canBulkDeleteSelected()}
    >
      {() => (
        <>
          <ActionWrapper>
            <ButtonWithDisabledInfo
              buttonDisabledShowTooltip={disableBulkSelectionDeleteButton()}
              tooltipTitle='Items with a status of “Error” or “Complete” cannot be deleted:'
              disabledReasons={[
                'Items with a status of “Error” or “Complete” cannot be deleted',
              ]}
              onClick={handlePricePostingScheduleBulkDeleteClicked}
              buttonLabel='Delete Price Postings Schedule'
              startIcon={<Delete />}
            />
          </ActionWrapper>
        </>
      )}
    </Can>
  )

  return pricePostingsScheduleList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Price Posting Scheduling Tool</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        <Can I='perform' this={addEditBulkOn.pricePostingsSchedule}>
          {() =>
            singlePricePostingScheduleSelected.status == 'SCH' &&
            abilityFunc.can(
              'edit/view',
              new CheckLicensee({
                ifCreatedByLicenseeIs:
                  singlePricePostingScheduleSelected.manufacturer &&
                  singlePricePostingScheduleSelected.manufacturer.id,
              })
            ) && (
              <>
                <Divider />
                <ActionItems onClick={handleEditSelected}>
                  <Edit color='primary' fontSize='small' />
                  <ActionLabels children='Edit' variant='body1' />
                </ActionItems>
                <Divider />
                <ActionItems onClick={handleDeleteSelected}>
                  <Block color='error' fontSize='small' />
                  <ActionLabels children='Delete' variant='body1' />
                </ActionItems>
              </>
            )
          }
        </Can>
      </Popover>
      <ListComponent
        mainTitle='Price Posting Scheduling Tool'
        listContent={
          <ReusableTable
            headers={pricePostingsScheduleHeaders}
            tableData={updatedPricePostingsScheduleList}
            pagination={true}
            selectionOption={true}
            totalCount={pricePostingsScheduleCount}
            fetchingTotalCount={fetchingTotalCount}
            onSingleItemSelected={onSingleItemSelected}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleBulkSelection={handleBulkSelection}
            handleClearSelection={handleClearSelection}
            handleSelectAllRows={handleSelectionOfAllRows}
            actionItemsOnSelection={actionItemsOnSelection}
          />
        }
        editComp={
          <EditPricePostingSchedule
            onCancel={onQuickViewClosed}
            pricePostingSchedule={pricePostingScheduleDetails}
          />
        }
        viewComp={
          <ViewPricePostingsSchedule
            processingStatus={statuses[pricePostingScheduleDetails.status]}
          />
        }
        modalEditTitle={`${
          pricePostingScheduleDetails.id
            ? 'Edit Price Posting Schedule'
            : 'Add Price Posting Schedule'
        }`}
        modalViewTitle='Price Posting Schedule Details'
        onModalClosed={onQuickViewClosed} //onQuickViewClosed}
        loadingText={loadingText}
        isDialogOpen={isPricePostingScheduleShown}
        onAddButtonClicked={''} //onAdd}
        isBulkUploadAvailable={true}
        bulkUploadContent={
          <PricePostingScheduleBulkUpload handleBulkUpload={handleBulkUpload} />
        }
        onBulkUploadClicked={handleBulkUploadClicked}
        bulkUploadTitle='Price Posting Schedule Bulk Upload'
        isBulkDialogOpen={isPPSBulkUploadDialogOpen}
        onBulkUploadModalClosed={onBulkUploadModalClosed}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePostingScheduleConfirmation}
        confirmationList={[
          'I understand that the prices I am scheduling will not be checked nor validated until the date I selected.',
        ]}
        onConfirmation={handlePricePostingScheduleConfirmation}
        onConfirmationModalClosed={handlePricePostingScheduleConfirmationCancel}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePostingDeleteConfirmation}
        confirmationList={pricePostingDeleteConfirmationMessage}
        onConfirmation={handlePricePostingDeleteConfirmation}
        onConfirmationModalClosed={handlePricePostingDeleteCancel}
      />
      <ConfirmationModal
        isConfirmationModalOpen={pricePostingScheduleBulkDeletionConfirmation}
        confirmationList={[
          'Are you sure you want to delete the selected items?',
        ]}
        onConfirmation={handlePricePostingScheduleBulkDeleteConfirmation}
        onConfirmationModalClosed={handlePricePostingScheduleBulkDeleteCancel}
      />
    </>
  )
}

export default PricePostingsScheduleList
