import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withFormik, yupToFormErrors, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Grid, MenuItem } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import ProductSizeInput from 'components/common/ProductSizeInput'
import {
  updateProductSize,
  addNewProductSize,
  setProductSizeShown,
  resetProductSizeDetails,
} from 'ducks/productSizes'
import { fetchContainerTypes } from 'ducks/global'
import {
  productSizeRequestedByLicensee,
  toggleConfigReqDialog,
} from 'ducks/configurationRequests'
import { convertSizeOfBBL } from 'common/helper'

const validations = Yup.object().shape({
  productSize: Yup.object().shape({
    size: Yup.string()
      .required('Required')
      .matches('^[0-9./]*$', 'Invalid character(s)')
      .test(
        'validate-max-size',
        'Number too large',
        value => isNaN(value) || value <= 9999999999
      )
      .test(
        'validate-leading-zeros',
        'Double leading zeros',
        value => !value.toString().match(/^0[0].*$/)
      )
      .test('validate-fraction', 'Fraction requires BBL', function (value) {
        // Get unit display text for use in convertSizeOfBBL
        const unit = this.options.unitsOfMeasure.find(
          unit => unit.id === this.parent.unit
        )
        // Valid if there is a value and that value is not a fraction unless unit type is BBL
        return (
          !value.includes('/') || (value.includes('/') && unit.unit === 'BBL')
        )
      })
      .test('validate-fraction', 'Invalid BBL size', function (value) {
        // Get unit display text for use in convertSizeOfBBL
        const unit = this.options.unitsOfMeasure.find(
          unit => unit.id === this.parent.unit
        )
        // Valid if there is a value and that value is not a fraction unless unit type is BBL
        return value && unit && unit.unit === 'BBL'
          ? convertSizeOfBBL(value, unit.unit)
          : true
      }),
    unit: Yup.string()
      .required('Required')
      .test('validate-bbl', 'BBL requires fraction', function (value) {
        // Get size from sibling
        const size = this.parent.size
        // Get unit display text for use in return condition
        const unit = this.options.unitsOfMeasure.find(unit => unit.id === value)
        // Valid if size is not a fraction or if size is a fraction AND unit text is BBL
        return size && unit && unit.unit === 'BBL' ? size.includes('/') : true
      }),
    containerType: Yup.string().required('Required'),
  }),
})

const EditProductSize = ({ unitsOfMeasure, isRequestedByLicensee }) => {
  const dispatch = useDispatch()
  const { isLoading, containerTypes } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(fetchContainerTypes())
  }, [dispatch])

  const onAddProductSize = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      let finalObject = formikValues.values.productSize
      const unitSelected = unitsOfMeasure.find(
        unit => unit.id === finalObject.unit
      )
      finalObject = {
        ...finalObject,
        size: parseFloat(convertSizeOfBBL(finalObject.size, unitSelected.unit)),
        unit: parseInt(finalObject.unit),
        containerType: parseInt(finalObject.containerType),
      }
      if (isRequestedByLicensee) {
        dispatch(productSizeRequestedByLicensee(finalObject))
      } else {
        if (finalObject.id) {
          dispatch(
            updateProductSize({ ...finalObject, id: parseInt(finalObject.id) })
          )
        } else {
          dispatch(addNewProductSize(finalObject))
        }
      }
    }
  }

  return (
    <Form>
      <Field
        name='productSize'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.productSize = JSON.parse(
            JSON.stringify(formikProps.form.values.productSize)
          )
          // Adjust unit sent as an object { id: 1, unit: ""} to use only the id as the value
          if (
            typeof formikProps.form.values.productSize.unit.id !== 'undefined'
          ) {
            // Get text for unit to check for BBL
            const productUnit = unitsOfMeasure.find(
              unit => unit.id === formikProps.form.values.productSize.unit.id
            )

            // If unit text is BBL then convert size from decimal to fraction
            if (productUnit && productUnit.unit === 'BBL')
              formikProps.form.values.productSize.size = convertSizeOfBBL(
                formikProps.form.values.productSize.size,
                productUnit.unit
              )

            formikProps.form.values.productSize = {
              ...formikProps.form.values.productSize,
              unit: formikProps.form.values.productSize.unit.id,
            }
          }

          if (
            typeof formikProps.form.values.productSize.containerType.id !==
            'undefined'
          ) {
            formikProps.form.values.productSize = {
              ...formikProps.form.values.productSize,
              containerType:
                formikProps.form.values.productSize.containerType.id,
            }
          }

          const formikValues = formikProps.form.values

          const handleCancel = () => {
            dispatch(setProductSizeShown(false))
            dispatch(resetProductSizeDetails())
            if (isRequestedByLicensee) {
              dispatch(toggleConfigReqDialog(false))
            }
          }

          return (
            <>
              {isLoading ||
              unitsOfMeasure.length === 0 ||
              containerTypes.length === 0 ? (
                <LoadingIndicator
                  text={`Loading ${isLoading ? 'Product Size' : 'Units'}...`}
                  transparent
                />
              ) : (
                <>
                  <CustomCard title={!isRequestedByLicensee && 'Product Size'}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='productSize.size'
                        label='Size'
                        id='size'
                        component={ProductSizeInput}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      {unitsOfMeasure.length > 0 ? (
                        <Field
                          variant='outlined'
                          margin='normal'
                          select
                          required
                          fullWidth
                          label='Units'
                          name='productSize.unit'
                          component={TextField}
                        >
                          Units *
                          {unitsOfMeasure.map(val => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.unit}
                            </MenuItem>
                          ))}
                        </Field>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      {containerTypes.length > 0 ? (
                        <Field
                          variant='outlined'
                          margin='normal'
                          select
                          required
                          fullWidth
                          label='Container Type'
                          name='productSize.containerType'
                          component={TextField}
                        >
                          Container Type *
                          {containerTypes.map(val => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.type}
                            </MenuItem>
                          ))}
                        </Field>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddProductSize(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.productSize.id && 'update'}
                    isDisabled={
                      formikValues.productSize.id && !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}
const WithFormikWrapper = withFormik({
  validate: (values, props) => {
    // get unitsOfMeasure from props and pass into Yup validation schema as an option
    const unitsOfMeasure = props.unitsOfMeasure
    return validations
      .validate(values, { abortEarly: false, unitsOfMeasure })
      .catch(err => {
        throw yupToFormErrors(err)
      })
  },
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditProductSize)

export default WithFormikWrapper
