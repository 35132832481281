import React, { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { checkForAuthenticatedUser } from 'ducks/authentication'

export default ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(store => store.auth)
  let location = useLocation()

  useEffect(() => {
    // Check if a user is already authenticated, store page path
    if (!isAuthenticated) dispatch(checkForAuthenticatedUser(location.pathname))
  }, [dispatch, location, isAuthenticated])

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
