import { createReducer, createAction } from '@reduxjs/toolkit'
import { getToken } from 'common/helper'
import { showError } from '../global'
import { print } from 'graphql'
import { getNewsUpdatesQuery } from 'graphql/queries'

export const getNewsUpdates = () => {
  return async dispatch => {
    await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getToken({ code: 'getNewsUpdatesQuery' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getNewsUpdatesQuery),
      }),
    })
      .then(res => res.json())
      .then(res => {
        const newsUpdateResults = {
          ...res.data.newsUpdates,
          firstLoad: false,
        }
        dispatch(setNewsUpdateResults(newsUpdateResults))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve news update, please check your internet connection and try again.'
          )
        )
      })
  }
}

export const setNewsUpdateResults = createAction(
  'newsUpdate/setNewsUpdateResults'
)

const initialState = {
  newsUpdateResults: {
    results: [],
    firstLoad: true,
  },
}

export default createReducer(initialState, {
  [setNewsUpdateResults]: (state, action) => {
    state.newsUpdateResults = action.payload
  },
})
