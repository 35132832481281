import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { lighten } from 'polished'
import { TextField } from 'formik-material-ui'
import { compareAsc, parseISO } from 'date-fns'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CommonTooltip from 'components/common/CommonTooltip'
import ReusableTable from 'components/common/ReusableTable'
import {
  setEditMode,
  fetchPricesTo,
  fetchProductsWithTrade,
  fetchPackageConfiguration,
  fetchProductSizes,
  getLEsForDropDown,
} from 'ducks/global'
import { handlePricePostSubmit } from 'ducks/pricePostings'
import NumericInput from 'components/common/NumericInput'
import { receivingMethod, pricePostingUpdateTypes, californiaCounties } from 'common/constants'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { Can, AbilityContext } from 'common/abilityContext'
import { getDateOnly, areAllCountiesSelected, checkIfUserIsABC } from 'common/helper'
import { Delete } from '@material-ui/icons'

const CheckboxWrapperForMultiProducts = styled(FormControlLabel)`
  && {
    padding: 0;
    margin-right: 0px;
  }
`

const CheckboxWrapperIsEffective = styled(FormControlLabel)`
  && {
    padding: 0px 0 10px 16px;
    margin-right: 0px;
  }
`

const DisclaimerText = styled(Typography)`
  && {
    font-size: 14px;
    background-color: ${props => lighten(0.1, props.theme.gold)};
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
`

const Title = styled(Typography)`
  && {
    color: ${props => props.theme.dark};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
`

const validations = props => {
  return Yup.object().shape({
    pricePosting: Yup.object().shape({
      manufacturer: !props.cannotSelectManufacturer ? Yup.string().required('Required') : '',
      product: Yup.string().required('Required'),
      package: Yup.string().required('Required'),
      size: Yup.string().required('Required'),
      county: Yup.string().required('Required'),
      pricesTo: Yup.string().required('Required'),
      pricePromotion: Yup.string().required('Required'),
      price: Yup.string()
        .required('Required')
        .matches(/^[$]((?!(0))[1-9]?,?[0-9]{1,}|0)\.[0-9]{2,3}$/, {
          message: 'Price not formatted properly (0.00)',
        }),
      receivingMethod: Yup.string().required('Required'),
    }),
  })
}

const EditPricePosting = props => {
  const dispatch = useDispatch()
  const {
    isLoading,
    loadingText,
    loadingProgress,
    pricesToForDropDown,
    productsWithTrade,
    productsWithTradeForBulkProcess,
    packageConfigForDropDown,
    productSizesForDropDown,
    lEsForDropDown,
  } = useSelector(store => store.global)
  const { user } = useSelector(store => store.auth)
  const { pricePostingDetails } = useSelector(store => store.pricePostings)
  const abilityFunc = useContext(AbilityContext)
  const userCanSelectManufacturer = abilityFunc.can('select', 'manufacturerOnPricePosting') === true

  //----------state initialization-------
  const [disclaimerParams, setDisclaimerParams] = useState({
    showBanner: false,
    updateType: '',
    bannerText: '',
  })
  const [multiSelectingProducts, setMultiSelectingProducts] = useState(false)
  const [listOfProductSelected, setListOfProductsSelected] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const selectedProducts = listOfProductSelected.map(selectedItem => selectedItem.id)
  //Here remove already selected products from the products dropdown
  const filteredProductsForDropdown = productsWithTrade.filter(
    product => !selectedProducts.includes(product.value)
  )

  const updateDisclaimer = form => {
    // Date Comparison
    const currentDate = getDateOnly(new Date())
    const pricePostDate = getDateOnly(new Date(pricePostingDetails.createdAt))
    const comparedSubmittedAndTodaysDate = compareAsc(
      parseISO(pricePostDate),
      parseISO(currentDate)
    )

    if (pricePostingDetails.status.value === 'Pending') {
      if (
        comparedSubmittedAndTodaysDate === -1 &&
        (form.initialValues.pricePosting.price !== form.values.pricePosting.price ||
          form.initialValues.pricePosting.containerCharge !==
            form.values.pricePosting.containerCharge)
      ) {
        // Check if the submitted date < today’s date
        setDisclaimerParams({
          showBanner: true,
          updateType: pricePostingUpdateTypes.pendingPricePosting,
          bannerText: 'Changes will result in a new price posting, effective in 10 days.',
        })
      } else if (compareAsc(parseISO(pricePostDate), parseISO(currentDate)) === 0) {
        // Submitted date === today’s date
        setDisclaimerParams({
          showBanner: false,
          updateType: pricePostingUpdateTypes.sameDayPricePosting,
          bannerText: '',
        })
      }
    } else if (
      pricePostingDetails.status.value === 'Active' &&
      (form.initialValues.pricePosting.price !== form.values.pricePosting.price ||
        form.initialValues.pricePosting.containerCharge !==
          form.values.pricePosting.containerCharge)
    ) {
      setDisclaimerParams({
        showBanner: true,
        updateType: pricePostingUpdateTypes.activePricePosting,
        bannerText: 'Changes will result in a new price posting, effective in 10 days.',
      })
    } else {
      // Submitted date === today’s date
      setDisclaimerParams({
        showBanner: false,
        updateType: '',
        bannerText: '',
      })
    }
  }

    const onAddPricePosting = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    // Set tradingArea to null if falsey
    formikValues.values.pricePosting.tradingArea =
      formikValues.values.pricePosting.tradingArea || null
    // Handle valid post
    if (formikValues.isValid) {
      let ppValues = formikValues.values.pricePosting
      if (areAllCountiesSelected(formikValues.values.pricePosting.county)) {
        let counties = californiaCounties.filter(c => c.value !== 'All Counties')
        ppValues = { ...formikValues.values.pricePosting, county: counties }
      }
      if (multiSelectingProducts && listOfProductSelected.length > 0) {
        let finalPricePostingList = []
        //create one object for each product selected and push it to the final array
        for (const productSelected of listOfProductSelected) {
          finalPricePostingList.push({
            ...ppValues,
            product: { value: productSelected.id },
          })
        }
        dispatch(handlePricePostSubmit(finalPricePostingList))
      } else {
        dispatch(handlePricePostSubmit([ppValues]))
      }
    }

    setTimeout(() => props.onCancel(), 0)
  }
  // Set manufacturerId to ensure product list accurately reflects the manufacturer
  const manufacturerId = 
          (pricePostingDetails && pricePostingDetails.manufacturer) ? pricePostingDetails.manufacturer.value 
          : (user && user.legalEntity ) ? user.legalEntity.id : null;

  useEffect(() => {
    dispatch(fetchPricesTo())
    dispatch(fetchProductsWithTrade([manufacturerId]))
    dispatch(fetchPackageConfiguration())
    dispatch(fetchProductSizes())
    dispatch(getLEsForDropDown())
    updateDisclaimer(props)
  }, [
    dispatch,
    pricePostingDetails.createdAt,
    pricePostingDetails.id,
    pricePostingDetails.status.value,
    manufacturerId,
  ])
  const updatedPackages = packageConfigForDropDown ? packageConfigForDropDown : []

  const handleProductSelection = event => {
    let newArray = listOfProductSelected
    // using product's dropdown event.value find the correct object in the productsWithTradeForBulkProcess
    /** @param event = {
      value: {
        id: '1123',
        manufacturer: {
          name: 'Some manufacturer  name',
        },
        name: 'some product name',
        tradeName: 'some trade name',
      },
    }*/
    newArray.push(productsWithTradeForBulkProcess.find(product => product.id === event.value))
    setListOfProductsSelected(newArray)
  }

  //--------multiple products selected list------

  const multipleProductsHeaders = userCanSelectManufacturer
    ? [
        { id: 'manufacturer', label: 'Manufacturer', isSortable: false },
        { id: 'tradeName', label: 'Trade Name', isSortable: false },
        { id: 'productName', label: 'Product Name', isSortable: false },
        { id: 'actions', label: 'Actions' },
      ]
    : [
        { id: 'tradeName', label: 'Trade Name', isSortable: false },
        { id: 'productName', label: 'Product Name', isSortable: false },
        { id: 'actions', label: 'Actions' },
      ]

  const handlePageNumber = newPage => {
    setPageNumber(newPage)
  }
  const handleRowsPerPage = rowsPerPage => {
    setRowsPerPage(parseInt(rowsPerPage, 10))
    setPageNumber(0)
  }

  let updatedMultipleProductsSelectedList = []
  //calculate the from and to value using current page number and current number of rows
  const fromIndex = pageNumber * rowsPerPage
  const endValueCount = (pageNumber + 1) * rowsPerPage
  //Use the calculated "from" and "to" value to create data for the table
  updatedMultipleProductsSelectedList = listOfProductSelected
    .slice(fromIndex, endValueCount)
    .map((selectedProduct, index) => {
      let updatedSelectedProduct = {}
      updatedSelectedProduct.id = selectedProduct.id
      if (userCanSelectManufacturer) {
        updatedSelectedProduct = {
          ...updatedSelectedProduct,
          manufacturer: selectedProduct.manufacturer.name,
        }
      }
      updatedSelectedProduct = {
        ...updatedSelectedProduct,
        tradeName: selectedProduct.tradeName,
        productName: selectedProduct.name,
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='false'
              onClick={event => handleUnSelectProduct(event, selectedProduct)}
              color='primary'
            >
              <Delete color='primary' />
            </IconButton>
          </>
        ),
      }

      return updatedSelectedProduct
    })

  const handleUnSelectProduct = (event, productToDelete) => {
    event.stopPropagation()
    //check if the last item is removed from the current page
    if (updatedMultipleProductsSelectedList.length === 1) {
      //calculate last page number
      const calculatedLastPageNumber = Math.max(
        0,
        Math.ceil(listOfProductSelected.length / rowsPerPage) - 1
      )
      //Check if the current page number is equal to calculated last page number
      if (pageNumber === calculatedLastPageNumber && pageNumber !== 0) {
        //then take the user to the previous page
        setPageNumber(pageNumber - 1)
      }
    }
    setListOfProductsSelected(listOfProductSelected =>
      listOfProductSelected.filter(product => product.id !== productToDelete.id)
    )
  }
  return (
    <Form>
      <Field
        name='pricePosting'
        render={formikProps => {
          // Remove reference to reducer variable by creating new object
          formikProps.form.values.pricePosting = JSON.parse(
            JSON.stringify(formikProps.form.values.pricePosting)
          )
          const pricePostingFormikValues = formikProps.form.values.pricePosting
          const allCountiesSelected = areAllCountiesSelected(pricePostingFormikValues.county)

          //toggle between price posting with multiple products or single product
          const toggleMultiProductOption = () => {
            if (!multiSelectingProducts) {
              formikProps.form.setFieldValue(`pricePosting.product`, '')
            }
            setMultiSelectingProducts(!multiSelectingProducts)
            setListOfProductsSelected([])
          }

          const messageForNoProductsInDropdown = () => {
            if (userCanSelectManufacturer) {
              if (pricePostingFormikValues.manufacturer) {
                return 'No products found'
              } else {
                return 'Please select a manufacturer first.'
              }
            } else {
              return 'No products found'
            }
          }

          const CheckBoxForMultiProducts = (
            <>
              <CheckboxWrapperForMultiProducts
                control={
                  <Checkbox
                    onChange={toggleMultiProductOption}
                    name='Multi select product'
                    size='small'
                    checked={multiSelectingProducts}
                  />
                }
                label='Select Multiple Products?'
              />
              <CommonTooltip
                informationList={['One price post will be created per product selection.']}
              />
            </>
          )

          const handleCancel = () => {
            pricePostingFormikValues.id ? dispatch(setEditMode(false)) : props.onCancel()
          }

          const handleAutoCompleteChange = (field, event) => {
            const selectedValue = event ? event : []
            if (field === 'manufacturer') {
              // On manufacturer, fetch list of products for selected manufacturer-ID
              const manufacturerId = event ? parseInt(event.value) : ''
              formikProps.form.setFieldValue(`pricePosting.product`, '')
              dispatch(fetchProductsWithTrade([manufacturerId]))
            } else if (
              field === 'county' &&
              (areAllCountiesSelected(selectedValue) || selectedValue.length > 1)
            ) {
              // On county AND Any one of the two(ALL counties selected, more than one county selected), clear the trading area
              formikProps.form.setFieldValue(`pricePosting.tradingArea`, '')
              if (areAllCountiesSelected(selectedValue)) {
                // Only ALL Counties is selected, remove the previously selected values
                formikProps.form.setFieldValue(`pricePosting.county`, [
                  { value: 'All Counties', label: 'All Counties' },
                ])
                return null
              }
            } else if (field === 'product') {
              formikProps.form.setFieldValue(`pricePosting.${field}`, selectedValue)
              if (multiSelectingProducts) {
                handleProductSelection(event)
              }
            }
            formikProps.form.setFieldValue(`pricePosting.${field}`, selectedValue)
          }

          // Check that only new price postings are able to modify all fields before midnight
          // If price post is created as a price change, you can only modify price/container charge before midnight
          const isFieldDisabled =
            pricePostingFormikValues.parentPricePostId ||
            (pricePostingFormikValues.id &&
              disclaimerParams.updateType !== pricePostingUpdateTypes.sameDayPricePosting)
              ? true
              : false

          const isTradingAreaDisabled =
            isFieldDisabled ||
            areAllCountiesSelected(pricePostingFormikValues.county) ||
            pricePostingFormikValues.county.length > 1
              ? true
              : false

          const isLoggedInUserManufacturerOfProduct = user && user.legalEntity && 
            user.legalEntity.id == pricePostingFormikValues.manufacturer.value

          const isUserABC = user.role ? checkIfUserIsABC(user.role.id) : false

          let isPromotionDisabled = pricePostingFormikValues.id && !formikProps.form.isValid

          if (
            isUserABC ||
            isLoggedInUserManufacturerOfProduct ||
            pricePostingFormikValues.status.value === 'Pending'
          ) {
            isPromotionDisabled = false
          }

          return (
            <>
              {isLoading || updatedPackages.length === 0 || productSizesForDropDown.length === 0 ? (
                <LoadingIndicator
                  text={
                    loadingText.length > 0
                      ? loadingText
                      : `Loading ${isLoading ? 'Price Posting' : 'Configurations'}...`
                  }
                  progress={loadingProgress}
                  transparent
                />
              ) : (
                <>
                  <CustomCard
                    titleContent={
                      <>
                        <Title
                          key={pricePostingDetails}
                          variant='h6'
                          children='Price Posting Details'
                        />
                        <Collapse
                          in={disclaimerParams.showBanner}
                          children={
                            <DisclaimerText
                              component='div'
                              variant='h6'
                              children={disclaimerParams.bannerText}
                            />
                          }
                        />
                      </>
                    }
                  >
                    <Can I='select' this='manufacturerOnPricePosting'>
                      {() => (
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                          <Field
                            autoFocus
                            isDisabled={isFieldDisabled}
                            placeHolderText='Manufacturer *'
                            name='pricePosting.manufacturer'
                            formikProps={formikProps.form}
                            selectedValue={pricePostingFormikValues.manufacturer}
                            dropDownOptions={lEsForDropDown}
                            onValueChange={event => handleAutoCompleteChange('manufacturer', event)}
                            component={AutoCompleteComp}
                          />
                          {CheckBoxForMultiProducts}
                        </Grid>
                      )}
                    </Can>
                    <Grid item xs={12} sm={12} md={8} lg={6}>
                      <Field
                        autoFocus={userCanSelectManufacturer ? false : true}
                        isDisabled={isFieldDisabled}
                        placeHolderText='Product *'
                        name='pricePosting.product'
                        formikProps={formikProps.form}
                        selectedValue={pricePostingFormikValues.product}
                        dropDownOptions={filteredProductsForDropdown}
                        onValueChange={event => handleAutoCompleteChange('product', event)}
                        noOptionsMessage={messageForNoProductsInDropdown()}
                        component={AutoCompleteComp}
                      />
                      {!userCanSelectManufacturer && CheckBoxForMultiProducts}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isFieldDisabled}
                        placeHolderText='Package Configuration *'
                        name='pricePosting.package'
                        selectedValue={pricePostingFormikValues.package}
                        formikProps={formikProps.form}
                        dropDownOptions={updatedPackages.filter(
                          ({ status }) => status === 'Active'
                        )}
                        onValueChange={event => handleAutoCompleteChange('package', event)}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        disabled={isFieldDisabled}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        placeholder='Example: one glass'
                        InputLabelProps={{
                          style: { pointerEvents: 'none', top: '-6px' },
                        }}
                        label={
                          <>
                            Promotional Item
                            <CommonTooltip
                              informationList={[
                                'Promotional items such as glassware or bottle opener that are part of the package',
                              ]}
                            />
                          </>
                        }
                        name='pricePosting.promotional'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isFieldDisabled}
                        placeHolderText='Product Size *'
                        name='pricePosting.size'
                        formikProps={formikProps.form}
                        selectedValue={pricePostingFormikValues.size}
                        dropDownOptions={productSizesForDropDown.filter(
                          ({ status }) => status === 'Active'
                        )}
                        onValueChange={event => handleAutoCompleteChange('size', event)}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isFieldDisabled}
                        placeHolderText={
                          <>
                            County(ies) *
                            <CommonTooltip
                              informationList={[
                                'Selecting multiple counties will result in the creation of individual price posts per county.',
                              ]}
                            />
                          </>
                        }
                        formikProps={formikProps.form}
                        selectedValue={pricePostingFormikValues.county}
                        dropDownOptions={allCountiesSelected ? [] : californiaCounties}
                        onValueChange={event => handleAutoCompleteChange('county', event)}
                        isMulti={true}
                        name='pricePosting.county'
                        noOptionsMessage={
                          allCountiesSelected
                            ? `Remove "All Counties" to see options`
                            : `No Options Found`
                        }
                        component={AutoCompleteComp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        disabled={isTradingAreaDisabled}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Trading Area'
                        name='pricePosting.tradingArea'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isFieldDisabled}
                        placeHolderText='Prices To *'
                        name='pricePosting.pricesTo'
                        formikProps={formikProps.form}
                        dropDownOptions={pricesToForDropDown.filter(
                          ({ status }) => status === 'Active'
                        )}
                        selectedValue={pricePostingFormikValues.pricesTo}
                        onValueChange={event => handleAutoCompleteChange('pricesTo', event)}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isFieldDisabled}
                        placeHolderText={
                          <>
                            Receiving Method *
                            <CommonTooltip
                              informationList={[
                                'Selecting multiple receiving methods will result in the creation of individual price posts per method.',
                              ]}
                            />
                          </>
                        }
                        name='pricePosting.receivingMethod'
                        formikProps={formikProps.form}
                        selectedValue={pricePostingFormikValues.receivingMethod}
                        dropDownOptions={receivingMethod}
                        onValueChange={event => handleAutoCompleteChange('receivingMethod', event)}
                        isMulti={true}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Price'
                        id='price'
                        name='pricePosting.price'
                        required
                        decimalScale={3}
                        onKeyUp={() => {
                          updateDisclaimer(formikProps.form)
                        }}
                        component={NumericInput}
                        isAllowed={values => {
                          const { formattedValue, floatValue } = values
                          if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                            return false
                          }
                          return formattedValue === '' || floatValue <= 9999.99
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                        placeholder='0.00'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Container Charge'
                        id='containerCharge'
                        name='pricePosting.containerCharge'
                        decimalScale={2}
                        onKeyUp={() => {
                          updateDisclaimer(formikProps.form)
                        }}
                        fixedDecimalScale={true}
                        component={NumericInput}
                        isAllowed={values => {
                          const { formattedValue, floatValue } = values
                          if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                            return false
                          }
                          return formattedValue === '' || floatValue <= 9999.99
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        isDisabled={isPromotionDisabled}
                        placeHolderText='Price Promotion *'
                        name='pricePosting.pricePromotion'
                        formikProps={formikProps.form}
                        selectedValue={pricePostingFormikValues.pricePromotion}
                        dropDownOptions={[
                          { value: 'false', label: 'No' },
                          { value: 'true', label: 'Yes' },
                        ]}
                        onValueChange={event => handleAutoCompleteChange('pricePromotion', event)}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                  </CustomCard>
                  {!isFieldDisabled && (
                    <Grid item xs={12} sm={12} md={6}>
                      <>
                        <CheckboxWrapperIsEffective
                          control={
                            <Field
                              onChange={() =>
                                formikProps.form.setFieldValue(
                                  'pricePosting.isEffectiveImmediately',
                                  !pricePostingFormikValues.isEffectiveImmediately
                                )
                              }
                              name='pricePosting.pricePosting'
                              component={Checkbox}
                              type='checkbox'
                              checked={pricePostingFormikValues.isEffectiveImmediately}
                            />
                          }
                          label='Is this price posting effective immediately?'
                        />
                        <CommonTooltip
                          informationList={[
                            'Price Posting cannot be updated once effective immediately is selected.',
                          ]}
                        />
                      </>
                    </Grid>
                  )}
                  {multiSelectingProducts && (
                    <Grid item xs={12} sm={12} md={12}>
                      <CustomCard
                        title='Price Posting Multiple Products'
                        children={
                          <ReusableTable
                            headers={multipleProductsHeaders}
                            tableData={updatedMultipleProductsSelectedList}
                            pagination={true}
                            selectionOption={false}
                            totalCount={listOfProductSelected.length}
                            onSingleItemSelected={() => {}}
                            setPageNumber={handlePageNumber}
                            setRowsPerPage={handleRowsPerPage}
                            pageNumber={pageNumber}
                            rowsPerPage={rowsPerPage}
                            canChangeRowsPerPage={[5, 10, 20, 50, 100]}
                            bulkSelected={[]}
                          />
                        }
                      />
                    </Grid>
                  )}
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddPricePosting(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={pricePostingFormikValues.id && 'update'}
                    isDisabled={
                      (pricePostingFormikValues.id && !formikProps.form.isValid) ||
                      !formikProps.form.isValid
                        ? true
                        : false
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  isInitialValid: false,
  enableReinitialize: true,
})(EditPricePosting)

export default WithFormikWrapper
