import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import { configurationTypes } from 'common/constants'
import styled from 'styled-components'
import EditProductSize from 'components/private/globalSettings/productSizes/EditProductSize'
import EditPackageConfiguration from 'components/private/globalSettings/packageConfiguration/EditPackageConfig'
import { fetchUnitsOfMeasure } from 'ducks/global'

const StyledSelect = styled(Select)`
  min-width: 16rem;
`
const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`

const Disclaimer = styled(Typography)`
  color: #666;
  line-height: 1.6;
`

const RequestNewConfiguration = () => {
  const dispatch = useDispatch()
  const [configuration, setConfiguration] = useState('')

  const { isLoading, unitsOfMeasure } = useSelector(store => store.global)
  const { productSizeDetails } = useSelector(store => store.productSizes)
  const { packageConfigurationDetails } = useSelector(
    store => store.packageConfigurations
  )

  dispatch(fetchUnitsOfMeasure())

  return isLoading ? (
    <LoadingIndicator text={`Validating request...`} transparent />
  ) : (
    <>
      <CustomCard title='Select a configuration type'>
        <StyledGrid item xs={12} sm={12} md={12} lg={12}>
          <FormControl variant='outlined'>
            <InputLabel id='select-configuration-label'>
              Configuration Type
            </InputLabel>
            <StyledSelect
              labelId='select-configuration-label'
              id='select-configuration-outlined'
              value={configuration}
              onChange={event => setConfiguration(event.target.value)}
              label='Configuration Type'
            >
              {configurationTypes.map((type, index) => (
                <MenuItem value={type.label} key={index}>
                  {type.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </StyledGrid>
      </CustomCard>
      {configuration === 'Product Size' ? (
        <EditProductSize
          productSize={productSizeDetails}
          isRequestedByLicensee={true}
          unitsOfMeasure={unitsOfMeasure}
        />
      ) : configuration === 'Package Configuration' ? (
        <EditPackageConfiguration
          packageConfiguration={packageConfigurationDetails}
          isRequestedByLicensee={true}
        />
      ) : (
        <Disclaimer
          children='If the product size/package configuration is not already in the system or a request has not been made to add it, the request will be sent to ABC and you will receive an email once it has been reviewed.'
          variant='subtitle1'
        />
      )}
    </>
  )
}

export default RequestNewConfiguration
