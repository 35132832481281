import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from '@material-ui/core'
import { createGlobalStyle } from 'styled-components'
import SideBar from 'components/private/SideBar'
import CustomHeader from 'components/common/CustomHeader'
import { Switch, Route } from 'react-router-dom'
import LegalEntities from 'components/private/legalEntity/LegalEntities'
import styled from 'styled-components'
import backgroundImg from 'assets/background.png'
import { AbilityContext } from 'common/abilityContext'
import { ability } from 'common/ability'
import { sideBarNavLinks } from 'common/constants'
import Dashboard from 'components/private/Dashboard'
import ABCUsers from 'components/private/abcUsers/ABCUsers'
import LegalEntityUsers from 'components/private/leUsers/LegalEntityUsers'
import ProductsList from 'components/private/products/ProductsList'
import GlobalSettings from 'components/private/globalSettings/GlobalSettings'
import PricePostingsList from 'components/private/pricePosting/PricePostingsList'
import PricePostingsScheduleList from 'components/private/staging/PricePostingsScheduleList'
import ConfigurationRequestList from 'components/private/configurationRequests/ConfigurationRequestList'
import NotFound from 'components/public/NotFound'
import QuickView from 'components/common/QuickView'
import { toggleConfigReqDialog } from 'ducks/configurationRequests'
import { resetNewsUpdateDetails, toggleNewsUpdateDialog } from 'ducks/newsUpdate'
import RequestNewConfiguration from 'components/private/configurationRequests/RequestNewConfiguration'
import NewsUpdate from 'components/private/newsUpdate/NewsUpdate'
import PublicFooter from 'components/public/Footer'

const GlobalStyle = createGlobalStyle`
  html {
    background: ${props => props.theme.light};
  }
  body {
    -webkit-font-smoothing: subpixel-antialiased !important; 
    text-rendering:optimizeLegibility !important;
  }

  td.active, td.approved {
    color: ${props => props.theme.active};
  }
  td.pending, td.denied {
    color: ${props => props.theme.pending};
  }
  td.inactive {
    color: ${props => props.theme.inactive};
  }
  fieldset {
    height: 50px;
  }

  a, a:link, a:visited {
    color: ${props => props.theme.blue};
  }
  a:hover, a:active {
    color: ${props => props.theme.dark};
  }
`

const LayoutWrapper = styled(Container)`
  && {
    margin-top: 53px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
`

const Background = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 254px;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
  background-image: url(${backgroundImg});
  background-position: center 80px;
  background-repeat: repeat-x;
  background-size: 1920px 254px;
`

const SwitchComp = () => {
  const abilityFunc = useContext(AbilityContext)
  return (
    <Switch>
      <Route
        exact
        path='/(dashboard|)'
        component={abilityFunc.can('read', sideBarNavLinks.dashboard) && Dashboard}
      />
      <Route
        exact
        path='/(licensees|)'
        component={
          abilityFunc.can('read', sideBarNavLinks.legalEntities) ? LegalEntities : NotFound
        }
      />
      <Route
        exact
        path='/licenseeUsers'
        component={
          abilityFunc.can('read', sideBarNavLinks.legalEntityUsers) ? LegalEntityUsers : NotFound
        }
      />
      <Route
        exact
        path='/abcUsers'
        component={abilityFunc.can('read', sideBarNavLinks.abcUsers) ? ABCUsers : NotFound}
      />
      <Route
        exact
        path='/products'
        component={abilityFunc.can('read', sideBarNavLinks.products) ? ProductsList : NotFound}
      />
      <Route
        exact
        path='/pricePostings'
        component={
          abilityFunc.can('read', sideBarNavLinks.pricePostings) ? PricePostingsList : NotFound
        }
      />
      {/* <Route
        exact
        path='/pricePostingsSchedule'
        component={
          abilityFunc.can('read', sideBarNavLinks.pricePostingsSchedule)
            ? PricePostingsScheduleList
            : NotFound
        }
      /> */}
      <Route
        exact
        path='/globalSettings'
        component={
          abilityFunc.can('read', sideBarNavLinks.globalSettings) ? GlobalSettings : NotFound
        }
      />
      <Route
        exact
        path='/configurationRequests'
        component={
          abilityFunc.can('read', sideBarNavLinks.configurationRequests)
            ? ConfigurationRequestList
            : NotFound
        }
      />
      <Route
        exact
        path='/NewsUpdate'
        component={abilityFunc.can('read', sideBarNavLinks.NewsUpdate) ? NewsUpdate : NotFound}
      />
    </Switch>
  )
}

const PrivateLayout = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store.auth)
  const { isReqConfigDialogOpen } = useSelector(store => store.configurationRequests)
  const { isNewsUpdateDialogOpen } = useSelector(store => store.newsUpdate)
  const handleClose = () => {
    dispatch(resetNewsUpdateDetails())
    dispatch(toggleNewsUpdateDialog(false))
  }
  return (
    <AbilityContext.Provider value={ability(user)}>
      <GlobalStyle />
      <SideBar role='navigation' />
      <CustomHeader role='banner' />
      <LayoutWrapper role='main'>
        <SwitchComp />
      </LayoutWrapper>
      <QuickView
        title='Request New Configuration'
        onClose={() => dispatch(toggleConfigReqDialog(false))}
        dialogContent={<RequestNewConfiguration />}
        isDialogOpen={isReqConfigDialogOpen}
        isSmallDialog={true}
      />
      <QuickView
        title='Add News Update'
        onClose={() => handleClose()}
        dialogContent={<NewsUpdate />}
        isDialogOpen={isNewsUpdateDialogOpen}
        isSmallDialog={true}
      />
      <Background />
      <PublicFooter role='banner' />
    </AbilityContext.Provider>
  )
}

export default PrivateLayout
