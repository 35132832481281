import { API, graphqlOperation } from 'aws-amplify'

import {
  setLoading,
  setLoadingProgress,
  setEditMode,
  showError,
  showSuccess,
  setAuxLoading,
  fetchProductsWithTrade,
  fetchPackageConfiguration,
  fetchProductSizes,
  fetchPricesTo,
  getLEsForDropDown,
  setFetchingTotalCount,
} from './global'

import {
    getPricePostingsPromoAuditByPricePosting,
} from 'graphql/queries'

import { format } from 'date-fns'

import {
  exportToExcel,
  convertSizeOfBBL,
  formatNumberToTwoDecimal,
  formatNumberToThreeDecimal,
} from 'common/helper'


export const exportPricePromoAuditByPricePosting = ( ppId ) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Postings Promo Audit...'))
    const graphQlParams = {
      where: {
        pricePostingsId: parseInt(ppId),
      },
      orderBy: 'createdAt_DESC',
    }

    await API.graphql(graphqlOperation(getPricePostingsPromoAuditByPricePosting, graphQlParams))
      .then(response => {
        const finalRes = response
            .data
            .pricePostingsPromoAuditByPricePosting
            .results
            .map(ppa => {
              return {
                "ID": ppa.id,
                'New Price Promotion Status': (ppa.pricePromotion) ? "Yes" : "No",
                'Price Promotion Field Changed By': ppa.createdBy.lastName + ', ' + ppa.createdBy.firstName,
                'Date Status Edited': format(ppa.createdAt, 'MM/dd/yyyy'),
                Manufacturer: ppa.pricePosting.manufacturer.name,
                'Trade Name': ppa.pricePosting.product.tradeName,
                'Product Name': ppa.pricePosting.product.name,
                'Package Configuration': ppa.pricePosting.package.package,
                'Product Size': `${convertSizeOfBBL(
                  ppa.pricePosting.productSize.size,
                  ppa.pricePosting.productSize.unit.unit
                )} ${ppa.pricePosting.productSize.unit.unit}`,
                'Container Type': ppa.pricePosting.productSize.containerType.type,
                'Trading Area': ppa.pricePosting.tradingArea,
                County: ppa.pricePosting.county,
                'Prices To': ppa.pricePosting.pricesTo.name,
                'Receiving Method': ppa.pricePosting.receivingMethod,
                'Price($)': formatNumberToThreeDecimal(ppa.pricePosting.price),
                'Container Charge($)': formatNumberToTwoDecimal(ppa.pricePosting.containerCharge),
                'Price Promotion(Yes/No)': (ppa.pricePosting.pricePromotion) ? "Yes" : "No",
                'Is Effective Immediately(Yes/No)': 'No',
                Licensee: ppa.pricePosting.createdByLicensee ? ppa.pricePosting.createdByLicensee.name : '',
                'Submitted Date': format(ppa.pricePosting.createdAt, 'MM/dd/yyyy'),
                'Effective Date': format(ppa.pricePosting.effectiveDate, 'MM/dd/yyyy'),
                Status: ppa.pricePosting.status,
              }
        })
        exportToExcel(finalRes, 'Price-Postings-Promo-Audit-Report')
      })
      .catch(errorResponse => {
        dispatch(
          showError(
            'Unable to retrieve list of pricePostings, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}