import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { packageConfigurationStatus } from 'common/constants'
import {
  fetchPackageConfigurations,
  clearFilters,
  setPackageConfigurationPageNumber,
  setFiltersUsed,
} from 'ducks/packageConfigurations'

const PackageConfigurationFilters = () => {
  const dispatch = useDispatch()
  const { packageConfigForDropDown } = useSelector(store => store.global)
  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setPackageConfigurationPageNumber(0))
    dispatch(setFiltersUsed(true))
    dispatch(
      fetchPackageConfigurations(formikProps.values.packageConfigurationFilters)
    )
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue(`packageConfigurationFilters`, {
      configuration: '',
      status: '',
    })
  }

  return (
    <Form>
      <Field
        name='packageConfigurationFilters'
        render={formikProps => {
          formikProps.form.values.packageConfigurationFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.packageConfigurationFilters)
          )
          const formikValues = formikProps.form.values

          const handleAutoCompleteChange = (field, event) => {
            const selectedValue = event ? event : []
            formikProps.form.setFieldValue(
              `packageConfigurationFilters.${field}`,
              selectedValue
            )
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Configuration'
                    name='packageConfigurationFilters.configuration'
                    selectedValue={
                      formikValues.packageConfigurationFilters.configuration
                    }
                    formikProps={formikProps.form}
                    dropDownOptions={packageConfigForDropDown}
                    onValueChange={event =>
                      handleAutoCompleteChange('configuration', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    placeHolderText='Status'
                    name='packageConfigurationFilters.status'
                    formikProps={formikProps.form}
                    selectedValue={
                      formikValues.packageConfigurationFilters.status
                    }
                    dropDownOptions={packageConfigurationStatus}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    component={AutoCompleteComp}
                    isMulti={true}
                  />
                </Grid>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                isDisabled={!formikProps.form.isValid}
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(PackageConfigurationFilters)

export default WithFormikWrapper
