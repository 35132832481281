import React from 'react'
import { AppBar, IconButton, Hidden, Toolbar, Link } from '@material-ui/core'
import styled from 'styled-components'
import { Dehaze } from '@material-ui/icons'
import logo from 'assets/logo.jpg'
import { useDispatch } from 'react-redux'
import { setPublicSidebarOpen } from 'ducks/public/layout'

const Header = styled(AppBar)`
  && {
    background-color: #fff;
    border-bottom: 4px solid ${props => props.theme.gold};
  }
`

const HeaderContent = styled(Toolbar)`
  && {
    min-height: 50px;
  }
`

const Logo = styled.img`
  max-width: 250px;
  max-height: 40px;
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const HamburgerIcon = styled(Dehaze)`
  && {
    font-size: 30px;
  }
`

const PublicHeader = () => {
  const dispatch = useDispatch()
  return (
    <Header position='fixed'>
      <HeaderContent>
        <IconButton
          aria-label='sidebar'
          aria-haspopup='false'
          onClick={() => dispatch(setPublicSidebarOpen())}
          color='primary'
        >
          <HamburgerIcon color='primary' />
        </IconButton>
        <Hidden only='xs'>
          <Logo alt='ABC Logo' src={logo} />
        </Hidden>
        <FlexGrow />
        <Link href='/login' variant='body1'>
          Login
        </Link>
      </HeaderContent>
    </Header>
  )
}

export default PublicHeader
