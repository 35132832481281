import React from 'react'
import { Grid, Link } from '@material-ui/core'
import BulkUploadComp from 'components/common/BulkUploadComp'
import { useSelector } from 'react-redux'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { useDispatch } from 'tests/react-redux-hooks'
import { setPPBulkUploadDialog, bulkPricePostingProcess } from 'ducks/pricePostings'
import { exportToExcel } from 'common/helper'
import styled from 'styled-components'
import { CloudDownload } from '@material-ui/icons'
import CustomCard from 'components/common/CustomCard'
import CommonTooltip from 'components/common/CommonTooltip'

const StyledCloudDownloadIcon = styled(CloudDownload)`
  margin-bottom: -5px;
  margin-right: 4px;
`

const formatColumnName = value => {
  /*----map through the headers array and replace column names----*/
  return value.includes('manufacturer')
    ? value.replace(value, 'manufacturer')
    : value === 'trade name'
    ? value.replace(value, 'tradeName')
    : value.includes('product name')
    ? value.replace(value, 'productName')
    : value.includes('package')
    ? value.replace(value, 'packageConfiguration')
    : value.includes('product size')
    ? value.replace(value, 'productSize')
    : value.includes('container type')
    ? value.replace(value, 'containerType')
    : value === 'trading area'
    ? value.replace(value, 'tradingArea')
    : value.includes('county')
    ? value.replace(value, 'county')
    : value === 'prices to'
    ? value.replace(value, 'pricesTo')
    : value.includes('receiving')
    ? value.replace(value, 'receivingMethod')
    : value === 'price($)'
    ? value.replace(value, 'price')
    : value.includes('container charge($)')
    ? value.replace(value, 'containerCharge')
    : value.includes('price promotion')
    ? value.replace(value, 'pricePromotion')
    : value.includes('is effective')
    ? value.replace(value, 'isEffectiveImmediately')
    : value.replace(/\s(.)/g, function ($$, $1) {
        return $1.toUpperCase()
      })
}

const pricePostingBulkUploadTemplate = [
  {
    Manufacturer: '',
    'Trade name': '',
    'Product Name': '',
    'Package Configuration': '',
    'Product Size': '',
    'Container Type': '',
    County: '',
    'Prices To': '',
    'Receiving Method': '',
    'Price($)': '',
    'Container Charge($)': '',
    'Is Effective Immediately(Yes/No)': '',
    'Price Promotion(Yes/No)': '',
  },
]

const PricePostingBulkUpload = () => {
  const dispatch = useDispatch()
  const { uploadNotReady, bulkJson } = useSelector(store => store.bulkUpload)
  const handleCancel = () => {
    dispatch(setPPBulkUploadDialog(false))
  }
  return (
    <CustomCard
      title={
        <>
          Bulk upload
          <CommonTooltip
            tooltipTitle='Price posting through bulk upload:'
            informationList={[
              'The required fields are: Manufacturer, Product name, Package configuration, Product size, County, Prices to, Receiving method, Price($), Container charge($).',
              'Price and Container Charge should be numerical or $00.00 format only.',
              'Failed rows will be returned in an .xlsx file along with error messages.',
              'If an error field is received, please review, fix the error and upload the file again.',
              'Refer to template for file format.',
            ]}
          />
        </>
      }
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <BulkUploadComp
          validations={{
            requiredColumn: [
              'manufacturer',
              'product name',
              'package configuration',
              'product size',
              'container type',
              'county',
              'prices to',
              'receiving method',
              'price($)',
              'container charge($)',
              'price promotion(yes/no)',
            ],
            columns: [
              'manufacturer',
              'tradeName',
              'productName',
              'packageConfiguration',
              'productSize',
              'containerType',
              'tradingArea',
              'county',
              'pricesTo',
              'receivingMethod',
              'price($)',
              'containerCharge($)',
              'isEffectiveImmediately',
              'pricePromotion',
            ],
            uploadProcessName: 'Price Posting bulk upload',
          }}
          formatColumnName={formatColumnName}
        />
        <SaveAndCancelButton
          onSave={() => dispatch(bulkPricePostingProcess(bulkJson))}
          onCancel={() => handleCancel()}
          submitLabel='Upload'
          isDisabled={!uploadNotReady}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Link
          component='button'
          variant='body2'
          onClick={() => {
            exportToExcel(pricePostingBulkUploadTemplate, 'Price Posting Bulk Upload Template')
          }}
        >
          {<StyledCloudDownloadIcon />} Download Price Posting Bulk Upload Template
        </Link>
      </Grid>
    </CustomCard>
  )
}

export default PricePostingBulkUpload
