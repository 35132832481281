import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Collapse,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import { Form, Field, withFormik, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import styled from 'styled-components'
import { registerAsLE, verifyLeRegistrationCode } from 'ducks/authentication'
import SubmitButton from 'components/common/SubmitButton'
import ToggleMaskPassword from 'components/common/ToggleMaskPassword'
import NumericInput from 'components/common/NumericInput'
import PasswordConstraintsCheck from 'components/common/PasswordContraintsCheck'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { countries, usStates } from 'common/constants'

const MainGrid = styled(Grid)`
  && {
    margin-top: 20px;
    margin-bottom: 5px;
  }
`

const PaddedGrid = styled(Grid)`
  && {
    padding: 5px !important;

    @media (min-width: 600px) {
      padding: 15px !important;
    }

    @media (min-width: 1200px) {
      padding: 25px !important;
    }
  }
`

const GridWithDivider = styled(PaddedGrid)`
  && {
    border-right: none;

    @media (min-width: 600px) {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
`

const Title = styled(Typography)`
  && {
    font-weight: bold;
  }
`

const Disclaimer = styled(Typography)`
  color: #666;
`

const PaddedDisclaimer = styled(Disclaimer)`
  && {
    margin-bottom: 18px;
  }
`

const PaddedTypography = styled(Typography)`
  && {
    margin-bottom: 18px;
  }
`

const LegalEntitySignUp = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)
  const {
    registeringLE,
    isRegistrationSuccess,
    isRegistrationCodeVerified,
  } = useSelector(store => store.auth)

  const verificationValidations = Yup.object().shape({
    verificationCode: Yup.string().required('Required'),
  })

  // If the registration is successful redirect the user to the login page
  if (isRegistrationSuccess) {
    return <Redirect to='/login' />
  }

  return (
    <Formik
      initialValues={{ verificationCode: '' }}
      onSubmit={credentials => {
        dispatch(verifyLeRegistrationCode(credentials))
      }}
      validationSchema={verificationValidations}
    >
      <Form noValidate>
        <Field
          name='verifyRegistrationCode'
          render={formikProps => (
            <>
              {!isRegistrationCodeVerified ? (
                <MainGrid container spacing={2}>
                  <GridWithDivider item xs={12} sm={6}>
                    {!isRegistrationCodeVerified ? (
                      <>
                        <Title variant='h6'>Licensee Registration</Title>
                        <br />
                        <Typography variant='body1'>
                          To complete the registration please enter your unique
                          identifier.
                        </Typography>
                        <br />
                        <Disclaimer variant='body2'>
                          * Identifier is case sensitive and includes any
                          special characters provided.
                        </Disclaimer>
                      </>
                    ) : (
                      ''
                    )}
                  </GridWithDivider>

                  <PaddedGrid item xs={12} sm={6}>
                    <Field
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='verificationCode'
                      label='Unique Identifier'
                      name='verificationCode'
                      autoComplete='current-password'
                      component={ToggleMaskPassword}
                    />
                    {!isRegistrationCodeVerified && (
                      <SubmitButton isSubmitting={isLoading} />
                    )}
                  </PaddedGrid>
                </MainGrid>
              ) : (
                ''
              )}
              <Collapse in={isRegistrationCodeVerified} timeout='auto'>
                {isRegistrationCodeVerified && (
                  <>
                    <br />
                    <WithFormikWrapper
                      registeringLE={registeringLE}
                      isRegistrationCodeVerified={isRegistrationCodeVerified}
                    />
                  </>
                )}
              </Collapse>
            </>
          )}
        />
      </Form>
    </Formik>
  )
}
export default LegalEntitySignUp

const validations = Yup.object().shape({
  registeringLE: Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    username: Yup.string()
      .matches(/^\S+$/, `Username can't include spaces.`)
      .required('Required'),
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Required'),
    password: Yup.string()
      .matches(
        /([a-z])/,
        'Password should have minimum of one lower case letter.'
      )
      .matches(
        /([A-Z])/,
        'Password should have minimum of one uppercase case letter.'
      )
      .matches(
        /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/,
        'Password should have minimum of one special character.'
      )
      .matches(/([0-9])/, 'Password should have minimum of one number.')
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Required'),
    mailingAddress: Yup.object().shape({
      address: Yup.string().required('Required'),
      address2: Yup.string(),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipcode: Yup.string()
        .max(20, 'Zip Code must be at most 20 characters.')
        .required('Required'),
      country: Yup.string().required('Required'),
    }),
  }),
})

const CustomButton = styled(Button)`
  && {
    height: 3rem;
    margin: 20px 20px 20px 0;
  }
`

const RegisterLegalEntity = () => {
  const dispatch = useDispatch()
  const onRegisterLE = formikValues => {
    formikValues.handleSubmit()
    if (formikValues.isValid) {
      dispatch(registerAsLE(formikValues.values.registeringLE))
    }
  }
  const { isRegistrationCodeVerified } = useSelector(store => store.auth)
  const { isLoading } = useSelector(store => store.global)
  return (
    <Field
      name='registeringLE'
      render={formikProps => {
        formikProps.form.values.registeringLE = JSON.parse(
          JSON.stringify(formikProps.form.values.registeringLE)
        )
        const leDetails = formikProps.form.values.registeringLE

        const handleAutoCompleteChange = (event, parentType, elementType) => {
          if (elementType === 'country') {
            formikProps.form.setFieldValue(
              `registeringLE.${parentType}.state`,
              ''
            )
          }
          formikProps.form.setFieldValue(
            `registeringLE.${parentType}.${elementType}`,
            event.label
          )
        }

        let enteringNewPassword = formikProps.form.values.registeringLE.password
        return (
          <Grid container spacing={2}>
            <GridWithDivider item xs={12} sm={6}>
              <Title variant='h6'>Licensee</Title>
              <br />
              <Typography variant='body1'>
                To complete the registration please confirm the licensee
                details:
              </Typography>
              <br />
              <PaddedTypography
                variant='body1'
                align='left'
                gutterBottom={true}
              >
                <b>Licensee: </b>
                {leDetails.licensee}
              </PaddedTypography>
              <Typography variant='body1' align='left'>
                <b>Premises Address: </b>
              </Typography>
              <Typography variant='body1' align='left' gutterBottom={true}>
                {leDetails.premisesAddress.address}
                {leDetails.premisesAddress.address2
                  ? `, ${leDetails.premisesAddress.address2}`
                  : ''}
                <br />
                {`${leDetails.premisesAddress.city}`}
                {`, ${leDetails.premisesAddress.state}`}
                {`, ${leDetails.premisesAddress.zipcode}`}
                <br />
                {`${leDetails.premisesAddress.country}`}
                <br />
              </Typography>
              <PaddedDisclaimer variant='body2' align='left'>
                * Only one of your premises addresses is displayed.
              </PaddedDisclaimer>
              <Typography variant='body1' align='left' gutterBottom={true}>
                <b>Mailing Address: </b>
              </Typography>
              <Field
                variant='outlined'
                margin='normal'
                required={true}
                fullWidth
                label='Address Line 1'
                name='registeringLE.mailingAddress.address'
                autoComplete='address-line1'
                component={TextField}
              />
              <Field
                variant='outlined'
                margin='normal'
                required={false}
                fullWidth
                label='Address Line 2'
                name='registeringLE.mailingAddress.address2'
                autoComplete='address-line2'
                component={TextField}
              />
              <Field
                variant='outlined'
                margin='normal'
                required={true}
                fullWidth
                label='City'
                name='registeringLE.mailingAddress.city'
                autoComplete='address-level2'
                component={TextField}
              />
              <Field
                placeHolderText='Country *'
                selectedValue={
                  formikProps.form.values.registeringLE.mailingAddress
                    .country && {
                    label:
                      formikProps.form.values.registeringLE.mailingAddress
                        .country,
                    value:
                      formikProps.form.values.registeringLE.mailingAddress
                        .country,
                  }
                }
                name='registeringLE.mailingAddress.country'
                formikProps={formikProps.form}
                dropDownOptions={countries}
                onValueChange={event =>
                  handleAutoCompleteChange(event, 'mailingAddress', 'country')
                }
                component={AutoCompleteComp}
              />
              {formikProps.form.values.registeringLE.mailingAddress.country ===
              'United States' ? (
                <Field
                  placeHolderText='State *'
                  selectedValue={
                    formikProps.form.values.registeringLE.mailingAddress
                      .state && {
                      label:
                        formikProps.form.values.registeringLE.mailingAddress
                          .state,
                      value:
                        formikProps.form.values.registeringLE.mailingAddress
                          .state,
                    }
                  }
                  name='registeringLE.mailingAddress.state'
                  formikProps={formikProps.form}
                  dropDownOptions={usStates}
                  onValueChange={event =>
                    handleAutoCompleteChange(event, 'mailingAddress', 'state')
                  }
                  component={AutoCompleteComp}
                />
              ) : (
                <Field
                  variant='outlined'
                  margin='normal'
                  required={true}
                  fullWidth
                  label='State'
                  name='registeringLE.mailingAddress.state'
                  autoComplete='address-level1'
                  component={TextField}
                />
              )}
              <Field
                variant='outlined'
                margin='normal'
                required={true}
                fullWidth
                label='Zip Code'
                name='registeringLE.mailingAddress.zipcode'
                autoComplete='postal-code'
                component={TextField}
              />
            </GridWithDivider>

            <PaddedGrid item xs={12} sm={6}>
              <Title variant='h6'>User Details</Title>
              <br />
              <Typography variant='body1'>
                Please create the licensee administrator by entering the details
                below:
              </Typography>
              <br />
              <Field
                variant='outlined'
                margin='normal'
                required={isRegistrationCodeVerified}
                fullWidth
                id='firstName'
                label='First Name'
                name='registeringLE.firstName'
                autoComplete='first name'
                component={TextField}
              />
              <Field
                variant='outlined'
                margin='normal'
                required={isRegistrationCodeVerified}
                fullWidth
                name='registeringLE.lastName'
                label='Last Name'
                id='lastName'
                component={TextField}
                autoComplete='last name'
              />
              <Field
                variant='outlined'
                margin='normal'
                required={isRegistrationCodeVerified}
                fullWidth
                disabled={false}
                name='registeringLE.email'
                label='Email'
                id='email'
                component={TextField}
                autoComplete='email'
              />
              <Field
                variant='outlined'
                margin='normal'
                fullWidth
                required={isRegistrationCodeVerified}
                name='registeringLE.username'
                label='Username'
                id='username'
                component={TextField}
                autoComplete='username'
              />
              <Field
                variant='outlined'
                margin='normal'
                required={isRegistrationCodeVerified}
                fullWidth
                name='registeringLE.password'
                label='Password'
                type='password'
                id='password'
                component={ToggleMaskPassword}
              />
              <PasswordConstraintsCheck
                enteringNewPassword={enteringNewPassword}
              />
              <Field
                variant='outlined'
                margin='normal'
                required={isRegistrationCodeVerified}
                fullWidth
                name='registeringLE.confirmPassword'
                label='Confirm Password'
                type='password'
                id='confirmPassword'
                component={ToggleMaskPassword}
              />
              <Field
                variant='outlined'
                margin='normal'
                fullWidth
                name='registeringLE.phone'
                label='Phone'
                id='phone'
                component={NumericInput}
                autoComplete='phone'
                format={'### ### #### ### ##'}
              />
            </PaddedGrid>
            <CustomButton
              onClick={() => onRegisterLE(formikProps.form)}
              fullWidth
              variant='contained'
              color='primary'
            >
              {isLoading ? (
                <CircularProgress color='inherit' size={25} />
              ) : (
                'Submit'
              )}
            </CustomButton>
          </Grid>
        )
      }}
    />
  )
}

export const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(RegisterLegalEntity)
