import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextField } from 'formik-material-ui'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import GenericDatePicker from 'components/common/GenericDatePicker'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import { getLEsForDropDown } from 'ducks/global'
import { licenseCategoryList, productStatus } from 'common/constants'
import { checkIfUserIsABC } from 'common/helper'
import {
  getProductsList,
  clearFilters,
  setProductsPageNumber,
  setProductBulkSelection,
  setFiltersUsed,
} from 'ducks/products'

const DatePickerWrapper = styled(Grid)`
  display: flex;
`
const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`
const ProductsFilters = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLEsForDropDown())
  }, [dispatch])

  const { user } = useSelector(store => store.auth)
  const { lEsForDropDown } = useSelector(store => store.global)
  const { listType } = useSelector(store => store.products)
  const userHasAbcRole = checkIfUserIsABC(user.role.id)
  const loggedInUserIsManufacturer =
    user.legalEntity &&
    user.legalEntity.licenseCategory.includes(licenseCategoryList.manufacturer)
  const updatedLEsForDropDown = lEsForDropDown.filter(
    le => (le.licenseCategory !== 'Wholesaler' || !le.licensed)
  )

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setProductsPageNumber(0))
    dispatch(setProductBulkSelection([]))
    dispatch(setFiltersUsed(true))
    dispatch(getProductsList(formikProps.values.productsFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue(`productsFilters`, {
      manufacturer: [],
      tradeName: '',
      name: '',
      status: '',
      oldName: [],
      createdAt: null,
      operatorForCreated: 'equal',
      operatorForUpdated: 'equal',
      updatedAt: null,
    })
  }

  return (
    <Form>
      <Field
        name='productsFilters'
        render={formikProps => {
          formikProps.form.values.productsFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.productsFilters)
          )
          const formikValues = formikProps.form.values
          const productsFiltersValues = formikProps.form.values.productsFilters

          const handleAutoCompleteChange = (field, event) => {
            const selectedValue = event ? event : []
            formikProps.form.setFieldValue(
              `productsFilters.${field}`,
              selectedValue
            )
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Manufacturer Name'
                    name='productsFilters.manufacturer'
                    selectedValue={formikValues.productsFilters.manufacturer}
                    formikProps={formikProps.form}
                    dropDownOptions={updatedLEsForDropDown}
                    onValueChange={event =>
                      handleAutoCompleteChange('manufacturer', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                    isDisabled={
                      (listType === 'filtered' && loggedInUserIsManufacturer) ||
                      false
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Trade Name'
                    name='productsFilters.tradeName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Product Name'
                    name='productsFilters.name'
                    component={TextField}
                  />
                </Grid>
                {userHasAbcRole && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Field
                      placeHolderText='Status'
                      name='productsFilters.status'
                      formikProps={formikProps.form}
                      selectedValue={formikValues.productsFilters.status}
                      dropDownOptions={productStatus}
                      onValueChange={event =>
                        handleAutoCompleteChange('status', event)
                      }
                      component={AutoCompleteComp}
                    />
                  </Grid>
                )}
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Added Date'
                    name='productsFilters.createdAt'
                    component={GenericDatePicker}
                    value={{
                      begin: productsFiltersValues.addedDateFrom,
                      end: productsFiltersValues.addedDateTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'productsFilters.addedDateFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'productsFilters.addedDateTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Edited Date'
                    name='productsFilters.updatedAt'
                    component={GenericDatePicker}
                    value={{
                      begin: productsFiltersValues.editedDateFrom,
                      end: productsFiltersValues.editedDateTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'productsFilters.editedDateFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'productsFilters.editedDateTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                isDisabled={!formikProps.form.isValid}
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(ProductsFilters)

export default WithFormikWrapper
