import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import SubmitButton from 'components/common/SubmitButton'
import { forgotUsername } from 'ducks/authentication'

const validations = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Required'),
})

const ForgotUsername = () => {
  const dispatch = useDispatch()

  const { isUsernameRecoverySent } = useSelector(store => store.auth)

  // If the username recovery email is sent, redirect to login page
  if (isUsernameRecoverySent) {
    return <Redirect to='/login' />
  }

  return (
    <>
      <Helmet>
        <title>Recover Username</title>
      </Helmet>
      <Typography variant='h6'>Recover Username</Typography>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(forgotUsername(values.email)).then(() => {
            setSubmitting(false)
          })
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='forgotUsername'
            render={formikProps => (
              <>
                <Field
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  component={TextField}
                  autoFocus
                />
                <SubmitButton isSubmitting={formikProps.form.isSubmitting} />
              </>
            )}
          />
        </Form>
      </Formik>
    </>
  )
}

export default ForgotUsername
