import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { format, startOfDay, endOfDay, compareAsc, parseISO } from 'date-fns'
import { licenseCategoryList } from 'common/constants'
import jwt from 'jsonwebtoken'
import { API, graphqlOperation } from 'aws-amplify'
import { licenseCategoryUpdateTemplate, productsAddedTemplate } from 'common/templates'
import { pricePostingURL } from 'common/constants'
import { getUsersQuery } from 'graphql/queries'

export const getToken = payload => {
  return jwt.sign(payload, process.env.REACT_APP_JWT_SECRET, {
    expiresIn: 20,
  })
}

// Update email attribute in Cognito user
export const updateCognitoEmail = (username, newEmail) => {
  fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_URL}/update-user-attributes`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + getToken({ code: username }),
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify({
      username: username,
      type: 'EMAIL_CHANGE',
      email: newEmail,
    }),
  })
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

export const exportToExcel = (excelData, fileName) => {
  let objectMaxLength = []
  for (let i = 0; i < excelData.length; i++) {
    let value = Object.values(excelData[i])
    for (let j = 0; j < value.length; j++) {
      if (typeof value[j] == 'number' || !value[j]) {
        objectMaxLength[j] = 10
      } else {
        objectMaxLength[j] =
          objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length + 1
      }
    }
    let key = Object.keys(excelData[i])
    for (let j = 0; j < key.length; j++) {
      objectMaxLength[j] =
        objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length + 1
    }
  }
  const wsCols = []
  for (const keyLength of objectMaxLength) {
    wsCols.push({ width: keyLength })
  }

  const ws = XLSX.utils.json_to_sheet(excelData)
  ws['!cols'] = wsCols
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

export const getPayloadFromActions = (actions, type) => {
  return actions.filter(action => action.type === type)[0].payload
}

export const verifyActionExists = (actions, type) => {
  return actions.filter(action => action.type === type).length > 0
}

export const formatDate = date => format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
export const getStartOfDay = date => formatDate(startOfDay(new Date(date)))
export const getEndOfDay = date => formatDate(endOfDay(new Date(date)))

// Notify LE admins via email
export const notifyLEAdmins = (
  licenseeId,
  messageType,
  customSubject = '',
  customMessage = '',
  extraEmail = ''
) => {
  let subject = 'ABC Price Posting - ',
    message = ''

  if (messageType === 'PRODUCT_ADDED') {
    subject += 'Products Added'
    message = productsAddedTemplate()
  } else if (messageType === 'LICENSEE_CATEGORY_CHANGED') {
    subject += 'License Type Change'
    message = licenseCategoryUpdateTemplate()
  } else if (messageType === 'CUSTOM') {
    subject += customSubject
    message = customMessage
  }

  // Retrieve admin users from licensee
  API.graphql(
    graphqlOperation(getUsersQuery, {
      where: {
        legalEntityId: parseInt(licenseeId),
        roleId: 4,
        status: 'Active',
      }, // LE Admin
      orderBy: 'createdAt_ASC',
      limit: 1000,
      offset: 0,
    })
  ).then(res => {
    if (res.data.users) {
      const users = res.data.users.results
      // Retrieve admin users emails
      let adminEmails = users.map(user => user.email)
      if (extraEmail.length > 0) adminEmails.push(extraEmail)
      // Remove duplicate emails
      adminEmails = [...new Set(adminEmails)].join(',')
      // Send emails
      const params = {
        body: {
          to: adminEmails,
          subject,
          message,
          buttonLink: pricePostingURL,
          buttonText: 'Visit Price Posting Application',
        },
      }
      API.post('ABC-API', '/send-email', params)
    }
  })
}

// ---------------------- GRAPHQL QUERY HELPERS ----------------------

export const cleanUp = data => (data ? data.replace(/\\/g, '\\\\').replace(/"/g, '\\"').trim() : '')

export const addGraphQLDateParam = (where, prop, date, operator) => {
  if (date) {
    switch (operator) {
      case 'equal':
        where[`${prop}_gte`] = getStartOfDay(date)
        where[`${prop}_lte`] = getEndOfDay(date)
        break
      case '_gt':
        where[`${prop}${operator}`] = getEndOfDay(date)
        break
      case '_lt':
        where[`${prop}${operator}`] = getStartOfDay(date)
        break
      default:
        where[`${prop}${operator}`] = date
    }
  }
  return where
}

export const removeExtraCharFromPrice = price => {
  return price && typeof price !== 'number'
    ? parseFloat(price.replace(/[$,]/gi, ''))
    : price && typeof price === 'number'
    ? price
    : 0
}

// Write correct GraphQL syntax of a date comparison from UI operators
export const buildDateClause = (prop, date, operator) => {
  if (date) {
    switch (operator) {
      case 'equal':
        return `, ${prop}_gte: "${getStartOfDay(date)}", ${prop}_lte: "${getEndOfDay(date)}"`
      case '_gt':
        return `, ${prop}${operator}: "${getEndOfDay(date)}"`
      case '_lt':
        return `, ${prop}${operator}: "${getStartOfDay(date)}"`
      default:
        return `, ${prop}${operator}: "${date}"`
    }
  }
  return ''
}

export const comparisonClauseBuilder = (where, prop, value, operator) => {
  if (value) {
    switch (operator) {
      case 'equal':
        where[`${prop}_gte`] = removeExtraCharFromPrice(value)
        where[`${prop}_lte`] = removeExtraCharFromPrice(value)
        break
      case '_gt':
        where[`${prop}${operator}`] = removeExtraCharFromPrice(value)
        break
      case '_lt':
        where[`${prop}${operator}`] = removeExtraCharFromPrice(value)
        break
      default:
        where[`${prop}${operator}`] = value
    }
  }
  return where
}

export const getDateOnly = myDate => {
  const date = myDate
  const dd = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear()
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (dd <= 9 ? '0' + dd : dd)
}

export const isDateSameAsToday = date => {
  const currentDate = getDateOnly(new Date())
  const pricePostDate = getDateOnly(new Date(date))
  const comparedSubmittedAndTodaysDate = compareAsc(parseISO(pricePostDate), parseISO(currentDate))
  return comparedSubmittedAndTodaysDate === 0
}

export const formatNumberToTwoDecimal = num => {
  // Convert Number to a string
  let value = String(num)
  // Split the input string into two arrays containing integers/decimals
  const res = value.split('.')
  // If there is no decimal point or decimal places greater than 2 or equal to 1.
  if (res.length === 1 || res[1].length > 2 || res[1].length === 1) {
    // Set the number to two decimal places
    num = num.toFixed(2)
  }
  // Return updated or original number.
  return `$${num}`
}

export const formatNumberToThreeDecimal = num => {
  // Convert Number to a string
  let value = String(num)
  // Split the input string into two arrays containing integers/decimals
  const res = value.split('.')
  // If there is no decimal point or decimal places greater than 2 or equal to 1.
  if (res.length === 1 || res[1].length === 2 || res[1].length === 1) {
    // Set the number to two decimal places
    num = num.toFixed(2)
  } else if (res[1].length > 2) {
    num = num.toFixed(3)
  }
  // Return updated or original number.
  return `$${num}`
}

export const convertSizeOfBBL = (size, unit) => {
  // Convert size to string
  const productSize = size.toString()
  // If unit is BBL
  if (unit === 'BBL') {
    // Create size map
    const bblMap = { '1/2': '0.5', '1/4': '0.25', '1/6': '0.16' }
    // Return decimal or fraction depending on the size provided
    return productSize.includes('/')
      ? bblMap[productSize] // return decimal
      : Object.keys(bblMap).find(key => bblMap[key] === productSize) // return fraction
  }
  // If not BBL return the size without any conversion
  return productSize
}

export const areAllCountiesSelected = county => {
  return county && county.map(c => c.value).includes('All Counties')
}

export const checkUserIsManufacturerNotCoc = usersLicenseeDetails => {
  let loggedInCategory = ''
  let loggedInLicenseType = ''
  if (usersLicenseeDetails) {
    loggedInCategory = usersLicenseeDetails.licenseCategory
    loggedInLicenseType = usersLicenseeDetails.licenseTypes.map(lt => lt.id)
    return (
      loggedInCategory === licenseCategoryList.manufacturer && !loggedInLicenseType.includes('7')
    )
  } else {
    return false
  }
}

export const checkUserIsCocOnly = usersLicenseeDetails => {
  let loggedInLicenseType
  if (usersLicenseeDetails) {
    loggedInLicenseType = usersLicenseeDetails.licenseTypes.map(lt => lt.id)
    return loggedInLicenseType.includes('7')
  } else {
    return false
  }
}

export const checkUserIsManufacturerAndWholesaler = usersLicenseeDetails => {
  return usersLicenseeDetails
    ? usersLicenseeDetails.licenseCategory === licenseCategoryList.both
    : false
}

export const checkIfUserIsLicensee = userRoleId => {
  return ['4', '5'].includes(userRoleId)
}

export const checkIfUserIsABC = userRoleId => {
  return ['1', '2', '3'].includes(userRoleId)
}

export class CheckLicensee {
  static get modelName() {
    return 'pricePostedBy'
  }

  constructor({ ifCreatedByLicenseeIs }) {
    this.ifCreatedByLicenseeIs = ifCreatedByLicenseeIs.toString()
  }
}

export class CheckManufacturerForProductCreation {
  static get modelName() {
    return 'productsBy'
  }

  constructor({ ifCreatedByManufacturerIs }) {
    this.ifCreatedByManufacturerIs = ifCreatedByManufacturerIs
  }
}

export const whereClauseBuilderForPP = whereClause => {
  let finalParams = {}
  if (whereClause) {
    if (whereClause.createdByLicensee && whereClause.createdByLicensee.length > 0) {
      finalParams.createdByLicensee_in = whereClause.createdByLicensee.map(cl => parseInt(cl.value))
    }
    if (whereClause.createdByLicenseeNot && whereClause.createdByLicenseeNot.length > 0) {
      finalParams.createdByLicensee_notIn = whereClause.createdByLicenseeNot.map(clNot =>
        parseInt(clNot.value)
      )
    }
    if (whereClause.manufacturer && whereClause.manufacturer.length > 0) {
      finalParams.manufacturerId_in = whereClause.manufacturer.map(stat => parseInt(stat.value))
    }
    if (whereClause.filteredListByCreatedByLicensee) {
      finalParams.createdByLicensee = parseInt(whereClause.filteredListByCreatedByLicensee.value)
    }
    if (whereClause.products && whereClause.products.length > 0) {
      finalParams.productId_in = whereClause.products.map(product => parseInt(product.value))
    }
    if (whereClause.productLike) {
      finalParams.productName_like = cleanUp(whereClause.productLike)
    }
    if (whereClause.productStatus) {
      finalParams.productStatus = cleanUp(whereClause.productStatus)
    }
    if (whereClause.tradeName) {
      finalParams.tradeName_like = cleanUp(whereClause.tradeName)
    }
    if (whereClause.package.length > 0) {
      finalParams.packageId_in = whereClause.package.map(p => parseInt(p.value))
    }
    if (whereClause.size.length > 0) {
      finalParams.sizeId_in = whereClause.size.map(s => parseInt(s.value))
    }
    if (whereClause.county && whereClause.county.length > 0) {
      finalParams.county_in = whereClause.county.map(county => county.value)
    }
    if (whereClause.pricePromotion) {
      finalParams.pricePromotion = whereClause.pricePromotion.value === 'true' ? true : false
    }
    if (whereClause.pricesTo.length > 0) {
      finalParams.pricesTo_in = whereClause.pricesTo.map(pt => parseInt(pt.value))
    }
    finalParams = comparisonClauseBuilder(
      finalParams,
      'price',
      whereClause.price,
      whereClause.operatorForPrice
    )
    finalParams = comparisonClauseBuilder(
      finalParams,
      'containerCharge',
      whereClause.containerCharge,
      whereClause.operatorForContainerCharge
    )
    if (whereClause.status && whereClause.status.length > 0) {
      finalParams.status_in = whereClause.status.map(s => s.value)
    }
    if (whereClause.effectiveFrom) {
      finalParams.effectiveDate_gte = getStartOfDay(whereClause.effectiveFrom)
      finalParams.effectiveDate_lte = getEndOfDay(whereClause.effectiveTo)
    }
    if (whereClause.submittedFrom) {
      finalParams.createdAt_gte = getStartOfDay(whereClause.submittedFrom)
      finalParams.createdAt_lte = getEndOfDay(whereClause.submittedTo)
    }
    if (whereClause.receivingMethod && whereClause.receivingMethod.length > 0) {
      finalParams.receivingMethod_in = whereClause.receivingMethod.map(rm => rm.value)
    }
    if (whereClause.includeCompetitivePost === 'Yes') {
      finalParams.competitivePostId_not = null
    }
    if (whereClause.includeCompetitivePost === 'No') {
      finalParams.competitivePostId = null
    }
  }
  return finalParams
}

export const whereClauseBuilderForProducts = whereClause => {
  let finalParams = {}

  if (whereClause.manufacturerId_in && whereClause.manufacturerId_in.length > 0)
    finalParams.manufacturerId_in = whereClause.manufacturerId_in
  if (whereClause.manufacturerId) {
    finalParams.manufacturerId = whereClause.manufacturerId
  }
  if (whereClause.name) finalParams.name_like = cleanUp(whereClause.name)
  if (whereClause.tradeName) {
    finalParams.tradeName_like = cleanUp(whereClause.tradeName)
  }
  if (whereClause.addedDateFrom && whereClause.addedDateTo) {
    finalParams.createdAt_gte = getStartOfDay(whereClause.addedDateFrom)
    finalParams.createdAt_lte = getEndOfDay(whereClause.addedDateTo)
  }
  if (whereClause.editedDateFrom && whereClause.editedDateTo) {
    finalParams.updatedAt_gte = getStartOfDay(whereClause.editedDateFrom)
    finalParams.updatedAt_lte = getEndOfDay(whereClause.editedDateTo)
  }
  if (whereClause.status) {
    finalParams.status = whereClause.status.value
  }
  return finalParams
}
