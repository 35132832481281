import React from 'react'
import { Helmet } from 'react-helmet'
import { Grid } from '@material-ui/core'
import ProductSizes from './productSizes/ProductSizes'
import PricesTo from './pricesTo/PricesTo'
import PackageConfigurations from './packageConfiguration/PackageConfigurations'

const GlobalSettings = () => {
  return (
    <>
      <Helmet>
        <title>Global Settings</title>
      </Helmet>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ProductSizes />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <PricesTo />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <PackageConfigurations />
        </Grid>
      </Grid>
    </>
  )
}

export default GlobalSettings
