import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextField } from 'formik-material-ui'
import { Field, Form, withFormik } from 'formik'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import GenericDatePicker from 'components/common/GenericDatePicker'
import CustomCard from 'components/common/CustomCard'
import { AbilityContext, Can } from 'common/abilityContext'
import { statusForDropdown } from 'common/constants'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { getLEsForDropDown, getRolesForDropDown } from 'ducks/global'
import {
  clearFilters,
  setLeUsersPageNumber,
  getLeUsersList,
} from 'ducks/legalEntityUser'
import AutoCompleteComp from 'components/common/AutoCompleteComp'

const DatePickerWrapper = styled(Grid)`
  display: flex;
`
const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    input {
      height: 17px;
    }
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`
const LeUsersFilters = () => {
  const dispatch = useDispatch()
  const { rolesForDropDown, lEsForDropDown } = useSelector(
    store => store.global
  )
  const abilityFunc = useContext(AbilityContext)

  useEffect(() => {
    dispatch(getRolesForDropDown())
    dispatch(getLEsForDropDown())
  }, [dispatch])

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setLeUsersPageNumber(0))
    dispatch(getLeUsersList(formikProps.values.leUsersFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(clearFilters())
    formikProps.setFieldValue(`leUsersFilters`, {
      firstName: '',
      lastName: '',
      email: '',
      role: [],
      createdAt: null,
      operatorForCreated: 'equal',
      operatorForUpdated: 'equal',
      updatedAt: null,
      status: [],
      licensee: [],
    })
  }

  const updatedRolesList = rolesForDropDown
    ? rolesForDropDown.filter(
        role =>
          abilityFunc.can('seeRolesForLeUsersFilters', role.label) === true
      )
    : []

  return (
    <Form>
      <Field
        name='leUsersFilters'
        render={formikProps => {
          formikProps.form.values.leUsersFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.leUsersFilters)
          )
          const formikValues = formikProps.form.values

          const handleAutoCompleteChange = (field, event) => {
            formikProps.form.setFieldValue(`leUsersFilters.${field}`, event)
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='First Name'
                    name='leUsersFilters.firstName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Last Name'
                    name='leUsersFilters.lastName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Email'
                    name='leUsersFilters.email'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Roles'
                    name='leUsersFilters.role'
                    selectedValue={formikValues.leUsersFilters.role}
                    formikProps={formikProps.form}
                    dropDownOptions={updatedRolesList}
                    onValueChange={event =>
                      handleAutoCompleteChange('role', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Status'
                    name='leUsersFilters.status'
                    selectedValue={formikValues.leUsersFilters.status}
                    formikProps={formikProps.form}
                    dropDownOptions={statusForDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Can I='select' this='licenseeFromLeUserFilters'>
                  {() => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        placeHolderText='Licensee'
                        name='leUsersFilters.licensee'
                        selectedValue={formikValues.leUsersFilters.licensee}
                        formikProps={formikProps.form}
                        dropDownOptions={lEsForDropDown}
                        onValueChange={event =>
                          handleAutoCompleteChange('licensee', event)
                        }
                        isMulti={true}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                  )}
                </Can>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Added Date'
                    name='leUsersFilters.createdAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.leUsersFilters.createdAtFrom,
                      end: formikValues.leUsersFilters.createdAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'leUsersFilters.createdAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'leUsersFilters.createdAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Edited Date'
                    name='leUsersFilters.updatedAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.leUsersFilters.updatedAtFrom,
                      end: formikValues.leUsersFilters.updatedAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'leUsersFilters.updatedAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'leUsersFilters.updatedAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(LeUsersFilters)

export default WithFormikWrapper
