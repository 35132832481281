import React from 'react'
import { darken } from 'polished'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Card, CardContent, Button, Typography } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import { checkIfUserIsABC } from 'common/helper'
import styled from 'styled-components'
import {
  deleteNewsUpdateByID,
  resetNewsUpdateDetails,
  setNewsUpdateDetails,
  setConfirmNewsUpdateDelete,
  toggleNewsUpdateDialog,
} from 'ducks/newsUpdate'
import { setEditMode } from 'ducks/global'
import ConfirmationModal from 'components/common/ConfirmationModal'

const ActionItems = styled.div`
  position: absolute;
  right: 10px;
  text-align: right;
  top: 26px;
  && svg {
    font-size: 16px;
  }
  && button {
    min-width: 0;
  }
`

const Title = styled(Typography)`
  color: ${props => darken(0.1, props.theme.blue)};
  font-size: 1.7rem;
  font-weight: 600;
`

const CardTitle = styled(Typography)`
  && {
    font-weight: 600;
    margin-bottom: 2px;
  }
`

const CustomCard = styled(Card)`
  && {
    margin-bottom: 20px;
  }
`

const CustomCardContent = styled(CardContent)`
  && {
    position: relative;
    padding: 10px 16px 0 !important;
  }
  && a {
    text-decoration: none;
  }
  && a:hover {
    text-decoration: underline;
  }
`

const NewFlag = styled.div`
  && {
    background: #ff3620;
    color: #fff;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 11px;
    padding: 1px 0 2px;
    border-radius: 0 0 3px 3px;
    letter-spacing: 0.1em;
    width: 44px;
    text-align: center;
  }
`
//
const NewsUpdates = newsUpdateResults => {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store.auth)
  const { confirmNewsUpdateDelete } = useSelector(store => store.newsUpdate)
  const userIsABC = user.role ? checkIfUserIsABC(user.role.id) : false
  const filterUpdateResults = newsUpdateResults.newsList.results
    .filter(item => {
      const today = new Date()
      const itemDate = new Date(item.createdAt)
      const dateDiff = (today - itemDate) / (1000 * 60 * 60 * 24)
      return dateDiff < 90
    })
    .map(item => {
      const today = new Date()
      const itemDate = new Date(item.createdAt)
      const dateDiff = (today - itemDate) / (1000 * 60 * 60 * 24)
      return {
        ...item,
        new: dateDiff < 22,
      }
    })

  const finalUpdateResults = filterUpdateResults.sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  )

  const getDateOnly = myDate => {
    const date = utcToZonedTime(myDate.toISOString(), 'America/Los_Angeles')
    const dd = date.getDate()
    const m = date.getMonth() + 1
    const y = date.getFullYear()
    return (m <= 9 ? '0' + m : m) + '-' + (dd <= 9 ? '0' + dd : dd) + '-' + y
  }

  const handleEditClicked = item => {
    dispatch(setEditMode(true))
    dispatch(setNewsUpdateDetails(item))
    dispatch(toggleNewsUpdateDialog(true))
  }

  const handleDeleteClicked = item => {
    dispatch(setNewsUpdateDetails(item))
    dispatch(setConfirmNewsUpdateDelete(true))
  }

  const handleDeleteConfirmation = () => {
    dispatch(deleteNewsUpdateByID())
    dispatch(setConfirmNewsUpdateDelete(false))
  }

  const handleDeleteCancel = () => {
    dispatch(resetNewsUpdateDetails())
    dispatch(setConfirmNewsUpdateDelete(false))
  }

  return (
    <>
      <Title variant='h4' component='h2'>
        News & Updates
      </Title>
      <br />
      {finalUpdateResults.map((item, index) => (
        <CustomCard key={index}>
          <CustomCardContent>
            {item.new && <NewFlag>NEW</NewFlag>}
            {userIsABC && item.id && (
              <ActionItems>
                <Button onClick={() => handleEditClicked(item)}>
                  <Edit color='primary' />
                </Button>
                <Button onClick={() => handleDeleteClicked(item)}>
                  <Delete color='primary' />
                </Button>
              </ActionItems>
            )}
            <CardTitle component='h3'>{item.title}</CardTitle>
            <i>{getDateOnly(new Date(item.createdAt))}</i>
            <p>
              {item.description}
              {item.link && String.fromCharCode(0x2026)}
              {item.link && (
                <>
                  <br />
                  <br />
                  <a
                    href={item.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='view full update (opens in new window)'
                  >
                    read more
                  </a>
                </>
              )}
            </p>
          </CustomCardContent>
        </CustomCard>
      ))}
      {!finalUpdateResults.length && (
        <>
          There are no recent news updates.
          <p>
            <a
              href='https://www.abc.ca.gov/licensing/beer-price-posting/program-updates/'
              target='_blank'
              rel='noopener noreferrer'
              title='View archived news updates (opens new window)'
            >
              View archived news updates &raquo;
            </a>
          </p>
        </>
      )}
      <ConfirmationModal
        isConfirmationModalOpen={confirmNewsUpdateDelete}
        confirmationList={['Are you sure you want to delete this News Update']}
        onConfirmation={() => handleDeleteConfirmation()}
        onConfirmationModalClosed={() => handleDeleteCancel()}
      />
    </>
  )
}

export default NewsUpdates
