import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Avatar } from '@material-ui/core'
import styled from 'styled-components'
import { format } from 'date-fns'
import { AttachMoney } from '@material-ui/icons'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'

const MoneyIcon = styled(AttachMoney)`
  && {
    font-size: 3.03125rem;
  }
`
const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`
const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewPricePost = () => {
  const { isAuxLoading, loadingAuxText } = useSelector(store => store.global)
  const { pricePostingDetails } = useSelector(store => store.publicPricePosts)
  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <CustomCard
          profileIcon={
            <UserIcon alt='Price Posting Icon'>
              <MoneyIcon />
            </UserIcon>
          }
          profileTitle={
            <>
              <TitleLabel children={`Product Name`} variant='body2' />
              <TitleValue
                children={
                  pricePostingDetails.product &&
                  pricePostingDetails.product.label
                }
                variant='h6'
              />
            </>
          }
          divider
        >
          <DataGrid
            label='Manufacturer'
            value={
              pricePostingDetails.manufacturer &&
              pricePostingDetails.manufacturer.label
            }
          />
          <DataGrid label='Trade Name' value={pricePostingDetails.tradeName} />
          <DataGrid
            label='Package Configuration'
            value={
              pricePostingDetails.package && pricePostingDetails.package.label
            }
          />
          <DataGrid
            label='Size'
            value={pricePostingDetails.size && pricePostingDetails.size.label}
          />
          <DataGrid
            label='County'
            value={
              pricePostingDetails.county && pricePostingDetails.county[0].label
            }
          />
          <DataGrid
            label='Trading Area'
            value={pricePostingDetails.tradingArea}
          />
          <DataGrid
            label='Prices To'
            value={
              pricePostingDetails.pricesTo && pricePostingDetails.pricesTo.label
            }
          />
          <DataGrid label='Price' value={pricePostingDetails.price} />
          <DataGrid
            label='Container Charge'
            value={pricePostingDetails.containerCharge}
          />
          <DataGrid
            label='Receiving Method'
            value={
              pricePostingDetails.receivingMethod &&
              pricePostingDetails.receivingMethod[0].label
            }
          />
          <DataGrid
            label='Status'
            value={
              pricePostingDetails.status && pricePostingDetails.status.label
            }
          />
          <DataGrid
            label='Licensee (Posted By)'
            value={
              pricePostingDetails.createdByLicensee &&
              pricePostingDetails.createdByLicensee.name
            }
          />
          <DataGrid
            label='Submit date'
            value={
              pricePostingDetails.createdAt
                ? format(pricePostingDetails.createdAt, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Effective date'
            value={
              pricePostingDetails.effectiveDate
                ? format(pricePostingDetails.effectiveDate, 'MM/dd/yyyy')
                : ''
            }
          />
          <DataGrid
            label='Competitive'
            value={pricePostingDetails.competitivePostId ? 'Yes' : 'No'}
          />
        </CustomCard>
      )}
    </>
  )
}

export default ViewPricePost
