import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { setLoading, showError, setEditMode, showSuccess } from './global'
import { roles } from 'common/constants'
import {
  getOwnProfileByIDQuery,
  getLeAdminOwnProfileByIDQuery,
} from 'graphql/queries'
import {
  updateProfileMutation,
  createAddressMutation,
  updateAddressMutation,
  updateProfileLegalEntityMutation,
} from 'graphql/mutations'
import { updateCognitoEmail } from 'common/helper'

/* Use this function to fetch user by ID after clicking each list Item */
/* The ID here should be dynamic */
export const fetchOwnProfileByID = (id, getLeName = false) => {
  return async dispatch => {
    dispatch(setLoading(true))
    await API.graphql(
      graphqlOperation(getOwnProfileByIDQuery, { id: parseInt(id), getLeName })
    )
      .then(response => {
        let newResponse = {}
        if (response.data.users.count > 0) {
          const userFromResponse = response.data.users.results[0]
          newResponse = {
            ...userFromResponse,
          }
        }
        if (!newResponse.legalEntity)
          newResponse.legalEntity = { isMailingSameAsPremises: true }
        else newResponse.legalEntity.isMailingSameAsPremises = true
        dispatch(setProfile(newResponse))
      })
      .catch(_ => {
        dispatch(
          showError(`Unable to retrieve profile data, please try again.`)
        )
      })
    dispatch(setLoading(false))
  }
}

export const fetchLeAdminOwnProfile = id => {
  return async dispatch => {
    dispatch(setLoading(true))
    await API.graphql(
      graphqlOperation(getLeAdminOwnProfileByIDQuery, { id: parseInt(id) })
    )
      .then(response => {
        let newResponse = {}

        if (response.data.users.count > 0) {
          const resData = response.data.users.results[0]
          newResponse = {
            ...resData,
          }
          newResponse.legalEntity.isMailingSameAsPremises = false
          if (
            resData.legalEntity.mailingAddress.id &&
            resData.legalEntity.premisesAddress.id ===
              resData.legalEntity.mailingAddress.id
          ) {
            newResponse = {
              ...newResponse,
              mailingAddress: resData.mailingAddress,
            }
            newResponse.legalEntity.isMailingSameAsPremises = true
          }
        }
        dispatch(setProfile(newResponse))
        dispatch(setEditMode(false))
      })
      .catch(_ => {
        dispatch(
          showError(`Unable to retrieve profile data, please try again.`)
        )
      })
    dispatch(setLoading(false))
  }
}

/*--------Dispatch for update own profile-----------*/

export const updateProfile = (ownProfileDetails, hasEmailChanged = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    const isLeAdmin = ownProfileDetails.role.name === roles.lEAdmin
    await API.graphql(
      graphqlOperation(updateProfileMutation(ownProfileDetails))
    )
      .then(_ => {
        if (!isLeAdmin) {
          dispatch(setEditMode(false))
          dispatch(showSuccess('User profile updated successfully'))
          dispatch(setProfile(ownProfileDetails))
        }
      })
      .catch(_ => {
        dispatch(showError(`Unable to edit profile data, please try again.`))
      })

    // If email was changed, update the Cognito user email attribute
    if (hasEmailChanged) {
      // Update email attribute in Cognito user
      updateCognitoEmail(getState().auth.user.username, ownProfileDetails.email)
    }

    // If user is an LE Admin we need to update the LE details as well
    if (isLeAdmin) {
      // -------------- UPDATE LE ADDRESSES ---------------

      let leData = ownProfileDetails.legalEntity
      leData.newMailingAddressId = null
      if (!leData.isMailingSameAsPremises) {
        if (leData.premisesAddress.id === leData.mailingAddress.id) {
          // If premises and mailing address id's are the same it means a new address needs to be created
          const newAddressRes = await API.graphql(
            graphqlOperation(createAddressMutation(leData.mailingAddress))
          )
          leData.newMailingAddressId = newAddressRes.data.createAddress.id
        } else {
          // If premises and mailing address id's are different it means the mailing address only needs to be updated
          await API.graphql(
            graphqlOperation(updateAddressMutation(leData.mailingAddress))
          )
        }
      }

      // -------------- UPDATE LE DETAILS ---------------

      await API.graphql(
        graphqlOperation(updateProfileLegalEntityMutation(leData))
      )
        .then(res => {
          dispatch(setEditMode(false))
          dispatch(
            showSuccess(
              'User profile and licensee details updated successfully'
            )
          )
          dispatch(setProfile(ownProfileDetails))
        })
        .catch(_ => {
          dispatch(showError(`Unable to edit licensee data, please try again.`))
        })
    }

    dispatch(setLoading(false))
  }
}

export const resetProfile = () => {
  return dispatch => {
    dispatch(setProfile(initialState.ownProfile))
    dispatch(setEditMode(false))
    dispatch(setProfileShown(false))
  }
}

export const setProfile = createAction('profileView/setProfile')
export const setProfileShown = createAction('profileView/setProfileShown')

const initialState = {
  ownProfile: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    createdAt: '',
    updatedAt: '',
    status: '',
    role: '',
    // isMailingSameAsPremises: true,
    legalEntity: {
      uniqueId: '',
      status: '',
      name: '',
      email: '',
      licenseTypes: [],
      premisesAddress: {
        address: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      mailingAddress: {
        address: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
    },
  },
  isProfileShown: false,
}

export default createReducer(initialState, {
  [setProfile]: (state, action) => {
    state.ownProfile = action.payload
  },
  [setProfileShown]: (state, action) => {
    state.isProfileShown = action.payload
  },
})
