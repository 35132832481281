import React from 'react'
import styled, { keyframes } from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import { rgba, lighten } from 'polished'

const ProgressBar = styled(LinearProgress)`
  && {
    height: 10px;
    border-radius: 10px;
    background-color: #f6f7e0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .MuiLinearProgress-bar {
      border-radius: 10px;
      background-color: ${props => props.theme.gold};
    }
    .MuiLinearProgress-bar2Buffer {
      background-color: ${props => lighten(0.3, props.theme.gold)} !important;
    }
  }
`

/* ------------- A N I M A T I O N S ------------- */

const glassAnimation = keyframes`
  0% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const beerAnimation = keyframes`
  0% {
    transform: translateY(-5px);
  }
  45% {
    transform: translateY(-82px);
  }
  100% {
    transform: translateY(-84px);
  }
`

const handleAnimation = keyframes`
  0% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(0deg) translateX(5px) translateY(20px);
  }
  100% {
    transform: rotate(0deg) translateX(5px) translateY(20px);
  }
`

const foamAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
`

const outsideBubbleAnimation = keyframes`
  0% {
    transform: scale(0) translateY(0px);
  }
  20% {
    transform: scale(0) translateY(0px);
  }
  30% {
    transform: scale(1) translateY(-30px);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(-80px);
    opacity: 0;
  }
`

const insideBubbleAnimation = keyframes`
  0% {
    transform: scale(0) translateY(0px);
  }
  25% {
    transform: scale(0) translateY(0px);
  }
  55% {
    transform: scale(1) translateY(-40px);
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(-60px);
    opacity: 0;
  }
`

/* ------------- A N I M A T E D  C O M P O N E N T S ------------- */

const Glass = styled.div`
  width: 70px;
  height: 90px;
  background: ${props => (props.transparent ? 'transparent' : 'white')};
  border: solid 3.5px #f6f7e0;
  /*border-top: none;*/
  border-radius: 3px 3px 10px 10px;
  position: absolute;
  top: 80px;
  left: 86px;
  box-shadow: 0px 11px 15px -7px ${props => props.theme.gold && rgba(props.theme.gold, 0.2)},
    0px 24px 38px 3px
      ${props => props.theme.gold && rgba(props.theme.gold, 0.14)},
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transform: rotate(-20deg);
  animation: ${glassAnimation} 6s infinite;
`

const Beer = styled.div`
  width: 58px;
  height: 84px;
  background: ${props => (props.transparent ? 'transparent' : 'white')};
  position: absolute;
  left: 3px;
  top: 0px;
  border-radius: 0 0 5px 5px;
  overflow: initial;

  &::before {
    content: '';
    width: 250px;
    height: 250px;
    background: ${props => props.theme.gold};
    position: absolute;
    top: 63px;
    left: -170px;
    transform: rotate(40deg);
    animation: ${beerAnimation} 6s infinite;
  }
`

const Handle = styled.div`
  width: 30px;
  height: 55px;
  background: transparent;
  border: solid 3.5px #f6f7e0;
  position: absolute;
  top: 72px;
  left: 150px;
  border-radius: 0 16px 16px 0;
  border-left: none;
  transform: rotate(-20deg);
  animation: ${handleAnimation} 6s infinite;
`

const Foam1 = styled.div`
  position: absolute;
  top: 72px;
  left: 80px;
  z-index: 4;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    animation: ${foamAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 10px;
    animation: ${foamAnimation} 6s infinite;
  }
`

const Foam2 = styled.div`
  position: absolute;
  top: 72px;
  left: 94px;
  z-index: 4;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    animation: ${foamAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: 10px;
    animation: ${foamAnimation} 6s infinite;
  }
`

const Foam3 = styled.div`
  position: absolute;
  top: 72px;
  left: 110px;
  z-index: 4;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    animation: ${foamAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 15px;
    animation: ${foamAnimation} 6s infinite;
  }
`

const Foam4 = styled.div`
  position: absolute;
  top: 72px;
  left: 124px;
  z-index: 4;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    animation: ${foamAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 10px;
    animation: ${foamAnimation} 6s infinite;
  }
`

const Foam5 = styled.div`
  position: absolute;
  top: 72px;
  left: 139px;
  z-index: 4;
  animation: ${foamAnimation} 6s infinite;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f6f7e0;
    border-radius: 50%;
    position: absolute;
  }
`

const OutsideBubble = styled.div`
  position: absolute;
  top: 65px;
  left: 92px;
  z-index: 4;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    background: ${props => props.theme.gold};
    border-radius: 50%;
    position: absolute;
    animation: ${outsideBubbleAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${props => props.theme.gold};
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 40px;
    animation: ${outsideBubbleAnimation} 6s infinite;
  }
`

const InsideBubble1 = styled.div`
  position: absolute;
  top: 135px;
  left: 98px;
  z-index: 4;
  opacity: 0.9;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 50%;
    position: absolute;
    animation: ${insideBubbleAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 4px;
    height: 4px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 30px;
    animation: ${insideBubbleAnimation} 3s infinite;
  }
`

const InsideBubble2 = styled.div`
  position: absolute;
  top: 115px;
  left: 105px;
  z-index: 4;
  opacity: 0.9;

  &::before {
    content: '';
    width: 7px;
    height: 7px;
    background: white;
    border-radius: 50%;
    position: absolute;
    animation: ${insideBubbleAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 3px;
    height: 3px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 17px;
    animation: ${insideBubbleAnimation} 3s infinite;
  }
`

const InsideBubble3 = styled.div`
  position: absolute;
  top: 125px;
  left: 112px;
  z-index: 4;
  opacity: 0.9;

  &::before {
    content: '';
    width: 9px;
    height: 9px;
    background: white;
    border-radius: 50%;
    position: absolute;
    animation: ${insideBubbleAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 12px;
    animation: ${insideBubbleAnimation} 3s infinite;
  }
`

const InsideBubble4 = styled.div`
  position: absolute;
  top: 121px;
  left: 118px;
  z-index: 4;
  opacity: 0.9;

  &::before {
    content: '';
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    position: absolute;
    animation: ${insideBubbleAnimation} 6s infinite;
  }

  &::after {
    content: '';
    width: 5.5px;
    height: 5.5px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 28px;
    left: 2px;
    animation: ${insideBubbleAnimation} 3s infinite;
  }
`

const Brewing = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  background-color: transparent;
  border-radius: 8px;

  #text {
    position: absolute;
    top: 190px;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.dark};
    letter-spacing: 0.5px;

    span {
      background-image: linear-gradient(
        transparent,
        ${props => (props.transparent ? 'transparent' : 'white')},
        transparent
      );
      padding: 6px 4px;
      border-radius: 14px;
    }
  }
`

const CenterDiv = styled.div`
  text-align: center;
  position: ${props => (props.position ? 'initial' : 'fixed')};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;
  width: 250px;
  height: 250px;
`

const LoadingIndicator = ({ text, transparent, progress = null }) => {
  return (
    <CenterDiv>
      <Brewing transparent={transparent}>
        <Glass transparent={transparent}>
          <Beer transparent={transparent} />
        </Glass>
        <Handle />
        <Foam1 />
        <Foam2 />
        <Foam3 />
        <Foam4 />
        <Foam5 />
        <OutsideBubble />
        <InsideBubble1 />
        <InsideBubble2 />
        <InsideBubble3 />
        <InsideBubble4 />
        <div id='text'>
          <span>{text ? text : 'Loading...'}</span>
        </div>
      </Brewing>
      {progress ? (
        typeof progress === 'number' ? (
          <ProgressBar
            variant={progress >= 0 ? 'determinate' : 'indeterminate'}
            value={progress}
          />
        ) : (
          <ProgressBar
            variant='buffer'
            value={progress.value}
            valueBuffer={progress.buffer}
          />
        )
      ) : (
        ''
      )}
    </CenterDiv>
  )
}

export default LoadingIndicator
