import { createAction, createReducer } from '@reduxjs/toolkit'

export const toggleDialogStatus = createAction('quickView/toggleDialogStatus')

export const toggleDialog = () => {
  return async dispatch => {
    dispatch(toggleDialogStatus())
  }
}

const initialState = {
  isDialogOpen: false,
}

export default createReducer(initialState, {
  [toggleDialogStatus]: (state, action) => {
    state.isDialogOpen = !state.isDialogOpen
  },
})
