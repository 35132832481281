import React, { useEffect } from 'react'
import { Redirect, Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Grid, Link } from '@material-ui/core'
import styled from 'styled-components'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { Helmet } from 'react-helmet'
import SubmitButton from 'components/common/SubmitButton'
import ToggleMaskPassword from 'components/common/ToggleMaskPassword'
import { signIn, resetAuth } from 'ducks/authentication'

const LeftAlignedGrid = styled(Grid)`
  text-align: start;
`

const ErrorMessage = styled.div`
  color: ${props => props.theme.red};
  padding-top: 10px;
`

const validations = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
})

const Login = () => {
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    isAuthenticating,
    pathname,
    failedLoginAttempts,
    setNewPassword,
  } = useSelector(store => store.auth)

  // Reset authentication variables to avoid using values set out of context
  useEffect(() => {
    dispatch(resetAuth())
  }, [dispatch])

  // If the user is authenticated redirect them to the home page
  if (isAuthenticated) {
    return <Redirect to={pathname} />
  } else if (setNewPassword) {
    return <Redirect to='/newPassword' />
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Typography variant='h6' role='heading' aria-level='2'>
        Sign In
      </Typography>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={(credentials, { setSubmitting }) => {
          dispatch(signIn(credentials))
          setSubmitting(false)
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='loginForm'
            render={formikProps => (
              <>
                <Field
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='username'
                  label='Username'
                  name='username'
                  autoComplete='username'
                  component={TextField}
                />
                <Field
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='password'
                  label='Password'
                  name='password'
                  autoComplete='current-password'
                  component={ToggleMaskPassword}
                />
                {failedLoginAttempts > 0 && failedLoginAttempts < 5 ? (
                  <ErrorMessage>
                    You have {5 - failedLoginAttempts} remaining login attempts.
                  </ErrorMessage>
                ) : null}
                {failedLoginAttempts === 5 ? (
                  <ErrorMessage>
                    Your account has been locked. Select Forgot Password to
                    create a new password.
                  </ErrorMessage>
                ) : null}
                <SubmitButton isSubmitting={isAuthenticating} text='Sign In' />
              </>
            )}
          />
          <Grid container>
            <LeftAlignedGrid item xs>
              <Link to='/forgotUsername' variant='body2' component={RouterLink}>
                Forgot Username?
              </Link>
            </LeftAlignedGrid>
            <Grid item>
              <Link to='/forgotPassword' variant='body2' component={RouterLink}>
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

export default Login
