import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import styled from 'styled-components'
import { onSidebarExpandIconClicked } from 'ducks/sidebar'
import { sidebarRoutes } from 'components/routes/sidebarRoutes'
import CustomDivider from 'components/common/CustomDivider'
import { Can } from 'common/abilityContext'
import { toggleConfigReqDialog } from 'ducks/configurationRequests'
import { toggleNewsUpdateDialog } from 'ducks/newsUpdate'

const ListWrapper = styled.div`
  width: 250px;
`

const ListItemButton = styled(ListItem)`
  && {
    color: ${props => props.theme.light};

    .MuiListItemIcon-root {
      min-width: 46px;
    }
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${props => props.theme.dark};
  }
`

const MainMenuTitleWrapper = styled.span`
  && {
    margin: 0.1% 0 0.1% 0;
    max-height: 53px;
    color: ${props => props.theme.light};
    display: flex;
    justify-content: space-around;
  }
`

const MainMenuTitle = styled(Typography)`
  && {
    align-self: center;
    font-weight: bold;
  }
`

const BackIcon = styled(ArrowBack)`
  color: ${props => props.theme.light};
`

const SideBar = () => {
  const dispatch = useDispatch()
  const { isSideBarOpen } = useSelector(store => store.sidebar)

  const ListItemLink = ({ primary, to, icon }) => {
    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <Link to={to} {...itemProps} ref={ref} />
        )),
      [to]
    )

    return (
      <Can I='read' this={to}>
        {() => (
          <li className='sideBarRoutingLink'>
            {to === '/requestNewConfiguration' ? (
              <>
                <Divider />
                <ListItemButton
                  onClick={() => dispatch(toggleConfigReqDialog(true))}
                  divider
                  button
                  component='div'
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={primary} />
                </ListItemButton>
              </>
            ) : to === '/newsUpdate' ? (
              <>
                <Divider />
                <ListItemButton
                  onClick={() => dispatch(toggleNewsUpdateDialog(true))}
                  divider
                  button
                  component='div'
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={primary} />
                </ListItemButton>
              </>
            ) : (
              <ListItemButton divider button component={renderLink}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} />
              </ListItemButton>
            )}
          </li>
        )}
      </Can>
    )
  }

  return (
    <StyledDrawer
      open={isSideBarOpen}
      onClose={() => dispatch(onSidebarExpandIconClicked())}
      role='navigation'
    >
      <ListWrapper
        role='presentation'
        onClick={() => dispatch(onSidebarExpandIconClicked())}
        onKeyDown={() => dispatch(onSidebarExpandIconClicked())}
      >
        <MainMenuTitleWrapper>
          <MainMenuTitle children='Main Menu' variant='h6' />
          <IconButton aria-label='back' aria-haspopup='false'>
            <BackIcon fontSize='large' />
          </IconButton>
        </MainMenuTitleWrapper>

        <CustomDivider />
        <List>
          {sidebarRoutes.map(item => (
            <Fragment key={item.label}>
              <ListItemLink
                to={item.to}
                primary={item.label}
                icon={item.icon}
              />
            </Fragment>
          ))}
        </List>
      </ListWrapper>
    </StyledDrawer>
  )
}
export default SideBar
