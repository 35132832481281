import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  Divider,
  Button,
} from '@material-ui/core'
import styled from 'styled-components'
import {
  MoreVert,
  PictureInPicture,
  Block,
  Edit,
  CloudDownload,
  HowToReg,
} from '@material-ui/icons'
import LoadingIndicator from 'components/common/LoadingIndicator'
import ConfirmationModal from 'components/common/ConfirmationModal'
import { AbilityContext } from 'common/abilityContext'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import ProductsFilters from './ProductsFilters'
import EditProduct from './EditProduct'
import ViewProduct from './ViewProducts'
import ProductBulkUpload from './ProductBulkUpload'
import { addEditBulkOn, roles } from 'common/constants'
import {
  clearFilters,
  getProductsList,
  setIsProductsFilterOpen,
  setProductShow,
  resetProductDetails,
  fetchProductByID,
  setProductBulkSelection,
  setProductsPageNumber,
  setProductsRowsPerPage,
  setProductsRequestedSort,
  setActivationConfirmation,
  setInactivationConfirmation,
  setIsBulkDialogOpen,
  exportForPricePosting,
  setListType,
  deactivateProductByID,
} from 'ducks/products'
import { resetBulkUpload } from 'ducks/bulkUpload'
import { setEditMode } from 'ducks/global'
import { CheckManufacturerForProductCreation } from 'common/helper'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

const ProductsList = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(-1)
  const {
    productsList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    activationConfirmation,
    inactivationConfirmation,
    isProductsFilterOpen,
    isProductShown,
    productsFilters,
    productDetails,
    isBulkDialogOpen,
    productBulk,
    listType,
    areFiltersUsed,
    cocProductsCount,
  } = useSelector(store => store.products)
  const { loadingText } = useSelector(store => store.global)

  const { user } = useSelector(store => store.auth)

  useEffect(() => {
    dispatch(getProductsList())
  }, [dispatch, listType, order, orderBy, pageNumber, rowsPerPage])

  const abilityFunc = useContext(AbilityContext)

  /*----Table Header-----*/

  const ProductsHeaders = [
    { id: 'manufacturers', label: 'Manufacturer', isSortable: true },
    { id: 'tradeName', label: 'Trade Name', isSortable: true },
    { id: 'name', label: 'Product Name', isSortable: true },
    { id: 'createdAt', label: 'Added Date', isSortable: true },
    { id: 'updatedAt', label: 'Edited Date', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  /* ----------- Popover actions methods ----------- */

  const handleActionOpen = (event, product) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedProduct(product)
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }

  const handleViewSelected = () => {
    setAnchorEl(null)
    dispatch(fetchProductByID(selectedProduct.id))
  }

  const handleActivateSelected = () => {
    setAnchorEl(null)
    dispatch(setActivationConfirmation(false))
    dispatch(deactivateProductByID(selectedProduct.id))
  }

  const handleDeactivateSelected = () => {
    setAnchorEl(null)
    dispatch(setInactivationConfirmation(false))
    dispatch(deactivateProductByID(selectedProduct.id))
  }

  const handleEditSelected = () => {
    setAnchorEl(null)
    dispatch(fetchProductByID(selectedProduct.id, true))
  }

  const openAction = Boolean(anchorEl)

  const onItemSelected = product => {
    dispatch(fetchProductByID(product.id))
  }

  /*------Products list----------*/
  let updatedProductsList = []
  updatedProductsList = productsList.results
    ? productsList.results.map((product, index) => ({
        id: product.id,
        manufacturer: product.manufacturer.name,
        tradeName: product.tradeName,
        product: product.name,
        createdAt: format(product.createdAt, 'MM/dd/yyyy'),
        updatedAt: format(product.updatedAt, 'MM/dd/yyyy'),
        status: product.status,
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='true'
              onClick={event => handleActionOpen(event, product)}
              color='primary'
            >
              <MoreVert color='primary' />
            </IconButton>
          </>
        ),
      }))
    : []

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = productsList.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          productsList.results.map(finalObject => ({
            id: finalObject.id,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = productsList.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setProductBulkSelection(newSelected))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getProductsList(false, true))
  }

  const handleClearSelection = () => {
    dispatch(setProductBulkSelection([]))
  }

  const handlePageNumber = newPage => {
    dispatch(setProductsPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setProductsRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setProductsRequestedSort(value))
  }

  const onQuickViewClosed = () => {
    dispatch(resetProductDetails())
    dispatch(setProductShow(false))
    dispatch(setEditMode(false))
  }

  const handleFilter = value => {
    dispatch(setIsProductsFilterOpen(value))
  }
  const onAdd = () => {
    dispatch(resetProductDetails())
    dispatch(setEditMode(true))
    dispatch(setProductShow(true))
  }
  const handleBulkUploadClicked = () => {
    dispatch(setIsBulkDialogOpen(true))
  }
  const onBulkUploadModalClosed = () => {
    dispatch(setIsBulkDialogOpen(false))
    dispatch(resetBulkUpload())
  }
  const handleProductsListType = event => {
    dispatch(setProductBulkSelection([]))
    dispatch(setListType(event.target.value))
    dispatch(setProductsPageNumber(0))
    dispatch(clearFilters())
  }

  const actionItemsOnSelection = (
    <ActionWrapper>
      <Button
        aria-label='export'
        color='primary'
        onClick={e => dispatch(exportForPricePosting())}
        size='medium'
        variant='contained'
        startIcon={<CloudDownload />}
      >
        Export For Price Posting
      </Button>
    </ActionWrapper>
  )

  return productsList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={handleViewSelected}>
          <PictureInPicture color='primary' fontSize='small' />
          <ActionLabels children='View' variant='body1' />
        </ActionItems>
        {selectedProduct.status === 'Active' &&
          [roles.abcITAdmin, roles.abcBizAdmin].includes(user.role.name) && (
            <ActionItems
              onClick={() => dispatch(setInactivationConfirmation(true))}
            >
              <Block color='error' fontSize='small' />
              <ActionLabels children='Inactivate' variant='body1' />
            </ActionItems>
          )}
        {selectedProduct.status === 'Inactive' &&
          [roles.abcITAdmin, roles.abcBizAdmin].includes(user.role.name) && (
            <ActionItems
              onClick={() => dispatch(setActivationConfirmation(true))}
            >
              <HowToReg color='primary' fontSize='small' />
              <ActionLabels children='Reactivate' variant='body1' />
            </ActionItems>
          )}
        {selectedProduct.status === 'Active' &&
        abilityFunc.can('perform', addEditBulkOn.products) &&
        abilityFunc.can(
          'edit',
          new CheckManufacturerForProductCreation({
            ifCreatedByManufacturerIs:
              selectedProduct.manufacturer && selectedProduct.manufacturer.id,
          })
        ) ? (
          <>
            <Divider />
            <ActionItems onClick={handleEditSelected}>
              <Edit color='primary' fontSize='small' />
              <ActionLabels children='Edit' variant='body1' />
            </ActionItems>
          </>
        ) : (
          ''
        )}
      </Popover>
      <ListComponent
        mainTitle='Products'
        listContent={
          <ReusableTable
            headers={ProductsHeaders}
            tableData={updatedProductsList}
            pagination={true}
            selectionOption={true}
            totalCount={productsList.totalCount}
            onSingleItemSelected={onItemSelected}
            handleBulkSelection={handleBulkSelection}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleSelectAllRows={handleSelectionOfAllRows}
            handleClearSelection={handleClearSelection}
            actionItemsOnSelection={actionItemsOnSelection}
          />
        }
        editComp={
          <EditProduct
            cannotSelectManufacturer={abilityFunc.cannot(
              'have',
              'manufacturerDetailsOnProducts'
            )}
            product={productDetails}
          />
        }
        viewComp={<ViewProduct />}
        modalEditTitle={`${productDetails.id ? 'Edit Product' : 'Add Product'}`}
        modalViewTitle='Product Details'
        onModalClosed={onQuickViewClosed}
        expandedOptions={<ProductsFilters productsFilters={productsFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isProductsFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isProductShown}
        onAddButtonClicked={onAdd}
        isBulkUploadAvailable={true}
        bulkUploadContent={
          <ProductBulkUpload
            cannotSelectManufacturer={abilityFunc.cannot(
              'have',
              'manufacturerDetailsOnProducts'
            )}
            productBulk={productBulk}
          />
        }
        onBulkUploadClicked={handleBulkUploadClicked}
        bulkUploadTitle='Product Bulk Upload'
        isBulkDialogOpen={isBulkDialogOpen}
        onBulkUploadModalClosed={onBulkUploadModalClosed}
        listType={listType}
        areFiltersUsed={areFiltersUsed || cocProductsCount === 0}
        handleListType={handleProductsListType}
        listTypeRadioLabels={['All Products', 'My Products']}
        permitRadioFilters={'onProductsList'}
        radioButtonDisabledReason={cocProductsCount === 0 ? true : false}
      />
      <ConfirmationModal
        isConfirmationModalOpen={activationConfirmation}
        confirmationList={[`Reactivate this product?`]}
        onConfirmation={handleActivateSelected}
        onConfirmationModalClosed={() =>
          dispatch(setActivationConfirmation(false))
        }
      />
      <ConfirmationModal
        isConfirmationModalOpen={inactivationConfirmation}
        confirmationList={[`Inactivate this product?`]}
        onConfirmation={handleDeactivateSelected}
        onConfirmationModalClosed={() =>
          dispatch(setInactivationConfirmation(false))
        }
        type='inactivate'
      />
    </>
  )
}

export default ProductsList
