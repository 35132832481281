import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from 'formik-material-ui'
import { Field, Form, withFormik } from 'formik'
import { Grid, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'
import styled from 'styled-components'
import CustomCard from 'components/common/CustomCard'
import GenericDatePicker from 'components/common/GenericDatePicker'
import {
  categoryList,
  statusForDropdown,
  licensedDropdownOptions,
  litigationOptions,
} from 'common/constants'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import { getLicenseTypes } from 'ducks/global'
import {
  clearFilters,
  setLePageNumber,
  getListOfLegalEntities,
  setLeBulkSelection,
} from 'ducks/legalEntity'
import AutoCompleteComp from 'components/common/AutoCompleteComp'

const CheckboxWrapper = styled(FormControlLabel)`
  && {
    padding: 0;
    margin-left: 2px;
  }
`
const EmailField = styled(Field)`
  && {
    margin-bottom: 0px;
  }
`

const DatePickerWrapper = styled(Grid)`
  display: flex;
`
const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    input {
      height: 17px;
    }
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`

const LicenseTypesGridWrapper = styled(Grid)`
  && {
    display: flex;
  }
  > div:nth-child(2) * {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`

const OperatorSelect = styled(Field)`
  && {
    min-width: 80px;

    &:first-child * div {
      font-size: 14px;
      width: 60px;
    }

    fieldset {
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`

const OperatorDropDown = styled(MenuItem)`
  && {
    font-size: 16px;
  }
`

const LegalEntitiesFilters = () => {
  const dispatch = useDispatch()
  const { licenseTypes } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getLicenseTypes())
  }, [dispatch])

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    dispatch(setLePageNumber(0))
    dispatch(setLeBulkSelection([]))
    dispatch(getListOfLegalEntities(formikProps.values.legalEntitiesFilters))
  }

  const onFiltersCleared = formikProps => {
    dispatch(setLeBulkSelection([]))
    dispatch(clearFilters())
    formikProps.setFieldValue(`legalEntitiesFilters`, {
      licensee: '',
      licenseTypes: [],
      licenseCategory: '',
      createdAt: null,
      operatorForLicenseType: 'or',
      operatorForCreated: 'equal',
      operatorForUpdated: 'equal',
      updatedAt: null,
      status: [],
      licensed: { value: 'Both', label: 'Both' },
      email: '',
      isEmailEmpty: false,
      litigation: { value: 'Both', label: 'Both' },
    })
  }

  return (
    <Form>
      <Field
        name='legalEntitiesFilters'
        render={formikProps => {
          formikProps.form.values.legalEntitiesFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.legalEntitiesFilters)
          )
          const formikValues = formikProps.form.values

          const handleAutoCompleteChange = (field, event) => {
            formikProps.form.setFieldValue(
              `legalEntitiesFilters.${field}`,
              event
            )
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Licensee'
                    name='legalEntitiesFilters.licensee'
                    component={TextField}
                  />
                </Grid>
                <LicenseTypesGridWrapper item xs={12} sm={6} md={4} lg={3}>
                  <OperatorSelect
                    select
                    name={'legalEntitiesFilters.operatorForLicenseType'}
                    component={TextField}
                    margin='normal'
                    variant='outlined'
                  >
                    <OperatorDropDown key='or' value='or'>
                      OR
                    </OperatorDropDown>
                    <OperatorDropDown key='and' value='and'>
                      AND
                    </OperatorDropDown>
                  </OperatorSelect>
                  <Field
                    placeHolderText='License Type'
                    name='legalEntitiesFilters.licenseTypes'
                    selectedValue={
                      formikValues.legalEntitiesFilters.licenseTypes
                    }
                    formikProps={formikProps.form}
                    dropDownOptions={licenseTypes}
                    onValueChange={event =>
                      handleAutoCompleteChange('licenseTypes', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </LicenseTypesGridWrapper>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    select
                    name={'legalEntitiesFilters.licenseCategory'}
                    label='License Category'
                    component={TextField}
                    fullWidth
                    margin='normal'
                    variant='outlined'
                  >
                    {categoryList.map((category, index) => (
                      <MenuItem key={index} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Status'
                    name='legalEntitiesFilters.status'
                    selectedValue={formikValues.legalEntitiesFilters.status}
                    formikProps={formikProps.form}
                    dropDownOptions={statusForDropdown}
                    onValueChange={event =>
                      handleAutoCompleteChange('status', event)
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Licensed Type'
                    name='legalEntitiesFilters.licensed'
                    selectedValue={formikValues.legalEntitiesFilters.licensed}
                    formikProps={formikProps.form}
                    dropDownOptions={licensedDropdownOptions}
                    onValueChange={event =>
                      handleAutoCompleteChange('licensed', event)
                    }
                    isMulti={false}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Added Date'
                    name='legalEntitiesFilters.createdAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.legalEntitiesFilters.createdAtFrom,
                      end: formikValues.legalEntitiesFilters.createdAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'legalEntitiesFilters.createdAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'legalEntitiesFilters.createdAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <DatePickerWrapper item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Edited Date'
                    name='legalEntitiesFilters.updatedAt'
                    component={GenericDatePicker}
                    value={{
                      begin: formikValues.legalEntitiesFilters.updatedAtFrom,
                      end: formikValues.legalEntitiesFilters.updatedAtTo,
                    }}
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'legalEntitiesFilters.updatedAtFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue(
                        'legalEntitiesFilters.updatedAtTo',
                        values.end
                      )
                    }}
                    fullWidth
                  />
                </DatePickerWrapper>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Litigation'
                    name='legalEntitiesFilters.litigation'
                    selectedValue={formikValues.legalEntitiesFilters.litigation}
                    formikProps={formikProps.form}
                    dropDownOptions={litigationOptions}
                    onValueChange={event =>
                      handleAutoCompleteChange('litigation', event)
                    }
                    isMulti={false}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <>
                    <EmailField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      label='Email'
                      name='legalEntitiesFilters.email'
                      component={TextField}
                      disabled={formikValues.legalEntitiesFilters.isEmailEmpty}
                    />
                    <CheckboxWrapper
                      control={
                        <Field
                          onChange={() => {
                            formikProps.form.setFieldValue(
                              `legalEntitiesFilters.isEmailEmpty`,
                              !formikValues.legalEntitiesFilters.isEmailEmpty
                            )
                          }}
                          name='legalEntitiesFilters.isEmailEmpty'
                          component={Checkbox}
                          type='checkbox'
                          size='small'
                          checked={
                            formikValues.legalEntitiesFilters.isEmailEmpty
                          }
                        />
                      }
                      label='Is Empty'
                    />
                  </>
                </Grid>
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(LegalEntitiesFilters)

export default WithFormikWrapper
