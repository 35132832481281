import React from 'react'
import styled from 'styled-components'
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CardHeader,
  CardActions,
  Divider,
  Fade,
  Box,
} from '@material-ui/core'

const Wrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`

const StyledCardHeader = styled(CardHeader)`
  flex-wrap: wrap;
  span {
    color: rgba(0, 0, 0, 0.87);
  }
  span.active {
    color: ${props => props.theme.active};
  }
  span.pending {
    color: ${props => props.theme.pending};
  }
  span.inactive {
    color: ${props => props.theme.inactive};
  }
`

const StyledCard = styled(Card)`
  && {
    border-top: 2px solid ${props => props.theme.border};
    .MuiCardHeader-action {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 830px) {
        width: 100%;
      }
    }
  }
`

const Title = styled(Typography)`
  && {
    color: ${props => props.theme.dark};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
`

const StyledDivider = styled(Divider)`
  && {
    margin: 0 16px;
    background-color: ${props => props.theme.gold};
    height: 2px;
  }
`

const TitleWrapper = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
  }
`

const CustomCard = props => {
  return (
    <Fade in timeout={{ enter: 800, exit: 800 }}>
      <Wrapper>
        {props.title ? (
          <Title variant='h6' children={props.title} />
        ) : props.titleContent ? (
          <TitleWrapper component='div'>{props.titleContent}</TitleWrapper>
        ) : (
          ''
        )}
        <StyledCard>
          {props.profileIcon && (
            <StyledCardHeader
              avatar={props.profileIcon}
              title={props.profileTitle}
              subheader={props.profileSubHeading}
              action={props.profileAction}
            />
          )}
          {props.divider ? <StyledDivider /> : ''}
          {props.children ? (
            <CardContent>
              <Grid container spacing={2}>
                {props.children}
              </Grid>
            </CardContent>
          ) : (
            ''
          )}
          {props.cardBottom && <CardActions>{props.cardBottom}</CardActions>}
        </StyledCard>
      </Wrapper>
    </Fade>
  )
}

export default CustomCard
