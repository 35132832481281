import React from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import SubmitButton from 'components/common/SubmitButton'
import ToggleMaskPassword from 'components/common/ToggleMaskPassword'
import PasswordConstraintsCheck from 'components/common/PasswordContraintsCheck'
import { forgotPasswordSubmit, signOut } from 'ducks/authentication'
import { setProfileShown } from 'ducks/profileView'

const VerifyCode = () => {
  const dispatch = useDispatch()
  const { isCodeVerified, user } = useSelector(store => store.auth)

  // If the code is verified redirect user to the login page to continue
  if (isCodeVerified) {
    return <Redirect to='/login' />
  }

  const validationFields = {
    code: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
    password: Yup.string()
      .matches(
        /([a-z])/,
        'Password should have minimum of one lower case letter.'
      )
      .matches(
        /([A-Z])/,
        'Password should have minimum of one uppercase case letter.'
      )
      .matches(
        /(?=.*[[\]{}()!@#$%^&*.,></\\?\-"':;|~`])/,
        'Password should have minimum of one special character.'
      )
      .matches(/([0-9])/, 'Password should have minimum of one numeric.')
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  }

  const validations = Yup.object().shape(validationFields)

  return (
    <>
      <Typography variant='body1'>
        A verification code was sent to your email. Please enter it here.
      </Typography>
      <Formik
        initialValues={{
          code: '',
          username: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (user) {
            dispatch(signOut())
            dispatch(setProfileShown(false))
          }
          dispatch(
            forgotPasswordSubmit(values.code, values.username, values.password)
          ).then(() => setSubmitting(false))
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='validationsPage'
            render={formikProps => {
              let enteringNewPassword = formikProps.form.values.password
              return (
                <>
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='code'
                    label='Verification Code'
                    name='code'
                    type='password'
                    component={TextField}
                    autoFocus
                  />
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='username'
                    label='Username'
                    name='username'
                    component={TextField}
                  />
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='New Password'
                    id='password'
                    component={ToggleMaskPassword}
                  />
                  <PasswordConstraintsCheck
                    enteringNewPassword={enteringNewPassword}
                  />
                  <Field
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='confirmPassword'
                    label='Confirm Password'
                    id='confirmPassword'
                    component={ToggleMaskPassword}
                  />
                  <SubmitButton isSubmitting={formikProps.form.isSubmitting} />
                </>
              )
            }}
          />
        </Form>
      </Formik>
    </>
  )
}

export default VerifyCode
