import { AbilityBuilder } from '@casl/ability'
import { sideBarNavLinks, roles, addEditBulkOn } from 'common/constants'
import { checkUserIsManufacturerNotCoc } from 'common/helper'

export const ability = loggedInUser =>
  AbilityBuilder.define((can, cannot) => {
    const loggedInUserRole = loggedInUser.role.name
    let loggedInUserCategory = null
    let userIsManufacturerNotCOC = false
    if (loggedInUser.legalEntity) {
      loggedInUserCategory = loggedInUser.legalEntity.licenseCategory
      userIsManufacturerNotCOC = checkUserIsManufacturerNotCoc(
        loggedInUser.legalEntity
      )
    }

    if (loggedInUserRole === roles.abcITAdmin) {
      can('read', 'post')
      can('read', [
        sideBarNavLinks.dashboard,
        sideBarNavLinks.legalEntities,
        sideBarNavLinks.legalEntityUsers,
        sideBarNavLinks.abcUsers,
        sideBarNavLinks.products,
        sideBarNavLinks.pricePostings,
        sideBarNavLinks.pricePostingsSchedule,
        sideBarNavLinks.globalSettings,
        sideBarNavLinks.configurationRequests,
        sideBarNavLinks.newsUpdate,
      ])
      cannot('read', sideBarNavLinks.requestNewConfiguration)
      can('create', [roles.abcITAdmin, roles.abcBizAdmin, roles.abcUser])
      can('createLE', [roles.lEAdmin, roles.lEUser])
      can('select', roles.lEAdmin)
      can('edit', [
        roles.abcITAdmin,
        roles.abcBizAdmin,
        roles.abcUser,
        roles.lEAdmin,
        roles.lEUser,
      ])
      can('editStatus', [
        roles.abcITAdmin,
        roles.abcBizAdmin,
        roles.abcUser,
        roles.lEAdmin,
        roles.lEUser,
      ])
      can('seeRolesForAbcUsers', [
        roles.abcITAdmin,
        roles.abcBizAdmin,
        roles.abcUser,
      ])
      can('seeRolesForLeUsersFilters', [roles.lEAdmin, roles.lEUser])
      can('selectCompetitiveFilter', 'onPricePostingList')
      can('have', 'manufacturerDetailsOnProducts')
      can('view', 'oldProductInfo')
      can('select', 'licenseeFromLeUserFilters')
      can('perform', [
        addEditBulkOn.licensees,
        addEditBulkOn.licenseeUsers,
        addEditBulkOn.abcUsers,
        addEditBulkOn.products,
        addEditBulkOn.productSizes,
        addEditBulkOn.pricesTo,
        addEditBulkOn.packageConfigurations,
        addEditBulkOn.pricePromo,
      ])
      can('edit', 'productsBy')
      cannot('perform', addEditBulkOn.pricePostings)
      can('select', 'manufacturerFromProductsFilter')
      cannot('haveFilteringRadio', ['onPricePostingsList', 'onProductsList'])
      can('select', 'manufacturerOnPricePosting')
      can('view', 'pricePostedBy')
      can('select', 'litigation')
    }
    if (loggedInUserRole === roles.abcBizAdmin) {
      can('read', 'post')
      can('read', [
        sideBarNavLinks.dashboard,
        sideBarNavLinks.legalEntities,
        sideBarNavLinks.legalEntityUsers,
        sideBarNavLinks.abcUsers,
        sideBarNavLinks.products,
        sideBarNavLinks.pricePostings,
        sideBarNavLinks.pricePostingsSchedule,
        sideBarNavLinks.globalSettings,
        sideBarNavLinks.configurationRequests,
        sideBarNavLinks.newsUpdate,
      ])
      cannot('read', sideBarNavLinks.requestNewConfiguration)
      can('create', roles.abcUser)
      can('createLE', [roles.lEAdmin, roles.lEUser])
      can('select', roles.lEAdmin)
      can('edit', [roles.abcUser, roles.lEAdmin, roles.lEUser])
      can('editStatus', [roles.abcUser, roles.lEAdmin, roles.lEUser])
      can('seeRolesForAbcUsers', [
        roles.abcITAdmin,
        roles.abcBizAdmin,
        roles.abcUser,
      ])
      can('seeRolesForLeUsersFilters', [roles.lEAdmin, roles.lEUser])
      can('have', 'manufacturerDetailsOnProducts')
      can('view', 'oldProductInfo')
      can('select', 'licenseeFromLeUserFilters')
      can('perform', [
        addEditBulkOn.licensees,
        addEditBulkOn.licenseeUsers,
        addEditBulkOn.abcUsers,
        addEditBulkOn.products,
        addEditBulkOn.productSizes,
        addEditBulkOn.pricesTo,
        addEditBulkOn.packageConfigurations,
        addEditBulkOn.pricePromo,
      ])
      can('edit', 'productsBy')
      cannot('perform', addEditBulkOn.pricePostings)
      can('selectCompetitiveFilter', 'onPricePostingList')
      can('select', 'manufacturerFromProductsFilter')
      cannot('haveFilteringRadio', ['onPricePostingsList', 'onProductsList'])
      can('select', 'manufacturerOnPricePosting')
      can('view', 'pricePostedBy')
      can('select', 'litigation')
    }
    if (loggedInUserRole === roles.abcUser) {
      can('read', 'post')
      can('read', [
        sideBarNavLinks.dashboard,
        sideBarNavLinks.legalEntities,
        sideBarNavLinks.legalEntityUsers,
        sideBarNavLinks.products,
        sideBarNavLinks.pricePostings,
        sideBarNavLinks.pricePostingsSchedule,
        sideBarNavLinks.globalSettings,
        sideBarNavLinks.configurationRequests,
      ])
      cannot('read', [
        sideBarNavLinks.abcUsers,
        sideBarNavLinks.requestNewConfiguration,
        sideBarNavLinks.newsUpdate,
      ])
      can('createLE', [roles.lEAdmin, roles.lEUser])
      can('select', [roles.lEAdmin, roles.lEAdmin])
      can('edit', [roles.lEAdmin, roles.lEUser])
      can('editStatus', [roles.lEAdmin, roles.lEUser])
      can('seeRolesForLeUsersFilters', [roles.lEAdmin, roles.lEUser])
      can('have', 'manufacturerDetailsOnProducts')
      can('view', 'oldProductInfo')
      can('select', 'licenseeFromLeUserFilters')
      can('perform', [
        addEditBulkOn.licensees,
        addEditBulkOn.licenseeUsers,
        addEditBulkOn.abcUsers,
        addEditBulkOn.products,
        addEditBulkOn.productSizes,
        addEditBulkOn.pricesTo,
        addEditBulkOn.packageConfigurations,
        addEditBulkOn.pricePromo,
      ])
      can('edit', 'productsBy')
      cannot('perform', addEditBulkOn.pricePostings)
      can('selectCompetitiveFilter', 'onPricePostingList')
      can('select', 'manufacturerFromProductsFilter')
      cannot('haveFilteringRadio', ['onPricePostingsList', 'onProductsList'])
      can('select', 'manufacturerOnPricePosting')
      can('view', 'pricePostedBy')
      cannot('select', 'litigation')
    }
    if (loggedInUserRole === roles.lEAdmin) {
      can('read', 'post')
      can('read', [
        sideBarNavLinks.dashboard,
        sideBarNavLinks.legalEntityUsers,
        sideBarNavLinks.pricePostings,
        sideBarNavLinks.pricePostingsSchedule,
        sideBarNavLinks.products,
        sideBarNavLinks.requestNewConfiguration,
      ])
      if (loggedInUserCategory === 'Wholesaler') {
        can('select', 'manufacturerFromProductsFilter')
        cannot('haveFilteringRadio', 'onProductsList')
        cannot('perform', addEditBulkOn.products)
      } else {
        can('edit', 'productsBy', {
          ifCreatedByManufacturerIs: loggedInUser.legalEntity.id,
        })
        can('perform', addEditBulkOn.products)
        cannot('select', 'manufacturerFromProductsFilter')
        can('haveFilteringRadio', 'onProductsList')
      }
      can('haveFilteringRadio', 'onPricePostingsList')
      if (userIsManufacturerNotCOC) {
        cannot('select', 'manufacturerOnPricePosting')
      } else {
        can('select', 'manufacturerOnPricePosting')
      }
      can('perform', [
        addEditBulkOn.licensees,
        addEditBulkOn.licenseeUsers,
        addEditBulkOn.abcUsers,
        addEditBulkOn.pricePostings,
        addEditBulkOn.pricePostingsSchedule,
      ])
      can('edit/view', 'pricePostedBy', {
        ifCreatedByLicenseeIs: loggedInUser.legalEntity.id,
      })
      cannot('have', 'manufacturerDetailsOnProducts')
      cannot('read', [
        sideBarNavLinks.legalEntities,
        sideBarNavLinks.abcUsers,
        sideBarNavLinks.globalSettings,
        sideBarNavLinks.configurationRequests,
        sideBarNavLinks.newsUpdate,
      ])
      can('createLE', roles.lEUser)
      cannot('select', roles.lEAdmin)
      cannot('selectCompetitiveFilter', 'onPricePostingList')
      can('edit', [roles.lEAdmin, roles.lEUser])
      can('editStatus', [roles.lEUser])
      can('seeRolesForLeUsersFilters', [roles.lEAdmin, roles.lEUser])
      can('view', 'oldProductInfo')
      cannot('select', 'licenseeFromLeUserFilters')
    }
    if (loggedInUserRole === roles.lEUser) {
      cannot('read', 'post')
      if (loggedInUserCategory === 'Wholesaler') {
        can('select', 'manufacturerFromProductsFilter')
        cannot('haveFilteringRadio', 'onProductsList')
        cannot('perform', addEditBulkOn.products)
      } else {
        can('edit', 'productsBy', {
          ifCreatedByManufacturerIs: loggedInUser.legalEntity.id,
        })
        can('perform', addEditBulkOn.products)
        cannot('select', 'manufacturerFromProductsFilter')
        can('haveFilteringRadio', 'onProductsList')
      }
      can('haveFilteringRadio', 'onPricePostingsList')
      if (userIsManufacturerNotCOC) {
        cannot('select', 'manufacturerOnPricePosting')
      } else {
        can('select', 'manufacturerOnPricePosting')
      }
      can('perform', [
        addEditBulkOn.licensees,
        addEditBulkOn.licenseeUsers,
        addEditBulkOn.abcUsers,
        addEditBulkOn.pricePostings,
        addEditBulkOn.pricePostingsSchedule,
      ])
      can('edit/view', 'pricePostedBy', {
        ifCreatedByLicenseeIs: loggedInUser.legalEntity.id,
      })
      cannot('have', 'manufacturerDetailsOnProducts')
      can('read', [
        sideBarNavLinks.dashboard,
        sideBarNavLinks.pricePostings,
        sideBarNavLinks.pricePostingsSchedule,
        sideBarNavLinks.products,
        sideBarNavLinks.requestNewConfiguration,
      ])
      cannot('read', [
        sideBarNavLinks.legalEntities,
        sideBarNavLinks.legalEntityUsers,
        sideBarNavLinks.abcUsers,
        sideBarNavLinks.globalSettings,
        sideBarNavLinks.configurationRequests,
        sideBarNavLinks.newsUpdate,
      ])
      cannot('selectCompetitiveFilter', 'onPricePostingList')
      cannot('edit', [
        roles.abcITAdmin,
        roles.abcBizAdmin,
        roles.abcUser,
        roles.lEAdmin,
        roles.lEUser,
      ])
      cannot('view', 'oldProductInfo')
    } else {
      can('notRead', 'post')
      cannot('read', 'post')
    }
  })
