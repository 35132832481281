import React, { useEffect } from 'react'
import CustomCard from 'components/common/CustomCard'
import ReusableTable from 'components/common/ReusableTable'
import { formatNumberToTwoDecimal } from 'common/helper'
import ButtonWithDisabledInfo from 'components/common/ButtonWithDisabledInfo'
import { format } from 'date-fns'
import {
  setPriceAndContainerFormType,
  setBulkSelectionOfCompetingWithItems,
  setCompetingWithListPageNumber,
  setCompetingWithListRowsPerPage,
  setCompetingWithListRequestSort,
  getCompetingWithPPList,
} from 'ducks/competitivePricePosting'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardReturn } from '@material-ui/icons'
import { formTypeValues } from 'common/constants'
import styled from 'styled-components'

const ActionWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
`

const competitiveListHeaders = [
  { id: 'manufacturer', label: 'Manufacturer', isSortable: true },
  { id: 'productName', label: 'Product Name', isSortable: true },
  { id: 'tradeName', label: 'Trade Name', isSortable: true },
  {
    id: 'package',
    label: 'Package Configuration',
    isSortable: true,
  },
  { id: 'size', label: 'Size', isSortable: true },
  { id: 'county', label: 'County', isSortable: true },
  { id: 'pricesTo', label: 'Prices To', isSortable: true },
  { id: 'price', label: 'Price($)', isSortable: true },
  {
    id: 'containerCharge',
    label: 'Container Charge($)',
    isSortable: true,
  },
  { id: 'createdByLicensee', label: 'By Licensee', isSortable: true },
  { id: 'effectiveDate', label: 'Effective Date', isSortable: true },
  { id: 'createdAt', label: 'Submit Date', isSortable: true },
  { id: 'status', label: 'Status', isSortable: true },
]

const CompetitorList = () => {
  const dispatch = useDispatch()
  const {
    listOfCompetingWithPP,
    competingWithListOrder,
    competingWithListOrderBy,
    competingWithListPageNumber,
    competingWithListRowsPerPage,
    bulkSelectionOfCompetingWithItems,
    competingWithPPTotalCount,
  } = useSelector(store => store.competitivePricePosting)

  const { fetchingTotalCount } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getCompetingWithPPList())
  }, [
    dispatch,
    competingWithListOrder,
    competingWithListOrderBy,
    competingWithListPageNumber,
    competingWithListRowsPerPage,
  ])

  //---------Methods for my competitors list------------
  const onSingleItemSelectionInCompetitorList = pricePosting => {
    //Might come in the future
  }

  const onBulkSelectionInCompetitorList = items => {
    const bulkSelectedIDs = bulkSelectionOfCompetingWithItems.map(d => d.id)
    const singlePageSelectedIDs = listOfCompetingWithPP.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelectionOfCompetingWithItems.filter(
        item => !singlePageSelectedIDs.includes(item.id)
      )
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          listOfCompetingWithPP.results.map(finalObject => ({
            id: finalObject.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = listOfCompetingWithPP.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
          }))

        newSelected = newSelected.concat(
          bulkSelectionOfCompetingWithItems,
          extractedValue
        )
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(
          bulkSelectionOfCompetingWithItems.slice(1)
        )
      } else if (
        selectedIndex ===
        bulkSelectionOfCompetingWithItems.length - 1
      ) {
        newSelected = newSelected.concat(
          bulkSelectionOfCompetingWithItems.slice(0, -1)
        )
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelectionOfCompetingWithItems.slice(0, selectedIndex),
          bulkSelectionOfCompetingWithItems.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setBulkSelectionOfCompetingWithItems(newSelected))
    if (newSelected.length === 0) {
      dispatch(setPriceAndContainerFormType(false))
    }
  }

  const handleSelectionOfAllRowsInCompetitorList = () => {
    //argument 'true' acts as a flag for selectAll
    dispatch(getCompetingWithPPList(true))
  }

  const handleClearAllSelectionInCompetitorList = () => {
    dispatch(setBulkSelectionOfCompetingWithItems([]))
    dispatch(setPriceAndContainerFormType(formTypeValues.priceOnly))
  }

  const handlePageNumberInCompetitorList = newPage => {
    dispatch(setCompetingWithListPageNumber(newPage))
  }
  const handleRowsPerPageInCompetitorList = rowsPerPage => {
    dispatch(setCompetingWithListRowsPerPage(rowsPerPage))
  }
  const handleRequestSortInCompetitorList = value => {
    dispatch(setCompetingWithListRequestSort(value))
  }
  let competingWithPricePostings = []
  competingWithPricePostings = listOfCompetingWithPP.results.map(
    (pricePosting, index) => {
      const updatedItem = {
        id: pricePosting.id,
        manufacturer: pricePosting.manufacturer.name,
        productName: pricePosting.product.name,
        tradeName: pricePosting.product.tradeName,
        package: pricePosting.package.package,
        size: `${pricePosting.productSize.size} ${pricePosting.productSize.unit.unit}`,
        county: pricePosting.county,
        pricesTo: pricePosting.pricesTo.name,
        price: formatNumberToTwoDecimal(pricePosting.price),
        containerCharge: formatNumberToTwoDecimal(pricePosting.containerCharge),
        createdByLicensee:
          pricePosting.createdByLicensee && pricePosting.createdByLicensee.name,
        effectiveDate: format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
        createdAt: format(pricePosting.createdAt, 'MM/dd/yyyy'),
        status: pricePosting.status,
      }
      return updatedItem
    }
  )

  const continueButtonDisabled = bulkSelectionOfCompetingWithItems.length > 1

  const actionItemsOnSelection = (
    <ActionWrapper>
      <ButtonWithDisabledInfo
        buttonDisabledShowTooltip={continueButtonDisabled}
        disabledReasons={[
          'In order to proceed with competitive price posting, please select only one competitor.',
        ]}
        onClick={e =>
          dispatch(setPriceAndContainerFormType(formTypeValues.priceOnly))
        }
        buttonLabel='Continue'
        startIcon={<KeyboardReturn />}
      />
    </ActionWrapper>
  )

  return (
    <CustomCard
      title='Select the price posting(s) you wish to compete with:'
      children={
        <ReusableTable
          headers={competitiveListHeaders}
          tableData={competingWithPricePostings}
          pagination={true}
          selectionOption={true}
          totalCount={competingWithPPTotalCount}
          fetchingTotalCount={fetchingTotalCount}
          onSingleItemSelected={onSingleItemSelectionInCompetitorList}
          handleBulkSelection={onBulkSelectionInCompetitorList}
          setPageNumber={handlePageNumberInCompetitorList}
          setRowsPerPage={handleRowsPerPageInCompetitorList}
          setRequestedSort={handleRequestSortInCompetitorList}
          pageNumber={competingWithListPageNumber}
          rowsPerPage={competingWithListRowsPerPage}
          order={competingWithListOrder}
          orderBy={competingWithListOrderBy}
          bulkSelected={bulkSelectionOfCompetingWithItems}
          handleSelectAllRows={handleSelectionOfAllRowsInCompetitorList}
          canChangeRowsPerPage={[5, 10, 20, 50, 100]}
          handleClearSelection={handleClearAllSelectionInCompetitorList}
          actionItemsOnSelection={actionItemsOnSelection}
        />
      }
    />
  )
}

export default CompetitorList
