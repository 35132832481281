import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Save, Block } from '@material-ui/icons'
import styled from 'styled-components'
import { darken } from 'polished'

const Wrapper = styled(Grid)`
  && {
    margin-top: 2px;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
`

const StyledButton = styled(Button)`
  && {
    width: 100%;
    color: white;
    ${props =>
      props.disabled
        ? `background-color: rgba(0, 0, 0, 0.12);`
        : props.styledcolor
        ? `background-color: ${props.theme[props.styledcolor]};`
        : ''}
    transition: 250ms all ease-in;

    &:hover {
      ${props =>
        props.styledcolor
          ? `background-color: ${darken(0.1, props.theme[props.styledcolor])};`
          : ''}
    }
  }
`

const SaveAndCancelButton = ({
  onSave,
  onCancel,
  submitLabel,
  submitColor = 'blue',
  cancelLabel,
  isDisabled,
}) => {
  return (
    <Wrapper container spacing={3}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <StyledButton
          aria-label='clear-form'
          styledcolor='cancel'
          variant='contained'
          onClick={() => onCancel()}
        >
          {cancelLabel ? cancelLabel : 'Cancel'}
        </StyledButton>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <StyledButton
          type='button'
          aria-label='submit-filter'
          styledcolor={submitColor}
          variant='contained'
          onClick={() => onSave()}
          disabled={isDisabled}
          id='buttonSave'
        >
          {submitColor === 'red' ? <Block /> : <Save />}
          &nbsp;&nbsp;{submitLabel ? submitLabel : 'Submit'}
        </StyledButton>
      </Grid>
    </Wrapper>
  )
}

export default SaveAndCancelButton
