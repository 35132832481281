import React, { useEffect } from 'react'
import { TextField } from 'formik-material-ui'
import CustomCard from 'components/common/CustomCard'
import { Field, Form, withFormik } from 'formik'
import { Grid, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  licenseCategoryList,
  receivingMethod,
  pricePostingStatus,
  operators,
  californiaCounties,
  includeCompetitiveOptions,
} from 'common/constants'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import {
  getLEsForDropDown,
  fetchPricesTo,
  fetchPackageConfiguration,
  fetchProductSizes,
  fetchProductsWithTrade,
} from 'ducks/global'
import {
  getPricePostingsList,
  clearFilters,
  setPricePostingsPageNumber,
  setFiltersUsed,
  setPricePostingBulkSelect,
} from 'ducks/pricePostings'
import NumericInput from 'components/common/NumericInput'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import GenericDatePicker from 'components/common/GenericDatePicker'
import { areAllCountiesSelected } from 'common/helper'
import { Can } from 'common/abilityContext'

const DatePickerField = styled(Field)`
  && {
    margin-top: 8px;
    width: 100%;
  }
`

const PricesGridWrapper = styled(Grid)`
  && {
    display: flex;
  }
`

const OperatorSelect = styled(Field)`
  && {
    &:first-child * div {
      font-size: 20px;
      width: 30px;
    }

    fieldset {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: none;
    }
  }
`

const OperatorDropDown = styled(MenuItem)`
  && {
    font-size: 20px;
  }
`

const PriceField = styled(Field)`
  && {
    fieldset {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`

const PricePostingsFilters = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLEsForDropDown())
    dispatch(fetchPricesTo())
    dispatch(fetchPackageConfiguration())
    dispatch(fetchProductSizes())
    dispatch(fetchProductsWithTrade(false))
  }, [dispatch])

  const { user } = useSelector(store => store.auth)
  const { listType } = useSelector(store => store.pricePostings)
  const {
    lEsForDropDown,
    pricesToForDropDown,
    productsWithTrade,
    packageConfigForDropDown,
    productSizesForDropDown,
  } = useSelector(store => store.global)

  const loggedInUserIsManufacturerOnly =
    user.legalEntity && user.legalEntity.licenseCategory === licenseCategoryList.manufacturer

  const updatedPackageConfigs = packageConfigForDropDown ? packageConfigForDropDown : []
  const updatedSizesForDropDown = productSizesForDropDown ? productSizesForDropDown : []
  const updatedLEsForDropDown = lEsForDropDown

  const onApplyingFilters = formikProps => {
    formikProps.handleSubmit()
    if (formikProps.isValid) {
      let ppFilters = formikProps.values.pricePostingsFilters
      if (areAllCountiesSelected(formikProps.values.pricePostingsFilters.county)) {
        let counties = californiaCounties.filter(c => c.value !== 'All Counties')
        ppFilters = { ...ppFilters, county: counties }
      }
      dispatch(setPricePostingBulkSelect([]))
      dispatch(setPricePostingsPageNumber(0))
      dispatch(setFiltersUsed(true))
      dispatch(getPricePostingsList(ppFilters, true))
    }
  }

  const onFiltersCleared = formikProps => {
    dispatch(setPricePostingBulkSelect([]))
    dispatch(setFiltersUsed(false))
    dispatch(clearFilters())
    formikProps.setFieldValue(`pricePostingsFilters`, {
      createdByLicensee: [],
      manufacturer: [],
      products: [],
      tradeName: '',
      package: [],
      size: [],
      county: [],
      pricesTo: [],
      operatorForPrice: 'equal',
      price: '',
      pricePromotion: '',
      receivingMethod: [],
      operatorForContainerCharge: 'equal',
      containerCharge: '',
      status: [],
      effectiveFrom: '',
      effectiveTo: '',
      submittedFrom: '',
      submittedTo: '',
      includeCompetitive: { value: 'Both', label: 'Both' },
    })
  }

  return (
    <Form>
      <Field
        name='pricePostingsFilters'
        render={formikProps => {
          formikProps.form.values.pricePostingsFilters = JSON.parse(
            JSON.stringify(formikProps.form.values.pricePostingsFilters)
          )
          const pricePostingFormikValues = formikProps.form.values.pricePostingsFilters
          const allCountiesSelected = areAllCountiesSelected(pricePostingFormikValues.county)

          const handleAutoCompleteChange = async (field, event) => {
            const selectedValue = event ? event : []
            if (field === 'manufacturer') {
              let manufacturerIds = ''
              if (event && event.length > 0) {
                manufacturerIds = event.map(e => parseInt(e.value))
              }
              formikProps.form.setFieldValue(`pricePostingsFilters.products`, '')
              dispatch(fetchProductsWithTrade(manufacturerIds))
            } else if (field === 'county' && areAllCountiesSelected(selectedValue)) {
              // On county AND "ALL Counties" selected, remove the previously selected values
              formikProps.form.setFieldValue(`pricePostingsFilters.county`, [
                { value: 'All Counties', label: 'All Counties' },
              ])
              return null
            }
            await formikProps.form.setFieldValue(`pricePostingsFilters.${field}`, selectedValue)

            const buttonApply = document.querySelector('#buttonSave')
            buttonApply.click()
          }

          return (
            <>
              <CustomCard title='Filters'>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Field
                    placeHolderText='Manufacturer'
                    name='pricePostingsFilters.manufacturer'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.manufacturer}
                    dropDownOptions={updatedLEsForDropDown}
                    onValueChange={event => handleAutoCompleteChange('manufacturer', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                    isDisabled={
                      (listType === 'filtered' && loggedInUserIsManufacturerOnly) || false
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={6}>
                  <Field
                    placeHolderText='Products'
                    name='pricePostingsFilters.products'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.products}
                    dropDownOptions={productsWithTrade}
                    onValueChange={event => handleAutoCompleteChange('products', event)}
                    isMulti={true}
                    noOptionsMessage='Please select a manufacturer first.'
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Trade Name'
                    name='pricePostingsFilters.tradeName'
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Field
                    placeHolderText='Licensee (Posted By)'
                    name='pricePostingsFilters.createdByLicensee'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.createdByLicensee}
                    dropDownOptions={updatedLEsForDropDown}
                    onValueChange={event => handleAutoCompleteChange('createdByLicensee', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                    isDisabled={(listType === 'filtered' && user.legalEntity) || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Package Configuration'
                    name='pricePostingsFilters.package'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.package}
                    dropDownOptions={updatedPackageConfigs}
                    onValueChange={event => handleAutoCompleteChange('package', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Size'
                    name='pricePostingsFilters.size'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.size}
                    dropDownOptions={updatedSizesForDropDown}
                    onValueChange={event => handleAutoCompleteChange('size', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='County(ies)'
                    name='pricePostingsFilters.county'
                    selectedValue={pricePostingFormikValues.county}
                    formikProps={formikProps.form}
                    dropDownOptions={allCountiesSelected ? [] : californiaCounties}
                    onValueChange={event => handleAutoCompleteChange('county', event)}
                    noOptionsMessage={
                      allCountiesSelected
                        ? `Remove "All Counties" to see options`
                        : `No Options Found`
                    }
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Prices To'
                    name='pricePostingsFilters.pricesTo'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.pricesTo}
                    dropDownOptions={pricesToForDropDown}
                    onValueChange={event => handleAutoCompleteChange('pricesTo', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <PricesGridWrapper item xs={12} sm={6} md={4} lg={3}>
                  <OperatorSelect
                    select
                    name={'pricePostingsFilters.operatorForPrice'}
                    component={TextField}
                    margin='normal'
                    variant='outlined'
                  >
                    {operators.map((op, index) => (
                      <OperatorDropDown key={index} value={op.value}>
                        {op.label}
                      </OperatorDropDown>
                    ))}
                  </OperatorSelect>
                  <PriceField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Price'
                    id='price'
                    name='pricePostingsFilters.price'
                    component={NumericInput}
                    isAllowed={values => {
                      const { formattedValue, floatValue } = values
                      if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                        return false
                      }
                      return formattedValue === '' || floatValue <= 9999999999
                    }}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </PricesGridWrapper>
                <PricesGridWrapper item xs={12} sm={6} md={4} lg={3}>
                  <OperatorSelect
                    select
                    name={'pricePostingsFilters.operatorForContainerCharge'}
                    component={TextField}
                    margin='normal'
                    variant='outlined'
                  >
                    {operators.map((op, index) => (
                      <OperatorDropDown key={index} value={op.value}>
                        {op.label}
                      </OperatorDropDown>
                    ))}
                  </OperatorSelect>
                  <PriceField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    label='Container Charge'
                    id='containerCharge'
                    name='pricePostingsFilters.containerCharge'
                    component={NumericInput}
                    isAllowed={values => {
                      const { formattedValue, floatValue } = values
                      if (formattedValue.toString().match(/^[$]0[0].*$/)) {
                        return false
                      }
                      return formattedValue === '' || floatValue <= 9999999999
                    }}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </PricesGridWrapper>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Price Promotion'
                    name='pricePostingsFilters.pricePromotion'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.pricePromotion}
                    dropDownOptions={[
                      { value: 'false', label: 'No' },
                      { value: 'true', label: 'Yes' },
                    ]}
                    onValueChange={event => handleAutoCompleteChange('pricePromotion', event)}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Receiving Method'
                    name='pricePostingsFilters.receivingMethod'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.receivingMethod}
                    dropDownOptions={receivingMethod}
                    onValueChange={event => handleAutoCompleteChange('receivingMethod', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Field
                    placeHolderText='Status'
                    name='pricePostingsFilters.status'
                    formikProps={formikProps.form}
                    selectedValue={pricePostingFormikValues.status}
                    dropDownOptions={pricePostingStatus}
                    onValueChange={event => handleAutoCompleteChange('status', event)}
                    isMulti={true}
                    component={AutoCompleteComp}
                  />
                </Grid>
                <Can I='selectCompetitiveFilter' this='onPricePostingList'>
                  {() => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Field
                        placeHolderText='Include Competitive'
                        name='pricePostingsFilters.includeCompetitive'
                        formikProps={formikProps.form}
                        selectedValue={
                          pricePostingFormikValues.includeCompetitive || {
                            value: 'Both',
                            label: 'Both',
                          }
                        }
                        dropDownOptions={includeCompetitiveOptions}
                        onValueChange={event =>
                          handleAutoCompleteChange('includeCompetitive', event)
                        }
                        isMulti={false}
                        component={AutoCompleteComp}
                      />
                    </Grid>
                  )}
                </Can>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Effective Date Range'
                    name='pricePostingsFilters.effectiveDateRange'
                    component={GenericDatePicker}
                    fullWidth
                    value={{
                      begin: pricePostingFormikValues.effectiveFrom,
                      end: pricePostingFormikValues.effectiveTo,
                    }}
                    placeholder='Select a date range'
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'pricePostingsFilters.effectiveFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue('pricePostingsFilters.effectiveTo', values.end)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DatePickerField
                    label='Submitted Date Range'
                    name='pricePostingsFilters.submittedDateRange'
                    component={GenericDatePicker}
                    fullWidth
                    value={{
                      begin: pricePostingFormikValues.submittedFrom,
                      end: pricePostingFormikValues.submittedTo,
                    }}
                    placeholder='Select a date range'
                    onChange={values => {
                      formikProps.form.setFieldValue(
                        'pricePostingsFilters.submittedFrom',
                        values.begin
                      )
                      formikProps.form.setFieldValue('pricePostingsFilters.submittedTo', values.end)
                    }}
                  />
                </Grid>
                {/* <StatusWrapper item xs={12} sm={6} md={8} lg={6}>
                  <StatusField
                    select
                    name={'pricePostingsFilters.status'}
                    component={TextField}
                    margin='normal'
                    variant='outlined'
                    isselected={pricePostingFormikValues.status}
                  >
                    {pricePostingStatus.map((status, index) => (
                      <MenuItem key={index} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </StatusField>
                  {pricePostingFormikValues.status && (
                    <DatePickerField
                      label='Date Range'
                      name='pricePostingsFilters.dateRange'
                      component={DateRangePicker}
                      fullWidth
                      value={{
                        begin: pricePostingFormikValues.fromDate,
                        end: pricePostingFormikValues.toDate,
                      }}
                      placeholder='Select a date range'
                      onChange={values => {
                        formikProps.form.setFieldValue(
                          'pricePostingsFilters.fromDate',
                          values.begin
                        )
                        formikProps.form.setFieldValue(
                          'pricePostingsFilters.toDate',
                          values.end
                        )
                      }}
                    />
                  )}
                </StatusWrapper> */}
              </CustomCard>
              <SaveAndCancelButton
                submitLabel='Apply'
                cancelLabel='Clear'
                isDisabled={!formikProps.form.isValid}
                onSave={() => onApplyingFilters(formikProps.form)}
                onCancel={() => onFiltersCleared(formikProps.form)}
              />
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
})(PricePostingsFilters)

export default WithFormikWrapper
