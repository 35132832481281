import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  setLoading,
  setLoadingProgress,
  setEditMode,
  showError,
  showSuccess,
  setAuxLoading,
  fetchProductsWithTrade,
  fetchPackageConfiguration,
  fetchProductSizes,
  fetchPricesTo,
  getLEsForDropDown,
  setFetchingTotalCount,
} from './global'
import { format } from 'date-fns'
import {
  getPricePostingsQuery,
  getPricePostingByIDQuery,
  getPricePostingsQueryForExport,
  getPricePostingsQueryAsLicensee,
  getPPForBulkSelection,
  getPPForBulkSelectionAsLicensee,
  getPricePostingsAsLicenseeTotalCount,
  getPricePostingsTotalCount,
} from 'graphql/queries'
import {
  pricePostingMutation,
  confirmedPricePostingMutation,
  pricePostingPromoMutation,
  inactivatePricePostings,
  activatePricePostings,
} from 'graphql/mutations'
import {
  exportToExcel,
  removeExtraCharFromPrice,
  formatNumberToTwoDecimal,
  formatNumberToThreeDecimal,
  checkUserIsManufacturerNotCoc,
  checkUserIsCocOnly,
  checkUserIsManufacturerAndWholesaler,
  checkIfUserIsLicensee,
  checkIfUserIsABC,
  convertSizeOfBBL,
  whereClauseBuilderForPP,
} from 'common/helper'
import { californiaCounties, receivingMethod } from 'common/constants'

const fetchPricePostingsTotalCount = graphQlParams => {
  return async dispatch => {
    dispatch(setFetchingTotalCount(true))
    if (graphQlParams.manufacturerId) {
      await API.graphql(graphqlOperation(getPricePostingsAsLicenseeTotalCount, graphQlParams))
        .then(res => {
          dispatch(setPricePostingsTotalCount(res.data.pricePostingsAsLicenseeCount.count))
        })
        .catch(err => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    } else {
      await API.graphql(graphqlOperation(getPricePostingsTotalCount, graphQlParams))
        .then(res => {
          dispatch(setPricePostingsTotalCount(res.data.pricePostingsCount.count))
        })
        .catch(_ => {
          dispatch(
            showError(
              'There was issue while trying to fetch total number of pages, please check your internet connection, and try again. Thank you.'
            )
          )
        })
    }
    dispatch(setFetchingTotalCount(false))
  }
}

export const getPricePostingsList = (whereClause, getUpdatedTotalCount) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Postings...'))
    if (getUpdatedTotalCount) {
      dispatch(setPricePostingsTotalCount(0))
    }
    const pricePostingsState = getState().pricePostings
    const currentUser = getState().auth.user
    const userHasLicenseeRole = checkIfUserIsLicensee(currentUser.role.id)
    const userHasAbcRole = currentUser.role && checkIfUserIsABC(currentUser.role.id)

    let whereClauseFromFilters = {}

    if (userHasLicenseeRole) {
      // if the logged-in user has licensee role
      whereClauseFromFilters = {
        ...whereClauseFromFilters,
        requiredManufacturerId: currentUser.legalEntity.id,
        createdBy: currentUser.id,
      }
    }

    if (!userHasAbcRole) {
      whereClauseFromFilters = {
        ...whereClauseFromFilters,
        productStatus: 'Active',
      }
    }

    const orderRule = `${pricePostingsState.orderBy}_${pricePostingsState.order}`
    if (whereClause) {
      whereClauseFromFilters.includeCompetitivePost = whereClause.includeCompetitive.value

      whereClauseFromFilters = {
        ...whereClause,
        ...whereClauseFromFilters,
      }
      dispatch(setPricePostingsFilters(whereClauseFromFilters))
    } else {
      whereClauseFromFilters = {
        ...pricePostingsState.pricePostingsFilters,
        ...whereClauseFromFilters,
      }
    }
    let filtered
    if (userHasLicenseeRole && pricePostingsState.listType === 'filtered') {
      filtered = true
    } else if (userHasLicenseeRole && pricePostingsState.listType === 'all') {
      filtered = false
    }

    let graphQlParams = {
      orderBy: orderRule,
      limit: pricePostingsState.rowsPerPage,
      offset: pricePostingsState.pageNumber * pricePostingsState.rowsPerPage,
      filtered: filtered,
    }

    let pricePostingsList
    if (userHasAbcRole) {
      if (whereClauseFromFilters) {
        graphQlParams.where = whereClauseBuilderForPP(whereClauseFromFilters)
      }
      await API.graphql(graphqlOperation(getPricePostingsQuery, graphQlParams))
        .then(res => {
          pricePostingsList = res.data
          dispatch(setPricePostingsList(pricePostingsList.pricePostings))
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of price postings, please check your internet connection and try again. Thank you.'
            )
          )
        )
    } else {
      //  If it gets here, the user has the licensee role
      if (pricePostingsState.listType === 'filtered') {
        //if the list is filtered to show items from the logged-in user's manufacturer
        //also if the filter is closed
        //if both these cases are agreed, then  added manufacturer ID
        whereClauseFromFilters = {
          ...whereClauseFromFilters,
          filteredListByCreatedByLicensee: {
            value: currentUser.legalEntity.id,
          },
        }
      } else if (pricePostingsState.listType === 'all' && !pricePostingsState.areFiltersUsed) {
        whereClauseFromFilters = {
          ...whereClauseFromFilters,
          filteredListByCreatedByLicensee: '',
        }
      }

      if (whereClauseFromFilters) {
        graphQlParams = {
          ...graphQlParams,
          manufacturerId: parseInt(whereClauseFromFilters.requiredManufacturerId),
          userId: parseInt(whereClauseFromFilters.createdBy),
          where: whereClauseBuilderForPP(whereClauseFromFilters),
        }
      }
      await API.graphql(graphqlOperation(getPricePostingsQueryAsLicensee, graphQlParams))
        .then(res => {
          pricePostingsList = res.data
          dispatch(setPricePostingsList(pricePostingsList.pricePostingsAsLicensee))
        })
        .catch(_ =>
          dispatch(
            showError(
              'Unable to retrieve list of price postings, please check your internet connection and try again. Thank you.'
            )
          )
        )
    }
    // If list is loaded and totalCount isn't retrieved yet, get count
    if (
      pricePostingsList &&
      (pricePostingsState.pricePostingsTotalCount === 0 || getUpdatedTotalCount)
    ) {
      let params
      if (pricePostingsList.pricePostings) {
        //setting params for ABC/non-licensee users
        params = { where: graphQlParams.where }
      } else {
        //setting params for licensee users
        params = {
          manufacturerId: graphQlParams.manufacturerId,
          userId: graphQlParams.userId,
          where: graphQlParams.where,
          filtered: filtered,
        }
      }
      dispatch(fetchPricePostingsTotalCount(params))
    }
    dispatch(setLoading(false))
  }
}

export const selectAllPricePostings = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Selecting all price postings...'))
    const pricePostingsState = getState().pricePostings
    let whereClauseFromFilters = pricePostingsState.pricePostingsFilters

    const currentUser = getState().auth.user
    const userHasLicenseeRole = checkIfUserIsLicensee(currentUser.role.id)

    let allPricePosts
    let graphQlParams = {}
    if (userHasLicenseeRole) {
      // if the logged-in user has licensee role
      whereClauseFromFilters = {
        ...whereClauseFromFilters,
        requiredManufacturerId: currentUser.legalEntity.id,
        createdBy: currentUser.id,
      }
      if (pricePostingsState.listType === 'filtered' && !pricePostingsState.areFiltersUsed) {
        //if the list is filtered to show items from the logged-in user's manufacturer
        //also if the filter is closed
        //if both these cases are agreed, then  add manufacturer ID
        whereClauseFromFilters = {
          ...whereClauseFromFilters,
          filteredListByCreatedByLicensee: {
            value: currentUser.legalEntity.id,
          },
        }
      } else if (pricePostingsState.listType === 'all' && !pricePostingsState.areFiltersUsed) {
        whereClauseFromFilters = {
          ...whereClauseFromFilters,
          filteredListByCreatedByLicensee: '',
        }
      }

      graphQlParams = {
        ...graphQlParams,
        manufacturerId: parseInt(whereClauseFromFilters.requiredManufacturerId),
        userId: parseInt(whereClauseFromFilters.createdBy),
        where: whereClauseBuilderForPP(whereClauseFromFilters),
      }
      const res = await API.graphql(
        graphqlOperation(getPPForBulkSelectionAsLicensee, graphQlParams)
      )
      allPricePosts = res.data.pricePostingsAsLicensee
      allPricePosts.count > 0 &&
        dispatch(
          setPricePostingBulkSelect(
            allPricePosts.results.map(r => ({
              id: r.id,
              manufacturer: {
                value: r.manufacturer.id,
                label: r.manufacturer.name,
              },
              package: {
                value: r.package.id,
                label: r.package.name,
              },
              receivingMethod: {
                value: r.receivingMethod,
                label: r.receivingMethod,
              },
              size: {
                value: r.productSize.id,
              },
              pricesTo: {
                value: r.pricesTo.id,
                label: r.pricesTo.name,
              },
              product: {
                value: r.product.id,
                label: r.product.name,
              },
              createdAt: r.createdAt,
              parentPricePostId: r.parentPricePostId,
              status: r.status,
              price: r.price,
              containerCharge: r.containerCharge,
              county: r.county,
              createdByLicenseeID: r.createdByLicensee ? r.createdByLicensee.id : '',
            }))
          )
        )
    } else {
      //  If it gets here, the user has the ABC role
      graphQlParams.where = whereClauseBuilderForPP(whereClauseFromFilters)
      const res = await API.graphql(graphqlOperation(getPPForBulkSelection, graphQlParams))
      allPricePosts = res.data.pricePostings
      allPricePosts.count > 0 &&
        dispatch(
          setPricePostingBulkSelect(
            allPricePosts.results.map(r => ({
              id: r.id,
              status: r.status,
              createdByLicenseeID: r.createdByLicensee ? r.createdByLicensee.id : '',
            }))
          )
        )
    }

    if (!allPricePosts || !allPricePosts.count === 0)
      dispatch(
        showError(
          'Unable to select items, please check your internet connection and try again. Thank you.'
        )
      )

    dispatch(setLoading(false))
  }
}

// Fetch pricePosting by ID after selecting list item
export const fetchPricePostingByID = (id, editMode = false) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Posting...'))
    dispatch(setEditMode(editMode))

    const pricePostingRes = await API.graphql(
      graphqlOperation(getPricePostingByIDQuery, { id: parseInt(id) })
    )
    if (pricePostingRes.data.pricePostings.results[0]) {
      const res = pricePostingRes.data.pricePostings.results[0]
      const pricePostingFromResponse = {
        ...res,
        product: {
          value: res.product.id,
          label: res.product.name,
        },
        tradeName: res.product.tradeName,
        size: {
          value: res.productSize.id,
          label: `
            ${convertSizeOfBBL(res.productSize.size, res.productSize.unit.unit)} ${
            res.productSize.unit.unit
          } - ${res.productSize.containerType.type}
          `.trim(),
        },
        price: formatNumberToThreeDecimal(res.price),
        pricePromotion: {
          value: res.pricePromotion,
          label: res.pricePromotion ? 'Yes' : 'No',
        },
        containerCharge: formatNumberToTwoDecimal(res.containerCharge),
        status: { value: res.status, label: res.status },
        county: [{ value: res.county, label: res.county }],
        receivingMethod: [
          {
            value: res.receivingMethod,
            label: res.receivingMethod,
          },
        ],
        tradingArea: res.tradingArea ? res.tradingArea : '',
        createdBy: res.createdBy
          ? {
              id: `${res.createdBy.id}`,
              fullName: `${res.createdBy.firstName} ${res.createdBy.lastName}`,
            }
          : {
              id: '',
              fullName: '',
            },
        isEffectiveImmediately: false,
        promotional: res.promotional,
      }
      dispatch(setPricePostingDetails(pricePostingFromResponse))
      // dispatch(setOldPricePostingDetails(newResponse))
      dispatch(setPricePostingShow(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve pricePosting, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

const toLowerAndTrimWhenString = value => {
  return typeof value === 'string' ? value.toLowerCase().trim() : value
}

// ----------------------------------------------------------------
// ------------ PROCESS PRICE POSTINGS IN BULK BATCHES ------------
// ----------------------------------------------------------------
const batchingPricePostingProcess = (
  finalPricePostingsList,
  listOfNumberOfItemsPerRow,
  manualProcess = false
) => {
  return async (dispatch, getState) => {
    try {
      let pricePostingsAwaitingConfirmation = [],
        priceCreationSuccessItems = [],
        pricePostingErrors = []
      const { failedPricePostingsDuringBulk } = getState().pricePostings

      //If there are any errors stored, push them to the current error variable
      if (failedPricePostingsDuringBulk.length > 0) {
        pricePostingErrors = pricePostingErrors.concat(failedPricePostingsDuringBulk)
        dispatch(setFailedPricePostingsDuringBulk([]))
      }
      // Separate bulk into batches of 500 to allow progress to be shown and remove overloading the server
      // calculate the actual length of rows that will be added at the end
      const bulkListSize = listOfNumberOfItemsPerRow.reduce((a, b) => {
          return a + b
        }, 0),
        batchSize = 1000
      let progressCount = 0,
        bufferSize = Math.floor((batchSize / bulkListSize) * 100)

      dispatch(
        setLoadingProgress({
          value: 0,
          buffer: bufferSize >= 100 ? 100 : bufferSize,
        })
      )
      while (
        progressCount < bulkListSize &&
        pricePostingErrors.length <= 99000 //the actual limit is 100,000 just to avoid going above limit in the last iteration, kept the limit to 99,000
      ) {
        let batchPricePostingsList
        // If current batch is less than the max batch size send the remaining items on the list
        if (bulkListSize <= batchSize) {
          dispatch(setLoadingProgress(-1)) // Display indeterminate loading
          batchPricePostingsList = finalPricePostingsList
          progressCount += bulkListSize
        } else {
          // In order to truly maintain the max batch size per request we'll review which items will result in more than 1 post
          // For example: The ones with "all counties" and/or receiving method "Both"
          let smartlyBatchedItems = [],
            smartItemsCounter = 0
          // The listOfNumberOfItemsPerRow array already has the list of resulting posts per row
          // Loop through it until we hit the batch size (this way we avoid hidden posts on the API request from sending more than the batchSize)
          while (smartItemsCounter < batchSize && finalPricePostingsList.length > 0) {
            // Retrieve the item from the list and update the real counter of resulting price posts
            const oneRowItem = finalPricePostingsList.shift()
            smartlyBatchedItems.push(oneRowItem)
            const batchedOutNumber = listOfNumberOfItemsPerRow.shift()
            smartItemsCounter += batchedOutNumber
          }

          progressCount += smartItemsCounter
          batchPricePostingsList = smartlyBatchedItems
        }

        // ------------ PROCESS PRICE POSTINGS IN BULK ------------
        const res = await API.graphql(
          graphqlOperation(pricePostingMutation, {
            data: batchPricePostingsList,
            bulk: true,
          })
        )

        const results = res.data.pricePostingMutation

        // Concatenate arrays per batch
        pricePostingsAwaitingConfirmation = pricePostingsAwaitingConfirmation.concat(
          results.needConfirmation
        )
        priceCreationSuccessItems = priceCreationSuccessItems.concat(results.success)
        pricePostingErrors = pricePostingErrors.concat(results.errors)

        bufferSize = Math.floor(((progressCount + batchSize) / bulkListSize) * 100)
        dispatch(
          setLoadingProgress({
            value: Math.floor((progressCount / bulkListSize) * 100),
            buffer: bufferSize >= 100 ? 100 : bufferSize,
          })
        )
      }
      dispatch(
        setLoadingProgress({
          value: 100,
          buffer: 100,
        })
      )

      if (pricePostingsAwaitingConfirmation.length > 0 && pricePostingErrors.length > 0) {
        dispatch(setFailedPricePostingsDuringBulk(pricePostingErrors))
      }

      if (pricePostingsAwaitingConfirmation.length > 0) {
        dispatch(setPricePostingItems(pricePostingsAwaitingConfirmation))
        dispatch(setPricePostingConfirmation(true))
        if (priceCreationSuccessItems.length > 0) {
          dispatch(setPricePostingSuccess(priceCreationSuccessItems))
        }
      } else if (pricePostingErrors.length > 0) {
        if (manualProcess && pricePostingErrors.length < 10) {
          dispatch(showError(pricePostingErrors.map(err => err['Error Reason']).toString()))
        } else {
          if (pricePostingErrors.length > 99500) {
            dispatch(
              showError(
                `Application has reached the limit of 100,000 errors. We created a file with these errors, please review your file and try again, thanks.`
              )
            )
          } else {
            dispatch(
              showError(
                `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
              )
            )
          }

          exportToExcel(pricePostingErrors, 'failedPricePosting')
        }
      } else if (priceCreationSuccessItems.length > 0 && !pricePostingErrors.length > 0) {
        dispatch(showSuccess('Price posting was successful.'))
      }

      if (pricePostingsAwaitingConfirmation.length === 0 && priceCreationSuccessItems.length > 0) {
        dispatch(getPricePostingsList(false, true))
      } else {
        dispatch(setLoading(false))
      }
    } catch (error) {
      dispatch(
        showError(
          `An error occurred during the bulk upload process, please make sure your file follows the template requirements and try again.`
        )
      )
    }
    if (manualProcess) {
      dispatch(setEditMode(false))
      dispatch(setPricePostingShow(false))
    }
    dispatch(setLoadingProgress(null))
  }
}

export const bulkPricePostingProcess = bulkArrayFromExcel => {
  return async (dispatch, getState) => {
    dispatch(setPPBulkUploadDialog(false))
    dispatch(setLoading('Retrieving data for bulk price posting...'))
    dispatch(setFailedPricePostingsDuringBulk([]))
    const currentUser = getState().auth.user
    let pricePostingErrors = [],
      finalPricePostingsList = [],
      pricesToOptions,
      productsWithTradeOptions,
      packageConfigOptions,
      productSizesOptions,
      listOfNumberOfItemsPerRow = []

    const currentUserLicenseeName = currentUser.legalEntity.name
    // Wrap on try catch to remove loading and reset UI if an unexpected error is found
    try {
      // Check if the logged in user is only manufacturer and not license type COC
      let userIsOnlyManufacturerNotCOC = null

      userIsOnlyManufacturerNotCOC = checkUserIsManufacturerNotCoc(currentUser.legalEntity)

      let correctlyEnteredManufacturerList = []

      if (userIsOnlyManufacturerNotCOC) {
        //Check if all the items from the uploaded excel have manufacturer name same as logged in user's licensee's name
        //filter only the correctly entered manufacturer details(value, label)
        bulkArrayFromExcel
          .map(bulkItem => toLowerAndTrimWhenString(bulkItem.manufacturer))
          .includes(toLowerAndTrimWhenString(currentUserLicenseeName)) &&
          (await dispatch(fetchProductsWithTrade(false))) //Fetch productName with tradeName, using logged in user's manufacturer ID, if no arguments were sent
      } else {
        //if it reaches here than the logged in user is other than *manufacturerOnlyNotCOC*
        //retrieve  all the configurations with IDs(packageConfig, productSizes, pricesTo)
        //use the list(receivingMethods, county ) from constants.js
        await dispatch(getLEsForDropDown()) // get the manufacturers list, to check if user entered correct manufacturer names in each row

        const existingLEsList = getState().global.lEsForDropDown
        const bulkArrayManufacturersList = bulkArrayFromExcel.map(bulkItem =>
          toLowerAndTrimWhenString(bulkItem.manufacturer)
        )

        //filter only the correctly entered manufacturer details(value, label )
        correctlyEnteredManufacturerList = existingLEsList.filter(existingLE =>
          bulkArrayManufacturersList.includes(toLowerAndTrimWhenString(existingLE.label))
        )

        //productName with tradeName, use all the manufacturer from bulkJSONWithCorrectManufacturer
        correctlyEnteredManufacturerList.length > 0 &&
          (await dispatch(
            fetchProductsWithTrade(correctlyEnteredManufacturerList.map(item => item.value))
          ))
      }

      dispatch(setLoading('Validating fields...'))

      await Promise.all([
        dispatch(fetchPackageConfiguration()),
        dispatch(fetchProductSizes()),
        dispatch(fetchPricesTo()),
      ])

      const {
        pricesToForDropDown,
        productsWithTradeForBulkProcess,
        packageConfigForDropDown,
        productSizesForDropDown,
      } = getState().global
      pricesToOptions = pricesToForDropDown
      productsWithTradeOptions = productsWithTradeForBulkProcess
      packageConfigOptions = packageConfigForDropDown
      productSizesOptions = productSizesForDropDown

      for (const bulkItem of bulkArrayFromExcel) {
        // Loop through each row item and check if all the fields entered are available
        let invalidRequiredFields = []

        let finalManufacturer
        if (bulkItem.manufacturer) {
          if (userIsOnlyManufacturerNotCOC) {
            finalManufacturer = toLowerAndTrimWhenString(bulkItem.manufacturer) ===
              toLowerAndTrimWhenString(currentUserLicenseeName) && {
              value: currentUser.legalEntity.id,
              label: currentUserLicenseeName,
            }
          } else {
            finalManufacturer = correctlyEnteredManufacturerList.filter(
              manufacturerObj =>
                toLowerAndTrimWhenString(manufacturerObj.label) ===
                toLowerAndTrimWhenString(bulkItem.manufacturer)
            )[0]
          }
        }
        if (!finalManufacturer) {
          invalidRequiredFields.push('Manufacturer')
        }

        let finalProductWithTrade

        if (bulkItem.productName) {
          const finalProduct = productsWithTradeOptions.filter(productObj => {
            return (
              toLowerAndTrimWhenString(productObj.name) ===
                toLowerAndTrimWhenString(bulkItem.productName) &&
              (userIsOnlyManufacturerNotCOC ||
                toLowerAndTrimWhenString(finalManufacturer.label) ===
                  toLowerAndTrimWhenString(productObj.manufacturer.name))
            )
          })

          if (finalProduct.length === 0) {
            invalidRequiredFields.push('Product name')
          }

          if (finalProduct.length > 0 && bulkItem.tradeName) {
            finalProductWithTrade = finalProduct.filter(
              productObj =>
                productObj.tradeName &&
                toLowerAndTrimWhenString(productObj.tradeName) ===
                  toLowerAndTrimWhenString(bulkItem.tradeName)
            )[0]
            if (!finalProductWithTrade) {
              invalidRequiredFields.push('Trade name')
            }
          } else if (finalProduct.length > 0) {
            finalProductWithTrade = finalProduct.filter(
              productObj => productObj.tradeName === ''
            )[0]
            if (!finalProductWithTrade) {
              invalidRequiredFields.push('Trade name')
            }
          }
        } else {
          invalidRequiredFields.push('Product name')
        }

        let finalPackageConfig
        if (bulkItem.packageConfiguration) {
          finalPackageConfig = packageConfigOptions.filter(
            packageObj =>
              toLowerAndTrimWhenString(packageObj.label) ===
              toLowerAndTrimWhenString(bulkItem.packageConfiguration)
          )[0]
        }

        if (!finalPackageConfig) {
          invalidRequiredFields.push('Package configuration')
        }

        let finalProductSize
        if (bulkItem.productSize && typeof bulkItem.productSize === 'string') {
          // filter out all the matched product size-unit  with the entered product size-unit
          const matchedProductSizeAndUnit = productSizesOptions.filter(item => {
            // the label is in <15.5 Liter - can> format, hence split by "-" and trim
            return (
              toLowerAndTrimWhenString(bulkItem.productSize) ===
              toLowerAndTrimWhenString(item.label.split('-')[0])
            )
          })

          if (matchedProductSizeAndUnit && matchedProductSizeAndUnit.length > 0) {
            if (bulkItem.containerType) {
              // now check if the entered container type exists in the matched product size-unit list
              const productSizeUnitTypeIndex = matchedProductSizeAndUnit.findIndex(
                item =>
                  toLowerAndTrimWhenString(bulkItem.containerType) ===
                  toLowerAndTrimWhenString(item.label.split('-')[1])
              )
              if (productSizeUnitTypeIndex > -1) {
                //both product size-unit and container type combination is found
                finalProductSize = matchedProductSizeAndUnit[productSizeUnitTypeIndex]
              } else {
                invalidRequiredFields.push('Combination of product size & container type')
              }
            }
          } else {
            invalidRequiredFields.push('Product size')
          }
        } else {
          invalidRequiredFields.push('Product size')
        }

        if (!bulkItem.containerType) {
          invalidRequiredFields.push('Container Type')
        }

        let finalCounty
        if (bulkItem.county) {
          const enteredCounty = toLowerAndTrimWhenString(bulkItem.county)
          finalCounty =
            enteredCounty === 'all counties' || enteredCounty === 'all'
              ? californiaCounties.filter(c => c.value !== 'All Counties')
              : californiaCounties.filter(
                  countyObj => countyObj.label.toLowerCase() === enteredCounty
                )
        }

        if (!finalCounty || finalCounty.length === 0) {
          invalidRequiredFields.push('County')
        }

        let finalReceivingMethod
        if (bulkItem.receivingMethod) {
          const enteredReceivingMethod = toLowerAndTrimWhenString(bulkItem.receivingMethod)
          finalReceivingMethod =
            enteredReceivingMethod === 'both'
              ? receivingMethod
              : receivingMethod.filter(
                  receivingMetObj => receivingMetObj.label.toLowerCase() === enteredReceivingMethod
                )
        }

        if (!finalReceivingMethod || finalReceivingMethod.length === 0) {
          invalidRequiredFields.push('Receiving Method')
        }

        let finalPricesTo
        if (bulkItem.pricesTo) {
          finalPricesTo = pricesToOptions.filter(
            pricesToObj =>
              toLowerAndTrimWhenString(pricesToObj.label) ===
              toLowerAndTrimWhenString(bulkItem.pricesTo)
          )[0]
        }

        if (!finalPricesTo) {
          invalidRequiredFields.push('Prices To')
        }

        let finalPrice
        if (typeof bulkItem.price === 'number') {
          finalPrice = bulkItem.price
        } else if (bulkItem.price && /^[$][0-9]{1,2}.[0-9]{1,2}/.test(bulkItem.price)) {
          finalPrice = parseFloat(bulkItem.price.replace(/[$,]/gi, ''))
        } else {
          invalidRequiredFields.push('Price')
        }

        let finalContainerCharge
        if (typeof bulkItem.containerCharge === 'number') {
          finalContainerCharge = bulkItem.containerCharge
        } else if (
          bulkItem.containerCharge &&
          /^[$][0-9]{1,2}.[0-9]{1,2}/.test(bulkItem.containerCharge)
        ) {
          finalContainerCharge = parseFloat(bulkItem.containerCharge.replace(/[$,]/gi, ''))
        } else {
          invalidRequiredFields.push('Container charge')
        }

        let finalPricePromotion
        if (
          bulkItem.pricePromotion &&
          (bulkItem.pricePromotion.toUpperCase() === 'YES' ||
            bulkItem.pricePromotion.toUpperCase() === 'NO')
        ) {
          finalPricePromotion = {
            value: 'YES' === bulkItem.pricePromotion.toUpperCase(),
            label: bulkItem.pricePromotion,
          }
        } else {
          invalidRequiredFields.push('Price promotion')
        }

        // Check if all the fields in the current row, are entered correctly and can be used for price posting
        if (invalidRequiredFields.length === 0) {
          // Push each correctly entered row item into the finalPricePostingsList
          // And also calculate & push the possible number of price postings that can be created out of each row
          listOfNumberOfItemsPerRow.push(finalCounty.length * finalReceivingMethod.length)
          finalPricePostingsList.push({
            manufacturer: finalManufacturer,
            product: {
              value: finalProductWithTrade.id,
              name: finalProductWithTrade.name,
              tradeName: finalProductWithTrade.tradeName,
            },
            package: finalPackageConfig,
            size: finalProductSize,
            county: finalCounty,
            pricesTo: finalPricesTo,
            receivingMethod: finalReceivingMethod,
            price: finalPrice,
            containerCharge: finalContainerCharge,
            pricePromotion: finalPricePromotion,
            isEffectiveImmediately:
              bulkItem.isEffectiveImmediately &&
              toLowerAndTrimWhenString(bulkItem.isEffectiveImmediately) === 'yes'
                ? true
                : false,
            userId: currentUser.id,
            userLicenseeID: currentUser.legalEntity.id,
            status: '',
            source: 'Bulk Upload',
          })
        } else {
          //if it gets here that means, one or more fields do not support for price posting process
          //hence add a errorReason for the current row object and push it to the array list
          pricePostingErrors.push({
            Manufacturer: bulkItem.manufacturer,
            'Trade name': bulkItem.tradeName,
            'Product Name': bulkItem.productName,
            'Package Configuration': bulkItem.packageConfiguration,
            'Product Size': bulkItem.productSize,
            'Container Type': bulkItem.containerType,
            County: bulkItem.county,
            'Prices To': bulkItem.pricesTo,
            'Receiving Method': bulkItem.receivingMethod,
            'Price($)': bulkItem.price,
            'Container Charge($)': bulkItem.containerCharge,
            'Price Promotion(Yes/No)': bulkItem.pricePromotion,
            'Effective Immediately(Yes/No)': bulkItem.isEffectiveImmediately,
            'Error Reason': `Invalid ${invalidRequiredFields.join(', ')}`,
          })
        }
      }

      //this will make sure, to export the error file right away if there are no items for price postings
      //if there are any items available for price posting, then the error file will be stored in store for exporting later
      if (pricePostingErrors.length > 0) {
        if (finalPricePostingsList.length > 0) {
          dispatch(setFailedPricePostingsDuringBulk(pricePostingErrors))
        } else {
          exportToExcel(pricePostingErrors, 'failedPricePosting')
          dispatch(setLoading(false))
          dispatch(
            showError(
              `Data validation was unsuccessful on 1 or more rows. We created a file with the error messages. Rows not included in the file have been added to the system. Please fix the issue(s) and upload the new file.`
            )
          )
        }
      }

      if (finalPricePostingsList.length > 0) {
        dispatch(setLoading('Processing price postings...'))
        dispatch(
          setLoadingProgress({
            value: 0,
            buffer: 0,
          })
        )
        dispatch(batchingPricePostingProcess(finalPricePostingsList, listOfNumberOfItemsPerRow))
      }
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(setLoadingProgress(null))
      dispatch(
        showError(
          `An error occurred during the bulk upload process, please make sure your file follows the template requirements and try again.`
        )
      )
    }
  }
}

export const handlePricePostSubmit = pricePostingDetailsList => {
  /**
   * @param pricePostingDetailsList: [
  {
    manufacturer: {
      value: '2',
      label: 'Famous Brewing Company',
    },
    product: {
      value: '6944',
      label: 'Famous Brewing Company - product from bulk - trade from bulk',
    },
    package: {
      value: '37',
      label: 'variety pack',
    },
    size: {
      value: '82',
      label: '15.5 Gallon(s)',
    },
    county: [
      {
        value: 'Alameda',
        label: 'Alameda',
      },
    ],
    tradingArea: 'on road', 
    pricesTo: {
      value: '12',
      label: 'localGuy',
    },
    price: '$11.00',
    containerCharge: '$1.00',
    receivingMethod: [
      {
        value: 'FOB',
        label: 'FOB',
      },
    ],
    isEffectiveImmediately: false,
    status: '',
    createdBy: {
      id: '',
      fullName: '',
    },
    createdByLicensee: {
      id: '',
      name: '',
    },
    pricePostingSuccess: '',
  },]
   */
  let newPricePostingsList = JSON.parse(JSON.stringify(pricePostingDetailsList))
  return async (dispatch, getState) => {
    dispatch(setLoading('Price posting in progress, please wait...'))
    dispatch(setFailedPricePostingsDuringBulk([]))
    const currentUser = getState().auth.user
    const { bulkSelected } = getState().pricePostings

    // Check if the logged in user is only manufacturer and not license type COC
    let userIsOnlyManufacturerNotCOC = null

    userIsOnlyManufacturerNotCOC = checkUserIsManufacturerNotCoc(currentUser.legalEntity)

    const userLicenseeID = parseInt(currentUser.legalEntity.id)

    // Check from the list of selection, if there are any pending and associated (have parentPricePostID equal to active item),
    // with one of the Active items in the list
    const parentPricePostIds = newPricePostingsList
      .filter(item => item.parentPricePostId && item.status.value === 'Pending')
      .map(t => t.parentPricePostId)
    newPricePostingsList = newPricePostingsList.filter(item =>
      parentPricePostIds.every(parentID => parseInt(item.id) !== parentID)
    )

    // Gather all final price postings to process them in bulk (1 API call)
    let finalPricePostingsList = [],
      listOfNumberOfItemsPerRow = []

    // Loop through the list of price postings to create final list
    for (const newPricePosting of newPricePostingsList) {
      // Add the manufacturer ID, if the logged in user is only Manufacturer
      let pricePosting
      if (userIsOnlyManufacturerNotCOC) {
        pricePosting = {
          ...newPricePosting,
          manufacturer: {
            value: userLicenseeID,
            label: currentUser.legalEntity.name,
          },
          userId: currentUser.id,
        }
      } else {
        pricePosting = {
          ...newPricePosting,
          manufacturer: {
            value: parseInt(newPricePosting.manufacturer.value),
            label: newPricePosting.manufacturer.label,
          },
          userId: currentUser.id,
        }
      }

      pricePosting = {
        ...pricePosting,
        price: removeExtraCharFromPrice(newPricePosting.price),
        containerCharge: removeExtraCharFromPrice(newPricePosting.containerCharge),
        pricePromotion: newPricePosting.pricePromotion ? newPricePosting.pricePromotion : false,
        isEffectiveImmediately: newPricePosting.isEffectiveImmediately
          ? newPricePosting.isEffectiveImmediately
          : false,
        userLicenseeID: userLicenseeID,
      }

      // calculate & push the possible number of price postings that can be created out of each row
      listOfNumberOfItemsPerRow.push(
        newPricePosting.county.length * newPricePosting.receivingMethod.length
      )

      // Push each correctly entered row item into the finalPricePostingsList
      finalPricePostingsList.push(pricePosting)
    }

    // --------------------------------------------------------
    // ------------ PROCESS PRICE POSTINGS IN BULK ------------
    // --------------------------------------------------------
    dispatch(batchingPricePostingProcess(finalPricePostingsList, listOfNumberOfItemsPerRow, true))

    if (bulkSelected.length > 0) {
      dispatch(setPricePostingBulkSelect([]))
    }
  }
}

export const pricePostingAfterConfirmation = pricePostingItems => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Price posting in progress, please wait...'))
    const currentUser = getState().auth.user
    const { failedPricePostingsDuringBulk } = getState().pricePostings

    if (pricePostingItems && pricePostingItems.length > 0) {
      // --------------------------------------------------------------
      // ---------- PROCESS CONFIRMED PRICE POSTINGS IN BULK ----------
      // --------------------------------------------------------------

      // Separate bulk into batches of 1000 to allow progress to be shown and remove overloading the server
      // calculate the actual length of rows that will be added at the end
      let priceCreationSuccessItems = [],
        pricePostingErrors = []
      const bulkListSize = pricePostingItems.length,
        batchSize = 1000
      let progressCount = 0,
        bufferSize = Math.floor((batchSize / bulkListSize) * 100)

      dispatch(
        setLoadingProgress({
          value: 0,
          buffer: bufferSize >= 100 ? 100 : bufferSize,
        })
      )
      while (progressCount < bulkListSize) {
        let batchPricePostingsList
        // If current batch is less than the max batch size send the remaining items on the list
        if (bulkListSize <= batchSize) {
          dispatch(setLoadingProgress(-1)) // Display indeterminate loading
          batchPricePostingsList = pricePostingItems
        } else {
          batchPricePostingsList = pricePostingItems.slice(progressCount, progressCount + batchSize)
        }

        const res = await API.graphql(
          graphqlOperation(confirmedPricePostingMutation, {
            data: batchPricePostingsList,
            userId: currentUser.id,
          })
        )

        const results = res.data.confirmedPricePostingMutation
        const priceChangeSuccess = results.changeSuccess,
          priceReactivationSuccess = results.reactivationSuccess

        const isSuccessful = priceChangeSuccess.length > 0 || priceReactivationSuccess.length > 0

        const priceChangeFailure = results.changeFailures,
          priceReactivationFailure = results.reactivationFailures

        const isFailed =
          priceChangeFailure.length > 0 ||
          priceReactivationFailure.length > 0 ||
          failedPricePostingsDuringBulk.length > 0

        if (isSuccessful && !isFailed) {
          priceCreationSuccessItems = priceCreationSuccessItems.concat(isSuccessful)
        } else {
          pricePostingErrors = pricePostingErrors.concat(
            priceChangeFailure
              .concat(priceReactivationFailure)
              .map(err => err.county)
              .join(',')
          )
        }

        progressCount += batchSize
        bufferSize = Math.floor(((progressCount + batchSize) / bulkListSize) * 100)
        dispatch(
          setLoadingProgress({
            value: Math.floor((progressCount / bulkListSize) * 100),
            buffer: bufferSize >= 100 ? 100 : bufferSize,
          })
        )
      }
      dispatch(
        setLoadingProgress({
          value: 100,
          buffer: 100,
        })
      )

      if (pricePostingErrors.length > 0) {
        showError(
          `There was an issue price posting with the following county(ies), please review the form and submit again. Thank you. ${pricePostingErrors
            .map(err => err.county)
            .join(',')}`
        )
        // exportToExcel(pricePostingErrors, 'failedPricePosting')
      } else if (priceCreationSuccessItems.length > 0 && !pricePostingErrors.length > 0) {
        dispatch(showSuccess(`Price Posting(s) was successful.`))
      }

      dispatch(setEditMode(false))
      dispatch(getPricePostingsList(false, true))
      dispatch(setLoadingProgress(null))
    }
  }
}

export const exportToExcelCall = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading Price Postings...'))
    const pricePostingState = getState().pricePostings
    const graphQlParams = {
      where: {
        id_in: pricePostingState.bulkSelected.map(item => parseInt(item.id)),
      },
      orderBy: 'createdAt_DESC',
    }
    await API.graphql(graphqlOperation(getPricePostingsQueryForExport, graphQlParams))
      .then(response => {
        const finalRes = response.data.pricePostings.results.map(pricePosting => {
          let updatedPricePosting = {}
          updatedPricePosting = {
            Manufacturer: pricePosting.manufacturer.name,
            'Trade Name': pricePosting.product.tradeName,
            'Product Name': pricePosting.product.name,
            'Package Configuration': pricePosting.package.package,
            'Product Size': `${convertSizeOfBBL(
              pricePosting.productSize.size,
              pricePosting.productSize.unit.unit
            )} ${pricePosting.productSize.unit.unit}`,
            'Container Type': pricePosting.productSize.containerType.type,
            'Trading Area': pricePosting.tradingArea,
            County: pricePosting.county,
            'Prices To': pricePosting.pricesTo.name,
            'Receiving Method': pricePosting.receivingMethod,
            'Price($)': formatNumberToThreeDecimal(pricePosting.price),
            'Container Charge($)': formatNumberToTwoDecimal(pricePosting.containerCharge),
            'Is Effective Immediately(Yes/No)': 'No',
            'Price Promotion(Yes/No)': pricePosting.pricePromotion ? 'Yes' : 'No',
            Licensee: pricePosting.createdByLicensee ? pricePosting.createdByLicensee.name : '',
            'Submitted Date': format(pricePosting.createdAt, 'MM/dd/yyyy'),
            'Effective Date': format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
            Status: pricePosting.status,
          }
          return updatedPricePosting
        })
        exportToExcel(finalRes, 'listOfPricePostings')
      })
      .catch(errorResponse => {
        dispatch(
          showError(
            'Unable to retrieve list of pricePostings, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

/* ------------------------------------------------------------------------------------------ */
/* ------------- A C T I V A T E / I N A C T I V A T E   P R I C E  P O S T S  -------------- */
/* ------------------------------------------------------------------------------------------ */

// Enable/Disable pricePosting (update status in DB: Active/Inactive)
const togglePricePostingStatus = async (
  option,
  pricePostings,
  updatedBy,
  dispatch,
  pricePostingDetails,
  pricePostingsList
) => {
  dispatch(
    setLoading(
      `${option === 'ENABLE' ? 'Reactivating' : 'Inactivating'} selected price post${
        pricePostings.length > 1 ? 's' : ''
      }, please wait...`
    )
  )
  dispatch(setAuxLoading(true))

  const listOfIDs = pricePostings.map(item => item.id)
  // Check if we need to activate or inactivate
  if (option === 'ENABLE') {
    pricePostingsList = JSON.parse(JSON.stringify(pricePostingsList))

    await API.graphql(
      graphqlOperation(
        activatePricePostings({
          ids: listOfIDs,
          updatedBy,
        })
      )
    )
      .then(response => {
        // Clear selection
        dispatch(setPricePostingBulkSelect([]))

        if (response.data.activatePricePostings.success) {
          // Find the price posts, and change the status
          // Use the existing list of price postings and find the indexes of each row item selected for status change
          const pricePostingIndexes = listOfIDs.map(i =>
            pricePostingsList.results.findIndex(j => j.id === i)
          )
          // Use the list of indexes and change the status, to reflect on to the UI
          pricePostingIndexes.forEach(i => {
            pricePostingsList.results[i].status = 'Active'
          })
          dispatch(setPricePostingsList(pricePostingsList))
          dispatch(
            setPricePostingDetails({
              ...pricePostingDetails,
              status: { value: 'Active', label: 'Active' },
            })
          )
          dispatch(
            showSuccess(
              `Price post${pricePostings.length > 1 ? 's' : ''} reactivated successfully.`
            )
          )
        } else {
          dispatch(
            showError(
              `Duplicates on 1 or more of the selected items have been found, only items without conflicts can be reactivated.`
            )
          )
        }
      })
      .catch(_ => {
        dispatch(showError(`Unable to reactivate a price post, please try again.`))
      })
  } else {
    await API.graphql(graphqlOperation(inactivatePricePostings({ ids: listOfIDs, updatedBy })))
      .then(response => {
        // Clear selection
        dispatch(setPricePostingBulkSelect([]))
        dispatch(getPricePostingsList(false, true))
        dispatch(
          setPricePostingDetails({
            ...pricePostingDetails,
            status: { value: 'Inactive', label: 'Inactive' },
          })
        )
        dispatch(
          showSuccess(`Price post${pricePostings.length > 1 ? 's' : ''} inactivated successfully.`)
        )
      })
      .catch(_ => {
        dispatch(showError(`Unable to inactivate a price post, please try again.`))
      })
  }

  dispatch(setLoading(false))
  dispatch(setAuxLoading(false))
}

export const activatePricePosting = pricePostings => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const { pricePostingDetails, pricePostingsList } = getState().pricePostings

    await togglePricePostingStatus(
      'ENABLE',
      pricePostings,
      currUser.id,
      dispatch,
      pricePostingDetails, // Status: "active" is updated in the pricePostingDetails and updated to the store, after successful activation of price post.
      pricePostingsList // Sent to change the status on the UI
    )
  }
}

export const inactivatePricePosting = pricePostings => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const { pricePostingDetails } = getState().pricePostings

    await togglePricePostingStatus(
      'DISABLE',
      pricePostings,
      currUser.id,
      dispatch,
      pricePostingDetails // Status: "Inactive" is updated in the pricePostingDetails and updated to the store, after successful inactivation of price post.
    )
  }
}

const toggleActivateInactivatePricePostingPromo = async (
  dispatch,
  pricePostingsList,
  currentUserId,
  isActivatePromo = false
) => {
  dispatch(
    setLoading(
      `${isActivatePromo ? 'Activate' : 'Inactivate'} selected price post ${
        pricePostingsList.length > 1 ? 's' : ''
      }, please wait...`
    )
  )
  dispatch(setAuxLoading(true))
  let pricePostings = JSON.parse(JSON.stringify(pricePostingsList))
  const pricePostingIds = pricePostings.map(item => {
    return {
      id: item.id,
      status: item.status,
    }
  })

  try {
    const pricePostingsResult = await API.graphql(
      graphqlOperation(pricePostingPromoMutation, {
        data: pricePostingIds,
        userId: currentUserId,
        activatePromo: isActivatePromo,
      })
    )

    if (pricePostingsResult) {
      dispatch(setPricePostingBulkSelect([]))
      dispatch(getPricePostingsList(false, true))
      dispatch(
        showSuccess(
          `Price post${
            pricePostingsResult.length > 1 ? 's' : ''
          }  activated / inactivated price promo successfully.`
        )
      )
    }
  } catch (err) {
    dispatch(showError(`Unable to activate / inactivate a price promo, please try again.`))
  }
  dispatch(setLoading(false))
  dispatch(setAuxLoading(false))
}

export const activatePricePostingPromo = pricePostings => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user

    await toggleActivateInactivatePricePostingPromo(dispatch, pricePostings, currUser.id, true)
  }
}

export const inactivatePricePostingPromo = pricePostings => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user

    await toggleActivateInactivatePricePostingPromo(dispatch, pricePostings, currUser.id, false)
  }
}

export const clearFilters = (reload = true) => {
  return dispatch => {
    dispatch(setPricePostingsPageNumber(0))
    dispatch(setPricePostingsFilters(initialState.pricePostingsFilters))
    reload && dispatch(getPricePostingsList(false, true))
  }
}

export const resetPricePostingDetails = () => {
  return (dispatch, getState) => {
    const currentUser = getState().auth.user
    const userHasCocLicenseType = checkUserIsCocOnly(currentUser.legalEntity)
    const loggedInUserIsBoth = checkUserIsManufacturerAndWholesaler(currentUser.legalEntity)
    if (userHasCocLicenseType || loggedInUserIsBoth) {
      // if logged in user is either COC or both(manufacturer and wholesaler)
      dispatch(
        setPricePostingDetails({
          ...initialState.pricePostingDetails,
          manufacturer: {
            value: currentUser.legalEntity.id,
            label: currentUser.legalEntity.name,
          },
        })
      )
    } else {
      dispatch(setPricePostingDetails(initialState.pricePostingDetails))
    }
    dispatch(setLoading(false))
  }
}

export const setPricePostingsList = createAction('pricePostings/setPricePostingsList')
export const resetPricePostingsList = createAction('pricePostings/resetPricePostingsList')
export const setIsPricePostingsFilterOpen = createAction(
  'pricePostings/setIsPricePostingsFilterOpen'
)
export const setPricePostingsFilters = createAction('pricePostings/setPricePostingsFilters')
export const setPricePostingShow = createAction('pricePostings/setPricePostingShow')
export const setPricePostingDetails = createAction('pricePostings/setPricePostingDetails')
export const setPricePostingBulkSelect = createAction('pricePostings/setPricePostingBulkSelect')
// export const setOldPricePostingDetails = createAction(
//   'pricePostings/setOldPricePostingDetails'
// )
export const setPricePostingsPageNumber = createAction('pricePostings/setPricePostingsPageNumber')
export const setPricePostingsRowsPerPage = createAction('pricePostings/setPricePostingsRowsPerPage')
export const setPricePostingsRequestedSort = createAction(
  'pricePostings/setPricePostingsRequestedSort'
)
export const setIsBulkDialogOpen = createAction('pricePostings/setIsBulkDialogOpen')
export const setPricePostingBulk = createAction('pricePosting/setPricePostingBulk')
export const setPricePostingConfirmation = createAction('pricePosting/setPricePostingConfirmation')
export const setPricePostingItems = createAction('pricePosting/setPricePostingItems')
export const setListType = createAction('pricePosting/setListType')
export const setFiltersUsed = createAction('pricePosting/setFiltersUsed')
export const setPricePostingSuccess = createAction('pricePosting/setPricePostingSuccess')
export const setInactivationConfirmation = createAction('pricePosting/setInactivationConfirmation')
export const setActivationConfirmation = createAction('pricePosting/setActivationConfirmation')

export const setBulkInactivationConfirmation = createAction(
  'pricePosting/setBulkInactivationConfirmation'
)

export const setBulkActivationConfirmation = createAction(
  'pricePosting/setBulkActivationConfirmation'
)

export const setPricePromoActivationConfirmation = createAction(
  'pricePosting/setPricePromoActivationConfirmation'
)
export const setPricePromoBulkActivationConfirmation = createAction(
  'pricePosting/setPricePromoBulkActivationConfirmation'
)
export const setPricePromoInactivationConfirmation = createAction(
  'pricePosting/setPricePromoInactivationConfirmation'
)
export const setPricePromoBulkInactivationConfirmation = createAction(
  'pricePosting/setPricePromoBulkInactivationConfirmation'
)

export const setSinglePricePostSelected = createAction('pricePosting/setSinglePricePostSelected')

export const setIsCompetingUsingMyOwn = createAction('pricePosting/setIsCompetingUsingMyOwn')

export const setCompetingUsingOthers = createAction('pricePosting/setCompetingUsingOthers')
export const setIsMultiPriceChangeModalOpen = createAction(
  'pricePosting/setIsMultiPriceChangeModalOpen'
)
export const setPPBulkUploadDialog = createAction('pricePosting/setPPBulkUploadDialog')
export const setFailedPricePostingsDuringBulk = createAction(
  'pricePosting/setFailedPricePostingsDuringBulk'
)
export const setPricePostingsTotalCount = createAction('pricePosting/setPricePostingsTotalCount')

const initialState = {
  pricePostingsList: { results: [], firstLoad: true },
  pricePostingsTotalCount: 0,
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  singlePricePostSelected: '',
  bulkSelected: [],
  isPricePostingsFilterOpen: false,
  areFiltersUsed: false,
  pricePostingsFilters: {
    createdByLicensee: [],
    manufacturer: [],
    products: [],
    tradeName: '',
    package: [],
    size: [],
    county: [],
    pricesTo: [],
    operatorForPrice: 'equal',
    price: '',
    receivingMethod: [],
    operatorForContainerCharge: 'equal',
    containerCharge: '',
    status: [],
    effectiveFrom: '',
    effectiveTo: '',
    submittedFrom: '',
    submittedTo: '',
    includeCompetitive: '',
  },
  isPricePostingShown: false,
  isCompetingUsingMyOwn: false,
  isCompetingUsingOthers: false,
  pricePostingDetails: {
    manufacturer: '',
    product: '',
    package: '',
    size: '',
    county: '',
    tradingArea: '',
    pricesTo: '',
    price: '',
    pricePromotion: '',
    containerCharge: '',
    receivingMethod: '',
    isEffectiveImmediately: false,
    status: '',
    createdBy: {
      id: '',
      fullName: '',
    },
    createdByLicensee: {
      id: '',
      name: '',
    },
  },
  pricePostingSuccess: [],
  // oldPricePostingDetails: {
  //   manufacturer: '',
  //   tradeName: '',
  //   oldTradeName: '',
  //   name: '',
  //   oldName: '',
  // },
  pricePostingBulk: { manufacturer: {} },
  isBulkDialogOpen: false,
  pricePostingConfirmation: false,
  pricePostingItems: [],
  listType: 'filtered',
  activationConfirmation: false,
  inactivationConfirmation: false,
  bulkInactivationConfirmation: false,
  bulkActivationConfirmation: false,
  isMultiPriceChangeModalOpen: false,
  isPPBulkUploadDialogOpen: false,
  failedPricePostingsDuringBulk: [],
  pricePromoActivationConfirmation: false,
  pricePromoBulkActivationConfirmation: false,
  pricePromoInactivationConfirmation: false,
  pricePromoBulkInactivationConfirmation: false,
}

export default createReducer(initialState, {
  [setPricePostingsList]: (state, action) => {
    state.pricePostingsList = action.payload
  },
  [setPricePostingsTotalCount]: (state, action) => {
    state.pricePostingsTotalCount = action.payload
  },
  [resetPricePostingsList]: state => {
    state.pricePostingsList = initialState.pricePostingsList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.pricePostingsFilters = initialState.pricePostingsFilters
    state.isPricePostingsFilterOpen = initialState.isPricePostingsFilterOpen
    state.listType = initialState.listType
    state.areFiltersUsed = initialState.areFiltersUsed
    state.pricePostingSuccess = initialState.pricePostingSuccess
    state.isPricePostingShown = initialState.isPricePostingShown
    state.pricePostingDetails = initialState.pricePostingDetails
    state.singlePricePostSelected = initialState.singlePricePostSelected
    state.pricePostingBulk = initialState.pricePostingBulk
    state.pricePostingConfirmation = initialState.pricePostingConfirmation
    state.pricePostingItems = initialState.pricePostingItems
  },
  [setIsPricePostingsFilterOpen]: (state, action) => {
    state.isPricePostingsFilterOpen = action.payload
  },
  [setPricePostingsFilters]: (state, action) => {
    state.pricePostingsFilters = action.payload
  },
  [setPricePostingShow]: (state, action) => {
    state.isPricePostingShown = action.payload
  },
  [setPricePostingDetails]: (state, action) => {
    state.pricePostingDetails = action.payload
  },
  // [setOldPricePostingDetails]: (state, action) => {
  //   state.oldPricePostingDetails = action.payload
  // },
  [setPricePostingBulkSelect]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setSinglePricePostSelected]: (state, action) => {
    state.singlePricePostSelected = action.payload
  },
  [setPricePostingsPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setPricePostingsRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setPricePostingsRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setIsBulkDialogOpen]: (state, action) => {
    state.isBulkDialogOpen = action.payload
  },
  [setPricePostingBulk]: (state, action) => {
    state.pricePostingBulk = action.payload
  },
  [setPricePostingConfirmation]: (state, action) => {
    state.pricePostingConfirmation = action.payload
  },
  [setPricePostingItems]: (state, action) => {
    state.pricePostingItems = action.payload
  },
  [setListType]: (state, action) => {
    state.listType = action.payload
  },
  [setFiltersUsed]: (state, action) => {
    state.areFiltersUsed = action.payload
  },
  [setPricePostingSuccess]: (state, action) => {
    state.pricePostingSuccess = action.payload
  },
  [setInactivationConfirmation]: (state, action) => {
    state.inactivationConfirmation = action.payload
  },
  [setActivationConfirmation]: (state, action) => {
    state.activationConfirmation = action.payload
  },
  [setBulkInactivationConfirmation]: (state, action) => {
    state.bulkInactivationConfirmation = action.payload
  },
  [setBulkActivationConfirmation]: (state, action) => {
    state.bulkActivationConfirmation = action.payload
  },
  [setIsCompetingUsingMyOwn]: (state, action) => {
    state.isCompetingUsingMyOwn = action.payload
  },
  [setCompetingUsingOthers]: (state, action) => {
    state.isCompetingUsingOthers = action.payload
  },
  [setIsMultiPriceChangeModalOpen]: (state, action) => {
    state.isMultiPriceChangeModalOpen = action.payload
  },
  [setPPBulkUploadDialog]: (state, action) => {
    state.isPPBulkUploadDialogOpen = action.payload
  },
  [setFailedPricePostingsDuringBulk]: (state, action) => {
    state.failedPricePostingsDuringBulk = action.payload
  },
  [setPricePromoActivationConfirmation]: (state, action) => {
    state.pricePromoActivationConfirmation = action.payload
  },
  [setPricePromoBulkActivationConfirmation]: (state, action) => {
    state.pricePromoBulkActivationConfirmation = action.payload
  },
  [setPricePromoInactivationConfirmation]: (state, action) => {
    state.pricePromoInactivationConfirmation = action.payload
  },
  [setPricePromoBulkInactivationConfirmation]: (state, action) => {
    state.pricePromoBulkInactivationConfirmation = action.payload
  },
})
