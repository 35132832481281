import React from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import Draggable from 'react-draggable'

const PaperComponent = props => {
  return (
    <Draggable
      handle='#confirmation-modal-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const ModalWrapper = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${props => props.theme.gray};
  }
`

const ModalHeader = styled(DialogTitle)`
  margin: 0px;
  background-color: ${props => props.theme.dark};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${props => props.theme.light};
  cursor: move;
`

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 1px;
    right: 1px;
    color: ${props => props.theme.light};
  }
`

const ModalActions = styled(DialogActions)`
  && {
    max-width: 99%;
  }
`

const StyledConfirmationList = styled.ul`
  && {
    margin: 0px;
  }
`

const ConfirmationModal = ({
  isConfirmationModalOpen,
  onConfirmationModalClosed,
  confirmationList,
  onConfirmation,
  onCancel,
  extraMessage,
  type = 'primary',
}) => {
  const isDeactivation = type === 'deactivate' || type === 'inactivate'
  return (
    <ModalWrapper
      open={isConfirmationModalOpen}
      onClose={onConfirmationModalClosed}
      aria-labelledby='confirmation-modal-title'
      maxWidth='sm'
      fullWidth={true}
      scroll='paper'
      PaperComponent={PaperComponent}
    >
      <ModalHeader id='confirmation-modal-title'>
        {'Confirmation'}
        <CloseButton
          variant='contained'
          size='medium'
          color='primary'
          onClick={onConfirmationModalClosed}
        >
          <Close />
        </CloseButton>
      </ModalHeader>
      <DialogContent>
        <Typography
          variant='subtitle1'
          confirmation='true'
          children={
            <>
              {extraMessage ? (
                <>
                  {extraMessage} <br />
                </>
              ) : (
                ''
              )}
              {!extraMessage && confirmationList.length === 1 ? (
                confirmationList[0]
              ) : (
                <>
                  Please confirm the following:
                  <StyledConfirmationList>
                    {confirmationList.map((info, index) => (
                      <li key={index}>
                        <Typography variant='body2'>{info}</Typography>
                      </li>
                    ))}
                  </StyledConfirmationList>
                </>
              )}
            </>
          }
        />
      </DialogContent>
      <ModalActions>
        <SaveAndCancelButton
          submitLabel={isDeactivation ? type : 'Yes'}
          cancelLabel={isDeactivation ? 'Cancel' : 'No'}
          submitColor={isDeactivation ? 'red' : 'blue'}
          onSave={onConfirmation}
          onCancel={onCancel ? onCancel : onConfirmationModalClosed}
        />
      </ModalActions>
    </ModalWrapper>
  )
}
export default ConfirmationModal
