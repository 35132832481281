import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Form, Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import SubmitButton from 'components/common/SubmitButton'
import { forgotPassword } from 'ducks/authentication'

const validations = Yup.object().shape({
  username: Yup.string()
    .matches(/^\S+$/, `Username can't include spaces.`)
    .required('Required'),
})

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { tempUsername } = useSelector(store => store.auth)

  // If username is already sent, redirect to the code verification and new password entry page
  if (tempUsername.length > 0) {
    return <Redirect to='/verifyCode' />
  }

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Typography variant='h6'>Reset Password</Typography>
      <Formik
        initialValues={{ username: '' }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(forgotPassword(values.username)).then(() => {
            setSubmitting(false)
          })
        }}
        validationSchema={validations}
      >
        <Form noValidate>
          <Field
            name='forgotPassword'
            render={formikProps => (
              <>
                <Field
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='username'
                  label='Username'
                  name='username'
                  autoComplete='username'
                  component={TextField}
                  autoFocus
                />
                <SubmitButton isSubmitting={formikProps.form.isSubmitting} />
              </>
            )}
          />
        </Form>
      </Formik>
    </>
  )
}

export default ForgotPassword
