import { createAction, createReducer } from '@reduxjs/toolkit'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { getUserQuery } from 'graphql/queries'
import { updateUserDetailsMutation } from 'graphql/mutations'

import { showSuccess, showError, setLoading, resetGlobalDuck } from './global'
import { resetProductsList } from './products'
import { resetLicenseeUser } from './legalEntityUser'
import { resetBulkUpload } from './bulkUpload'
import { resetPricePostingsList } from './pricePostings'
import { resetProductSize } from './productSizes'
import { resetPricesTo } from './pricesTo'
import { resetLegalEntities } from './legalEntity'
import { resetABCUsers } from './abcUsers'
import {
  resetMyCompetingPricePosts,
  resetCompetingWithPPList,
} from './competitivePricePosting'
import { getToken } from 'common/helper'

// ACTIONS
export const setAuthenticated = createAction('auth/setAuthenticated')
export const setAuthenticating = createAction('auth/setAuthenticating')
export const setTempUsername = createAction('auth/setTempUsername')
export const setPathname = createAction('auth/setPathname')
export const setCodeVerified = createAction('auth/setCodeVerified')
export const setNewPassword = createAction('auth/setNewPassword')
export const setFailedLoginAttempts = createAction(
  'auth/setFailedLoginAttempts'
)
export const setUsernameRecoverySent = createAction(
  'auth/setUsernameRecoverySent'
)
export const resetAuth = createAction('auth/resetAuth')
export const setIsRegistrationCodeVerified = createAction(
  'auth/setIsRegistrationCodeVerified'
)
export const setIsRegisteringUserDetails = createAction(
  'auth/setIsRegisteringUserDetails'
)
export const setIsRegisteringLeDetails = createAction(
  'auth/setIsRegisteringLeDetails'
)
export const setRegistrationSuccess = createAction(
  'auth/setRegistrationSuccess'
)
export const setIsRecoveryUsernameCodeVerified = createAction(
  'auth/setIsRecoveryUsernameCodeVerified'
)
export const setEmailLinkedLicensees = createAction(
  'auth/setEmailLinkedLicensees'
)

/* ---------------- AUTHENTICATION METHODS ---------------- */

const getUser = async id => {
  const dbUser = await API.graphql(
    graphqlOperation(getUserQuery, { where: { cognitoId: id } })
  )
  return dbUser.data.user
}

const authenticateUser = (cognitoId, dispatch) => {
  // Get DB user based on CognitoId
  getUser(cognitoId).then(dbUser => {
    dbUser.cognitoId = cognitoId
    dispatch(
      setAuthenticated({
        isAuth: true,
        user: dbUser,
      })
    )
    dispatch(setAuthenticating(false))
  })
}

export const signIn = credentials => {
  return async dispatch => {
    dispatch(setAuthenticating(true))
    await Auth.signIn(credentials.username.toLowerCase(), credentials.password)
      .then(async user => {
        // Catch new password challenge
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (!credentials.newPassword) {
            // Redirect user to new password page
            dispatch(setTempUsername(credentials.username.toLowerCase()))
            dispatch(setNewPassword(true))
            dispatch(setAuthenticating(false))
          } else {
            await Auth.completeNewPassword(user, credentials.newPassword, {})
              .then(async cognitoUser => {
                dispatch(
                  showSuccess(
                    'New password set successfully, please login to continue.'
                  )
                )

                // Update cognitoId in users table before authentication
                const uid =
                  cognitoUser.signInUserSession.idToken.payload['custom:userId']
                const cognitoId =
                  cognitoUser.signInUserSession.idToken.payload.sub

                await API.graphql(
                  graphqlOperation(updateUserDetailsMutation, {
                    data: { cognitoId },
                    where: { id: uid },
                  })
                ).then(res => {
                  authenticateUser(cognitoId, dispatch)
                })
              })
              .catch(err => {
                dispatch(showError(err.message))
              })
          }
        } else {
          authenticateUser(user.attributes.sub, dispatch)
        }
      })
      .catch(async err => {
        switch (err.code) {
          case 'NotAuthorizedException':
            let errorMessage = ''
            if (err.message.includes('disabled')) {
              errorMessage = `Account is not active, please contact your administrator.`
              dispatch(setFailedLoginAttempts(0))
            } else {
              errorMessage =
                'The username and/or password you entered is invalid.'
              // Update failedLoginAttempts attribute in Cognito user
              await fetch(
                `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/update-user-attributes`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    Authorization:
                      'Bearer ' + getToken({ code: credentials.username }),
                    'Content-Type': 'text/plain',
                  },
                  body: JSON.stringify({
                    username: credentials.username.toLowerCase(),
                    type: 'FAILED_LOGIN_ATTEMPTS',
                  }),
                }
              )
                .then(response => response.json())
                .then(res => {
                  dispatch(
                    setFailedLoginAttempts(parseInt(res.failedLoginAttempts))
                  )
                })
            }
            dispatch(showError(errorMessage))
            break
          case 'UserLambdaValidationException':
            dispatch(
              showError(
                'Maximum number of failed login attempts has been exceeded, please reset your password.'
              )
            )
            break
          default:
            dispatch(setFailedLoginAttempts(0))
            dispatch(
              showError('The username and/or password you entered is invalid.')
            )
        }
        dispatch(setAuthenticating(false))
      })
  }
}

export const addNewPassword = (oldPassword, newPassword) => {
  return async (dispatch, getState) => {
    const tempUsername = getState().auth.tempUsername
    if (tempUsername.length > 0) {
      dispatch(
        signIn({
          username: tempUsername,
          password: oldPassword,
          newPassword,
        })
      )
    } else {
      dispatch(showError('No user found, please go to login page.'))
    }
  }
}

export const verifyRegistrationCode = value => {
  return async dispatch => {
    dispatch(setLoading(true))

    // Retrieve user data from DB matching the verification code
    await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/user-verification`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: value.verificationCode }),
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          code: value.verificationCode,
        }),
      }
    )
      .then(response => response.json())
      .then(res => {
        if (res.data) {
          if (res.data.status === 'Pending') {
            dispatch(
              showSuccess(
                'Registration code verified successfully, please continue the registration process'
              )
            )
            // Set user details
            const registeringUserFromResponse = {
              verificationCode: value.verificationCode,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              email: res.data.email,
              username: '',
              password: '',
              confirmPassword: '',
              phone: '',
              legalEntityId: res.data.legalEntityId,
            }
            dispatch(setIsRegisteringUserDetails(registeringUserFromResponse))
            dispatch(setIsRegistrationCodeVerified(true))
          } else if (res.data.status === 'Active') {
            dispatch(
              showSuccess(
                'User has already been activated, please login to continue.'
              )
            )
            dispatch(setRegistrationSuccess(true))
          } else if (res.data.status === 'Inactive') {
            dispatch(
              showError(
                'Account is not active, please contact your administrator.'
              )
            )
          }
        } else {
          dispatch(
            showError(
              `The verification code you entered is either expired or not valid, please make sure you are entering the correct verification code or request a new one from your administrator.`
            )
          )
        }
        dispatch(setLoading(false))
      })
      .catch(e => {
        dispatch(
          showError(
            'There was an error while verifying the code, please try again.'
          )
        )
        dispatch(setLoading(false))
      })
  }
}

export const verifyLeRegistrationCode = value => {
  return async dispatch => {
    dispatch(setLoading(true))

    // Retrieve user data from DB matching the verification code
    await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/le-verification`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: value.verificationCode }),
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          code: value.verificationCode,
        }),
      }
    )
      .then(response => response.json())
      .then(res => {
        if (res.data) {
          if (res.data.status === 'Pending') {
            dispatch(
              showSuccess(
                'Unique Identifier verified successfully, please continue the registration process'
              )
            )
            // Set user details
            const registeringLeFromResponse = {
              verificationCode: value.verificationCode,
              licensee: res.data.name,
              firstName: '',
              lastName: '',
              email: res.data.email,
              username: '',
              password: '',
              confirmPassword: '',
              phone: '',
              premisesAddress: {
                address: res.data.premisesAddress.address,
                address2: res.data.premisesAddress.address2,
                city: res.data.premisesAddress.city,
                state: res.data.premisesAddress.state,
                zipcode: res.data.premisesAddress.zipcode,
                country: res.data.premisesAddress.country,
              },
              mailingAddress: {
                address: res.data.mailingAddress.address,
                address2: res.data.mailingAddress.address2,
                city: res.data.mailingAddress.city,
                state: res.data.mailingAddress.state,
                zipcode: res.data.mailingAddress.zipcode,
                country: res.data.mailingAddress.country,
              },
            }
            dispatch(setIsRegisteringLeDetails(registeringLeFromResponse))
            dispatch(setIsRegistrationCodeVerified(true))
          } else if (res.data.status === 'Active') {
            dispatch(
              showSuccess(
                'Licensee has already been activated, please login to continue.'
              )
            )
            dispatch(setRegistrationSuccess(true))
          }
        } else {
          dispatch(showError(`The unique identifier is invalid.`))
        }
        dispatch(setLoading(false))
      })
      .catch(e => {
        dispatch(
          showError(
            'There was an error while verifying the identifier, please try again.'
          )
        )
        dispatch(setLoading(false))
      })
  }
}

export const registerAsUser = credentials => {
  return async dispatch => {
    dispatch(setLoading(true))

    // Retrieve user data from DB matching the verification code
    await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_URL}/signup-user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer ' + getToken({ code: credentials.verificationCode }),
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        code: credentials.verificationCode,
        username: credentials.username.toLowerCase(),
        password: credentials.password,
        email: credentials.email,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        phone: credentials.phone.trim(),
      }),
    })
      .then(response => response.json())
      .then(res => {
        if (res.error) {
          switch (res.error) {
            case 'UsernameExistsException':
              dispatch(
                showError(
                  `The username you selected already exists, please try again with a different one.`
                )
              )
              break
            case 'InvalidPasswordException':
              dispatch(
                showError(
                  `The password selected doesn't comply with ABC's password policy, please try again.`
                )
              )
              break
            default:
              dispatch(
                showError('An error occurred while registering the user.')
              )
          }
        } else {
          dispatch(
            showSuccess(
              'User registered successfully, please login to continue.'
            )
          )
          dispatch(setRegistrationSuccess(true))
          dispatch(setTempUsername(credentials.username.toLowerCase()))
        }
        dispatch(setLoading(false))
      })
      .catch(e => {
        dispatch(showError('An error occurred while registering the user.'))
        dispatch(setLoading(false))
      })
  }
}

export const registerAsLE = credentials => {
  return async dispatch => {
    dispatch(setLoading(true))
    dispatch(setIsRegisteringLeDetails(credentials))

    // Sign up licensee account and create LE Admin User
    await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_URL}/signup-le`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer ' + getToken({ code: credentials.verificationCode }),
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        code: credentials.verificationCode,
        username: credentials.username.toLowerCase(),
        password: credentials.password,
        email: credentials.email,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        phone: credentials.phone.trim(),
        address: credentials.mailingAddress.address,
        address2: credentials.mailingAddress.address2,
        city: credentials.mailingAddress.city,
        state: credentials.mailingAddress.state,
        zipcode: credentials.mailingAddress.zipcode,
        country: credentials.mailingAddress.country,
      }),
    })
      .then(response => response.json())
      .then(res => {
        if (res.error) {
          switch (res.error) {
            case 'UsernameExistsException':
              dispatch(
                showError(
                  `The username you selected already exists, please try again with a different one.`
                )
              )
              break
            case 'InvalidPasswordException':
              dispatch(
                showError(
                  `The password selected doesn't comply with ABC's password policy, please try again.`
                )
              )
              break
            default:
              dispatch(
                showError('An error occurred while registering the account.')
              )
          }
        } else {
          dispatch(
            showSuccess(
              'Licensee registered successfully, please login to continue.'
            )
          )
          dispatch(setRegistrationSuccess(true))
          dispatch(setTempUsername(credentials.username.toLowerCase()))
        }
        dispatch(setLoading(false))
      })
      .catch(e => {
        dispatch(showError('An error occurred while registering the account.'))
        dispatch(setLoading(false))
      })
  }
}

export const verifyCode = code => {
  return async (dispatch, getState) => {
    const tempUsername = getState().auth.tempUsername
    if (tempUsername.length > 0) {
      await Auth.confirmSignUp(tempUsername, code)
        .then(() => {
          dispatch(showSuccess('Code verified, please login to continue.'))
          dispatch(setCodeVerified(true))
        })
        .catch(err => dispatch(showError(err.message)))
    } else {
      dispatch(
        showError('Please sign up to be able to get a verification code.')
      )
    }
  }
}

export const forgotUsername = email => {
  return async dispatch => {
    // Send username recovery email with verification code
    await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/username-verification`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ email: email }),
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    )
      .then(response => response.json())
      .then(res => {
        dispatch(setUsernameRecoverySent(true))
        dispatch(
          showSuccess(
            'Your request has been submitted. If the email address is correct and your account is active you should receive an email within 5 minutes. If no email is received, please contact your administrator.'
          )
        )
      })
      .catch(e =>
        dispatch(
          showError(
            'There was an error while sending the request, please try again.'
          )
        )
      )
  }
}

export const recoverUsername = verificationCode => {
  return async dispatch => {
    // Retrieve usernames & LEs based on your verification code
    await fetch(
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/recover-username`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: verificationCode }),
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          code: verificationCode,
        }),
      }
    )
      .then(response => response.json())
      .then(res => {
        if (res.entries.length > 0) {
          dispatch(
            showSuccess(
              'Code verified successfully, please continue the recover username process.'
            )
          )
          dispatch(setEmailLinkedLicensees(res.entries))
          dispatch(setIsRecoveryUsernameCodeVerified(true))
        } else {
          dispatch(
            showError(
              'There are no accounts linked to this email. Please contact the administrator.'
            )
          )
        }
      })
      .catch(e =>
        dispatch(
          showError(
            'The verification code you entered is either expired or not valid. Please make sure you are entering the correct verification code or begin the Recover Username request again.'
          )
        )
      )
  }
}

export const forgotPassword = username => {
  username = username.toLowerCase()
  return async (dispatch, getState, services) => {
    await services.AmplifyAuth.forgotPassword(username)
      .then(() => {
        dispatch(setTempUsername(username))
        dispatch(
          showSuccess(
            'A verification code has been sent to the email linked to your username.'
          )
        )
      })
      .catch(err => {
        dispatch(showError(err.message))
      })
  }
}

export const forgotPasswordSubmit = (code, username, newPassword) => {
  username = username.toLowerCase()
  return async dispatch => {
    // After collecting confirmation code and new password
    await Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(async () => {
        // Reset failedLoginAttempts attribute in Cognito user
        await fetch(
          `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/update-user-attributes`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + getToken({ code: username }),
              'Content-Type': 'text/plain',
            },
            body: JSON.stringify({
              username: username.toLowerCase(),
              type: 'RESET_LOGIN_ATTEMPTS',
            }),
          }
        )

        dispatch(
          showSuccess('Password reset successfully, please login to continue.')
        )
        dispatch(setCodeVerified(true))
      })
      .catch(err => {
        if (err.code === 'ExpiredCodeException')
          dispatch(
            showError(
              'The verification code you entered is either expired or not valid.  Please make sure you are entering the correct verification code or begin the Forgot Password request again.'
            )
          )
        else dispatch(showError(err.message))
      })
  }
}

export const checkForAuthenticatedUser = (pathname = '/') => {
  return async dispatch => {
    dispatch(setLoading(true))
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        // Get DB user based on CognitoId
        getUser(user.attributes.sub).then(dbUser => {
          dbUser.cognitoId = user.attributes.sub
          // Pathname set to remember page pathname on refresh
          dispatch(setPathname(pathname))
          dispatch(
            setAuthenticated({
              isAuth: true,
              user: dbUser,
            })
          )
          dispatch(setLoading(false))
        })
      })
      .catch(_ => {
        dispatch(setLoading(false))
      })
  }
}

export const signOut = () => {
  return async dispatch => {
    await Auth.signOut()
      .then(() => {
        dispatch(setPathname('/'))
        dispatch(setAuthenticated({ isAuth: false, user: {} }))

        // Reset Authentication properties
        dispatch(resetAuth())

        // Reset legal Entity
        dispatch(resetLegalEntities())

        // Reset ABC Users
        dispatch(resetABCUsers())

        // Reset products
        dispatch(resetProductsList())

        // Reset Licensee Users
        dispatch(resetLicenseeUser())

        // Reset bulk duck
        dispatch(resetBulkUpload())

        // Reset competitive price postings
        dispatch(resetMyCompetingPricePosts())
        dispatch(resetCompetingWithPPList())

        // Reset price posting duck
        dispatch(resetPricePostingsList())

        // Reset Global duck
        dispatch(resetGlobalDuck())

        // Reset product size(global settings)
        dispatch(resetProductSize())
        // Reset price To(global settings)
        dispatch(resetPricesTo())
      })
      .catch(err => dispatch(showError(err.message)))
  }
}

// INITIAL STATE
const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isCodeVerified: false,
  isUsernameRecoverySent: false,
  isRecoveryUsernameCodeVerified: false,
  setNewPassword: false,
  emailLinkedLicensees: [],
  user: {},
  tempUsername: '',
  failedLoginAttempts: 0,
  pathname: '/',
  isRegistrationCodeVerified: false,
  isRegistrationSuccess: false,
  registeringUser: {
    verificationCode: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    phone: '',
  },
  registeringLE: {},
}

// REDUCER
export default createReducer(initialState, {
  [setAuthenticated]: (state, action) => {
    state.isAuthenticated = action.payload.isAuth
    state.user = action.payload.user
    state.isAuthenticating = false
  },
  [setAuthenticating]: (state, action) => {
    state.isAuthenticating = action.payload
  },
  [setTempUsername]: (state, action) => {
    state.tempUsername = action.payload
  },
  [setPathname]: (state, action) => {
    state.pathname = action.payload
  },
  [setCodeVerified]: (state, action) => {
    state.isCodeVerified = action.payload
  },
  [setNewPassword]: (state, action) => {
    state.setNewPassword = action.payload
  },
  [setFailedLoginAttempts]: (state, action) => {
    state.failedLoginAttempts = action.payload
  },
  [setUsernameRecoverySent]: (state, action) => {
    state.isUsernameRecoverySent = action.payload
  },
  [resetAuth]: state => {
    state.isCodeVerified = false
    state.isUsernameRecoverySent = false
    state.setNewPassword = false
    state.tempUsername = ''
    state.isRegistrationCodeVerified = false
    state.isRegistrationSuccess = false
    state.failedLoginAttempts = 0
  },
  [setIsRegistrationCodeVerified]: (state, action) => {
    state.isRegistrationCodeVerified = action.payload
  },
  [setIsRegisteringUserDetails]: (state, action) => {
    state.registeringUser = action.payload
  },
  [setIsRegisteringLeDetails]: (state, action) => {
    state.registeringLE = action.payload
  },
  [setRegistrationSuccess]: (state, action) => {
    state.isRegistrationSuccess = action.payload
  },
  [setIsRecoveryUsernameCodeVerified]: (state, action) => {
    state.isRecoveryUsernameCodeVerified = action.payload
  },
  [setEmailLinkedLicensees]: (state, action) => {
    state.emailLinkedLicensees = action.payload
  },
})
