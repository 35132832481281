import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field, withFormik } from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { Grid, MenuItem } from '@material-ui/core'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import AutoCompleteComp from 'components/common/AutoCompleteComp'
import NumericInput from 'components/common/NumericInput'
import { AbilityContext } from 'common/abilityContext'
import { getRoles, getLEsForDropDown, setEditMode } from 'ducks/global'
import {
  addNewLeUser,
  updateLeUser,
  setLeUserShown,
  resetLeUserDetails,
} from 'ducks/legalEntityUser'

const validations = props => {
  return Yup.object().shape({
    leUser: Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string()
        .email('Please enter a valid email')
        .required('Required'),
      role: Yup.string().required('Required'),
      legalEntity: !props.canSelectLeAdmin
        ? Yup.string().required('Required')
        : '',
    }),
  })
}

const EditLeUser = () => {
  const dispatch = useDispatch()
  const abilityFunc = useContext(AbilityContext)
  const { user } = useSelector(store => store.auth)

  useEffect(() => {
    dispatch(getRoles())
    dispatch(getLEsForDropDown())
  }, [dispatch])

  const onAddLeUser = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      if (formikValues.values.leUser.id) {
        // Check if email has changed (used to update Cognito user's email)
        const newEmail = formikValues.values.leUser.email
        const hasEmailChanged =
          newEmail.length > 0 &&
          formikValues.initialValues.leUser.email !== newEmail
        dispatch(updateLeUser(formikValues.values.leUser, hasEmailChanged))
      } else {
        dispatch(addNewLeUser(formikValues.values.leUser))
      }
    }
  }

  const { isLoading, roles, lEsForDropDown } = useSelector(
    store => store.global
  )
  const updatedRolesList = roles
    ? roles.filter(role => abilityFunc.can('createLE', role.name) === true)
    : []
  return (
    <Form>
      <Field
        name='leUser'
        render={formikProps => {
          formikProps.form.values.leUser = JSON.parse(
            JSON.stringify(formikProps.form.values.leUser)
          )
          const formikValues = formikProps.form.values
          const handleLeChange = event => {
            formikProps.form.setFieldValue(`leUser.legalEntity`, event)
          }

          const handleCancel = () => {
            if (!formikValues.leUser.id) {
              dispatch(setLeUserShown(false))
              dispatch(resetLeUserDetails())
            }
            dispatch(setEditMode(false))
          }

          const leObject =
            formikValues.leUser.legalEntity &&
            lEsForDropDown.find(
              le => le.value === formikValues.leUser.legalEntity.id
            )
          return (
            <>
              {isLoading || updatedRolesList.length === 0 ? (
                <LoadingIndicator
                  text={`Loading ${isLoading ? 'User' : 'Roles'}...`}
                  transparent
                />
              ) : (
                <>
                  <CustomCard title='Account Information'>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        autoFocus
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='First Name'
                        name='leUser.firstName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Last Name'
                        name='leUser.lastName'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Email'
                        name='leUser.email'
                        autoComplete='email'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='leUser.phoneNumber'
                        label='Phone'
                        id='phone'
                        component={NumericInput}
                        autoComplete='phone'
                        format={'### ### #### ### ##'}
                      />
                    </Grid>
                    {formikProps.form.values.leUser.id !== user.id ? (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field
                          name={'leUser.role'}
                          label='Role'
                          select
                          fullWidth
                          margin='normal'
                          variant='outlined'
                          component={TextField}
                        >
                          Role *
                          {updatedRolesList.map(val => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {!formikValues.canSelectLeAdmin && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field
                          placeHolderText='Licensee *'
                          selectedValue={leObject}
                          name='leUser.legalEntity'
                          formikProps={formikProps.form}
                          dropDownOptions={lEsForDropDown}
                          onValueChange={handleLeChange}
                          component={AutoCompleteComp}
                        />
                      </Grid>
                    )}
                  </CustomCard>
                  <div>* indicates a required field</div>
                  <SaveAndCancelButton
                    onSave={() => onAddLeUser(formikProps.form)}
                    onCancel={() => handleCancel()}
                    submitLabel={formikValues.leUser.id && 'update'}
                    isDisabled={
                      formikValues.leUser.id && !formikProps.form.isValid
                    }
                  />
                </>
              )}
            </>
          )
        }}
      />
    </Form>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: props => validations(props),
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(EditLeUser)

export default WithFormikWrapper
