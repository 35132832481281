import { createAction, createReducer } from '@reduxjs/toolkit'

export const setPublicSidebarOpen = createAction(
  'publicLayout/setPublicSidebarOpen'
)

const initialState = {
  isPublicSidebarOpen: false,
}

export default createReducer(initialState, {
  [setPublicSidebarOpen]: (state, action) => {
    state.isPublicSidebarOpen = !state.isPublicSidebarOpen
  },
})
