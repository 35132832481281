import gql from 'graphql-tag'

/* ------------------------------------------------------------------------ */
/* ------------------------------ U S E R S ------------------------------- */
/* ------------------------------------------------------------------------ */

export const getUserQuery = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      username
      status
      role {
        id
        name
      }
      legalEntity {
        id
        name
        licenseCategory
        licenseTypes {
          id
        }
      }
    }
  }
`

export const getUsersQuery = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $limit: Int
    $offset: Int
    $includeLE: Boolean = false
  ) {
    users(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        firstName
        lastName
        email
        role {
          name
        }
        status
        legalEntity @include(if: $includeLE) {
          name
          status
        }
        createdAt
        updatedAt
      }
      count
      totalCount
    }
  }
`

export const getUserByIdQuery = gql`
  query UserByID($where: UserWhereInput, $includeLicensee: Boolean = false) {
    users(where: $where) {
      results {
        id
        firstName
        lastName
        email
        username
        phoneNumber
        createdAt
        updatedAt
        status
        role {
          id
          name
        }
        legalEntity @include(if: $includeLicensee) {
          id
          name
          status
        }
      }
      count
    }
  }
`

export const findUserDuplicatesQuery = gql`
  query UserDuplicates($where: UserWhereInput) {
    users(where: $where) {
      results {
        id
      }
      count
    }
  }
`

export const getOwnProfileByIDQuery = gql`
  query UserProfile($id: Int!, $getLeName: Boolean!) {
    users(where: { id: $id }) {
      results {
        id
        firstName
        lastName
        phoneNumber
        email
        role {
          name
        }
        legalEntity @include(if: $getLeName) {
          name
          licenseTypes {
            id
            label: name
            number
          }
        }
        status
        createdAt
        updatedAt
        username
      }
      count
    }
  }
`

export const getLeAdminOwnProfileByIDQuery = gql`
  query LeAdminProfile($id: Int!) {
    users(where: { id: $id }) {
      results {
        id
        firstName
        lastName
        phoneNumber
        email
        role {
          name
        }
        status
        createdAt
        updatedAt
        legalEntity {
          id
          status
          name
          email
          licenseTypes {
            id
            label: name
            number
          }
          premisesAddress {
            id
            address
            address2
            city
            state
            zipcode
            country
          }
          mailingAddress {
            id
            address
            address2
            city
            state
            zipcode
            country
          }
        }
        username
      }
      count
    }
  }
`

export const getUsersForSelectAll = gql`
  query Users($where: UserWhereInput, $orderBy: UserOrderByInput) {
    users(where: $where, orderBy: $orderBy) {
      results {
        id
      }
    }
  }
`

export const getUsersForExport = gql`
  query Users($where: UserWhereInput, $orderBy: UserOrderByInput, $includeLE: Boolean = false) {
    users(where: $where, orderBy: $orderBy) {
      results {
        legalEntity @include(if: $includeLE) {
          name
        }
        firstName
        lastName
        email
        role {
          name
        }
        status
      }
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ----------------------- L I C E N S E   T Y P E S ----------------------- */
/* ------------------------------------------------------------------------- */

export const getLicenseTypesQuery = gql`
  query Licenses {
    licenseTypes(orderBy: id_ASC) {
      value: id
      number
      label: name
      category
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ----------------------------- R O L E S --------------------------------- */
/* ------------------------------------------------------------------------- */

export const getRolesQuery = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`
export const getRolesQueryForDropDown = gql`
  query Roles {
    roles {
      value: id
      label: name
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ---------------------- L E G A L   E N T I T I E S ---------------------- */
/* ------------------------------------------------------------------------- */

//  Add licensed, to get licensed boolean from API
export const getLegalEntitiesQuery = gql`
  query LegalEntities(
    $where: LegalEntityWhereInput
    $orderBy: LegalEntityOrderByInput
    $limit: Int
    $offset: Int
    $operator: String
  ) {
    legalEntities(
      where: $where
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      operator: $operator
    ) {
      results {
        id
        name
        licensed
        licenseTypes {
          name
          number
        }
        licenseCategory
        status
        createdAt
        updatedAt
      }
      count
      totalCount
    }
  }
`

export const fetchLicenseesIdQuery = gql`
  query LegalEntities($where: LegalEntityWhereInput) {
    legalEntities(where: $where) {
      results {
        id
      }
    }
  }
`

export const getLicenseesCountQuery = gql`
  query LicenseesCount {
    legalEntities {
      count
    }
  }
`

export const getLicenseesCountForPublicQuery = gql`
  query LicenseesCount {
    legalEntities(where: { status: "Active" }) {
      count
    }
  }
`

export const getLEsForDropdownQuery = gql`
  query LegalEntities($limit: Int, $offset: Int) {
    legalEntities(orderBy: name_ASC, limit: $limit, offset: $offset) {
      results {
        value: id
        label: name
        licenseCategory
        licensed
        status
      }
      count
    }
  }
`

export const getLEsForPublicDropdownQuery = gql`
  query LegalEntities($limit: Int, $offset: Int) {
    legalEntities(where: { status: "Active" }, orderBy: name_ASC, limit: $limit, offset: $offset) {
      results {
        value: id
        label: name
        licenseCategory
        licensed
      }
      count
    }
  }
`

//  Add licensed, to get licensed boolean from API
export const getLegalEntityByIdQuery = gql`
  query LegalEntities($where: LegalEntityWhereInput) {
    legalEntities(where: $where) {
      results {
        id
        uniqueId
        name
        email
        status
        previous_status
        licensed
        licenseCategory
        licenseTypes {
          value: id
          label: name
          number
          category
        }
        premisesAddress {
          id
          address
          address2
          city
          state
          zipcode
          country
        }
        mailingAddress {
          id
          address
          address2
          city
          state
          zipcode
          country
        }
        inLitigation
        createdAt
        updatedAt
      }
      count
    }
  }
`

export const findLegalEntityQuery = gql`
  query LegalEntities($where: LegalEntityWhereInput) {
    legalEntities(where: $where) {
      results {
        id
      }
      count
    }
  }
`

export const getLegalEntitiesForSelectAll = gql`
  query LegalEntities(
    $where: LegalEntityWhereInput
    $orderBy: LegalEntityOrderByInput
    $limit: Int
    $offset: Int
  ) {
    legalEntities(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        licensed
      }
    }
  }
`

export const getLegalEntitiesForBulkPrinting = gql`
  query LegalEntities($where: LegalEntityWhereInput, $orderBy: LegalEntityOrderByInput) {
    legalEntities(where: $where, orderBy: $orderBy) {
      results {
        uniqueId
        name
        email
        status
        licensed
        licenseCategory
        licenseTypes {
          value: id
          label: name
          number
          category
        }
        premisesAddress {
          id
          address
          address2
          city
          state
          zipcode
          country
        }
        mailingAddress {
          id
          address
          address2
          city
          state
          zipcode
          country
        }
      }
      count
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ------------------------- P R O D U C T S ------------------------------- */
/* ------------------------------------------------------------------------- */

export const getProductsQuery = gql`
  query Products(
    $where: ProductWhereInput
    $orderBy: ProductOrderByInput
    $limit: Int
    $offset: Int
  ) {
    products(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        manufacturer {
          id
          name
        }
        name
        tradeName
        createdAt
        updatedAt
        status
      }
      count
      totalCount
    }
  }
`

export const getProductsForBulkSelection = gql`
  query Products(
    $where: ProductWhereInput
    $orderBy: ProductOrderByInput
    $limit: Int
    $offset: Int
  ) {
    products(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
      }
    }
  }
`

export const getProductsForPricePostingAndExport = gql`
  query Products($where: ProductWhereInput, $orderBy: ProductOrderByInput) {
    products(where: $where, orderBy: $orderBy) {
      results {
        id
        manufacturer {
          name
        }
        name
        tradeName
        createdAt
        updatedAt
      }
    }
  }
`

export const getProductByIDQuery = gql`
  query ProductByID($id: Int!) {
    products(where: { id: $id }) {
      results {
        id
        manufacturer {
          id
          name
        }
        name
        tradeName
        oldTradeName
        oldName
        createdAt
        updatedAt
        status
        source
      }
      count
    }
  }
`

export const getPublicProductByIDQuery = gql`
  query ProductByID($id: Int!) {
    products(where: { id: $id }) {
      results {
        id
        manufacturer {
          id
          name
        }
        name
        tradeName
        createdAt
        updatedAt
      }
      count
    }
  }
`

export const getProductsWithTradeQuery = gql`
  query Products($where: ProductWhereInput, $userIsManufacturer: Boolean!) {
    products(where: $where, orderBy: createdAt_DESC) {
      results {
        id
        manufacturer @skip(if: $userIsManufacturer) {
          name
        }
        name
        tradeName
      }
      count
    }
  }
`

export const findProductDuplicatesQuery = gql`
  query ProductDuplicates($where: ProductWhereInput) {
    products(where: $where) {
      results {
        id
      }
      count
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ------------------------- P R I C E  P O S T I N G----------------------- */
/* ------------------------------------------------------------------------- */

export const getPricePostingsQuery = gql`
  query PricePostings(
    $where: PricePostingWhereInput
    $orderBy: PricePostingOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostings(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        manufacturer {
          name
        }
        product {
          name
          tradeName
        }
        status
        package {
          package
        }
        productSize {
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        county
        pricesTo {
          name
        }
        receivingMethod
        price
        pricePromotion
        containerCharge
        effectiveDate
        createdAt
        createdBy {
          id
        }
        createdByLicensee {
          id
          name
        }
      }
      count
    }
  }
`

export const getPublicPricePostsQuery = gql`
  query PricePostings(
    $where: PricePostingWhereInput
    $orderBy: PricePostingOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostings(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        manufacturer {
          name
        }
        product {
          name
          tradeName
        }
        status
        package {
          package
        }
        productSize {
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        county
        pricesTo {
          name
        }
        receivingMethod
        price
        pricePromotion
        containerCharge
        effectiveDate
        createdAt
        createdByLicensee {
          id
          name
        }
      }
      count
    }
  }
`
export const getPublicPricePostByIDQuery = gql`
  query PricePostByID($id: Int!) {
    pricePostings(where: { id: $id }) {
      results {
        id
        manufacturer {
          value: id
          label: name
        }
        createdByLicensee {
          id
          name
        }
        parentPricePostId
        product {
          id
          name
          tradeName
        }
        package {
          value: id
          label: package
        }
        productSize {
          id
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        tradingArea
        county
        pricesTo {
          value: id
          label: name
        }
        price
        pricePromotion
        receivingMethod
        containerCharge
        effectiveDate
        status
        competitivePostId
        createdAt
        updatedAt
      }
      count
    }
  }
`

export const getPricePostingsTotalCount = gql`
  query PricePostingsCount($where: PricePostingWhereInput) {
    pricePostingsCount(where: $where) {
      count
    }
  }
`

export const getPricePostingsAsLicenseeTotalCount = gql`
  query PricePostingsAsLicenseeCount(
    $manufacturerId: Int!
    $userId: Int!
    $where: PricePostingWhereInput
    $filtered: Boolean = false
  ) {
    pricePostingsAsLicenseeCount(
      manufacturerId: $manufacturerId
      userId: $userId
      where: $where
      filtered: $filtered
    ) {
      count
    }
  }
`

export const getPricePostingsQueryAsLicensee = gql`
  query PricePostings(
    $manufacturerId: Int!
    $userId: Int!
    $where: PricePostingWhereInput
    $orderBy: PricePostingOrderByInput
    $limit: Int
    $offset: Int
    $filtered: Boolean = false
  ) {
    pricePostingsAsLicensee(
      manufacturerId: $manufacturerId
      userId: $userId
      where: $where
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      filtered: $filtered
    ) {
      results {
        id
        manufacturer {
          id
          name
        }
        product {
          id
          name
          tradeName
        }
        status
        package {
          id
          package
        }
        productSize {
          id
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        county
        pricesTo {
          id
          name
        }
        receivingMethod
        price
        pricePromotion
        containerCharge
        effectiveDate
        createdAt
        createdBy {
          id
        }
        updatedAt
        parentPricePostId
        competitivePostId
        createdByLicensee {
          id
          name
        }
      }
      count
    }
  }
`

export const getPPForBulkSelectionAsLicensee = gql`
  query PricePostings(
    $manufacturerId: Int!
    $userId: Int!
    $where: PricePostingWhereInput
    $orderBy: PricePostingOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostingsAsLicensee(
      manufacturerId: $manufacturerId
      userId: $userId
      where: $where
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      results {
        id
        status
        createdByLicensee {
          id
        }
        price
        containerCharge
        county
        manufacturer {
          id
          name
        }
        package {
          id
          package
        }
        receivingMethod
        productSize {
          id
        }
        pricesTo {
          id
          name
        }
        product {
          id
          name
        }
        createdAt
        updatedAt
        parentPricePostId
        competitivePostId
        status
        price
        pricePromotion
        containerCharge
        county
        createdByLicensee {
          id
          name
        }
      }
      count
    }
  }
`

export const getPPForBulkSelection = gql`
  query PricePostings(
    $where: PricePostingWhereInput
    $orderBy: PricePostingOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostings(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        status
        createdByLicensee {
          id
        }
        receivingMethod
        price
        containerCharge
      }
      count
    }
  }
`

export const getPricePostingByIDQuery = gql`
  query PricePostByID($id: Int!) {
    pricePostings(where: { id: $id }) {
      results {
        id
        manufacturer {
          value: id
          label: name
        }
        createdByLicensee {
          id
          name
        }
        parentPricePostId
        product {
          id
          name
          tradeName
        }
        package {
          value: id
          label: package
        }
        productSize {
          id
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        tradingArea
        county
        pricesTo {
          value: id
          label: name
        }
        price
        pricePromotion
        receivingMethod
        containerCharge
        effectiveDate
        status
        competitivePostId
        promotional
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        source
      }
      count
    }
  }
`

export const findGlobalSettingsInPricePostsQuery = setting => gql` 
  query GlobalSettingsInPricePosts {
    pricePostings(where: {${setting.package ? `packageId: ${setting.package}` : ''}
    ${setting.size ? `sizeId: ${setting.size}` : ''}
    ${setting.pricesTo ? `pricesTo: ${setting.pricesTo}` : ''}}){
      results {
        id
      }
      count
    }
  }
`

export const getPricePostingsQueryForExport = gql`
  query PricePostings($where: PricePostingWhereInput, $orderBy: PricePostingOrderByInput) {
    pricePostings(where: $where, orderBy: $orderBy) {
      results {
        manufacturer {
          name
        }
        product {
          name
          tradeName
        }
        status
        createdByLicensee {
          id
          name
        }
        package {
          package
        }
        productSize {
          size
          unit {
            unit
          }
          containerType {
            type
          }
        }
        county
        pricesTo {
          name
        }
        price
        pricePromotion
        effectiveDate
        receivingMethod
        containerCharge
        tradingArea
        createdAt
      }
    }
  }
`

/* -------------------------------------------------------------------------------------------- */
/* ------------------------- P R I C E   P O S T I N G   S C H E D U L E----------------------- */
/* -------------------------------------------------------------------------------------------- */

export const getPricePostingsScheduleSingleQuery = gql`
  query PricePostingsScheduleSingle($where: PricePostingsScheduleUniqueWhereInput!) {
    pricePostingsScheduleSingle(where: $where) {
      id
      manufacturer {
        id
        name
      }
      uploadName
      uploadKey
      scheduledDate
      numRecords
      numProcessed
      numErrors
      status
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const getPricePostingsScheduleQuery = gql`
  query PricePostingsSchedule(
    $where: PricePostingsScheduleWhereInput
    $orderBy: PricePostingsScheduleOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostingsSchedule(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        manufacturer {
          id
          name
        }
        uploadName
        uploadKey
        status
        scheduledDate
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        numErrors
      }
      count
    }
  }
`

export const getPricePostingsScheduleCount = gql`
  query pricePostingsScheduleCount($where: PricePostingsScheduleWhereInput) {
    pricePostingsScheduleCount(where: $where) {
      count
    }
  }
`

export const getPricePostingsScheduleQueryAsLicensee = gql`
  query PricePostingsScheduleAsLicensee(
    $manufacturerId: Int!
    $where: PricePostingsScheduleWhereInput
    $orderBy: PricePostingsScheduleOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricePostingsScheduleAsLicensee(
      manufacturerId: $manufacturerId
      where: $where
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      results {
        id
        manufacturer {
          id
          name
        }
        uploadName
        uploadKey
        status
        scheduledDate
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        numErrors
      }
      count
    }
  }
`

export const getPricePostingsScheduleAsLicenseeCount = gql`
  query PricePostingsScheduleAsLicenseeCount(
    $manufacturerId: Int!
    $where: PricePostingsScheduleWhereInput
    $filtered: Boolean = false
  ) {
    pricePostingsScheduleAsLicenseeCount(
      manufacturerId: $manufacturerId
      where: $where
      filtered: $filtered
    ) {
      count
    }
  }
`

/* -------------------------------------------------------------------------------------------- */
/* ------------------------- P R I C E   P O S T I N G S  P R O M O   A U D I T ----------------------- */
/* -------------------------------------------------------------------------------------------- */

export const getPricePostingsPromoAuditByPricePosting = gql`
  query getPricePostingsPromoAuditByPricePosting(
      $where: PricePostingsPromoAuditWhereInput, 
      $orderBy: PricePostingsPromoAuditOrderByInput, 
      $limit: Int, 
      $offset: Int
  ) {
    pricePostingsPromoAuditByPricePosting(
        where: $where, 
        orderBy: $orderBy, 
        limit: $limit, 
        offset: $offset
    ) {
      results {
        id
        pricePosting {
          id
          price
          county
          tradingArea
          pricePromotion
          promotional
          receivingMethod
          containerCharge
          effectiveDate
          endDate
          status
          parentPricePostId
          competitivePostId
          createdAt
          updatedAt
          source
          createdBy {
            id
            firstName
            lastName
          }
          updatedBy {
            id
            firstName
            lastName
          }
          manufacturer {
            id
            name
          }
          product {
            id
            name
            tradeName
            status
          }
          package {
            id
            package
            status
          }
          productSize {
            id
            size
            containerType {
              type
            }
            unit {
              id
              unit
            }
            status
          }
          pricesTo {
            id
            name
            status
          }
        }
        pricePromotion
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
      }
      count
    }
  }
`

/* -------------------------------------------------------------------------------- */
/* ------------------------- G L O B A L  S E T T I N G S ------------------------- */
/* -------------------------------------------------------------------------------- */

export const getUnitsQuery = gql`
  query Units {
    units(where: { id_gt: 0 }) {
      id
      unit
      abbreviation
    }
  }
`

export const getContainerTypes = gql`
  query ContainerTypes {
    containerTypes(where: { id_gt: 0 }) {
      id
      type
    }
  }
`

// ------------------- P R O D U C T   S I Z E S -------------------

export const getProductSizesQuery = gql`
  query ProductSizes(
    $where: ProductSizeWhereInput
    $orderBy: ProductSizeOrderByInput
    $limit: Int
    $offset: Int
  ) {
    productSizes(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        size
        unit {
          id
          unit
          abbreviation
        }
        containerType {
          id
          type
        }
        status
      }
      count
      totalCount
    }
  }
`

export const getProductSizeByIDQuery = gql`
  query ProductSizeByID($id: Int!) {
    productSizes(where: { id: $id }) {
      results {
        id
        size
        unit {
          id
          unit
          abbreviation
        }
        containerType {
          id
          type
        }
        status
      }
      count
    }
  }
`

export const findProductSizeDuplicatesQuery = gql`
  query ProductSizes($where: ProductSizeWhereInput) {
    productSizes(where: $where) {
      results {
        id
      }
      count
    }
  }
`

export const getProductSizesForDropDown = gql`
  query ProductSize($where: ProductSizeWhereInput) {
    productSizes(where: $where, orderBy: unit_ASC) {
      results {
        id
        size
        status
        unit {
          unit
        }
        containerType {
          type
        }
      }
      count
    }
  }
`

// ------------------- P R I C E S   T O -------------------

export const getPricesToQuery = gql`
  query PricesTo(
    $where: PricesToWhereInput
    $orderBy: PricesToOrderByInput
    $limit: Int
    $offset: Int
  ) {
    pricesTo(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        name
        status
      }
      count
      totalCount
    }
  }
`

export const getPricesToForDropDownQuery = gql`
  query PricesTo {
    pricesTo(orderBy: name_ASC) {
      results {
        value: id
        label: name
        status: status
      }
      count
    }
  }
`

export const getPricesToByIDQuery = gql`
  query PricesToByID($id: Int!) {
    pricesTo(where: { id: $id }) {
      results {
        id
        name
        status
      }
      count
    }
  }
`

export const findPricesToDuplicatesQuery = gql`
  query PricesToDuplicates($where: PricesToWhereInput) {
    pricesTo(where: $where) {
      results {
        id
      }
      count
    }
  }
`

// ------------------- P A C K A G E  C O N F I G U R A T I O N -------------------

export const getPackagesQuery = gql`
  query Packages(
    $where: PackageWhereInput
    $orderBy: PackageOrderByInput
    $limit: Int
    $offset: Int
  ) {
    packages(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        package
        status
      }
      count
      totalCount
    }
  }
`

export const getPackagesForDropDown = gql`
  query Packages($where: PackageWhereInput) {
    packages(where: $where, orderBy: package_DESC) {
      results {
        value: id
        label: package
        status
      }
      count
    }
  }
`

export const getPackagesByIDQuery = gql`
  query PackagesByID($id: Int!) {
    packages(where: { id: $id }) {
      results {
        id
        package
        status
      }
      count
    }
  }
`

export const findPackagesDuplicatesQuery = gql`
  query PackagesDuplicates($where: PackageWhereInput) {
    packages(where: $where) {
      results {
        id
      }
      count
    }
  }
`

// ------------------- C O N F I G   R E Q U E S T   -------------------

export const getConfigRequestsQuery = gql`
  query ConfigRequests(
    $where: ConfigRequestWhereInput
    $orderBy: ConfigRequestOrderByInput
    $limit: Int
    $offset: Int
  ) {
    configRequests(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
      results {
        id
        type
        typeId
        request
        status
        createdAt
        reviewedAt
      }
      count
      totalCount
    }
  }
`

export const getConfigRequestByIDQuery = gql`
  query ConfigRequestByID($id: Int!) {
    configRequests(where: { id: $id }) {
      results {
        id
        type
        typeId
        request
        status
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdByLicensee {
          id
          name
        }
        reviewedAt
        reviewedBy {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`

// ------------------- C O N F I G   R E Q U E S T   -------------------

export const getNewsUpdateQuery = gql`
  query NewsUpdate {
    newsUpdate {
      id
      title
      description
    }
  }
`

export const getNewsUpdatesQuery = gql`
  query NewsUpdate {
    newsUpdates {
      results {
        id
        title
        description
        createdAt
      }
    }
  }
`
export const getProgramUpdatesQuery = gql`
  query ProgramUpdate {
    programUpdates {
      results {
        title
        link
        pubDate
        content
      }
    }
  }
`
