import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import {
  fetchPackageConfigurations,
  setPackageConfigurationShown,
  resetPackageConfigurationDetails,
  fetchPackageConfigurationByID,
  setPackageConfigurationPageNumber,
  setPackageConfigurationRequestedSort,
  updatePackageConfigurationOnConfirmation,
  setIsPackageConfigurationUsed,
  togglePackageStatus,
  setStatusChangeConfirmationForPackage,
  setIsPackageConfigurationFilterOpen,
} from 'ducks/packageConfigurations'
import { fetchPackageConfiguration as fetchPackageConfigurationForDropdown } from 'ducks/global'
import LoadingIndicator from 'components/common/LoadingIndicator'
import EditPackageConfig from './EditPackageConfig'
import PackageConfigurationFilters from './PackageConfigurationFilters'
import ConfirmationModal from 'components/common/ConfirmationModal'

import {
  IconButton,
  Popover,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core'
import { Block, VerifiedUserOutlined, MoreVert, Edit } from '@material-ui/icons'
import styled from 'styled-components'

const ActionItems = styled(MenuItem)`
  && {
    display: flex;
    justify-content: start;
  }
`
const ActionLabels = styled(Typography)`
  && {
    margin-left: 8px;
  }
`

const PackageConfigurations = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedPackage, setSelectedPackage] = useState(-1)
  const {
    packageConfigurationsList,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    selected,
    isPackageConfigurationShown,
    packageConfigurationDetails,
    packageConfigurationFilters,
    isPackageConfigurationUsed,
    statusChangeConfirmationForPackage,
    isPackageConfigurationFilterOpen,
  } = useSelector(store => store.packageConfigurations)
  const { loadingText } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(fetchPackageConfigurations())
    dispatch(fetchPackageConfigurationForDropdown())
  }, [dispatch, order, orderBy, rowsPerPage, pageNumber])

  const PackageConfigurationsHeaders = [
    { id: 'package', label: 'Configuration', isSortable: true },
    { id: 'status', label: 'Status', isSortable: true },
    { id: 'actions', label: 'Actions' },
  ]

  const onItemSelected = packageConfiguration => {
    setAnchorEl(null)
    dispatch(fetchPackageConfigurationByID(packageConfiguration.id))
  }

  const handlePageNumber = newPage => {
    dispatch(setPackageConfigurationPageNumber(newPage))
  }
  const handleRequestSort = value => {
    dispatch(setPackageConfigurationRequestedSort(value))
  }

  const onQuickViewClosed = () => {
    dispatch(resetPackageConfigurationDetails())
    dispatch(setPackageConfigurationShown(false))
  }
  const onAdd = () => {
    dispatch(resetPackageConfigurationDetails())
    dispatch(setPackageConfigurationShown(true))
  }

  const handleFilter = value => {
    dispatch(setIsPackageConfigurationFilterOpen(value))
  }

  const handleActionOpen = (event, packageConfig) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setSelectedPackage({
      ...packageConfig,
      id: parseInt(packageConfig.id),
    })
  }

  const handleActionClose = () => {
    setAnchorEl(null)
  }
  const handleStatusChange = () => {
    setAnchorEl(null)
    dispatch(setStatusChangeConfirmationForPackage(true))
  }

  const openAction = Boolean(anchorEl)

  const updatedPackageConfigurationsList = packageConfigurationsList.results
    ? packageConfigurationsList.results.map(packageConfig => ({
        id: packageConfig.id,
        name: packageConfig.package,
        status: packageConfig.status,
        actions: (
          <>
            <IconButton
              aria-label='actions'
              aria-haspopup='true'
              onClick={event => handleActionOpen(event, packageConfig)}
              color='primary'
            >
              <MoreVert color='primary' />
            </IconButton>
          </>
        ),
      }))
    : []

  return packageConfigurationsList.firstLoad ? (
    <LoadingIndicator loadingText={loadingText} />
  ) : (
    <>
      <Popover
        anchorReference={'anchorEl'}
        anchorEl={anchorEl}
        aria-haspopup='true'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openAction}
        onClose={handleActionClose}
      >
        <ActionItems onClick={() => onItemSelected(selectedPackage)}>
          <Edit color='primary' fontSize='small' />
          <ActionLabels children='Edit' variant='body1' />
        </ActionItems>
        <Divider />
        {selectedPackage.status === 'Active' ? (
          <ActionItems onClick={handleStatusChange}>
            <Block color='error' fontSize='small' />
            <ActionLabels children='Deactivate' variant='body1' />
          </ActionItems>
        ) : (
          <ActionItems onClick={handleStatusChange}>
            <VerifiedUserOutlined color='primary' fontSize='small' />
            <ActionLabels children='Activate' variant='body1' />
          </ActionItems>
        )}
      </Popover>
      <ListComponent
        mainTitle='Package Configurations'
        listContent={
          <ReusableTable
            headers={PackageConfigurationsHeaders}
            tableData={updatedPackageConfigurationsList}
            pagination={true}
            selectionOption={false}
            totalCount={packageConfigurationsList.totalCount}
            onSingleItemSelected={onItemSelected}
            setPageNumber={handlePageNumber}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={selected}
          />
        }
        editComp={
          <EditPackageConfig
            packageConfiguration={packageConfigurationDetails}
          />
        }
        expandedOptions={
          <PackageConfigurationFilters
            packageConfigurationFilters={packageConfigurationFilters}
          />
        }
        handleExpandingFilter={handleFilter}
        filterExpanded={isPackageConfigurationFilterOpen}
        modalEditTitle={
          packageConfigurationDetails.id
            ? 'Edit Package Configuration'
            : 'Add Package Configuration'
        }
        modalViewTitle='Package Configuration'
        onModalClosed={onQuickViewClosed}
        loadingText={loadingText}
        isDialogOpen={isPackageConfigurationShown}
        onAddButtonClicked={onAdd}
      />
      <ConfirmationModal
        isConfirmationModalOpen={isPackageConfigurationUsed}
        confirmationList={[
          'This configuration is currently being used by one or more price posts, if you confirm the change, the current configuration will become inactive and the change will be added as a new item.',
        ]}
        onConfirmation={() =>
          dispatch(updatePackageConfigurationOnConfirmation())
        }
        onConfirmationModalClosed={() =>
          dispatch(setIsPackageConfigurationUsed(false))
        }
      />
      <ConfirmationModal
        isConfirmationModalOpen={statusChangeConfirmationForPackage}
        confirmationList={[
          `Please confirm if you want to ${
            selectedPackage.status === 'Active' ? 'deactivate' : 'reactivate'
          } "Prices To" item.`,
        ]}
        onConfirmation={() => dispatch(togglePackageStatus(selectedPackage))}
        onConfirmationModalClosed={() =>
          dispatch(setStatusChangeConfirmationForPackage(false))
        }
      />
    </>
  )
}

export default PackageConfigurations
