import { createReducer, createAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  showSuccess,
  showError,
  setLoading,
  setAuxLoading,
  setEditMode,
} from './global'
import {
  getUsersQuery,
  getUserByIdQuery,
  findUserDuplicatesQuery,
} from 'graphql/queries'
import {
  createNewUser,
  resendUserRegistration,
  updateUserMutation,
} from 'graphql/mutations'
import { abcUserRegistrationTemplate } from 'common/templates'
import { userSignUpLink } from 'common/constants'
import {
  updateCognitoEmail,
  cleanUp,
  getStartOfDay,
  getEndOfDay,
} from 'common/helper'

export const setUsersList = createAction('abcUsers/setUsersList')
export const setAbcUsersDetails = createAction('abcUsers/setAbcUsersDetails')
export const setAbcPageNumber = createAction('abcUsers/setAbcPageNumber')
export const setAbcRowsPerPage = createAction('abcUsers/setAbcRowsPerPage')
export const setAbcRequestedSort = createAction('abcUsers/setAbcRequestedSort')
export const setAbcSelectedValue = createAction('abcUsers/setAbcSelectedValue')
export const setAbcUsersFilters = createAction('abcUsers/setAbcUsersFilters')
export const setIsAbcUsersFilterOpen = createAction(
  'abcUsers/setIsAbcUsersFilterOpen'
)
export const setAbcUserShow = createAction('abcUsers/setAbcUserShow')
export const setDeactivationConfirmation = createAction(
  'abcUsers/setDeactivationConfirmation'
)
export const setActivationConfirmation = createAction(
  'abcUsers/setActivationConfirmation'
)
export const resetABCUsers = createAction('abcUsers/resetABCUsers')

export const addNewUser = userDetails => {
  let newUser = JSON.parse(JSON.stringify(userDetails))
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading...'))
    const currUser = getState().auth.user

    // Check that the combination of names & email doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findUserDuplicatesQuery, {
        where: {
          firstName_lower: cleanUp(newUser.firstName),
          lastName_lower: cleanUp(newUser.lastName),
          email_lower: cleanUp(newUser.email),
        },
      })
    )
    if (res.data.users.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'A user with this name and email already exists, please review your information and try again.'
        )
      )
    } else {
      const user = {
        ...newUser,
        status: 'Pending',
        roleId: newUser.role,
        userId: currUser.id,
      }
      user.email = user.email.toLowerCase()

      const userRes = await API.graphql(graphqlOperation(createNewUser(user)))
      if (userRes.data.createUser) {
        userRes.data.createUser.role = newUser.role

        // Send user registration email
        const verificationCode = userRes.data.createUser.verificationCode
        const params = {
          body: {
            to: user.email,
            subject: 'ABC Price Posting - User Registration',
            message: abcUserRegistrationTemplate(verificationCode),
            buttonLink: userSignUpLink,
            buttonText: 'Complete Registration',
          },
        }
        await API.post('ABC-API', '/send-email', params)
        dispatch(setAbcUsersDetails(userRes.data.createUser))

        dispatch(setEditMode(false))
        dispatch(
          showSuccess(
            'A link to complete the registration process has been sent to the user.'
          )
        )
        dispatch(getListOfUsers())
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the ABC User, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

export const getListOfUsers = whereClause => {
  return async (dispatch, getState) => {
    const abcUserState = getState().abcUsers
    const orderRule = `${abcUserState.orderBy}_${abcUserState.order}`
    let filtersWhereClause = null
    if (whereClause) {
      let selectedRoles = whereClause.role
        ? whereClause.role.map(r => r.value)
        : ''
      filtersWhereClause = {
        ...whereClause,
        roleId_in: selectedRoles,
      }
      dispatch(setAbcUsersFilters(filtersWhereClause))
    } else {
      filtersWhereClause = abcUserState.abcUsersFilters
    }
    dispatch(setLoading('Loading ABC Users...'))

    // Add GraphQL query parameters
    let graphQLparams = {
      orderBy: orderRule,
      limit: abcUserState.rowsPerPage,
      offset: abcUserState.pageNumber * abcUserState.rowsPerPage,
    }
    // Set up GraphQL where parameters based on filters selected
    if (filtersWhereClause) {
      let whereParam = {}

      if (
        filtersWhereClause.roleId_in &&
        filtersWhereClause.roleId_in.length > 0
      )
        whereParam.roleId_in = filtersWhereClause.roleId_in.map(rId =>
          parseInt(rId)
        )
      else whereParam.roleId_in = [1, 2, 3]

      if (filtersWhereClause.status && filtersWhereClause.status.length > 0)
        whereParam.status_in = filtersWhereClause.status.map(stat => stat.value)

      if (filtersWhereClause.firstName)
        whereParam.firstName_like = cleanUp(filtersWhereClause.firstName)
      if (filtersWhereClause.lastName)
        whereParam.lastName_like = cleanUp(filtersWhereClause.lastName)
      if (filtersWhereClause.email)
        whereParam.email_like = cleanUp(filtersWhereClause.email)

      if (filtersWhereClause.createdAtFrom && filtersWhereClause.createdAtTo) {
        whereParam.createdAt_gte = getStartOfDay(
          filtersWhereClause.createdAtFrom
        )
        whereParam.createdAt_lte = getEndOfDay(filtersWhereClause.createdAtTo)
      }
      if (filtersWhereClause.updatedAtFrom && filtersWhereClause.updatedAtTo) {
        whereParam.updatedAt_gte = getStartOfDay(
          filtersWhereClause.updatedAtFrom
        )
        whereParam.updatedAt_lte = getEndOfDay(filtersWhereClause.updatedAtTo)
      }

      graphQLparams.where = whereParam
    }

    await API.graphql(graphqlOperation(getUsersQuery, graphQLparams))
      .then(response => {
        dispatch(setUsersList(response.data.users))
      })
      .catch(errorResponse => {
        dispatch(
          showError(
            'Unable to retrieve list of users, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setAbcPageNumber(0))
    dispatch(setAbcUsersFilters(initialState.abcUsersFilters))
    dispatch(getListOfUsers())
  }
}

/* Resend Registration code */
export const resendRegistration = id => {
  return async (dispatch, getState) => {
    dispatch(setAuxLoading('Sending registration to user...'))
    const currUser = getState().auth.user

    const user = {
      id,
      updatedBy: currUser.id,
    }
    const userRes = await API.graphql(
      graphqlOperation(resendUserRegistration(user))
    )
    if (userRes.data.resendUserRegistration) {
      const newRegistration = userRes.data.resendUserRegistration

      // Send user registration email
      const verificationCode = newRegistration.verificationCode
      const params = {
        body: {
          to: newRegistration.email.toLowerCase(),
          subject: 'ABC Price Posting - User Registration',
          message: abcUserRegistrationTemplate(verificationCode),
          buttonLink: userSignUpLink,
          buttonText: 'Complete Registration',
        },
      }
      await API.post('ABC-API', '/send-email', params)

      dispatch(
        showSuccess(
          'A link to complete the registration process has been sent to the user.'
        )
      )
      dispatch(setAuxLoading(false))
    } else {
      dispatch(setAuxLoading(false))
      dispatch(
        showError(
          'There was an issue while resending registration, please review the form and submit again. Thank you.'
        )
      )
    }
  }
}

// Fetch user by ID after selecting list item
export const fetchABCUserByID = (id, editMode = false) => {
  return async dispatch => {
    dispatch(setLoading('Loading User...'))
    const userRes = await API.graphql(
      graphqlOperation(getUserByIdQuery, { where: { id: parseInt(id) } })
    )
    if (userRes.data.users.results[0]) {
      const userFromResponse = userRes.data.users.results[0]
      let newResponse = {}
      newResponse = {
        ...userFromResponse,
        role: userFromResponse.role.id,
      }
      dispatch(setAbcUsersDetails(newResponse))
      dispatch(setEditMode(editMode))
      dispatch(setAbcUserShow(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve user, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const resetUserDetails = () => {
  return dispatch => {
    dispatch(setAbcUsersDetails(initialState.abcUsersDetails))
    dispatch(setLoading(false))
  }
}

export const updateAbcUser = (abcUsersDetails, hasEmailChanged = false) => {
  return async dispatch => {
    dispatch(setLoading(true))
    // Check that the combination of names & email doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findUserDuplicatesQuery, {
        where: {
          id_not: parseInt(abcUsersDetails.id),
          firstName_lower: cleanUp(abcUsersDetails.firstName),
          lastName_lower: cleanUp(abcUsersDetails.lastName),
          email_lower: cleanUp(abcUsersDetails.email),
        },
      })
    )
    if (res.data.users.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'A user with this name and email already exists, please review your information and try again.'
        )
      )
    } else {
      // If email was changed, update the Cognito user email attribute
      if (hasEmailChanged && abcUsersDetails.username) {
        // Update email attribute in Cognito user
        updateCognitoEmail(abcUsersDetails.username, abcUsersDetails.email)
      }

      await API.graphql(graphqlOperation(updateUserMutation(abcUsersDetails)))
        .then(res => {
          dispatch(setEditMode(false))
          dispatch(setAbcUsersDetails(abcUsersDetails))
          dispatch(getListOfUsers())
          dispatch(showSuccess('User details updated successfully'))
        })
        .catch(_ => {
          dispatch(showError(`Unable to edit profile data, please try again.`))
        })
    }
    dispatch(setLoading(false))
  }
}

/* ------------------------------------------------------------------------------- */
/* ------------- A C T I V A T E / D E A C T I V A T E   U S E R S  -------------- */
/* ------------------------------------------------------------------------------- */

// Enable/Disable user in Cognito and update status in DB
const toggleUserStatus = async (
  userDetails,
  option,
  userIds,
  updatedBy,
  dispatch
) => {
  dispatch(setLoading(true))
  dispatch(setAuxLoading(true))

  // Create new object
  let abcUser = JSON.parse(JSON.stringify(userDetails))

  const params = {
    body: {
      option,
      userIds,
      updatedBy,
    },
  }
  await API.post('ABC-API', '/update-user-status', params)
    .then(res => {
      abcUser.status = res.status
      // Update profile view & list
      dispatch(setAbcUsersDetails(abcUser))
      dispatch(getListOfUsers())
      dispatch(
        showSuccess(
          `User ${
            option === 'ENABLE' ? 'activated' : 'deactivated'
          } successfully`
        )
      )
    })
    .catch(_ => {
      dispatch(
        showError(
          `Unable to ${
            option === 'ENABLE' ? 'activate' : 'deactivate'
          } user, please try again.`
        )
      )
    })

  dispatch(setLoading(false))
  dispatch(setAuxLoading(false))
}

export const activateAbcUser = userId => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const userDetails = getState().abcUsers.abcUsersDetails

    await toggleUserStatus(
      userDetails,
      'ENABLE',
      [userId],
      currUser.id,
      dispatch
    )
  }
}

export const deactivateAbcUser = userId => {
  return async (dispatch, getState) => {
    const currUser = getState().auth.user
    const userDetails = getState().abcUsers.abcUsersDetails

    await toggleUserStatus(
      userDetails,
      'DISABLE',
      [userId],
      currUser.id,
      dispatch
    )
  }
}

const initialState = {
  abcUsersDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    createdAt: null,
    updatedAt: null,
    status: '',
    role: '',
  },
  isAbcUserShown: false,
  abcUsersFilters: {
    firstName: '',
    lastName: '',
    email: '',
    role: [],
    createdAt: null,
    operatorForCreated: 'equal',
    operatorForUpdated: 'equal',
    updatedAt: null,
    status: [],
  },
  usersList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  selected: [],
  isAbcUsersFilterOpen: false,
  activationConfirmation: false,
  deactivationConfirmation: false,
}

export default createReducer(initialState, {
  [setAbcUsersDetails]: (state, action) => {
    state.abcUsersDetails = action.payload
  },
  [setUsersList]: (state, action) => {
    state.usersList = action.payload
  },
  [setAbcPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setAbcRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setAbcRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setAbcSelectedValue]: (state, action) => {
    state.selected = action.payload
  },
  [setAbcUsersFilters]: (state, action) => {
    state.abcUsersFilters = action.payload
  },
  [setIsAbcUsersFilterOpen]: (state, action) => {
    state.isAbcUsersFilterOpen = action.payload
  },
  [setAbcUserShow]: (state, action) => {
    state.isAbcUserShown = action.payload
  },
  [setActivationConfirmation]: (state, action) => {
    state.activationConfirmation = action.payload
  },
  [setDeactivationConfirmation]: (state, action) => {
    state.deactivationConfirmation = action.payload
  },
  [resetABCUsers]: state => {
    state.abcUsersDetails = initialState.abcUsersDetails
    state.isAbcUserShown = initialState.isAbcUserShown
    state.abcUsersFilters = initialState.abcUsersFilters
    state.usersList = initialState.usersList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.selected = initialState.selected
    state.isAbcUsersFilterOpen = initialState.isAbcUsersFilterOpen
    state.activationConfirmation = initialState.activationConfirmation
    state.deactivationConfirmation = initialState.deactivationConfirmation
  },
})
