import React from 'react'
import { TextField } from '@material-ui/core'

const ProductSizeInput = ({ field, form: { touched, errors }, ...props }) => {
  let touchedValue = null
  let errorValue = null
  let fieldNameArray = field.name.split('.')
  if (fieldNameArray.length > 1) {
    touchedValue =
      touched[fieldNameArray[0]] &&
      touched[fieldNameArray[0]][fieldNameArray[1]]

    errorValue =
      errors[fieldNameArray[0]] && errors[fieldNameArray[0]][fieldNameArray[1]]
  } else {
    touchedValue = touched[fieldNameArray[0]] 
    errorValue = errors[fieldNameArray[0]]
  }
  return (
    <TextField
      error={touchedValue && errorValue ? true : false}
      helperText={touchedValue && errorValue && `${errorValue}`}
      {...field}
      {...props}
    />
  )
}
export default ProductSizeInput
