import { createAction, createReducer } from '@reduxjs/toolkit'
import { setLoading, showError } from 'ducks/global'
import {
  whereClauseBuilderForProducts,
  getToken,
  exportToExcel,
} from 'common/helper'
import {
  getProductsForBulkSelection,
  getProductsQuery,
  getPublicProductByIDQuery,
  getProductsForPricePostingAndExport,
} from 'graphql/queries'
import { print } from 'graphql'
import { format } from 'date-fns'

export const getProductsList = (whereClause, selectAll) => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Loading products, please wait....'))
    const { orderBy, order, productsFilters, rowsPerPage, pageNumber } =
      getState().publicProducts

    const orderRule = `${orderBy}_${order}`

    if (whereClause) {
      // Filter and map manufacturer values from the filters
      whereClause = {
        ...whereClause,
        manufacturerId_in: whereClause.manufacturer.map(item =>
          parseInt(item.value)
        ),
      }

      dispatch(setProductsFilters(whereClause))
    } else {
      whereClause = {
        ...productsFilters,
        ...whereClause,
      }
    }
    whereClause = {
      ...whereClause,
      status: { value: 'Active' },
    }

    // Add GraphQL query parameters
    let graphQLParams = {
      orderBy: orderRule,
      where: whereClauseBuilderForProducts(whereClause),
    }

    if (selectAll) {
      fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization:
            'Bearer ' + getToken({ code: 'getProductsForBulkSelection' }),
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          query: print(getProductsForBulkSelection),
          variables: graphQLParams,
        }),
      })
        .then(res => res.json())
        .then(response => {
          dispatch(setProductBulkSelection(response.data.products.results))
        })
        .catch(_ => {
          dispatch(
            showError(
              'Unable to select products, please check your internet connection and try again. Thank you.'
            )
          )
        })
    } else {
      graphQLParams = {
        ...graphQLParams,
        limit: rowsPerPage,
        offset: pageNumber * rowsPerPage,
      }
      await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken({ code: 'getProductsQuery' }),
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          query: print(getProductsQuery),
          variables: graphQLParams,
        }),
      })
        .then(res => res.json())
        .then(response => {
          dispatch(setProductsList(response.data.products))
        })
        .catch(_ => {
          dispatch(
            showError(
              'Unable to retrieve list of products, please check your internet connection and try again'
            )
          )
        })
    }
    dispatch(setLoading(false))
  }
}

export const fetchProductByID = id => {
  return async dispatch => {
    dispatch(setLoading('Loading Product...'))

    await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer ' + getToken({ code: 'getPublicProductByIDQuery' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getPublicProductByIDQuery),
        variables: { id: parseInt(id) },
      }),
    })
      .then(res => res.json())
      .then(res => {
        const tempRes = res.data.products.results[0]
        const newResponse = {
          ...tempRes,
          manufacturer: tempRes.manufacturer,
          tradeName: tempRes.tradeName ? tempRes.tradeName : '',
        }
        dispatch(setProductDetails(newResponse))
        dispatch(setProductShow(true))
      })
      .catch(_ => {
        dispatch(
          showError(
            `Unable to retrieve product, please check your internet connection and try again`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const exportProductsToExcel = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading('Exporting products for price posting, please wait...'))

    const productsState = getState().products
    const orderRule = `${productsState.orderBy}_${productsState.order}`
    const graphQLParams = {
      where: {
        id_in: productsState.bulkSelected.map(item => parseInt(item.id)),
      },
      orderBy: orderRule,
    }

    await fetch(`${process.env.REACT_APP_PUBLIC_GRAPHQL_SERVER}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization:
          'Bearer ' + getToken({ code: 'getProductsForPricePostingAndExport' }),
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getProductsForPricePostingAndExport),
        variables: graphQLParams,
      }),
    })
      .then(res => res.json())
      .then(response => {
        const finalProductsToExport = response.data.products.results.map(
          item => ({
            Manufacturer: item.manufacturer.name,
            'Trade Name': item.tradeName,
            'Product Name': item.name,
            'Created Date': format(item.createdAt, 'MM/dd/yyyy'),
            'Updated Date': format(item.updatedAt, 'MM/dd/yyyy'),
          })
        )
        exportToExcel(finalProductsToExport, 'List of products')
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list  of products, please check your internet connection and try again.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const resetProductDetails = () => {
  return dispatch => {
    dispatch(setProductDetails(initialState.productDetails))
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setProductBulkSelection([]))
    dispatch(setProductsPageNumber(0))
    dispatch(setProductsFilters(initialState.productsFilters))
    dispatch(getProductsList())
  }
}

export const setProductsList = createAction('products/setProductsList')
export const resetProductsList = createAction('products/resetProductsList')
export const setIsProductsFilterOpen = createAction(
  'products/setIsProductsFilterOpen'
)
export const setProductsFilters = createAction('products/setProductsFilters')
export const setProductShow = createAction('products/setProductShow')
export const setProductDetails = createAction('products/setProductDetails')
export const setProductBulkSelection = createAction(
  'products/setProductBulkSelection'
)
export const setProductsPageNumber = createAction(
  'products/setProductsPageNumber'
)
export const setProductsRowsPerPage = createAction(
  'products/setProductsRowsPerPage'
)
export const setProductsRequestedSort = createAction(
  'products/setProductsRequestedSort'
)

const initialState = {
  productsList: { results: [], count: 0, totalCount: 0, firstLoad: true },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'createdAt',
  bulkSelected: [],
  isProductsFilterOpen: false,
  productsFilters: {
    manufacturer: [],
    tradeName: '',
    name: '',
    createdAt: null,
    operatorForCreated: 'equal',
    updatedAt: null,
    operatorForUpdated: 'equal',
  },
  isProductShown: false,
  productDetails: {
    manufacturer: '',
    tradeName: '',
    oldTradeName: '',
    name: '',
    oldName: '',
    createdAt: null,
    updatedAt: null,
  },
}

export default createReducer(initialState, {
  [setProductsList]: (state, action) => {
    state.productsList = action.payload
  },
  [resetProductsList]: state => {
    state.productsList = initialState.productsList
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.bulkSelected = initialState.bulkSelected
    state.isProductsFilterOpen = initialState.isProductsFilterOpen
    state.productsFilters = initialState.productsFilters
    state.isProductShown = initialState.isProductShown
    state.productDetails = initialState.productDetails
  },
  [setIsProductsFilterOpen]: (state, action) => {
    state.isProductsFilterOpen = action.payload
  },
  [setProductsFilters]: (state, action) => {
    state.productsFilters = action.payload
  },
  [setProductShow]: (state, action) => {
    state.isProductShown = action.payload
  },
  [setProductDetails]: (state, action) => {
    state.productDetails = action.payload
  },

  [setProductBulkSelection]: (state, action) => {
    state.bulkSelected = action.payload
  },
  [setProductsPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setProductsRowsPerPage]: (state, action) => {
    state.rowsPerPage = parseInt(action.payload, 10)
    state.pageNumber = 0
  },
  [setProductsRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
})
