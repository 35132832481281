import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Collapse,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ArrowLeft,
  Tune,
  PersonAdd,
  CloudUpload,
  InfoOutlined,
} from '@material-ui/icons'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import LoadingIndicator from './LoadingIndicator'
import QuickView from 'components/common/QuickView'
import { Can } from 'common/abilityContext'

// const List = styled.ul`
//   padding-left: 20px;
// `

const ToolTipIcon = styled(InfoOutlined)`
  margin-right: 2px;
`

const PaddedContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`

const CustomCardHeader = styled(CardHeader)`
  && {
    margin: 0px;
    background-color: ${props => props.theme.dark};
    padding: 16px 20px;
    font-weight: bold;
    color: ${props => props.theme.light};
    .MuiCardHeader-action {
      width: ${props => (props.smalltable === 'true' ? '25%' : '70%')};
      margin-right: 3px;
    }
    @media (max-width: 448px) {
      display: block;
      .MuiCardHeader-action {
        width: 100%;
      }
      .MuiCardHeader-content {
        margin-bottom: 20px;
      }
    }
  }
`

const CustomCardContent = styled(CardContent)`
  && {
    padding-bottom: 0px !important;
  }
`
const CardActionGrid = styled(Grid)`
  && {
    margin: 0 0 -3px 0;
    justify-content: flex-end;
  }
`

const FilterIcon = styled(Tune)`
  margin-right: 6px;
`

const ArrowNotExpanded = styled(ArrowLeft)`
  && {
    transition: all 0.3s ease;
    transform: ${props => (props.filterexpanded ? `rotate(-90deg)` : '')};
  }
`

const AddIcon = styled(PersonAdd)`
  margin-right: 6px;
`

const BulkUploadIcon = styled(CloudUpload)`
  margin-right: 6px;
`
const RadioFormControl = styled(FormControl)`
  && {
    display: flex;
  }
  @media only screen and (max-width: 400px) {
    margin-bottom: 7px;
  }
`
const RadioGroupOptions = styled(RadioGroup)`
  && {
    justify-content: flex-end;
  }
`

const RadioLabel = styled(FormControlLabel)`
  @media only screen and (max-width: 400px) {
    display: flex;
    height: 25px;
  }
`

const HeaderButton = styled(Button)`
  && {
    margin-top: 5px;
    margin-left: 20px;

    @media (max-width: 448px) {
      margin-left: 0;
      width: 100%;
    }
  }
`

const ListComponent = ({
  mainTitle,
  listContent,
  onModalClosed,
  editComp,
  viewComp,
  modalEditTitle,
  modalViewTitle,
  loadingText,
  expandedOptions,
  handleExpandingFilter,
  filterExpanded,
  isDialogOpen,
  onAddButtonClicked,
  smallTable,
  bulkUploadContent,
  bulkUploadTitle,
  onBulkUploadClicked,
  isBulkDialogOpen,
  isBulkUploadAvailable,
  onBulkUploadModalClosed,
  listType,
  handleListType,
  listTypeRadioLabels,
  permitRadioFilters,
  radioButtonDisabledReason,
  publicView,
}) => {
  const { isEditMode, isLoading, loadingProgress } = useSelector(
    store => store.global
  )

  return (
    <PaddedContainer>
      <Card>
        <CustomCardHeader
          smalltable={`${smallTable}`}
          action={
            <CardActionGrid container>
              {expandedOptions && (
                <HeaderButton
                  variant='contained'
                  color='primary'
                  size='medium'
                  onClick={() => handleExpandingFilter(!filterExpanded)}
                  startIcon={!smallTable && <FilterIcon />}
                  endIcon={
                    <ArrowNotExpanded
                      filterexpanded={filterExpanded ? 'true' : undefined}
                    />
                  }
                >
                  Filter
                </HeaderButton>
              )}
              {publicView ? (
                ''
              ) : (
                <Can I='perform' this={`addEditBulkOn${mainTitle}`}>
                  {() => (
                    <>
                      {isBulkUploadAvailable && (
                        <HeaderButton
                          variant='contained'
                          color='primary'
                          size='medium'
                          onClick={() => onBulkUploadClicked()}
                          startIcon={<BulkUploadIcon />}
                        >
                          Bulk Upload
                        </HeaderButton>
                      )}
                      {onAddButtonClicked && (
                        <HeaderButton
                          variant='contained'
                          color='primary'
                          size='medium'
                          onClick={() => onAddButtonClicked()}
                          startIcon={!smallTable && <AddIcon />}
                        >
                          New
                        </HeaderButton>
                      )}
                    </>
                  )}
                </Can>
              )}
            </CardActionGrid>
          }
          title={
            <div role='heading' aria-level='1'>
              {mainTitle}
            </div>
          }
          titleTypographyProps={{ variant: 'h6' }}
        />
        <CustomCardContent>
          <Collapse in={filterExpanded}>
            {filterExpanded ? expandedOptions : ''}
          </Collapse>
          {isLoading ? (
            <LoadingIndicator text={loadingText} progress={loadingProgress} />
          ) : (
            ''
          )}
          {publicView ? (
            ''
          ) : (
            <Can I='haveFilteringRadio' this={permitRadioFilters}>
              {listType ? (
                <RadioFormControl component='fieldset'>
                  <RadioGroupOptions
                    aria-label='Fetch All Items'
                    name='Fetch All Items'
                    value={listType}
                    onChange={event => handleListType(event)}
                    row
                  >
                    <Tooltip
                      arrow={true}
                      variant='dense'
                      placement='bottom'
                      disableFocusListener={!radioButtonDisabledReason}
                      disableHoverListener={!radioButtonDisabledReason}
                      disableTouchListener={!radioButtonDisabledReason}
                      title={
                        //leaving it here, just so that in the future might needed to show multiple messages
                        // disabledReasons.length > 1 ? (
                        //   <>
                        //     <Typography
                        //       color='inherit'
                        //       variant='subtitle1'
                        //       children={<b>{'Radio buttons disabled reason'}</b>}
                        //     />
                        //     <List>
                        //       {disabledReasons.map((info, index) => (
                        //         <li key={index}>
                        //           <Typography variant='body2'>{info}</Typography>
                        //         </li>
                        //       ))}
                        //     </List>
                        //   </>
                        // ) : (
                        <Typography
                          color='inherit'
                          variant='subtitle1'
                          children={`"My Products" cannot be selected as there are no products created under the logged in user's licensee.`}
                        />
                        // )
                      }
                    >
                      <span>
                        <RadioLabel
                          value='all'
                          control={<Radio color='primary' />}
                          label={listTypeRadioLabels[0]}
                          labelPlacement='end'
                        />
                        <RadioLabel
                          value='filtered'
                          control={<Radio color='primary' />}
                          label={listTypeRadioLabels[1]}
                          labelPlacement='end'
                        />
                        {radioButtonDisabledReason && (
                          <ToolTipIcon color='primary' />
                        )}
                      </span>
                    </Tooltip>
                  </RadioGroupOptions>
                </RadioFormControl>
              ) : (
                ''
              )}
            </Can>
          )}
          {listContent}
        </CustomCardContent>
      </Card>
      {isEditMode ? (
        <QuickView
          title={modalEditTitle}
          onClose={() => onModalClosed()}
          dialogContent={editComp}
          isDialogOpen={isDialogOpen}
          isSmallDialog={smallTable}
        />
      ) : (
        <QuickView
          title={modalViewTitle}
          onClose={() => onModalClosed()}
          dialogContent={viewComp}
          isDialogOpen={isDialogOpen}
          isSmallDialog={smallTable}
        />
      )}
      {isBulkUploadAvailable && (
        <QuickView
          title={bulkUploadTitle}
          onClose={() => onBulkUploadModalClosed()}
          dialogContent={bulkUploadContent}
          isDialogOpen={isBulkDialogOpen}
          isSmallDialog={true}
        />
      )}
    </PaddedContainer>
  )
}

ListComponent.defaultProps = {
  permitRadioFilters: 'none',
}

export default ListComponent
