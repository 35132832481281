import React, { useState } from 'react'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import '../../styles/rsuite.css'
import { InputLabel, FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  picker: {
    alignItems: 'center',
    backgroundColor: '#fff !important',
    borderColor: '#ccc',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '45px',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    marginTop: '8px',
    fontSize: '14px',
    '& div': {
      border: 'none !important',
    },
    '& input::placeholder': {
      color: '#ccc !important',
    },
    '& span': {
      color: '#aaa !important',
    },
  },
  dialog: {
    width: '1000px',
    margin: 'auto',
    overflow: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    'z-index': '1000',
  },
  labelContainer: {
    position: 'absolute',
    top: '-16px',
    left: '-2px',
  },
  label: {
    background: '#fff',
    display: 'inline-block',
    padding: '0 5px',
    'white-space': 'nowrap',
  },
}

//Formats dates for DateRangePicker
const FormatDates = (dates) => {
  const begin = new Date(dates.begin)
    const end = new Date(dates.end)
    const values = dates.begin ? [begin, end] : [] 
    return(values)
}

const DatePickerWrapper = ({ label, onChange, classes, value }) => {
  const values = FormatDates(value)
  return (
    <>
      <FormControl className={classes.labelContainer}>
        <InputLabel variant='filled' shrink className={classes.label}>
          {label}
        </InputLabel>
      </FormControl>
      <DateRangePicker
        className={classes.picker}
        value={values}
        preventOverflow={true}
        placement={'autoHorizontalStart'}
        placeholder='MM/DD/YYYY ~ MM/DD/YYYY'
        format='MM/dd/yyyy'
        onChange={date => {
          const startDate = date[0] ? date[0] : ''
          const endDate = date[1] ? date[1] : ''
          onChange({
            begin: startDate > endDate ? endDate : startDate,
            end: startDate > endDate ? startDate : endDate,
          })
        }}
      />
    </>
  )
}

const StyledDatePickerWrapper = withStyles(styles)(DatePickerWrapper)

const GenericDatePicker = props => {
  const [open, setOpen] = useState(false)
  const datePickerProps = {
    ...props,
    setOpen,
    open,
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <StyledDatePickerWrapper {...datePickerProps} />
    </div>
  )
}

export default GenericDatePicker
