import { createAction, createReducer } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import {
  setLoading,
  setEditMode,
  showError,
  showSuccess,
  setNewPackageConfigAdded,
} from './global'
import {
  getPackagesQuery,
  getPackagesByIDQuery,
  findPackagesDuplicatesQuery,
  findGlobalSettingsInPricePostsQuery,
} from 'graphql/queries'
import {
  createPackageMutation,
  updatePackageMutation,
  inactivateAndCreatePackageMutation,
  changePackageStatus,
} from 'graphql/mutations'
import { cleanUp } from 'common/helper'

export const fetchPackageConfigurations = whereClause => {
  return async (dispatch, getState) => {
    const packageState = getState().packageConfigurations
    const orderRule = `${packageState.orderBy}_${packageState.order}`

    dispatch(setLoading('Fetching Package configurations...'))

    // Add GraphQL query parameters
    let graphQLparams = {
      where: { status_not: 'Pending' },
      orderBy: orderRule,
      limit: packageState.rowsPerPage,
      offset: packageState.pageNumber * packageState.rowsPerPage,
    }

    if(!whereClause){
      whereClause = {
        ...packageState.packageConfigurationFilters,
      }
    }   

    let whereParam = {}
    if (whereClause.configuration)
      whereParam.package_in = whereClause.configuration.map(pkg => pkg.label)
    if (whereClause.status)
      whereParam.status_in = whereClause.status.map(status => status.value)
    graphQLparams.where = { ...graphQLparams.where, ...whereParam }
    whereClause !== packageState.packageConfigurationFilters && dispatch(setPackageConfigurationFilters(whereClause))
    

    await API.graphql(graphqlOperation(getPackagesQuery, graphQLparams))
      .then(response => {
        dispatch(setPackageConfigurationsList(response.data.packages))
      })
      .catch(_ => {
        dispatch(
          showError(
            'Unable to retrieve list of package configurations, please check your internet connection and try again'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const resetPackageConfigurationDetails = () => {
  return dispatch => {
    dispatch(
      setPackageConfigurationDetails(initialState.packageConfigurationDetails)
    )
    dispatch(setEditMode(true))
    dispatch(setLoading(false))
  }
}

export const addNewPackageConfiguration = packageConfigurationDetails => {
  let newPackageConfiguration = JSON.parse(
    JSON.stringify(packageConfigurationDetails)
  )
  return async dispatch => {
    dispatch(setLoading('Adding package configuration...'))
    const graphQlParams = {
      where: {
        package_lower: cleanUp(newPackageConfiguration.package),
      },
    }

    // Check that the package configuration doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findPackagesDuplicatesQuery, graphQlParams)
    )
    if (res.data.packages.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The package configuration selected already exists. Please review your information and try again.'
        )
      )
    } else {
      const graphQlParamsForCreation = {
        data: {
          package: cleanUp(newPackageConfiguration.package),
        },
      }
      const packageRes = await API.graphql(
        graphqlOperation(createPackageMutation, graphQlParamsForCreation)
      )
      if (packageRes.data.createPackage) {
        dispatch(setPackageConfigurationDetails(packageRes.data.createPackage))
        dispatch(setPackageConfigurationShown(false))
        dispatch(
          showSuccess(
            'New package configuration has been created successfully.'
          )
        )
        dispatch(fetchPackageConfigurations())
        dispatch(setNewPackageConfigAdded(true))
      } else {
        dispatch(setLoading(false))
        dispatch(
          showError(
            'There was an issue creating the package configuration, please review the form and submit again. Thank you.'
          )
        )
      }
    }
  }
}

export const updatePackageConfiguration = packageConfigurationDetails => {
  return async dispatch => {
    dispatch(setLoading('Updating package configuration...'))
    const graphQlParams = {
      where: {
        package_lower: cleanUp(packageConfigurationDetails.package),
      },
    }

    // Check that the package configuration doesn't already exist in the system
    const res = await API.graphql(
      graphqlOperation(findPackagesDuplicatesQuery, graphQlParams)
    )
    if (res.data.packages.count > 0) {
      dispatch(setLoading(false))
      dispatch(
        showError(
          'The package configuration selected already exists. Please review your information and try again.'
        )
      )
    } else {
      // Check that the package configuration isn't being used by existing price postings, if it is show message to user
      const res = await API.graphql(
        graphqlOperation(
          findGlobalSettingsInPricePostsQuery({
            package: packageConfigurationDetails.id,
          })
        )
      )
      // Display confirmation message to user
      if (res.data.pricePostings.count > 0) {
        dispatch(setLoading(false))
        dispatch(setIsPackageConfigurationUsed(true))
        dispatch(setPackageConfigurationDetails(packageConfigurationDetails))
        dispatch(setPackageConfigurationShown(false))
      } else {
        const graphQlParamsForUpdate = {
          data: {
            package: cleanUp(packageConfigurationDetails.package),
          },
          where: {
            id: packageConfigurationDetails.id,
          },
        }
        await API.graphql(
          graphqlOperation(updatePackageMutation, graphQlParamsForUpdate)
        )
          .then(_ => {
            dispatch(
              setPackageConfigurationDetails(packageConfigurationDetails)
            )
            dispatch(setPackageConfigurationShown(false))
            dispatch(
              showSuccess(
                'Package configuration has been updated successfully.'
              )
            )
            dispatch(fetchPackageConfigurations())
            dispatch(setNewPackageConfigAdded(true))
          })
          .catch(_ => {
            dispatch(
              showError(
                'There was an issue updating the package configuration, please review the form and submit again. Thank you.'
              )
            )
          })
        dispatch(setLoading(false))
      }
    }
  }
}

export const updatePackageConfigurationOnConfirmation = () => {
  return async (dispatch, getState) => {
    dispatch(setIsPackageConfigurationUsed(false))
    dispatch(setLoading('Loading...'))
    const { packageConfigurationDetails } = getState().packageConfigurations
    const graphQlParamsForUpdate = {
      data: {
        package: cleanUp(packageConfigurationDetails.package),
      },
      where: {
        id: packageConfigurationDetails.id,
      },
    }
    await API.graphql(
      graphqlOperation(
        inactivateAndCreatePackageMutation,
        graphQlParamsForUpdate
      )
    )
      .then(_ => {
        dispatch(setPackageConfigurationDetails(packageConfigurationDetails))
        dispatch(
          showSuccess('Package configuration has been updated successfully.')
        )
        dispatch(fetchPackageConfigurations())
        dispatch(setNewPackageConfigAdded(true))
      })
      .catch(_ => {
        dispatch(
          showError(
            'There was an issue updating the package configuration, please review the form and submit again. Thank you.'
          )
        )
      })
    dispatch(setLoading(false))
  }
}

export const togglePackageStatus = packageDetails => {
  return async (dispatch, getState) => {
    dispatch(setStatusChangeConfirmationForPackage(false))
    const userMessage =
      packageDetails.status === 'Active' ? 'Deactivating' : 'Reactivating'
    dispatch(
      setLoading(`${userMessage}  "Package Configuration". Please wait...`)
    )
    let { packageConfigurationsList } = getState().packageConfigurations
    packageConfigurationsList = JSON.parse(
      JSON.stringify(packageConfigurationsList)
    )
    const changingStatus =
      packageDetails.status === 'Active' ? 'Inactive' : 'Active'
    const graphQLParams = {
      status: changingStatus,
      where: {
        id: packageDetails.id,
      },
    }
    await API.graphql(graphqlOperation(changePackageStatus, graphQLParams))
      .then(_ => {
        const packageIndex = packageConfigurationsList.results.findIndex(
          item => parseInt(item.id) === packageDetails.id
        )
        packageConfigurationsList.results[packageIndex].status = changingStatus
        dispatch(setPackageConfigurationsList(packageConfigurationsList))
        dispatch(
          showSuccess(
            `${userMessage}  "Package Configuration" was successfully.`
          )
        )
      })
      .catch(err => {
        dispatch(
          showError(
            `There was an issue ${userMessage} the "Package Configuration", please check your internet connection and try again. Thank you.`
          )
        )
      })
    dispatch(setLoading(false))
  }
}

// Fetch package configurations by ID after selecting list item
export const fetchPackageConfigurationByID = (id, editMode = true) => {
  return async dispatch => {
    dispatch(setLoading('Loading...'))
    const packageRes = await API.graphql(
      graphqlOperation(getPackagesByIDQuery, { id: parseInt(id) })
    )
    if (packageRes.data.packages.results[0]) {
      dispatch(
        setPackageConfigurationDetails(packageRes.data.packages.results[0])
      )
      dispatch(setEditMode(editMode))
      dispatch(setPackageConfigurationShown(true))
    } else {
      dispatch(
        showError(
          `Unable to retrieve package configuration, please check your internet connection and try again`
        )
      )
    }
    dispatch(setLoading(false))
  }
}

export const clearFilters = () => {
  return dispatch => {
    dispatch(setFiltersUsed(false))
    dispatch(setPackageConfigurationPageNumber(0))
    dispatch(
      setPackageConfigurationFilters(initialState.packageConfigurationFilters)
    )
    dispatch(fetchPackageConfigurations())
  }
}

export const setPackageConfigurationsList = createAction(
  'packageConfigurations/setPackageConfigurationsList'
)
export const setPackageConfigurationFilters = createAction(
  'packageConfigurations/setPackageConfigurationFilters'
)
export const setIsPackageConfigurationFilterOpen = createAction(
  'packageConfigurations/setIsPackageConfigurationFilterOpen'
)
export const setPackageConfigurationPageNumber = createAction(
  'packageConfigurations/setPackageConfigurationPageNumber'
)
export const setPackageConfigurationRequestedSort = createAction(
  'packageConfigurations/setPackageConfigurationRequestedSort'
)
export const setPackageConfigurationSelectedValue = createAction(
  'packageConfigurations/setPackageConfigurationSelectedValue'
)
export const setPackageConfigurationShown = createAction(
  'packageConfigurations/setPackageConfigurationShown'
)
export const setPackageConfigurationDetails = createAction(
  'packageConfigurations/setPackageConfigurationDetails'
)
export const setIsPackageConfigurationUsed = createAction(
  'packageConfigurations/setIsPackageConfigurationUsed'
)
export const resetPackageConfiguration = createAction(
  'packageConfigurations/resetPackageConfiguration'
)
export const setStatusChangeConfirmationForPackage = createAction(
  'packageConfigurations/setStatusChangeConfirmationForPackage'
)
export const setFiltersUsed = createAction(
  'packageConfigurations/setFiltersUsed'
)

const initialState = {
  packageConfigurationsList: {
    results: [],
    count: 0,
    totalCount: 0,
    firstLoad: true,
  },
  packageConfigurationFilters: {
    configuration: '',
    status: '',
  },
  packageConfigurationDetails: {
    package: '',
  },
  pageNumber: 0,
  rowsPerPage: 10,
  order: 'DESC',
  orderBy: 'package',
  selected: [],
  isPackageConfigurationShown: false,
  isPackageConfigurationUsed: false,
  isPackageConfigurationFilterOpen: false,
  statusChangeConfirmationForPackage: false,
}

export default createReducer(initialState, {
  [setPackageConfigurationDetails]: (state, action) => {
    state.packageConfigurationDetails = action.payload
  },
  [setPackageConfigurationsList]: (state, action) => {
    state.packageConfigurationsList = action.payload
  },
  [setPackageConfigurationFilters]: (state, action) => {
    state.packageConfigurationFilters = action.payload
  },
  [setIsPackageConfigurationFilterOpen]: (state, action) => {
    state.isPackageConfigurationFilterOpen = action.payload
  },
  [setFiltersUsed]: (state, action) => {
    state.areFiltersUsed = action.payload
  },
  [setPackageConfigurationPageNumber]: (state, action) => {
    state.pageNumber = action.payload
  },
  [setPackageConfigurationRequestedSort]: (state, action) => {
    const isDesc = state.orderBy === action.payload && state.order === 'DESC'
    state.order = isDesc ? 'ASC' : 'DESC'
    state.orderBy = action.payload
  },
  [setPackageConfigurationSelectedValue]: (state, action) => {
    state.selected = action.payload
  },
  [setPackageConfigurationShown]: (state, action) => {
    state.isPackageConfigurationShown = action.payload
  },
  [setIsPackageConfigurationUsed]: (state, action) => {
    state.isPackageConfigurationUsed = action.payload
  },
  [setStatusChangeConfirmationForPackage]: (state, action) => {
    state.statusChangeConfirmationForPackage = action.payload
  },
  [resetPackageConfiguration]: state => {
    state.packageConfigurationsList = initialState.packageConfigurationsList
    state.packageConfigurationDetails = initialState.packageConfigurationDetails
    state.pageNumber = initialState.pageNumber
    state.rowsPerPage = initialState.rowsPerPage
    state.order = initialState.order
    state.orderBy = initialState.orderBy
    state.selected = initialState.selected
    state.isPackageConfigurationShown = initialState.isPackageConfigurationShown
    state.isPackageConfigurationUsed = initialState.isPackageConfigurationUsed
  },
})
