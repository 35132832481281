import React from 'react'
import {} from '@material-ui/core'

import { Typography, Button, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { darken } from 'polished'
import { useDispatch, useSelector } from 'react-redux'
import {
  reactivateLicensee,
  setConfirmationForLinkingProducts,
  createNewLicenseeProcess,
} from 'ducks/legalEntity'

const Wrapper = styled(Grid)`
  && {
    margin-top: 2px;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
`

const StyledButton = styled(Button)`
  && {
    width: 100%;
    color: white;
    ${props =>
      props.disabled
        ? `background-color: rgba(0, 0, 0, 0.12);`
        : props.styledcolor
        ? `background-color: ${props.theme[props.styledcolor]};`
        : ''}
    transition: 250ms all ease-in;

    &:hover {
      ${props =>
        props.styledcolor
          ? `background-color: ${darken(0.1, props.theme[props.styledcolor])};`
          : ''}
    }
  }
`

const CreateOrReactivateLicensee = () => {
  const dispatch = useDispatch()
  const { inactiveLicenseeFound, legalEntityDetails } = useSelector(
    store => store.legalEntity
  )

  const handleCreateLicensee = () => {
    // Only ask about linking products from inactive licensee if the current licensee is a manufacturer
    if (legalEntityDetails.licenseCategory.includes('Manufacturer'))
      dispatch(setConfirmationForLinkingProducts(true))
    else dispatch(createNewLicenseeProcess(legalEntityDetails, false, false))
  }

  return (
    <>
      <Typography
        children='One inactive licensee with the same name has been found in the system. To continue, select one of the options below:'
        variant='subtitle1'
      />
      <br />
      <Wrapper container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <StyledButton
            aria-label='submit-filter'
            styledcolor='blue'
            variant='contained'
            onClick={() => dispatch(reactivateLicensee(inactiveLicenseeFound))}
          >
            Reactivate existing licensee
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <StyledButton
            aria-label='submit-filter'
            styledcolor='blue'
            variant='contained'
            onClick={handleCreateLicensee}
          >
            Create a new licensee
          </StyledButton>
        </Grid>
      </Wrapper>
    </>
  )
}

export default CreateOrReactivateLicensee
