import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Avatar, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Edit, AttachMoney, Delete, InsertDriveFile } from '@material-ui/icons'
import { setEditMode } from 'ducks/global'
import { fetchPricePostScheduleErrorFile } from 'ducks/pricePostingsSchedule'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { Can } from 'common/abilityContext'
import { addEditBulkOn } from 'common/constants'
import { CheckLicensee } from 'common/helper'

const MoneyIcon = styled(AttachMoney)`
  && {
    font-size: 3.03125rem;
  }
`
const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`

const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`

const DeleteIcon = styled(Delete)`
  && {
    font-size: 30px;
  }
`
const TitleLabel = styled(Typography)`
  font-weight: bold;
`
const TitleValue = styled(Typography)`
  color: #666;
`

const ViewPricePostingsSchedule = props => {
  const dispatch = useDispatch()
  const { isAuxLoading, loadingAuxText } = useSelector(store => store.global)
  // const { user } = useSelector(store => store.auth)
  const { pricePostingScheduleDetails } = useSelector(
    store => store.pricePostingsSchedule
  )

  return (
    <>
      {isAuxLoading ? (
        <LoadingIndicator text={loadingAuxText} transparent />
      ) : (
        <>
          <CustomCard
            profileIcon={
              <UserIcon alt='Scheduled Price Posting Icon'>
                <MoneyIcon />
              </UserIcon>
            }
            profileTitle={
              <>
                <TitleLabel children={`File Name`} variant='body2' />
                <TitleValue
                  children={
                    pricePostingScheduleDetails.uploadName &&
                    pricePostingScheduleDetails.uploadName
                  }
                  variant='h6'
                />
              </>
            }
            profileAction={
              <>
                <Can
                  I='edit/view'
                  this={
                    new CheckLicensee({
                      ifCreatedByLicenseeIs:
                        pricePostingScheduleDetails.manufacturer &&
                        pricePostingScheduleDetails.manufacturer.id,
                    })
                  }
                >
                  {() =>
                    pricePostingScheduleDetails.status == 'SCH' && (
                      <Can
                        I='perform'
                        this={addEditBulkOn.pricePostingsSchedule}
                      >
                        {() => (
                          <>
                            {/* <IconButton
                          aria-label='Deleted Scheduled Price Posting'
                          aria-haspopup='false'
                          color='primary'
                          onClick={
                              dispatch(
                            deletePricePostingsScheduleByID(
                              pricePostingScheduleDetails.id
                            )
                          )}
                        >
                          <DeleteIcon color='primary' />
                          <Typography variant='subtitle1'>
                            &nbsp;{'Delete'}
                          </Typography>
                        </IconButton> */}
                            <IconButton
                              aria-label='Edit Scheduled Price Posting'
                              aria-haspopup='false'
                              color='primary'
                              onClick={() => dispatch(setEditMode(true))}
                            >
                              <EditIcon color='primary' />
                              <Typography variant='subtitle1'>
                                &nbsp;{'Edit'}
                              </Typography>
                            </IconButton>
                          </>
                        )}
                      </Can>
                    )
                  }
                </Can>
              </>
            }
            divider
          >
            <DataGrid
              label='Scheduled Submit Date'
              value={
                pricePostingScheduleDetails.scheduledDate
                  ? format(
                      pricePostingScheduleDetails.scheduledDate,
                      'MM/dd/yyyy'
                    )
                  : ''
              }
            />
            <DataGrid
              label='Posted By'
              value={
                pricePostingScheduleDetails.createdBy &&
                pricePostingScheduleDetails.createdBy.label
              }
            />
            <DataGrid
              label='Posted Date'
              value={
                pricePostingScheduleDetails.createdAt
                  ? format(pricePostingScheduleDetails.createdAt, 'MM/dd/yyyy')
                  : ''
              }
            />
            <DataGrid
              label='Status'
              value={props.processingStatus ? props.processingStatus : ''}
            />
            <DataGrid
              label='Record Count'
              value={
                pricePostingScheduleDetails.numRecords
                  ? pricePostingScheduleDetails.numRecords
                  : '0'
              }
            />
            <DataGrid
              label='Successful Count'
              value={
                pricePostingScheduleDetails.numProcessed
                  ? pricePostingScheduleDetails.numProcessed
                  : '0'
              }
            />
            <DataGrid
              label='Error Count'
              value={
                pricePostingScheduleDetails.numErrors
                  ? pricePostingScheduleDetails.numErrors
                  : '0'
              }
            />
            <br />
            {pricePostingScheduleDetails.numErrors ? (
              <DataGrid
                label='Error File'
                value={
                  <IconButton
                    aria-label='Error File'
                    aria-haspopup='false'
                    className='inactive'
                    onClick={() =>
                      dispatch(
                        fetchPricePostScheduleErrorFile(
                          pricePostingScheduleDetails.uploadKey
                        )
                      )
                    }
                  >
                    <InsertDriveFile color='error' />
                    <Typography variant='subtitle1'>
                      &nbsp;{'Download Error File'}
                    </Typography>
                  </IconButton>
                }
              />
            ) : (
              ''
            )}
          </CustomCard>
        </>
      )}
    </>
  )
}

export default ViewPricePostingsSchedule
