import gql from 'graphql-tag'
import { cleanUp } from 'common/helper'

export const createAddressMutation = address => gql`
  mutation createAddress {
    createAddress(
      data: {
        address: "${cleanUp(address.address)}"
        address2: "${cleanUp(address.address2)}"
        city: "${cleanUp(address.city)}"
        state: "${cleanUp(address.state)}"
        zipcode: "${cleanUp(address.zipcode)}"
        country: "${address.country}"
      }
    ) {
      id
    }
  }
`

export const updateAddressMutation = address => gql`
  mutation UpdateAddress {
    updateAddress(
      data: {
        address: "${cleanUp(address.address)}"
        address2: "${cleanUp(address.address2)}"
        city: "${cleanUp(address.city)}"
        state: "${cleanUp(address.state)}"
        zipcode: "${cleanUp(address.zipcode)}"
        country: "${address.country}"
      },
      where: { id: ${address.id} }
    ) {
      id
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ---------------------- L E G A L   E N T I T I E S ---------------------- */
/* ------------------------------------------------------------------------- */

export const licenseeMutation = gql`
  mutation LicenseeMutation(
    $data: LicenseeMutationInput!
    $duplicateCheck: Boolean = true
  ) {
    licenseeMutation(data: $data, duplicateCheck: $duplicateCheck) {
      result {
        id
        uniqueId
        status
      }
      error
      message
    }
  }
`

export const bulkLicenseeMutation = gql`
  mutation BulkLicenseeMutation($data: [LicenseeMutationInput!]!) {
    bulkLicenseeMutation(data: $data) {
      result {
        id
        uniqueId
        email
        status
      }
      type
      error
      message
      index
      adminsToNotify
    }
  }
`

export const updateProfileLegalEntityMutation = leDetails => gql`
  mutation UpdateProfileLE {
    updateLegalEntity(data:{
      ${
        leDetails.isMailingSameAsPremises
          ? `mailingAddressId: ${leDetails.premisesAddress.id}`
          : ''
      }
      ${
        leDetails.newMailingAddressId
          ? `mailingAddressId: ${leDetails.newMailingAddressId}`
          : ''
      }
    }, where:{ id: ${leDetails.id}}) {
      id
    }
  }
`

export const deactivateLicenseeMutation = gql`
  mutation DeactivateLicenseeMutation($id: Int!, $updatedBy: Int!) {
    deactivateLicensee(id: $id, updatedBy: $updatedBy) {
      success
    }
  }
`

export const reactivateLicenseeMutation = gql`
  mutation ReactivateLicenseeMutation($id: Int!, $updatedBy: Int!) {
    reactivateLicensee(id: $id, updatedBy: $updatedBy) {
      success
    }
  }
`

export const linkProductsFromLicenseeMutation = gql`
  mutation LinkProducts($newId: Int!, $oldId: Int!, $updatedBy: Int!) {
    linkProductsFromLicensee(
      newId: $newId
      oldId: $oldId
      updatedBy: $updatedBy
    ) {
      success
    }
  }
`

/* ------------------------------------------------------------------------ */
/* ------------------------------ U S E R S ------------------------------- */
/* ------------------------------------------------------------------------ */

export const createNewUser = userDetails => gql`
  mutation createNewUser {
    createUser(
      data: {
        firstName: "${cleanUp(userDetails.firstName)}"
        lastName: "${cleanUp(userDetails.lastName)}"
        email: "${cleanUp(userDetails.email.toLowerCase())}"
        phoneNumber: "${cleanUp(userDetails.phoneNumber)}"
        roleId: ${userDetails.roleId}
        status: "${userDetails.status}"
        ${
          userDetails.legalEntityId
            ? `legalEntityId: ${userDetails.legalEntityId}`
            : ''
        }
        createdBy: ${userDetails.userId}
        updatedBy: ${userDetails.userId}
      }
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      role {
        name
      }
      verificationCode
      createdAt
      updatedAt
      status
      legalEntity{
        id
        name
      }
    }
  }
`

export const resendUserRegistration = user => gql`
  mutation ResendUserRegistration {
    resendUserRegistration(data:{ updatedBy: ${user.updatedBy} }, where:{ id: ${user.id}}) {
      email
      verificationCode
    }
  }
`

export const registerUser = userDetails => gql`
  mutation RegisterUser {
    updateUser(data:{
      cognitoId: "${userDetails.cognitoId}"
      firstName: "${cleanUp(userDetails.firstName)}"
      lastName: "${cleanUp(userDetails.lastName)}"
      username: "${cleanUp(userDetails.username.toLowerCase())}"
      email: "${cleanUp(userDetails.email.toLowerCase())}"
      ${userDetails.phone ? `phoneNumber: "${cleanUp(userDetails.phone)}"` : ''}
      status: "${userDetails.status}"
    }, where:{ verificationCode: "${userDetails.verificationCode}"}) {
      id
    }
  }
`

export const addLicenseTypesMutation = types => gql`
  mutation AddLicenseTypes {
    addLicenseTypes(data: ${types}) {
      success
    }
  }
`

export const removeLicenseTypesMutation = leId => gql`
  mutation RemoveLicenseTypes {
    deleteLicenseTypes(where: { legalEntityId: ${leId} }) {
      success
    }
  }
`

export const updateProfileMutation = userDetails => gql`
  mutation UpdateProfile {
    updateUser(data:{
      firstName: "${cleanUp(userDetails.firstName)}"
      lastName: "${cleanUp(userDetails.lastName)}"
      email: "${cleanUp(userDetails.email.toLowerCase())}"
      phoneNumber: "${cleanUp(userDetails.phoneNumber)}"
    }, where:{ id: ${userDetails.id}}) {
      id
    }
  }
`

export const updateUserDetailsMutation = gql`
  mutation UpdateUserDetails(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`

export const updateUserMutation = userDetails => gql`
  mutation UpdateUserDetails {
    updateUser(data:{
      firstName: "${cleanUp(userDetails.firstName)}"
      lastName: "${cleanUp(userDetails.lastName)}"
      email: "${cleanUp(userDetails.email.toLowerCase())}"
      phoneNumber: "${cleanUp(userDetails.phoneNumber)}"
      ${userDetails.role ? `roleId: ${userDetails.role}` : ''}
    }, where:{ id: ${userDetails.id}}) {
      id
    }
  }
`

export const updateLeUserMutation = userDetails => gql`
  mutation UpdateLeUser {
    updateUser(data:{
      firstName: "${cleanUp(userDetails.firstName)}"
      lastName: "${cleanUp(userDetails.lastName)}"
      email: "${cleanUp(userDetails.email.toLowerCase())}"
      phoneNumber: "${cleanUp(userDetails.phoneNumber)}"
      ${userDetails.role ? `roleId: ${userDetails.role}` : ''}
      ${
        userDetails.legalEntity
          ? `legalEntityId: ${userDetails.legalEntity.id}`
          : ''
      }
    }, where:{ id: ${userDetails.id}}) {
      id
    }
  }
`

/* ------------------------------------------------------------------------- */
/* ------------------------- P R O D U C T S ------------------------------- */
/* ------------------------------------------------------------------------- */

export const createNewProduct = productDetails => gql`
  mutation createNewProduct {
    createProduct(
      data: {
        manufacturerId: ${productDetails.manufacturer}
        name: "${cleanUp(productDetails.name)}"
        tradeName: "${
          productDetails.tradeName ? cleanUp(productDetails.tradeName) : ''
        }"
        createdBy: ${productDetails.userId}
        updatedBy: ${productDetails.userId}
      }
    ) {
      id
      manufacturer{
        name
        id
      }
      name
      tradeName
      oldTradeName
      oldName
      createdAt
      updatedAt
    }
  }
`

export const updateProductMutation = productDetails => gql`
mutation updateExistingProduct {
  updateProduct(
    data: {
      manufacturerId: ${productDetails.manufacturer}
      name: "${cleanUp(productDetails.name)}"
      tradeName: "${
        productDetails.tradeName ? cleanUp(productDetails.tradeName) : ''
      }"
      ${
        productDetails.oldName
          ? `oldName:"${cleanUp(productDetails.oldName)}"`
          : ''
      }
      ${
        productDetails.oldTradeName
          ? `oldTradeName:"${cleanUp(productDetails.oldTradeName)}"`
          : ''
      }
      updatedBy: ${productDetails.userId}
    }, where:{ id: ${productDetails.id}}) {
    id 
  }
}
`

export const activateOrDeactivateProductMutation = gql`
  mutation activateOrDeactivateExistingProduct($where: ProductWhereInput!) {
    activateOrDeactivateProduct(where: $where) {
      id
      status
    }
  }
`

/* -------------------------------------------------------------------------------- */
/* ------------------------- P R I C E  P O S T I N G S ------------------------- */
/* -------------------------------------------------------------------------------- */

export const pricePostingMutation = gql`
  mutation PricePostingMutation($data: [JSON!]!, $bulk: Boolean = false) {
    pricePostingMutation(data: $data, bulk: $bulk) {
      duplicates
      needConfirmation
      success
      errors
    }
  }
`

export const confirmedPricePostingMutation = gql`
  mutation ConfirmedPricePostingMutation($data: [JSON!]!, $userId: ID) {
    confirmedPricePostingMutation(data: $data, userId: $userId) {
      changeSuccess
      reactivationSuccess
      changeFailures
      reactivationFailures
    }
  }
`

export const pricePostingPromoMutation = gql`
  mutation PricePostingPromoMutation($data: [JSON!]!, $userId: ID, $activatePromo: Boolean!) {
    pricePostingPromoMutation(data: $data, userId: $userId, activatePromo: $activatePromo) {
      results
      count
    }
  }
`

export const inactivatePricePostings = ({ ids, updatedBy }) => gql`
  mutation inactivatePricePostings {
    inactivatePricePostings(ids:[${ids.join(',')}], updatedBy: ${updatedBy}) {
      id
    }
  }
`
export const activatePricePostings = ({ ids, updatedBy }) => gql`
  mutation activatePricePostings {
    activatePricePostings(ids:[${ids.join(',')}], updatedBy: ${updatedBy}) {
      success
    }
  }
`

export const competitivePricePosting = gql`
  mutation competitivePricePost(
    $myPricePostingIds: [Int!]!
    $competingPricePostingIds: [Int!]!
    $data: PricePostingCompetitiveInput!
  ) {
    competitivePricePost(
      myPricePostingIds: $myPricePostingIds
      competingPricePostingIds: $competingPricePostingIds
      data: $data
    ) {
      success
    }
  }
`

/* -------------------------------------------------------------------------------- */
/* ------------------------- P R I C E  P O S T I N G S  S C H E D U L E ------------------------- */
/* -------------------------------------------------------------------------------- */

export const pricePostingsScheduleUpdateScheduleDate = gql`
  mutation PricePostingsScheduleUpdateScheduleDate(
    $ppsId: Int!
    $ppsManufacturerId: Int!
    $data: PricePostingsScheduleUpdateScheduleDateInput!
  ) {
    pricePostingsScheduleUpdateScheduledDate(
      ppsId: $ppsId
      ppsManufacturerId: $ppsManufacturerId
      data: $data
    ) {
      success
    }
  }
`
export const deletePricePostSchedule = gql`
mutation DeletePricePostSchedule(
  $where: PricePostingsScheduleWhereInput!
  ) {
  deletePricePostSchedule(
    where: $where
    ) {
    success
  }
}
`

export const createPricePostingsSchedule = gql`
  mutation createPricePostingsSchedule(
    $data: PricePostingsScheduleMutationInput!
  ) {
    createPricePostingsSchedule(data: $data) {
      success
    }
  }
`

/* -------------------------------------------------------------------------------------------- */
/* ------------------------- P R I C E   P O S T I N G S  P R O M O   A U D I T ----------------------- */
/* -------------------------------------------------------------------------------------------- */

export const createPricePostingsPromoAudit = gql`
  mutation createPricePostingsPromoAudit($data: PricePostingsPromoAuditMutationInput!) {
    createPricePostingsPromoAudit(data: $data) {
      success
    }
  }
`

/* -------------------------------------------------------------------------------- */
/* ------------------------- G L O B A L  S E T T I N G S ------------------------- */
/* -------------------------------------------------------------------------------- */

// ------------------- P R O D U C T   S I Z E S -------------------

export const createProductSizeMutation = gql`
  mutation CreateProductSize($data: ProductSizeCreateInput!) {
    createProductSize(data: $data) {
      id
      size
      unit {
        id
        unit
        abbreviation
      }
      containerType {
        id
        type
      }
    }
  }
`

export const updateProductSizeMutation = gql`
  mutation ProductSizeUpdate(
    $data: ProductSizeUpdateInput!
    $where: ProductSizeWhereUniqueInput!
  ) {
    updateProductSize(data: $data, where: $where) {
      id
    }
  }
`

export const inactivateAndCreateProductSizeMutation = gql`
  mutation InactivateAndCreateProductSize(
    $data: ProductSizeUpdateInput!
    $where: ProductSizeWhereUniqueInput!
  ) {
    inactivateAndCreateProductSize(data: $data, where: $where) {
      id
    }
  }
`

export const changeProductSizeStatus = gql`
  mutation ChangeProductSizeStatus(
    $status: String!
    $where: ProductSizeWhereUniqueInput!
  ) {
    changeProductSizeStatus(status: $status, where: $where) {
      success
    }
  }
`

// ------------------- P R I C E S  T O -------------------

export const createPricesToMutation = gql`
  mutation createPricesTo($data: PricesToCreateInput!) {
    createPricesTo(data: $data) {
      id
      name
    }
  }
`

export const updatePricesToMutation = gql`
  mutation updateExistingPricesTo(
    $data: PricesToUpdateInput!
    $where: PricesToWhereUniqueInput!
  ) {
    updatePricesTo(data: $data, where: $where) {
      id
    }
  }
`

export const inactivateAndCreatePricesToMutation = gql`
  mutation inactivateAndCreatePricesTo(
    $data: PricesToUpdateInput!
    $where: PricesToWhereUniqueInput!
  ) {
    inactivateAndCreatePricesTo(data: $data, where: $where) {
      id
    }
  }
`

export const changePricesToStatus = gql`
  mutation ChangePricesToStatus(
    $status: String!
    $where: PricesToWhereUniqueInput!
  ) {
    changePricesToStatus(status: $status, where: $where) {
      success
    }
  }
`

// ------------------- P A C K A G E   C O N F I G U R A T I O N -------------------

export const createPackageMutation = gql`
  mutation createPackage($data: PackageCreateInput!) {
    createPackage(data: $data) {
      id
      package
    }
  }
`

export const updatePackageMutation = gql`
  mutation updateExistingPackage(
    $data: PackageUpdateInput!
    $where: PackageWhereUniqueInput!
  ) {
    updatePackage(data: $data, where: $where) {
      id
    }
  }
`

export const inactivateAndCreatePackageMutation = gql`
  mutation inactivateAndCreatePackage(
    $data: PackageUpdateInput!
    $where: PackageWhereUniqueInput!
  ) {
    inactivateAndCreatePackage(data: $data, where: $where) {
      id
    }
  }
`

export const changePackageStatus = gql`
  mutation ChangePackageStatus(
    $status: String!
    $where: PackageWhereUniqueInput!
  ) {
    changePackageStatus(status: $status, where: $where) {
      success
    }
  }
`

// ------------------- C O N F I G   R E Q U E S T S -------------------

export const createConfigRequestMutation = gql`
  mutation CreateConfigRequest($data: ConfigRequestCreateInput!) {
    createConfigRequest(data: $data) {
      id
    }
  }
`

export const updateConfigRequestMutation = gql`
  mutation ConfigRequestUpdate(
    $data: ConfigRequestUpdateInput!
    $where: ConfigRequestWhereUniqueInput!
  ) {
    updateConfigRequest(data: $data, where: $where) {
      id
    }
  }
`

export const deleteConfigRequestMutation = gql`
  mutation DeleteConfigRequest($where: ConfigRequestWhereUniqueInput!) {
    deleteConfigRequest(where: $where) {
      success
    }
  }
`
// ------------------- News Update -------------------

export const createNewsUpdateMutation = gql`
  mutation CreateNewsUpdate($data: NewsUpdateCreateInput!) {
    createNewsUpdate(data: $data) {
      id
    }
  }
`

export const updateNewsUpdateMutation = gql`
  mutation UpdateNewsUpdate(
    $data: UpdateNewsUpdateInput!
    $where: NewsUpdateWhereUniqueInput!
  ) {
    updateNewsUpdate(data: $data, where: $where) {
      id
    }
  }
`

export const deleteNewsUpdateMutation = gql`
  mutation DeleteNewsUpdate($where: NewsUpdateWhereUniqueInput!) {
    deleteNewsUpdate(where: $where) {
      success
    }
  }
`
