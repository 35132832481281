import React, { useEffect } from 'react'
import ListComponent from 'components/common/ListComponent'
import ReusableTable from 'components/common/ReusableTable'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  getPricePostsList,
  exportToExcelCall,
  setIsPricePostingsFilterOpen,
  setPricePostingsPageNumber,
  setPricePostingsRowsPerPage,
  setPricePostingsRequestedSort,
  fetchPricePostingByID,
  resetPricePostDetails,
  setPricePostingBulkSelect,
} from 'ducks/public/pricePosts'
import {
  formatNumberToTwoDecimal,
  formatNumberToThreeDecimal,
  convertSizeOfBBL,
} from 'common/helper'
import { format } from 'date-fns'
import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import styled from 'styled-components'
import LoadingIndicator from 'components/common/LoadingIndicator'
import PricePostsFilters from './PricePostsFilters'
import ViewPricePost from './ViewPricePost'

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    padding: 6px 18px;
  }

  @media (max-width: 550px) {
    display: block;

    button {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`

/*------ Price Posts list ------*/

let pricePostingsHeaders = [
  { id: 'manufacturer', label: 'Manufacturer', isSortable: true },
  { id: 'tradeName', label: 'Trade Name', isSortable: true },
  { id: 'productName', label: 'Product Name', isSortable: true },
  {
    id: 'package',
    label: 'Package Configuration',
    isSortable: true,
  },
  { id: 'size', label: 'Size', isSortable: true },
  { id: 'county', label: 'County', isSortable: true },
  { id: 'pricesTo', label: 'Prices To', isSortable: true },
  { id: 'receivingMethod', label: 'Receiving Method', isSortable: true },
  { id: 'price', label: 'Price($)', isSortable: true },
  {
    id: 'containerCharge',
    label: 'Container Charge($)',
    isSortable: true,
  },
  { id: 'pricePromotion', label: 'Price Promotion', isSortable: true },
  { id: 'createdByLicensee', label: 'Posted By', isSortable: true },
  { id: 'effectiveDate', label: 'Effective Date', isSortable: true },
  { id: 'status', label: 'Status', isSortable: true },
]

const PublicPricePosts = () => {
  const dispatch = useDispatch()

  const {
    pricePostingsList,
    pricePostingsTotalCount,
    order,
    orderBy,
    rowsPerPage,
    pageNumber,
    bulkSelected,
    isPricePostingsFilterOpen,
    isPricePostingShown,
    pricePostingsFilters,
  } = useSelector(store => store.publicPricePosts)
  const { loadingText, fetchingTotalCount } = useSelector(store => store.global)

  useEffect(() => {
    dispatch(getPricePostsList())
  }, [dispatch, order, orderBy, pageNumber, rowsPerPage])

  /* ----------- Actions on selected items ----------- */

  const actionItemsOnSelection = (
    <ActionWrapper>
      <Button
        aria-label='export'
        color='primary'
        onClick={e => dispatch(exportToExcelCall())}
        size='medium'
        variant='contained'
        startIcon={<CloudDownload />}
      >
        Export To Excel
      </Button>
    </ActionWrapper>
  )

  /*-------------------Price posts list----------------*/

  let updatedPricePostingsList = []
  updatedPricePostingsList = pricePostingsList.results.map(pricePosting => {
    const updatedPricePosting = {
      id: pricePosting.id,
      manufacturer: pricePosting.manufacturer.name,
      tradeName: pricePosting.product.tradeName,
      productName: pricePosting.product.name,
      package: pricePosting.package.package,
      size: `
        ${convertSizeOfBBL(pricePosting.productSize.size, pricePosting.productSize.unit.unit)} ${
        pricePosting.productSize.unit.unit
      } ${pricePosting.productSize.containerType.type}
      `,
      county: pricePosting.county,
      pricesTo: pricePosting.pricesTo.name,
      receivingMethod: pricePosting.receivingMethod,
      price: formatNumberToThreeDecimal(pricePosting.price),
      containerCharge: formatNumberToTwoDecimal(pricePosting.containerCharge),
      pricePromotion: pricePosting.pricePromotion ? 'Yes' : 'No',
      createdByLicensee: pricePosting.createdByLicensee && pricePosting.createdByLicensee.name,
      effectiveDate: format(pricePosting.effectiveDate, 'MM/dd/yyyy'),
      status: pricePosting.status,
    }
    return updatedPricePosting
  })

  /*---------------Pagination----------------*/

  const handlePageNumber = newPage => {
    dispatch(setPricePostingsPageNumber(newPage))
  }
  const handleRowsPerPage = rowsPerPage => {
    dispatch(setPricePostingsRowsPerPage(rowsPerPage))
  }
  const handleRequestSort = value => {
    dispatch(setPricePostingsRequestedSort(value))
  }
  const handleFilter = value => {
    dispatch(setIsPricePostingsFilterOpen(value))
  }

  /*-------------Modals----------------*/

  const onQuickViewClosed = () => {
    dispatch(resetPricePostDetails())
  }

  /*------------Bulk/Single selection--------*/

  const onSingleItemSelected = pricePosting => {
    dispatch(fetchPricePostingByID(pricePosting.id))
  }

  const handleSelectionOfAllRows = () => {
    dispatch(getPricePostsList(false, false, true)) //@params: whereClause, getUpdatedTotalCount, selectAll
  }

  const handleBulkSelection = items => {
    const bulkSelectedIDs = bulkSelected.map(d => d.id)
    const singlePageSelectedIDs = pricePostingsList.results.map(n => n.id)
    let newSelected = []
    if (items === 'selectAll' || items === 'unselectAll') {
      //This means select all/unselect all on the current page
      //first, remove the current page selectedIDs from the bulk selected array
      newSelected = bulkSelected.filter(item => !singlePageSelectedIDs.includes(item.id))
      if (items === 'selectAll') {
        //now update the bulk selected array with current page IDs
        newSelected = newSelected.concat(
          pricePostingsList.results.map(finalObject => ({
            id: finalObject.id,
            manufacturer: {
              value: finalObject.manufacturer.id,
              label: finalObject.manufacturer.name,
            },
            package: {
              value: finalObject.package.id,
              label: finalObject.package.name,
            },
            receivingMethod: {
              value: finalObject.receivingMethod,
              label: finalObject.receivingMethod,
            },
            size: {
              value: finalObject.productSize.id,
            },
            pricesTo: {
              value: finalObject.pricesTo.id,
              label: finalObject.pricesTo.name,
            },
            product: {
              value: finalObject.product.id,
              label: finalObject.product.name,
            },
            createdAt: finalObject.createdAt,
            parentPricePostId: finalObject.parentPricePostId,
            status: finalObject.status,
            createdByLicenseeID: finalObject.createdByLicensee.id,
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            pricePromotion: finalObject.pricePromotion,
            county: finalObject.county,
          }))
        )
        //if it does not gets here, that mean unselect all on the current page
        //already filtered out list will be sent to store at the end
      }
    } else {
      const selectedIndex = bulkSelectedIDs.indexOf(items.id)

      if (selectedIndex === -1) {
        // if the selected item is a new selection(that does not exists in bulkSelected)

        const extractedValue = pricePostingsList.results
          .filter(ppItem => items.id === ppItem.id)
          .map(finalObject => ({
            id: finalObject.id,
            manufacturer: {
              value: finalObject.manufacturer.id,
              label: finalObject.manufacturer.name,
            },
            package: {
              value: finalObject.package.id,
              label: finalObject.package.name,
            },
            receivingMethod: {
              value: finalObject.receivingMethod,
              label: finalObject.receivingMethod,
            },
            size: {
              value: finalObject.productSize.id,
            },
            pricesTo: {
              value: finalObject.pricesTo.id,
              label: finalObject.pricesTo.name,
            },
            product: {
              value: finalObject.product.id,
              label: finalObject.product.name,
            },
            createdAt: finalObject.createdAt,
            parentPricePostId: finalObject.parentPricePostId,
            status: finalObject.status,
            createdByLicenseeID: finalObject.createdByLicensee
              ? finalObject.createdByLicensee.id
              : '',
            price: finalObject.price,
            containerCharge: finalObject.containerCharge,
            pricePromotion: finalObject.pricePromotion,
            county: finalObject.county,
          }))

        newSelected = newSelected.concat(bulkSelected, extractedValue)
      } else if (selectedIndex === 0) {
        //if the selected item is already checked(already exists in the bulkSelected array on first(0th) index)
        newSelected = newSelected.concat(bulkSelected.slice(1))
      } else if (selectedIndex === bulkSelected.length - 1) {
        newSelected = newSelected.concat(bulkSelected.slice(0, -1))
      } else if (selectedIndex > 0) {
        // if the selected item exists somewhere in the bulkSelected array
        newSelected = newSelected.concat(
          bulkSelected.slice(0, selectedIndex),
          bulkSelected.slice(selectedIndex + 1)
        )
      }
    }

    dispatch(setPricePostingBulkSelect(newSelected))
  }

  const handleClearSelection = event => {
    dispatch(setPricePostingBulkSelect([]))
  }

  return pricePostingsList.firstLoad ? (
    <LoadingIndicator text={loadingText} transparent />
  ) : (
    <>
      <Helmet>
        <title>Price Postings</title>
      </Helmet>
      <ListComponent
        mainTitle='Price Postings'
        listContent={
          <ReusableTable
            headers={pricePostingsHeaders}
            tableData={updatedPricePostingsList}
            pagination={true}
            selectionOption={true}
            totalCount={pricePostingsTotalCount}
            fetchingTotalCount={fetchingTotalCount}
            onSingleItemSelected={onSingleItemSelected}
            handleBulkSelection={handleBulkSelection}
            setPageNumber={handlePageNumber}
            setRowsPerPage={handleRowsPerPage}
            setRequestedSort={handleRequestSort}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            bulkSelected={bulkSelected}
            canChangeRowsPerPage={[10, 20, 50, 100]}
            handleSelectAllRows={handleSelectionOfAllRows}
            handleClearSelection={handleClearSelection}
            actionItemsOnSelection={actionItemsOnSelection}
            permitRadioFilters={false}
          />
        }
        publicView={true}
        viewComp={<ViewPricePost />}
        modalViewTitle='Price Posting Details'
        onModalClosed={onQuickViewClosed}
        expandedOptions={<PricePostsFilters pricePostingsFilters={pricePostingsFilters} />}
        handleExpandingFilter={handleFilter}
        filterExpanded={isPricePostingsFilterOpen}
        loadingText={loadingText}
        isDialogOpen={isPricePostingShown}
      />
    </>
  )
}

export default PublicPricePosts
