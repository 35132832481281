import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import LoadingIndicator from 'components/common/LoadingIndicator'
import CustomCard from 'components/common/CustomCard'
import styled from 'styled-components'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import {
  createNewsUpdate,
  updateNewsUpdate,
  resetNewsUpdateDetails,
  toggleNewsUpdateDialog,
} from 'ducks/newsUpdate'

const StyledForm = styled(Form)`
  width: 100%;
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      height: auto;
    }
  }
`

const validations = Yup.object().shape({
  news: Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  }),
})

const NewsUpdate = () => {
  const dispatch = useDispatch()
  const { isEditMode, isLoading, loadingText } = useSelector(
    store => store.global
  )
  const { newsUpdateDetails } = useSelector(store => store.newsUpdate)
  const onNewsAdd = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      dispatch(createNewsUpdate(formikValues.values.news))
    }
  }
  const onNewsUpdate = formikValues => {
    formikValues.handleSubmit() // Used to trigger Yup validations
    if (formikValues.isValid) {
      dispatch(updateNewsUpdate(formikValues.values.news))
    }
  }

  return (
    <StyledForm>
      <Field
        name='news'
        render={formikProps => {
          formikProps.form.values.news = !formikProps.form.values.news
            ? JSON.parse(JSON.stringify(newsUpdateDetails))
            : JSON.parse(JSON.stringify(formikProps.form.values.news))

          const handleCancel = () => {
            dispatch(resetNewsUpdateDetails())
            dispatch(toggleNewsUpdateDialog(false))
          }

          return (
            <>
              {isLoading ? (
                <LoadingIndicator text={loadingText} transparent />
              ) : (
                <>
                  <CustomCard title='News Update'>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        label='Title'
                        name='news.title'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Field
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        multiline
                        rows='4'
                        label='Description'
                        name='news.description'
                        component={StyledTextField}
                      />
                    </Grid>
                  </CustomCard>
                  <SaveAndCancelButton
                    onSave={() =>
                      isEditMode
                        ? onNewsUpdate(formikProps.form)
                        : onNewsAdd(formikProps.form)
                    }
                    onCancel={() => handleCancel()}
                    submitLabel='Update'
                  />
                </>
              )}
            </>
          )
        }}
      />
    </StyledForm>
  )
}

const WithFormikWrapper = withFormik({
  validationSchema: validations,
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  enableReinitialize: true,
})(NewsUpdate)

export default WithFormikWrapper
