import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from 'assets/logo.jpg'
import { IconButton, MenuItem, Menu } from '@material-ui/core'
import { AccountCircle, Dehaze } from '@material-ui/icons'
import { Typography, AppBar, Toolbar, Hidden } from '@material-ui/core'
import styled from 'styled-components'
import { signOut } from 'ducks/authentication'
import { onSidebarExpandIconClicked } from 'ducks/sidebar'
import ProfileView from 'components/private/myProfile/ProfileView'
import ProfileEdit from 'components/private/myProfile/ProfileEdit'
import QuickView from 'components/common/QuickView'
import { setEditMode } from 'ducks/global'
import { resetProfile, setProfileShown } from 'ducks/profileView'

const Header = styled(AppBar)`
  && {
    background-color: #fff;
    border-bottom: 4px solid ${props => props.theme.gold};
  }
`

const HeaderContent = styled(Toolbar)`
  && {
    min-height: 50px;
  }
`

const HeaderTitle = styled(Typography)`
  && {
    color: ${props => props.theme.dark};
  }
`

const Logo = styled.img`
  max-width: 250px;
  max-height: 40px;
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const ProfileButton = styled(IconButton)`
  && {
    padding-left: 10px;
    line-height: 50px;
    border-radius: 5px;
  }
`

const ProfileIcon = styled(AccountCircle)`
  && {
    font-size: 30px;
  }
`

const HamburgerIcon = styled(Dehaze)`
  && {
    font-size: 30px;
  }
`

const CustomHeader = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(store => store.auth)
  const [anchorEl, setAnchorEl] = useState(null)
  const { isEditMode } = useSelector(store => store.global)
  const { ownProfile, isProfileShown } = useSelector(store => store.profileView)

  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onProfileClicked = () => {
    dispatch(setProfileShown(true))
    dispatch(setEditMode(false))
    handleClose()
  }

  return (
    <>
      <Header position='fixed'>
        <HeaderContent>
          <IconButton
            aria-label='sidebar'
            aria-haspopup='false'
            onClick={() => dispatch(onSidebarExpandIconClicked())}
            color='primary'
          >
            <HamburgerIcon color='primary' />
          </IconButton>
          <Hidden only='xs'>
            <Logo alt='ABC Logo' src={logo} />
          </Hidden>
          <FlexGrow />
          <>
            <ProfileButton
              aria-label='account of current user'
              aria-haspopup='true'
              onClick={handleMenu}
              color='primary'
            >
              <ProfileIcon color='primary' />
              <HeaderTitle variant='subtitle1'>
                &nbsp;{user.firstName}
              </HeaderTitle>
            </ProfileButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => onProfileClicked()}>Profile</MenuItem>
              <MenuItem onClick={() => dispatch(signOut())}>Logout</MenuItem>
            </Menu>
          </>
          <QuickView
            isDialogOpen={isProfileShown}
            title={isEditMode ? 'Edit Profile' : 'My Profile'}
            dialogContent={
              isEditMode ? (
                <ProfileEdit ownProfile={ownProfile} />
              ) : (
                <ProfileView />
              )
            }
            onClose={() => dispatch(resetProfile())}
          />
        </HeaderContent>
      </Header>
    </>
  )
}

export default CustomHeader
