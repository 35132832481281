import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography, Avatar, IconButton, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Edit, Lock } from '@material-ui/icons'
import { fetchOwnProfileByID, fetchLeAdminOwnProfile } from 'ducks/profileView'
import { setEditMode } from 'ducks/global'
import { format } from 'date-fns'
import CustomCard from 'components/common/CustomCard'
import DataGrid from 'components/common/DataGrid'
import LoadingIndicator from 'components/common/LoadingIndicator'
import { roles } from 'common/constants'

const UserIcon = styled(Avatar)`
  && {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.light};
    width: 80px;
    height: 80px;
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    top: 5px;
    line-height: 50px;
    border-radius: 5px;
  }
`
const EditButton = styled(StyledIconButton)`
  && {
    right: 1em;
  }
`
const EditIcon = styled(Edit)`
  && {
    font-size: 30px;
  }
`
const LockIcon = styled(Lock)`
  && {
    font-size: 30px;
  }
`
const GridContainer = styled(Grid)`
  && {
    width: 275px;
  }
`

const ProfileView = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(store => store.global)
  const { user } = useSelector(store => store.auth)
  const { ownProfile } = useSelector(store => store.profileView)
  const { premisesAddress, mailingAddress } = ownProfile.legalEntity
    ? ownProfile.legalEntity
    : ''
  useEffect(() => {
    if (user.role.name === roles.lEAdmin) {
      dispatch(fetchLeAdminOwnProfile(user.id))
    } else {
      dispatch(fetchOwnProfileByID(user.id, user.role.name === roles.lEUser))
    }
  }, [dispatch, user.id, user.role.name])

  const userIsLicensee =
    (user.role.name === roles.lEUser || user.role.name === roles.lEAdmin) &&
    ownProfile.legalEntity
      ? true
      : false

  const history = useHistory()

  return (
    <>
      {isLoading ? (
        <LoadingIndicator text='Loading Profile...' transparent />
      ) : (
        <>
          <CustomCard
            profileIcon={
              <UserIcon>
                {ownProfile.firstName.charAt(0).toUpperCase()}
                {ownProfile.lastName.charAt(0).toUpperCase()}
              </UserIcon>
            }
            profileTitle={
              <Typography
                children={`${ownProfile.firstName} ${ownProfile.lastName}`}
                variant='h6'
              />
            }
            profileSubHeading={ownProfile.role ? ownProfile.role.name : ''}
            profileAction={
              <>
                <GridContainer container>
                  <Grid item xs={7}>
                    <StyledIconButton
                      aria-label='Change Password'
                      aria-haspopup='false'
                      color='primary'
                      onClick={() => history.push('/forgotPassword')}
                    >
                      <LockIcon color='primary' />
                      <Typography variant='subtitle1'>
                        &nbsp;{'Change Password'}
                      </Typography>
                    </StyledIconButton>
                  </Grid>
                  <Grid item xs={5}>
                    <EditButton
                      aria-label='Edit ABC User'
                      aria-haspopup='false'
                      color='primary'
                      onClick={() => dispatch(setEditMode(true))}
                    >
                      <EditIcon color='primary' />
                      <Typography variant='subtitle1'>
                        &nbsp;{'Edit'}
                      </Typography>
                    </EditButton>
                  </Grid>
                </GridContainer>
              </>
            }
            divider
          >
            {userIsLicensee && (
              <DataGrid label='Licensee' value={ownProfile.legalEntity.name} />
            )}
            <DataGrid label='Email' value={ownProfile.email} />
            <DataGrid label='Status' value={ownProfile.status} />
            <DataGrid label='Phone' value={ownProfile.phoneNumber} />
            <DataGrid
              label='Added Date'
              value={
                ownProfile.createdAt
                  ? format(ownProfile.createdAt, 'MM/dd/yyyy')
                  : ''
              }
            />
            <DataGrid
              label='Edited Date'
              value={
                ownProfile.updatedAt
                  ? format(ownProfile.updatedAt, 'MM/dd/yyyy')
                  : ''
              }
            />
            <DataGrid label='Username' value={ownProfile.username} />
            {userIsLicensee && (
              <DataGrid
                label='License Types'
                value={ownProfile.legalEntity.licenseTypes}
              />
            )}
          </CustomCard>
          {user.role.name === roles.lEAdmin && ownProfile.legalEntity ? (
            <>
              <CustomCard
                title={
                  ownProfile.legalEntity.isMailingSameAsPremises
                    ? 'Licensee Premises & Mailing Address'
                    : 'Licensee Premises Address'
                }
              >
                <DataGrid
                  label='Address Line 1'
                  value={premisesAddress.address}
                />
                {premisesAddress.address2 ? (
                  <DataGrid
                    label='Address Line 2'
                    value={premisesAddress.address2}
                  />
                ) : (
                  ''
                )}
                <DataGrid label='City' value={premisesAddress.city} />
                <DataGrid label='State' value={premisesAddress.state} />
                <DataGrid label='Zip Code' value={premisesAddress.zipcode} />
                <DataGrid label='Country' value={premisesAddress.country} />
              </CustomCard>
              {!ownProfile.legalEntity.isMailingSameAsPremises && (
                <CustomCard title='Licensee Mailing Address'>
                  <DataGrid
                    label='Address Line 1'
                    value={mailingAddress.address}
                  />
                  {mailingAddress.address2 ? (
                    <DataGrid
                      label='Address Line 2'
                      value={mailingAddress.address2}
                    />
                  ) : (
                    ''
                  )}
                  <DataGrid label='City' value={mailingAddress.city} />
                  <DataGrid label='State' value={mailingAddress.state} />
                  <DataGrid label='Zip Code' value={mailingAddress.zipcode} />
                  <DataGrid label='Country' value={mailingAddress.country} />
                </CustomCard>
              )}
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}

export default ProfileView
