import React from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogActions,
  Paper,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import SaveAndCancelButton from 'components/common/SaveAndCancelSection'
import Draggable from 'react-draggable'

const PaperComponent = props => {
  return (
    <Draggable
      handle='#form-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const DialogHeader = styled(DialogTitle)`
  margin: 0px;
  background-color: ${props => props.theme.dark};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${props => props.theme.light};
  cursor: move;
`

const QuickViewWrapper = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${props => props.theme.gray};
  }
`

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 1px;
    right: 1px;
    color: ${props => props.theme.light};
  }
`

const CustomDialogContent = styled(DialogContent)`
  && {
    min-height: ${props =>
      props.children[1] && !props.children[1].props.confirmation
        ? '250px'
        : ''};
  }
`

const CustomDialogActions = styled(DialogActions)`
  && {
    max-width: 99%;
  }
`

const QuickView = ({
  title,
  contentText,
  dialogContent,
  onClose,
  isDialogOpen,
  dialogActions,
  onConfirmation,
  isSmallDialog,
}) => {
  return (
    <QuickViewWrapper
      open={isDialogOpen}
      onClose={() => onClose()}
      aria-labelledby='form-dialog-title'
      maxWidth={!isSmallDialog ? 'lg' : 'sm'}
      fullWidth={true}
      scroll='paper'
      PaperComponent={PaperComponent}
    >
      <DialogHeader id='form-dialog-title'>{title}</DialogHeader>
      <CloseButton
        variant='contained'
        size='medium'
        color='primary'
        name='Close pop-up'
        aria-label='Close pop-up'
        onClick={() => onClose()}
      >
        <Close />
      </CloseButton>
      <CustomDialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {dialogContent}
      </CustomDialogContent>
      {dialogActions && (
        <CustomDialogActions>
          <SaveAndCancelButton
            submitLabel='Yes'
            cancelLabel='No'
            onSave={() => onConfirmation()}
            onCancel={() => onClose()}
          />
        </CustomDialogActions>
      )}
    </QuickViewWrapper>
  )
}

export default QuickView
