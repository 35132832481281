import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { ThemeProvider } from '@material-ui/styles'
import { Provider } from 'react-redux'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { materialUITheme as baseTheme } from 'styles/baseTheme'
import config from './config'
import store from './store'

// Setup AWS Amplify
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'ABC-API',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
    graphql_endpoint: config.graphql.endpoint,
    graphql_endpoint_iam_region: config.apiGateway.REGION,
  },
})

ReactDOM.render(
  <ThemeProvider theme={baseTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
